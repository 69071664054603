import * as React from 'react';
import { createRootRoute, Outlet } from '@tanstack/react-router';
import { useAuth } from '../components/amplifyProvider';
import { loadingPage } from '../components/LoadingPage';
import AvAppBar from '../components/newNavigation/AvAppBar';
import AvSidebar from '../components/newNavigation/AvSideBar';
import { useAvContext } from '../context/AvContextProvider';
import { useAvDebugContext } from '../context/AvDebugProvider';
import RootContext from '../context/RootContext';
import { useAppVersion } from '../hooks/useAppVersion';
import { getAccountTokenFromLS } from '../utils/auth.utils';
import { TanStackRouterDevtools } from '../utils/AvRouter';
import { AuthStorageKeys } from '../utils/localStorageAuthUtils.utils';

export const Route = createRootRoute({
  component: Rootroot,
  validateSearch: (search: Record<string, unknown>) => search,
});
function Rootroot() {
  return (
    <RootContext>
      <RootComponent />
    </RootContext>
  );
}
function RootComponent() {
  const { isLoading, isAuthenticated, accountId } = useAvContext();
  const { showDevTools } = useAvDebugContext();
  const isLoggedIn = localStorage.getItem(AuthStorageKeys.authConfig);
  const { logout } = useAuth();
  useAppVersion();
  if (!isAuthenticated) {
    if (isLoading) {
      if (!getAccountTokenFromLS(accountId)) {
        return loadingPage(!!isLoggedIn);
      }
    } else {
      logout(!!getAccountTokenFromLS(accountId));
      return loadingPage(!!isLoggedIn);
    }
  }
  return (
    <>
      <AvAppBar />
      <AvSidebar />
      <main>
        <Outlet />
      </main>
      {showDevTools && <TanStackRouterDevtools />}
    </>
  );
}
