import React from 'react';
import { Box, Typography } from '@mui/material';
import { useRouter } from '@tanstack/react-router';
import { useSnackbar } from 'notistack';
import { useAvMutation } from '../API/useAvQuery';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags } from '../types';
import { useNavigate, useParams } from '../utils/AvRouter';
import { isDebugMode } from '../utils/rum.utils';
import { formDisabledStyle } from '../utils/Utils';
import AvJsonViewer from './AvJsonViewer';
import AvMenu from './AvMenu';
import AvSnackBarMessage from './AvSnackBarMessage';
import { AvStepper } from './AvStepper';
import { flex } from './AvThemeProvider';

interface AvFormProps {
  title: React.ReactNode;
  name?: string;
  children: React.ReactNode;
  saveFunc: (onSuccess: any, onError?: any, v1?: any) => Promise<unknown> | void;
  testFunc?: (onSuccess: any, onError: any) => Promise<unknown>;
  setErrors?: (errors: any) => void;
  data?: Record<string, any>;
  disabled?: boolean;
  stepperProps?: Record<string, any> | null;
  limitWidth?: boolean;
  navigationOnSave?: string;
  sx?: Record<string, any>;
  padding?: string;
  menuOptions?: Array<Record<string, any>>;
  isAllowedEdit?: boolean;
  shouldDisableOpacityOnViewMode?: boolean;
  customGlobalActions?: React.ReactNode[];
  className?: string;
}

const AvForm: React.FC<AvFormProps> = ({
  title,
  name = '',
  children,
  saveFunc,
  testFunc,
  data = {},
  setErrors = () => {},
  disabled = false,
  stepperProps,
  limitWidth = true,
  navigationOnSave,
  sx = {},
  padding = '24px 32px',
  menuOptions,
  isAllowedEdit = true,
  shouldDisableOpacityOnViewMode = false,
  customGlobalActions = [],
  className,
}) => {
  const { id } = useParams();
  const { getPathName, featureFlags } = useAvContext();
  const navigate = useNavigate();
  const router = useRouter();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleError = isTest => error => {
    const detailedError = () => {
      const failureInfo = errorsGraphQL?.failureInfo;
      return (
        <AvSnackBarMessage
          title={failureInfo?.category ? failureInfo.userMessage : 'Connection Validation Failed'}
          subTitle={failureInfo?.description || `The connection details provided were not validated by ${name}`}
          isTest={!!isTest}
          message={failureInfo?.resolutionSteps || error.message}
          closeSnackbar={() => closeSnackbar(snackBarKey)}
          onClickSkipValidation={save as any}
        />
      );
    };
    const errors = error.errors?.reduce(
      (map, { field, defaultMessage }) => ({ ...map, [`${isTest ? 'config.' : ''}${field}`]: defaultMessage }),
      {}
    );
    const fieldErrorsArray =
      error?.graphQLErrors?.[0]?.extensions?.code === 'FIELDS_VALIDATION_FAILED'
        ? error?.graphQLErrors?.[0]?.extensions?.message?.split(', ')
        : undefined;
    const errorsGraphQL =
      featureFlags[FeatureFlags.RunsScreenErrorInfo] && error?.graphQLErrors?.[0]?.extensions?.failureInfo
        ? error?.graphQLErrors?.[0]?.extensions
        : fieldErrorsArray?.reduce((acc, error) => {
            const [field, message] = error.split(': ');
            return { ...acc, [field]: message };
          }, {});

    const isValidationError = error?.status === 406 || errorsGraphQL?.status === 406;
    const snackBarKey =
      errorsGraphQL && !errorsGraphQL.failureInfo
        ? ''
        : enqueueSnackbar(
            isValidationError ? detailedError() : error.message || error,
            isValidationError ? ({ variant: 'static', persist: true, action: <span /> } as any) : { variant: 'error' }
          );

    setErrors(errors || errorsGraphQL || {});
  };

  const isDirectEntry = window.history.length === 1;
  const goBack = () => {
    if (!navigationOnSave && isDirectEntry) {
      navigate(getPathName(''));
    } else if (navigationOnSave) {
      navigate({ to: navigationOnSave, state: { id } });
    } else {
      router.history.back();
      setTimeout(() => {
        navigate({ state: { id }, replace: true });
      }, 0);
    }
  };

  const { mutateAsync: save, isPending: isSaving } = useAvMutation({
    mutationFn: saveParams => saveFunc(goBack, handleError(false), saveParams)!,
  });

  const { mutateAsync: test, isPending: isTesting } = useAvMutation({
    mutationFn: () => testFunc!(() => enqueueSnackbar('Test Passed', { variant: 'success' }), handleError(true)),
  });

  const defaultStepperProps = {
    steps: [{ name: 'Fill', nextText: 'Save' }],
    secondaryAction: testFunc ? { func: test, label: '' } : undefined,
    onNext: save,
    isLoading: isSaving || isTesting || disabled,
    ...stepperProps,
  };

  return (
    <Box
      component="form"
      sx={{ ...flex.col, height: '100%', ...sx, '.MuiTextField-root, .MuiAutocomplete-root': { maxWidth: 450 } }}
      noValidate
      onSubmit={e => e.preventDefault()}
      autoComplete="off"
      className={className}>
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          padding,
          '.MuiCollapse-root': isAllowedEdit || shouldDisableOpacityOnViewMode ? {} : formDisabledStyle,
        }}>
        <Box sx={{ ...flex.col, height: '100%', maxWidth: limitWidth ? '1200px' : 'unset', mx: 'auto', '> div': { flexShrink: 0 } }}>
          <Box sx={{ ...flex.justifyBetweenCenter, gap: 2, mb: title ? 2 : undefined }}>
            {title && (
              <Typography variant="h3" sx={{ ...flex.itemsCenter, gap: 2, whiteSpace: 'pre', margin: 0 }}>
                {title}
              </Typography>
            )}
            <Box sx={{ ...flex.row, gap: '8px' }}>
              {/* eslint-disable-next-line react/no-array-index-key */}
              {customGlobalActions?.map((action, index) => <React.Fragment key={`action-${index}`}>{action}</React.Fragment>)}{' '}
              {menuOptions ? <AvMenu options={menuOptions} /> : null}
            </Box>
          </Box>
          {children}
          {isDebugMode() ? <AvJsonViewer data={data} /> : null}
          {isAllowedEdit && <hr style={{ marginTop: 24, borderColor: 'transparent' }} />}
        </Box>
      </Box>
      {isAllowedEdit && <AvStepper onCancel={goBack} {...defaultStepperProps} />}
    </Box>
  );
};

export default AvForm;
