import React, { useEffect, useState } from 'react';
import { configureAmplify, exporter, newLoginFlow, shouldSkipUILogin } from '../utils/auth.utils';
import { AuthStorageKeys } from '../utils/localStorageAuthUtils.utils';
import { ProvideAuth } from './amplifyProvider';
import Login, { AuthConfiguration } from './Login';

interface AuthProviderProps {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [authConfig, setAuthConfig] = useState<AuthConfiguration | null>(
    JSON.parse(localStorage.getItem(AuthStorageKeys.authConfig) || 'null')
  );
  const [isCurrentlyLoggingIn, setIsCurrentlyLoggingIn] = useState<boolean>(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (newLoginFlow()) {
      const checkTokenInLocalStorage = event => {
        if (
          (event.key === AuthStorageKeys.accessTokenByAccountId && event.oldValue === null) || // login
          (event.key === AuthStorageKeys.authConfig && event.newValue === null) // logout
        ) {
          window.location.reload();
        }
      };
      window.addEventListener('storage', checkTokenInLocalStorage);
      return () => window.removeEventListener('storage', checkTokenInLocalStorage);
    }
  }, []);

  if (exporter() && shouldSkipUILogin()) {
    return <ProvideAuth isCurrentlyLoggingIn={isCurrentlyLoggingIn}>{children}</ProvideAuth>;
  }
  if (!authConfig) {
    return <Login setAuthConfig={setAuthConfig} setIsCurrentlyLoggingIn={setIsCurrentlyLoggingIn} />;
  }
  configureAmplify(authConfig);
  return <ProvideAuth isCurrentlyLoggingIn={isCurrentlyLoggingIn}>{children}</ProvideAuth>;
}
