import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import AvDialog from '../../components/AvDialog';
import AvForm from '../../components/AvForm';
import AvMenu, { renderOptionOfMenu } from '../../components/AvMenu';
import { flex } from '../../components/AvThemeProvider';
import AvTooltip from '../../components/AvTooltip';
import { IconVariant, ItemWithLogo } from '../../components/ItemWithLogo';
import RadioButton from '../../components/RadioButton';
import { ActionButton } from '../../components/Table/Utils';
import { useAvContext } from '../../context/AvContextProvider';
import { PAGE_PATHS, PermissionEntitiesNames } from '../../types';
import { useLocation, useNavigate, useParams } from '../../utils/AvRouter';
import { formDisabledStyle, iconSize, isDeepEqual, isNullOrUndefined, noop, setNestedValue } from '../../utils/Utils';
import { useAuditNavigation } from '../AuditLogs/hooks';
import { useRunAggregation } from '../ModelManagement/hooks';
import RulesList, { itemStyle, rulesListItemStyle } from '../Settings/GroupingRules/RulesList';
import { SettingType, useIsFieldSyncedToSetting } from '../Settings/hooks';
import SettingsNotSetBox from '../Settings/SettingsNotSetBox';
import { typeLabelMap } from '../Sources/Mapping/mapping.types';
import { EMPTY_RULES_MESSAGE } from './constants';
import EditConditionRule from './EditConditionRule';
import EditSourcePriorityRule from './EditSourcePriorityRule';
import { useCopyPasteRules, useRuleSet, useSaveRuleSet } from './hooks';
import RulePreview from './RulePreview';
import { RuleConfigType } from './types';
import { getEmptyRuleSet, unificationRulesListHeadCells } from './Utils';
import { ReactComponent as InfoFull } from '../../assets/colorful/Info Full.svg';
import { ReactComponent as Edit } from '../../assets/Edit.svg';
import { ReactComponent as Plus } from '../../assets/Plus.svg';

const editIcon = <Edit style={iconSize(18)} />;
const infoIcon = <InfoFull />;

export default function RuleSetGuard(props) {
  const { fieldName } = useParams();
  const navigate = useNavigate();
  const {
    getPathName,
    accountEntities: { fieldMap },
  } = useAvContext();
  if (!fieldName || !fieldMap[fieldName]) {
    setTimeout(() => navigate({ ...getPathName('field-not-found'), replace: true }));
    return null;
  }
  return <RuleSet {...props} />;
}
function RuleSet({ mode }: { mode?: 'create' | 'edit' }) {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { state } = useLocation();
  const isCreateField = useMemo(() => state?.isCreate, []);
  const {
    accountEntities: { fieldMap, ingressProjs, aggProjs },
  } = useAvContext();
  const { mutateAsync: run } = useRunAggregation();
  const { id: urlId, fieldName } = useParams();
  const [drawerHeight, setDrawerHeight] = useState(0);
  const [allowOverrideSPF, setAllowOverrideSPF] = useState(isCreateField);
  const fieldType = typeLabelMap[fieldMap[fieldName!].originalType.kind || fieldMap[fieldName!].type];
  const emptyRuleSet = getEmptyRuleSet(fieldName, fieldType, fieldMap[fieldName!]) as any;
  const [ruleSet, setRuleSet] = useState(emptyRuleSet);
  const [dirtyForm, setDirtyForm] = useState(false);
  const onChangeRuleSet = (newRuleSet, forcePristine = false) => {
    setRuleSet(newRuleSet);
    setDirtyForm(!forcePristine);
  };
  const projectionName = fieldMap[ruleSet.name].mainProjId.name;
  const configName = `${projectionName}.${fieldMap[ruleSet.name].name}`;
  const { data: syncSettings, isLoading: isSynched } = useIsFieldSyncedToSetting(configName);
  const { data: originalRuleSet = {}, isLoading: isLoadingSet } = useRuleSet(!isCreateField && fieldName, emptyRuleSet, data => {
    onChangeRuleSet(structuredClone(data), true);
    setRuleType(data.rules[0].ruleConfig.type);
    return data;
  });
  const { menuOptions = [] } = useAuditNavigation({
    id: originalRuleSet.id,
    path: `${PAGE_PATHS.DATA_UNIFICATION_ENTITIES}/${PAGE_PATHS.DATA_UNIFICATION_FIELDS}/set/${fieldName}`,
    hasIcon: true,
  });
  const isLoading = isLoadingSet || isSynched;
  const allowOverride =
    isNullOrUndefined(syncSettings) ||
    (syncSettings?.synced && syncSettings?.settingsType === SettingType.UnificationRules) ||
    allowOverrideSPF ||
    isLoading;

  const [ruleType, setRuleType] = useState(RuleConfigType.CONDITIONAL);
  const [switchingToType, setSwitchingToType] = useState<string | undefined>(undefined);

  const switchRuleType = useCallback(
    newRuleType => {
      const originalRuleConfig = originalRuleSet.rules?.[0].ruleConfig || {};
      if (newRuleType === originalRuleConfig.type) {
        onChangeRuleSet(originalRuleSet, true);
      } else {
        const newRuleSet = getEmptyRuleSet(fieldName, fieldType, fieldMap[fieldName!]);
        onChangeRuleSet({ ...originalRuleSet, ...newRuleSet }, true);
      }
      setRuleType(newRuleType);
    },
    [fieldMap, fieldName, fieldType, originalRuleSet]
  );

  const onChangeRuleType = useCallback(
    newRuleType => (dirtyForm ? setSwitchingToType(newRuleType) : switchRuleType(newRuleType)),
    [dirtyForm, switchRuleType]
  );

  const { mutateAsync: save, isPending: isSaving } = useSaveRuleSet(ruleSet);

  const editRuleArrayIndex = urlId ? ruleSet.rules?.findIndex(({ id }) => id === urlId) : undefined;

  const onActive = id =>
    navigate({
      to: `${mode ? '../../' : ''}${!id || id === urlId ? '' : `edit/$id`}`,
      params: prev => ({ ...prev, id }),
      search: prev => prev,
    });
  const onCreate = () => setTimeout(() => navigate({ to: `${urlId ? '../../' : 'create'}`, search: prev => prev }));
  const onClose = () => navigate({ to: mode === 'create' ? '../' : '../../', search: prev => prev });

  const headCellsForSources = [
    {
      label: 'Name',
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: ({ sourceName }) => <ItemWithLogo variant={IconVariant.sourcesMapByName} type={sourceName} />,
      width: '250px',
    },
    {
      label: '',
      formatter: ({ fieldNameWithAlias }) => fieldMap[fieldNameWithAlias].title,
      width: '300px',
    },
  ];

  const onChangeRules = rules => onChangeRuleSet(prev => ({ ...prev, rules }));
  const onChangeSources = sourcePriorityRules =>
    onChangeRuleSet(prev => ({
      ...prev,
      rules: sourcePriorityRules.length ? setNestedValue('0.ruleConfig.sourcePriority', prev.rules, sourcePriorityRules) : [prev.rules[1]],
    }));

  const copyPasteOptions = useCopyPasteRules(ruleSet, rules => {
    onChangeRules(rules);
    setRuleType(rules[0].ruleConfig.type);
  });

  const stepperProps = {
    steps: [
      {
        actionProps: {
          label: 'Save',
          options: [
            { label: 'Save', clickHandler: () => save(onClose) },
            {
              label: 'Save & Run',
              clickHandler: () =>
                save(() => {
                  run({ projIds: [fieldMap[ruleSet.name].mainProjId] });
                  onClose();
                }),
            },
          ],
        },
      },
    ],
    disableNext:
      isSaving ||
      (ruleType === RuleConfigType.CONDITIONAL && !ruleSet.name) ||
      (ruleType === RuleConfigType.SOURCE_PRIORITY && !ruleSet.rules[0].ruleConfig.sourcePriority?.length) ||
      isDeepEqual(ruleSet, originalRuleSet),
    disabledMessage: dirtyForm && !isSaving && EMPTY_RULES_MESSAGE,
    onCancel: () => {
      onChangeRuleSet(originalRuleSet);
      onClose();
    },
  };

  const conditionalRules = useMemo(
    () =>
      ruleType === RuleConfigType.SOURCE_PRIORITY
        ? ruleSet.rules.filter(({ ruleConfig }) => ruleConfig.type === RuleConfigType.CONDITIONAL)
        : ruleSet.rules,
    [ruleSet, ruleType]
  );
  return (
    <>
      <AvForm
        saveFunc={noop}
        sx={{ width: '100%', position: 'relative', '> div:first-of-type': { mb: `${drawerHeight}px` } }}
        limitWidth={false}
        title=""
        data={ruleSet}
        stepperProps={stepperProps}>
        <Box
          sx={{
            ...flex.justifyBetweenCenter,
            gap: 2,
            position: 'sticky',
            top: 0,
            zIndex: 2,
            ':before': {
              content: '""',
              width: 'calc(100% + 61px)',
              height: 'calc(100% + 40px)',
              position: 'absolute',
              left: '-29px',
              top: '-24px',
              backgroundColor: palette.colors.neutrals[150],
            },
          }}>
          <Typography variant="h3" sx={{ ...flex.justifyBetweenCenter, gap: 2, m: 0, zIndex: 0 }}>
            {fieldMap[ruleSet.name].title} Rule Set
            {!allowOverride && (
              <SettingsNotSetBox
                onClick={() => setAllowOverrideSPF(true)}
                settingsType={syncSettings.settingsType}
                isSynced={syncSettings.synced}
              />
            )}
          </Typography>
          <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
            <Button
              variant="contained"
              size="small"
              disabled={!allowOverride || ruleSet.rules[0].ruleConfig.type === RuleConfigType.SOURCE_PRIORITY}
              onClick={() => onCreate()}>
              <Plus style={{ marginLeft: '-6px' }} />
              New Rule
            </Button>
            <AvMenu options={[...menuOptions, ...copyPasteOptions]} renderOption={renderOptionOfMenu} disabled={!allowOverride} />
          </Box>
        </Box>
        <Box sx={[allowOverride ? {} : formDisabledStyle, { ...flex.col, gap: 2, mt: 3 }]}>
          {ingressProjs[aggProjs[projectionName].aggregates.name] && (
            <>
              <Box sx={{ ...flex.col, gap: 1 }}>
                <RuleTypeHeader />
                <RadioButton options={ruleTypeOptions} value={ruleType} onChange={onChangeRuleType} />
              </Box>
              <Divider />
            </>
          )}
          {ruleType === RuleConfigType.SOURCE_PRIORITY && (
            <Box sx={{ ...flex.col, gap: 2, '> div:first-of-type': { maxWidth: 600 } }}>
              {!!ruleSet.rules[0].ruleConfig.sourcePriority?.length && (
                <RulesList
                  rules={ruleSet.rules[0].ruleConfig.sourcePriority}
                  onChange={onChangeSources}
                  onEdit={() => onActive(ruleSet.rules[0].id)}
                  findDefaultFallbackRule={() => false}
                  headCells={headCellsForSources}
                  isLoading={isLoading}
                  active={urlId}
                  projectionName={fieldMap[fieldName!].mainProjId.name}
                  permissionConfiguration={{ resource: PermissionEntitiesNames.MODEL }}
                  buttonProps={{ clone: { isHidden: true } }}
                  variant="compact"
                />
              )}
              {ruleSet.rules[0].ruleConfig.expression && (
                <Box sx={{ ...flex.col, gap: 1, ml: 5.5 }}>
                  <Box sx={{ ...flex.itemsCenter, gap: 1, color: palette.colors.neutrals[700], fontWeight: 600, ml: 5 }}>
                    Result Expression
                  </Box>
                  <Box
                    sx={[{ ...flex.justifyBetweenCenter, pl: 4.5, pr: 3 }, rulesListItemStyle({ hasEdit: true, variant: 'compact' })]}
                    onClick={() => onActive(ruleSet.rules[0].id)}>
                    <Box sx={itemStyle('compact')}>{ruleSet.rules[0].ruleConfig.expression}</Box>
                    <Box sx={flex.row}>{ActionButton('Edit', () => onActive(ruleSet.rules[0].id), editIcon)}</Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {isLoading || (ruleSet.rules?.length && ruleType === ruleSet.rules[0].ruleConfig.type) ? (
            <Box
              sx={
                ruleType === RuleConfigType.SOURCE_PRIORITY
                  ? { ...flex.col, gap: 3, mt: 3, p: 3, border: `1px solid ${palette.colors.neutrals[400]}`, overflow: 'hidden' }
                  : {}
              }>
              {ruleType === RuleConfigType.SOURCE_PRIORITY && <Box sx={{ fontSize: 16, fontWeight: 600 }}>Default Rule</Box>}
              <RulesList
                rules={conditionalRules}
                onChange={onChangeRules}
                onEdit={onActive}
                findDefaultFallbackRule={(r, i) => conditionalRules.length === i + 1}
                headCells={unificationRulesListHeadCells(fieldMap, projectionName)}
                isLoading={isLoading}
                active={urlId}
                projectionName={fieldMap[fieldName!].mainProjId.name}
                permissionConfiguration={{ resource: PermissionEntitiesNames.MODEL }}
                showOrderColumn={ruleType === RuleConfigType.CONDITIONAL}
              />
            </Box>
          ) : (
            <Box sx={{ ...flex.center, p: 2, border: `1px solid ${palette.colors.neutrals[350]}`, whiteSpace: 'pre-wrap' }}>
              No rules yet{' '}
              <Button variant="link" onClick={() => onCreate()} sx={{ color: palette.primary.main }}>
                Create new Rule
              </Button>
            </Box>
          )}
        </Box>
        {fieldName && (
          <RulePreview
            ruleSet={ruleSet}
            fieldName={fieldName}
            entityTypeId={fieldMap[fieldName].entityTypeId}
            projId={fieldMap[fieldName].mainProjId}
            onExpand={setDrawerHeight}
          />
        )}
      </AvForm>
      {ruleType === RuleConfigType.SOURCE_PRIORITY &&
      ((urlId === ruleSet.rules[0].id && ruleSet.rules[0].ruleConfig.type === RuleConfigType.SOURCE_PRIORITY) || mode === 'create') ? (
        <EditSourcePriorityRule
          onClose={() => onClose()}
          isOpen={!!mode}
          projectionName={projectionName}
          field={ruleSet.name}
          rule={urlId ? ruleSet.rules[editRuleArrayIndex] : undefined}
          onFieldChange={val =>
            onChangeRuleSet(prev =>
              urlId ? setNestedValue(`rules.${editRuleArrayIndex}`, prev, val) : { ...prev, rules: prev.rules.toSpliced(-1, 0, val) }
            )
          }
        />
      ) : (
        <EditConditionRule
          onClose={() => onClose()}
          isOpen={!!mode}
          projectionName={projectionName}
          field={ruleSet.name}
          rule={urlId ? ruleSet.rules[editRuleArrayIndex] : undefined}
          ruleSet={ruleSet}
          onFieldChange={val =>
            onChangeRuleSet(prev =>
              urlId ? setNestedValue(`rules.${editRuleArrayIndex}`, prev, val) : { ...prev, rules: prev.rules.toSpliced(-1, 0, val) }
            )
          }
        />
      )}
      <SwitchToTypeDialog
        value={switchingToType}
        onApply={() => switchRuleType(switchingToType)}
        onClose={() => setSwitchingToType(undefined)}
      />
    </>
  );
}

const ruleTypeOptions = [
  { title: 'Conditions', value: RuleConfigType.CONDITIONAL, tooltip: 'Define business logic with ordered, dynamic if/then rules' },
  {
    title: 'Priority By Source',
    value: RuleConfigType.SOURCE_PRIORITY,
    tooltip: 'Determine which value is selected when multiple sources are available',
  },
];

const RuleTypeHeader = () => (
  <Box sx={{ ...flex.itemsCenter, gap: 1, fontSize: 16, fontWeight: 600 }}>
    <span>Rule Set Type</span>
    <AvTooltip
      variant="message"
      color="white"
      muiProps={{ PopperProps: { sx: { '.MuiTooltip-tooltip': { maxWidth: 'unset' } } }, placement: 'bottom-start' }}
      title={
        <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
          <Box sx={{ alignSelf: 'start' }}>{infoIcon}</Box>
          <Box sx={{ ...flex.col, gap: 2 }}>
            {ruleTypeOptions.map(({ title, tooltip }) => (
              <Box key={title}>
                <Box sx={{ fontWeight: 600 }}>{title}</Box>
                <div>{tooltip}</div>
              </Box>
            ))}
          </Box>
        </Box>
      }>
      <Box sx={flex.row}>{infoIcon}</Box>
    </AvTooltip>
  </Box>
);

interface SwitchToTypeDialogProps {
  value: string | undefined;
  onApply: () => void;
  onClose: () => void;
}
const SwitchToTypeDialog: React.FC<SwitchToTypeDialogProps> = ({ value, onApply, onClose }) => {
  const apply = () => {
    onApply();
    onClose();
  };

  return (
    <AvDialog
      open={!!value}
      onClose={onClose}
      titleVariant="error"
      noPadding
      title="Confirm Rule Set Type Change"
      footer={
        <Box sx={{ ...flex.justifyEndCenter, gap: 2 }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={apply} variant="contained">
            Continue
          </Button>
        </Box>
      }>
      <Box sx={{ px: 3, ...flex.col, gap: 3 }}>
        <span>Switching to a different rule set type will discard all unsaved changes in the current configuration.</span>
        <span>Are you sure you want to proceed?</span>
      </Box>
    </AvDialog>
  );
};
