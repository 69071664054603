import { ConditionType, getDatePickerFromQueryObject, getStringConditionFromDatePicker } from '../components/filters/Utils';
import { Filter } from '../types/filter.types';
import { SortDir } from '../views/CustomDashboards/types';
import { FieldType, FieldTypeEnum, SchemaTypes, typeLabelMap } from '../views/Sources/Mapping/mapping.types';
import { filtersFieldToIgnore } from '../views/Tickets/ticket.types';
import { severityWithNone } from './severity.utils';
import { extractKeyValues, isDeepEqual } from './Utils';

export const filterToExpression = (typeMap, filterObj) => {
  const operands = Object.keys(filterObj)
    .filter(
      fieldName =>
        !filtersFieldToIgnore.includes(fieldName) && (Array.isArray(filterObj[fieldName]) || typeof filterObj[fieldName] === 'string')
    )
    .map(fieldName => {
      // TODO: Remove when AVA-12635 is implemented.
      // When null isn't first in the array it is parsed incorrectly
      const hasNullString = filterObj[fieldName].includes('null');
      const sortedFilterValues = hasNullString ? [null, ...filterObj[fieldName].filter(val => val !== 'null')] : filterObj[fieldName];
      const condition = parseURLCondition(fieldName, sortedFilterValues);

      if (FieldTypeEnum[typeMap[fieldName]] === FieldType.Boolean) {
        return sortedFilterValues.length
          ? createBooleanExpressionObj(fieldName, sortedFilterValues, typeMap)
          : { expression: { fieldName, value: {} } };
      }

      if (sortedFilterValues[0] !== null && !condition) {
        return { expression: { fieldName } };
      }

      if (condition === sortedFilterValues.toString()) {
        return createExpressionObj(fieldName, { operator: 'in', value: { values: sortedFilterValues } }, typeMap);
      }
      const conditionObj = condition && JSON.parse(condition);
      const operator = condition && Object.keys(conditionObj)[0];
      return !operator
        ? createExpressionObj(fieldName, { operator: 'equals', value: conditionObj }, typeMap)
        : !['and', 'or'].includes(operator)
          ? createExpressionObj(fieldName, conditionObj, typeMap)
          : {
              [operator]: {
                operands: conditionObj[operator].map(operand => createExpressionObj(fieldName, operand, typeMap)),
              },
            };
    });
  return operands.length ? { and: { operands } } : {};
};

export const isEmptyFilter = (filter?: Filter) =>
  filter?.and?.operands?.length === 1 && filter?.and?.operands[0]?.expression?.fieldName === null;

export const updateFilterInExpression = (typeMap, newFilter, filterExp) =>
  filterToExpression(typeMap, {
    ...expressionToFilter(typeMap, filterExp),
    ...newFilter,
  });
export const expressionToFilter = (typeMap, filterExp) =>
  filterExp.expression
    ? createFilterObj(typeMap, {}, filterExp)
    : filterExp.and?.operands.reduce((obj, exp) => createFilterObj(typeMap, obj, exp), {}) || {};

export const createExpressionObj = (fieldName, conditionObj, typeMap) => {
  const [operator, resolution] = conditionObj?.operator?.split('|') || [];
  const baseConditionObj = conditionObj?.operator ? { ...conditionObj, operator } : conditionObj;
  const underlying = { fieldName, ...getExpressionCondition(baseConditionObj, typeLabelMap[typeMap[fieldName]]) };
  return { expression: resolution ? { arrayCondition: { underlying, resolution } } : underlying };
};

export const addCustomFiltersAsExpression = ({ expressionFiltersObj, operand, operator, fields, value, fieldTypeMap }) => {
  if (!value) {
    return expressionFiltersObj;
  }
  const adddedFiltersObject = fields.reduce(
    (acc, field) => ({
      ...acc,
      [operand]: {
        operands: [...acc[operand].operands, createExpressionObj(field, { operator, value }, fieldTypeMap)],
      },
    }),
    { [operand]: { operands: [] } }
  );

  return { ...expressionFiltersObj, and: { operands: [...(expressionFiltersObj?.and?.operands || []), adddedFiltersObject] } };
};

const createBooleanExpressionObj = (fieldName, values, typeMap) => ({
  or: { operands: values.map(val => createExpressionObj(fieldName, val, typeMap)) },
});

const emptyCondition = { and: [{}] };

const createFilterObj = (typeMap, filterObj, filterExp: Filter) => {
  const exp = Object.values(filterExp)[0];
  const { fieldName } =
    // @ts-ignore
    exp?.expression || filterExp.expression || exp.operands[0].expression?.arrayCondition?.underlying || exp.operands[0].expression;
  const filterCondition = getFilterCondition(typeLabelMap[typeMap[fieldName]], filterExp, typeMap);
  return {
    ...filterObj,
    [fieldName]:
      !filterCondition || isDeepEqual(emptyCondition, filterCondition)
        ? []
        : Array.isArray(filterCondition.value)
          ? filterCondition.value
          : [encodeURIComponent(JSON.stringify(filterCondition))],
  };
};

export function getExpressionCondition(operand, type) {
  if ([SchemaTypes.DATE, SchemaTypes.DATETIME].includes(type)) {
    return getStringConditionFromDatePicker(operand);
  }

  if (type === SchemaTypes.BOOL) {
    const hasValue = JSON.parse(operand) !== null;
    return {
      boolCondition: {
        [hasValue ? 'equals' : 'empty']: hasValue ? operand : {},
      },
    };
  }
  const condition = ConditionType[type] || 'stringCondition';

  return {
    [condition]: {
      [operand.operator]: ['empty', 'notEmpty'].includes(operand.operator)
        ? {}
        : Array.isArray(operand.value?.values)
          ? {
              values: operand.value.values.filter(v => v).map(v => (condition === 'stringCondition' ? v.toString() : v)),
            }
          : operand.value,
      ...(Array.isArray(operand.value?.values) && operand.value.values.includes(null) && { addNullFilter: true }),
    },
  };
}

function getFilterCondition(type, filterExp, typeMap) {
  if ([SchemaTypes.DATETIME || SchemaTypes.DATE].includes(type)) {
    return getDatePickerFromQueryObject(filterExp);
  }

  if (type === SchemaTypes.BOOL) {
    if (!filterExp.expression?.boolCondition && !filterExp.or) {
      return { value: [] };
    }

    if (filterExp.expression) {
      const key = Object.keys(filterExp.expression.boolCondition)[0];
      return {
        value: key === 'notEmpty' ? [true, false] : [key === 'equals' ? Object.values(filterExp.expression.boolCondition)[0] : null],
      };
    }
    return {
      value: filterExp.or.operands
        .map(({ expression }) => expression.boolCondition)
        .map(boolCondition => (Object.keys(boolCondition)[0] === 'equals' ? Object.values(boolCondition)[0] : null)),
    };
  }

  const typeCondition = ConditionType[type] || 'stringCondition';

  const getFilterObj = ({ expression }) => {
    const exp = expression.arrayCondition?.underlying[typeCondition] || expression[typeCondition];
    const operator = exp && Object.keys(exp)[0];
    const resolution = expression.arrayCondition?.resolution || '';
    return exp
      ? Array.isArray(exp.in?.values)
        ? { operator: 'in', value: [...exp.in.values.filter(v => v), ...(exp.addNullFilter ? [null] : [])] }
        : {
            operator: `${operator}${resolution ? `|${resolution}` : ''}`,
            value: type === SchemaTypes.NUMBER ? parseInt(exp[operator]) : exp[operator],
          }
      : {};
  };

  const operator = Object.keys(filterExp)[0];
  if (operator === 'not') {
    return getFilterCondition(type, filterExp.not, typeMap);
  }
  if (operator === 'expression') {
    if (filterExp.expression[typeCondition] && Array.isArray(filterExp.expression[typeCondition].in?.values)) {
      return getFilterObj(filterExp);
    }
    return { and: [getFilterObj(filterExp)] };
  }
  return {
    [operator]: filterExp[operator].operands.map(v => (v.expression ? getFilterObj(v) : expressionToFilter(typeMap, v))),
  };
}

const operandOrAndFilter = (filter: Filter, inclusive = false) => {
  const operands = ['or', 'and'];
  return (
    (inclusive
      ? (filter.and || filter.or)?.operands.filter(op => operands.includes(Object.keys(op)[0]))
      : (filter.and || filter.or)?.operands.filter(op => !operands.includes(Object.keys(op)[0]))) || []
  );
};
export const cleanEmptyFilters = (filter: Filter | undefined): Filter | undefined =>
  filter &&
  Object.keys(filter).length &&
  [
    ...extractKeyValues(operandOrAndFilter(filter), 'expression').filter(
      ({ fieldName, value, ...condition }) => Object.values(condition).length
    ),
    ...operandOrAndFilter(filter, true),
  ].length
    ? {
        [Object.keys(filter)[0]]: {
          operands: [
            ...extractKeyValues(operandOrAndFilter(filter), 'expression')
              .filter(({ fieldName, value, ...condition }) => Object.values(condition).length)
              .map(val => ({ expression: val })),
            ...operandOrAndFilter(filter, true),
          ],
        },
      }
    : undefined;

const parseURLCondition = (fieldName, sortedFilterValues) => {
  try {
    return decodeURIComponent(sortedFilterValues);
  } catch (e) {
    console.warn(`skipped parsing of filter "${fieldName}"`, e);
    return '';
  }
};

const defaultSortBySeverityInput: { data: { [key: string]: string }[]; severityFieldName: string; key: string; direction?: SortDir } = {
  data: [],
  severityFieldName: '',
  key: 'value',
  direction: SortDir.DESC,
};

export const sortBySeverity = (defaultSortBySeverity = defaultSortBySeverityInput) => {
  if (defaultSortBySeverity.severityFieldName && checkIfSeverityFilter(defaultSortBySeverity.severityFieldName)) {
    return defaultSortBySeverity.data.toSorted(({ [defaultSortBySeverity.key]: a }, { [defaultSortBySeverity.key]: b }) =>
      defaultSortBySeverity.direction === SortDir.ASC
        ? severityWithNone.indexOf(b) - severityWithNone.indexOf(a)
        : severityWithNone.indexOf(a) - severityWithNone.indexOf(b)
    );
  }

  return defaultSortBySeverity.data;
};

export function checkIfSeverityFilter(severityFieldName: string) {
  return severityFieldName.split('.')[1] === 'severity';
}
