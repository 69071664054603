import React from 'react';
import { IconButton, Slide } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ReactComponent as CheckFull } from '../assets/CheckFull.svg';
import { ReactComponent as ErrorFull } from '../assets/colorful/Fail_full.svg';
import { ReactComponent as InfoFull } from '../assets/Info.svg';
import { ReactComponent as WarningFull } from '../assets/WarningFull.svg';
import { ReactComponent as X } from '../assets/X.svg';

const iconVariants = {
  success: <CheckFull />,
  error: <WarningFull />,
  warning: <WarningFull />,
  info: <InfoFull />,
  static: <ErrorFull style={{ width: 22, height: 22 }} />,
};

export default function AvNotificationProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  const notificationRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (e, key) => {
    e.stopPropagation();
    notificationRef.current?.closeSnackbar(key);
  };

  const dismiss = key => (
    <IconButton aria-label="close" color="inherit" onClick={e => onClickDismiss(e, key)}>
      <X />
    </IconButton>
  );
  return (
    <SnackbarProvider
      maxSnack={2}
      preventDuplicate
      domRoot={document.body}
      ref={notificationRef}
      iconVariant={iconVariants}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={dismiss}
      TransitionComponent={Slide}>
      {children}
    </SnackbarProvider>
  );
}
