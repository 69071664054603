import React, { useRef } from 'react';
import { alpha, Box, Button, styled, TextField, useTheme } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { useAvContext } from '../../context/AvContextProvider';
import { FeatureFlags } from '../../types';
import { flex } from '../AvThemeProvider';
import RadioButton from '../RadioButton';
import Select from '../Select';
import { RelativeDatePeriod, RelativeDateType } from './AvDateRangePicker.constants';
import { DateRangeValueType, PredefinedPreset, Preset } from './types';
import { captionComponent, customPreset, getPresets, stringDefinition } from './utils';
import { ReactComponent as Arrow } from '../../assets/Arrow Down.svg';

export const ArrowLeft = styled(Arrow)`
  transform: rotate(90deg);
  width: 14px;
`;
export const ArrowRight = styled(Arrow)`
  transform: rotate(-90deg);
  width: 14px;
`;

export const NotSetOption = ({ value, onChange }: { value: string; onChange: (v) => void }) => (
  <Box sx={{ ...flex.col, py: 1, px: 2 }}>
    <RadioButton
      options={[
        { title: 'Date Range', value: 'relative' },
        { title: 'Not set', value: 'empty' },
      ]}
      value={value}
      onChange={onChange}
    />
  </Box>
);

export const RelativeDateOptions = ({
  allowFutureDates,
  isNull,
  isPreset,
  onPresetClick,
  preset,
  predefinedPresets,
}: {
  allowFutureDates: boolean;
  isNull: boolean;
  isPreset: boolean;
  onPresetClick: (preset) => void;
  preset?: Preset;
  predefinedPresets?: PredefinedPreset[];
}) => {
  const theme = useTheme();

  const isCustomPreset =
    !isPreset ||
    (isPreset &&
      (predefinedPresets?.length ? predefinedPresets : getPresets({ allowFutureDates })).every(
        p => stringDefinition(p.definition) !== stringDefinition(preset)
      ));
  return (
    <Box sx={{ ...flex.colJustifyCenter, gap: 1, py: 2, pl: 2, pr: 2, ...(!allowFutureDates && flex.justifyStart) }}>
      {(predefinedPresets || [...getPresets({ allowFutureDates }), { title: customPreset }]).map(p => (
        <Button
          key={p.title}
          disabled={isNull}
          size="small"
          sx={
            (isCustomPreset && p.title === customPreset) || (isPreset && stringDefinition(preset) === stringDefinition(p.definition))
              ? {
                  justifyContent: 'flex-start',
                  fontWeight: 400,
                  pointerEvents: 'none',
                  color: theme.palette.primary.main,
                  background: alpha(theme.palette.colors.primary[500], 0.12),
                }
              : { justifyContent: 'flex-start', fontWeight: 400 }
          }
          onClick={() => onPresetClick(p)}>
          {p.title}
        </Button>
      ))}
    </Box>
  );
};

export const DynamicRangeSelect = ({
  inputFocus,
  isNull,
  disablePortal,
  onBlur,
  updatePreset,
  preset,
  predicate,
  showMinutesAndHours = false,
}: {
  isNull: boolean;
  disablePortal: boolean;
  predicate: ({ value }: { value: any }) => boolean;
  preset?: Preset;
  inputFocus: boolean;
  onBlur: () => void;
  updatePreset: (key: string, value: any) => void;
  showMinutesAndHours?: boolean;
}) => {
  const theme = useTheme();
  const countInputRef = useRef<any>();
  const { featureFlags } = useAvContext();
  const isRelativeBeforeEnabled = featureFlags[FeatureFlags.RelativeBeforeDate];

  const periodOptions = [
    ...(showMinutesAndHours
      ? [
          { title: 'Minutes', value: RelativeDatePeriod.minutes },
          { title: 'Hours', value: RelativeDatePeriod.hours },
        ]
      : []),
    { title: 'Days', value: RelativeDatePeriod.days },
    { title: 'Weeks', value: RelativeDatePeriod.weeks },
    { title: 'Months', value: RelativeDatePeriod.months },
  ];

  const dateTypeOptions = [
    { title: 'Next', value: RelativeDateType.next },
    { title: 'Last', value: RelativeDateType.last },
    { title: 'Previous', value: RelativeDateType.previous },
    { title: 'Before', value: RelativeDateType.before },
  ];

  const isNonRelativeBefore = !isRelativeBeforeEnabled && preset?.type === RelativeDateType.before;
  return (
    <Box
      // @ts-ignore
      sx={{
        ...flex.itemsCenter,
        gap: 1,
        pl: '22px',
        ...(isNull ? { display: 'none' } : {}),
        '.MuiPaper-root': theme?.components?.MuiAutocomplete?.styleOverrides?.paper,
      }}>
      <Box sx={{ pr: '2px' }}>Dynamic Range:</Box>
      <Select
        muiProps={{ disablePortal }}
        style={{ width: 124 }}
        isRequired
        size="small"
        options={dateTypeOptions.filter(predicate)}
        value={preset?.type}
        onChange={v => updatePreset('type', v)}
      />
      <TextField
        sx={{ width: 72 }}
        autoComplete="off"
        type="number"
        focused={inputFocus}
        onBlur={onBlur}
        inputProps={{ min: 1 }}
        required
        ref={countInputRef}
        size="small"
        value={preset?.count || ''}
        onChange={e => updatePreset('count', +e.target.value)}
        disabled={isNonRelativeBefore}
      />
      <Select
        muiProps={{ disablePortal }}
        style={{ width: 124 }}
        isRequired
        size="small"
        options={periodOptions}
        value={preset?.period}
        onChange={v => updatePreset('period', v)}
        disabled={isNonRelativeBefore}
      />
    </Box>
  );
};

export const CalenderPicker = ({
  date,
  isRange,
  onSelect,
  month,
  onMonthChange,
  allowFutureDates,
  allowPastDatesOnDayPicker,
  timeOptions,
  onChangeTimeOptions,
}: {
  onSelect: (v) => void;
  date: DateRangeValueType;
  isRange: boolean;
  month: Date | null;
  onMonthChange: (value: Date) => void;
  allowFutureDates: boolean;
  allowPastDatesOnDayPicker: boolean;
  timeOptions: any[] | undefined;
  onChangeTimeOptions: (v) => void;
}) => (
  <>
    <DayPicker
      components={
        {
          CaptionLabel: captionComponent,
          IconLeft: ArrowLeft,
          IconRight: ArrowRight,
        } as any
      }
      onSelect={onSelect}
      selected={date === null ? undefined : isRange ? date : date?.from}
      month={month === null ? undefined : month}
      onMonthChange={onMonthChange}
      disabled={
        allowPastDatesOnDayPicker
          ? allowFutureDates
            ? undefined // All dates are allowed
            : date => differenceInCalendarDays(date, new Date()) > 0 // Allow past dates, disable future dates
          : allowFutureDates
            ? date => differenceInCalendarDays(date, new Date()) < 0 // Allow future dates, disable past dates
            : date => differenceInCalendarDays(date, new Date()) !== 0 // Enable only today
      }
      mode={(isRange ? 'range' : 'single') as any}
      numberOfMonths={isRange ? 2 : 1}
    />
    {timeOptions && (
      <Box sx={{ ...flex.center, gap: 1, pt: 1 }}>
        <Box>Time:</Box>
        <Select
          style={{ width: 150 }}
          onChange={onChangeTimeOptions}
          isRequired
          options={timeOptions}
          size="small"
          value={`${date?.from.getHours()}:${date?.from.getMinutes()}`}
        />
      </Box>
    )}
  </>
);
