import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../utils/queryKey';

const GET_STATIC_LISTS = gql`
  query getAllDataSourcesTypes {
    getAllDataSourcesTypes {
      type
      name
      category
      iconUrl
      suppressionFilter: suppressionFilterV2 {
        filter
        include
      }
      authenticationSourceType {
        type
        name
        iconUrl
        authenticationType
        authenticationProviderType
        useAuthUrl
        authenticationEntityTypes
      }
      agingConfigs {
        daysToClose
        isRelationBased
        agingRules {
          filter
          daysToClose
        }
      }
      scheduleFrequency {
        incrementalCron
        fullCron
        supportedSchedules
      }
      tag
    }
    getDataSourceDetailsByAccountId {
      mappedSourceName
      iconUrl
    }
    getAllIntegrationTypes {
      type
      name
      authenticationSourceTypeName
      category
      supports {
        create
        sync
        webhook
        schema
        comments
        attachments
        manualAttach
      }
    }
    getAllAuthenticationSourcesTypes {
      type
      name
      iconUrl
      authenticationType
      authenticationProviderType
      useAuthUrl
      authenticationEntityTypes
      kbLink
    }
  }
`;

const convertArrToObj = (obj, item) => ({ ...obj, [item.type]: item });
const convertArrToObjByName = (obj, item) => ({ ...obj, [item.name]: item });

const categorizeSourceOptions = (sourceOptions, connected) => {
  const flatTypes = new Set(connected.map(({ mappedSourceName }) => mappedSourceName));
  return sourceOptions
    .map(opt => ({ ...opt, group: flatTypes.has(opt.name) ? 'Connected' : 'Not Connected' }))
    .toSorted((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};

export const cachedStatic = {
  sourcesObj: { sourcesMap: {}, sourcesMapByName: {}, sourceOptions: [] },
  destinationsObj: { destinationsMap: {}, destinationsOptions: [] },
};

export const useTypeNameMap = ({ api, enableRequests }) =>
  useQuery({
    queryKey: [QueryKey.sourceTypeList],
    queryFn: () =>
      api(GET_STATIC_LISTS).then(QueryData => {
        const data = QueryData?.data;
        if (!data) {
          return {};
        }
        const sources = [
          ...data.getAllDataSourcesTypes,
          ...data.getDataSourceDetailsByAccountId
            .filter(({ mappedSourceName }) => !data.getAllDataSourcesTypes.find(({ name }) => name === mappedSourceName))
            .map(({ mappedSourceName: name, iconUrl, kbLink }) => ({ type: name, name, iconUrl, kbLink })),
        ];
        return {
          ...cachedStatic,
          sourcesObj: {
            sourcesMap: sources.reduce(convertArrToObj, {}),
            sourcesMapByName: sources.reduce(convertArrToObjByName, {}),
            sourceOptions: categorizeSourceOptions(sources, data.getDataSourceDetailsByAccountId),
          },
          destinationsObj: {
            destinationsMap: data.getAllIntegrationTypes.reduce(convertArrToObj, {}),
            destinationsOptions: data.getAllIntegrationTypes,
          },
          authenticationSourcesObj: {
            authTypesMap: data.getAllAuthenticationSourcesTypes.reduce(convertArrToObj, {}),
            authTypesMapByName: data.getAllAuthenticationSourcesTypes.reduce(convertArrToObjByName, {}),
            authTypesOptions: data.getAllAuthenticationSourcesTypes,
          },
        };
      }),
    enabled: enableRequests,

    retry: 10,
    retryDelay: 1000,
  });
