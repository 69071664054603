import { useLocation } from '@tanstack/react-router';

export const getAccountIdFromPathName = pathname => pathname?.split?.('/')?.[1];
export const getAppIdFromPathName = pathname => pathname?.split?.('/')?.[2];

export const getPathNameByAccount =
  (accountId, selectedApp: string) =>
  (path, rest = '', newSelectedApp = selectedApp) => ({
    to: `/${accountId}/${newSelectedApp}/${path}${rest}`,
    params: prev => ({ ...prev, accountId }),
  });

export const useAccountId = () => {
  const location = useLocation();
  const id = getAccountIdFromPathName(location.pathname);
  return id === 'error-process-request' ? '' : id;
};
