import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Divider, Paper, Popper, SxProps, Theme, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as monaco from 'monaco-editor';
import { flex } from '../../components/AvThemeProvider';
import AvTooltip from '../../components/AvTooltip';
import CodeEditor from '../../components/CodeEditor';
import { macroFuncOptions } from '../../components/CodeEditor/CELSyntax';
import Select from '../../components/Select';
import { Usages } from '../../context/context.type';
import { iconSize } from '../../utils/Utils';
import { innerLabelStyle } from '../ModelManagement/FieldInfo';
import { isBetweenDoubleCurly, useFieldSuggestions } from '../Settings/GroupingRules/hooks';
import { ReactComponent as Function } from '../../assets/Function.svg';
import { ReactComponent as SortDown } from '../../assets/Sort Down.svg';

const ArrowIcon = isUp => <SortDown style={{ ...iconSize(12), transform: `rotate(${isUp ? -180 : 0}deg)` }} />;
const popperHeight = 270;
const keyboardKeys = {
  ArrowDown: 40,
  ArrowUp: 38,
  Enter: 13,
};
const triggerKeydownEvent = (element, key) =>
  element.dispatchEvent(new KeyboardEvent('keydown', { key, code: key, bubbles: true, cancelable: true }));

const operators = [
  { symbol: '<', tooltip: 'Less than' },
  { symbol: '>', tooltip: 'Greater than' },
  { symbol: '<=', tooltip: 'Less than or equal to' },
  { symbol: '>=', tooltip: 'Greater than or equal to' },
  { symbol: '-', tooltip: 'Subtraction' },
  { symbol: '+', tooltip: 'Addition' },
  { symbol: '/', tooltip: 'Division' },
  { symbol: '*', tooltip: 'Multiplication' },
  { symbol: '==', tooltip: 'Equal to' },
  { symbol: 'in', tooltip: 'In list' },
  { symbol: '&&', tooltip: 'Logical AND' },
  { symbol: '||', tooltip: 'Logical OR' },
  { symbol: '!', tooltip: 'Logical NOT' },
];
const curleyLength = '{{'.length;

type OperatorOptionProps = { symbol: ReactNode; tooltip?: string; sx?: SxProps; small?: boolean };
const OperatorOption = ({ symbol, tooltip, sx = {}, small = false }: OperatorOptionProps) => (
  <AvTooltip title={tooltip}>
    <Box
      sx={{
        ...flex.center,
        height: small ? 20 : 24,
        minWidth: small ? 20 : 24,
        whiteSpace: 'nowrap',
        px: small ? 0.5 : 1,
        borderRadius: small ? '6px' : '8px',
        backgroundColor: ({ palette }) => palette.colors.neutrals[300],
        color: ({ palette }) => palette.colors.neutrals[600],
        fontSize: small ? 10 : 11,
        fontWeight: 600,
        cursor: 'default',
        ...sx,
      }}>
      {symbol}
    </Box>
  </AvTooltip>
);

const getWordIndexesBetweenCurley = (text: string, cursorPosition: number) => {
  const prefix = text.substring(0, cursorPosition - 1);
  const suffix = text.substring(cursorPosition - 1);
  return { start: prefix.lastIndexOf('{{') + curleyLength, end: prefix.length + suffix.indexOf('}}') };
};
const getWordBetweenCurley = (text: string, cursorPosition: number) => {
  const { start, end } = getWordIndexesBetweenCurley(text, cursorPosition);
  return text.substring(start, end).trim();
};

const generateFieldOperation = (model, position, suggestion) => {
  const { start, end } = getWordIndexesBetweenCurley(model.getLineContent(position.lineNumber), position.column);
  const range = new monaco.Range(position.lineNumber, start + 1 - curleyLength, position.lineNumber, end + 1 + curleyLength);
  return { identifier: { major: 1, minor: 1 }, range, text: `{{${suggestion.insertText}}} `, forceMoveMarkers: true };
};

const generateFunctionOperation = (model, position, suggestion) => {
  const wordStartColumn = model.getWordAtPosition(position)?.startColumn || position.column;
  const range = new monaco.Range(position.lineNumber, wordStartColumn, position.lineNumber, position.column);
  return { identifier: { major: 1, minor: 1 }, range, text: `${suggestion.label}()`, forceMoveMarkers: true };
};

const CustomSuggestWidget = ({ editor, position, suggestions, anchorEl, width, descriptionSuggestion, moreOperators }) => {
  const selectRef = useRef<HTMLElement>(null);
  const [hoveredSuggestion, setHoveredSuggestion] = useState(null);
  const handleSuggestionClick = suggestion => {
    const model = editor.getModel();
    const op = isBetweenDoubleCurly(model, position)
      ? generateFieldOperation(model, position, suggestion)
      : generateFunctionOperation(model, position, suggestion);

    model.pushEditOperations([], [op], () => null);
    editor.focus();
    if (suggestion.kind === monaco.languages.CompletionItemKind.Function) {
      const newPos = editor.getPosition();
      editor.setPosition({ ...newPos, column: newPos.column - 1 });
    }
  };

  const candidateSuggestion = descriptionSuggestion || hoveredSuggestion || suggestions[0];
  const isMethodSuggested = candidateSuggestion?.kind === monaco.languages.CompletionItemKind.Function;

  useEffect(() => {
    const handleKeyDown = event => {
      const targetInput = selectRef.current?.querySelector('input');
      if (targetInput === event.target) {
        return;
      }
      if (event.key in keyboardKeys) {
        triggerKeydownEvent(targetInput, event.key);
        event.stopPropagation();
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown, true);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [hoveredSuggestion, suggestions]);

  return (
    <Popper
      open={Boolean(position)}
      anchorEl={anchorEl}
      placement="bottom-start"
      sx={{ width: isMethodSuggested ? width : undefined, zIndex: ({ zIndex }) => zIndex.modal + 2 }}>
      <Paper onMouseDown={e => e.preventDefault()} onMouseLeave={() => setHoveredSuggestion(null)}>
        <Box sx={flex.row}>
          <Box
            ref={selectRef}
            sx={{
              ...(isMethodSuggested ? { width: '30%', pt: 1, pl: 1, height: popperHeight + 8 * 3 } : { width: '100%' }),
              '.MuiAutocomplete-listbox': { maxHeight: popperHeight },
              '.MuiAutocomplete-paper': { boxShadow: 'none' },
              '.MuiTextField-root': { display: 'none' },
            }}>
            <Select
              variant="filter"
              showInput={false}
              showOnlyAutoComplete
              onChange={(label, suggestion) => handleSuggestionClick(suggestion)}
              muiProps={{ onHighlightChange: (e, suggestion) => setHoveredSuggestion(suggestion) }}
              options={suggestions}
              getValueFunc={({ label }) => label}
              getLabelFunc={({ label }) => label}
              size="xSmall"
            />
          </Box>
          {isMethodSuggested && (
            <Box sx={{ ...flex.col, gap: 1.5, width: '70%', p: 2 }}>
              <Box
                sx={{
                  backgroundColor: ({ palette }) => palette.colors.neutrals[150],
                  p: 2,
                  flex: 1,
                  ...flex.col,
                  gap: 1,
                }}>
                <span>
                  <strong>{candidateSuggestion?.label}</strong>({candidateSuggestion?.paramTypes})
                </span>
                <Box sx={{ fontSize: 12, flexGrow: 1 }}>
                  <div>Output Type: {candidateSuggestion?.detail}</div>
                  <div>{candidateSuggestion?.details.whatDoesItDo}</div>
                </Box>
                {candidateSuggestion?.details.example && (
                  <Box
                    sx={{ fontSize: 12, p: 1, border: theme => `1px solid ${theme.palette.colors.neutrals[400]}`, whiteSpace: 'pre-line' }}>
                    <div>{candidateSuggestion?.details.example.expression}</div>
                    <div>= {candidateSuggestion?.details.example.result}</div>
                  </Box>
                )}
                <Box sx={{ ...flex.col, gap: 1, mt: 2.5 }}>
                  <Typography variant="h7">Operations & References</Typography>
                  <Box sx={{ ...flex.wrap, gap: 0.5 }}>
                    {operators.map(({ symbol, tooltip }) => (
                      <OperatorOption key={symbol} symbol={symbol} tooltip={tooltip} />
                    ))}
                    <Divider orientation="vertical" sx={{ height: '70%', alignSelf: 'center', mx: 0.5 }} />
                    {moreOperators}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            ...flex.itemsCenter,
            px: 1.5,
            py: 1,
            whiteSpace: 'pre-wrap',
            fontSize: 12,
            borderTop: ({ palette }) => `1px solid ${palette.colors.neutrals[350]}`,
          }}>
          <OperatorOption symbol="Enter" small sx={{ px: 1 }} /> to accept
          <OperatorOption symbol={ArrowIcon(false)} small sx={{ ml: 1.5, mr: 0.5 }} />
          <OperatorOption symbol={ArrowIcon(true)} small /> to navigate
        </Box>
      </Paper>
    </Popper>
  );
};

const filterSuggestions = (options, currentWord) => options.filter(({ label }) => label.toLowerCase().includes(currentWord.toLowerCase()));

const CELExpressionInput = ({
  projectionName,
  usage = Usages.EVAL,
  funcOptions = macroFuncOptions,
  fieldOptions,
  onChange,
  value,
  error,
  size = 'small',
}: {
  projectionName: string;
  usage?: Usages;
  funcOptions?: typeof macroFuncOptions;
  fieldOptions?: { value: string; title: string }[];
  onChange: (value) => void;
  value: string | undefined;
  error?: string;
  size?: 'small' | 'medium';
}) => {
  const fieldSuggestions = useFieldSuggestions(projectionName, usage, fieldOptions);
  const editorRef = useRef(null);
  const position = useRef({});
  const [suggestions, setSuggestions] = useState(funcOptions);
  const [descriptionSuggestion, setDescriptionSuggestion] = useState<(typeof funcOptions)[0] | null>(null);
  const anchorEl = useRef<HTMLElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleEditorDidMount = editor => {
    editorRef.current = editor;

    editor.onDidFocusEditorWidget(() => setIsFocused(true));
    editor.onDidBlurEditorWidget(() => setIsFocused(false));

    const isInString = (text, cursorPosition) =>
      monaco.editor
        .tokenize(text, 'cel')[0]
        .findLast(({ offset }) => offset < cursorPosition)
        ?.type.startsWith('string.');

    editor.onDidChangeCursorPosition(e => {
      if (e.position.lineNumber === 1) {
        const model = editor.getModel();
        const betweenDoubleCurly = isBetweenDoubleCurly(model, e.position);
        const text = model.getLineContent(e.position.lineNumber);
        const word = betweenDoubleCurly ? getWordBetweenCurley(text, e.position.column) : model.getWordAtPosition(e.position)?.word || '';
        setSuggestions(
          isInString(text, e.position.column) ? [] : filterSuggestions(betweenDoubleCurly ? fieldSuggestions : funcOptions, word)
        );
        const functionSuggestionIndex = !betweenDoubleCurly && model.bracketPairs.findMatchingBracketUp(')', e.position);
        const functionName =
          functionSuggestionIndex &&
          model.getWordAtPosition({ lineNumber: functionSuggestionIndex.startLineNumber, column: functionSuggestionIndex.startColumn - 1 })
            ?.word;
        setDescriptionSuggestion(functionSuggestionIndex ? funcOptions.find(({ label }) => label === functionName)! : null);
        position.current = e.position;
      }
    });

    editor.onDidChangeModelContent(({ eol }) => {
      const text = editor.getValue();
      const sanitizedText = text.replace(new RegExp(`[${eol}\r]`, 'g'), '');
      if (sanitizedText !== text) {
        editor.setValue(sanitizedText);
      }
    });
  };

  return (
    <Box ref={anchorEl} sx={flex.row}>
      <Box sx={{ position: 'relative', pointerEvents: 'none' }}>
        <Box
          sx={{
            ...innerLabelStyle,
            position: 'absolute',
            width: 20,
            alignItems: 'flex-start',
            height: 'calc(100% - 14px)',
            left: 10,
            top: 7,
            zIndex: 1,
          }}>
          <Function style={{ marginTop: size === 'small' ? -2 : 2 }} />
        </Box>
      </Box>
      <CodeEditor
        height={size === 'small' ? 32 : 40}
        language="cel"
        isSingleLine
        style={
          {
            p: size === 'small' ? '5px' : 1,
            pl: 4.5,
            transition: (theme: Theme) => theme.transitions.create(['border-color']),
            borderColor: isFocused ? ({ palette }: Theme) => palette.primary.main : undefined,
            ':hover': isFocused ? undefined : { borderColor: ({ palette }: Theme) => palette.colors.neutrals[800] },
          } as SxProps
        }
        onChange={onChange}
        value={value || ''}
        onMount={handleEditorDidMount}
        error={error}
      />
      {editorRef.current && isFocused && (suggestions.length > 0 || descriptionSuggestion) && (
        <CustomSuggestWidget
          editor={editorRef.current}
          position={position.current}
          suggestions={suggestions}
          anchorEl={anchorEl.current}
          width={anchorEl.current?.clientWidth}
          descriptionSuggestion={descriptionSuggestion}
          moreOperators={
            <OperatorOption
              symbol={fieldOptions ? '{{result}}' : '{{Field}}'}
              tooltip={fieldOptions ? 'Populating the outcome of the rule' : 'Populating value from field'}
            />
          }
        />
      )}
    </Box>
  );
};

export default CELExpressionInput;
