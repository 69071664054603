import React, { ReactElement } from 'react';
import { Box, Theme, useTheme } from '@mui/material';
import { ellipsis, emptyObject } from '../utils/Utils';
import { flex } from './AvThemeProvider';

interface IAvTagProps {
  text: ReactElement | string | number;
  isActive?: boolean;
  bg?: string;
  color?: string;
  oneLine?: boolean;
  sx?: object;
  isArrowRight?: boolean;
  isArrowLeft?: boolean;
}

const getBackground = (theme: Theme, isActive?: boolean) =>
  isActive ? theme.palette.colors.primary[150] : theme.palette.colors.neutrals[350];

const labelStyle =
  ({
    isActive,
    bg,
    color,
    oneLine,
    sx,
    isArrowRight,
    isArrowLeft,
  }: {
    isActive?: boolean;
    bg?: string;
    color?: string;
    oneLine?: boolean;
    sx?: object;
    isArrowRight?: boolean;
    isArrowLeft?: boolean;
  }) =>
  theme => ({
    whiteSpace: 'pre-wrap',
    ...(oneLine ? ellipsis : {}),
    padding: '2px 6px',
    borderRadius: '8px',
    lineHeight: '20px',
    fontWeight: 500,
    fontSize: 13,
    color: color || (isActive ? theme.palette.colors.primary[400] : theme.palette.colors.neutrals[600]),
    backgroundColor: bg || getBackground(theme, isActive),
    ...(isArrowRight && { borderBottomRightRadius: 0, borderTopRightRadius: 0, paddingLeft: '10px' }),
    ...(isArrowLeft && { borderBottomLeftRadius: 0, borderTopLeftRadius: 0, paddingRight: '10px' }),
    ...sx,
  });

const AvTag: React.FC<IAvTagProps> = ({
  text,
  isActive,
  bg,
  color,
  oneLine,
  sx = emptyObject,
  isArrowRight = false,
  isArrowLeft = false,
}) => {
  const theme = useTheme();

  return (
    text && (
      <Box sx={{ ...flex.itemsCenter }}>
        {isArrowLeft && (
          <Box
            component="span"
            sx={{
              borderTop: '12px solid transparent',
              borderRight: `8px solid ${bg || getBackground(theme, isActive)}`,
              borderBottom: '12px solid transparent',
            }}
          />
        )}
        <Box component="span" key={`${text}`} sx={labelStyle({ isActive, bg, color, oneLine, sx, isArrowRight, isArrowLeft })}>
          {text}
        </Box>
        {isArrowRight && (
          <Box
            component="span"
            sx={{
              borderTop: '12px solid transparent',
              borderLeft: `8px solid ${bg || getBackground(theme, isActive)}`,
              borderBottom: '12px solid transparent',
            }}
          />
        )}
      </Box>
    )
  );
};

export default AvTag;
