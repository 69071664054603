import React, { useEffect, useRef, useState } from 'react';
import { Editor, loader, OnChange, OnMount } from '@monaco-editor/react';
import { Box, FormControl, IconButton, InputLabel, SxProps, Theme } from '@mui/material';
import * as monaco from 'monaco-editor';
import { editor, IDisposable, languages } from 'monaco-editor';
import { mergeTransitions } from '../../utils/theme.utils';
import { noop } from '../../utils/Utils';
import { AvStepper } from '../AvStepper';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import { configureCELSyntax } from './CELSyntax';
import { ReactComponent as CollapseAll } from '../../assets/Collaps all.svg';
import { ReactComponent as ExpandAll } from '../../assets/Expand all.svg';

import CompletionItem = languages.CompletionItem;

export type Intellisense = Array<{
  name: string;
  children?: Array<{
    name: string;
    returnType?: string | null;
    args?: Array<{
      name: string;
      type: string;
    }>;
  }>;
}>;

loader.config({ monaco });

const editorStyle = (error, style, height) => ({
  width: '100%',
  height,
  ...flex.col,
  border: ({ palette }) => `1px solid ${error ? palette.error.main : palette.colors.neutrals[400]}`,
  '&, .monaco-editor, .monaco-editor > div': {
    borderRadius: style?.borderRadius ?? '10px',
  },
  ...style,
  transition: (theme: Theme) => mergeTransitions(theme, theme.transitions.create(['height']), style?.transition),
});

const singleLineOptions: Partial<editor.IStandaloneEditorConstructionOptions> = {
  lineNumbers: 'off',
  lineDecorationsWidth: 'off',
  overviewRulerLanes: 0,
  hideCursorInOverviewRuler: true,
  scrollBeyondLastColumn: 0,
  scrollbar: {
    horizontal: 'hidden',
    vertical: 'hidden',
    // avoid can not scroll page when hover monaco
    alwaysConsumeMouseWheel: false,
  },
  overviewRulerBorder: false,
  renderLineHighlight: 'none',
  lineNumbersMinChars: 0,
  glyphMargin: false,
  folding: false,
  quickSuggestions: false,
  suggestOnTriggerCharacters: false,
};

const celProps = {
  beforeMount: configureCELSyntax,
};

interface CodeEditorProps {
  label?: string;
  value: string;
  onChange?: OnChange | React.Dispatch<React.SetStateAction<any>>;
  isRequired?: boolean;
  isSingleLine?: boolean;
  disabled?: boolean;
  error?: string;
  intellisense?: Intellisense;
  height?: number | string;
  language?: string;
  onMount?: OnMount;
  footer?: React.ReactNode;
  style?: SxProps;
  options?: Partial<editor.IStandaloneEditorConstructionOptions>;
  isLoading?: boolean;
  placeholder?: string;
  insertTextFunc?: (args, v, name) => string;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  label,
  value = '',
  onChange,
  isRequired,
  isSingleLine,
  disabled,
  error,
  intellisense,
  height = '100%',
  language = 'python',
  onMount,
  footer,
  style = {},
  options = {},
  isLoading = false,
  placeholder,
  insertTextFunc = (args, v, name) => name,
}) => {
  const completionRef = useRef<IDisposable | null>(null);

  useEffect(() => () => completionRef.current?.dispose(), []);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleMount = (editor, monaco) => {
    const provideCompletionItems = () => ({
      suggestions: (intellisense || []).reduce<CompletionItem[]>((acc, v) => {
        if (v.children) {
          acc.push(
            ...v.children.map(
              ({ args, name, returnType }) =>
                ({
                  label: `${v.name}.${name}`,
                  kind: monaco.languages.CompletionItemKind.Method,
                  detail: args ? `${returnType} ${args.map(arg => `${arg.name}: ${arg.type}`).join(', ')}` : 'field',
                  insertText: insertTextFunc(args, v, name),
                }) as CompletionItem
            )
          );
        }
        return acc;
      }, []),
    });
    if (intellisense) {
      completionRef.current = monaco.languages.registerCompletionItemProvider(language, { provideCompletionItems });
    }
    onMount?.(editor, monaco);
  };

  return (
    <FormControl required={isRequired} error={Boolean(error)} sx={{ width: '100%', flexGrow: 1, position: 'relative' }}>
      {label ? <InputLabel>{label}</InputLabel> : undefined}
      <AvStepper isLoading={isLoading} sx={{ position: 'absolute', width: '100%', zIndex: 1, padding: 0 }} />
      <Box sx={{ ...editorStyle(error, style, isExpanded ? 180 : height), ...(isSingleLine && { pr: 4.5 }) }}>
        <Editor
          value={value}
          onChange={disabled ? noop : onChange}
          language={language}
          height="100%"
          width="100%"
          options={{
            readOnly: disabled,
            minimap: { enabled: false },
            fontSize: 14,
            scrollBeyondLastLine: false,
            formatOnPaste: true,
            scrollbar: { alwaysConsumeMouseWheel: false },
            fixedOverflowWidgets: false,
            wordWrap: isSingleLine && isExpanded ? 'on' : 'off',
            ...(isSingleLine ? singleLineOptions : {}),
            ...options,
          }}
          onMount={handleMount}
          {...(language === 'cel' && celProps)}
        />
        {isSingleLine && (
          <AvTooltip muiProps={{ placement: 'top' }} title={isExpanded ? 'Collapse' : 'Expand'}>
            <IconButton sx={{ position: 'absolute', height: 20, width: 20, right: 8 }} onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <CollapseAll /> : <ExpandAll />}
            </IconButton>
          </AvTooltip>
        )}
        <Box
          sx={{
            position: 'absolute',
            display: value ? 'none' : undefined,
            whiteSpace: 'pre-wrap',
            top: '1px',
            left: '69px',
            pointerEvents: 'none',
            userSelect: 'none',
            fontFamily: 'Menlo, Monaco, Courier New, monospace',
            fontFeatureSettings: '"liga" 0, "calt" 0',
            color: theme => theme.palette.colors.neutrals[600],
          }}>
          {placeholder}
        </Box>
        {footer}
      </Box>
    </FormControl>
  );
};
export default CodeEditor;
