import React from 'react';
import ZendeskAuthentication from '../hooks/zendeskAuthentication';

interface KBConnectProps {
  children: any;
}

const ZendeskAuthProvider: React.FC<KBConnectProps> = ({ children }) => {
  const isZendeskAuthRequired = window.location.pathname.includes('/zendesk/token');

  return isZendeskAuthRequired ? ZendeskAuthentication(children) : children;
};

export default ZendeskAuthProvider;
