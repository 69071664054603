import React from 'react';
import { useAvContext } from '../../context/AvContextProvider';
import { useParams } from '../../utils/AvRouter';
import AuditLogs from '../AuditLogs/AuditLogs';
import { AuditEntityType } from '../AuditLogs/types';

const RuleSetAuditLogs = () => {
  const { id, fieldName } = useParams();
  const { fieldMap } = useAvContext().accountEntities;
  const title = `${fieldMap[fieldName!].title} Rule Set - Audit Logs `;

  return <AuditLogs id={id} entityType={AuditEntityType.UNIFICATION_RULE_SET} title={title} />;
};

export default RuleSetAuditLogs;
