import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { NotFound } from '../components/ErrorBoundary';

export const Route = createFileRoute('/error-process-request')({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <NotFound
      text="Sorry, we are unable to proceed with your request"
      buttonProps={{ children: 'Contact support', onClick: () => (window.location.href = 'mailto:support@avalor.io') }}
    />
  );
}
