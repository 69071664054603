import { GroupHeaderRowType } from '../components/Table/types';
import { getGroupComparator } from '../components/Table/Utils';
import { PagePathsValue } from '../context/AvContext.types';
import { SearchContextKeys, SearchContextKeysType } from '../context/SearchContext.types';
import {
  APP_PATHS,
  AppPathsValue,
  PAGE_PATHS,
  Permission,
  PermissionAppName,
  PermissionCategoriesNames,
  PermissionEntitiesNames,
} from '../types';
import { ScreenType, ScreenTypeType } from '../types/savedViews.types';
import { TableRowSize } from '../views/CustomDashboards/types';
import { BucketType } from '../views/Settings/TicketStatuses/Utils';
import { FactorType } from '../views/Tickets/Factors/utils';
import { CURRENT_STATUS_FIELD, getHeadCellWithExpended, NESTED_CURRENT_STATUS_FIELD } from '../views/Tickets/Utils';
import {
  defaultAlertSortBy,
  defaultExceptionsSortBy,
  defaultSortBy,
  defaultSortByTickets,
  FilterIncidentEntityTypesLabelV2,
  FilterTypesLabelV2,
} from './mapping';
import AlertsError from '../assets/emptyStates/No Alerts yet.svg';
import TicketError from '../assets/emptyStates/No Tickets Found.svg';

export const ACTIONS_MAP = {
  UPDATE: 'update',
  MERGE: 'merge',
  COMMENT: 'comment',
  OUTEGRATION_CREATE: 'outegration_create',
  ALERT_TO_INCIDENT: 'alert_to_incident',
  ATTACHMENTS: 'attachments',
  OUTEGRATION_ACT: 'outegration_act',
  EXCEPTIONS_SECTION: 'exceptions-section',
  EXCEPTIONS_FORM: 'exceptions-form',
};

type ActionsMapType = (typeof ACTIONS_MAP)[keyof typeof ACTIONS_MAP];

export const getActionsPermissionConfig = (
  projectionName: string
): Record<
  string,
  {
    resource: PermissionEntitiesNames;
    category?: PermissionCategoriesNames;
    permission?: Permission;
  }
> => ({
  [ACTIONS_MAP.UPDATE]:
    projectionName === entityViewConfig.Exception.projectionName
      ? {
          category: PermissionAppName.VULNERABILITIES,
          resource: PermissionEntitiesNames.EXCEPTION,
          permission: Permission.UPDATE,
        }
      : { resource: PermissionEntitiesNames.OPT, permission: Permission.UPDATE },
  [ACTIONS_MAP.MERGE]: { resource: PermissionEntitiesNames.OPT, permission: Permission.CREATE },
  [ACTIONS_MAP.COMMENT]:
    projectionName === entityViewConfig.Exception.projectionName
      ? {
          category: PermissionAppName.VULNERABILITIES,
          resource: PermissionEntitiesNames.EXCEPTION,
          permission: Permission.UPDATE,
        }
      : { resource: PermissionEntitiesNames.OPT, permission: Permission.UPDATE },
  [ACTIONS_MAP.OUTEGRATION_CREATE]: { resource: PermissionEntitiesNames.INT, permission: Permission.CREATE },
  [ACTIONS_MAP.ALERT_TO_INCIDENT]: { resource: PermissionEntitiesNames.OPT, permission: Permission.UPDATE },
  [ACTIONS_MAP.ATTACHMENTS]:
    projectionName === entityViewConfig.Exception.projectionName
      ? {
          category: PermissionAppName.VULNERABILITIES,
          resource: PermissionEntitiesNames.EXCEPTION,
          permission: Permission.UPDATE,
        }
      : { resource: PermissionEntitiesNames.OPT, permission: Permission.UPDATE },
  [ACTIONS_MAP.OUTEGRATION_ACT]: { resource: PermissionEntitiesNames.INT, permission: Permission.CREATE },
  [ACTIONS_MAP.EXCEPTIONS_SECTION]: {
    category: PermissionAppName.VULNERABILITIES,
    resource: PermissionEntitiesNames.EXCEPTION,
    permission: Permission.READ,
  },
  [ACTIONS_MAP.EXCEPTIONS_FORM]: {
    category: PermissionAppName.VULNERABILITIES,
    resource: PermissionEntitiesNames.EXCEPTION,
    permission: Permission.CREATE,
  },
});

export type EntityConfigType = {
  path: PagePathsValue;
  entityTypeId: string;
  projectionName: string;
  projDisplayName?: string;
  pageTitle: string;
  appPath: AppPathsValue;
  app: string;
  viewScreenType?: ScreenTypeType;
  searchContextKey: SearchContextKeysType;
  defaultSortBy: (alias: string) => any | void;
  titleFieldName?: string;
  searchPlaceholder?: string;
  searchFields?: string[];
  labelsV2?: Record<string, string>;
  defaultFilters?: Record<string, any[]>;
  showVisualEntityExplorer?: boolean;
  defaultSavedView?: string;
  vulnsField?: string;
  extraFilters?: string[];
  hideCommentsTab?: boolean;
  hideActivityTab?: boolean;
  hideGroupBy?: boolean;
  hideApplyChanges?: boolean;
  groupingKeyField?: string;
  extraRelationGroup?: string;
  isTicketFilters?: boolean;
  rowGroupByField?: (row: any) => any;
  getHeadCellFunc?: (hc: any) => any;
  getData?: (hc: any) => any;
  actions: {
    grid?: Record<ActionsMapType, boolean>;
    panel?: Record<ActionsMapType, boolean>;
  };
  styles?: {
    isTransparentPageBackground?: boolean;
    tableSize?: TableRowSize;
  };
  noDataImage?: SVGElement;
  isMultiSelect?: boolean;
  disablePagination?: boolean;
  currentStatusName?: typeof CURRENT_STATUS_FIELD | typeof NESTED_CURRENT_STATUS_FIELD;
  filterBucketsFunc?: (bucket: BucketType, currentActiveBucket?: BucketType) => boolean;
};

interface IEntityViewConfig {
  [key: string]: EntityConfigType;
}

export const entityViewConfig: IEntityViewConfig = {
  Ticket: {
    path: PAGE_PATHS.TICKETS,
    entityTypeId: 'Ticket',
    projectionName: 'tickets',
    pageTitle: 'Tickets',
    appPath: APP_PATHS.VULNERABILITIES,
    app: 'Vulnerabilities',
    viewScreenType: ScreenType.Tickets,
    labelsV2: FilterTypesLabelV2,
    showVisualEntityExplorer: true,
    searchPlaceholder: 'Search Title',
    searchFields: ['ticket.title', 'ticket._key'],
    defaultSavedView: 'Active',
    searchContextKey: SearchContextKeys.Tickets,
    vulnsField: 'ticket.finding_count',
    extraFilters: ["ticket.state != 'ARCHIVED'"],
    groupingKeyField: 'finding.grouping_key',
    titleFieldName: 'title',
    extraRelationGroup: 'Assignee',
    isTicketFilters: true,
    defaultSortBy: alias => defaultSortByTickets(alias),
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.MERGE]: true,
        [ACTIONS_MAP.COMMENT]: true,
        [ACTIONS_MAP.OUTEGRATION_CREATE]: true,
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: {
        [ACTIONS_MAP.ATTACHMENTS]: true,
        [ACTIONS_MAP.EXCEPTIONS_SECTION]: true,
        [ACTIONS_MAP.EXCEPTIONS_FORM]: true,
      },
    },
    styles: {
      isTransparentPageBackground: true,
    },
    noDataImage: TicketError,
    currentStatusName: NESTED_CURRENT_STATUS_FIELD,
  },
  Asset: {
    path: PAGE_PATHS.ASSETS,
    entityTypeId: 'Asset',
    projectionName: 'uber_assets',
    pageTitle: 'Assets',
    appPath: APP_PATHS.VULNERABILITIES,
    app: 'Vulnerabilities',
    viewScreenType: ScreenType.AssetView,
    showVisualEntityExplorer: true,
    labelsV2: FilterTypesLabelV2,
    defaultFilters: {
      'asset.source_names': [],
      'asset.type': [],
      'asset.site': [],
      'asset.os': [],
    },
    searchFields: ['asset.name', 'asset._key'],
    defaultSavedView: 'Active',
    searchContextKey: SearchContextKeys.Assets,
    vulnsField: '',
    extraFilters: ["asset.state != 'ARCHIVED'"],
    hideCommentsTab: true,
    hideActivityTab: false,
    extraRelationGroup: 'Owner',
    titleFieldName: 'name',
    defaultSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        // Added to support Act button [AVA-9992] fixed in [AVA-10084]
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: true,
    },
    noDataImage: TicketError,
  },
  Incident: {
    path: PAGE_PATHS.INCIDENTS,
    entityTypeId: 'Incident',
    projectionName: 'incidents',
    pageTitle: 'Incidents',
    app: 'Incidents',
    appPath: APP_PATHS.DETECTIONS,
    viewScreenType: ScreenType.Incidents,
    labelsV2: FilterIncidentEntityTypesLabelV2,
    showVisualEntityExplorer: true,
    searchFields: ['incident.title', 'incident._key'],
    defaultSavedView: 'Open Tickets',
    searchContextKey: SearchContextKeys.Incidents,
    groupingKeyField: 'alert.grouping_key',
    titleFieldName: 'title',
    vulnsField: 'incident.alert_count',
    extraFilters: ["incident.state NOT IN ('INACTIVE', 'ARCHIVED')"],
    isTicketFilters: true,
    defaultSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.MERGE]: true,
        [ACTIONS_MAP.COMMENT]: true,
        [ACTIONS_MAP.OUTEGRATION_CREATE]: true,
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: true },
    },
    styles: {
      isTransparentPageBackground: true,
    },
    noDataImage: TicketError,
    currentStatusName: NESTED_CURRENT_STATUS_FIELD,
  },
  Alert: {
    path: PAGE_PATHS.ALERTS,
    entityTypeId: 'Alert',
    projectionName: 'uber_alerts',
    pageTitle: 'Alerts',
    app: 'Incidents',
    appPath: APP_PATHS.DETECTIONS,
    viewScreenType: ScreenType.Alerts,
    showVisualEntityExplorer: true,
    labelsV2: FilterIncidentEntityTypesLabelV2,
    searchFields: ['alert.title', 'alert._key'],
    defaultSavedView: 'Active',
    searchContextKey: SearchContextKeys.Alerts,
    titleFieldName: 'rule_name',
    defaultFilters: {
      'alert.severity': ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'],
      'alert.state': [],
      'alert.created': [],
      'alert.tactic_name': [],
      'alert.technique_name': [],
    },
    hideCommentsTab: true,
    hideActivityTab: true,
    vulnsField: '',
    defaultSortBy: defaultAlertSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.ALERT_TO_INCIDENT]: true,
      },
      panel: {
        [ACTIONS_MAP.ATTACHMENTS]: false,
      },
    },
    styles: {
      isTransparentPageBackground: false,
      tableSize: TableRowSize.Medium,
    },
    noDataImage: AlertsError,
  },
  Finding: {
    path: PAGE_PATHS.FINDINGS,
    entityTypeId: 'Finding',
    projectionName: 'uber_findings',
    pageTitle: 'Findings',
    appPath: APP_PATHS.VULNERABILITIES,
    app: 'Vulnerabilities',
    viewScreenType: ScreenType.FindingsView,
    showVisualEntityExplorer: true,
    labelsV2: FilterTypesLabelV2,
    searchFields: ['finding.title', 'finding.cve_id', 'component.name', 'component._key', 'asset.os', 'finding._key'],
    defaultSavedView: 'Active Critical and High Findings',
    defaultFilters: {
      'finding.source_names': [],
      'finding.type': [],
      'finding.severity': [],
    },
    searchContextKey: SearchContextKeys.Findings,
    extraFilters: ["finding.state != 'ARCHIVED'"],
    extraRelationGroup: '*',
    titleFieldName: 'title',
    defaultSortBy,
    hideCommentsTab: true,
    hideActivityTab: true,
    actions: {
      grid: { [ACTIONS_MAP.UPDATE]: true, [ACTIONS_MAP.MERGE]: true },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: false,
      tableSize: TableRowSize.Medium,
    },
    noDataImage: TicketError,
  },
  Factor: {
    path: PAGE_PATHS.FACTOR,
    entityTypeId: 'Factor',
    projectionName: 'uber_factors',
    pageTitle: 'Factors',
    isMultiSelect: false,
    appPath: APP_PATHS.RISK360,
    app: 'Risk360',
    labelsV2: FilterTypesLabelV2, //--------
    searchFields: ['factor.name', 'factor.category_name', 'factor._key'],
    defaultFilters: {
      'factor.name': [],
      'factor.category_name': [],
      'factor.enabled': [],
    },
    searchContextKey: SearchContextKeys.Factor,
    disablePagination: true,
    extraFilters: [],
    rowGroupByField: row => row?.groupId,
    getHeadCellFunc: hc => getHeadCellWithExpended({ headCells: hc }),
    getData: queryData =>
      queryData
        .map(row => ({
          ...row,
          // last30Days: factorLineChartData,
          disableStickyRow: true,
          groupHeaderType:
            row.type === FactorType.GROUP_FACTOR
              ? GroupHeaderRowType.GROUP
              : row.type === FactorType.STANDALONE_FACTOR
                ? GroupHeaderRowType.STANDALONE
                : GroupHeaderRowType.SUB_GROUP,
        }))
        .toSorted(getGroupComparator(row => row?.groupId, 'asc')),
    extraRelationGroup: '*',
    titleFieldName: 'name',
    defaultSortBy,
    hideCommentsTab: true,
    hideActivityTab: true,
    hideGroupBy: true,
    actions: {
      grid: { [ACTIONS_MAP.UPDATE]: false, [ACTIONS_MAP.MERGE]: false },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: true,
    },
    noDataImage: TicketError,
  },
  PolicyViolation: {
    path: PAGE_PATHS.POLICY_VIOLATIONS,
    entityTypeId: 'PolicyViolation',
    projectionName: 'uber_policy_violations',
    pageTitle: 'Policy Violations',
    isMultiSelect: true,
    appPath: APP_PATHS.CAASM,
    app: 'CAASM',
    defaultSavedView: 'Active',
    viewScreenType: ScreenType.PolicyViolations,
    labelsV2: FilterTypesLabelV2,
    searchPlaceholder: 'Search Policy Violation',
    searchFields: ['policy_violation.title', 'policy_violation._key'],
    defaultFilters: {
      'policy_violation.state': [],
      'policy_violation.severity': [],
      'policy_violation.policy_name': [],
    },
    searchContextKey: SearchContextKeys.PolicyViolation,
    disablePagination: false,
    extraRelationGroup: '*',
    titleFieldName: 'title',
    defaultSortBy,
    hideCommentsTab: true,
    hideActivityTab: true,
    hideGroupBy: false,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.MERGE]: false,
        [ACTIONS_MAP.OUTEGRATION_CREATE]: true,
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: false,
      tableSize: TableRowSize.Medium,
    },
    noDataImage: TicketError,
    currentStatusName: CURRENT_STATUS_FIELD,
  },
  PolicyPopulation: {
    path: PAGE_PATHS.POLICY_VIOLATIONS,
    entityTypeId: 'PolicyPopulation',
    projectionName: 'uber_policy_populations',
    projDisplayName: 'Policy Violation',
    pageTitle: 'Policy Violations',
    isMultiSelect: true,
    appPath: APP_PATHS.CAASM,
    app: 'CAASM',
    defaultSavedView: 'Active',
    viewScreenType: ScreenType.PolicyPopulations,
    labelsV2: FilterTypesLabelV2,
    searchPlaceholder: 'Search Policy Violation',
    searchFields: ['policy_population.title', 'policy_population._key'],
    defaultFilters: {
      'policy_population.state': [],
      'policy_population.severity': [],
      'policy_population.policy_name': [],
    },
    searchContextKey: SearchContextKeys.PolicyPopulation,
    disablePagination: false,
    extraFilters: ['policy_population.is_violating = true'], // https://jira.corp.zscaler.com/browse/AVA-13941
    extraRelationGroup: '*',
    titleFieldName: 'title',
    defaultSortBy,
    hideCommentsTab: true,
    hideActivityTab: true,
    hideGroupBy: false,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.MERGE]: false,
        [ACTIONS_MAP.OUTEGRATION_CREATE]: true,
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: false,
      tableSize: TableRowSize.Medium,
    },
    noDataImage: TicketError,
    currentStatusName: CURRENT_STATUS_FIELD,
  },
  Exception: {
    pageTitle: 'Exceptions',
    path: PAGE_PATHS.EXCEPTIONS,
    entityTypeId: 'Exception',
    projectionName: 'uber_exceptions',
    app: 'Vulnerabilities',
    hideApplyChanges: true,
    appPath: APP_PATHS.VULNERABILITIES,
    viewScreenType: ScreenType.ExceptionsView,
    searchContextKey: SearchContextKeys.Exceptions,
    groupingKeyField: 'ticket._key',
    searchFields: ['ticket.title', 'exception._key'],
    extraFilters: ["exception.state != 'ARCHIVED'"],
    defaultSortBy: defaultExceptionsSortBy,
    defaultFilters: {
      'exception.current_status': [],
      'ticket.severity': [],
      'requester.name': [],
    },
    actions: {
      grid: { [ACTIONS_MAP.UPDATE]: true, [ACTIONS_MAP.COMMENT]: true, [ACTIONS_MAP.OUTEGRATION_CREATE]: true },
      panel: { [ACTIONS_MAP.OUTEGRATION_CREATE]: true, [ACTIONS_MAP.ATTACHMENTS]: true },
    },
    styles: {
      isTransparentPageBackground: true,
    },
    noDataImage: TicketError,
    currentStatusName: CURRENT_STATUS_FIELD,
    filterBucketsFunc: (bucket, currentActiveBucket) => (currentActiveBucket?.approved ? false : !bucket.closed && !bucket.approved),
  },
};

export const projectionEntityViewConfig: IEntityViewConfig = Object.fromEntries(
  Object.values(entityViewConfig).map(config => [config.projectionName, { ...config }])
);
