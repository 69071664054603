import { createFileRoute } from '@tanstack/react-router';
import { PAGE_PATHS } from '../../../../types';

const path = PAGE_PATHS.INCIDENTS;
export const Route = createFileRoute('/$accountId/incidents/incidents/')({
  staticData: {
    path,
    key: 'Incident',
  },
});
