import { FormatterType } from '../../components/Table/types';
import { Measurement } from '../Measurements/types';
import { MeasurementCategory, MeasurementUnit, TimeBucket } from '../Reports/types';
import { FieldType, FieldTypeEnum, FieldType as MappingFieldType } from '../Sources/Mapping/mapping.types';

const isNumber = (entityFieldKey: string, fieldTypeMap: Record<string, string>, measurement?: Measurement) =>
  FieldTypeEnum[fieldTypeMap[entityFieldKey]] === MappingFieldType.Number ||
  measurement?.category === MeasurementCategory.NUMERIC ||
  measurement?.category === MeasurementCategory.POPULATION_COUNT;

const isPercentage = (measurement?: Measurement) =>
  measurement?.category === MeasurementCategory.PERCENTAGE ||
  (measurement?.category === MeasurementCategory.MATH_EXPRESSION && measurement?.unit.name === MeasurementUnit.Percentage);

const isDate = (entityFieldKey: string, fieldTypeMap: Record<string, string>) =>
  [MappingFieldType.DateTime, MappingFieldType.Date].includes(FieldTypeEnum[fieldTypeMap[entityFieldKey]] as any);

const isHistoricDate = (entityFieldKey: string) => TimeBucket[entityFieldKey];

// eslint-disable-next-line import/prefer-default-export
export const getFieldType = ({
  entityFieldKey,
  measurements,
  fieldTypeMap,
}: {
  entityFieldKey: string;
  measurements: Measurement[];
  fieldTypeMap: Record<string, string>;
}): FormatterType | (typeof FieldType)['FIX'] | undefined => {
  const maybeMeasurement = measurements.find(({ systemName }) => systemName === entityFieldKey);
  return isNumber(entityFieldKey, fieldTypeMap, maybeMeasurement)
    ? FormatterType.number
    : isDate(entityFieldKey, fieldTypeMap)
      ? FormatterType.date
      : isHistoricDate(entityFieldKey)
        ? FormatterType.historicDate
        : isPercentage(maybeMeasurement)
          ? FormatterType.percentage
          : Array.isArray(fieldTypeMap[entityFieldKey])
            ? FormatterType.array
            : FieldTypeEnum[fieldTypeMap[entityFieldKey]] === MappingFieldType.Message
              ? FormatterType.json
              : FieldTypeEnum[fieldTypeMap[entityFieldKey]] === MappingFieldType.Boolean
                ? FormatterType.boolean
                : FieldTypeEnum[fieldTypeMap[entityFieldKey]] === MappingFieldType.FIX
                  ? FieldType.FIX
                  : undefined;
};
