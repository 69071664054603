import { gql } from '@apollo/client';

const useKnowledgeBase = ({
  redirect,
  newPage = true,
  overrideAccountId = false,
  accountId,
  api,
}: {
  redirect?: string;
  newPage?: boolean;
  overrideAccountId?: boolean;
  accountId?: string;
  api: any;
}) => {
  const options = overrideAccountId ? { options: { headers: { accountId } } } : {};
  return () =>
    api(GET_KNOWLEDGE_BASE_JWT, options).then(({ data }) => {
      const redirectUrl = redirect || 'https://support.avalor.io/hc';
      const url = `https://avalorhelp.zendesk.com/access/jwt?jwt=${data.getZendeskToken?.zendeskToken || ''}&return_to=${redirectUrl}`;
      if (newPage) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    });
};

const GET_KNOWLEDGE_BASE_JWT = gql`
  query {
    getZendeskToken {
      zendeskToken
    }
  }
`;
export default useKnowledgeBase;
