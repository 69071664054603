import React from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { AvContextWithExternalProjectionsProvider } from '../../../context/AvContextProvider';

export const Route = createFileRoute('/$accountId/risk360/_externalProjectionsLayout')({
  component: ExternalProjectionLayout,
});

function ExternalProjectionLayout() {
  return (
    <AvContextWithExternalProjectionsProvider>
      <Outlet />
    </AvContextWithExternalProjectionsProvider>
  );
}
