import { EntityTypeID } from '../../context/context.type';
import { Filter } from '../../types/filter.types';

export type EnhancedProj = {
  clusteringEnabled: boolean;
  meta: {
    id: {
      name: string;
      builtIn: boolean;
    };
  };
  clusteringPredicate: {
    anyofList: Rule[];
  };
  aggregates: {
    name: string;
    builtIn: boolean;
  };
};

export type UpdateEnhancedProj = Omit<EnhancedProj, 'clusteringPredicate' | 'clusteringEnabled' | 'aggregates'> & {
  clusteringPredicate: { anyof: UpdateRule[] };
};

export type Rule = {
  id: string;
  ignoreNulls: boolean;
  name: string;
  predicate: {
    predicateFieldsList: { fieldName?: string; celExpression?: string }[];
    filter: Filter;
  };
  fieldsList: string[];
};

type UpdateRule = Omit<Rule, 'predicate' | 'fieldsList' | 'id'> & {
  predicate: {
    predicateFields: { fieldName?: string; celExpression?: string }[];
    filter?: Filter;
  };
};

export enum PredicateType {
  FILTER = 'FILTER',
  CEL_EXPRESSION = 'CEL_EXPRESSION',
}

export enum AgingScenarioType {
  TIME_BASED = 'TIME_BASED',
  VALUE_BASED = 'VALUE_BASED',
}

export enum RuleConfigType {
  CONDITIONAL = 'CONDITIONAL',
  SOURCE_PRIORITY = 'SOURCE_PRIORITY',
  AGING_CONFIG = 'AGING_CONFIG',
}

export enum AssignmentType {
  VALUE = 'VALUE',
  FIELD = 'FIELD',
  STRING_TEMPLATE = 'STRING_TEMPLATE',
  CEL_EXPRESSION = 'CEL_EXPRESSION',
}

export type Assignment = {
  assignmentType: AssignmentType;
  value: string | number | boolean;
};

export type SourcePriorityRule = { sourceName: string; fieldNameWithAlias: string };
export type ConditionalRuleConfig = {
  type: RuleConfigType.CONDITIONAL;
  predicate: { type: PredicateType; value: Filter | string };
  assignment: Assignment;
};
export type SourcePriorityRuleConfig = {
  type: RuleConfigType.SOURCE_PRIORITY;
  expression: string | null;
  sourcePriority: SourcePriorityRule[];
};

type RuleSetConfig = {
  projId: EntityTypeID;
  field: string;
};

export type UnificationRuleSet = {
  id?: string;
  name: string;
  ruleSetConfig: RuleSetConfig;
  rules: UnificationRule[];
};

export type AgingConfigRuleConfig = {
  type: RuleConfigType.AGING_CONFIG;
  populationFilter: Filter;
  scenarioType: AgingScenarioType;
  timeBasedScenario?: TimeBasedScenario;
  valueBasedScenario?: Filter;
};
export type UnificationRule = {
  id?: string;
  name: string;
  isNew?: boolean;
  ruleConfig: SourcePriorityRuleConfig | ConditionalRuleConfig | AgingConfigRuleConfig;
};

type LogicalTimeBasedScenario = { operands: { config: { sourceNames: string[]; days: number } }[] };
export type TimeBasedScenario = {
  and?: LogicalTimeBasedScenario;
  or?: LogicalTimeBasedScenario;
};
