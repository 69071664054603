import { createFileRoute } from '@tanstack/react-router';
import { PAGE_PATHS } from '../../../../../types';

const path = PAGE_PATHS.INCIDENTS;
export const Route = createFileRoute('/$accountId/threats/incidents/$id/')({
  staticData: {
    path,
    key: 'Incident',
  },
});
