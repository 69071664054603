import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import { TickRotation, X_TICK_MARGIN } from './AvComposedWidget.utils';

const AXIS = {
  X: 'X',
  Y: 'Y',
} as const;

interface Props {
  rotation?: (typeof TickRotation)[keyof typeof TickRotation];
  axis: (typeof AXIS)[keyof typeof AXIS];
  // Provided by recharts
  height?: number;
  width?: number;
  payload?: { value: string; [key: string]: unknown };
  // Passes by recharts
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  // Passes by recharts
  // eslint-disable-next-line react/no-unused-prop-types
  index?: number;
  x?: number;
  y?: number;
  tickFormatter?: (value: string) => string;
  visibleTicksCount?: number;
  customTickFormatter?: (value: string) => string | ReactNode;
}

export const customTickClassnames = {
  xAxisVertical: `.xAxis .${TickRotation.vertical}`,
  xAxisHorizontal: `.xAxis .${TickRotation.horizontal}`,
  xAxisSlanted: `.xAxis .${TickRotation.slanted}`,
  yAxisSlanted: `.yAxis .${TickRotation.slanted}`,
};

const DEFAULT_MAX_WIDTH = 80;

function CustomTick({
  x = 0,
  y = 0,
  payload,
  rotation = TickRotation.horizontal,
  width: barWidth,
  height: barHeight = 80,
  tickFormatter,
  visibleTicksCount,
  customTickFormatter,
  axis,
}: Props) {
  const maxWidth =
    axis === AXIS.Y || rotation === TickRotation.horizontal
      ? undefined
      : rotation === TickRotation.slanted
        ? barHeight || DEFAULT_MAX_WIDTH
        : barHeight - X_TICK_MARGIN;
  const defaultWidth = rotation === TickRotation.horizontal ? 100 : axis === AXIS.X ? 85 : 150;
  const width = axis === AXIS.Y ? barWidth || defaultWidth : barWidth && visibleTicksCount ? barWidth / visibleTicksCount : defaultWidth;
  if (!payload?.value) {
    return null;
  }
  return (
    <foreignObject x={x} y={y} width={1} height={1} overflow="visible">
      <Box
        className={rotation}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth,
          height: barHeight,
          width,
          ...flex.center,
          pointerEvents: 'none',
          [`.yAxis &`]: {
            transform: 'translate(-100%, -50%)',
            justifyContent: 'flex-end',
            height: 'auto',
          },
        }}>
        {customTickFormatter ? (
          customTickFormatter(payload.value)
        ) : (
          <AvTooltip
            sx={{
              color: theme => theme.palette.colors.neutrals[500],
              fontSize: '12px',
              pointerEvents: 'all',
              [`${customTickClassnames.xAxisVertical} &`]: {
                transform: 'rotate(-90deg) translate(-35%, -25%)',
                transformOrigin: 'center center',
              },
              [`${customTickClassnames.xAxisSlanted} &`]: {
                transform: 'rotate(-45deg) translate(-50%, -50%)',
                transformOrigin: 'center center',
              },

              [`${customTickClassnames.yAxisSlanted} &`]: {
                transform: 'rotate(-45deg)',
                transformOrigin: 'right center',
              },
            }}>
            {tickFormatter?.(payload?.value)}
          </AvTooltip>
        )}
      </Box>
    </foreignObject>
  );
}

export default CustomTick;
