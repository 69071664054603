import React from 'react';
import { ToggleButton } from '@mui/material';
import { Filter } from '../../types/filter.types';
import AvTooltip from '../AvTooltip';
import { ReactComponent as CaseSensitive } from '../../assets/Case Sensitive.svg';

interface Props {
  actualFilterValue: Filter;
  filter: Filter;
  setFilter: any;
}

export default function CaseSensitiveButton({ actualFilterValue, filter, setFilter }: Props) {
  return (
    <AvTooltip title={`Case Sensitive ${actualFilterValue.expression!.caseInsensitive ? 'OFF' : 'ON'}`}>
      <ToggleButton
        value="case_sensitive"
        selected={!actualFilterValue.expression!.caseInsensitive}
        onChange={() =>
          setFilter({ ...filter, expression: { ...filter.expression, caseInsensitive: !filter.expression!.caseInsensitive } })
        }
        sx={({ palette }) => ({
          border: `1px solid ${palette.colors.neutrals[400]}`,
          padding: '5px',
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: palette.colors.primary[150],
            borderColor: palette.colors.primary[500],
          },
        })}>
        <CaseSensitive />
      </ToggleButton>
    </AvTooltip>
  );
}
