import { SchemaTypes } from '../Sources/Mapping/mapping.types';
import { AssignmentType } from './types';

export const EMPTY_RULES_MESSAGE = 'You must add at least one rule in order to save this rule set.';

export const initialStateEnhancedProj = {
  clusteringEnabled: true,
  meta: {
    id: {
      name: '',
      builtIn: true,
    },
  },
  clusteringPredicate: {
    anyofList: [],
  },
  aggregates: {
    name: '',
    builtIn: true,
  },
};

export const assigneeRadioOptions = [
  { value: AssignmentType.VALUE, title: 'Value' },
  { value: AssignmentType.STRING_TEMPLATE, title: 'Smart Text' },
  { value: AssignmentType.FIELD, title: 'Field' },
  { value: AssignmentType.CEL_EXPRESSION, title: 'Expression' },
];

export const defaultValues = {
  [SchemaTypes.BOOL]: false,
  [SchemaTypes.DATE]: '',
  [SchemaTypes.DATETIME]: '',
  [SchemaTypes.FIX]: '',
  [SchemaTypes.IP]: '',
  [SchemaTypes.NUMBER]: 0,
  [SchemaTypes.STRING]: '',
};
