import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import AvTag from '@components/AvTag';
import { flex } from '@components/AvThemeProvider';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  SxProps,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Link } from '@utils/AvRouter';
import { cleanEmptyFilters, expressionToFilter, filterToExpression, updateFilterInExpression } from '@utils/filterUtils';
import { formDisabledStyle, iconSize, isDeepEqual, isFieldEmpty, isObject, noop, setNestedValue } from '@utils/Utils';
import { useSnackbar } from 'notistack';
import AvAttentionBox, { AttentionBoxVariant } from '../../components/AvAttentionBox';
import AvDialog from '../../components/AvDialog';
import AvFilters from '../../components/AvFilters';
import AvSidePanel, { NavigationBar } from '../../components/AvSidePanel';
import AvTooltip from '../../components/AvTooltip';
import CodeEditor from '../../components/CodeEditor';
import OpenSelect from '../../components/OpenSelect';
import Select from '../../components/Select';
import SwitchToggle from '../../components/SwitchToggle';
import TextInput from '../../components/TextInput';
import { useAvContext } from '../../context/AvContextProvider';
import { VisibilityConfig } from '../../context/context.type';
import { LayoutContext } from '../../context/LayoutContext';
import useGlobalFilters from '../../hooks/useGlobalFilters';
import { APP_PATHS, FeatureFlags, PAGE_PATHS, Permission, PermissionEntitiesNames } from '../../types';
import { Filter } from '../../types/filter.types';
import { ErrorTypes } from '../../types/query.types';
import { useAuditNavigation } from '../AuditLogs/hooks';
import { getDWQueryDefault, interactiveQueryMaxRowsSize } from '../CustomDashboards/types';
import { useCustomRolesList, usePermissionResourcesOptions } from '../CustomRoles/hooks';
import { SettingsName, useIsFieldSyncedToSetting } from '../Settings/hooks';
import { FieldTypeEnum } from '../Sources/Mapping/mapping.types';
import AvMessagePopover from '../Tickets/AvMessagePopover';
import { drawerWidth } from '../Tickets/ticket.types';
import { editFieldStyle } from '../Tickets/ticketInfo.utils';
import FieldUsage from './FieldUsage';
import { editedTag, InvestigateEntityButton } from './GroupRowHead';
import {
  fieldTypeIconsMap,
  fieldTypeLabelMap,
  getFieldKey,
  getPythonType,
  ModelRowItem,
  useFieldRegulationsData,
  useProjEvaluations,
  useSaveManualUpdates,
  useUpdateFieldVisibility,
} from './hooks';
import { TConfig, TUpdateRolesPerProjection } from './types';
import ValuesByQueryPreviewTable from './ValuesByQueryPreviewTable';
import { ReactComponent as ArrowDown } from '../../assets/Arrow Down.svg';
import { ReactComponent as ErrorIcon } from '../../assets/colorful/Fail_full.svg';
import { ReactComponent as QuestionFull } from '../../assets/colorful/Question_full.svg';
import { ReactComponent as WarningFull } from '../../assets/colorful/Warning_full_orange.svg';
import { ReactComponent as WarningFullGrey } from '../../assets/colorful/Warning_Full.svg';
import { ReactComponent as Edit } from '../../assets/Edit.svg';
import { ReactComponent as Show } from '../../assets/EyeShow.svg';
import { ReactComponent as FilterSVG } from '../../assets/Filter.svg';
import { ReactComponent as Function } from '../../assets/Function.svg';
import { ReactComponent as Hide } from '../../assets/Hide.svg';
import { ReactComponent as MinusCircle } from '../../assets/MinusCircle.svg';
import { ReactComponent as Plus } from '../../assets/Plus.svg';
import { ReactComponent as Table } from '../../assets/Table.svg';
import { ReactComponent as Undo } from '../../assets/Undo.svg';

const expandIcon = <ArrowDown />;
const plusIcon = <Plus style={{ width: 16, height: 16 }} />;
const filterIcon = <FilterSVG style={{ width: 16, height: 16 }} />;
const functionIcon = <Function style={{ width: 22, height: 22 }} />;
const deleteIcon = <MinusCircle style={{ height: 20, width: 20 }} />;
const editBigIcon = <Edit style={{ height: 20, width: 20 }} />;
const undoIcon = <Undo />;
const warningIcon = <WarningFull />;
const questionFullIcon = <QuestionFull />;
const tableIcon = <Table />;
const infoFullIcon = <WarningFullGrey style={{ width: 18, height: 18 }} />;
const editIcon = <Edit />;
const hideIcon = <Hide />;
const showIcon = <Show />;

const initialConfig = {
  allowManualUpdate: false,
  forceComment: false,
  forceCommentType: 'ALWAYS',
  forceCommentValues: [],
  contentValidation: false,
  contentValidationAllowedValues: [],
  forceAllowedRoles: false,
  allowedRolesIds: [],
};
const forceCommentOptions = [
  { title: 'Always', value: 'ALWAYS' },
  { title: 'Specific', value: 'CUSTOM' },
];

interface ManualUpdatesProps {
  config: Partial<TConfig>;
  onChange(key: keyof TConfig, value: any): unknown;
  reset(): unknown;
  projection: string;
  builtInProjection: boolean;
  availableUpdateRolesPerProjection: TUpdateRolesPerProjection[];
}

const rolesToExclude = ['RND_ADMIN'];
const useRolesOptions = (filter = v => v) => {
  const { data, isLoading } = useCustomRolesList(role => !rolesToExclude.includes(role.name), true);
  return { isLoading, data: data.map(role => ({ title: role.name, value: role.id! })).filter(filter) };
};

export function ManualUpdates({
  config,
  onChange,
  reset,
  projection,
  builtInProjection,
  availableUpdateRolesPerProjection,
}: ManualUpdatesProps) {
  const {
    accountEntities: { aggProjs, fieldMap },
    getPathName,
    featureFlags,
  } = useAvContext();
  const { palette } = useTheme();
  const projUpdateRoles =
    availableUpdateRolesPerProjection.find(v => v.projectionName === projection || v.projectionName === 'default')?.availableRoles || [];
  const hasUpdatePermissions = projUpdateRoles?.length > 0;
  const { data: rolesOptions, isLoading } = useRolesOptions(role => projUpdateRoles?.includes(role.value));
  const initialRoles = rolesOptions.map(role => role.value);

  const { typeMap } = aggProjs[projection];
  const fieldListObjects = useGlobalFilters();

  const queryObjectDimensionField = config.queryObject?.select.dims?.[0]?.name;
  const queryObjectProjection = config.queryObject?.projectionId?.name;
  const disableContentValidationSettings = queryObjectProjection && !aggProjs[queryObjectProjection];
  const uniquePermissionResourcesOptions = usePermissionResourcesOptions();
  const permissionResourcesOrExceptionsM2FF =
    featureFlags[FeatureFlags.PermissionResourceInFieldRegulation] || featureFlags[FeatureFlags.ExceptionsM2];

  return (
    <Box sx={{ ...flex.col, gap: 1 }}>
      <Box sx={theme => ({ ...configFieldStyle(theme), border: 'none' })}>
        <SwitchToggle
          value={config.allowManualUpdate}
          onChange={val => (val ? onChange('allowManualUpdate', val) : reset())}
          label="Allow Manual Updates"
          disabled={!hasUpdatePermissions}
        />
      </Box>
      {config.allowManualUpdate && (
        <>
          <Box sx={configFieldStyle}>
            <Box sx={flex.justifyBetweenCenter}>
              <SwitchToggle
                value={config.forceAllowedRoles}
                onChange={val => {
                  onChange('forceAllowedRoles', val);
                  const allowedRolesIds = val ? initialRoles : [];
                  onChange('allowedRolesIds', allowedRolesIds);
                }}
                label="Allowed Permissions"
              />
            </Box>
            {hasUpdatePermissions && config.forceAllowedRoles && (
              <>
                {!permissionResourcesOrExceptionsM2FF && divider}
                <Select
                  isRequired={!permissionResourcesOrExceptionsM2FF}
                  style={{ width: '100%', maxWidth: 'unset', ...(permissionResourcesOrExceptionsM2FF && { mt: 2.5 }) }}
                  size="small"
                  onChange={val => onChange('allowedRolesIds', val)}
                  options={rolesOptions}
                  value={config.allowedRolesIds}
                  isMultiple
                  loading={isLoading}
                  label="Restrict to Roles"
                  placeholder="Add Role..."
                />
                {permissionResourcesOrExceptionsM2FF && (
                  <>
                    <AvTag text="OR" sx={{ mt: 1.5, mb: 1 }} />
                    <Select
                      style={{ mb: 1.5, width: '100%', maxWidth: 'unset' }}
                      size="small"
                      onChange={val => onChange('allowedResources', val)}
                      options={uniquePermissionResourcesOptions}
                      value={config.allowedResources}
                      isMultiple
                      label="Restrict to Resources"
                      placeholder="Add Resource..."
                    />
                  </>
                )}
              </>
            )}
          </Box>
          <Box sx={configFieldStyle}>
            <Box sx={flex.justifyBetweenCenter}>
              <SwitchToggle value={config.forceComment} onChange={val => onChange('forceComment', val)} label="Require Reason on update" />
              {config.forceComment && (
                <Select
                  muiProps={{
                    componentsProps: { popper: { sx: { width: 150 } } },
                  }}
                  isRequired
                  showInput={false}
                  options={forceCommentOptions}
                  variant="filter"
                  transparent
                  value={config.forceCommentType}
                  size="xSmall"
                  onChange={val => onChange('forceCommentType', val)}
                />
              )}
            </Box>
            {config.forceComment && config.forceCommentType === 'CUSTOM' && (
              <>
                {!permissionResourcesOrExceptionsM2FF && divider}
                <OpenSelect
                  placeholder="Type to add..."
                  size="small"
                  style={{
                    ...(permissionResourcesOrExceptionsM2FF && { mb: 1.5, mt: 2.5 }),
                    '&.MuiAutocomplete-root.autocomplete-multiple': {
                      maxWidth: 'unset',
                      '.MuiFormControl-root': {
                        maxWidth: 'unset',
                      },
                    },
                  }}
                  onChange={val => onChange('forceCommentValues', val)}
                  value={config.forceCommentValues || []}
                  isMultiple
                />
              </>
            )}
          </Box>
          <Box sx={configFieldStyle}>
            <Box sx={flex.justifyBetweenCenter}>
              <SwitchToggle
                value={config.contentValidation}
                onChange={val => onChange('contentValidation', val)}
                label="Content Validation"
              />
              <ToggleButtonGroup
                size="xSmall"
                value={config.useQueryForContentValidation ? 'query' : 'manual'}
                color="white"
                disabled={!config.contentValidation}
                onChange={({ target }) => {
                  if ((target as HTMLInputElement).value === 'query') {
                    if (!config.queryObject) {
                      onChange('queryObject', {
                        ...getDWQueryDefault(),
                        top: { size: interactiveQueryMaxRowsSize },
                        projectionId: { name: projection, builtIn: builtInProjection },
                      });
                    }
                    onChange('useQueryForContentValidation', true);
                  } else {
                    onChange('useQueryForContentValidation', false);
                    onChange('queryObject', null);
                  }
                }}>
                <ToggleButton value="manual">Manual</ToggleButton>
                <ToggleButton value="query">Query</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {config.useQueryForContentValidation && config.queryObject && config.contentValidation && !disableContentValidationSettings ? (
              <Box sx={{ ...flex.col, gap: 2, mt: permissionResourcesOrExceptionsM2FF ? 2.5 : 1 }}>
                <Select
                  label="Field"
                  size="small"
                  isRequired
                  options={fieldListObjects}
                  groupByFunc={o => o.group}
                  value={queryObjectDimensionField}
                  onChange={val =>
                    onChange(
                      'queryObject',
                      setNestedValue(
                        'select.dims.0',
                        {
                          ...config.queryObject,
                          projectionId: { name: fieldMap[val].mainProjId.name, builtIn: fieldMap[val].mainProjId.builtIn },
                        },
                        { name: val }
                      )
                    )
                  }
                />
                <AvFilters
                  dims={fieldListObjects}
                  filters={config.queryObject.filter ? expressionToFilter(typeMap, config.queryObject.filter) : {}}
                  setFilters={newFilters => onChange('queryObject', setNestedValue('filter', config.queryObject, newFilters))}
                  updateFilters={(key, value) =>
                    onChange(
                      'queryObject',
                      setNestedValue(
                        'filter',
                        config.queryObject,
                        filterToExpression(typeMap, {
                          ...(config.queryObject?.filter ? expressionToFilter(typeMap, config.queryObject.filter) : {}),
                          [key]: value,
                        })
                      )
                    )
                  }
                  isFilterExpression
                  activeProjName={projection}
                  displayRecommendedFields={false}
                />
                <ValuesByQueryPreviewTable queryObject={config.queryObject} />
              </Box>
            ) : disableContentValidationSettings ? (
              <Box sx={{ mt: 2 }}>
                {queryObjectProjection === 'statuses' ? (
                  <>
                    Query Content Validation set through
                    <Link
                      style={{ marginLeft: '4px', fontWeight: 500, color: palette.primary.main }}
                      to={getPathName(`${PAGE_PATHS.SETTINGS}/${PAGE_PATHS.TICKETS_STATUSES}`, undefined, APP_PATHS.VULNERABILITIES).to}>
                      Status settings
                    </Link>
                    . Select the Manual option to define a different allowed set of values
                  </>
                ) : (
                  <>Content validation settings are not available for this field</>
                )}
              </Box>
            ) : config.contentValidation ? (
              <>
                {!permissionResourcesOrExceptionsM2FF && divider}
                <OpenSelect
                  placeholder="Type to add..."
                  style={{
                    ...(permissionResourcesOrExceptionsM2FF && { mb: 1.5, mt: 2.5 }),
                    '&.MuiAutocomplete-root.autocomplete-multiple': {
                      maxWidth: 'unset',
                      '.MuiFormControl-root': {
                        maxWidth: 'unset',
                      },
                    },
                  }}
                  size="small"
                  onChange={val => onChange('contentValidationAllowedValues', val)}
                  value={config.contentValidationAllowedValues || []}
                  isMultiple
                />
              </>
            ) : null}
          </Box>
        </>
      )}
      <Box />
    </Box>
  );
}
const divider = <Divider sx={{ mx: -2, mt: 1, mb: 1 }} />;
export const innerLabelStyle = {
  ...flex.itemsCenter,
  color: ({ palette }) => palette.colors.neutrals[600],
  position: 'relative',
  right: 6,
  fontSize: 13,
  fontWeight: 400,
  mr: '2px',
  '.MuiAutocomplete-inputRoot &': { mr: '-6px' },
  ':before': {
    content: '""',
    backgroundColor: ({ palette }) => palette.colors.neutrals[200],
    width: 'calc(100% + 17px)',
    position: 'absolute',
    top: -6,
    bottom: -6,
    left: -9,
    zIndex: -1,
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
  },
};
const startAdornment = label =>
  function innerLabel() {
    return <Box sx={innerLabelStyle}>{label}</Box>;
  };

interface ExpandButtonProps {
  value?: boolean;
  onChange?(val: boolean): unknown;
  disabled?: boolean;
  style?: SxProps;
  label?: React.ReactNode;
}

export function ExpandButton({ value = false, onChange = noop, disabled = false, style = {}, label }: ExpandButtonProps) {
  return (
    <>
      <IconButton
        disabled={disabled}
        sx={{
          svg: {
            rotate: value ? null : '-90deg',
            transition: ({ transitions }) =>
              transitions.create(['rotate'], {
                duration: transitions.duration.short,
              }),
          },
          ...style,
        }}
        onClick={() => onChange(!value)}>
        {expandIcon}
      </IconButton>
      {label && (
        <Box sx={{ cursor: 'pointer' }} onClick={() => onChange(!value)}>
          {label}
        </Box>
      )}
    </>
  );
}

const dot = <Box sx={{ color: ({ palette }) => palette.colors.neutrals[500] }}>•</Box>;

interface AddFormulaProps {
  options: FormulaType[];
  onAdd(newFormula: FormulaType): void;
}

export function AddFormula({ options, onAdd }: AddFormulaProps) {
  const renderOption = ({ aggName, func, args }) => (
    <Box sx={{ ...flex.row, gap: 4 }}>
      <AvTooltip sx={{ fontWeight: 600, width: 200 }}>{aggName}</AvTooltip>
      <Box sx={{ ...flex.row, gap: 1 }}>
        <span>{func}</span>
        {args.map(({ stringValue }) => (
          <>
            {dot}
            <span key={stringValue}>{stringValue}</span>
          </>
        ))}
      </Box>
    </Box>
  );

  const filterFunc = (options, { inputValue }) =>
    options.filter(o =>
      [o.aggName, o.func, ...o.args.map(({ stringValue }) => stringValue)].join(' ').toLowerCase().includes(inputValue.toLowerCase())
    );

  return (
    <Select
      label={<>{plusIcon}ADD FORMULA</>}
      options={options}
      variant="outlined"
      size="xSmall"
      renderOption={renderOption}
      getLabelFunc={({ aggName }) => aggName}
      onChange={(_, formula) => onAdd(formula)}
      onCreateNew={onAdd}
      filterFunc={filterFunc}
      style={{
        pl: '10px',
        color: ({ palette }) => palette.primary.main,
        '> svg': { display: 'none' },
      }}
      muiProps={{
        componentsProps: {
          popper: { sx: { '.MuiAutocomplete-listbox': { maxWidth: 'unset' } } },
        },
        noOptionsText: 'No functions',
      }}
    />
  );
}

export const formulaStyle = (isEditable, isExpanded) => ({
  ...flex.itemsCenter,
  gap: 1,
  pb: '4px',
  pt: '5px',
  px: 2,
  borderBottom: ({ palette }) => (isExpanded ? `1px solid ${palette.colors.neutrals[350]}` : '1px solid transparent'),
  '.MuiAutocomplete-root, .MuiFormControl-root': { maxWidth: 'unset' },
  '> div .MuiButtonBase-root, .MuiInputBase-root :before': {
    transition: theme => theme.transitions.create(['color', 'background-color', 'opacity']),
  },
  ...((!isExpanded || !isEditable) && {
    [isEditable ? '&:not(:hover)' : '&']: {
      '.MuiInputBase-root:not(.Mui-focused):not(.Mui-error)': {
        pointerEvents: 'none',
        '& :before': {
          backgroundColor: 'transparent',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '.MuiAutocomplete-endAdornment .MuiButtonBase-root, [aria-label="Clear"]': {
          opacity: 0,
        },
        '.MuiInputBase-input.Mui-disabled': {
          color: theme => theme.palette.colors.neutrals[550],
          '~ .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  }),
});

type FormulaType = {
  aggName: string;
  func: string;
  args: { stringValue }[];
  filter: Filter;
  builtIn: boolean;
};

type FormulaProps = {
  index: number;
  error?: string;
  setError: (str?: string) => void;
  activeItem: ModelRowItem;
  onChange: (value: FormulaType[]) => void;
  allFieldAggregations: FormulaType[];
  formulas: FormulaType[];
  isExpanded: boolean;
  toggleExpandFormula: (index: number) => void;
  getFieldsWithSameName: (aggName: string) => any[];
} & FormulaType;

const fillArgsArray = (funcTypesMap, func: string, args: { stringValue }[], defaultValue?: string) =>
  funcTypesMap[func].argNames.map((_, i) => args[i] || { stringValue: defaultValue });

function Formula({
  index,
  error,
  setError,
  aggName,
  func,
  args,
  filter,
  activeItem,
  onChange,
  allFieldAggregations,
  formulas,
  builtIn,
  getFieldsWithSameName,
  isExpanded,
  toggleExpandFormula,
}: FormulaProps) {
  const { data: { funcTypes } = {} } = useProjEvaluations();
  const { aggProjs } = useAvContext().accountEntities;
  const isEditable = !builtIn;

  const getDuplicatedNameTooltip = () => {
    const fieldsWhereNameIsUsed = getFieldsWithSameName(aggName);
    return isEditable && fieldsWhereNameIsUsed.length
      ? `changes will also apply to fields: ${fieldsWhereNameIsUsed.map(({ field }) => field.name).join(',')}`
      : undefined;
  };
  const [duplicatedNameTooltip, setDuplicatedNameTooltip] = useState(getDuplicatedNameTooltip());
  const onAliasBlur = () => {
    if (allFieldAggregations.find(f => f.aggName === aggName && f.builtIn && !builtIn)) {
      setError('Alias in use in built-in formula');
      setDuplicatedNameTooltip(undefined);
    } else {
      setError(undefined);
      setDuplicatedNameTooltip(getDuplicatedNameTooltip());
    }
  };

  const filterOptions = aggProjs[activeItem.projectionName].fieldList.SPF;
  const typeMap = filterOptions.reduce((acc, { value, type }) => ({ ...acc, [value]: type }), {});

  const funcTypesMap = funcTypes.reduce((obj, item) => ({ ...obj, [item.value]: item }), {});
  const argsArray = fillArgsArray(funcTypesMap, func, args, filterOptions[0].value);

  const updateFormula = (f, key, val) => {
    const newFormula = { ...f, [key]: val };
    return ['func', 'args'].includes(key)
      ? {
          ...newFormula,
          aggName: `${newFormula.func?.toLowerCase() || ''}_${newFormula.args[0]?.stringValue?.replaceAll('.', '_') || ''}`,
          args: fillArgsArray(funcTypesMap, newFormula.func || func, newFormula.args, newFormula.args[0]?.stringValue),
        }
      : newFormula;
  };
  const onFormulaChange = key => val => onChange(formulas.map((f, i) => (i === index ? updateFormula(f, key, val) : f)));
  const onFormulaDelete = () => onChange(formulas.filter((f, i) => i !== index));
  const onChangeArgsAt = index => stringValue => onFormulaChange('args')(argsArray.with(index, { stringValue }));

  return (
    <Box
      sx={{
        border: ({ palette }) => `1px solid ${palette.colors.neutrals[350]}`,
        borderRadius: '6px',
      }}>
      <Box sx={formulaStyle(isEditable, isExpanded)}>
        <ExpandButton
          value={isExpanded}
          onChange={() => toggleExpandFormula(index)}
          style={!isEditable && !filter.expression ? { pointerEvents: 'none', opacity: 0 } : undefined}
        />
        <Select
          startAdornment={startAdornment(functionIcon)}
          value={func}
          onChange={onFormulaChange('func')}
          size="small"
          options={funcTypes}
          disabled={!isEditable}
          isRequired
        />
        {args[0]?.stringValue?.endsWith('.model') ? (
          <TextInput
            startAdornment={startAdornment('Projection')}
            value={args[0].stringValue.split('.model')[0]}
            onChange={onChangeArgsAt(0)}
            size="small"
          />
        ) : (
          <Select
            startAdornment={startAdornment('Field')}
            value={args[0]?.stringValue}
            onChange={onChangeArgsAt(0)}
            size="small"
            options={filterOptions}
            disabled={!isEditable}
            groupByFunc={({ group }) => group}
            isRequired
          />
        )}
        <TextInput
          startAdornment={startAdornment('Alias')}
          value={aggName}
          onChange={onFormulaChange('aggName')}
          size="small"
          sx={{ '.MuiInputBase-input': { fontWeight: 600 } }}
          onBlur={onAliasBlur}
          error={error}
          disabled={!isEditable}
          tooltipContent={
            error ? (
              <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                <ErrorIcon />
                {error}
              </Box>
            ) : undefined
          }
        />
        <Box sx={{ ...flex.itemsCenter, gap: 1.5, pl: 1 }}>
          <Box sx={{ ...flex.itemsCenter, ...(duplicatedNameTooltip ? { opacity: 1 } : { opacity: 0, pointerEvents: 'none' }) }}>
            <AvMessagePopover message={duplicatedNameTooltip} icon={warningIcon} asTooltip />
          </Box>
          <IconButton
            sx={
              {
                color: ({ palette }) => (isExpanded ? palette.primary.main : palette.colors.neutrals[600]),
                visibility: isEditable ? undefined : 'hidden',
              } as any
            }
            onClick={() => toggleExpandFormula(index)}>
            {editBigIcon}
          </IconButton>
          <AvTooltip title="Remove formula">
            <IconButton onClick={onFormulaDelete} sx={{ color: ({ palette }) => palette.colors.neutrals[600] }}>
              {deleteIcon}
            </IconButton>
          </AvTooltip>
        </Box>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box sx={{ ...flex.col, gap: 2, p: 2 }}>
          {argsArray.length > 1 && (
            <Box sx={{ ...flex.col, gap: 1 }}>
              <Box sx={{ fontWeight: 500 }}>Parameters</Box>
              <Box sx={{ ...flex.row, gap: 1 }}>
                {argsArray.slice(1).map(({ stringValue }, index) => (
                  <Select
                    key={funcTypesMap[func].argNames[index + 1]}
                    label={funcTypesMap[func].argNames[index + 1]}
                    value={stringValue}
                    onChange={onChangeArgsAt(index + 1)}
                    size="small"
                    style={{ width: 190 }}
                    options={filterOptions}
                    isRequired
                  />
                ))}
              </Box>
            </Box>
          )}
          <Box>
            <AvFilters
              updateFilters={(key, val) => onFormulaChange('filter')(updateFilterInExpression(typeMap, { [key]: val }, filter))}
              setFilters={newFilters => onFormulaChange('filter')(filterToExpression(typeMap, newFilters))}
              size="xSmall"
              disabled={!isEditable}
              dims={filterOptions}
              usage="SPF"
              activeProjName={activeItem.projectionName}
              filters={expressionToFilter(typeMap, filter)}
              onClearFilters={filter.and && (() => onFormulaChange('filter')({}))}
              moreFiltersLabel={
                <Box sx={flex.itemsCenter}>
                  {plusIcon}
                  {filterIcon}
                </Box>
              }
              displayRecommendedFields={false}
            />
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}

const createNewFormula = index => ({
  aggName: `New_${index + 1}`,
  args: [] as { stringValue }[],
  filter: {},
  func: 'MAX',
  builtIn: false,
});

const freezeFirstLine = editor => {
  editor.onDidChangeModelContent(({ isUndoing, changes, eol }) => {
    if (
      !isUndoing &&
      !changes[0]?.forceMoveMarkers &&
      (changes[0]?.range?.startLineNumber === 1 || changes[0]?.range?.endLineNumber === 1)
    ) {
      editor.trigger('undo', 'undo');
      if (changes[0].text.startsWith(eol)) {
        editor.setPosition({ lineNumber: 2, column: 0 });
        editor.setScrollPosition({ scrollLeft: 0 });
      }
    }
  });
};

const generateScriptSignature = (fieldName, formulas, type) =>
  `def eval_${fieldName.replaceAll('.', '_')}(${[
    'this',
    'settings',
    'shared_context',
    ...formulas.map(({ aggName }) => aggName),
    '*args',
    '**kwargs',
  ].join(', ')}) -> ${getPythonType(type)}:\n`;

const PYTHON_VALUE = 'PYTHON_SCRIPT_PER_FIELD';
const CEL_VALUE = 'CEL';
const evalStrategyOptions = [
  { value: PYTHON_VALUE, title: 'Python' },
  { value: CEL_VALUE, title: 'CEL' },
];
const ORIGIN = {
  METADATA: 'METADATA',
  BUILT_IN_MODEL: 'BUILT_IN_MODEL',
  CUSTOM_MODEL: 'CUSTOM_MODEL',
};

const DEFAULT_FALLBACK_ERROR = {
  none: 'Error occurred due to NULL value',
  error: 'Error occurred during field evaluation',
};

const getFallbackObj = (key, type = 'failMessage', value?) => ({
  type,
  value: value !== undefined ? value : type === 'failMessage' ? DEFAULT_FALLBACK_ERROR[key] : '',
});
const defaultValueOptions = [
  { title: 'Set Value', value: 'value' },
  { title: 'Fail & Set Error', value: 'failMessage' },
  { title: 'Set Null', value: 'null' },
];

const getDefaultFallbackStrategy = () => ({
  errorFallback: getFallbackObj('error'),
  noneFallback: getFallbackObj('none', 'null'),
});

const newScript = '\n    return None';
const getNewField = activeItem => ({
  script: newScript,
  aggNames: [],
  fallbackStrategy: getDefaultFallbackStrategy(),
  field: {
    name: activeItem.systemName,
    type: `TYPE_${FieldTypeEnum[activeItem.type]}`,
    origin: activeItem.isMetadataField ? ORIGIN.METADATA : activeItem.builtIn ? ORIGIN.BUILT_IN_MODEL : ORIGIN.CUSTOM_MODEL,
  },
});

const getNormalizedErrorFallback = fallbackObj =>
  fallbackObj.failMessage || isObject(fallbackObj.value)
    ? {
        type: fallbackObj.value?.value === null ? 'null' : Object.keys(fallbackObj)[0],
        value: fallbackObj.failMessage || fallbackObj.value.value,
      }
    : fallbackObj;
const normalizeFallback = fallbackStrategy => ({
  errorFallback: getNormalizedErrorFallback(fallbackStrategy.errorFallback),
  noneFallback: getNormalizedErrorFallback(fallbackStrategy.noneFallback),
});

const hasEmptyStrategyValue = ({ errorFallback, noneFallback }) =>
  (errorFallback.type !== 'null' && isFieldEmpty(errorFallback.value) && errorFallback.type) ||
  (noneFallback.type !== 'null' && isFieldEmpty(noneFallback.value) && noneFallback.type);

type Change = { fieldScript; fieldAggregations };
type SetChangesFunc = (change: Change, projId: any, shouldRemove: boolean) => void;
type ProjEval = { projId; config: { fieldScripts; fieldAggregations } };

interface CalculationProps {
  activeItem: ModelRowItem;
  setOnSaveFunc: (func?: (fieldMetadata: any) => void | any[]) => void;
  setOnResetFunc: (func?: () => void) => void;
  setChanges: SetChangesFunc;
  changes: ProjEval[];
}
function Calculation({ activeItem, setOnSaveFunc, setOnResetFunc, setChanges, changes }: CalculationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [expandedSections, setExpandedSections] = useState({ formulas: true, script: true, defaultValues: true });
  const [expandedFormulas, setExpandedFormulas] = useState({});
  const [errors, setErrors] = useState({});
  const [lastScript, setLastScript] = useState('');

  const { data: { data } = {} } = useProjEvaluations();
  const findByProj = ({ projId }) => activeItem.projectionName === projId.name;
  const getFieldFromConfig = config => config?.fieldScripts.find(({ field }) => field.name === activeItem.systemName);
  const getField = config => getFieldFromConfig(config) || getNewField(activeItem);
  const originalFieldObj = data?.find(findByProj);
  const changeProjObj = changes.find(findByProj);
  const fieldObj = getFieldFromConfig(changes.find(findByProj)?.config) ? changeProjObj : originalFieldObj;
  const field = getField(fieldObj.config);
  const allFieldAggregations = [...(changeProjObj?.config.fieldAggregations || []), ...(originalFieldObj?.config.fieldAggregations || [])];
  const allFieldScripts = [...(changeProjObj?.config.fieldScripts || []), ...(originalFieldObj?.config.fieldScripts || [])];

  const getInitialData = (field, fieldAggregations) => {
    const formulas = field.aggNames.map(name => {
      const formula = fieldAggregations.find(({ aggName }) => aggName === name);
      if (!formula) {
        const error = `${ErrorTypes.Setup}: Formula ${name} not found`;
        console.error(error);
        enqueueSnackbar(error, { variant: 'error' });
        return { ...createNewFormula(field.aggNames.length), aggName: name };
      }
      return formula;
    });
    return {
      evalStrategy: field.evalStrategy || PYTHON_VALUE,
      script:
        field.evalStrategy === CEL_VALUE
          ? field.script
          : field.script.replace(/^.*\n/, generateScriptSignature(field.field.name, formulas, activeItem.type)),
      formulas,
      fallbackStrategy: field.fallbackStrategy ? normalizeFallback(field.fallbackStrategy) : getDefaultFallbackStrategy(),
    };
  };

  const [newData, setNewData] = useState(() => getInitialData(field, allFieldAggregations));
  useEffect(() => {
    setNewData(getInitialData(field, allFieldAggregations));
    if (field.evalStrategy === CEL_VALUE) {
      setLastScript(getInitialData({ ...field, evalStrategy: PYTHON_VALUE, script: newScript }, allFieldAggregations).script);
    }
  }, [activeItem]);

  const updateScriptFirstLine = (script, formulas) =>
    script.replace(/^.*\n/, generateScriptSignature(field.field.name, formulas, activeItem.type));

  const onChange = fieldKey => value => {
    const data = { ...newData, [fieldKey]: value };
    if (fieldKey === 'evalStrategy') {
      setNewData({
        ...data,
        script: lastScript,
      });
      setLastScript(data.script);
    } else if (data.evalStrategy !== PYTHON_VALUE) {
      setLastScript(updateScriptFirstLine(lastScript, data.formulas));
      setNewData(data);
    } else {
      setNewData({ ...data, script: updateScriptFirstLine(data.script, data.formulas) });
    }
  };
  const onFormulaAdd = (newFormula = createNewFormula(allFieldAggregations.length)) => {
    onChange('formulas')([...newData.formulas, newFormula]);
    if (!getFieldsWithSameName(newFormula.aggName).length && !newFormula.builtIn) {
      toggleExpandFormula(newData.formulas.length);
    }
  };

  const toggleExpandFormula = index => setExpandedFormulas(prev => ({ ...prev, [index]: !prev[index] }));

  const onChangeFallback = key => val =>
    onChange('fallbackStrategy')({
      ...newData.fallbackStrategy,
      [`${key}Fallback`]: getFallbackObj(key, newData.fallbackStrategy[`${key}Fallback`].type, val),
    });

  const projId = {
    name: activeItem.projectionName,
    builtIn: activeItem.entityTypeId.builtIn,
  };

  const getFieldsWithSameName = aggName =>
    allFieldScripts.filter(({ field, aggNames }) => field.name !== activeItem.systemName && aggNames.includes(aggName));
  useEffect(() => {
    setOnSaveFunc(fieldMetadata => {
      if (Object.values(errors).filter(Boolean).length) {
        // document.querySelector('.Mui-error').dispatchEvent(new Event('mouseover'));
        return undefined;
      }
      const emptyStrategyType = hasEmptyStrategyValue(newData.fallbackStrategy);
      if (emptyStrategyType) {
        enqueueSnackbar(`Default ${emptyStrategyType === 'value' ? 'value' : 'message'} is missing`, { variant: 'error' });
        return undefined;
      }
      if (fieldMetadata || !isDeepEqual(getInitialData(field, allFieldAggregations), newData)) {
        return setChanges(
          {
            fieldAggregations: newData.formulas.filter(({ builtIn }) => !builtIn),
            fieldScript: {
              field: field.field,
              aggNames: newData.formulas.map(({ aggName }) => aggName),
              script: newData.script,
              fallbackStrategy: newData.fallbackStrategy,
              evalStrategy: newData.evalStrategy,
              fieldMetadata,
            },
          },
          projId,
          !fieldMetadata && isDeepEqual(getInitialData(getField(originalFieldObj.config), allFieldAggregations), newData)
        );
      }
      return changes;
    });

    setOnResetFunc(
      isDeepEqual(getInitialData(getField(originalFieldObj.config), allFieldAggregations), newData)
        ? undefined
        : () => {
            setChanges(
              {
                fieldAggregations: [],
                fieldScript: field,
              },
              projId,
              true
            );
            setNewData(getInitialData(getField(originalFieldObj.config), originalFieldObj.config.fieldAggregations));
          }
    );
  }, [changes, newData, setOnSaveFunc, errors]);

  return (
    <Box sx={{ ...flex.col, gap: 2, pt: 1, pb: 3 }}>
      <Box sx={{ ...flex.justifyBetween, gap: 2 }}>
        <Typography
          variant="h5"
          sx={{ ...flex.itemsCenter, gap: 1, fontSize: 16, pointerEvents: newData.formulas.length ? undefined : 'none' }}>
          <ExpandButton
            value={newData.formulas.length === 0 ? false : expandedSections.formulas}
            onChange={val => setExpandedSections({ ...expandedSections, formulas: val })}
            label={`Formulas (${newData.formulas.length})`}
          />
        </Typography>
        <AddFormula
          options={allFieldAggregations.filter(({ aggName }) => !newData.formulas?.find(f => f.aggName === aggName))}
          onAdd={onFormulaAdd}
        />
      </Box>
      <Collapse in={newData.formulas.length === 0 ? false : expandedSections.formulas} timeout="auto" unmountOnExit>
        <Box sx={{ ...flex.col, gap: 1 }}>
          {newData.formulas?.map((formula, index) => (
            <Formula
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              index={index}
              error={errors[index]}
              setError={error => setErrors({ ...errors, [index]: error })}
              {...formula}
              getFieldsWithSameName={getFieldsWithSameName}
              activeItem={activeItem}
              onChange={onChange('formulas')}
              allFieldAggregations={allFieldAggregations}
              formulas={newData.formulas}
              originalConfig={originalFieldObj.config}
              isExpanded={!!expandedFormulas[index]}
              toggleExpandFormula={toggleExpandFormula}
            />
          ))}
        </Box>
      </Collapse>
      <Box sx={{ ...flex.justifyBetween, gap: 2 }}>
        <Typography variant="h5" sx={{ ...flex.itemsCenter, gap: 1, fontSize: 16 }}>
          <ExpandButton
            value={expandedSections.script}
            onChange={val => setExpandedSections({ ...expandedSections, script: val })}
            label="Editor"
          />
        </Typography>
        <ToggleButtonGroup
          color="white"
          size="xSmall"
          value={newData.evalStrategy}
          onChange={(_, value) => onChange('evalStrategy')(value)}
          exclusive>
          {evalStrategyOptions.map(({ value, title }) => (
            <ToggleButton key={value} value={value} sx={{ pointerEvents: value === newData.evalStrategy ? 'none' : undefined }}>
              {title}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <Collapse in={expandedSections.script} timeout="auto" unmountOnExit>
        <CodeEditor
          key={newData.evalStrategy}
          value={newData.script}
          onChange={onChange('script')}
          onMount={newData.evalStrategy === PYTHON_VALUE ? freezeFirstLine : undefined}
          height={400}
        />
      </Collapse>
      <Box sx={{ ...flex.justifyBetween, gap: 2, mt: 1 }}>
        <Typography variant="h5" sx={{ ...flex.itemsCenter, gap: 1, fontSize: 16 }}>
          <ExpandButton
            value={expandedSections.defaultValues}
            onChange={val => setExpandedSections({ ...expandedSections, defaultValues: val })}
            label="Default values for exception & none cases"
          />
        </Typography>
      </Box>
      <Collapse in={expandedSections.defaultValues} timeout="auto" unmountOnExit>
        <Box sx={{ ...flex.col, gap: 1 }}>
          {['none', 'error'].map(key => (
            <Box key={key} sx={{ ...flex.itemsCenter, gap: 2 }}>
              <Select
                label={`If ${key === 'none' ? 'Null' : 'Error'}`}
                options={defaultValueOptions}
                value={newData.fallbackStrategy[`${key}Fallback`].type}
                onChange={type =>
                  onChange('fallbackStrategy')({ ...newData.fallbackStrategy, [`${key}Fallback`]: getFallbackObj(key, type) })
                }
                size="small"
                style={{ width: 150 }}
                muiProps={{ disableClearable: true }}
              />
              {newData.fallbackStrategy[`${key}Fallback`].type !== 'null' &&
                (newData.fallbackStrategy[`${key}Fallback`].type === 'value' &&
                fieldTypeLabelMap[getFieldKey(activeItem.type)].label === 'Boolean' ? (
                  <ToggleButtonGroup
                    sx={{ alignSelf: 'end' }}
                    color="white"
                    size="small"
                    value={newData.fallbackStrategy[`${key}Fallback`].value}
                    onChange={(_, value) => onChangeFallback(key)(value)}
                    exclusive>
                    <ToggleButton value disabled={newData.fallbackStrategy[`${key}Fallback`].value}>
                      True
                    </ToggleButton>
                    <ToggleButton value={false} disabled={!newData.fallbackStrategy[`${key}Fallback`].value}>
                      False
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  <TextInput
                    label={Object.keys(newData.fallbackStrategy[`${key}Fallback`])[0] === 'failMessage' ? 'Message' : 'Value'}
                    value={newData.fallbackStrategy[`${key}Fallback`].value}
                    onChange={onChangeFallback(key)}
                    type={
                      newData.fallbackStrategy[`${key}Fallback`].type === 'value' &&
                      fieldTypeLabelMap[getFieldKey(activeItem.type)].label === 'Number'
                        ? 'number'
                        : undefined
                    }
                    size="small"
                    style={{ width: 350, maxWidth: 'unset' }}
                  />
                ))}
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
}

const tabsEntityField = [
  {
    value: 'function',
    label: (
      <Box sx={{ ...flex.row, gap: 1 }}>
        <Function style={iconSize(18)} />
        Calculation
      </Box>
    ),
  },
  { value: 'manual', label: 'Manual Updates' },
  { value: 'usage', label: 'Usage' },
];
const configFieldStyle = ({ palette }) => ({
  ...flex.colJustifyCenter,
  minHeight: 42,
  borderRadius: '6px',
  px: 2,
  py: 1,
  border: `1px solid ${palette.colors.neutrals[350]}`,
  '.autocomplete-multiple': {
    maxWidth: '100%',
    width: '100%',
    '.MuiFormControl-root': { maxWidth: 'unset' },
  },
});

const moveCursorToEnd = (element: HTMLElement, endIndex: number) => {
  const selection = window.getSelection()!;
  const range = document.createRange();
  range.setStart(element.childNodes[0]!, endIndex);
  range.collapse(true);
  selection.removeAllRanges();
  selection.addRange(range);
};

interface FieldInfoProps {
  onClose: (id?: string) => void;
  refetch: () => void;
  activeItem?: ModelRowItem;
  changes: ProjEval[];
  setChanges: SetChangesFunc;
  setOnSaveField: any;
  onPreview: () => void;
}
export function FieldInfo({ onClose, activeItem, changes, setChanges, setOnSaveField, refetch, onPreview }: FieldInfoProps) {
  const {
    layoutWidths: { sideBar: sidebarWidth = drawerWidth },
  } = useContext(LayoutContext);
  const { palette } = useTheme();
  const {
    accountEntities,
    userPermissions: { isInternalRole, hasAllowedPermissionToResource },
  } = useAvContext();
  const [resetCalculationToOriginal, setResetCalculationToOriginal] = useState<() => void>();
  const [hidden, setHidden] = useState(activeItem?.visibilityConfig?.config.hidden);
  const [allowOverride, setAllowOverride] = useState(false);
  const { data: { fieldRegulations, availableUpdateRolesPerProjection } = {}, isLoading: isLoadingFieldRegulations } =
    useFieldRegulationsData(d => d);
  const { data: { data: projEvaluations } = {}, isLoading: isLoadingProjEval } = useProjEvaluations();
  const originalFieldObj = activeItem && projEvaluations?.find(({ projId }) => activeItem.projectionName === projId.name);
  const { saveManualUpdates, isLoading: isSaving } = useSaveManualUpdates();
  const fieldRegulation =
    fieldRegulations &&
    activeItem &&
    fieldRegulations.find(
      regulation => regulation.projectionName === activeItem.projectionName && regulation.fieldName === activeItem.systemName
    );
  const [tab, setTab] = useState(tabsEntityField[0].value);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [config, setConfig] = useState({
    ...initialConfig,
    ...(fieldRegulation?.config || {}),
  });
  const { mutate: updateFieldVisibility, isPending: isUpdatingVisibility } = useUpdateFieldVisibility(() => setHidden(prev => !prev));
  const { data: fieldSyncStatus } = useIsFieldSyncedToSetting(activeItem && `${activeItem.projectionName}.${activeItem.systemName}`);
  const titleRef = useRef<HTMLElement>();
  const [fieldsData, setFieldsData] = useState<any>();
  const onDisplayNameChange = e =>
    setFieldsData({
      ...(fieldsData || {}),
      displayName: e.currentTarget.textContent,
      name: activeItem!.systemName,
      entityTypeId: activeItem!.entityTypeId,
      type: activeItem!.type,
    });
  const setOnSaveFunc = useMemo(() => func => setOnSaveField(() => () => func(fieldsData)), [fieldsData]);

  const hasChanges = fieldsData || resetCalculationToOriginal;
  const resetToOriginal = () => {
    resetCalculationToOriginal?.();
    setFieldsData(undefined);
    titleRef.current!.innerHTML = activeItem!.displayName;
  };

  const hasRegulationChanges = !isDeepEqual(config, fieldRegulation?.config || {});
  useEffect(() => {
    setConfig({ ...initialConfig, ...(fieldRegulation?.config || {}) });
    setAllowOverride(false);
  }, [fieldRegulations, activeItem]);

  const updateConfig = (key, val) => setConfig(prev => ({ ...prev, [key]: val }));

  const { menuOptions = [] } = useAuditNavigation({
    id: `${activeItem?.id}?projName=${activeItem?.projectionName}`,
    path: PAGE_PATHS.MODEL_MANAGEMENT,
  });

  const onSaveManualUpdates = () => {
    saveManualUpdates(
      fieldRegulation
        ? { ...fieldRegulation, config }
        : {
            projectionName: activeItem!.projectionName,
            builtInProjection: true,
            fieldName: activeItem!.systemName,
            config: {
              ...config,
              queryObject: config.queryObject ? { ...config.queryObject, filter: cleanEmptyFilters(config.queryObject.filter) } : null,
            },
          }
    );
  };
  const isCalculationTab = tab === tabsEntityField[0].value;
  const isSyncedToSettingsAndNotOverride = !allowOverride && fieldSyncStatus?.synced;
  const hasFieldVisibilityUpdatePermissions =
    hasAllowedPermissionToResource({ resource: PermissionEntitiesNames.MODEL, permission: Permission.CREATE }) ||
    hasAllowedPermissionToResource({ resource: PermissionEntitiesNames.MODEL, permission: Permission.UPDATE });
  return !activeItem || isLoadingProjEval || isLoadingFieldRegulations ? (
    <AvSidePanel storageKey="modelManagementInfo" />
  ) : (
    <AvSidePanel
      storageKey="modelManagementInfo"
      isOpen={!!activeItem}
      onClose={onClose}
      maxWidthOffset={sidebarWidth}
      footerComponent={
        <Box sx={{ ...flex.justifyBetweenCenter, width: '100%', gap: 1 }}>
          {!activeItem.builtIn ? (
            <Button color="error" size="small" variant="contained" onClick={() => setOpenDeleteModal(true)}>
              Delete
            </Button>
          ) : (
            <Box />
          )}
          {isCalculationTab ? (
            <Box sx={{ ...flex.row, gap: 1, width: '100%' }}>
              {hasChanges && (
                <Button size="small" variant="outlined" onClick={resetToOriginal}>
                  {undoIcon}Revert
                </Button>
              )}
              <span style={{ flex: 1 }} />
              <Button size="small" onClick={onPreview} variant="outlined">
                {tableIcon}Preview
              </Button>
              <Button size="small" variant="contained" onClick={() => onClose()}>
                Done
              </Button>
            </Box>
          ) : hasRegulationChanges && !isCalculationTab ? (
            <Box sx={{ ...flex.row, gap: 1 }}>
              <Button disabled={isSaving} size="small" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button disabled={isSaving} size="small" variant="contained" onClick={onSaveManualUpdates}>
                Save
              </Button>
            </Box>
          ) : null}
        </Box>
      }
      menuOptions={isInternalRole ? menuOptions : undefined}
      topNavRightComponent={
        <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
          {!hasChanges && originalFieldObj?.config.fieldScripts.every(({ field }) => field.name !== activeItem.systemName) ? (
            <Alert icon={infoFullIcon} className="grey-small">
              Field is not populated
            </Alert>
          ) : null}
          <NavigationBar id={activeItem.id} />
          {isInternalRole && <Divider flexItem orientation="vertical" />}
        </Box>
      }
      headComponent={
        <Box>
          <Box sx={{ pb: 2, ...flex.col, gap: 2 }}>
            <Box sx={{ ...flex.justifyBetweenCenter, gap: 1, position: 'relative' }}>
              <Typography component="span" variant="h5" sx={{ ...flex.itemsCenter, gap: 2 }}>
                <Box
                  ref={titleRef}
                  sx={activeItem.isMetadataField ? undefined : editFieldStyle}
                  contentEditable={!activeItem.isMetadataField}
                  onInput={onDisplayNameChange}
                  onKeyDown={e => e.stopPropagation()}
                  spellCheck="false"
                  suppressContentEditableWarning>
                  {activeItem.displayName}
                </Box>
                {!activeItem.isMetadataField && (
                  <AvTooltip title="Edit">
                    <IconButton
                      size="medium"
                      onClick={() => {
                        titleRef.current!.focus();
                        moveCursorToEnd(titleRef.current!, activeItem!.displayName.length);
                      }}>
                      {editIcon}
                    </IconButton>
                  </AvTooltip>
                )}
                {hasChanges && <Box sx={{ height: '100%', ...flex.itemsCenter }}>{editedTag()}</Box>}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ ...flex.justifyBetween, gap: 1 }}>
            <Box
              sx={{
                display: 'grid',
                gridColumn: 2,
                gridTemplateColumns: '150px auto',
                rowGap: 2,
                columnGap: 2,
                alignItems: 'center',
              }}>
              <Typography variant="h7">System Name</Typography>
              <div>
                {activeItem.projectionName}.{activeItem.systemName}
              </div>
              <Typography variant="h7">Field Type</Typography>
              <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                {fieldTypeIconsMap(palette)[getFieldKey(activeItem.type)]} {fieldTypeLabelMap[getFieldKey(activeItem.type)]?.label}
              </Box>
              <Typography variant="h7">Aggregates</Typography>
              <Box
                sx={{
                  ...flex.itemsCenter,
                  gap: 1,
                  '&:hover , &:hover *': { color: palette.colors.primary[500], textDecoration: 'underline' },
                }}>
                {originalFieldObj?.proj.aggregates.name}
                <InvestigateEntityButton projName={activeItem.projectionName} />
                <AvMessagePopover
                  message={
                    <Box>
                      you are working on <span style={{ fontWeight: 600 }}>{activeItem.entityTypeId.name}</span> entity level field, which
                      aggregates the <span style={{ fontWeight: 600 }}>Merged Entities</span> entity level.
                    </Box>
                  }
                  icon={questionFullIcon}
                  asTooltip
                />
              </Box>
              <>
                <Typography variant="h7">Visibility</Typography>
                <AvTooltip title="Access is denied" disabled={hasFieldVisibilityUpdatePermissions}>
                  <ToggleButtonGroup
                    color="white"
                    size="xSmall"
                    style={{ width: 'fit-content' }}
                    disabled={!hasFieldVisibilityUpdatePermissions || isUpdatingVisibility}
                    value={hidden ? 'true' : 'false'}
                    onChange={(e, value) => {
                      const v: VisibilityConfig = {
                        projectionName: activeItem?.projectionName,
                        builtInProjection: activeItem?.builtInProjection as boolean,
                        fieldName: activeItem?.systemName,
                        config: { hidden: value === 'true' },
                      };
                      if (activeItem?.visibilityConfig?.id) {
                        v.id = activeItem.visibilityConfig.id;
                      }
                      setHidden(value === 'true');
                      updateFieldVisibility(v, { onSuccess: () => accountEntities.refetch() });
                    }}
                    exclusive>
                    <ToggleButton value="false">
                      <AvTooltip title="Visible">
                        <Box sx={flex.col}>{showIcon}</Box>
                      </AvTooltip>
                    </ToggleButton>
                    {/* eslint-disable-next-line react/jsx-boolean-value */}
                    <ToggleButton value="true">
                      <AvTooltip title="Hidden">
                        <Box sx={flex.col}>{hideIcon}</Box>
                      </AvTooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </AvTooltip>
              </>
            </Box>
          </Box>
        </Box>
      }>
      <Tabs sx={{ pt: 2, flexShrink: 0 }} value={tab} onChange={(e, val) => setTab(val)}>
        {tabsEntityField.map(({ value, label }) => (
          <Tab key={value} value={value} label={label} />
        ))}
      </Tabs>
      {isSyncedToSettingsAndNotOverride && isCalculationTab && (
        <AvAttentionBox
          small
          content={
            <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
              Configured through {SettingsName[fieldSyncStatus.settingsType]}
              <Button variant="outlined" size="xSmall" onClick={() => setAllowOverride(true)}>
                Unlink & Override
              </Button>
            </Box>
          }
          variant={AttentionBoxVariant.Warning}
        />
      )}
      <Box sx={{ py: 1, ...(isCalculationTab && isSyncedToSettingsAndNotOverride ? formDisabledStyle : {}) }}>
        {isCalculationTab && (
          <Calculation
            activeItem={activeItem}
            setOnSaveFunc={setOnSaveFunc}
            setOnResetFunc={func => setResetCalculationToOriginal(() => func)}
            setChanges={setChanges}
            changes={changes}
          />
        )}
        {tab === tabsEntityField[1].value && (
          <ManualUpdates
            config={config}
            reset={() => setConfig(initialConfig)}
            onChange={(key, val) => updateConfig(key, val)}
            projection={activeItem.projectionName}
            builtInProjection={activeItem.builtInProjection}
            availableUpdateRolesPerProjection={availableUpdateRolesPerProjection || []}
          />
        )}
        {tab === tabsEntityField[2].value && (
          <FieldUsage
            field={activeItem.systemName}
            proj={{
              name: activeItem.projectionName,
              builtIn: activeItem.entityTypeId.builtIn,
            }}
          />
        )}
      </Box>
      <DeleteFieldDialog
        onClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
        field={activeItem}
        onSuccess={() => {
          resetToOriginal();
          refetch();
          onClose();
        }}
      />
    </AvSidePanel>
  );
}

interface DeleteFieldDialogProps {
  open: boolean;
  onClose: () => void;
  field: any;
  onSuccess: (res?) => void;
}
function DeleteFieldDialog({ open = false, onClose, field, onSuccess }: DeleteFieldDialogProps) {
  const {
    api,
    accountEntities: { aggProjs },
  } = useAvContext();
  const [error, setError] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteField, isPending: isLoading } = useMutation({
    mutationFn: () =>
      api(DELETE_FIELD, {
        options: {
          deleteFieldRequest: {
            projId: aggProjs[field.projectionName].projId,
            fieldName: field.systemName,
          },
        },
        muteErrors: true,
      })
        .then(res => {
          onClose();
          onSuccess(res);
          enqueueSnackbar('Field deleted successfully', { variant: 'success' });
        })
        .catch((error: any) => {
          if (!error.networkError) {
            setError(error.message);
          } else {
            enqueueSnackbar('Something Went wrong...', { variant: 'error' });
            onClose();
          }
        }),
  });

  const handleClose = () => {
    onClose();
    setError(undefined);
  };

  const footer = (
    <Box sx={{ ...flex.justifyEndCenter, gap: 2 }}>
      <Button onClick={handleClose}>{error ? 'Close' : 'Cancel'}</Button>
      {!error && (
        <Button color="error" variant="contained" onClick={deleteField as any} disabled={isLoading}>
          Delete
        </Button>
      )}
    </Box>
  );
  return (
    <AvDialog
      title={error ? 'Unable to delete field' : 'Are you sure?'}
      titleVariant={error ? 'warning' : 'error'}
      onClose={onClose}
      open={open}
      footer={footer}>
      {error ? (
        <Box sx={{ width: '100%' }}>{error}</Box>
      ) : (
        <Box>
          Are you sure you would like to delete field
          <span style={{ fontWeight: 600 }}>{` ${field.displayName} (${field.systemName}) `}</span>
          from the model?
        </Box>
      )}
    </AvDialog>
  );
}

const DELETE_FIELD = gql`
  mutation ($deleteFieldRequest: DeleteFieldRequest) {
    deleteEntityField(deleteFieldRequest: $deleteFieldRequest)
  }
`;
