import { datadogRum } from '@datadog/browser-rum';
import envVariables from '../shared/projectEnvVariables';
import { getExporterWorkflowMetadata, isExportMode } from './exportDashboards.utils';

// eslint-disable-next-line no-underscore-dangle
const isSynthetics = window._DATADOG_SYNTHETICS_BROWSER || navigator.userAgent.includes('Datadog');

export const isDebugMode = () => {
  const resetDebugModeCount = Number(localStorage.getItem('resetDebugModeCount'));
  if (!['prod-se01', 'prod'].includes(envVariables.VITE_ENV) || resetDebugModeCount > 0) {
    return localStorage.getItem('debugMode') === 'true';
  }
  localStorage.setItem('debugMode', 'false');
  localStorage.setItem('resetDebugModeCount', `${resetDebugModeCount + 1}`);
  return false;
};
export const shouldRecordSession = !isDebugMode() && !isSynthetics && !envVariables.STORYBOOK && envVariables.VITE_DD_CLIENT_TOKEN;

if (shouldRecordSession) {
  datadogRum.init({
    applicationId: '79576ecf-b1bc-4bf0-ab5f-a99515bd4b2c',
    clientToken: envVariables.VITE_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'app-avalor',
    env: envVariables.VITE_ENV,
    version: envVariables.VITE_VERSION,
    sessionReplaySampleRate: 100,
    compressIntakeRequests: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    beforeSend: (event, context: any) => {
      if (event.type === 'resource' && event.resource.type === 'fetch') {
        // eslint-disable-next-line no-param-reassign
        event.context = {
          ...event.context,
          request: {
            graphqlname: context.requestInit?.headers?.graphqlname,
            body: context.requestInit?.body && typeof context.requestInit.body === 'string' && JSON.parse(context.requestInit.body),
          },
          response: { headers: Object.fromEntries(context.response?.headers || []) },
        };
      }
      return true;
    },
  });

  datadogRum.startSessionReplayRecording();
  if (isExportMode()) {
    datadogRum.setUser({
      ...datadogRum.getUser(),
      ...getExporterWorkflowMetadata(),
    });
  }
}
