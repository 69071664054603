import React from 'react';
import { createFileRoute } from '@tanstack/react-router';
import ProtectedRoute from '../../../../../../../components/Permissions';
import { useAvContext } from '../../../../../../../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../../../../../../../types';
import RuleSetAuditLogs from '../../../../../../../views/DataUnification/RuleSetAuditLogs';

const Component = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  return (
    <ProtectedRoute
      isAllowed={
        hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_FIELDS, permission: Permission.CREATE }) ||
        hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_FIELDS, permission: Permission.UPDATE })
      }>
      <RuleSetAuditLogs />
    </ProtectedRoute>
  );
};

export const Route = createFileRoute('/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id/')({
  component: Component,
});
