import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { flex } from './AvThemeProvider';
import Loading from './Loading';

export const loadingPage = (isAuthenticating: boolean = true) => (
  <main>
    <Box sx={{ ...flex.colCenter, gap: '50px' }}>
      <Loading />
      {isAuthenticating && (
        <Typography variant="h4" sx={{ color: theme => theme.palette.colors.neutrals[500] }}>
          Authenticating...
        </Typography>
      )}
    </Box>
  </main>
);
