import React from 'react';
import { useRouter } from '@tanstack/react-router';
import ProtectedRoute from '../../components/Permissions';
import { useAvContext } from '../../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../../types';
import RuleSet from './RuleSet';

const Component = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const { state } = useRouter();
  const endOfURL = state.location.pathname.split('/').at(-1);
  const oneBeforeLast = state.location.pathname.split('/').at(-2);
  const mode = endOfURL === 'create' ? 'create' : oneBeforeLast !== 'set' ? 'edit' : undefined;
  return (
    <ProtectedRoute
      isAllowed={
        hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_FIELDS, permission: Permission.CREATE }) ||
        hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_FIELDS, permission: Permission.UPDATE })
      }>
      <RuleSet mode={mode} />
    </ProtectedRoute>
  );
};

export default Component;
