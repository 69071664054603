import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarKey, useSnackbar } from 'notistack';
import envVariables from '../shared/projectEnvVariables';
import { useAvDebugContext } from './AvDebugProvider';

const ReactQueryDevtools =
  envVariables.MODE === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // eslint-disable-next-line import/extensions
        import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
          default: d.ReactQueryDevtools,
        }))
      );

const NetworkStatusContext = createContext({
  isOnline: true,
});

export const useNetworkStatus = () => useContext(NetworkStatusContext);

const defaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    retry: false,
  },
};
const client = new QueryClient({
  defaultOptions,
});
export default function AvQueryProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { showDevTools } = useAvDebugContext();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const snackBarKey = useRef<SnackbarKey>('');

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        closeSnackbar(snackBarKey.current);
        snackBarKey.current = '';
      } else {
        snackBarKey.current = enqueueSnackbar("You're offline - some features are unavailable", { variant: 'warning', persist: true });
      }
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  client.setDefaultOptions({ ...defaultOptions, queries: { ...defaultOptions.queries, enabled: isOnline } });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NetworkStatusContext.Provider value={{ isOnline }}>
      <QueryClientProvider client={client}>
        {children}
        {showDevTools && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </NetworkStatusContext.Provider>
  );
}
