import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Button, Divider, IconButton, Skeleton, useTheme } from '@mui/material';
import AvFilters from '../../components/AvFilters';
import { flex } from '../../components/AvThemeProvider';
import ColumnReorder from '../../components/ColumnReorder';
import { SeverityItem } from '../../components/ItemWithLogo';
import NoDataFound from '../../components/NoDataFound';
import AvTable from '../../components/Table/AvTable';
import { FormatterType } from '../../components/Table/types';
import { useAvContext } from '../../context/AvContextProvider';
import { EntityTypeID } from '../../context/context.type';
import useHandleError from '../../hooks/useHandleError';
import { FeatureFlags } from '../../types';
import { Filter } from '../../types/filter.types';
import { cleanEmptyFilters, filterToExpression } from '../../utils/filterUtils';
import { alphaSolid } from '../../utils/Utils';
import { ProjectionID } from '../CustomDashboards/types';
import { fieldTypeIconsMap, getFieldKey } from '../ModelManagement/hooks';
import { getRowsFromPreviewData } from '../Sources/hooks';
import { FieldType } from '../Sources/Mapping/mapping.types';
import { useUnificationPreview } from './hooks';
import { ReactComponent as ArrowDown } from '../../assets/Arrow Down.svg';

const defaultViewFields = (projId: ProjectionID) =>
  projId.builtIn
    ? ['tickets', 'uber_findings'].includes(projId.name)
      ? ['_key', 'source_key', 'title', 'type', 'source_names']
      : ['_key', 'source_key', 'name', 'type', 'source_names']
    : ['_key', 'source_key', 'source_names'];

const buildViewItems = (allFields, viewFields) =>
  allFields
    .map(({ id, name, displayName }) => ({
      id,
      label: displayName,
      modelFieldName: name,
      hidden: !viewFields.includes(name),
    }))
    .toSorted((a, b) => viewFields.indexOf(a.modelFieldName) - viewFields.indexOf(b.modelFieldName));

const getVal = val => (typeof val === 'object' ? JSON.stringify(val) : val?.toString());

interface Props {
  ruleSet: any;
  fieldName: string;
  projId: ProjectionID;
  entityTypeId: EntityTypeID;
  onExpand: (height: number) => void;
}
const RulePreview: React.FC<Props> = ({ ruleSet, fieldName, projId, entityTypeId, onExpand }) => {
  const { palette } = useTheme();
  const ref = useRef<HTMLElement>(null);
  const {
    featureFlags,
    accountEntities: { aggProjs, fieldTypeMap, fieldMap },
  } = useAvContext();

  const { name: projName } = projId;
  const fieldPathAlias = ruleSet.name.split('.')[0];
  const defaultFilter = { [`${fieldPathAlias}._key`]: [] };
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);

  const [expanded, setExpanded] = useState(false);
  const doExpand = newExpand => {
    setExpanded(newExpand);
    requestAnimationFrame(() => onExpand(ref.current?.clientHeight || 0));
  };
  useEffect(() => {
    onExpand(ref.current?.clientHeight || 0);

    const onResize = () => {
      onExpand(ref.current?.clientHeight || 0);
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const [selectivityFilter, setSelectivityFilter] = useState<Filter | undefined>();
  const onApplyFilter = (currentFilters = filters) =>
    setSelectivityFilter(cleanEmptyFilters(filterToExpression(fieldTypeMap, currentFilters)));
  const [filters, setFilters] = useState<Record<string, string[]>>(defaultFilter);
  const hasNewFilters = useMemo(
    () => JSON.stringify(selectivityFilter) !== JSON.stringify(cleanEmptyFilters(filterToExpression(fieldTypeMap, filters))),
    [filters, selectivityFilter, fieldTypeMap, featureFlags]
  );

  const initialViewItems = useMemo(
    () => buildViewItems(aggProjs[projName].fields, [ruleSet.ruleSetConfig.field, ...defaultViewFields(projId)]),
    []
  );
  const [viewFields, setViewFields] = useState(initialViewItems);

  const { data: { rows = [], scriptError = '' } = { rows: [], scriptError: '' }, isLoading: isPreviewLoading } = useUnificationPreview(
    {
      ruleSet,
      additionalFields: viewFields.filter(({ hidden }) => !hidden).map(({ modelFieldName }) => modelFieldName),
      selectivityFilter,
      entityTypeId,
    },
    data => {
      if (data.internalError) {
        handleError({ status: 500, message: data.internalError });
      }
      return {
        rows: getRowsFromPreviewData(
          data.previewData,
          entityName => Object.values(aggProjs).find(({ entityTypeId: { name } }) => name === entityName)!.pathAlias
        ),
        scriptError: data.scriptError,
      };
    },
    expanded
  );

  const currentFieldStyle = { backgroundColor: alphaSolid(palette.colors.primary[100], 0.5) };
  const headCells = useMemo(
    () =>
      !rows[0]
        ? [{ id: 'avalorId', label: !isPreviewLoading && `${entityTypeId.name} ID`, isKey: true, hidden: false, style: { width: '100vw' } }]
        : viewFields
            .filter(({ hidden, modelFieldName }) => !hidden || modelFieldName === '_key')
            .map(({ id: fieldKey, modelFieldName, hidden }) => ({
              id: modelFieldName === '_key' ? 'avalorId' : fieldKey,
              isKey: modelFieldName === '_key',
              label: (
                <Box sx={{ ...flex.row, gap: 1 }}>
                  <>
                    {fieldTypeIconsMap(palette)[getFieldKey(fieldTypeMap[fieldKey])]}
                    <div>{fieldMap[fieldKey]?.title || fieldKey}</div>
                  </>
                </Box>
              ),
              hidden,
              formatter: [FieldType.DateTime, FieldType.Date].includes(fieldTypeMap[fieldKey])
                ? v => v
                : modelFieldName === 'severity'
                  ? val => <SeverityItem value={val} />
                  : undefined,
              type: [FieldType.DateTime, FieldType.Date].includes(fieldTypeMap[fieldKey]) ? FormatterType.date : undefined,
              getValue: row => getVal(row[fieldKey]),
              ...(fieldKey === fieldName ? { style: currentFieldStyle, sx: currentFieldStyle } : undefined),
            })),
    [rows, viewFields, projName, fieldPathAlias]
  );

  return (
    <Box
      ref={ref}
      sx={{
        position: 'fixed',
        bottom: 64,
        left: 'var(--side-bar-width)',
        width: 'calc(100% - var(--side-bar-width))',
        height: expanded ? '50%' : 71,
        zIndex: theme => theme.zIndex.drawer,
        backgroundColor: palette.colors.neutrals[150],
        '> :first-of-type': { borderTop: theme => `5px solid ${theme.palette.colors.neutrals[150]}` },
      }}>
      <Box
        sx={{
          ...flex.col,
          gap: 2,
          height: '100%',
          backgroundColor: ({ palette }) => palette.white.main,
          p: 3,
        }}
        style={{ cursor: !expanded ? 'pointer' : undefined }}
        onClick={() => doExpand(true)}>
        <Box sx={flex.justifyBetweenCenter}>
          <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
            {rows?.length || isPreviewLoading ? (
              <Box sx={{ ...flex.itemsCenter, gap: 0.5 }}>
                Preview for top {isPreviewLoading ? <Skeleton width={19} /> : <b>{rows?.length}</b>} entities
              </Box>
            ) : (
              <Box>Expand to load preview</Box>
            )}
            {expanded && (
              <>
                <Divider orientation="vertical" sx={{ height: 16, alignSelf: 'center' }} flexItem />
                <AvFilters
                  setFilters={setFilters}
                  updateFilters={(key, val) => setFilters({ ...filters, [key]: val })}
                  filters={filters}
                  onClearFilters={() => {
                    setFilters(defaultFilter);
                    onApplyFilter(defaultFilter);
                  }}
                  dims={aggProjs[projName].fieldList.INTERACTIVE}
                  ignoreVisibilityConfig
                  activeProjName={projName}
                  {...(hasNewFilters && { onApplyFilter })}
                  displayRecommendedFields={false}
                />
              </>
            )}
          </Box>
          <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
            {expanded && (
              <>
                <ColumnReorder items={viewFields} setItems={setViewFields} initialColumns={initialViewItems} width={18} />
                <Divider orientation="vertical" sx={{ height: 16, alignSelf: 'center' }} flexItem />
              </>
            )}
            <IconButton
              onClick={e => {
                doExpand(expanded => !expanded);
                e.stopPropagation();
              }}
              sx={{ ml: 1 }}>
              <ArrowDown style={{ transform: !expanded ? 'rotate(180deg)' : undefined }} />
            </IconButton>
          </Box>
        </Box>
        {expanded &&
          (!isPreviewLoading && scriptError ? (
            <Box sx={{ overflowY: 'auto' }}>
              <Alert severity="error" sx={{ whiteSpace: 'pre-line' }}>
                {scriptError}
              </Alert>
            </Box>
          ) : isPreviewLoading || rows.length ? (
            <AvTable
              rows={rows}
              headCells={headCells}
              hasHeaderFilters={false}
              size="xSmall"
              enableSelectionColor={false}
              loading={isPreviewLoading}
              allowEdit={false}
              resizable
              pagination
            />
          ) : (
            <NoDataFound
              imgStyle={{ height: 150 }}
              callToAction={
                <>
                  Try to{' '}
                  <Button
                    onClick={() => {
                      setFilters(defaultFilter);
                    }}>
                    Clear all Filters
                  </Button>
                </>
              }
            />
          ))}
      </Box>
    </Box>
  );
};

export default RulePreview;
