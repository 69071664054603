import { useMemo, useState } from 'react';
import { useAvContext } from '../context/AvContextProvider';
import { trackEvent } from '../services/analytics.service';
import { FeatureFlags } from '../types';
import { FieldType } from '../views/CustomDashboards/types/types';

export const getTopFields = fields => fields.filter(({ isRecommended }) => isRecommended);

export const getVisibleDims = ({ dims, dimsToRemove = new Set(), isUnpopulatedFieldsActive = true, ignoreVisibilityConfig = false }) =>
  dims.filter(
    dim =>
      (ignoreVisibilityConfig || !dim.visibilityConfig?.config?.hidden) &&
      !dimsToRemove.has(dim.value) &&
      (isUnpopulatedFieldsActive ? true : dim.isPopulated)
  );

interface Props {
  dims: any[];
  metrics: any[];
  selectedFields?: Set<string>;
  onChangeFilterType?: (event: React.MouseEvent<HTMLElement> | null, fieldsTypes: string[]) => any;
  topFieldsEnabled: boolean;
  ignoreVisibilityConfig?: boolean;
  populatedFieldsEnabled: boolean;
}

export default function useFilterFields({
  dims,
  metrics,
  selectedFields,
  onChangeFilterType,
  topFieldsEnabled,
  ignoreVisibilityConfig,
  populatedFieldsEnabled,
}: Props) {
  const [activeFilterType, setActiveFilterType] = useState<FieldType | null>(FieldType.All);
  const [isTopFieldsFilterActive, setIsTopFieldsFilterActive] = useState(topFieldsEnabled);
  const [isInUseFilterActive, setIsInUseFilterActive] = useState(false);
  const [isUnpopulatedFieldsActive, setIsUnpopulatedFieldsActive] = useState(!populatedFieldsEnabled);

  const { featureFlags } = useAvContext();

  const filterByTopFields = () => {
    trackEvent(isTopFieldsFilterActive ? 'Top Fields - Deactivate' : 'Top Fields - Activate');
    setIsTopFieldsFilterActive(prevState => !prevState);
  };

  const filterByUsedFields = () => setIsInUseFilterActive(prevState => !prevState);

  const filterByUnpopulatedFields = () => {
    trackEvent(isUnpopulatedFieldsActive ? 'Include Empty Fields - Deactivate' : 'Include Empty Fields - Activate');
    setIsUnpopulatedFieldsActive(prevState => !prevState);
  };

  const getUsedFields = fields => fields.filter(field => selectedFields?.has(field.value ?? field.systemName));

  const getFieldsByFilterType = () => {
    if (activeFilterType === FieldType.Measurements) {
      return metrics;
    }

    const filteredDims = getVisibleDims({ dims, isUnpopulatedFieldsActive, ignoreVisibilityConfig });

    if (activeFilterType === FieldType.Dimensions) {
      return filteredDims;
    }

    return [...filteredDims, ...metrics];
  };

  const fields = useMemo(() => {
    const newFields = getFieldsByFilterType();
    const topFields = isTopFieldsFilterActive ? getTopFields(newFields) : newFields;
    return isInUseFilterActive ? getUsedFields(topFields) : topFields;
  }, [dims, metrics, activeFilterType, isTopFieldsFilterActive, isInUseFilterActive, isUnpopulatedFieldsActive]);

  const initFilters = () => {
    setIsTopFieldsFilterActive(true);
    setIsInUseFilterActive(false);
    setIsUnpopulatedFieldsActive(!populatedFieldsEnabled);
    setActiveFilterType(FieldType.All);
  };

  const resetFilters = () => {
    trackEvent('Clear Filters');

    setIsTopFieldsFilterActive(false);
    setIsInUseFilterActive(false);

    if (featureFlags[FeatureFlags.PopulatedFields]) {
      setIsUnpopulatedFieldsActive(true);
    }

    setActiveFilterType(FieldType.All);
  };

  const onClickFilterType = (filterType: FieldType) => {
    if (filterType === activeFilterType) {
      setActiveFilterType(null);
    } else {
      setActiveFilterType(filterType);
      onChangeFilterType?.(null, [filterType]);
    }
  };

  return {
    fields,
    activeFilterType,
    topFields: {
      isTopFieldsFilterActive,
      filterByTopFields,
    },
    inUse: {
      isInUseFilterActive,
      filterByUsedFields,
    },
    unpopulated: {
      isUnpopulatedFieldsActive,
      filterByUnpopulatedFields,
    },
    onClickFilterType,
    initFilters,
    resetFilters,
  };
}
