import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { RouterProvider } from '@tanstack/react-router';
import AvThemeProvider from './components/AvThemeProvider';
import reportWebVitals from './reportWebVitals';
import router from './RouterInit';
import { initializePendoScript } from './utils/pendo.utils';

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

initializePendoScript();
const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(
  <AvThemeProvider>
    <RouterProvider router={router} />
  </AvThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
