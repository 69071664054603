import React from 'react';
import { FormatterType, HeadCellType, SortType } from '@components/Table/types';
import { format } from 'date-fns';
import WithDescriptionTooltip from '../components/WithDescriptionTooltip/WithDescriptionTooltip';
import { useAvContext } from '../context/AvContextProvider';
import { hasTimeBucket } from '../views/CustomDashboards/EditCustomDashboard/TimeBucketBreakdown/utils';
import { useGetDisplayName, useGetFieldType } from '../views/CustomDashboards/hooks';
import { AllTypeWidgets, DwQueryRequest, Field, Select, SortBy, SortDir } from '../views/CustomDashboards/types';
import { FieldType } from '../views/CustomDashboards/types/types';
import { defaultDrillDownHierarchy, filterTimeBucketFromDimensions } from '../views/CustomDashboards/Utils';
import { TimeBucketName, TimeBucketOrder, TimeBucketToDateGap } from '../views/Reports/types';
import { DateFormats } from './date.utils';

export const useGetHeadCellsWithRequestObject = (request: DwQueryRequest): HeadCellType[] => {
  const getDisplayName = useGetDisplayName(request.projectionId.name);
  const getHeadCellType = useGetFieldType(request.projectionId.name);
  const { measurements } = useAvContext();
  const sort = request.sorting;
  const ids = [...request.select.dims, ...request.select.metrics];
  return ids.map(({ name, alias }) => {
    const sortDir = sort.find(({ name: sortName }) => sortName === alias || sortName === name)?.dir;
    const finalName = alias || name;

    if (hasTimeBucket(name)) {
      return {
        id: name,
        label: TimeBucketName[name],
        type: FormatterType.historicDate,
        sort: sortDir === SortDir.ASC ? SortType.ASC : sortDir === SortDir.DESC ? SortType.DESC : undefined,
        formatter: date => (date ? format(new Date(date), DateFormats[TimeBucketToDateGap[name]]) : date),
      };
    }

    return {
      id: finalName,
      label: (
        <WithDescriptionTooltip title={measurements.asObject[finalName]?.description} placement="top">
          <span>{getDisplayName(finalName)}</span>
        </WithDescriptionTooltip>
      ),
      type: getHeadCellType({ entityFieldKey: finalName }) as FormatterType,
      sort: sortDir === SortDir.ASC ? SortType.ASC : sortDir === SortDir.DESC ? SortType.DESC : undefined,
    };
  });
};

const addMetric = ({
  requests,
  fieldType,
  fields,
  pathAlias,
}: {
  requests: DwQueryRequest[];
  fieldType: FieldType;
  fields: string[];
  pathAlias?: string;
}) => [...requests[0].select[fieldType], ...fields.map(name => ({ name, ...(pathAlias ? { alias: pathAlias } : {}) }))];

const addDimension = ({
  requests,
  fieldType,
  fields,
  isMultiTimeBreakdown,
}: {
  requests: DwQueryRequest[];
  fieldType: FieldType;
  fields: string[];
  isMultiTimeBreakdown?: boolean;
}) => {
  const fieldsObject = fields.map(field => ({ name: field }));
  if (
    fields.every(field => hasTimeBucket(field)) &&
    requests[0].select.dims.find(dim => hasTimeBucket(dim.name)) &&
    !isMultiTimeBreakdown
  ) {
    return [...fieldsObject, ...filterTimeBucketFromDimensions(requests[0].select.dims)];
  }
  if (fields.every(field => hasTimeBucket(field))) {
    const selectedTimeBuckets = requests[0].select[fieldType].filter(dim => hasTimeBucket(dim.name));
    return [...selectedTimeBuckets, ...fieldsObject, ...filterTimeBucketFromDimensions(requests[0].select[fieldType])];
  }
  return [...requests[0].select[fieldType], ...fieldsObject];
};

const getTimeBucketSortBasis = (dims: Field[]) => {
  const timeBuckets = dims.filter(({ name }) => hasTimeBucket(name));
  return timeBuckets.sort(({ name: name1 }, { name: name2 }) => TimeBucketOrder[name1] - TimeBucketOrder[name2]);
};

export const getNewRequestParams = ({
  widget,
  fields = [],
  pathAlias,
  fieldType,
  isHistoricalAnalyticsFeatureFlag,
  isMultiTimeBreakdown = true,
}: {
  fieldType: FieldType;
  fields: string[];
  pathAlias?: string;
  widget: AllTypeWidgets;
  isHistoricalAnalyticsFeatureFlag: boolean;
  isMultiTimeBreakdown?: boolean;
}) => {
  const { requests } = widget;
  const fieldsSet = new Set(fields);
  const isFieldAlreadySelected = requests[0].select[fieldType].some(({ name }) => fieldsSet.has(name));

  const newSelect: Select = {
    ...requests[0].select,
    [fieldType]: isFieldAlreadySelected
      ? requests[0].select[fieldType].filter(({ name }) => !fieldsSet.has(name))
      : fieldType === FieldType.Measurements
        ? addMetric({ requests, fieldType, fields, pathAlias })
        : addDimension({ requests, fieldType, fields, isMultiTimeBreakdown }),
  };

  const filteredDimensions = filterTimeBucketFromDimensions(newSelect.dims);
  const isTimeBucketDimensionSelected = !!newSelect.dims.find(dim => hasTimeBucket(dim.name));
  const historicalFFWithTimeBucketSelected = isHistoricalAnalyticsFeatureFlag && isTimeBucketDimensionSelected;

  const newDistinct = !newSelect.metrics.length && !!newSelect.dims.length;
  const newGroupBy = newSelect.metrics.length && newSelect.dims.length ? newSelect.dims.map(v => v.name) : [];

  const sortBasis = historicalFFWithTimeBucketSelected
    ? getTimeBucketSortBasis(newSelect.dims)
    : newSelect.metrics.length
      ? newSelect.metrics
      : filteredDimensions;

  const filteredSorting = requests[0].sorting.filter(
    ({ name }) => newSelect.metrics.some(value => value.name === name) || newSelect.dims.some(value => value.name === name)
  );
  const newSortBy =
    filteredSorting.length && (!isHistoricalAnalyticsFeatureFlag || (isHistoricalAnalyticsFeatureFlag && !isTimeBucketDimensionSelected))
      ? filteredSorting
      : sortBasis[0]
        ? ([
            {
              name: sortBasis[0].alias || sortBasis[0].name,
              dir: historicalFFWithTimeBucketSelected ? SortDir.ASC : SortDir.DESC,
            },
          ] as SortBy[])
        : [];
  const newDrillDownHierarchy =
    newSelect.dims.length !== 1 || newSelect.metrics.length !== 1 || historicalFFWithTimeBucketSelected
      ? defaultDrillDownHierarchy
      : widget.drillDownHierarchy;

  const newTop = newSelect.dims.length !== 1 ? { ...requests[0].top, groupOthers: false } : requests[0].top;

  return { newSelect, newGroupBy, newSortBy, newDistinct, newDrillDownHierarchy, newTop };
};
