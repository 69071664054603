import React, { useRef } from 'react';
import { Box, ButtonProps, ClickAwayListener, Paper, Popper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import AvButton from '../../../components/AvButton';
import AvSchemaFieldCompletionEditor from '../../../components/AvSchemaFieldCompletionEditor';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import CodeEditor from '../../../components/CodeEditor';
import TextInput from '../../../components/TextInput';
import { DescriptorType } from '../../../context/context.type';
import useFullScreenToggle from '../../../hooks/useFullScreenToggle';
import { iconSize, noop } from '../../../utils/Utils';
import { getPythonType } from '../../ModelManagement/hooks';
import { MappingType } from './mapping.types';
import { ReactComponent as InfoFull } from '../../../assets/colorful/Info Full.svg';
import { ReactComponent as Function } from '../../../assets/Function.svg';

const infoIcon = <InfoFull />;
const FIXES_TOOLTIP =
  'Fixes is a unique field, the returned value must be a list of dictionaries with the following structure: [{"fix": <FIX_STRING>, "type": <FIX_TYPE>}]. Supported fix types are “TEXT” and “VERSION”.';

const tooltipText = (footerText = 'Just remember, the value you return should match the type of the model field you are mapping to!') => (
  <Box>
    <Box sx={{ fontWeight: 600 }}>A few notes to get you started</Box>
    <div>Rows of data stored as dictionaries. To access row values use square brackets or the get() method</div>
    <Box sx={{ color: theme => theme.palette.colors.neutrals[600], my: 3 }}>{`Example: row["MyDataField"] OR row.get("MyDataField")`}</Box>
    <div>{footerText}</div>
  </Box>
);

const codeOverrides = {
  field: {
    fixes: value => `def evaluate(row: dict) -> list[dict]:
    item = row.get("${value}")\n    
    return [{
        "type": <USE "TEXT"/"VERSION">,
        "fix": item
    }]`,
  },
  type: {
    DATE: value => `from datetime import datetime\n
def evaluate(row: dict) -> str:
    date_string = row.get("${value}")
    ORIGINAL_DATE_FORMAT = '%Y-%m-%d' # Replace with source dateformat\n    
    return datetime.strptime(date_string, ORIGINAL_DATE_FORMAT).strftime('%Y-%m-%d')`,
    DATETIME: value => `from datetime import datetime\n
def evaluate(row: dict) -> str:
    date_string = row.get("${value}")
    ORIGINAL_DATE_FORMAT = '%Y-%m-%dT%H:%M:%S.%fZ' # Replace with source dateformat\n    
    return datetime.strptime(date_string, ORIGINAL_DATE_FORMAT).strftime('%Y-%m-%dT%H:%M:%SZ')`,
    IP: value => `import re

def validate_ip(ip_str: str):
    ip_pattern = re.compile(r'^(?:\\d{1,3}\\.){3}\\d{1,3}$')
    return ip_pattern.match(ip_str)

def evaluate(row: dict):
    input_data = row.get("${value}")
    ips = []
    
    SPLIT_CHARS = r',|\\|'
    
    if isinstance(input_data, str):
        input_data = input_data.strip()
        potential_ips = re.split(SPLIT_CHARS, input_data)
        for ip in potential_ips:
            ip = ip.strip()
            if validate_ip(ip):
                ips.append(ip)
                
    elif isinstance(input_data, list):
        for item in input_data:
            if isinstance(item, str) and validate_ip(item.strip()):
                ips.append(item.strip())

    return ips`,
  },
};

const getDefaultSignature = fieldType =>
  `def evaluate(row: dict) ${fieldType ? `-> ${Array.isArray(fieldType) ? 'str' : getPythonType(fieldType)}` : ''}:\n`;

const generateCodeAccordingToType = (fieldType, value) =>
  codeOverrides.type[fieldType]?.(value) ||
  (Array.isArray(fieldType) && [DescriptorType.TYPE_STRING, 'MESSAGE'].includes(fieldType[0])
    ? `${getDefaultSignature([DescriptorType.TYPE_STRING])}    item = row.get("${value}")\n
    if item:
        return [item]
    return []`
    : `${getDefaultSignature(fieldType)}    item = row.get("${value}")\n    return ${getPythonType(fieldType)}(item)`);

const getInitialScript = (fieldType, value, type, modelFieldName) =>
  value
    ? type === MappingType.Path
      ? fieldType
        ? codeOverrides.field[modelFieldName]?.(value) || generateCodeAccordingToType(fieldType, value)
        : `${getDefaultSignature(fieldType)}    return row.get("${value}")`
      : `${getDefaultSignature(fieldType)}    return "${value}"`
    : `${getDefaultSignature(fieldType)}    return None`;

export const editorPopperTypeProps = {
  [MappingType.Script]: {
    name: 'Script',
    icon: <Function style={iconSize()} />,
    tooltip: modelFieldName => (
      <AvTooltip
        variant="message"
        color="white"
        muiProps={{ PopperProps: { sx: { '.MuiTooltip-tooltip': { maxWidth: 450 } } } }}
        title={
          <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
            <Box sx={{ alignSelf: 'start' }}>{infoIcon}</Box>
            <Box>{tooltipText(modelFieldName === 'fixes' ? FIXES_TOOLTIP : undefined)}</Box>
          </Box>
        }>
        <div>{infoIcon}</div>
      </AvTooltip>
    ),
  },
  [MappingType.String_Template]: {
    name: 'Smart Text',
    icon: null,
    tooltip: () => (
      <AvTooltip
        variant="message"
        color="white"
        muiProps={{ PopperProps: { sx: { '.MuiTooltip-tooltip': { maxWidth: 450 } } } }}
        title={
          <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
            <Box sx={{ alignSelf: 'start' }}>{infoIcon}</Box>
            <Box>
              Define the field value using free text, or a combination of free text and selected fields. <br />
              <br /> To add fields, enclose them in double curly brackets. <strong>Example:</strong> {`{{Ticket Status}}`}
            </Box>
          </Box>
        }>
        <div>{infoIcon}</div>
      </AvTooltip>
    ),
  },
  [MappingType.Literal]: { name: 'Value', icon: null },
  [MappingType.Path]: { name: 'Field', icon: null },
};

interface EditorPopperProps {
  open: boolean;
  value: string;
  buttons: (ButtonProps & { onActionClick(...args: unknown[]): unknown; isDisabled?: (value) => boolean })[];
  type: string;
  fieldType?: string;
  setType(val: MappingType): void;
  handleClose(...args: unknown[]): unknown;
  anchorEl?: Element;
  onChange?(val: string): void;
  editable?: boolean;
  hideToggle?: boolean;
  modelFieldName?: string;
  language?: string;
  pythonScript?: boolean;
  projectionName?: string;
  FieldSelector?: any;
  defaultHeight?: number | string;
  mappingTypes?: MappingType[];
  isMultiLineStringTemplate?: boolean;
  stringTemplateTitle?: string;
  stringTemplateIsInteractiveFields?: boolean;
  textInputAutoFocused?: boolean;
}
const EditorPopper = ({
  anchorEl,
  open,
  editable = true,
  buttons,
  value,
  onChange = noop,
  type,
  setType,
  fieldType,
  handleClose,
  modelFieldName,
  language,
  hideToggle,
  pythonScript = true,
  projectionName = '',
  FieldSelector,
  defaultHeight = '100%',
  mappingTypes = [MappingType.Script, MappingType.Literal, MappingType.Path],
  isMultiLineStringTemplate = false,
  stringTemplateIsInteractiveFields = false,
  textInputAutoFocused = false,
}: EditorPopperProps) => {
  const popperRef = useRef(null);
  const { fullScreenToggle, maximized } = useFullScreenToggle(popperRef.current);
  const values = useRef({
    [type]: value,
    [MappingType.Script]: (type === MappingType.Script && value) || getInitialScript(fieldType, value, type, modelFieldName),
    [MappingType.String_Template]: (type === MappingType.String_Template && value) || '',
  }).current;
  const onValueChange = newValue => {
    onChange(newValue);
    values[type] = newValue;
    if (type !== MappingType.Script && pythonScript) {
      values[MappingType.Script] = getInitialScript(fieldType, newValue, type, modelFieldName);
    }
  };

  return (
    <Popper
      ref={popperRef}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      sx={{ width: 600, zIndex: theme => theme.zIndex.modal }}
      onClick={event => event.stopPropagation()}>
      <ClickAwayListener onClickAway={handleClose}>
        <Paper sx={{ p: 2, ...flex.col, gap: 2, height: defaultHeight || '100%' }}>
          <Box component="span" sx={{ ...flex.itemsCenter, color: theme => theme.palette.colors.neutrals[500], fontWeight: 600, gap: 1.5 }}>
            {!hideToggle ? (
              <Box sx={{ ...flex.row, flexGrow: 1, gap: 1 }}>
                {editorPopperTypeProps[type].icon}
                {editorPopperTypeProps[type].name} Editor
                {editorPopperTypeProps[type].tooltip?.(modelFieldName)}
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1 }} />
            )}
            {type && !hideToggle && (
              <ToggleButtonGroup color="white" size="small" value={type} exclusive onChange={e => setType((e.target as any).value)}>
                {Object.values(mappingTypes).map(mapType => (
                  <ToggleButton key={mapType} value={mapType} disabled={mapType === type}>
                    {editorPopperTypeProps[mapType].name}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
            {fullScreenToggle}
          </Box>
          {type === MappingType.Script ? (
            <CodeEditor
              value={values[type]}
              disabled={!editable}
              onChange={onValueChange}
              height={maximized ? '100%' : 300}
              language={language}
            />
          ) : type === MappingType.String_Template ? (
            <Box sx={{ position: 'relative' }}>
              <AvSchemaFieldCompletionEditor
                projectionName={projectionName}
                onChange={onValueChange}
                value={values[type]}
                height={isMultiLineStringTemplate ? 200 : undefined}
                isInteractive={stringTemplateIsInteractiveFields}
              />
            </Box>
          ) : type === MappingType.Path && FieldSelector != null ? (
            FieldSelector
          ) : (
            <TextInput
              value={values[type]}
              disabled={!editable}
              onChange={onValueChange}
              sx={{ maxWidth: 'unset' }}
              autoFocus={textInputAutoFocused}
            />
          )}
          {editable ? (
            <div style={{ alignSelf: 'end', ...flex.row, gap: 16 }}>
              {buttons.map(params => {
                const { children, onActionClick, isDisabled, ...rest } = params;
                return (
                  <AvButton
                    key={children as string}
                    onClick={onActionClick && (() => onActionClick(values[type], type))}
                    disabled={isDisabled?.(values[type])}
                    {...rest}>
                    {children}
                  </AvButton>
                );
              })}
            </div>
          ) : null}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default EditorPopper;

export const dropStyle = (selected, borderSide) => ({
  ...(selected && {
    div: {
      background: theme =>
        `linear-gradient(277.57deg, ${theme.palette.colors.primary[400]} -103.08%, ${theme.palette.colors.primary[200]} 155.47%)`,
      position: 'absolute',
      [borderSide]: '-2px',
      height: '110%',
      width: '7px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      transform: borderSide === 'left' && 'rotate(180deg)',
    },
  }),
});
