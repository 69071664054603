import React from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../context/AvContextProvider';
import { AccountEntities } from '../../context/context.type';
import useHandleError from '../../hooks/useHandleError';
import { FeatureFlags } from '../../types';
import { useNavigate } from '../../utils/AvRouter';
import { QueryKey } from '../../utils/queryKey';
import { getNetworkErrorCode, noop } from '../../utils/Utils';
import { AssignmentType, PredicateType, RuleConfigType, UpdateEnhancedProj } from './types';
import { ReactComponent as Copy } from '../../assets/Copy.svg';
import { ReactComponent as Paste } from '../../assets/Paste.svg';

const GET_UNIFICATION_RULES = gql`
  query {
    findUnificationRuleSets {
      id
      name
      rules {
        id
        ruleConfig {
          type
        }
      }
      ruleSetConfig {
        field
        projId {
          name
          builtIn
        }
      }
    }
  }
`;
const GET_FIELDS_OVERRIDE_LIST = gql`
  query {
    evaluateProjectionFieldsOverrideDefaultScript {
      projectionName
      fieldsList
    }
  }
`;
const GET_UNIFICATION_RULE_BY_NAME = gql`
  query ($name: String!) {
    findUnificationRuleSetByName(name: $name) {
      id
      name
      ruleSetConfig {
        field
        projId {
          name
          builtIn
        }
      }
      rules {
        id
        name
        ruleConfig {
          type
          predicate {
            type
            value
          }
          assignment {
            value
            assignmentType
          }
          expression
          sourcePriority {
            sourceName
            fieldNameWithAlias
          }
          populationFilter
          scenarioType
          timeBasedScenario
          valueBasedScenario
        }
      }
    }
  }
`;

const DELETE_UNIFICATION_RULE_BY_ID = gql`
  mutation ($ruleSetId: String!) {
    deleteUnificationRuleSetById(ruleSetId: $ruleSetId) {
      id
    }
  }
`;
const CREATE = gql`
  mutation ($ruleSet: UnificationRuleSetInput) {
    createUnificationRuleSet(ruleSet: $ruleSet) {
      id
    }
  }
`;
const UPDATE = gql`
  mutation ($ruleSet: UnificationRuleSetInput) {
    updateUnificationRuleSet(ruleSet: $ruleSet) {
      id
    }
  }
`;

const GET_UNIFICATION_PREVIEW = gql`
  query (
    $ruleSet: UnificationRuleSetInput!
    $additionalFields: [String]
    $selectivityFilter: FilterScalar
    $entityTypeId: EntityTypeIDInput!
  ) {
    previewUnificationRuleSet(
      ruleSet: $ruleSet
      additionalFields: $additionalFields
      selectivityFilter: $selectivityFilter
      entityTypeId: $entityTypeId
    ) {
      internalError
      scriptError
      previewData
    }
  }
`;

export const useUnificationRules = () => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.UnificationRules],
    queryFn: () => api(GET_UNIFICATION_RULES, { onSuccess: ({ data }) => data.findUnificationRuleSets }),
    gcTime: 0,
  });
};

export const useFieldsOverrideMap = () => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.FieldsOverrideList],
    queryFn: () =>
      api(GET_FIELDS_OVERRIDE_LIST, {
        onSuccess: ({ data }) =>
          data.evaluateProjectionFieldsOverrideDefaultScript.reduce(
            (map, { projectionName, fieldsList }) => ({ ...map, [projectionName]: new Set(fieldsList) }),
            {}
          ),
      }),
    gcTime: 0,
  });
};

export const useRuleSet = (name, emptyRuleSet, onSuccess = d => d) => {
  const {
    api,
    getPathName,
    accountEntities: { fieldMap },
  } = useAvContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = e => {
    if (e?.[0]?.extensions?.status === 404) {
      if (fieldMap[name]) {
        return onSuccess(emptyRuleSet);
      }
      console.warn(e);
      navigate({ ...getPathName('field-not-found'), replace: true });
    } else {
      const errorText = `Failed to fetch rule set data for ${fieldMap[name]?.displayName || name}.`;
      enqueueSnackbar(errorText, { variant: 'error' });
      console.error(errorText, e);
    }
    return {};
  };
  return useQuery({
    queryKey: [QueryKey.UnificationsRulesSet, name],
    queryFn: () =>
      api(GET_UNIFICATION_RULE_BY_NAME, {
        options: { name },
        onSuccess: ({ data, errors }) => (errors ? handleError(errors) : onSuccess(data.findUnificationRuleSetByName)),
        muteErrors: true,
      }).catch(handleError),
    enabled: !!name,
    gcTime: 0,
  });
};

export const useDeleteRuleSet = () => {
  const { api } = useAvContext();
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ ruleSetId, onSuccess }: { ruleSetId: string; onSuccess: (data?) => void }) =>
      api(DELETE_UNIFICATION_RULE_BY_ID, {
        options: { ruleSetId },
        onSuccess: () => {
          client.resetQueries({ queryKey: [QueryKey.FieldsOverrideList] });
          onSuccess?.();
        },
      }),
  });
};

export const useSaveRuleSet = ruleSet => {
  const { api, featureFlags } = useAvContext();
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: deleteRuleSet } = useDeleteRuleSet();
  return useMutation({
    mutationFn: (onSuccessFunc?: () => void): Promise<any> => {
      const onSuccess = res => {
        if (!res?.errors) {
          enqueueSnackbar('Rule set saved successfully', { variant: 'success' });
        }
        onSuccessFunc?.();
      };
      return ruleSet.rules.length === 0
        ? deleteRuleSet({ ruleSetId: ruleSet.id, onSuccess })
        : api(ruleSet.id ? UPDATE : CREATE, {
            options: {
              ruleSet: {
                ...ruleSet,
                rules: ruleSet.rules.map(({ isNew, id, ...rule }) => ({ ...rule, id: isNew ? undefined : id })),
              },
            },
            muteErrors: true,
            onSuccess,
          }).catch(e => {
            if (getNetworkErrorCode(e) === 400) {
              console.warn(e);
              enqueueSnackbar(e.message, { variant: 'error', style: { whiteSpace: 'pre-line' } });
            } else {
              handleError(e);
            }
          });
    },
  });
};

export const useUnificationPreview = (
  { ruleSet, additionalFields, selectivityFilter = {}, entityTypeId },
  onSuccess = d => d,
  enabled = true
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { api, featureFlags } = useAvContext();
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);
  const onError = e => {
    if (getNetworkErrorCode(e) === 400) {
      console.warn(e);
      enqueueSnackbar(e?.[0]?.message || e.message, { variant: 'error', style: { whiteSpace: 'pre-line' } });
    } else {
      handleError(e);
    }
  };
  return useQuery({
    queryKey: [QueryKey.UnificationPreview, ruleSet, selectivityFilter, additionalFields, entityTypeId],
    queryFn: () =>
      api(GET_UNIFICATION_PREVIEW, {
        options: {
          ruleSet: { ...ruleSet, rules: ruleSet.rules.map(r => ({ ...r, isNew: undefined })) },
          additionalFields,
          selectivityFilter,
          entityTypeId,
        },
        onSuccess: ({ data, errors }) =>
          (data?.previewUnificationRuleSet ? onSuccess(data.previewUnificationRuleSet) : onError(errors)) || {},
        muteErrors: true,
      }).catch(onError),
    enabled,
    gcTime: 0,
  });
};

export function useCopyPasteRules(ruleSet, onChangeRules: (rules) => void) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    accountEntities: { fieldMap, aggProjs, ingressProjs },
  } = useAvContext();

  const hasValidFilter = (fields, filter) => {
    if (filter && typeof filter === 'object') {
      if ('fieldName' in filter) {
        return fields.includes(filter.fieldName);
      }
      return Object.values(filter).every(val => hasValidFilter(fields, val));
    }
    return true;
  };
  const hasValidConditionalFieldNames = (fields, { ruleConfig: { assignment, predicate } }, isDefaultRule) =>
    (assignment.assignmentType !== AssignmentType.FIELD || fields.includes(assignment.value)) &&
    ((isDefaultRule && !predicate) || predicate.type !== PredicateType.FILTER || hasValidFilter(fields, predicate.value));
  const hasValidSourcePriorityFieldNames = (fields, { ruleConfig: { sourcePriority } }) =>
    sourcePriority.every(({ sourceName, fieldNameWithAlias }) => sourceName && fields.includes(fieldNameWithAlias));

  const hasValidFieldNames = (row, isDefaultRule) => {
    const projectionName = fieldMap[ruleSet.name].mainProjId.name;
    if (row.ruleConfig.type === RuleConfigType.CONDITIONAL) {
      const fields = getAggProjRuleFilterOptions(aggProjs, projectionName, ruleSet.name).map(({ value }) => value);
      return hasValidConditionalFieldNames(fields, row, isDefaultRule);
    }
    const fields = ingressProjs[aggProjs[projectionName].aggregates.name].fields
      .filter(field => !field.visibilityConfig.config.hidden)
      .map(({ value }) => value);
    return hasValidSourcePriorityFieldNames(fields, row);
  };

  const pasteRules = copiedRules => {
    try {
      const newRules = JSON.parse(copiedRules);
      const isValidRules = newRules.every(({ name, ruleConfig }) =>
        ruleConfig.type === RuleConfigType.CONDITIONAL
          ? name && (ruleConfig.assignment.assignmentType !== AssignmentType.FIELD || ruleConfig.assignment.value)
          : ruleConfig.sourcePriority.length
      );
      if (isValidRules) {
        const connections = newRules.reduce(
          ({ valid, skipped }, row, index) =>
            hasValidFieldNames(row, index === newRules.length - 1)
              ? { valid: [...valid, row], skipped }
              : { valid, skipped: [...skipped, row] },
          { valid: [], skipped: [] }
        );
        if (connections.skipped.length) {
          enqueueSnackbar(`Rules applied without the following fields: ${connections.skipped.map(row => `${row.name}`).join(', ')}`, {
            variant: 'warning',
          });
          console.warn('Rules that were skipped: ', connections.skipped);
        } else {
          enqueueSnackbar('Rules applied successfully', { variant: 'success' });
        }
        onChangeRules(connections.valid.map(rule => ({ ...rule, isNew: true })));
        return true;
      }
    } catch (e) {
      console.warn(e);
    }
    enqueueSnackbar(`Failed to apply: ${copiedRules.slice(0, 200)}${copiedRules.length > 199 ? '...' : ''}`, {
      variant: 'error',
    });
    return false;
  };

  return [
    {
      icon: <Copy />,
      title: 'Copy All Rules',
      onClick: () => {
        navigator.clipboard.writeText(JSON.stringify(ruleSet.rules));
        enqueueSnackbar('Rules copied to clipboard', { variant: 'success' });
      },
    },
    {
      icon: <Paste />,
      title: 'Paste Rules',
      onClick: () => {
        navigator.clipboard.readText().then(pasteRules);
      },
    },
  ];
}

export const getAggProjRuleFilterOptions = (aggProjs: AccountEntities, projectionName: string, field?: string) => {
  const isAggregatingAggProjection = !!aggProjs[aggProjs[projectionName].aggregates.name];
  return aggProjs[projectionName].fieldList.EVAL.filter(f => !isAggregatingAggProjection || f.value !== field);
};

const GET_ENHANCED_AGG_PROJ = gql`
  query aggProjections {
    aggProjections(enhancedClustering: true) {
      clusteringEnabled
      meta {
        id {
          name
          builtIn
        }
        typeId {
          name
          builtIn
        }
      }
      clusteringPredicate {
        anyofList {
          ignoreNulls
          name
          predicate {
            predicateFieldsList {
              fieldName
              celExpression
            }
            filter
          }
          fieldsList
        }
      }
      settings
      aggregates {
        name
        builtIn
      }
    }
  }
`;
export const useEnhancedAggProjs = () => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.enhancedAggProjs],
    queryFn: () => api(GET_ENHANCED_AGG_PROJ, { onSuccess: ({ data }) => data.aggProjections }),
    gcTime: 0,
  });
};

export const UPDATE_ENHANCED_PROJECTION = gql`
  mutation ($proj: AggProjectionInputScalar!) {
    updateAggProjection(proj: $proj) {
      meta {
        id {
          name
          builtIn
        }
      }
    }
  }
`;
export const useSaveEnhancedProj = () => {
  const { api } = useAvContext();
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: ({ proj, onSuccess = noop }: { proj: UpdateEnhancedProj; onSuccess: VoidFunction }) =>
      api(UPDATE_ENHANCED_PROJECTION, {
        options: { proj },
        onSuccess: () => {
          client.refetchQueries({ queryKey: ['enhancedAggProjs'] });
          enqueueSnackbar('Saved Successfully', { variant: 'success' });
          onSuccess();
        },
      }),
  });
};
