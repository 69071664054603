import React, { Fragment, ReactNode, useContext, useState } from 'react';
import { Box, ClickAwayListener, Collapse, Divider, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AvDialog from '../../../../components/AvDialog';
import { flex } from '../../../../components/AvThemeProvider';
import AvTooltip from '../../../../components/AvTooltip';
import { useAvContext } from '../../../../context/AvContextProvider';
import { FeatureFlags } from '../../../../types';
import { iconSize, noop } from '../../../../utils/Utils';
import { CustomDashboardContext } from '../../CustomDashboardContext';
import { BarSubType, LineSubType, PieSubType, TableSubType, TextSubType, TileSubType, WidgetCategory } from '../../types';
import {
  chartsDefaultSize,
  defaultBar,
  defaultLine,
  defaultPie,
  defaultTable,
  defaultText,
  defaultTile,
  defaultTrend,
  tableDefaultSize,
  trendDefaultRequestParams,
} from '../../Utils';
import { ReactComponent as Arrow } from '../../../../assets/Arrow Down.svg';
import { ReactComponent as Error } from '../../../../assets/colorful/Fail_full.svg';
import { ReactComponent as Bars } from '../../../../assets/widgetTypes/Bars.svg';
import { ReactComponent as StatusBar } from '../../../../assets/widgetTypes/ChartTile.svg';
import { ReactComponent as Donut } from '../../../../assets/widgetTypes/Donut.svg';
import { ReactComponent as HoritontalBarsStacked } from '../../../../assets/widgetTypes/Horitontal Bars Stacked.svg';
import { ReactComponent as HorizontalBars } from '../../../../assets/widgetTypes/Horitontal Bars.svg';
import { ReactComponent as HorizontalBarsStacked100 } from '../../../../assets/widgetTypes/Horizontal Bars Stacked 100.svg';
import { ReactComponent as Line2 } from '../../../../assets/widgetTypes/Line 2.svg';
import { ReactComponent as Line } from '../../../../assets/widgetTypes/Line.svg';
import { ReactComponent as Pie } from '../../../../assets/widgetTypes/Pie.svg';
import { ReactComponent as RichText } from '../../../../assets/widgetTypes/RichText.svg';
import { ReactComponent as Table } from '../../../../assets/widgetTypes/Table.svg';
import { ReactComponent as TrendTile } from '../../../../assets/widgetTypes/TrendTile.svg';
import { ReactComponent as VerticalBarsStacked100 } from '../../../../assets/widgetTypes/Vertical Bars Stacked 100.svg';
import { ReactComponent as VerticalBarsStacked } from '../../../../assets/widgetTypes/Vertical Bars Stacked.svg';

export const getKeyByValue = (object, category: string) => Object.entries(object).find(([, val]) => val === category)?.[0];

const SelectWidgetType = ({ isTabOpen, setIsTabOpen }: { isTabOpen: boolean; setIsTabOpen: (v: boolean) => void }) => {
  const theme = useTheme();
  const { featureFlags } = useAvContext();
  const { newWidget, setNewWidget, getDisplayName } = useContext(CustomDashboardContext);
  const [drillDownWarning, setDrillDownWarning] = useState({ isOpen: false, onClick: noop });
  const widgetType: WidgetTypeInfo = widgetTypeObject[newWidget.category][newWidget.type];
  const handleClose = () => {
    setDrillDownWarning({ onClick: noop, isOpen: false });
  };
  const onClickWidget = (category, type) => {
    const defaultNewWidget = widgetTypeObject[category][type].default;
    const metric = newWidget.requests[0].select.metrics?.[0] || '';
    const prevDrillDown = [WidgetCategory.Table, WidgetCategory.Pie, WidgetCategory.Bar].includes(category) && newWidget.drillDownHierarchy;

    setNewWidget({
      ...defaultNewWidget,
      id: newWidget.id,
      requests: newWidget.requests.map(r => ({
        ...r,
        ...(type === TileSubType.Trend ? trendDefaultRequestParams(r) : {}), // When selecting trend, set to a default historical query, and keep the metrics.
        top:
          r.top && newWidget.category === WidgetCategory.Table && r.top.size === tableDefaultSize
            ? { ...r.top, size: chartsDefaultSize }
            : r.top,
      })),
      category,
      type,
      drillDownHierarchy: prevDrillDown || defaultNewWidget.drillDownHierarchy,
      definition:
        category === WidgetCategory.Tile && newWidget.definition.title.title === ''
          ? { ...defaultNewWidget.definition, title: { title: getDisplayName(metric.alias || metric.name) } }
          : { ...defaultNewWidget.definition, title: { title: newWidget.definition.title.title } },
    });
  };

  const onSelectNewWidget = (category: string, type: string) => () => {
    if (
      [WidgetCategory.Table, WidgetCategory.Pie, WidgetCategory.Bar].includes(category as WidgetCategory) ||
      !newWidget.drillDownHierarchy?.fields.length
    ) {
      onClickWidget(category, type);
    } else {
      setDrillDownWarning({
        isOpen: true,
        onClick: () => {
          onClickWidget(category, type);
          handleClose();
        },
      });
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsTabOpen(false)}>
      <Box sx={{ ...flex.col, gap: 2 }}>
        <Box
          onClick={() => setIsTabOpen(!isTabOpen)}
          sx={{
            ...flex.itemsCenter,
            height: 42,
            pl: '8px',
            pr: '12px',
            border: `1px solid ${theme.palette.colors.neutrals[300]}`,
            borderRadius: '12px',
          }}>
          <Box sx={{ ...flex.itemsCenter, gap: '7px' }}>
            {widgetType.icon}
            <Typography sx={{ fontSize: 13 }} variant="h6">
              {widgetType.title}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Arrow
            style={{
              ...iconSize(18),
              ...(isTabOpen
                ? {
                    transform: 'rotate(-180deg)',
                    transition: theme.transitions.create(['transform'], { duration: theme.transitions.duration.shorter }),
                  }
                : {}),
            }}
          />
        </Box>
        <Collapse in={isTabOpen} timeout="auto" unmountOnExit>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'fit-content(100%) 0px', columnGap: 5, rowGap: 1 }}>
            {Object.keys(widgetTypeObject)
              .filter(
                category =>
                  category !== WidgetCategory.Text &&
                  (featureFlags[FeatureFlags.HistoricalAnalytics] ? true : category !== WidgetCategory.Line)
              )
              .map(category => (
                <Fragment key={category}>
                  {category !== WidgetCategory.Text && (
                    <Box sx={{ ...flex.itemsStart, pt: 1, fontSize: 12, color: theme.palette.colors.neutrals[500] }}>
                      {getKeyByValue(WidgetCategory, category)}
                    </Box>
                  )}
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', columnGap: 1, rowGap: 1 }}>
                    {Object.keys(widgetTypeObject[category]).map(
                      type =>
                        (!widgetTypeObject[category][type].featureFlag || featureFlags[widgetTypeObject[category][type].featureFlag]) && (
                          <AvTooltip key={type} title={widgetTypeObject[category][type].title}>
                            <Box
                              sx={{
                                ...flex.center,
                                px: '4px',
                                py: '3px',
                                borderRadius: '8px',
                                border: `1px solid transparent`,
                                ':hover': { backgroundColor: theme.palette.colors.neutrals[300] },
                                ...(newWidget.category === category &&
                                  newWidget.type === type && { border: `1px solid ${theme.palette.colors.neutrals[600]}` }),
                              }}
                              onClick={onSelectNewWidget(category, type)}>
                              {widgetTypeObject[category][type].icon}
                            </Box>
                          </AvTooltip>
                        )
                    )}
                    {category === WidgetCategory.Tile &&
                      Object.keys(widgetTypeObject[WidgetCategory.Text]).map(type => (
                        <AvTooltip key={type} title={widgetTypeObject[WidgetCategory.Text][type].title}>
                          <Box
                            sx={{
                              ...flex.center,
                              px: '4px',
                              py: '3px',
                              borderRadius: '8px',
                              border: `1px solid transparent`,
                              ':hover': { backgroundColor: theme.palette.colors.neutrals[300] },
                              ...(newWidget.category === WidgetCategory.Text &&
                                newWidget.type === type && { border: `1px solid ${theme.palette.colors.neutrals[600]}` }),
                            }}
                            onClick={onSelectNewWidget(WidgetCategory.Text, type)}>
                            {widgetTypeObject[WidgetCategory.Text][type].icon}
                          </Box>
                        </AvTooltip>
                      ))}
                  </Box>
                </Fragment>
              ))}
          </Box>
          <Divider sx={{ pt: 2 }} />
        </Collapse>
        <AvDialog
          open={drillDownWarning.isOpen}
          onClose={handleClose}
          title={
            <>
              <Error />
              <Box>Existing drill down settings will be deleted</Box>
            </>
          }
          footer={
            <Box sx={{ ...flex.justifyEndCenter, gap: 2 }}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" onClick={drillDownWarning.onClick}>
                Change anyway
              </Button>
            </Box>
          }>
          <Box>Changing this widget to line widget type will delete the existing drill down settings.</Box>
          <Box sx={{ pb: 5 }}>Would you like to change type anyway?</Box>
        </AvDialog>
      </Box>
    </ClickAwayListener>
  );
};

export default SelectWidgetType;

interface WidgetTypeInfo {
  value: WidgetCategory;
  title: string;
  icon: ReactNode;
}
const widgetIconStyle = { width: 33, height: 28 };
const widgetTypeObject = {
  [WidgetCategory.Bar]: {
    [BarSubType.BarVertical]: {
      value: WidgetCategory.Bar,
      title: 'Vertical Bars',
      icon: <Bars style={widgetIconStyle} />,
      default: defaultBar,
    },
    [BarSubType.BarHorizontal]: {
      value: WidgetCategory.Bar,
      title: 'Horizontal Bars',
      icon: <HorizontalBars style={widgetIconStyle} />,
      default: defaultBar,
    },
    [BarSubType.StackedVertical]: {
      value: WidgetCategory.Bar,
      title: 'Vertical Bars Stacked',
      icon: <VerticalBarsStacked style={widgetIconStyle} />,
      default: defaultBar,
    },
    [BarSubType.StackedHorizontal]: {
      value: WidgetCategory.Bar,
      title: 'Horizontal Bars Stacked',
      icon: <HoritontalBarsStacked style={widgetIconStyle} />,
      default: defaultBar,
    },
    [BarSubType.Percentage100_Vertical]: {
      value: WidgetCategory.Bar,
      title: 'Vertical Bars Stacked 100%',
      icon: <VerticalBarsStacked100 style={widgetIconStyle} />,
      default: defaultBar,
    },
    [BarSubType.Percentage100_Horizontal]: {
      value: WidgetCategory.Bar,
      title: 'Horizontal Bars Stacked 100%',
      icon: <HorizontalBarsStacked100 style={widgetIconStyle} />,
      default: defaultBar,
    },
  },
  [WidgetCategory.Line]: {
    [LineSubType.Line]: {
      value: WidgetCategory.Line,
      title: 'Line',
      icon: <Line2 style={widgetIconStyle} />,
      default: defaultLine,
    },
    [LineSubType.Spline]: {
      value: WidgetCategory.Line,
      title: 'Spline',
      icon: <Line style={widgetIconStyle} />,
      default: defaultLine,
    },
  },
  [WidgetCategory.Pie]: {
    [PieSubType.Pie]: {
      value: WidgetCategory.Pie,
      title: 'Pie',
      icon: <Pie style={widgetIconStyle} />,
      default: defaultPie(),
    },
    [PieSubType.Donut]: {
      value: WidgetCategory.Pie,
      title: 'Donut',
      icon: <Donut style={widgetIconStyle} />,
      default: defaultPie(PieSubType.Donut),
    },
  },
  [WidgetCategory.Table]: {
    [TableSubType.Table]: {
      value: WidgetCategory.Table,
      title: 'Table',
      icon: <Table style={widgetIconStyle} />,
      default: defaultTable(),
    },
  },
  [WidgetCategory.Tile]: {
    [TileSubType.Tile]: {
      value: WidgetCategory.Tile,
      title: 'Tile',
      icon: <StatusBar style={widgetIconStyle} />,
      default: defaultTile,
    },
    [TileSubType.Trend]: {
      value: WidgetCategory.Tile,
      title: 'Trend',
      icon: <TrendTile style={widgetIconStyle} />,
      default: defaultTrend,
      featureFlag: FeatureFlags.ProgressAndTrendWidgets,
    },
  },
  [WidgetCategory.Text]: {
    [TextSubType.Text]: {
      value: WidgetCategory.Text,
      title: 'Text',
      icon: <RichText style={widgetIconStyle} />,
      default: defaultText,
    },
  },
};
