import { createFileRoute } from '@tanstack/react-router';
import { PAGE_PATHS } from '../../../../types';

const path = PAGE_PATHS.EXCEPTIONS;
export const Route = createFileRoute('/$accountId/vulnerabilities/exceptions/')({
  staticData: {
    path,
    key: 'Exception',
  },
});
