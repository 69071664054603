import React from 'react';
import { Box, Button, Skeleton, useTheme } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import { ActionButton } from '../../../components/Table/Utils';
import { projToPermissionCategory } from '../../../context/AvContext.types';
import { useAvContext } from '../../../context/AvContextProvider';
import { PermissionCategoriesNames, PermissionEntitiesNames } from '../../../types';
import { reorder } from '../../../utils/DragAndDropUtils';
import { SourcePriorityRule, UnificationRule } from '../../DataUnification/types';
import { DEFAULT_RULE_NAME, GroupingRule } from './types';
import { getNextCloneName } from './utils';
import { ReactComponent as Delete } from '../../../assets/Delete.svg';
import { ReactComponent as Drag } from '../../../assets/Drag.svg';
import { ReactComponent as Clone } from '../../../assets/Duplicate.svg';
import { ReactComponent as Edit } from '../../../assets/Edit.svg';

const dragIcon = <Drag style={{ width: 18, height: 18 }} />;
const editIcon = <Edit style={{ width: 20, height: 20 }} />;
const cloneIcon = <Clone style={{ width: 20, height: 20 }} />;
const deleteIcon = <Delete style={{ width: 20, height: 20 }} />;

type Rule = GroupingRule | UnificationRule | SourcePriorityRule;
interface IProps {
  rules: Rule[];
  onChange: (rules: (Rule & { isNew?: boolean })[]) => void;
  onEdit?: (id?: string) => void;
  active?: string;
  findDefaultFallbackRule?: (rule: Rule, index: number, arr?) => boolean;
  headCells?: { label: string; formatter: (rule, rowIndex?) => React.ReactNode | string; width?: string; sx?: any; hasTooltip?: boolean }[];
  isLoading?: boolean;
  projectionName?: string;
  permissionConfiguration?: {
    resource: PermissionEntitiesNames;
    category?: PermissionCategoriesNames;
  };
  permissionsMapOverride?: Record<string, boolean>;
  buttonProps?: {
    edit?: { isHidden: boolean };
    delete?: {
      isHidden?: boolean;
      disabled?: (rules) => boolean;
      tooltip?: (isDeleteDisabled: boolean, isLoading: boolean) => string | undefined;
    };
    clone?: { isHidden: boolean };
  };
  variant?: 'normal' | 'compact' | 'transparent';
  showOrderColumn?: boolean;
  showHeaders?: boolean;
}
function RulesList({
  rules: originalRules,
  onChange,
  onEdit,
  active = '-1',
  findDefaultFallbackRule = r => !!(r as GroupingRule).defaultFallbackRule,
  headCells = [],
  isLoading,
  projectionName,
  permissionConfiguration = {
    resource: PermissionEntitiesNames.SETTINGS,
    category: projToPermissionCategory[projectionName!],
  },
  permissionsMapOverride = {},
  buttonProps,
  variant = 'normal',
  showOrderColumn = true,
  showHeaders = true,
}: IProps) {
  const { palette, components } = useTheme();
  const {
    userPermissions: { allowedPermissionsToResource },
  } = useAvContext();
  const hasPermissionsMap = { ...allowedPermissionsToResource(permissionConfiguration), ...permissionsMapOverride };
  const rules = isLoading ? ([1, 2, 3].map(id => ({ id })) as any) : originalRules;
  const defaultRule = originalRules.find(findDefaultFallbackRule) as GroupingRule | UnificationRule;
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const items = reorder(originalRules, result.source.index, result.destination.index);
    if (items.filter((...args) => !findDefaultFallbackRule(...args)).length > 1) {
      onChange(items);
    }
  };

  const onActive = rule => e => {
    onEdit!(active === rule.id ? undefined : (rule.id as string));
    e.stopPropagation();
  };

  return (
    <Box sx={{ ...flex.row, width: '100%' }}>
      {showOrderColumn && (
        <div>
          {showHeaders && <Box sx={{ ...headerStyle(variant), color: palette.colors.neutrals[500], pl: 0 }}>Order</Box>}
          <Box sx={{ ...flex.colJustifyCenter, gap: 1 }}>
            {rules.map((r, i) => (
              <Box
                /* eslint-disable-next-line react/no-array-index-key */
                key={i}
                sx={{
                  ...itemStyle(variant),
                  justifyContent: 'center',
                  pointerEvents: 'none',
                  color: palette.colors.neutrals[500],
                  pl: 0,
                  ...(variant === 'compact' ? { pr: 2 } : {}),
                }}>
                {i + 1}
              </Box>
            ))}
          </Box>
        </div>
      )}
      <Box sx={{ flex: 1 }}>
        {showHeaders && (
          <Box sx={gridStyle(headCells)}>
            <Box />
            {headCells.map(({ label }) => (
              <Box key={label} sx={headerStyle(variant)}>
                {label}
              </Box>
            ))}
          </Box>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <Box {...provided.droppableProps} ref={provided.innerRef} sx={flex.col}>
                {rules.map((rule, index) => {
                  const isDeleteDisabled = buttonProps?.delete?.disabled?.(rules) || false;
                  const actions = [
                    ...(!buttonProps?.edit?.isHidden && hasPermissionsMap.UPDATE
                      ? [ActionButton('Edit', () => onEdit!(rule.id as string), editIcon)]
                      : []),
                    ...(!buttonProps?.delete?.isHidden && hasPermissionsMap.DELETE
                      ? isDeleteDisabled || isLoading
                        ? [
                            <AvTooltip
                              title={
                                buttonProps?.delete?.tooltip?.(isDeleteDisabled, isLoading!) || (isDeleteDisabled ? undefined : 'Delete')
                              }
                              muiProps={{ placement: 'top' }}>
                              <Box sx={flex.row}>
                                <Button disabled>{deleteIcon}</Button>
                              </Box>
                            </AvTooltip>,
                          ]
                        : [ActionButton('Delete', () => onChange(rules.filter(r => r !== rule)), deleteIcon)]
                      : []),
                    ...(!buttonProps?.clone?.isHidden && hasPermissionsMap.UPDATE
                      ? [
                          ActionButton(
                            'Clone',
                            () =>
                              onChange(
                                rules.toSpliced(index + 1, 0, {
                                  ...structuredClone(rule),
                                  id: crypto.randomUUID(),
                                  isNew: true,
                                  name: getNextCloneName(
                                    rules.map(({ name }) => name),
                                    rule.name
                                  ),
                                } as any)
                              ),
                            cloneIcon
                          ),
                        ]
                      : []),
                  ];
                  return findDefaultFallbackRule(rule, index) ? null : (
                    <Draggable
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      index={index}
                      draggableId={`${index}`}
                      isDragDisabled={!hasPermissionsMap.CREATE && !hasPermissionsMap.UPDATE}>
                      {provided => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={onEdit ? onActive(rule) : undefined}
                          sx={[
                            gridStyle(headCells),
                            rulesListItemStyle({ isLoading, hasEdit: onEdit, variant }),
                            active === rule.id ? components!.MuiTableRow!.styleOverrides!.root!['&.active-row'] : {},
                          ]}>
                          <Box sx={[itemStyle(variant), variant === 'compact' ? { pl: 2 } : {}, { color: palette.colors.neutrals[600] }]}>
                            {hasPermissionsMap.CREATE || hasPermissionsMap.UPDATE ? dragIcon : null}
                          </Box>
                          {headCells?.map(({ formatter, label, sx = {}, hasTooltip = true }) => (
                            <Box key={label} sx={[itemStyle(variant), { gap: 1 }, sx]}>
                              {isLoading ? (
                                <Skeleton variant="text" height={21} width="80%" />
                              ) : hasTooltip ? (
                                <AvTooltip>{formatter(rule, index)}</AvTooltip>
                              ) : (
                                formatter(rule, index)
                              )}
                            </Box>
                          ))}
                          {!isLoading && (
                            <Box
                              onClick={e => e.stopPropagation()}
                              sx={{
                                position: 'absolute',
                                right: 24,
                                height: '100%',
                                ...flex.itemsCenter,
                                gap: 2,
                                transition: theme =>
                                  theme.transitions.create(['opacity'], { duration: theme.transitions.duration.shorter }),
                                backgroundImage:
                                  variant === 'transparent'
                                    ? 'transparent'
                                    : `linear-gradient(to right, transparent, ${palette.colors.neutrals[100]} 20%)`,
                                pl: 4,
                              }}>
                              {actions}
                            </Box>
                          )}
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        {defaultRule && (
          <Box
            onClick={onEdit ? onActive(defaultRule) : undefined}
            sx={[
              gridStyle(headCells),
              rulesListItemBaseStyle({ isLoading, hasEdit: onEdit, variant }),
              { border: variant === 'transparent' ? undefined : theme => `1px solid ${theme.palette.colors.neutrals[400]}` },
              defaultRule.id === active ? components!.MuiTableRow!.styleOverrides!.root!['&.active-row'] : {},
            ]}>
            <Box />
            {headCells?.map(({ formatter, label }) => (
              <Box key={label} sx={[itemStyle(variant), { gap: 1 }]}>
                {isLoading ? <Skeleton variant="text" height={21} width="80%" /> : <AvTooltip>{formatter(defaultRule)}</AvTooltip>}
              </Box>
            ))}
            <Box
              onClick={e => e.stopPropagation()}
              sx={{
                position: 'absolute',
                right: 24,
                height: '100%',
                ...flex.itemsCenter,
                gap: 2,
                transition: theme => theme.transitions.create(['opacity'], { duration: theme.transitions.duration.shorter }),
                pl: 4,
              }}>
              {(defaultRule.id || defaultRule.name === DEFAULT_RULE_NAME) &&
                hasPermissionsMap.UPDATE &&
                onEdit &&
                ActionButton('Edit', () => onEdit(defaultRule.id as string), editIcon)}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default RulesList;

const gridStyle = cols => ({
  display: 'grid',
  gridTemplateColumns: `40px ${cols.map(({ width }) => width || 'auto').join(' ')}`,
  position: 'relative',
});

export const itemStyle = variant => ({
  ...flex.itemsCenter,
  height: variant === 'transparent' ? 40 : 54,
  py: variant === 'transparent' ? 1 : 2,
  px: variant === 'compact' ? 0.5 : variant === 'transparent' ? 1 : 3,
  cursor: 'pointer',
});

const headerStyle = variant => ({
  py: 1,
  px: variant === 'compact' ? 0.5 : variant === 'transparent' ? 1 : 3,
  fontWeight: 600,
});

export const rulesListItemBaseStyle = ({ isLoading, hasEdit, variant }) => ({
  mb: 1,
  pointerEvents: isLoading ? 'none' : undefined,
  ':hover':
    variant === 'transparent'
      ? { background: theme => theme.palette.colors.neutrals[300] }
      : { boxShadow: '0px 15px 50px rgba(0, 0, 0, 0.1)', zIndex: 1 },
  transition: theme => theme.transitions.create(['box-shadow'], { duration: theme.transitions.duration.shorter }),
  '&:not(:hover) > :last-of-type': { opacity: 0 },
  cursor: hasEdit ? 'pointer' : 'grab',
});
export const rulesListItemStyle = ({ isLoading = false, hasEdit, variant }) => ({
  ...rulesListItemBaseStyle({ isLoading, hasEdit, variant }),
  backgroundColor: variant === 'transparent' ? 'transparent' : theme => theme.palette.white.main,
  ...(variant === 'compact' ? { border: theme => `1px solid ${theme.palette.colors.neutrals[300]}`, borderRadius: '6px' } : {}),
});
