import React from 'react';
import AvButton, { ButtonKinds } from '@components/AvButton';
import { SxProps } from '@mui/material';
import { emptyObject } from '@utils/Utils';
import { ReactComponent as X } from '../assets/X.svg';

const clearIcon = <X style={{ width: 20, height: 20 }} />;

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps;
}

export default function ClearButton({ onClick, sx = emptyObject }: Props) {
  return (
    <AvButton
      kind={ButtonKinds.ICON}
      key="clear"
      tabIndex={-1}
      aria-label="Clear"
      onClick={onClick}
      sx={{ color: theme => theme.palette.colors.neutrals[500], ...sx }}>
      {clearIcon}
    </AvButton>
  );
}
