import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Divider, InputLabel, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import AvSchemaFieldCompletionEditor from '../../components/AvSchemaFieldCompletionEditor';
import AvSidePanel from '../../components/AvSidePanel';
import { flex } from '../../components/AvThemeProvider';
import NewExpressionBuilder from '../../components/filters/NewExpressionBuilder';
import { booleanOptions, booleanOptions2 } from '../../components/filters/Utils';
import RadioButton from '../../components/RadioButton';
import Select from '../../components/Select';
import TextInput from '../../components/TextInput';
import { useAvContext } from '../../context/AvContextProvider';
import { LayoutContext } from '../../context/LayoutContext';
import { FeatureFlags } from '../../types';
import { Filter } from '../../types/filter.types';
import { SelectOption } from '../Rules/rules.options';
import { editorPopperTypeProps } from '../Sources/Mapping/EditorPopper';
import { SchemaTypes, typeLabelMap } from '../Sources/Mapping/mapping.types';
import CELExpressionInput from './CELExpressionInput';
import { assigneeRadioOptions, defaultValues } from './constants';
import { getAggProjRuleFilterOptions } from './hooks';
import { AssignmentType, ConditionalRuleConfig, PredicateType, UnificationRule } from './types';
import { emptyRule } from './Utils';

const assignmentErrorMessages = {
  [AssignmentType.FIELD]: 'field is required',
  [AssignmentType.CEL_EXPRESSION]: 'expression is required',
};

interface Props {
  rule: UnificationRule;
  field: string;
  onFieldChange: (value) => void;
  projectionName: string;
  ruleSet: { rules: UnificationRule[] };
  isOpen: boolean;
  onClose: () => void;
  assignmentProps?: {
    title?: string;
    options?: SelectOption[];
  };
}
function EditConditionRule({ rule, field, onFieldChange, projectionName, isOpen, ruleSet, onClose, assignmentProps }: Props) {
  const { sideBar: sidebarWidth } = useContext(LayoutContext).layoutWidths;
  const {
    featureFlags,
    accountEntities: { aggProjs, fieldMap },
  } = useAvContext();
  const [errors, setErrors] = useState<UnificationRule>({} as UnificationRule);
  const ruleConfigErrors = errors.ruleConfig as ConditionalRuleConfig;
  const fieldType = typeLabelMap[fieldMap[field].originalType.kind || fieldMap[field].type];
  const [newRule, setNewRule] = useState<UnificationRule>(emptyRule(fieldType));
  const ruleConfig = newRule.ruleConfig as ConditionalRuleConfig;
  const onChange = field => value => setNewRule({ ...newRule, [field]: value });
  const onRuleConfigChange = field => value => setNewRule({ ...newRule, ruleConfig: { ...ruleConfig, [field]: value } });
  const filterOptions = useMemo(() => getAggProjRuleFilterOptions(aggProjs, projectionName, field), [projectionName, field]);
  const isEditMode = !newRule.isNew;
  const defaultValueOptions = useMemo(
    () =>
      filterOptions.filter(
        ({ repeated, originalType, type }: any) =>
          fieldMap[field].repeated === repeated && fieldType === typeLabelMap[originalType.kind || type]
      ),
    [field]
  );

  const getAssignmentError = () => {
    const assignment = ruleConfig?.assignment;
    if (assignment && !assignment.value) {
      const message = assignmentErrorMessages[assignment.assignmentType];
      return message ? { ruleConfig: { assignment: { value: message } } } : {};
    }
    return {};
  };

  const getPredicateError = () => {
    const predicate = ruleConfig?.predicate;
    return predicate && predicate.type === PredicateType.CEL_EXPRESSION && !predicate.value
      ? { ruleConfig: { predicate: { value: 'expression is required' } } }
      : {};
  };

  const onSave = () => {
    const newErrors = {
      ...(!newRule.name ? { name: 'Name is required' } : {}),
      ...(ruleSet.rules.some(rule => rule.id !== newRule.id && rule.name === newRule.name)
        ? { name: 'This rule name already exists' }
        : {}),
      ...getAssignmentError(),
      ...getPredicateError(),
    };
    if (Object.keys(newErrors).length) {
      setErrors(newErrors as any);
    } else {
      onFieldChange(newRule);
      doClose();
    }
  };

  const doClose = () => {
    onClose();
    setErrors({} as any);
    setNewRule(emptyRule(fieldType));
  };

  useEffect(() => {
    if (rule) {
      // TODO revert this ugliness once migration is done AVA-13439
      const conditionalRule = { ...rule, ruleConfig: rule.ruleConfig as ConditionalRuleConfig };
      setNewRule({
        ...conditionalRule,
        ruleConfig: {
          ...conditionalRule.ruleConfig,
          assignment: {
            ...conditionalRule.ruleConfig.assignment,
            assignmentType:
              fieldType === SchemaTypes.STRING && conditionalRule.ruleConfig.assignment.assignmentType === AssignmentType.VALUE
                ? AssignmentType.STRING_TEMPLATE
                : conditionalRule.ruleConfig.assignment.assignmentType,
          },
        },
      });
    }
  }, [rule]);

  const shouldIncludeValueInput = [SchemaTypes.DATETIME, SchemaTypes.DATE].includes(fieldType);
  const radioOptions = useMemo(
    () =>
      [
        ...assigneeRadioOptions.filter(
          ({ value }) =>
            (fieldType === SchemaTypes.STRING || value !== AssignmentType.STRING_TEMPLATE) &&
            (![SchemaTypes.DATETIME, SchemaTypes.DATE, SchemaTypes.STRING].includes(fieldType) || value !== AssignmentType.VALUE) &&
            (featureFlags[FeatureFlags.EnableUnificationCEL] || value !== AssignmentType.CEL_EXPRESSION)
        ),
        ...(shouldIncludeValueInput ? [{ value: AssignmentType.VALUE, title: 'Empty' }] : []),
      ].map(item =>
        editorPopperTypeProps[item.value]?.tooltip
          ? {
              ...item,
              title: (
                <Box sx={{ '&, > div': { ...flex.itemsCenter, gap: 1 } }}>
                  {item.title}
                  {editorPopperTypeProps[item.value].tooltip()}
                </Box>
              ),
            }
          : item
      ),
    [fieldType]
  );
  return !isOpen ? (
    <AvSidePanel storageKey="dataUnificationInfo" />
  ) : (
    <AvSidePanel
      storageKey="dataUnificationInfo"
      maxWidthOffset={sidebarWidth}
      isOpen={isOpen}
      minWidth={940}
      onClose={doClose}
      headComponent={
        <Box sx={{ ...flex.col, gap: 3 }}>
          <Typography variant="h5">{isEditMode ? ' Edit' : 'Create'} Unification Rule</Typography>
          <Box sx={{ ...flex.itemsCenter, gap: 5 }}>
            <InputLabel required sx={{ fontSize: '12px', fontWeight: 500 }}>
              NAME
            </InputLabel>
            <TextInput size="small" isRequired label="" value={newRule.name} onChange={onChange('name')} error={errors.name} autoFocus />
          </Box>
        </Box>
      }
      footerComponent={
        <Box sx={{ ...flex.justifyEnd, gap: 1 }}>
          <Button size="small" onClick={doClose}>
            Cancel
          </Button>
          <Button size="small" variant="contained" onClick={onSave}>
            {isEditMode ? 'Done' : 'Add'}
          </Button>
        </Box>
      }>
      <Typography variant="h5" sx={{ ...flex.justifyBetween, my: 2 }}>
        {ruleConfig.predicate ? 'If' : 'Fallback Unification Logic'}
        {featureFlags[FeatureFlags.EnableUnificationCEL] && ruleConfig.predicate && (
          <ToggleButtonGroup
            size="xSmall"
            value={ruleConfig.predicate.type}
            color="white"
            onChange={({ target }) => {
              const type = (target as HTMLInputElement).value;
              const newRuleConfig = rule?.ruleConfig as ConditionalRuleConfig;
              return onRuleConfigChange('predicate')({
                type,
                value: newRuleConfig?.predicate!.type === type ? newRuleConfig.predicate.value : undefined,
              });
            }}>
            <ToggleButton value={PredicateType.FILTER}>Conditions</ToggleButton>
            <ToggleButton value={PredicateType.CEL_EXPRESSION}>Expression</ToggleButton>
          </ToggleButtonGroup>
        )}
      </Typography>
      {ruleConfig.predicate?.type === PredicateType.FILTER ? (
        <NewExpressionBuilder
          fields={filterOptions}
          isVertical={false}
          filter={ruleConfig.predicate.value as Filter}
          canDeleteLast={false}
          setFilter={value => onRuleConfigChange('predicate')({ type: PredicateType.FILTER, value })}
        />
      ) : ruleConfig.predicate?.type === PredicateType.CEL_EXPRESSION ? (
        <Box sx={{ p: 1.5, border: ({ palette }) => `1px solid ${palette.colors.neutrals[300]}` }}>
          <CELExpressionInput
            projectionName={projectionName}
            onChange={value => onRuleConfigChange('predicate')({ type: PredicateType.CEL_EXPRESSION, value })}
            value={ruleConfig.predicate.value?.toString()}
            error={ruleConfigErrors?.predicate?.value.toString()}
          />
        </Box>
      ) : (
        <div>This rule will apply if none of the rules are met</div>
      )}
      <Divider sx={{ mx: -2, mt: 1, mb: 1 }} />
      <Typography variant="h5" sx={{ my: 2 }}>
        {assignmentProps?.title ?? `Set ${fieldMap[field].title} as`}
      </Typography>
      {!assignmentProps?.options ? (
        <RadioButton
          options={radioOptions}
          value={ruleConfig.assignment.assignmentType}
          onChange={assignmentType => {
            const newRuleConfig = rule?.ruleConfig as ConditionalRuleConfig;
            onRuleConfigChange('assignment')({
              assignmentType,
              value: newRuleConfig?.assignment.assignmentType === assignmentType ? newRuleConfig.assignment.value : '',
            });
            setErrors({} as UnificationRule);
          }}
        />
      ) : null}
      {ruleConfig.assignment.assignmentType === AssignmentType.FIELD ? (
        <Select
          label=""
          isRequired
          size="small"
          options={defaultValueOptions}
          groupByFunc={({ group }) => group}
          onChange={value => onRuleConfigChange('assignment')({ ...ruleConfig.assignment, value })}
          value={ruleConfig.assignment.value || undefined}
          placeholder="Select field..."
          error={ruleConfigErrors?.assignment?.value?.toString()}
          style={{ width: 230 }}
        />
      ) : ruleConfig.assignment.assignmentType === AssignmentType.STRING_TEMPLATE ? (
        assignmentProps?.options ? (
          <Select
            options={assignmentProps.options}
            size="small"
            onChange={value => onRuleConfigChange('assignment')({ ...ruleConfig.assignment, value })}
            value={ruleConfig.assignment.value.toString() || undefined}
            placeholder="Select value"
            isRequired
          />
        ) : (
          <AvSchemaFieldCompletionEditor
            projectionName={projectionName}
            onChange={value => onRuleConfigChange('assignment')({ ...ruleConfig.assignment, value })}
            value={ruleConfig.assignment.value.toString()}
            error={ruleConfigErrors?.assignment?.value.toString()}
          />
        )
      ) : ruleConfig.assignment.assignmentType === AssignmentType.CEL_EXPRESSION ? (
        <Box sx={{ width: '100%' }}>
          <CELExpressionInput
            projectionName={projectionName}
            onChange={value => onRuleConfigChange('assignment')({ ...ruleConfig.assignment, value })}
            value={ruleConfig.assignment.value.toString()}
            error={ruleConfigErrors?.assignment?.value.toString()}
          />
        </Box>
      ) : fieldType === SchemaTypes.BOOL ? (
        <Select
          acceptNullValue
          value={ruleConfig.assignment.value === '' ? defaultValues[fieldType] : ruleConfig.assignment.value}
          onChange={value => onRuleConfigChange('assignment')({ ...ruleConfig.assignment, value })}
          size="small"
          options={featureFlags[FeatureFlags.AllowEmptyAssignmentOnUnification] ? booleanOptions : booleanOptions2}
          isRequired
          style={{ width: 230 }}
        />
      ) : !shouldIncludeValueInput ? (
        <TextInput
          value={ruleConfig.assignment.value?.toString()}
          style={{ maxWidth: 'unset', width: 230 }}
          size="small"
          onChange={value => onRuleConfigChange('assignment')({ ...ruleConfig.assignment, value })}
          type={fieldType === SchemaTypes.NUMBER ? 'number' : undefined}
          label=""
        />
      ) : undefined}
    </AvSidePanel>
  );
}

export default EditConditionRule;
