import React from 'react';
import { createFileRoute, Navigate } from '@tanstack/react-router';
import { useAvContext } from '../context/AvContextProvider';

export const Route = createFileRoute('/')({
  component: RouteComponent,
});

function RouteComponent() {
  const { accountId } = useAvContext();
  if (accountId) {
    return <Navigate to="/$accountId" params={{ accountId }} />;
  }
}
