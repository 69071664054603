import { flex } from '../../components/AvThemeProvider';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { getEscapedValue } from '../../utils/Utils';
import { fixTypeOptionsMap, pivotOptionsMap } from './FixesTab/pivotFixTable.utils';

export const getTicketSqlV2 = (ticketKey, notificationTargetsQuery, keepSlaWithoutFormatting, enableExceptions) => `SELECT
            ticket._key,
            ticket.severity as severity, 
            ticket.created as created, 
            ticket.${entityViewConfig.Ticket.titleFieldName} as title, 
            ${keepSlaWithoutFormatting ? 'ticket.sla' : 'DAY(ticket.sla)'} AS sla,
            ticket.first_seen as firstSeen,
            ticket.finding_count as allFindingCount,
            ticket.active_finding_count as findingCount,
            ticket.asset_count as allAssetCount,
            ticket.active_asset_count as assetCount, 
            ticket.integration_info as integration,
            ticket.severity_score as severityScore, 
            ticket.current_status.name as avalorStatus, 
            ticket.current_status.timestamp as statusTimestamp, 
            ticket.original_severity_score as originalSeverityScore, 
            ticket.original_severity as originalSeverity,
            ticket.is_fixable as isFixable,
            ticket.grouping_explanation as groupingExplanation,
            low_active_findings_risk_mass_granular as low_active_findings_risk_mass_granular,
            medium_active_findings_risk_mass_granular as medium_active_findings_risk_mass_granular,
            high_active_findings_risk_mass_granular as high_active_findings_risk_mass_granular,
            critical_active_findings_risk_mass_granular as critical_active_findings_risk_mass_granular,
            active_findings_risk_mass_granular as active_findings_risk_mass_granular,
            active_findings_risk_mass_granular,
            ticket.locked,
            ticket.state,
            ${
              enableExceptions
                ? 'COLLECT_IF(json_object(exception: json_object(key: ticket.exception._key, requested_sla: ticket.exception.requested_sla, requester_email: ticket.exception.requester_email, requester_name: ticket.exception.requester.name, reviewer_email: ticket.exception.reviewer_email, reviewer_name: ticket.exception.reviewer.name, status: ticket.exception.current_status)), ticket.exception._key != null) as exceptions, '
                : ''
            }
            COLLECT_IF(json_object(comment: json_object(key: ticket.comments._key, userId: ticket.comments.user_id, created: ticket.comments.created, updated: ticket.comments.last_update, text: ticket.comments.text, userName: ticket.comments.username, userEmail: ticket.comments.user_email)), ticket.comments._key != null) as comments,
            COLLECT_IF(json_object(commentAttachment: json_object(key: ticket.comments.attachments._key, original_name: ticket.comments.attachments.original_name, stored_name: ticket.comments.attachments.stored_name, content_type: ticket.comments.attachments.content_type, related_entity_key: ticket.comments.attachments.related_entity_key)), ticket.comments.attachments._key != null) as commentAttachments,
            COLLECT_IF(json_object(attachment: json_object(key: ticket.attachments._key, original_name: ticket.attachments.original_name, stored_name: ticket.attachments.stored_name, content_type: ticket.attachments.content_type, related_entity_key: ticket.attachments.related_entity_key)), ticket.attachments._key != null) as attachments,
            remediation_percentage_granular as remediation,
            ticket.type as ticketType ${notificationTargetsQuery ? `,${notificationTargetsQuery}` : ''}
          FROM tickets WHERE ticket._key='${getEscapedValue(ticketKey)}'`;

export const getIncidentSqlV2 = (ticketKey, notificationTargetsQuery) => `SELECT
            incident._key,
            incident.severity as severity,
            incident.created as created,
            incident.type as ticketType,
            incident.${entityViewConfig.Incident.titleFieldName} as title,
            incident.sla AS sla,
            incident.first_seen as firstSeen,
            incident.alert_count as allAlertCount,
            COUNT_DISTINCT(event._key) as allEventCount,
            incident.integration_info as integration,
            incident.severity_score as severityScore,
            incident.current_status.name as avalorStatus,
            incident.grouping_explanation as groupingExplanation,
            COLLECT_IF(json_object(comment: json_object(key: incident.comments._key, userId: incident.comments.user_id, created: incident.comments.created, updated: incident.comments.last_update, text: incident.comments.text, userName: incident.comments.username, userEmail: incident.comments.user_email)), incident.comments._key != null) as comments,
            COLLECT_IF(json_object(commentAttachment: json_object(key: incident.comments.attachments._key, original_name: incident.comments.attachments.original_name, stored_name: incident.comments.attachments.stored_name, content_type: incident.comments.attachments.content_type, related_entity_key: incident.comments.attachments.related_entity_key)), incident.comments.attachments._key != null) as commentAttachments,
            COLLECT_IF(json_object(attachment: json_object(key: incident.attachments._key, original_name: incident.attachments.original_name, stored_name: incident.attachments.stored_name, content_type: incident.attachments.content_type, related_entity_key: incident.attachments.related_entity_key)), incident.attachments._key != null) as attachments,
            incident.original_severity_score as originalSeverityScore,
            incident.original_severity as originalSeverity
            ${notificationTargetsQuery ? `,${notificationTargetsQuery}` : ''}
            FROM incidents
            WHERE incident._key='${getEscapedValue(ticketKey)}'
            GROUP BY incident._key, severity, created, ticketType, title, sla,
            firstSeen, allAlertCount, integration, severityScore, avalorStatus,
            originalSeverityScore, originalSeverity, groupingExplanation`;

export const getAssetsSqlV2 = (ticketKey, notificationTargetsQuery) => `SELECT
            asset._key,
            asset.${entityViewConfig.Asset.titleFieldName} as title,
            asset.first_seen as firstSeen,
            asset.created as created,
            max_risk_granular as severityScore,
            asset.type as ticketType,
            low_active_findings_risk_mass_granular as low_active_findings_risk_mass_granular,
            medium_active_findings_risk_mass_granular as medium_active_findings_risk_mass_granular,
            high_active_findings_risk_mass_granular as high_active_findings_risk_mass_granular,
            critical_active_findings_risk_mass_granular as critical_active_findings_risk_mass_granular,
            active_findings_risk_mass_granular as active_findings_risk_mass_granular,
            total_findings_granular as total_findings_granular,
            total_tickets_granular as total_tickets_granular
            ${notificationTargetsQuery ? `,${notificationTargetsQuery}` : ''}
          FROM uber_assets
          WHERE asset._key='${getEscapedValue(ticketKey)}'
          GROUP BY asset._key, title, firstSeen, created, ticketType`;

export const getFindingSqlV2 = ticketKey => `SELECT
            finding._key,
            finding.${entityViewConfig.Finding.titleFieldName} as title,
            finding.first_seen as firstSeen,
            finding.created as created,
            finding.type as ticketType,
            finding.original_severity_score as originalSeverityScore,
            finding.severity_score as severityScore,
            finding.severity as severity,
            finding.state as state
          FROM uber_findings
          WHERE finding._key='${getEscapedValue(ticketKey)}'`;

export const getFactorSql = ticketKey => `SELECT
            factor._key,
            (factor.raw_score / factor.weight) * 100 as severity,
            factor.${entityViewConfig.Factor.titleFieldName} as title,
            factor.effective_normalized_score as scoreLeft,
            factor_normalized_max_score_granular as scoreRight,
            factor.state as state
          FROM uber_factors
          WHERE factor._key='${getEscapedValue(ticketKey)}'`;

export const getPolicyPopulationSql = (policyPopulationsKey, notificationTargetsQuery) => `SELECT
            policy_population._key,
            policy_population.${entityViewConfig.PolicyPopulation.titleFieldName} as title,
            policy_population.first_seen as firstSeen,
            policy_population.severity_score as severityScore,
            policy_population.severity as severity,
            policy_population.created as created,
            policy_population.current_status as avalorStatus,
            policy_population.integration_info as integration,
            policy_population.state as state
           ${notificationTargetsQuery ? `,${notificationTargetsQuery}` : ''}
          FROM uber_policy_populations
          WHERE policy_population._key='${getEscapedValue(policyPopulationsKey)}'`;

export const getPolicyViolationSql = (policyViolationKey, notificationTargetsQuery) => `SELECT
            policy_violation._key,
            policy_violation.${entityViewConfig.PolicyPopulation.titleFieldName} as title,
            policy_violation.first_seen as firstSeen,
            policy_violation.severity_score as severityScore,
            policy_violation.severity as severity,
            policy_violation.created as created,
            policy_violation.current_status as avalorStatus,
            policy_violation.integration_info as integration,
            policy_violation.state as state
           ${notificationTargetsQuery ? `,${notificationTargetsQuery}` : ''}
          FROM uber_policy_violations
          WHERE policy_violation._key='${getEscapedValue(policyViolationKey)}'`;

export const getAlertsSql = (ticketKey, notificationTargetsQuery) => `SELECT
            alert._key,
            alert.${entityViewConfig.Alert.titleFieldName} as title,
            COUNT_DISTINCT(event._key) as allEventCount,
            alert.summary_attributes as summaryAttributes,
            alert.description as description,       
            alert.first_seen as firstSeen,
            alert.last_seen as lastSeen,
            alert.created as created,
            alert.type as ticketType,
            alert.rule_id as ruleId,
            alert.rule_name as ruleName,
            alert.severity_score as severityScore,
            alert.severity as severity,
            alert.original_severity_score as originalSeverityScore,
            alert.original_severity as originalSeverity,
            alert.tactic as tacticId,
            alert.technique as techniqueId
            ${notificationTargetsQuery ? `,${notificationTargetsQuery}` : ''}
          FROM uber_alerts
          WHERE alert._key='${getEscapedValue(ticketKey)}'
          GROUP BY alert._key, title, summaryAttributes, description, firstSeen, lastSeen, created,
          ticketType, severityScore, severity, originalSeverityScore, originalSeverity,
          ruleId, ruleName, tacticId, techniqueId`;

export const getExceptionsSql = (ticketKey, notificationTargetsQuery) => `SELECT
            exception._key,
            exception.created as created,
            exception.current_status as avalorStatus,
            exception.integration_info as integration,
            ticket.sla as originalTicketSLA,
            exception.approved_sla,
            ('for: ' + ticket.title) as title,
            COLLECT_IF(json_object(attachment: json_object(key: exception.attachments._key, original_name: exception.attachments.original_name, stored_name: exception.attachments.stored_name, content_type: exception.attachments.content_type, related_entity_key: exception.attachments.related_entity_key)), exception.attachments._key != null) as attachments,
            COLLECT_IF(json_object(comment: json_object(key: exception.comments._key, userId: exception.comments.user_id, created: exception.comments.created, updated: exception.comments.last_update, text: exception.comments.text, userName: exception.comments.username, userEmail: exception.comments.user_email)), exception.comments._key != null) as comments,
            COLLECT_IF(json_object(commentAttachment: json_object(key: exception.comments.attachments._key, original_name: exception.comments.attachments.original_name, stored_name: exception.comments.attachments.stored_name, content_type: exception.comments.attachments.content_type, related_entity_key: exception.comments.attachments.related_entity_key)), exception.comments.attachments._key != null) as commentAttachments
            ${notificationTargetsQuery ? `,${notificationTargetsQuery}` : ''}
          FROM uber_exceptions
          WHERE exception._key='${getEscapedValue(ticketKey)}'`;

export const beforeEditStyle = {
  content: '""',
  position: 'absolute',
  top: '-2px',
  left: '-12px',
  padding: '2px 12px',
  width: '100%',
  height: '100%',
  boxSizing: 'content-box',
  borderRadius: '8px',
  zIndex: -1,
  backgroundColor: 'transparent',
  transition: theme =>
    theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shorter,
    }),
};
export const editFieldStyle = {
  width: '100%',
  cursor: 'pointer',
  outline: 'none',
  position: 'relative',
  ...flex.itemsCenter,
  gap: 2,
  ':before': {
    ...beforeEditStyle,
  },
  '&:hover:before, &:focus:before': {
    backgroundColor: theme => theme.palette.colors.neutrals[300],
  },
  '&:focus > div': {
    textOverflow: 'unset',
  },
};

export const defaultFixesTabConfig = {
  fixType: fixTypeOptionsMap.kb,
  filters: {},
  fixesView: pivotOptionsMap.component,
  selectedHeadersIndexMap: Object.keys(pivotOptionsMap).reduce((acc, key) => ({ ...acc, [key]: 0 }), {}),
};

export const slaStringFormat = 'MMM dd, yyyy';
