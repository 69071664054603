import { BaseWidget, WidgetCategory, WidgetDefinition } from './base.types';
import { FieldType } from './QueryObject.types';

export enum TableSubType {
  Table = 'TABLE',
}

export enum ColumnFormatter {
  Progress = 'Data Bar',
  None = 'None',
  Text = 'Text',
  SourceIcons = 'Icons',
  SourceIconsWithNames = 'Icons + Text',
}

export const columnFormatterTypes: Record<ColumnFormatter, FieldType[]> = {
  [ColumnFormatter.Progress]: [FieldType.metric],
  [ColumnFormatter.None]: [FieldType.metric],
  [ColumnFormatter.SourceIcons]: [FieldType.dim],
  [ColumnFormatter.Text]: [FieldType.dim],
  [ColumnFormatter.SourceIconsWithNames]: [FieldType.dim],
};
export type FieldStyleType = {
  columnFormatter?: ColumnFormatter;
  formattingRuleId?: string;
};

export type TableCustomDefinition = {
  rowSize: TableRowSize;
  showTotals?: boolean;
  crossTableView?: boolean;
  fieldStyle?: Record<string, FieldStyleType>;
};

export type TableWidget = BaseWidget & {
  category: WidgetCategory.Table;
  definition: WidgetDefinition & { custom: TableCustomDefinition };
};

export enum TableRowSize {
  Small = 'xSmall',
  Medium = 'small',
  Large = 'large',
  Tiles = 'tiles',
}

export type TableTypeWidget = TableWidget & {
  type: TableSubType.Table;
};

export type TableCategoryWidgetTypes = TableTypeWidget;
