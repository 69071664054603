import React, { ReactElement } from 'react';
import { alpha, Box, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, useTheme } from '@mui/material';
import { dotStyle, emptyArray, emptyObject, noop } from '../utils/Utils';
import { flex } from './AvThemeProvider';

interface RadioButtonProps {
  label?: string;
  value?: string | number | boolean;
  options?: { title: string | ReactElement; value: string | number }[];
  onChange?: (arg0: string) => void;
  sx?: any;
  isHorizontal?: boolean;
  disabled?: boolean;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  label = '',
  value = '',
  onChange = noop,
  options = emptyArray,
  sx = emptyObject,
  isHorizontal = true,
  disabled = false,
}) => {
  const { palette } = useTheme();
  return (
    <FormControl component="fieldset" sx={{ mb: '6px', ...sx }} disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <RadioGroup
        row
        aria-label={label}
        onChange={({ target }) => onChange(target.value)}
        value={value}
        sx={{ ...(isHorizontal ? flex.itemsCenter : flex.colItemsStart), gap: isHorizontal ? 1 : 2 }}>
        {options.map(({ title, value: val }) => (
          <FormControlLabel
            key={val}
            value={val}
            label={title}
            sx={{ marginLeft: 0, marginRight: '24px', gap: 1 }}
            control={
              <Radio
                sx={{
                  p: 0,
                  alignSelf: 'flex-start',
                  mt: '0.2em',
                  ':before': {
                    content: '""',
                    position: 'absolute',
                    ...dotStyle(undefined, 32),
                    transition: theme =>
                      theme.transitions.create(['background-color'], {
                        duration: theme.transitions.duration.shorter,
                      }),
                  },
                  'input + div': {
                    ...dotStyle(undefined, 16),
                    transition: theme =>
                      theme.transitions.create(['border-color'], {
                        duration: theme.transitions.duration.shorter,
                      }),
                  },
                  ':hover': {
                    backgroundColor: 'transparent',
                    '&:before': { backgroundColor: alpha(palette.colors.primary[300], 0.15) },
                    'input + div': { borderColor: palette.colors.neutrals[800] },
                    '&.Mui-checked input + div': { borderColor: palette.colors.primary[650] },
                  },
                }}
                checkedIcon={
                  <Box sx={{ border: '4px solid', backgroundColor: palette.colors.neutrals[200], borderColor: palette.primary.main }} />
                }
                icon={<Box sx={{ border: '1px solid', borderColor: palette.colors.neutrals[500] }} />}
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
