import React from 'react';
import { createFileRoute, Navigate } from '@tanstack/react-router';
import { APP_PATHS, PAGE_PATHS } from '../../../types';
import { useSearch } from '../../../utils/AvRouter';
import { entityViewConfig } from '../../../utils/entityViewConfig';

export const Route = createFileRoute('/$accountId/tickets/')({
  component: RouteComponent,
});

export function RouteComponent() {
  const { ticketCategory } = useSearch();
  const ticketApp = ticketCategory && ticketCategory !== entityViewConfig.Ticket.app ? APP_PATHS.DETECTIONS : APP_PATHS.VULNERABILITIES;
  const page = ticketApp.toLowerCase() === entityViewConfig.Ticket.app.toLowerCase() ? PAGE_PATHS.TICKETS : PAGE_PATHS.INCIDENTS;
  const pathname = window.location.pathname.split('/')?.toSpliced(2, 1, ticketApp, page).join('/');
  // @ts-ignore
  return <Navigate from="/" to={pathname} search />;
}
