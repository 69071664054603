import React, { useEffect, useMemo } from 'react';
// import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import {
  createFileRoute,
  createLazyFileRoute,
  createRootRoute,
  NavigateOptions,
  Link as RouterLink,
  useLocation as TanstackLocation,
  useNavigate as TanstackNavigate,
  useParams as useParamsTanstack,
  useRouter,
  useRouterState,
  useSearch as useSearchTanstack,
} from '@tanstack/react-router';
import envVariables from '../shared/projectEnvVariables';
import { CustomDashboardDto } from '../views/CustomDashboards/types';

const useLocation = (options = {}) => {
  const location = TanstackLocation({ ...options, structuralSharing: true });
  return useMemo(() => {
    if (location.state) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { __hashScrollIntoViewOptions, key, __TSR_index, ...rest } = location.state;
      return { ...location, state: rest };
    }
    return location;
  }, [location]);
};

const useSearchParams = (defaultParams?): [Record<string, unknown>, any] => {
  const defaultSearchParamsRef = React.useRef(defaultParams);
  const hasSetSearchParamsRef = React.useRef(false);
  const location = useLocation({ select: location => ({ search: location.search, searchStr: location.searchStr }) });
  const search = React.useMemo(
    () => getSearchParamsForLocation(location.search, hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current),
    [location?.searchStr]
  );
  const navigate = useNavigate();
  const setSearch = React.useCallback(
    (nextInit, options: NavigateOptions) => {
      const newSearchParams = typeof nextInit === 'function' ? nextInit(search) : nextInit;
      navigate({ search: newSearchParams, ...options });
    },
    [search]
  );

  useEffect(() => {
    hasSetSearchParamsRef.current = true;
    return () => {
      hasSetSearchParamsRef.current = false;
    };
  }, []);

  return [search, setSearch];
};
const useParams = (options = {}) => useParamsTanstack({ strict: false, ...options });

const getSearchParamsForLocation = (locationSearch: Record<string, unknown>, defaultSearchParams: Record<string, unknown> | null) => {
  const searchParams = locationSearch;
  if (defaultSearchParams && typeof defaultSearchParams === 'object') {
    // Use `defaultSearchParams.forEach(...)` here instead of iterating of
    // `defaultSearchParams.keys()` to work-around a bug in Firefox related to
    // web extensions. Relevant Bugzilla tickets:
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1414602
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1023984
    // @ts-ignore
    Object.entries(defaultSearchParams).forEach(entry => {
      const key = entry[0];
      // @ts-ignore
      if (!(key in searchParams)) {
        // @ts-ignore
        searchParams[key] = defaultSearchParams[key];
      }
    });
  }

  return searchParams;
};

// interface MUILinkProps extends Omit<MuiLinkProps, 'href'> {
//   to: string;
//   params?: Record<string, unknown>;
// }
//
// const MUILinkComponent = React.forwardRef<HTMLAnchorElement, MUILinkProps>((props, ref) => {
//   const { to, ...rest } = props;
//   return (
//     <MuiLink
//       component={RouterLink}
//       to={to}
//       {...rest}
//       sx={{ ...(rest.sx ? rest.sx : {}), textDecoration: 'none', color: 'inherit' }}
//       ref={ref}
//     />
//   );
// });

const useNavigate = () => {
  const navigate = TanstackNavigate();
  // eslint-disable-next-line custom-rules/no-let
  let nav;
  return opt => {
    if (typeof opt === 'string') {
      try {
        // @ts-ignore
        nav = navigate({ from: window.location.pathname, to: opt });
      } catch {
        nav = navigate({ to: opt });
      }
      return nav;
    }
    if (opt.replace) {
      return navigate(opt);
    }
    try {
      nav = navigate({ from: window.location.pathname, ...opt });
    } catch {
      nav = navigate(opt);
    }
    return nav;
  };
};

const useSearch = () => useSearchTanstack({ strict: false });

const useIsIdComponent = () => window.location.pathname.split('/').at(-1) === '$id';

declare module '@tanstack/react-router' {
  interface HistoryState extends CustomDashboardDto {
    [x: string]: any;
  }
}
declare module '@tanstack/react-router' {
  interface StaticDataRouteOption {
    path?: string;
    key?: string;
    groupingRuleType?: string;
  }
}

const Link = RouterLink as any;
// const MuiLinkk: any | LinkComponent<typeof MUILinkComponent> = createLink(MUILinkComponent);

const shouldShowDevTools = localStorage.getItem('avalor-devtools') === 'true';

const TanStackRouterDevtools =
  envVariables.MODE === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      );

const useShouldRenderGuard = () => {
  const router = useRouter();
  const mountedPath = useMemo(() => window.location.pathname, []);
  return mountedPath === window.location.pathname && !router.state.isLoading;
};

export {
  createFileRoute,
  createLazyFileRoute,
  createRootRoute,
  useNavigate,
  useLocation,
  useSearchParams,
  Link,
  useParams,
  useSearch,
  useIsIdComponent,
  useRouterState,
  TanStackRouterDevtools,
  shouldShowDevTools,
  useShouldRenderGuard,
};
