import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem, Link as muiLink } from '@mui/material';
import { Link } from '../../utils/AvRouter';
import { cellContentStyle, ellipsis, iconSize } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import Select from '../Select';
import TextInput from '../TextInput';
import DeleteButtonWithDialog from './DeleteButtonWithDialog';
import { DeleteProps, FormatterType, GroupHeaderRowType, HeadCellType } from './types';
import { ReactComponent as ArrowDown } from '../../assets/Arrow Down.svg';
import { ReactComponent as Delete } from '../../assets/Delete.svg';
import { ReactComponent as Edit } from '../../assets/Edit.svg';

const arrowIcon = <ArrowDown />;
const deleteIcon = size => <Delete style={iconSize(size)} />;
const editIcon = size => <Edit style={iconSize(size)} />;
export const enum AvalorTableIds {
  InlineActionsId = 'avalor__inlineActions',
  FreeSpaceId = 'avalor_free_space',
  ExpandCaretId = 'avalor_expand_caret',
}

export const loadingPercentages = ['70%', '50%', '80%'];
export const autoWidthStyle = { whiteSpace: 'nowrap', width: '100%' };
export const autoWithMaxWidthStyle = { ...autoWidthStyle, maxWidth: 350, ...ellipsis };
export const resizeResponsiveProps = { maxWidth: 0 };

export const isAuto = (columnWidths, id, initWidth?) => !initWidth && (columnWidths[id] === 'auto' || !columnWidths[id]);
export const isAutoFit = (columnWidths, id) => columnWidths[id] === 'auto_fit' || !columnWidths[id];

export const isNumberType = (type: string | undefined) => type && ['number', 'percentage'].includes(type);

export const cellBoxStyle = (id, resizable, columnWidths, type, size, freeSpace, columnsToAddFreeSpace): any => [
  id === AvalorTableIds.InlineActionsId
    ? { pt: '12px' }
    : !resizable && size === 'xSmall'
      ? {}
      : resizable && isAuto(columnWidths, id)
        ? freeSpace > 100
          ? {
              ...autoWithMaxWidthStyle,
              maxWidth: columnsToAddFreeSpace?.includes(id) ? 300 + (freeSpace - 100) / columnsToAddFreeSpace.length : 300,
            }
          : autoWithMaxWidthStyle
        : resizable && isAutoFit(columnWidths, id)
          ? autoWidthStyle
          : { ...cellContentStyle, WebkitLineClamp: 1 },
  { paddingRight: isNumberType(type) ? 1 : 0 },
];

export const descendingComparator = (a, b, orderByFunc) => {
  const origValA = orderByFunc(a);
  const origValB = orderByFunc(b);
  const valA = (typeof origValA === 'string' ? origValA.toLowerCase() : origValA) ?? '';
  const valB = (typeof origValB === 'string' ? origValB.toLowerCase() : origValB) ?? '';
  return valB < valA ? -1 : valB > valA ? 1 : 0;
};

export const getComparator = (order, orderByFunc) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderByFunc) : (a, b) => -descendingComparator(a, b, orderByFunc);

export const ToolbarActionButton = (label, onClick, icon?, disabled?: boolean, tooltip = '') => {
  const button = (
    <Button variant="filter" className="transparent" onClick={onClick} disabled={disabled}>
      {icon}
      {label}
    </Button>
  );
  return tooltip ? (
    <AvTooltip title={tooltip}>
      <span>{button}</span>
    </AvTooltip>
  ) : (
    button
  );
};

export const getGroupComparator = (rowGroupByField, order) =>
  rowGroupByField &&
  ((a, b) =>
    getComparator(order, rowGroupByField)(a, b) ||
    (a.groupHeaderType === GroupHeaderRowType.GROUP ? -1 : b.groupHeaderType === GroupHeaderRowType.GROUP ? 1 : 0));

/**
 * @deprecated use ActionButtonV2
 */
export const ActionButton = (label, onClick, icon?, link?, shouldOpenTab = false, disabled = false) => (
  <AvTooltip title={label} muiProps={{ placement: 'top' }}>
    <Button {...buttonProps(shouldOpenTab, onClick, link)} sx={{ ...actionButtonStyle }} disabled={disabled}>
      {icon}
    </Button>
  </AvTooltip>
);

export const ActionButtonV2 = ({
  label,
  onClick,
  icon,
  link,
  shouldOpenTab = false,
  disabled = false,
}: {
  label: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  link?: string;
  shouldOpenTab?: boolean;
  disabled?: boolean;
}) => (
  <AvTooltip title={label} muiProps={{ placement: 'top' }}>
    <Box sx={{ ...flex.row }}>
      <Button
        disabled={disabled}
        {...buttonProps(shouldOpenTab, onClick, link)}
        sx={
          disabled
            ? {
                svg: {
                  width: 24,
                  height: 24,
                  color: theme => theme.palette.colors.neutrals[400],
                },
              }
            : { ...actionButtonStyle }
        }>
        {icon}
      </Button>
    </Box>
  </AvTooltip>
);

const buttonProps = (shouldOpenTab, onClick, link) => {
  if (!link) {
    return { onClick };
  }
  if (typeof link === 'string') {
    return shouldOpenTab
      ? { component: muiLink, href: link, target: '_blank' }
      : {
          component: Link,
          to: link,
        };
  }
  const { to, search, state } = link;
  return shouldOpenTab
    ? { component: muiLink, href: to, search, state, target: '_blank' }
    : {
        component: Link,
        to,
        search,
        state,
      };
};

export const ActionMenuButton = ({
  label,
  icon,
  options,
}: {
  label: string;
  icon?: React.ReactNode;
  options: { title: string; onClick: () => void }[];
}) => {
  const [closeTimeout, setCloseTimeout] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<Element>();
  const clearCloseTimeout = () => clearTimeout(closeTimeout);
  const handleClose = () => {
    clearCloseTimeout();
    setCloseTimeout(setTimeout(() => setAnchorEl(undefined), 200));
  };
  const shouldShowMenuOptions = options.length > 1;
  const toggleMenu = ({ currentTarget }) => setAnchorEl(anchorEl ? null : currentTarget);
  const handleClick = ({ currentTarget }) => (shouldShowMenuOptions ? toggleMenu({ currentTarget }) : options[0].onClick());

  return (
    <Box sx={{ ...flex.row }} onMouseOver={clearCloseTimeout} onMouseLeave={handleClose}>
      <Button onClick={handleClick} className={anchorEl ? 'Mui-focusVisible' : undefined}>
        <AvTooltip title={label} muiProps={{ placement: 'top' }}>
          <span>{icon}</span>
        </AvTooltip>
      </Button>
      {shouldShowMenuOptions && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          keepMounted
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={popoverStyle}>
          {options.map(option => (
            <MenuItem
              key={option.title}
              onClick={() => {
                option.onClick?.();
                handleClose();
              }}>
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};

const popoverStyle = {
  pointerEvents: 'none',
  p: '2px',
  '.MuiMenu-paper': {
    pointerEvents: 'auto',
    minWidth: 250,
  },
  '.MuiMenuItem-root': {
    paddingLeft: 2,
    paddingRight: 2,
    minHeight: 40,
  },
};

const actionButtonStyle = {
  svg: {
    width: 24,
    height: 24,
    color: theme => theme.palette.colors.neutrals[600],
  },
  '&:hover svg': {
    color: theme => theme.palette.primary.main,
  },
};

export const expandArrowColumn = (shouldExpand): HeadCellType => ({
  id: AvalorTableIds.ExpandCaretId,
  label: '',
  initWidth: 40,
  style: {
    lineHeight: '10px',
    padding: 0,
    width: 40,
    '&.MuiTableCell-root': {
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'center',
      svg: {
        width: 16,
        height: 16,
        color: theme => theme.palette.colors.neutrals[600],
      },
    },
    '.MuiTableRow-root:not(.expanded-row) & svg': { transform: 'rotate(-90deg)' },
  },
  formatter: (v, row) => (shouldExpand(row) ? arrowIcon : null),
  type: FormatterType.string,
});

export const getInput = (row, id, onChange, options, formattedContent) => {
  const props = {
    value: formattedContent,
    onChange: onChange(id, row),
    isRequired: true,
    size: 'small' as const,
  };
  return options ? <Select {...props} options={options} /> : <TextInput {...props} autoFocus={row.avalor_SetFocus === id} />;
};

interface PaginationRowsTextProps {
  from?: number;
  to?: number;
  count?: number;
}
export const PaginationRowsText: React.FC<PaginationRowsTextProps> = ({ from, to, count }) => (
  <Box component="span" sx={{ fontSize: 12, color: theme => theme.palette.colors.neutrals[700] }}>
    Showing {from?.toLocaleString()}-{to?.toLocaleString()} of {count?.toLocaleString()}
  </Box>
);

export const totalCellStyle = ({ palette }) => ({
  paddingRight: 2,
  borderTop: `1px solid ${palette.colors.neutrals[300]}`,
  borderBottom: `1px solid ${palette.colors.neutrals[400]}`,
  fontWeight: 600,
});

export const getStickyGroupRowStyle = containerRef => ({
  position: 'sticky',
  top: containerRef.current?.querySelector('th').offsetHeight,
  backgroundColor: ({ palette }) => palette.white.main,
  zIndex: 1,
});

export const tableHeaderWithIcon = (label, icon) => (
  <Box sx={{ ...flex.itemsCenter, gap: 1, svg: { color: theme => theme.palette.colors.neutrals[500] } }}>
    {icon}
    {label}
  </Box>
);

const inlineActionsStyle = {
  position: 'sticky',
  right: 0,
  '> div > div': {
    position: 'absolute',
    padding: '0 24px 0 32px',
    top: 0,
    right: 0,
    height: '100%',
    ...flex.justifyCenter,
    paddingTop: 'inherit',
    paddingBottom: 'inherit',
    backgroundImage: theme => `linear-gradient(to right, transparent, ${theme.palette.colors.neutrals[200]} 20%)`,
    opacity: 0,
    transition: theme =>
      theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.short,
      }),
    '.MuiTableRow-hover:hover &:not(:empty), &:has(.Mui-focusVisible)': {
      opacity: 1,
    },
    '.MuiTableRow-hover.Mui-selected &': {
      backgroundImage: theme => `linear-gradient(to right, transparent, ${theme.palette.colors.primary[150]} 20%)`,
    },
    '.MuiButton-root': {
      minWidth: '40px',
      ...flex.itemsStart,
    },
  },
};

export const inlineActionsHeader = ({
  allowEdit,
  keyName,
  inlineActions,
  deleteProps,
  onEditNavigation = id => `edit/${id}`,
  handleSelected,
  rows,
}: {
  allowEdit: boolean;
  keyName: string;
  inlineActions: any[];
  deleteProps: DeleteProps | undefined;
  onEditNavigation: ((id: any) => string | { to: string; search?: any; state?: any }) | undefined;
  handleSelected: (newVal: any) => void;
  rows: any[];
}): HeadCellType => ({
  id: AvalorTableIds.InlineActionsId,
  disableSortBy: true,
  style: inlineActionsStyle,
  formatter: (_, row, setRows) => (
    <Box className="inline-actions-cell" onClick={e => e.stopPropagation()}>
      {row && (
        <>
          {allowEdit && ActionButton('Edit', null, editIcon(24), onEditNavigation(row[keyName]))}
          {deleteProps && (
            <DeleteButtonWithDialog
              button={onClick => ActionButton('Delete', onClick, deleteIcon(24))}
              selected={[row[keyName]]}
              handleSelected={handleSelected}
              deleteProps={{
                ...deleteProps,
                message: deleteProps.customMessage?.([row]) || deleteProps.getName?.(row),
              }}
            />
          )}
        </>
      )}
      {inlineActions.map(action => (
        <React.Fragment key={action.name}>{action(row, setRows, rows, handleSelected)}</React.Fragment>
      ))}
    </Box>
  ),
});
