import { useCallback, useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { QueriesResults, QueryObserverOptions, useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags } from '../types';
import { Filter } from '../types/filter.types';
import { ErrorTypes } from '../types/query.types';
import { cleanEmptyFilters } from '../utils/filterUtils';
import { AliasResolution, DwQueryRequest } from '../views/CustomDashboards/types/QueryObject.types';
import { FileFormat } from '../views/Reports/types';
import useDebounce from './useDebounce';
import useHandleError from './useHandleError';
import { CANCEL_ASYNC_QUERY } from './useQuerySql';

const QUERY = gql`
  query queryObjectAsync($req: Json!) {
    queryObjectAsync(value: $req) {
      queryId
    }
  }
`;
const POLLING = gql`
  query asyncPollDwQuery($req: Json!) {
    asyncPollDwQuery(value: $req) {
      rows {
        data
        id
      }
      totalRowCount
      totals {
        data
        id
      }
      asyncResult
    }
  }
`;

export type QueryObjectProps = {
  queryObject: DwQueryRequest | DwQueryRequest[];
  origin?: string;
  showTotals?: boolean;
  totalRowCount?: boolean;
  onError?: (error) => void;
  options?: Partial<QueryObserverOptions>;
  muteErrors?: boolean;
  contextFilter?: Filter;
  debounceQueries?: boolean;
  searchQuery?: string;
  fileFormat?: string;
  aliasResolution?: AliasResolution;
};

export type UseQueryObjectProps = QueryObjectProps & { onSuccess?: (d: any[], index: number) => any[] };

export const useGetQueryObjectAsyncId = ({
  queryObject,
  origin = 'INTERACTIVE_QUERY',
  onError = undefined,
  showTotals = false,
  totalRowCount = false,
  onSuccess = d => d,
  options = {},
  muteErrors = undefined,
  contextFilter,
  debounceQueries = false,
  searchQuery,
  fileFormat = FileFormat.JSON_FORMAT,
  aliasResolution = [],
}: QueryObjectProps & { onSuccess?: (d: string) => string }): any => {
  const { api } = useAvContext();
  const { debouncedValue, isDebouncing } = useDebounce(
    {
      queriesObject: Array.isArray(queryObject) ? queryObject : [queryObject],
      options,
      showTotals,
      totalRowCount,
      contextFilter,
    },
    debounceQueries ? 3000 : 0
  );

  const cleanedContextFilter = useMemo(() => cleanEmptyFilters(debouncedValue.contextFilter), [debouncedValue.contextFilter]);
  const queries = debouncedValue.queriesObject.map((query, index) => ({
    queryKey: JSON.stringify(debouncedValue.queriesObject[index]) + JSON.stringify(cleanedContextFilter),
    queryFn: ({ signal }: { signal?: AbortSignal }) =>
      api(QUERY, {
        options: {
          req: {
            query,
            totals: { totalRow: debouncedValue.showTotals, totalRowCount: debouncedValue.totalRowCount },
            ...(origin && { origin }),
            ...(fileFormat && { fileFormat }),
            contextFilter: cleanedContextFilter,
            aliasResolution,
            searchQuery,
          },
        },
        onError,
        signal,
        muteErrors,
      })
        .then(data => {
          const flattenedData = data?.data?.queryObjectAsync?.queryId;
          if (data === undefined || data.errors) {
            throw data?.errors || new Error(`${ErrorTypes.Network}: ${debouncedValue.queriesObject[index]}`);
          }
          return onSuccess(flattenedData);
        })
        .catch(e => {
          if (!signal?.aborted) {
            if (muteErrors) {
              onError?.(e);
            } else {
              throw e;
            }
          }
        }),
    ...debouncedValue.options,
    enabled:
      (debouncedValue.options.enabled || debouncedValue.options.enabled === undefined) &&
      (!!query?.select?.dims?.length || !!query?.select?.metrics?.length),
  }));
  // @ts-ignore
  const results: {
    data: any;
    refetch: () => Promise<any>;
    isLoading: boolean;
    isRefetching: boolean;
    isPreviousData: boolean;
    error?: any[];
  }[] = useQueries({ queries }) as QueriesResults<any>;
  const refetch = useCallback(() => Promise.all(results.map(r => r.refetch())), [results]);
  return useMemo(
    () => ({
      data: !results.length ? undefined : results[0].data,
      isLoading: results.some(result => result.isLoading),
      errors: results.map(result => result.error).filter(e => !!e) || [],
      refetch,
      isRefetching: results.some(result => result.isRefetching),
      isPreviousData: results.some(result => result.isPreviousData),
      isDebouncing,
    }),
    [results, isDebouncing]
  );
};

export const useAsyncPollDwQuery = ({
  queriesId,
  onSuccess = d => d,
  onError = undefined,
  options = {},
  muteErrors = undefined,
  isLoading,
  isRefetching,
}: {
  queriesId: string[];
  onSuccess?: (d: any[], index: number, asyncResult: string) => any[];
  onError?: (error) => void;
  options?: Partial<QueryObserverOptions>;
  muteErrors?: boolean;
  isLoading?: boolean;
  isRefetching?: boolean;
}) => {
  const { api } = useAvContext();
  const queryClient = useQueryClient();
  const { mutate: cancel } = useMutation({
    mutationFn: (queryId: string) => api(CANCEL_ASYNC_QUERY, { options: { queryId } }),
    onSettled: (data, error, variables) =>
      queryClient.resetQueries({
        predicate: ({ state: { data } }) => Boolean(data && typeof data === 'object' && 'queryId' in data && data.queryId === variables),
      }),
  });
  const queries = queriesId.map((queryId, index) => ({
    queryKey: queriesId,
    queryFn: ({ signal }: { signal?: AbortSignal }) =>
      api(POLLING, {
        options: {
          req: {
            queryId,
          },
        },
        onError,
        signal,
        muteErrors,
      })
        .then(data => {
          const flattenedData = data?.data?.asyncPollDwQuery?.rows?.map(row => ({ ...row.data, id: row.id }));
          const asyncResult = data?.data?.asyncPollDwQuery?.asyncResult;
          if (data === undefined || data.errors) {
            throw data?.errors || new Error(`${ErrorTypes.Network}: ${queriesId[index]}`);
          }
          return {
            data: onSuccess(flattenedData, index, asyncResult),
            asyncResult,
            totals: data?.data?.asyncPollDwQuery?.totals?.data,
            totalRowCount: data?.data?.asyncPollDwQuery?.totalRowCount,
          };
        })
        .catch(e => {
          if (!signal?.aborted) {
            if (muteErrors) {
              onError?.(e);
            } else {
              throw e;
            }
          } else {
            cancel(queryId);
          }
        }),
    ...options,
    enabled: (options.enabled || options.enabled === undefined) && !!queryId,
  }));

  // @ts-ignore
  const results: {
    data: { data?: any; asyncResult?: string; totals?: Record<string, number>; totalRowCount?: number };
    refetch: () => Promise<any>;
    isLoading: boolean;
    isRefetching: boolean;
    isPreviousData: boolean;
    error?: any[];
  }[] = useQueries({ queries });
  const refetch = useCallback(() => Promise.all(results.map(r => r.refetch())), [results]);
  return useMemo(
    () => ({
      asyncResult: !results.length
        ? undefined
        : results.length === 1
          ? results[0].data?.asyncResult
          : results.map(({ data: { asyncResult } }) => asyncResult),
      totalRowCount: !results.length ? undefined : results[0].data?.totalRowCount,
      totals: !results.length ? undefined : results.length === 1 ? results[0].data?.totals : results.map(({ data: { totals } }) => totals),
      data:
        !results.length || (queries.length > 1 && results.some(({ data: { data } }) => !data?.length))
          ? undefined
          : results.length === 1
            ? results[0].data?.data
            : results.map(({ data }) => data?.data),
      isLoading:
        results.some(result => result.isLoading) || isLoading || !!results.find(({ data }) => data?.asyncResult === 'ASYNC_RUNNING'),
      errors: results.map(result => result.error).filter(e => !!e) || [],
      refetch,
      isRefetching:
        results.some(result => result.isRefetching) || isRefetching || !!results.find(({ data }) => data?.asyncResult === 'ASYNC_RUNNING'),
      isPreviousData: results.some(result => result.isPreviousData),
    }),
    [results]
  );
};

export const useQueryObjectAsync = ({
  queryObject,
  origin = 'INTERACTIVE_QUERY',
  onSuccess = d => d,
  onError = undefined,
  showTotals = false,
  totalRowCount = false,
  options = {},
  muteErrors = undefined,
  contextFilter,
  debounceQueries = false,
  searchQuery,
  fileFormat = 'JSON_FORMAT',
  aliasResolution,
}: UseQueryObjectProps) => {
  const { featureFlags } = useAvContext();
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);
  const [isPolling, setIsPolling] = useState(true);
  const [pollingIds, setPollingIds] = useState<string[]>([]);

  const {
    data: newPollingId,
    isLoading,
    isRefetching,
    isDebouncing,
  } = useGetQueryObjectAsyncId({
    queryObject,
    origin,
    showTotals,
    totalRowCount,
    onError,
    options,
    muteErrors,
    contextFilter,
    debounceQueries,
    searchQuery,
    fileFormat,
    aliasResolution,
    onSuccess: d => {
      setPollingIds([...pollingIds, d]);
      setIsPolling(true);
      return d;
    },
  });

  useEffect(() => {
    if (pollingIds) {
      setIsPolling(true);
    }
  }, [pollingIds]);
  const result = useAsyncPollDwQuery({
    queriesId: [newPollingId],
    isLoading,
    isRefetching,
    onSuccess: (d, i, asyncResult) => {
      setIsPolling(!(asyncResult === 'ASYNC_DONE'));
      return onSuccess(d, i);
    },
    onError: e => {
      setIsPolling(false);
      if (onError) {
        onError(e);
      } else {
        handleError(e);
      }
    },
    options: { refetchInterval: 3000, refetchIntervalInBackground: true, enabled: !!pollingIds.length && isPolling },
  });

  return { ...result, isDebouncing: Boolean(isDebouncing) };
};
