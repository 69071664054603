import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { useAvContext } from '../context/AvContextProvider';
import { AppDto } from '../context/types';
import { getAccountTokenFromLS } from '../utils/auth.utils';
import { QueryKey } from '../utils/queryKey';

export const GET_ALL_APPS = gql`
  query {
    findAllApps {
      id
      name
    }
  }
`;

export const useAllApps = () => {
  const { api, accountId } = useAvContext();

  return useQuery<AppDto[]>({
    queryKey: [QueryKey.FindAllApps],
    queryFn: () => api(GET_ALL_APPS).then(data => data?.data?.findAllApps),
    enabled: !!accountId && !!getAccountTokenFromLS(accountId),
  });
};
