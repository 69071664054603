import {
  APP_PATHS,
  FeatureFlags,
  PAGE_PATHS,
  Permission,
  PermissionAppName,
  PermissionCategoriesNames,
  PermissionEntitiesNames,
  PermissionForRoute,
  PermissionModelEntityName,
} from '../types';
import { entityViewConfig } from './entityViewConfig';

export const appToPermissionCategory: Partial<Record<keyof typeof APP_PATHS, PermissionCategoriesNames>> = {
  [APP_PATHS.DETECTIONS]: PermissionAppName.DETECTIONS,
  [APP_PATHS.INCIDENTS]: PermissionAppName.INCIDENTS,
  [APP_PATHS.VULNERABILITIES]: PermissionAppName.VULNERABILITIES,
  [APP_PATHS.CAASM]: PermissionAppName.CAASM,
  [APP_PATHS.RISK360]: PermissionAppName.RISK360,
};

export const getPermissionNameFromValue = ({
  entityName,
  categoryName,
  permission,
}: {
  entityName: PermissionEntitiesNames;
  categoryName?: PermissionCategoriesNames;
  permission: Permission;
}) => (categoryName ? `${categoryName}:${entityName}:${permission}` : `${entityName}:${permission}`);

const appTypeToDefaultEntity = {
  [PermissionAppName.VULNERABILITIES]: PermissionModelEntityName.TICKET,
  [PermissionAppName.INCIDENTS]: PermissionModelEntityName.INCIDENT,
  [PermissionAppName.DETECTIONS]: PermissionModelEntityName.INCIDENT,
  [PermissionAppName.CAASM]: PermissionModelEntityName.ASSET,
  [PermissionAppName.RISK360]: PermissionModelEntityName.FACTOR,
};

export const permissionMappingPerRoute = (app?: PermissionAppName): Partial<Record<keyof typeof PAGE_PATHS, PermissionForRoute>> => ({
  [PAGE_PATHS.RULES]: {
    category: PermissionAppName.DETECTIONS,
    permissionEntity: PermissionEntitiesNames.RULE,
  },
  [PAGE_PATHS.CONTENT_PERMISSIONS]: {
    permissionEntity: PermissionEntitiesNames.USER,
  },
  [PAGE_PATHS.MODEL_MANAGEMENT]: {
    permissionEntity: PermissionEntitiesNames.MODEL,
  },
  [PAGE_PATHS.DATA_PURGE]: {
    permissionEntity: PermissionEntitiesNames.MODEL,
  },
  [PAGE_PATHS.ASSET_AGING]: {
    permissionEntity: PermissionEntitiesNames.MODEL,
  },
  [PAGE_PATHS.MEASUREMENTS]: {
    permissionEntity: PermissionEntitiesNames.MODEL,
  },
  [PAGE_PATHS.DATA_UNIFICATION_FIELDS]: {
    permissionEntity: PermissionEntitiesNames.MODEL,
  },
  [PAGE_PATHS.DATA_UNIFICATION_ENTITIES]: {
    permissionEntity: PermissionEntitiesNames.MODEL,
  },
  [PAGE_PATHS.DATA_UNIFICATION]: {
    permissionEntity: PermissionEntitiesNames.MODEL,
  },
  [PAGE_PATHS.EDIT_MY_ACCOUNT_MANAGEMENT]: {
    permissionEntity: PermissionEntitiesNames.ACCOUNT,
  },
  [PAGE_PATHS.USER_MANAGEMENT]: {
    permissionEntity: PermissionEntitiesNames.USER,
  },
  [PAGE_PATHS.ACCOUNT_USAGE]: {
    permissionEntity: PermissionEntitiesNames.ACCOUNT,
  },
  [PAGE_PATHS.EDIT_MY_USER]: {
    permissionEntity: PermissionEntitiesNames.USER,
  },
  [PAGE_PATHS.GLOBAL_SCRIPTS]: {
    category: PermissionAppName.DETECTIONS,
    permissionEntity: PermissionEntitiesNames.GLOBAL_SCRIPT,
  },
  [PAGE_PATHS.TARGETS]: {
    permissionEntity: PermissionEntitiesNames.INTEGRATION,
  },
  [PAGE_PATHS.ALERTS]: {
    category: PermissionModelEntityName.ALERT,
    permissionEntity: PermissionEntitiesNames.OPT,
  },
  [PAGE_PATHS.TEST_QUERY]: {
    category: PermissionModelEntityName.ALERT,
    permissionEntity: PermissionEntitiesNames.OPT,
  },
  [PAGE_PATHS.RULES]: {
    category: PermissionAppName.DETECTIONS,
    permissionEntity: PermissionEntitiesNames.RULE,
  },
  [PAGE_PATHS.SOURCES]: {
    permissionEntity: PermissionEntitiesNames.SOURCE,
  },
  [PAGE_PATHS.SOURCES_MAPPING]: {
    permissionEntity: PermissionEntitiesNames.SOURCE_MAPPING,
  },
  [PAGE_PATHS.INCIDENTS]: {
    category: PermissionModelEntityName.INCIDENT,
    permissionEntity: PermissionEntitiesNames.OPT,
  },
  [PAGE_PATHS.TICKETS]: {
    category: PermissionModelEntityName.TICKET,
    permissionEntity: PermissionEntitiesNames.OPT,
  },
  [PAGE_PATHS.EXCEPTIONS]: {
    category: PermissionAppName.VULNERABILITIES,
    permissionEntity: PermissionEntitiesNames.EXCEPTION,
  },
  [PAGE_PATHS.EXCEPTIONS_SETTINGS]: {
    category: PermissionModelEntityName.EXCEPTION,
    permissionEntity: PermissionEntitiesNames.SETTINGS,
  },
  [PAGE_PATHS.FINDINGS]: {
    category: PermissionModelEntityName.FINDING,
    permissionEntity: PermissionEntitiesNames.OPT,
  },
  [PAGE_PATHS.ASSETS]: {
    category: PermissionModelEntityName.ASSET,
    permissionEntity: PermissionEntitiesNames.OPT,
  },
  [PAGE_PATHS.FACTOR]: {
    category: PermissionModelEntityName.FACTOR,
    permissionEntity: PermissionEntitiesNames.OPT,
  },
  [PAGE_PATHS.WORKFLOWS]: {
    permissionEntity: PermissionEntitiesNames.WORKFLOW_SCRIPT,
  },
  [PAGE_PATHS.RULE_SET]: {
    category: app === PermissionAppName.VULNERABILITIES ? PermissionModelEntityName.FINDING : PermissionModelEntityName.ALERT,
    permissionEntity: PermissionEntitiesNames.SETTINGS,
  },
  [PAGE_PATHS.SEVERITY_SLA]: {
    category: PermissionModelEntityName.TICKET,
    permissionEntity: PermissionEntitiesNames.SETTINGS,
  },
  [PAGE_PATHS.TICKETS_STATUSES]: {
    category: app === PermissionAppName.VULNERABILITIES ? PermissionModelEntityName.TICKET : PermissionModelEntityName.INCIDENT,
    permissionEntity: PermissionEntitiesNames.SETTINGS,
  },
  [PAGE_PATHS.SCORE]: {
    category: PermissionModelEntityName.TICKET,
    permissionEntity: PermissionEntitiesNames.SETTINGS,
  },
  [PAGE_PATHS.UI_CONFIG]: {
    category: appTypeToDefaultEntity[app!], // TODO: change this function to get the entity type as input, since some of the permissions are based on entities
    permissionEntity: PermissionEntitiesNames.SETTINGS,
  },
  [PAGE_PATHS.SETTINGS]: {
    category: appTypeToDefaultEntity[app!],
    permissionEntity: PermissionEntitiesNames.SETTINGS,
  },
  [PAGE_PATHS.LOGS]: {
    permissionEntity: PermissionEntitiesNames.SEARCH,
  },
  [PAGE_PATHS.ENTITIES]: {
    permissionEntity: PermissionEntitiesNames.SEARCH,
  },
  [PAGE_PATHS.LOOKUP_TABLES]: {
    category: PermissionAppName.DETECTIONS,
    permissionEntity: PermissionEntitiesNames.LOOKUP_TABLE,
  },
  [PAGE_PATHS.REMEDIATION]: {
    category: PermissionAppName.VULNERABILITIES,
    permissionEntity: PermissionEntitiesNames.ANALYTICS,
  },
  [PAGE_PATHS.RISK]: {
    category: PermissionAppName.VULNERABILITIES,
    permissionEntity: PermissionEntitiesNames.ANALYTICS,
  },
  [PAGE_PATHS.ASSET_COVERAGE]: {
    category: PermissionModelEntityName.ASSET,
    permissionEntity: PermissionEntitiesNames.OPT,
  },
  [PAGE_PATHS.PIVOT]: {
    category:
      app === PermissionAppName.VULNERABILITIES
        ? PermissionAppName.VULNERABILITIES
        : app === PermissionAppName.DETECTIONS
          ? PermissionAppName.DETECTIONS
          : PermissionAppName.INCIDENTS,
    permissionEntity: PermissionEntitiesNames.ANALYTICS,
  },
  [PAGE_PATHS.ROI]: {
    category: PermissionAppName.VULNERABILITIES,
    permissionEntity: PermissionEntitiesNames.ANALYTICS,
  },
  [PAGE_PATHS.OVERVIEW]: {
    // temporary until we have permissions for risk360
    category: app === PermissionAppName.VULNERABILITIES ? PermissionAppName.VULNERABILITIES : undefined,
    permissionEntity: app === PermissionAppName.VULNERABILITIES ? PermissionEntitiesNames.ANALYTICS : PermissionEntitiesNames.BACKOFFICE,
  },
  [PAGE_PATHS.REPORTS]: {
    permissionEntity: PermissionEntitiesNames.REPORT,
  },
  [PAGE_PATHS.CUSTOM_DASHBOARDS]: {
    permissionEntity: PermissionEntitiesNames.REPORT,
  },
  [PAGE_PATHS.ALIASES]: {
    permissionEntity: PermissionEntitiesNames.ALIAS,
    relevantFeatureFlag: FeatureFlags.ShowAliasPage,
  },
  [PAGE_PATHS.RELATIONS]: {
    permissionEntity: PermissionEntitiesNames.ALIAS,
  },
  [PAGE_PATHS.ROLES]: {
    permissionEntity: PermissionEntitiesNames.ROLE,
  },
  [PAGE_PATHS.FACTOR_RULES]: {
    category: PermissionAppName.RISK360,
    permissionEntity: PermissionEntitiesNames.EXE_RULE,
  },
  [PAGE_PATHS.POLICIES]: {
    category: PermissionAppName.CAASM,
    permissionEntity: PermissionEntitiesNames.EXE_RULE,
  },
  [PAGE_PATHS.POLICY_COMPLIANCE]: {
    category: PermissionAppName.CAASM,
    permissionEntity: PermissionEntitiesNames.ANALYTICS,
  },
  [PAGE_PATHS.ASSET_INVENTORY]: {
    category: PermissionAppName.CAASM,
    permissionEntity: PermissionEntitiesNames.ANALYTICS,
  },
  [PAGE_PATHS.TOOL_COVERAGE_AND_GAP]: {
    category: PermissionAppName.CAASM,
    permissionEntity: PermissionEntitiesNames.ANALYTICS,
  },
  [PAGE_PATHS.BACKOFFICE_ACTIONS]: {
    permissionEntity: PermissionEntitiesNames.BACKOFFICE,
  },
  [PAGE_PATHS.GLOBAL_FACTOR_RULES]: {
    category: PermissionAppName.RISK360,
    permissionEntity: PermissionEntitiesNames.BUILTIN_EXE_RULE,
  },
  [PAGE_PATHS.ICONS]: {
    permissionEntity: PermissionEntitiesNames.BACKOFFICE,
  },
  [PAGE_PATHS.ALL_ACCOUNTS]: {
    permissionEntity: PermissionEntitiesNames.ACCOUNT,
    permission: Permission.CREATE,
  },
  [PAGE_PATHS.ALL_USERS]: {
    permissionEntity: PermissionEntitiesNames.BACKOFFICE,
  },
  [PAGE_PATHS.QUERY]: {
    permissionEntity: PermissionEntitiesNames.QUERY_RUNNER,
  },
  [PAGE_PATHS.POLICY_VIOLATIONS]: {
    category: PermissionModelEntityName.POLICYVIOLATION,
    permissionEntity: PermissionEntitiesNames.OPT,
  },
  [PAGE_PATHS.AUTHENTICATION_SOURCES]: {
    permissionEntity: PermissionEntitiesNames.AUTHENTICATION,
  },
  [PAGE_PATHS.RISK360_BACKOFFICE_ACTIONS]: {
    category: PermissionAppName.RISK360,
    permissionEntity: PermissionEntitiesNames.BUILTIN_EXE_RULE,
  },
  [PAGE_PATHS.FORMATTING_RULES]: {
    permissionEntity: PermissionEntitiesNames.REPORT,
  },
});
export const fullPermissionsToResource = (value = true): { [key in Permission]: boolean } =>
  Object.keys(Permission).reduce((acc, key) => ({ ...acc, [key]: value }), {} as { [key in Permission]: boolean });

export const projectionNameToCategoryName = {
  [entityViewConfig.Ticket.projectionName]: PermissionModelEntityName.TICKET,
  [entityViewConfig.Finding.projectionName]: PermissionModelEntityName.FINDING,
  [entityViewConfig.Asset.projectionName]: PermissionModelEntityName.ASSET,
  [entityViewConfig.Incident.projectionName]: PermissionModelEntityName.INCIDENT,
  [entityViewConfig.Alert.projectionName]: PermissionModelEntityName.ALERT,
  [entityViewConfig.Factor.projectionName]: PermissionModelEntityName.FACTOR,
  [entityViewConfig.PolicyPopulation.projectionName]: PermissionModelEntityName.POLICYVIOLATION,
  [entityViewConfig.PolicyViolation.projectionName]: PermissionModelEntityName.POLICYVIOLATION,
  [entityViewConfig.Exception.projectionName]: PermissionModelEntityName.EXCEPTION,
  uber_global_vulnerabilities: 'GLOBALVULNERABILITY',
};
