import React, { createContext, useContext, useMemo, useState } from 'react';
import { shouldShowDevTools } from '@utils/AvRouter';

interface DebugContextProps {
  showDevTools: boolean | undefined;
  setShowDevTools: (v: boolean) => void;
}
const DebugContext = createContext<DebugContextProps>({
  showDevTools: undefined,
  setShowDevTools: () => {},
});

const AvDebugProvider = ({ children }) => {
  const [showDevTools, setShowDevTools] = useState(shouldShowDevTools);

  const value = useMemo(() => ({ showDevTools, setShowDevTools }), [showDevTools]);
  return <DebugContext.Provider value={value}>{children}</DebugContext.Provider>;
};

export const useAvDebugContext = () => useContext(DebugContext);
export default AvDebugProvider;
