import envVariables from '../shared/projectEnvVariables';

declare const window: {
  pendo?: pendo.Pendo;
} & Window;

export const getPendo = () => window.pendo;

export const initializePendo = ({ userId, name, email, role, roleId, roleName, accountId, accountName, accountType, accountCreated }) => {
  window.pendo?.initialize({
    visitor: {
      id: `${userId}_${envVariables.VITE_ENV}`,
      email,
      name,
      role,
      roleId,
      roleName,
    },

    account: {
      id: `${accountId}_${envVariables.VITE_ENV}`,
      name: accountName,
      type: accountType,
      creationDate: accountCreated,
    },
  });
};

export const initializePendoScript = () => {
  if (envVariables.VITE_PENDO_API_KEY) {
    /* eslint-disable */
    (function (p, e, n, d) {
      let v;
      let w;
      let x;
      let y;
      let z;
      let o = (p[d] = p[d] || {});
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w) {
        o[v[w]] = o[v[w]] || (() => o._q[v[w] === v[0] ? 'unshift' : 'push']([v[w]].concat([].slice.call(arguments, 0))));
      }
      y = e.createElement(n);
      y.async = !0;
      y.src = `https://cdn.pendo.io/agent/static/${envVariables.VITE_PENDO_API_KEY}/pendo.js`;
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
    /* eslint-enable */
  }
};
