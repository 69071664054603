import { Theme } from '@mui/material';

type Transition = string | ((theme: Theme) => string);

// eslint-disable-next-line import/prefer-default-export
export const mergeTransitions = (theme: Theme, ...transitions: Transition[]): string =>
  transitions
    .map(transition => (typeof transition === 'function' ? transition(theme) : transition))
    .filter(Boolean)
    .join(', ');
