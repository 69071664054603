import React from 'react';
import { flex } from '@components/AvThemeProvider';
import { IconVariant } from '@components/ItemWithLogo';
import { customTickClassnames } from '@components/Widgets/CustomTick';
import { Box, SxProps } from '@mui/material';
import { ellipsis } from '@utils/Utils';
import AvItemWithLogoGroup, { IconSize } from '../../../../components/AvItemWithLogoGroup';
import AvTooltip from '../../../../components/AvTooltip';

const SourceIconsFormatter = ({
  value,
  isXRotated,
  isYRotated,
  showText = true,
  isVertical,
  hasWidthLimit = true,
  itemMaxWidth,
  rotateText90deg,
  sx,
}: {
  value: string;
  isXRotated?: boolean;
  isYRotated?: boolean;
  showText?: boolean;
  isVertical?: boolean;
  hasWidthLimit?: boolean;
  itemMaxWidth?: number | string;
  rotateText90deg?: boolean;
  sx?: SxProps;
}) => {
  const sourcesArray = value.split(',');
  return (
    <Box
      sx={{
        pointerEvents: 'all',
        color: theme => theme.palette.colors.neutrals[500],
        fontSize: '12px',
        ...sx,
        ...(!showText && { mt: 0 }),
      }}>
      <Box sx={{ ...(isVertical ? flex.colCenter : flex.justifyStartCenter), gap: 1 }}>
        <AvItemWithLogoGroup
          variant={IconVariant.sourcesMapByName}
          items={sourcesArray}
          size={IconSize.collapsed}
          limit={1}
          inputVariantSpace="12px"
        />
        {showText && (
          <Box
            {...(hasWidthLimit && { width: itemMaxWidth || (isVertical ? 80 : 50) })}
            sx={{
              ...ellipsis,
              [`${customTickClassnames.xAxisVertical} &`]: rotateText90deg
                ? { transform: 'rotate(-90deg) translate(-20%, 0%)', transformOrigin: 'center center' }
                : undefined,
              [`${customTickClassnames.xAxisSlanted} &`]: isXRotated
                ? {
                    transform: 'rotate(-45deg) translate(-50%, -50%)',
                    transformOrigin: 'center center',
                  }
                : undefined,

              [`${customTickClassnames.yAxisSlanted} &`]: isYRotated
                ? {
                    transform: 'rotate(-45deg) translate(-50%, -50%)',
                    transformOrigin: 'center center',
                  }
                : undefined,
            }}>
            <AvTooltip>{value}</AvTooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SourceIconsFormatter;
