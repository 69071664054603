import { WidgetCategory } from './base.types';

export enum TabsType {
  Data = 'data',
  Filters = 'filters',
  Style = 'style',
  DrillDown = 'drilldown',
}

export enum AxisTickType {
  /**
   * Dynamic based on chart data (currently not implemented correctly)
   * @default
   */
  Dynamic = 'Dynamic',
  /**
   * 0deg
   */
  Horizontal = 'Horizontal',
  /**
   * -45deg
   */
  Vertical = 'Vertical',
  /**
   * -90deg
   */
  Slanted = 'Slanted',
}

export enum FieldType {
  Measurements = 'metrics',
  Dimensions = 'dims',
  All = 'all',
}

export type SizeLimit = {
  minW: number;
  maxW: number;
  minH: number;
  maxH: number;
};

export enum DashboardsTab {
  Custom = 'Custom',
  Templates = 'templates',
}

export enum LabelFormatterOption {
  SOURCE_ICONS = 'Icons',
  NONE = 'Text',
  SOURCE_ICONS_WITH_NAMES = 'Text + Icons',
}

export type ChartPosition = {
  margin?: { left?: number; bottom?: number };
};

export const labelFormatterOptionToDy: Record<
  LabelFormatterOption,
  Partial<Record<WidgetCategory, Partial<Record<AxisTickType, number>>>> | undefined
> = {
  [LabelFormatterOption.NONE]: { [WidgetCategory.Bar]: { [AxisTickType.Vertical]: 50, [AxisTickType.Slanted]: 40 } },
  [LabelFormatterOption.SOURCE_ICONS]: {
    [WidgetCategory.Bar]: {
      [AxisTickType.Slanted]: 35,
      [AxisTickType.Horizontal]: 35,
      [AxisTickType.Vertical]: 35,
    },
    [WidgetCategory.Line]: {
      [AxisTickType.Slanted]: 30,
      [AxisTickType.Horizontal]: 30,
    },
    [WidgetCategory.Pie]: {
      [AxisTickType.Horizontal]: 32,
    },
  },
  [LabelFormatterOption.SOURCE_ICONS_WITH_NAMES]: {
    [WidgetCategory.Bar]: {
      [AxisTickType.Slanted]: 60,
      [AxisTickType.Horizontal]: 40,
      [AxisTickType.Vertical]: 65,
    },
    [WidgetCategory.Line]: {
      [AxisTickType.Slanted]: 60,
      [AxisTickType.Horizontal]: 50,
    },
    [WidgetCategory.Pie]: {
      [AxisTickType.Horizontal]: 32,
    },
  },
};

export const labelFormatterOptionToHeight: Record<
  LabelFormatterOption,
  Partial<Record<WidgetCategory, Partial<Record<AxisTickType, number>>>> | undefined
> = {
  [LabelFormatterOption.NONE]: {
    [WidgetCategory.Bar]: { [AxisTickType.Vertical]: 70, [AxisTickType.Slanted]: 65, [AxisTickType.Horizontal]: 45 },
  },
  [LabelFormatterOption.SOURCE_ICONS]: {
    [WidgetCategory.Bar]: { [AxisTickType.Slanted]: 45, [AxisTickType.Horizontal]: 45, [AxisTickType.Vertical]: 45 },
    [WidgetCategory.Line]: { [AxisTickType.Slanted]: 45, [AxisTickType.Horizontal]: 45 },
    [WidgetCategory.Pie]: { [AxisTickType.Slanted]: 30, [AxisTickType.Horizontal]: 40 },
  },
  [LabelFormatterOption.SOURCE_ICONS_WITH_NAMES]: {
    [WidgetCategory.Bar]: {
      [AxisTickType.Slanted]: 110,
      [AxisTickType.Horizontal]: 70,
      [AxisTickType.Vertical]: 110,
    },
    [WidgetCategory.Line]: {
      [AxisTickType.Slanted]: 80,
      [AxisTickType.Horizontal]: 50,
    },
    [WidgetCategory.Pie]: {
      [AxisTickType.Slanted]: 30,
      [AxisTickType.Horizontal]: 40,
    },
  },
};
