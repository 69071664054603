import React from 'react';
import { getEmptyFilterExpression, getEmptyTimeBasedFilter } from '../../components/filters/Utils';
import { FilterToText } from '../CustomDashboards/components/FiltersDescription';
import { SchemaTypes } from '../Sources/Mapping/mapping.types';
import { defaultValues } from './constants';
import { AgingScenarioType, AssignmentType, PredicateType, RuleConfigType, UnificationRule } from './types';

const getSourcePriorityRule = fieldNameWithAlias => ({
  type: RuleConfigType.SOURCE_PRIORITY,
  expression: null,
  sourcePriority: [{ sourceName: undefined, fieldNameWithAlias }],
});

export const emptyRule = (fieldType: SchemaTypes, fieldNameWithAlias?) =>
  ({
    id: crypto.randomUUID(),
    isNew: true,
    name: fieldNameWithAlias ? 'Source Priority Rule' : '',
    ruleConfig: fieldNameWithAlias
      ? getSourcePriorityRule(fieldNameWithAlias)
      : {
          type: RuleConfigType.CONDITIONAL,
          predicate: { type: PredicateType.FILTER, value: getEmptyFilterExpression() },
          assignment: {
            assignmentType: fieldType === SchemaTypes.STRING ? AssignmentType.STRING_TEMPLATE : AssignmentType.VALUE,
            value: defaultValues[fieldType] ?? '',
          },
        },
  }) as UnificationRule;

export const emptyAssetAgingRule = () =>
  ({
    id: crypto.randomUUID(),
    isNew: true,
    name: '',
    ruleConfig: {
      type: RuleConfigType.AGING_CONFIG,
      populationFilter: getEmptyFilterExpression(),
      scenarioType: AgingScenarioType.TIME_BASED,
      timeBasedScenario: getEmptyTimeBasedFilter(),
    },
  }) as UnificationRule;

export const getEmptyRuleSet = (name, type, field) => ({
  ...getEmptyBaseRuleSet(name, field),
  rules: [
    {
      name: 'Default Rule',
      ruleConfig: {
        type: RuleConfigType.CONDITIONAL,
        assignment: {
          assignmentType: type === SchemaTypes.STRING ? AssignmentType.STRING_TEMPLATE : AssignmentType.VALUE,
          value: defaultValues[type] === '' && ![SchemaTypes.DATETIME, SchemaTypes.DATE].includes(type) ? 'Unknown' : defaultValues[type],
        },
      },
      id: crypto.randomUUID(),
      isNew: true,
    },
  ],
});

export const unificationRulesListHeadCells = (fieldMap, projectionName) => [
  { label: 'Name', formatter: ({ name }) => name, width: '300px' },
  {
    label: 'Result',
    formatter: ({ ruleConfig }) =>
      ruleConfig.assignment.assignmentType === AssignmentType.FIELD
        ? (fieldMap[ruleConfig.assignment.value].title ?? 'Unknown')
        : ruleConfig.assignment.value?.toString(),
    width: '250px',
  },
  {
    label: 'Condition',
    sx: { overflow: 'hidden' },
    // eslint-disable-next-line react/no-unstable-nested-components
    formatter: rule =>
      rule.ruleConfig.predicate &&
      (rule.ruleConfig.predicate.type === PredicateType.FILTER ? (
        <FilterToText filter={rule.ruleConfig.predicate.value} projectionId={projectionName} size="medium" oneLine />
      ) : (
        rule.ruleConfig.predicate.value
      )),
  },
];

export const getEmptyBaseRuleSet = (name, field) => ({
  name,
  ruleSetConfig: {
    projId: field.mainProjId,
    field: field.name,
  },
  rules: [] as UnificationRule[],
});

export const categoryOptions = [
  { title: 'Aging by time', value: AgingScenarioType.TIME_BASED },
  { title: 'Aging by value', value: AgingScenarioType.VALUE_BASED },
];
