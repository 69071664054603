import { Dispatch, SetStateAction } from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvMutation } from '../../API/useAvQuery';
import { useStaticErrorSnackBar } from '../../components/AvSnackBarMessage';
import { useAvContext } from '../../context/AvContextProvider';
import { QueryKey } from '../../utils/queryKey';
import { flattenObject, uniqBy } from '../../utils/Utils';
import { generateOption } from '../AssetsCoverage/Utils';
import { GENERAL_ENTITY } from './Mapping/mapping.types';

const getParserConfig = value =>
  value === 'CSV'
    ? { delimiter: ',', quote: '"', escape: '\\' }
    : ['PYTHON', 'PYTHON_STREAM'].includes(value)
      ? { script: 'def' }
      : value === 'XML'
        ? { rootXPath: '' }
        : value === 'JSON'
          ? { rootJsonPath: '$' }
          : undefined;
export const useFileReaderTypes = () => ({
  data: [null, 'JSONL', 'JSON', 'CSV', 'XML', 'PYTHON', 'PYTHON_STREAM'].map(reader => ({
    reader,
    title: reader ?? 'Auto',
    fileReaderConfig: getParserConfig(reader),
  })),
});

export const useProcessNow = (onSuccess = d => d, muteErrors?, syncMode?) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({ id, limitRows, ...body }: any) =>
      api(
        `data-source-instance/process?dsiId=${id}${limitRows ? `&limitRows=${limitRows}` : ''}${syncMode ? `&syncMode=${syncMode}` : ''}`,
        {
          options: { method: 'PUT' },
          body,
          muteErrors,
        }
      ).then(onSuccess),
  });
};

const handleErrors = enqueueSnackbar => e => {
  if ([e?.status, e?.graphQLErrors?.[0]?.extensions?.status, e?.extensions?.status].includes(400)) {
    console.warn(e);
  } else {
    console.error(e);
  }
  enqueueSnackbar(typeof e?.message === 'string' ? e?.message : 'Something went wrong, Our engineers have been notified', {
    variant: 'error',
  });
};

export const useDataPreview = ({ previewId, type, fileParser, disabled = false }) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: [QueryKey.DataPreview, previewId, fileParser],
    queryFn: () =>
      api(GET_PREVIEW, {
        options: {
          details: {
            fileId: previewId,
            dataSourceType: type.toUpperCase(),
            reader: fileParser?.reader,
            fileReaderConfig: fileParser?.fileReaderConfig,
          },
        },
        muteErrors: true,
        onSuccess: data => {
          if (data.data?.getFilePreview) {
            return data.data.getFilePreview;
          }
          handleErrors(enqueueSnackbar)(data.errors?.[0]);
          return [];
        },
      }).catch(handleErrors(enqueueSnackbar)),
    enabled: !!previewId && !!type && !disabled,
  });
};

export const getDefaultFieldName = mapping =>
  `${mapping.modelName}_${mapping.metadataName}_${mapping.modelFieldName}_${mapping.entityName}`;

export function useMapping(type, previewId, instanceId, onSuccess = d => d, setOriginalObject?: Dispatch<SetStateAction<any>>) {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.Mapping, type, previewId, instanceId, !!setOriginalObject],
    queryFn: () => {
      const query = previewId ? GET_LIST_BY_TYPE : GET_LIST;
      const options = previewId ? { dataSourceType: type.toUpperCase() } : { id: instanceId };
      return api(query, { options })
        .then(({ data }) => {
          setOriginalObject?.(data.findMappingByDataSourceInstanceId);
          const original = (previewId ? data.getDefaultMappings : data.findMappingByDataSourceInstanceId.mappingMetadata).fieldMapping;
          return original.map(row => {
            const newRow = {
              ...row,
              entityName: row.entityName || GENERAL_ENTITY,
              modelFieldName: row.modelFieldName || row.modelName || row.metadataName,
            };
            return { ...newRow, defaultFieldName: getDefaultFieldName(newRow) };
          });
        })
        .then(onSuccess);
    },
    enabled: !!type,
    gcTime: 0,
  });
}

export function useSourceMappingId(instanceId) {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.SourceMappingId, instanceId],
    queryFn: () => api(GET_SOURCE_MAPPING_ID, { options: { id: instanceId } }).then(({ data }) => data.findMappingByDataSourceInstanceId),
    enabled: !!instanceId,
  });
}

export const getMappingMetadata = (relevantChanges, entityFields) => ({
  fieldMapping: relevantChanges.map(({ defaultFieldName, ...row }) => ({
    ...row,
    entityName: row.entityName === GENERAL_ENTITY ? undefined : row.entityName,
    ...(entityFields[row.entityName].fields.find(({ name }) => name === row.modelFieldName)?.isMetadata
      ? { metadataName: row.modelFieldName }
      : { modelName: row.modelFieldName }),
  })),
});

export const useMappingPreview = ({ previewId, type, instanceId, mapping, ...otherProps }, onSuccess: any = d => d) => {
  const {
    api,
    accountEntities: { oldIngressProjs },
  } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.mappingPreview],
    queryFn: () =>
      api(GET_MAPPING_PREVIEW, {
        options: {
          details: {
            fileId: previewId,
            dsiId: instanceId,
            dataSourceType: type.toUpperCase(),
            mappingMetadata: getMappingMetadata(
              mapping.filter(({ deleted }) => !deleted),
              oldIngressProjs
            ),
            ...otherProps,
          },
        },
        muteErrors: true,
      }).then(({ data, errors }) => onSuccess(data?.getTransformationPreview, errors)),
    enabled: !!type,
    gcTime: 0,
  });
};

export const useBridgeHiddenFields = () => {
  const { api } = useAvContext();
  const {
    data = { bridge: {} },
    isPending: isLoading,
    error,
  } = useQuery({
    queryKey: [QueryKey.Bridge],
    queryFn: () => api(GET_BRIDGE_QUERY).then(({ data }) => getHiddenFields(data)),
  });

  return { data, isLoading, error };
};

const getHiddenFields = data =>
  !data?.allBridgeEntities
    ? {}
    : data.allBridgeEntities.reduce((acc, entity) => {
        if (entity.entityTypeId?.name && entity.compositeKeyDefinition?.keyFieldName) {
          acc[entity.entityTypeId.name] = entity.compositeKeyDefinition.keyFieldName;
        }
        return acc;
      }, {});

export const getFieldErrorsMap = data =>
  data.metadata.errorsList.reduce(
    (map, { entityTypeId: { name }, fieldErrorsList }) => ({
      ...map,
      ...fieldErrorsList.reduce(
        (fieldMap, { fieldName, errorMessage }) => ({ ...fieldMap, [`${name.toLowerCase()}.${fieldName}`]: errorMessage }),
        {}
      ),
    }),
    {}
  );

export const useValidateMapping = ({ previewId, type, instanceId, mapping, ...otherProps }) => {
  const { enqueueSnackbar } = useSnackbar();
  const staticErrorSnackBar = useStaticErrorSnackBar();
  const {
    api,
    accountEntities: { oldIngressProjs },
  } = useAvContext();
  return useAvMutation({
    mutationFn: ({ entityName, modelFieldName, mappingValue, mappingType, newMapping }: Partial<(typeof mapping)[keyof typeof mapping]>) =>
      api(GET_MAPPING_VALIDATE, {
        options: {
          details: {
            fileId: previewId,
            dsiId: instanceId,
            dataSourceType: type.toUpperCase(),
            mappingMetadata: getMappingMetadata(
              (newMapping || mapping)
                .map(m => (m.entityName === entityName && m.modelFieldName === modelFieldName ? { ...m, mappingValue, mappingType } : m))
                .filter(({ deleted }) => !deleted),
              oldIngressProjs
            ),
            ...otherProps,
          },
        },
        muteErrors: true,
        onSuccess: ({ data, errors }) => {
          if (errors) {
            const error = errors[0];
            if (error?.extensions?.status === 400) {
              console.warn(error.message);
              staticErrorSnackBar({ title: 'Validation Failed', message: error.message });
            } else {
              console.error(error?.message);
              enqueueSnackbar(error?.message, { variant: 'error' });
            }
            return undefined;
          }
          const errorsMap = getFieldErrorsMap(data.getTransformationPreview);
          const fieldError = errorsMap[`${entityName.toLowerCase()}.${modelFieldName}`];
          if (fieldError) {
            staticErrorSnackBar({ title: 'Validation Failed', message: fieldError });
          } else {
            enqueueSnackbar('Validation passed successfully!', { variant: 'success' });
          }
          return fieldError;
        },
      }),
  } as any) as any;
};

export const useDSIData = ({ instanceId: id }) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: [QueryKey.FileParser, id],
    queryFn: () =>
      api(GET_DSI_DATA, { options: { id }, onError: () => enqueueSnackbar('Failed to fetch File Parser', { variant: 'error' }) }).then(
        ({ data }) => ({
          ...(data?.findDataSourceInstanceById || {}),
          fileParser: data?.findDataSourceInstanceById?.fileParser || undefined,
        })
      ),
    enabled: !!id,
  });
};

export const useDataSourceBulkUpdate = (onSuccess: () => void) => {
  const { api } = useAvContext();
  const { mutate } = useMutation({
    mutationFn: ({ dsiIds, active }: { dsiIds: string[]; active?: boolean }) =>
      api(BULK_UPDATE_DSIS, {
        options: { updateData: dsiIds.map(id => ({ id, active })) },
      }).then(onSuccess),
  });
  return mutate;
};

export const useSetFileParser = ({ instanceId: dsiId }) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (fileParser: any) =>
      api(SET_FILE_PARSER, { options: { dsiId, ...fileParser } }).catch(e => {
        console.error(e);
        const userFacingError = 'Failed to save File Parser';
        enqueueSnackbar(userFacingError, { variant: 'error' });
        throw new Error(userFacingError);
      }),
  });
};

export const useDataAndFieldsPreview = ({ previewId, type, fileParser }) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useQuery({
    queryKey: [QueryKey.DataPreviewFromWizard, previewId, fileParser],
    queryFn: () =>
      api(GET_PREVIEW_AND_FIELDS, {
        options: {
          details: {
            fileId: previewId,
            dataSourceType: type.toUpperCase(),
            reader: fileParser?.reader,
            fileReaderConfig: fileParser?.fileReaderConfig,
          },
        },
        muteErrors: true,
      })
        .then(data => {
          if (data.data?.getFilePreview) {
            return { data: data.data.getFilePreview, fields: data.data.getFileFieldsPreview };
          }
          handleErrors(enqueueSnackbar)(data.errors?.[0]);
          return [];
        })
        .catch(handleErrors(enqueueSnackbar)),
    enabled: !!previewId && !!type,
  });
};

export const useDataStreamInstanceAndFieldsPreview = (
  {
    instanceId,
    fileParser,
    onSuccess = d => d,
    onError,
  }: { instanceId: string | undefined; fileParser; onSuccess?: (any) => any; onError?: (e) => void },
  enabled
) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.UseDataStreamInstancePreviewSource, instanceId, fileParser, onSuccess.toString()],
    queryFn: () =>
      api(GET_INSTANCE_PREVIEW_SOURCE, {
        options: { instanceId, reader: fileParser?.reader, fileReaderConfig: fileParser?.fileReaderConfig },
        onSuccess: data => ({ data: data?.data?.getDataSourceInstanceFilePreview, fields: data?.data?.getDataSourceInstanceFileFields }),
        onError,
      }),
    enabled: !!enabled,
    gcTime: 0,
  });
};

export const useDataStreamInstancePreview = (
  {
    instanceId,
    fileParser,
    onSuccess = d => d,
    onError,
  }: { instanceId: string | undefined; fileParser; onSuccess?: (any) => any; onError?: (e) => void },
  enabled
) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [QueryKey.UseDataStreamInstancePreview, instanceId, fileParser, onSuccess.toString()],
    queryFn: () =>
      api(GET_INSTANCE_PREVIEW, {
        options: { instanceId, reader: fileParser?.reader, fileReaderConfig: fileParser?.fileReaderConfig },
        onSuccess: data => onSuccess(data?.data?.getDataSourceInstanceFilePreview),
        onError,
      }),
    enabled: !!instanceId && !!enabled,
    gcTime: 0,
  });
};

export const useGetSuppressionFields = (fileId, type, newData, id, isLoading, onError, disabled = false) => {
  const { data: dataPreview, isLoading: isLoadingPreview } = useDataPreview({
    previewId: fileId,
    type,
    fileParser: newData.fileParser,
    disabled,
  });

  const { fields: suppressionFieldsFilePreview } = useTransformDataPreview(dataPreview);

  const { data: suppressionFieldsDSI, isLoading: isLoadingDSI } = useDataStreamInstancePreview(
    {
      instanceId: id,
      fileParser: newData.fileParser,
      onSuccess: rows => (rows?.[0] ? Object.keys(JSON.parse(rows[0])).map(generateOption) : []),
      onError,
    },
    !isLoading && newData
  );

  return {
    suppressionFields: suppressionFieldsDSI || suppressionFieldsFilePreview,
    isLoading: isLoadingPreview || isLoadingDSI,
  };
};

export const useSuggestedMapping = (details, onSuccess = d => d) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: () =>
      api(SUGGEST_MAPPING, { options: { details }, muteErrors: true })
        .then(data => {
          if (data.data?.suggestMapping) {
            onSuccess(data.data?.suggestMapping);
          } else {
            handleErrors(enqueueSnackbar)(data.errors?.[0]);
          }
        })
        .catch(handleErrors(enqueueSnackbar)),
  });
};

export const useAddEntity = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (name: string) =>
      api(ADD_ENTITY_DW2, {
        options: { req: { id: { displayName: name, name: name.replaceAll(' ', '_').toLowerCase(), builtIn: false } } },
        muteErrors: true,
      })
        .then(data => {
          if (data.data?.addEntityType) {
            enqueueSnackbar('Entity created successfully', { variant: 'success' });
            onSuccess?.();
          } else {
            handleErrors(enqueueSnackbar)(data.errors?.[0]);
          }
        })
        .catch(handleErrors(enqueueSnackbar)),
  });
};

export const useAddFieldToEntity = (onSuccess = d => d) => {
  const { api } = useAvContext();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<() => Promise<any>, unknown, { displayName; name; fieldType; entityTypeId; repeated }>({
    mutationFn: ({ displayName, name, fieldType, entityTypeId, repeated = false }) =>
      api(CREATE_ENTITY_FIELD, {
        options: {
          request: {
            typeId: entityTypeId,
            fieldType,
            repeated,
            displayName: displayName || name,
            name,
          },
        },
        muteErrors: true,
        onSuccess: data => {
          enqueueSnackbar(`Field created successfully`, { variant: 'success' });
          queryClient.refetchQueries({ queryKey: [QueryKey.Entities] });
          return onSuccess(data);
        },
      }).catch(error => {
        if ([error?.status, error?.graphQLErrors?.[0]?.extensions?.status].includes(400)) {
          console.warn(error);
        } else {
          console.error(error);
        }
        enqueueSnackbar(error?.message || `Failed to create field ${name} for entity ${entityTypeId.name}`, {
          variant: 'error',
        });
      }),
  });
};

export const useUpdateFieldToEntity = () => {
  const { api } = useAvContext();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<() => Promise<any>, unknown, { fields }>({
    mutationFn: ({ fields }) =>
      api(BULK_UPDATE_ENTITY_FIELDS, {
        options: {
          request: {
            fields: fields.map(({ entityTypeId, displayName, name }) => ({
              typeId: entityTypeId,
              name,
              displayName,
            })),
          },
        },
      }).then(({ data: { bulkUpdateEntityFields: data } }) => {
        if (data.failedFields.length > 0) {
          enqueueSnackbar(data.failedFields.map(e => e.message).join(', '), { variant: 'error' });
          console.warn(data.failedFields);
        } else {
          enqueueSnackbar(`Fields updated successfully`, { variant: 'success' });
        }
        queryClient.refetchQueries({ queryKey: [QueryKey.Entities] });
        return data;
      }),
  });
};

export const getAllFieldDefs = (mapping, row) =>
  uniqBy(
    [
      ...mapping
        .filter(({ deleted }) => !deleted)
        .map(item => ({
          ...item,
          fieldKey: `${item.entityName.toLowerCase()}.${item.modelFieldName}`,
        })),
      ...Object.keys(row)
        .filter(f => f !== 'avalorId')
        .map(fieldKey => ({
          fieldKey,
          entityName: fieldKey.split('.')[0].toLowerCase(),
          modelFieldName: fieldKey.split('.')[1],
          isAutoMapped: true,
        })),
    ],
    ({ fieldKey }) => fieldKey
  ).sort((a, b) => a.fieldKey.localeCompare(b.fieldKey));

const addEntityPrefix = (fields, entityName) =>
  Object.keys(fields).reduce(
    (obj, key) => ({
      ...obj,
      [`${entityName}.${key}`]: fields[key],
    }),
    {}
  );
export const getRowsFromPreviewData = (data, getFieldPrefix = entityName => entityName.toLowerCase()) =>
  data.map(({ avalor_id: avalorId, flat_model: fm, aggregationValues, raw, ...generalFields }) =>
    (fm || []).reduce(
      (row, entityModel) => {
        const { model, ...metadata } = entityModel.entity;
        const fields = { ...metadata, ...model };
        const entityName = getFieldPrefix(entityModel.type_id.name);
        return {
          ...row,
          ...addEntityPrefix(flattenObject({ obj: fields, shouldFlattenArray: false }), entityName),
          // eslint-disable-next-line no-underscore-dangle
          avalorId: avalorId || fields._key,
        };
      },
      {
        ...addEntityPrefix(flattenObject({ obj: generalFields, shouldFlattenArray: false }), 'general'),
        ...(aggregationValues && { agg_values: aggregationValues }),
      }
    )
  );

const sortedHeaders = ['severity', 'id'].reverse();

export function useTransformDataPreview(data) {
  const rows = data?.map((row, index) => ({ ...JSON.parse(row), avalor_unique_key: index })) || [];
  const headCells =
    !rows || !rows[0]
      ? []
      : Object.keys(rows[0])
          .sort((a, b) => sortedHeaders.indexOf(b) - sortedHeaders.indexOf(a))
          .map(key => ({
            id: key,
            label: key,
            isKey: key === 'avalor_unique_key',
            hidden: key === 'avalor_unique_key',
            style: { maxWidth: 250 },
            getValue: ({ [key]: val }: any) => (typeof val === 'object' ? JSON.stringify(val) : val?.toString()),
          }));
  return {
    rows,
    headCells,
    fields: headCells
      .filter(({ id }) => id !== 'avalor_unique_key')
      .map(({ id, label }) => ({
        value: id,
        title: label,
      })),
  };
}

export const useTestConnection = ({
  newData,
  configFields,
  showOneAuthUi,
  isEditMode,
}: {
  newData: any;
  configFields: any;
  showOneAuthUi: boolean;
  isEditMode: boolean;
}) => {
  const { api } = useAvContext();
  return useAvMutation({
    mutationFn: ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) =>
      api(VALIDATE_DATA_RETRIEVAL, {
        options: {
          config: {
            ...configFields.reduce(
              (acc, { valueName, hideIfOneAuth, defaultValue }) => ({
                ...acc,
                [valueName]:
                  hideIfOneAuth && showOneAuthUi && defaultValue !== null && newData.retrievalConfig[valueName] === undefined
                    ? defaultValue
                    : newData.retrievalConfig[valueName],
              }),
              {}
            ),
            ...(newData.retrievalConfig.avalorGatewayDnsName && {
              avalorGatewayDnsName: newData.retrievalConfig.avalorGatewayDnsName,
            }),
          },

          deliveryMethod: newData.deliveryMethod,
          id: isEditMode ? newData.id : '',
          authenticationSourceId: newData.authenticationSourceId ? newData.authenticationSourceId : '',
        },
        muteErrors: true,
        onSuccess,
      }).catch(onError),
  });
};

export const useCancelWorkflow = guidRuns => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ onSuccess }: { onSuccess: () => void }) =>
      api(CANCEL_WORKFLOW, {
        options: { wfExecutions: { workflowsExecutions: guidRuns, dsiId: null } },
        onError: () => enqueueSnackbar('Failed to cancel workflow ', { variant: 'error' }),
        onSuccess: () => {
          enqueueSnackbar('Workflow Execution canceled successfully ', { variant: 'success' });
          onSuccess();
        },
      }),
  });
};

const GET_PREVIEW = gql`
  query ($details: DataSourcePreviewDetails!) {
    getFilePreview(details: $details)
  }
`;

const GET_PREVIEW_AND_FIELDS = gql`
  query ($details: DataSourcePreviewDetails!) {
    getFilePreview(details: $details)
    getFileFieldsPreview(details: $details)
  }
`;

const GET_INSTANCE_PREVIEW_SOURCE = gql`
  query ($instanceId: String!, $reader: FileReaderType, $fileReaderConfig: JsonNode) {
    getDataSourceInstanceFilePreview(dsiId: $instanceId, reader: $reader, fileReaderConfig: $fileReaderConfig)
    getDataSourceInstanceFileFields(dsiId: $instanceId, reader: $reader, fileReaderConfig: $fileReaderConfig)
  }
`;

const GET_DSI_DATA = gql`
  query ($id: String!) {
    findDataSourceInstanceById(id: $id) {
      dataSourceType
      name
      mappedSourceName
      iconUrl
      fileParser {
        id
        reader
        fileReaderConfig
      }
    }
  }
`;

export const BULK_UPDATE_DSIS = gql`
  mutation ($updateData: [DataSourceUpdateData]!) {
    dataSourceBulkUpdate(dataSourceBulkUpdateData: $updateData)
  }
`;

const SET_FILE_PARSER = gql`
  mutation ($dsiId: String!, $reader: FileReaderType, $fileReaderConfig: JsonNode) {
    setDsiParser(dsiId: $dsiId, reader: $reader, fileReaderConfig: $fileReaderConfig)
  }
`;

const GET_INSTANCE_PREVIEW = gql`
  query ($instanceId: String!, $reader: FileReaderType, $fileReaderConfig: JsonNode) {
    getDataSourceInstanceFilePreview(dsiId: $instanceId, reader: $reader, fileReaderConfig: $fileReaderConfig)
  }
`;

const GET_MAPPING_PREVIEW = gql`
  query ($details: TransformationPreviewDetails!) {
    getTransformationPreview(details: $details) {
      previewData
      metadata {
        totalRows
        errorsList {
          entityTypeId {
            name
            builtIn
          }
          fieldErrorsList {
            fieldName
            errorMessage
          }
        }
        parsingErrorsList
      }
      populatedFieldsList
    }
  }
`;

const GET_BRIDGE_QUERY = gql`
  query {
    allBridgeEntities {
      entityTypeId {
        name
        builtIn
      }
      compositeKeyDefinition {
        keyFieldName
      }
    }
  }
`;

const GET_MAPPING_VALIDATE = gql`
  query ($details: TransformationPreviewDetails!) {
    getTransformationPreview(details: $details) {
      metadata {
        errorsList {
          entityTypeId {
            name
            builtIn
          }
          fieldErrorsList {
            fieldName
            errorMessage
          }
        }
        parsingErrorsList
      }
    }
  }
`;

const CREATE_ENTITY_FIELD = gql`
  mutation ($request: AddEntityFieldRequest) {
    createEntityField(request: $request) {
      name
    }
  }
`;

const BULK_UPDATE_ENTITY_FIELDS = gql`
  mutation ($request: BulkUpdateEntityFieldsRequest) {
    bulkUpdateEntityFields(request: $request) {
      updatedFields {
        name
      }
      failedFields {
        fieldName
        message
      }
    }
  }
`;

export const SUGGEST_MAPPING = gql`
  query ($details: MappingSuggestionRequestDetails) {
    suggestMapping(details: $details)
  }
`;

export const ADD_ENTITY_DW2 = gql`
  mutation ($req: EntityTypeScalar!) {
    addEntityType(entityType: $req) {
      id {
        builtIn
        name
      }
    }
  }
`;

const GET_LIST = gql`
  query ($id: String!) {
    findMappingByDataSourceInstanceId(id: $id) {
      id
      name
      dataSourceType
      mappingMetadata
    }
  }
`;
const GET_LIST_BY_TYPE = gql`
  query ($dataSourceType: String!) {
    getDefaultMappings(dataSourceType: $dataSourceType)
  }
`;

const GET_SOURCE_MAPPING_ID = gql`
  query ($id: String!) {
    findMappingByDataSourceInstanceId(id: $id) {
      id
    }
  }
`;

const VALIDATE_DATA_RETRIEVAL = gql`
  mutation ($config: IDataSourceInstanceConfig!, $deliveryMethod: String!, $id: String!, $authenticationSourceId: String!) {
    validateDataRetrievalNew(config: $config, deliveryMethod: $deliveryMethod, id: $id, authenticationSourceId: $authenticationSourceId)
  }
`;

const CANCEL_WORKFLOW = gql`
  mutation ($wfExecutions: workflowExecutionInput!) {
    cancelWorkflows(wfExecutions: $wfExecutions)
  }
`;

// const GET_FILE_READER_TYPES = gql`
//     query {
//         FileReaderTypes: __type(name: "FileReaderType") {
//             enumValues {
//                 name
//             }
//         }
//     }
// `;
