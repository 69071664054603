import { createFileRoute } from '@tanstack/react-router';
import { PAGE_PATHS } from '../../../../types';

const path = PAGE_PATHS.TICKETS;
export const Route = createFileRoute('/$accountId/vulnerabilities/tickets/')({
  staticData: {
    path,
    key: 'Ticket',
  },
});
