import React, { useRef, useState } from 'react';
import ClearButton from '@components/ClearButton';
import { Box, Button, Collapse, TextField, TextFieldProps, useTheme } from '@mui/material';
import { getCleanNumberInputValue, noop } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import { InputTooltipWrapper } from './InputTooltipWrapper';

const listItemIcon = (activeIcon, pointerEvents?) => ({
  ...flex.itemsCenter,
  gap: '2px',
  color: theme => (activeIcon ? theme.palette.colors.primary[500] : theme.palette.colors.neutrals[600]),
  pointerEvents,
  '& .MuiIconButton-root': {
    padding: 0,
    '.MuiOutlinedInput-root.Mui-error &': {
      color: theme => theme.palette.error.main,
    },
    '& svg': {
      height: 16,
      width: 16,
    },
  },
});

export enum EventSourceType {
  UserType = 'userType',
  ClearButton = 'clearButton',
}

interface TextInputProps {
  label?: string | React.ReactNode;
  value?: string | number | null;
  helperText?: string;
  error?: any;
  isRequired?: boolean;
  disabled?: boolean;
  onChange?: any;
  icon?: React.ReactNode;
  disableClearButton?: boolean;
  actionIcons?: React.ReactNode[];
  tooltipContent?: string | React.ReactNode;
  startAdornment?: () => React.ReactNode;
  enableAnimation?: boolean;
  inputProps?: Record<string, any>;
  onBlur?: (e: any, isClear?: boolean) => void;
  onKeyDown?: (e: any) => void;
}
const TextInput = React.forwardRef(
  (
    {
      label = undefined,
      value = '',
      helperText = '',
      error = '',
      isRequired = false,
      onChange = noop,
      disabled = false,
      icon,
      disableClearButton = false,
      actionIcons = [],
      tooltipContent,
      startAdornment,
      enableAnimation = false,
      inputProps = {},
      ...muiProps
    }: TextInputProps & TextFieldProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    const [isSearchOpen, setIsSearchOpen] = useState(!enableAnimation);
    const OnBlurAnimation = () => !value && setIsSearchOpen(false);
    const iconRef: any = useRef();
    const toggleAnimation = () => {
      setIsSearchOpen(!isSearchOpen);
      if (!isSearchOpen) {
        iconRef.current.nextSibling.focus();
      }
    };

    const InputProps: any = {
      startAdornment:
        startAdornment?.() ||
        (icon &&
          (enableAnimation ? (
            <Button ref={iconRef} onClick={toggleAnimation}>
              {icon}
            </Button>
          ) : (
            <Box sx={listItemIcon(icon && value, 'none')}>{icon}</Box>
          ))),
      endAdornment:
        actionIcons.length || (!disableClearButton && value) ? (
          <Box sx={listItemIcon(value)}>
            {!disableClearButton && value && (
              <ClearButton
                onClick={e => {
                  onChange('', { source: EventSourceType.ClearButton });
                  muiProps.onBlur?.(e, true);
                }}
              />
            )}
            {[...actionIcons]}
          </Box>
        ) : (
          ''
        ),
      ...inputProps,
    };

    const textField = (
      <TextField
        autoFocus={enableAnimation}
        ref={ref}
        label={label}
        value={value === null ? '' : value}
        required={isRequired}
        disabled={disabled}
        placeholder={disabled ? undefined : helperText}
        error={Boolean(error)}
        onChange={({ target }) =>
          onChange(muiProps.type === 'number' ? getCleanNumberInputValue(target.value) : target.value, { source: EventSourceType.UserType })
        }
        InputProps={InputProps}
        {...muiProps}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        inputProps={{ ref: innerRef }}
        onBlur={
          enableAnimation
            ? e => {
                muiProps.onBlur?.(e);
                if (e.relatedTarget !== iconRef.current) {
                  OnBlurAnimation();
                }
              }
            : muiProps.onBlur
        }
        onKeyDown={e => {
          e.stopPropagation();
          muiProps.onKeyDown?.(e);
        }}
      />
    );

    return enableAnimation ? (
      <Collapse
        in={isSearchOpen}
        orientation="horizontal"
        sx={collapseStyle(isSearchOpen, theme)}
        timeout={theme.transitions.duration.short}
        easing="ease-in-out"
        collapsedSize={
          iconRef.current
            ? iconRef.current.offsetWidth + +getComputedStyle(iconRef.current.closest('.MuiInputBase-root')).paddingLeft.slice(0, -2)
            : undefined
        }>
        <InputTooltipWrapper tooltipContent={tooltipContent} error={error} inputNode={innerRef?.current} value={value}>
          {textField}
        </InputTooltipWrapper>
      </Collapse>
    ) : (
      <InputTooltipWrapper tooltipContent={tooltipContent} error={error} inputNode={innerRef?.current} value={value}>
        {textField}
      </InputTooltipWrapper>
    );
  }
);

export default TextInput;

const collapseStyle: any = (isSearchOpen, theme) => ({
  '.MuiInputBase-root': isSearchOpen ? undefined : { pointerEvents: 'none' },
  '.MuiButtonBase-root': isSearchOpen ? undefined : { pointerEvents: 'all' },
  '.MuiOutlinedInput-notchedOutline': {
    opacity: isSearchOpen ? 1 : 0,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.short,
    }),
  },
});
