import React, { Dispatch, SetStateAction, useState } from 'react';
import { Avatar, Box, Button, Divider, Menu, MenuItem, Skeleton, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../context/AvContextProvider';
import { useAvDebugContext } from '../../context/AvDebugProvider';
import { GetUsersInfo } from '../../hooks/getUsersInfo';
import envVariables from '../../shared/projectEnvVariables';
import { APP_PATHS, PAGE_PATHS, Permission } from '../../types';
import { App } from '../../types/sideBar.types';
import { getAccountTokenFromLS } from '../../utils/auth.utils';
import { isDebugMode } from '../../utils/rum.utils';
import { Initials } from '../../utils/Utils';
import { useAuth } from '../amplifyProvider';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import SwitchToggle from '../SwitchToggle';
import { appBarItemHoveredStyle, appBarItemSelectedStyle } from './AppItem';
import AvMenuItem from './AvMenuItem';
import SwitchAccountMenu from './SwitchAccountMenu';
import { ReactComponent as Copy } from '../../assets/Copy.svg';
import { ReactComponent as Logout } from '../../assets/Logout.svg';

const logoutIcon = <Logout />;
interface Props {
  setFilteredAppsBarItems: Dispatch<SetStateAction<App[]>>;
}
const UserMenu: React.FC<Props> = ({ setFilteredAppsBarItems }) => {
  const {
    isBackOfficeAvailable,
    getPathName,
    accountData,
    user,
    isLoading,
    selectedApp,
    userPermissions: { hasAllowedPermission, isInternalRole },
    accountId,
  } = useAvContext();
  const { logout } = useAuth();
  const { usersInfo } = GetUsersInfo(user.userId ? [user.userId] : []);
  const userName = usersInfo?.[user.userId] || '';
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [closeTimeout, setCloseTimeout] = useState<any>();
  const [debugVisible, setDebugVisible] = useState(false);
  const [debugMode, setDebugMode] = useState(isDebugMode());
  const { showDevTools, setShowDevTools } = useAvDebugContext();
  const [anchorElUser, setAnchorElUser] = useState<Element>();
  const toggleUserMenu = ({ currentTarget, shiftKey, altKey }) => {
    if (shiftKey && altKey) {
      setDebugVisible(true);
    }
    setAnchorElUser(anchorElUser ? null : currentTarget);
  };
  const debugToggle = checked => {
    setDebugMode(checked);
    localStorage.setItem('debugMode', checked);
  };
  const devtoolsToggle = checked => {
    setShowDevTools(checked);
    localStorage.setItem('avalor-devtools', checked);
  };

  const clearCloseTimeout = () => clearTimeout(closeTimeout);

  const closeUserMenu = () => {
    clearCloseTimeout();
    setCloseTimeout(setTimeout(() => setAnchorElUser(undefined), 350));
  };
  const { email } = user;

  const copyAccessToken = () => {
    navigator.clipboard.writeText(`Bearer ${getAccountTokenFromLS(accountId) || ''}`);
    enqueueSnackbar('Copied!', { variant: 'success' });
    closeUserMenu();
  };
  const userAccountLogoProps = { src: `/${accountData.logo}`, alt: accountData.name };
  const hasAccountManagementPermissions = hasAllowedPermission({ path: PAGE_PATHS.EDIT_MY_ACCOUNT_MANAGEMENT });
  const hasEditMyUserManagementPermissions = hasAllowedPermission({ path: PAGE_PATHS.EDIT_MY_USER });
  const hasBuiltInFactorsPagePermissions =
    hasAllowedPermission({ path: PAGE_PATHS.BACKOFFICE_ACTIONS, permission: Permission.UPDATE }) ||
    hasAllowedPermission({ path: PAGE_PATHS.GLOBAL_FACTOR_RULES, permission: Permission.UPDATE }) ||
    isInternalRole;
  const hasAllUsersPagePermissions = hasAllowedPermission({ path: PAGE_PATHS.ALL_USERS });
  const devtoolsVisible = debugVisible && envVariables.MODE !== 'production';
  return (
    <Box onMouseOver={clearCloseTimeout} onMouseLeave={closeUserMenu}>
      {isLoading ? (
        <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
          <Skeleton variant="circular" sx={iconLoadingStyle} />
          <Skeleton variant="circular" sx={iconLoadingStyle} />
        </Box>
      ) : (
        <AvTooltip title={accountData.name}>
          <Button
            sx={{
              ...flex.itemsCenter,
              gap: 2,
              px: 1,
              py: 0.5,
              border: '1px solid transparent',
              ...appBarItemHoveredStyle(theme),
              ...(selectedApp === APP_PATHS.SETTINGS || anchorElUser ? appBarItemSelectedStyle(theme) : {}),
            }}
            onClick={toggleUserMenu}>
            <Avatar {...userAccountLogoProps} sx={{ height: 30, width: 30 }} />
            <Initials name={usersInfo?.[user.userId] || ''} size="small" />
          </Button>
        </AvTooltip>
      )}
      {Boolean(anchorElUser) && (
        <Menu
          anchorEl={anchorElUser}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={Boolean(anchorElUser)}
          sx={popoverStyle}>
          <MenuItem sx={{ paddingX: 1, pointerEvents: 'none' }}>
            <Box
              sx={{
                ...flex.justifyBetweenCenter,
                width: '100%',
              }}>
              <Box sx={{ ...flex.row, gap: 2 }}>
                <Initials name={usersInfo?.[user.userId] || ''} />
                <Box sx={{ ...flex.col }}>
                  <Box sx={{ fontWeight: 600 }}>{userName}</Box>
                  <Box sx={(theme: any) => ({ color: theme.palette.colors.neutrals[500] })}>{email}</Box>
                </Box>
              </Box>
            </Box>
          </MenuItem>
          <AvMenuItem
            pathName={getPathName(PAGE_PATHS.EDIT_MY_USER, '', APP_PATHS.SETTINGS).to}
            onClick={closeUserMenu}
            title="Profile Settings"
            hasPermissions={hasEditMyUserManagementPermissions}
          />
          <Divider />
          <MenuItem sx={{ paddingLeft: 1, pointerEvents: 'none', userSelect: 'all' }}>
            <Avatar {...userAccountLogoProps} sx={{ height: 40, width: 40 }} />
            <Box sx={{ fontWeight: 600 }}>{accountData.name}</Box>
          </MenuItem>
          <SwitchAccountMenu closeParent={setAnchorElUser} setFilteredAppsBarItems={setFilteredAppsBarItems} />
          <AvMenuItem
            pathName={getPathName(PAGE_PATHS.EDIT_MY_ACCOUNT_MANAGEMENT, '', APP_PATHS.SETTINGS).to}
            onClick={closeUserMenu}
            title="Account Settings"
            hasPermissions={hasAccountManagementPermissions}
          />
          <AvMenuItem
            pathName={getPathName(PAGE_PATHS.ALL_USERS, '', APP_PATHS.SETTINGS).to}
            onClick={closeUserMenu}
            title="Manage Accounts"
            hasPermissions={isBackOfficeAvailable && hasAllUsersPagePermissions}
          />
          {hasBuiltInFactorsPagePermissions && <Divider />}
          <AvMenuItem
            pathName={
              isBackOfficeAvailable
                ? getPathName(PAGE_PATHS.BACKOFFICE_ACTIONS, '', APP_PATHS.SETTINGS).to
                : isInternalRole
                  ? getPathName(PAGE_PATHS.ALL_ACCOUNTS, '', APP_PATHS.SETTINGS).to
                  : getPathName(PAGE_PATHS.GLOBAL_FACTOR_RULES, '', APP_PATHS.SETTINGS).to
            }
            onClick={closeUserMenu}
            title="Internal Admin Tools"
            hasPermissions={hasBuiltInFactorsPagePermissions}
          />
          {isInternalRole && <Divider />}
          {isInternalRole && (
            <MenuItem onClick={copyAccessToken}>
              Copy Access Token <Copy />
            </MenuItem>
          )}
          <Divider />
          <MenuItem onClick={() => logout()} sx={{ svg: { color: ({ palette }: any) => palette.colors.neutrals[600] } }}>
            {logoutIcon}Log out
          </MenuItem>
          {debugVisible ? (
            <MenuItem>
              <SwitchToggle label="Debug Mode" value={debugMode} onChange={debugToggle} />
            </MenuItem>
          ) : null}
          {devtoolsVisible ? (
            <MenuItem>
              <SwitchToggle label="DevTools" value={showDevTools} onChange={devtoolsToggle} />
            </MenuItem>
          ) : null}
        </Menu>
      )}
    </Box>
  );
};

export default UserMenu;

const popoverStyle = {
  pointerEvents: 'none',
  '.MuiMenu-paper': {
    pointerEvents: 'auto',
    minWidth: 250,
  },
  '.MuiMenuItem-root': {
    paddingLeft: 2,
    paddingRight: 2,
    minHeight: 40,
    '.MuiTypography-root': {
      width: '100%',
    },
    svg: {
      width: 18,
    },
  },
};

const iconLoadingStyle = {
  minWidth: 'inherit',
  height: 30,
  width: 30,
  svg: {
    height: 'inherit',
    width: 'inherit',
  },
};
