import { dateFilterOptions } from '../../components/SelectDate';
import { ScreenType } from '../../types/savedViews.types';

export const viewScreenType = ScreenType.AssetCoverage;
export const slicerWidth = 450;

const now = new Date();
export const defaultFilters = {
  tablePage: ['0'],
  orderBy: ['asset.last_seen'],
  tab: '0',
  timeRange: [new Date(now.getTime() - dateFilterOptions[8].value), now],
  'asset.source_names': [],
  'asset.type': [],
  'asset.owner_id': [],
  headCells: [],
  overlappingAssetsBySource: [],
  seriesIndex: [],
};
export const generateOption = value => ({ title: value, value });
