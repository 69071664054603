import React from 'react';
import { Box } from '@mui/material';
import AvItemWithLogoGroup, { IconSize } from '../../components/AvItemWithLogoGroup';
import { flex } from '../../components/AvThemeProvider';
import AvTooltip from '../../components/AvTooltip';
import ColorDescription from '../../components/ColorPicker/ColorDescription';
import ColorItem from '../../components/ColorPicker/ColorItem';
import { IconVariant } from '../../components/ItemWithLogo';
import { HeadCellType } from '../../components/Table/types';
import { Filter, NumberConditionType } from '../../types/filter.types';
import { generateOption, generateUUID } from '../../utils/Utils';
import { AllTypeWidgets, WidgetCategory } from '../CustomDashboards/types';
import { MeasurementUnit } from '../Reports/types';
import { FormattingCondition, FormattingRule } from './types';

export const NONE_ID = 'none';

export const headCells = (onEdit): HeadCellType[] => [
  {
    id: 'id',
    hidden: true,
    isKey: true,
  },
  {
    id: 'name',
    label: 'Name',
    linkProps: { shouldShowLink: () => false, onClickLink: onEdit },
  },
  {
    id: 'result',
    label: 'Result',
    formatter: (_, row) => (
      <AvItemWithLogoGroup
        items={[...new Set<string>(row.formattingConditions.map(v => v.color))]}
        variant={IconVariant.color}
        CustomItemRender={ColorItem}
        size={IconSize.collapsedXSmall}
        limit={3}
      />
    ),
  },
  {
    id: 'type',
    label: 'Unit',
  },
  {
    id: 'condition',
    label: 'Condition',
    formatter: (_, row) => row && <ColorDescription rule={row} colorSize={18} />,
  },
];

export const defaultFormattingCondition = (theme): FormattingCondition => ({
  id: generateUUID(),
  color: `${theme.palette.colors.primary[500]}FF`,
  filter: {
    expression: {
      fieldName: '',
      numberCondition: {
        gte: 0,
      },
    },
  },
});

const defaultFormattingCondition1 = (theme): FormattingCondition => ({
  id: generateUUID(),
  color: `${theme.palette.colors.negative[500]}FF`,
  filter: {
    expression: {
      fieldName: '',
      numberCondition: {
        lte: 25,
      },
    },
  },
});
const defaultFormattingCondition2 = (theme): FormattingCondition => ({
  id: generateUUID(),
  // eslint-disable-next-line custom-rules/no-color-hex
  color: `${theme.palette.colors.orange[500]}FF`,
  filter: {
    expression: {
      fieldName: '',
      numberCondition: {
        between: {
          from: 26,
          to: 50,
        },
      },
    },
  },
});

const defaultFormattingCondition3 = (theme): FormattingCondition => ({
  id: generateUUID(),
  color: `${theme.palette.colors.yellow[500]}FF`,
  filter: {
    expression: {
      fieldName: '',
      numberCondition: {
        between: {
          from: 51,
          to: 75,
        },
      },
    },
  },
});

const defaultFormattingCondition4 = (theme): FormattingCondition => ({
  id: generateUUID(),
  color: `${theme.palette.colors.positive[500]}FF`,
  filter: {
    expression: {
      fieldName: '',
      numberCondition: {
        gte: 76,
      },
    },
  },
});

const defaultFormattingConditionFallback = (theme): FormattingCondition => ({
  id: generateUUID(),
  color: `${theme.palette.colors.primary[500]}FF`,
});

export const defaultFormattingConditions = theme => [
  defaultFormattingCondition1(theme),
  defaultFormattingCondition2(theme),
  defaultFormattingCondition3(theme),
  defaultFormattingCondition4(theme),
  defaultFormattingConditionFallback(theme),
];

export const defaultFormattingRule = (theme): FormattingRule => ({
  id: CreatePrefix + generateUUID(),
  name: '',
  type: MeasurementUnit.Percentage,
  formattingConditions: defaultFormattingConditions(theme),
});

export const measurementUnitOptions = Object.values(MeasurementUnit).map(v => generateOption(v));

export const CreatePrefix = 'create_';
export const getIsCreate = formattingRule => formattingRule?.id?.startsWith(CreatePrefix);

export const renderFormattingRuleOption = (v?: FormattingRule) =>
  v?.id && v.id !== NONE_ID ? (
    <Box sx={{ ...flex.justifyBetween, width: '100%' }}>
      <Box>{v.name}</Box>
      <AvTooltip variant="message" color="white" hasWidthLimit={false} title={v && <ColorDescription rule={v} colorSize={20} />}>
        <Box>
          <AvItemWithLogoGroup
            items={[...new Set<string>(v.formattingConditions.map(f => f.color))]}
            variant={IconVariant.color}
            CustomItemRender={ColorItem}
            size={IconSize.collapsedXSmall}
            shouldShowTooltip={false}
            limit={3}
          />
        </Box>
      </AvTooltip>
    </Box>
  ) : (
    <Box>None</Box>
  );

export const getFormattingConditionById = (id: string | undefined, formattingRules: FormattingRule[]) =>
  formattingRules.find(v => v.id === id)?.formattingConditions || [];

export const getColorFromFormattingConditions = ({
  value,
  formattingConditions,
  defaultColor,
}: {
  value: number;
  formattingConditions: FormattingCondition[];
  defaultColor: string;
}) => {
  const conditionWithoutLast = formattingConditions.slice(0, -1);
  const fallbackCondition = formattingConditions.at(-1);
  return (
    conditionWithoutLast.find(({ filter }) => filter && checkFilterPass(value, filter))?.color || fallbackCondition?.color || defaultColor
  );
};

const checkFilterPass = (value: number, expressionFilter: Filter) => {
  const condition = Object.keys(expressionFilter!.expression!.numberCondition || {})?.[0] || NumberConditionType.EQUALS;
  const conditionValue = expressionFilter!.expression!.numberCondition![condition];
  switch (condition) {
    case NumberConditionType.GT: {
      return value > conditionValue;
    }
    case NumberConditionType.GTE: {
      return value >= conditionValue;
    }
    case NumberConditionType.LT: {
      return value < conditionValue;
    }
    case NumberConditionType.LTE: {
      return value <= conditionValue;
    }
    case NumberConditionType.EQUALS: {
      return value === conditionValue;
    }
    case NumberConditionType.NOT_EQUAL: {
      return value !== conditionValue;
    }
    case NumberConditionType.BETWEEN: {
      return value > conditionValue.from && value < conditionValue.to;
    }
    default: {
      return false;
    }
  }
};

export const hasFormattingRuleOnWidget = (widget: AllTypeWidgets) => {
  if (widget.category === WidgetCategory.Table) {
    return !!Object.values(widget.definition.custom.fieldStyle || {}).find(f => !!f.formattingRuleId);
  }
  if (widget.category === WidgetCategory.Tile) {
    return !!widget.definition.custom.formattingRuleId;
  }
  return false;
};
