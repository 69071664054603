import * as monaco from 'monaco-editor';

export const macroFuncOptions = [
  {
    name: 'ifElse',
    returnType: 'any',
    args: [
      { name: 'condition', type: 'boolean' },
      { name: 'value_if_true', type: 'any' },
      { name: 'value_if_false', type: 'any' },
    ],
    details: {
      whatDoesItDo: 'Evaluate a logical expression and return one value if true and another value if false.',
      example: {
        expression: `ifElse(5 == 7, "Numbers are equal", "Numbers are not equal")`,
        result: `"Numbers are not equal"`,
      },
    },
  },
  {
    name: 'textJoin',
    returnType: 'string',
    args: [
      { name: 'separator', type: 'string' },
      { name: 'values', type: 'string' },
    ],
    details: {
      whatDoesItDo: 'Concatenate multiple text values, elements in lists or both into a single text, separated by a specified delimiter.',
      example: {
        expression: `textJoin("@", "value1", "value2", "value3")`,
        result: '"value1@values2@value3"',
      },
    },
  },
  {
    name: 'intersect',
    returnType: 'boolean',
    args: [
      { name: 'list1', type: 'string' },
      { name: 'list2', type: 'string' },
    ],
    details: {
      whatDoesItDo:
        'Checks if there are any common elements between list1 and list2. Returns true if at least one common element is found, and false otherwise.',
      example: {
        expression: `intersect(["foo", "boo"], ["boo", "loo"])`,
        result: 'true',
      },
    },
  },
  {
    name: 'extract',
    returnType: 'string',
    args: [
      { name: 'text', type: 'string' },
      { name: 'delimiter', type: 'string' },
      { name: 'position', type: 'integer' },
    ],
    details: {
      whatDoesItDo: 'Retrieves a substring from text, based on the specified delimiter and zero-indexed position',
      example: {
        expression: `extract("value1@values2", "@", 0)`,
        result: '"value1"',
      },
    },
  },
  {
    name: 'upper',
    returnType: 'string',
    args: [{ name: 'text', type: 'string' }],
    details: {
      whatDoesItDo: 'Convert all characters in the specified text to uppercase.',
      example: {
        expression: `upper("myText")`,
        result: '"MYTEXT"',
      },
    },
  },
  {
    name: 'lower',
    returnType: 'string',
    args: [{ name: 'text', type: 'string' }],
    details: {
      whatDoesItDo: 'Convert all characters in the specified text to lowercase.',
      example: {
        expression: `lower("myText")`,
        result: '"mytext"',
      },
    },
  },
  {
    name: 'replace',
    returnType: 'string',
    args: [{ name: 'text_to_search, search_for, replace_with', type: 'string, string, string' }],
    details: {
      whatDoesItDo: 'Replaces all exact matches of search_for in text_to_search with replace_with.',
      example: {
        expression: `replace("something", "some", "one ")`,
        result: '"one thing"',
      },
    },
  },
  {
    name: 'isBlank',
    returnType: 'boolean',
    args: [{ name: 'value', type: 'string' }],
    details: {
      whatDoesItDo:
        'Returns true if the value is blank. Includes null, an empty string, a string with only whitespace or an empty list. Otherwise, returns false.',
      example: {
        expression: 'isBlank("   ")',
        result: 'true',
      },
    },
  },
  {
    name: 'ifBlank',
    returnType: 'boolean',
    args: [{ name: 'value, value_if_blank', type: 'string' }],
    details: {
      whatDoesItDo:
        "Returns value if it's not blank. Includes null, an empty string, a string with only whitespace or an empty list. Otherwise, returns value_if_blank.",
      example: {
        expression: 'ifBlank("  ", "The value is empty")',
        result: '"The value is empty"',
      },
    },
  },
  {
    name: 'extractRegex',
    returnType: 'string / list[string]',
    args: [
      { name: 'text', type: 'string' },
      { name: 'regex_to_search', type: 'string' },
      { name: 'return_mode (not mandatory)', type: 'integer' },
    ],
    details: {
      whatDoesItDo: `Retrieves a substring from a text, based on the specified regular expression. Use return_mode to define what texts to extract. By default, return mode is 0.
        0: Returns the first text that matches the regex.
        1: Returns all texts that match the regex as a list`,
      example: {
        expression: `extractRegex("Apache log4j vulnerability CVE-2021-44228", "CVE-\\d{4}-\\d{5}")`,
        result: 'CVE-2021-44228',
      },
    },
  },
  {
    name: 'contains',
    returnType: 'boolean',
    args: [{ name: 'text, text_to_search', type: 'string, string' }],
    details: {
      whatDoesItDo: 'Returns true if text_to_search is found anywhere within text. Otherwise, returns false.',
      example: {
        expression: 'contains("my text", "text")',
        result: 'true',
      },
    },
  },
  {
    name: 'containsAll',
    returnType: 'boolean',
    args: [{ name: 'text, text_to_search, text_to_search, ...', type: 'string, string' }],
    details: {
      whatDoesItDo: 'Returns true if all the provided texts are found within text. Otherwise, returns false.',
      example: {
        expression: 'containsAll("my text", "my", "text")',
        result: 'true',
      },
    },
  },
  {
    name: 'containsEither',
    returnType: 'boolean',
    args: [{ name: 'text, text_to_search, text_to_search, ...', type: 'string, string' }],
    details: {
      whatDoesItDo: 'Returns true if any of the provided texts are found within text. Otherwise, returns false.',
      example: {
        expression: 'containsEither("my text", "my", "text")',
        result: 'true',
      },
    },
  },
  {
    name: 'matches',
    returnType: 'boolean',
    args: [{ name: 'text, regex_to_search', type: 'string, string' }],
    details: {
      whatDoesItDo: 'Returns true if the provided regex matches the text. Otherwise, returns false.',
      example: {
        expression: 'matches("This is an entirely fresh new text", ".*fresh.*text")',
        result: 'True',
      },
    },
  },
  {
    name: 'matchesAll',
    returnType: 'boolean',
    args: [{ name: 'text, regex_to_search, regex_to_search, ...', type: 'string, string, string' }],
    details: {
      whatDoesItDo: 'Returns true if all the provided regexes match the text. If any regex does not match, returns false.',
      example: {
        expression: 'matchesAll("This is an entirely fresh new text", ".*fresh.*", ".*text")',
        result: 'True',
      },
    },
  },
  {
    name: 'matchesEither',
    returnType: 'boolean',
    args: [{ name: 'text, regex_to_search, regex_to_search, ...', type: 'string, string, string' }],
    details: {
      whatDoesItDo: 'Returns true if any of the provided regexes match the text. If none of the regexes match, returns false.',
      example: {
        expression: 'matchesEither("This is an entirely fresh new text", ".*new.*", ".*random.*")',
        result: 'True',
      },
    },
  },
  {
    name: 'toDate',
    returnType: 'string',
    args: [{ name: 'date_string', type: 'string' }],
    details: {
      whatDoesItDo: 'Takes a string and returns a date. Should automatically recognize all the standard date formats.',
      example: {
        expression: 'toDate("2023-10-04T15:23:01Z")',
        result: '2023-10-04T15:23:01Z',
      },
    },
  },
  {
    name: 'dateDiff',
    returnType: 'number',
    args: [{ name: 'start_date, end_date, date_unit', type: 'date, date, string' }],
    details: {
      whatDoesItDo:
        'Returns the number of date_unit values between two dates. Valid date_unit values are: "YEARS", "MONTHS", and "DAYS".\n' +
        'The input date can either be a {{date field}} or a string that can be converted to a date using the toDate function.',
      example: {
        expression: 'dateDiff(toDate("2024-01-01T15:23:01Z"), toDate("2024-02-15T15:23:01Z"), "DAYS")',
        result: '46',
      },
    },
  },
  {
    name: 'dateAdd',
    returnType: 'date',
    args: [{ name: 'date, interval, date_unit', type: 'date, number, string' }],
    details: {
      whatDoesItDo:
        'Returns a date with the specified interval added according to the date_unit. Valid date_unit values are: "YEARS", "MONTHS", and "DAYS".\n' +
        'The input date can either be a {{date field}} or a string that can be converted to a date using the toDate() function.',
      example: {
        expression: 'dateAdd(toDate("2024-01-01T15:23:01Z"), 3, "MONTHS")',
        result: '2024-04-01T15:23:01Z',
      },
    },
  },
  {
    name: 'formatDate',
    returnType: 'date',
    args: [{ name: 'date, date_format', type: 'date, string' }],
    details: {
      whatDoesItDo:
        'Takes a date and returns a string in the specified date format.\n' +
        'The input date can either be a {{date field}} or a string that can be converted to a date using the toDate() function.',
      example: {
        expression: 'formatDate(toDate("2024-01-25T15:23:01Z"), "MM/DD/YYYYTHH:MM:SSZ")',
        result: '"01/25/2024T15:23:01Z"',
      },
    },
  },
  {
    name: 'today',
    returnType: 'date',
    args: [],
    details: {
      whatDoesItDo: 'Returns the current day according to UTC time.',
      example: {
        expression: 'today()',
        result: '2025-01-13T00:00:00Z',
      },
    },
  },
  {
    name: 'abs',
    returnType: 'int',
    args: [{ name: 'number', type: 'int' }],
    details: {
      whatDoesItDo: `Returns the absolute value of a number`,
      example: {
        expression: `abs(-10)`,
        result: '10',
      },
    },
  },
].map(({ args, name, returnType, details }) => ({
  label: name,
  kind: monaco.languages.CompletionItemKind.Function,
  paramTypes: `${args.map(({ name: argName }) => argName).join(', ')}`,
  detail: returnType,
  insertText: `${name}(${args.map(({ type }) => type).join(', ')})`,
  details,
}));

// eslint-disable-next-line import/prefer-default-export
export const configureCELSyntax = editor => {
  const celKeyWords = macroFuncOptions.map(({ label }) => label);
  const CEL_OPERATORS = ['==', '!=', '<', '>', '<=', '>=', '&&', '||', '!', '+', '-', '*', '/', '%'];

  editor.languages.register({ id: 'cel' });
  editor.languages.setMonarchTokensProvider('cel', {
    defaultToken: '',
    tokenPostfix: '.cel',
    keywords: celKeyWords,
    operators: CEL_OPERATORS,
    symbols: /[=><!~?:&|+\-*/%^]+/,
    tokenizer: {
      root: [
        [/[a-z_$][\w$]*/, { cases: { '@keywords': 'keyword', '@default': 'identifier' } }],
        { include: '@whitespace' },
        [/[{}()[\]]/, '@brackets'],
        [/@symbols/, { cases: { '@operators': 'operator', '@default': '' } }],
        [/\d+/, 'number'],
        [/"([^"\\]|\\.)*$/, 'string.invalid'],
        [/"/, { token: 'string.quote', next: '@string' }],
      ],
      whitespace: [
        [/[ \t\r\n]+/, 'white'],
        [/\/\/.*$/, 'comment'],
      ],
      string: [
        [/[^\\"]+/, 'string'],
        [/\\./, 'string.escape'],
        [/"/, { token: 'string.quote', next: '@pop' }],
      ],
    },
  });
  monaco.languages.setLanguageConfiguration('cel', {
    comments: { lineComment: '//' },
    brackets: [
      ['{', '}'],
      ['[', ']'],
      ['(', ')'],
    ],
  });
};
