import { AxisTickType } from '../../views/CustomDashboards/types/types';

export enum TickRotation {
  vertical = 'vertical',
  slanted = 'slanted',
  horizontal = 'horizontal',
}

const barAxisTypeToRotation: Record<Exclude<AxisTickType, AxisTickType.Dynamic>, TickRotation> = {
  [AxisTickType.Horizontal]: TickRotation.horizontal,
  [AxisTickType.Slanted]: TickRotation.slanted,
  [AxisTickType.Vertical]: TickRotation.vertical,
} as const;

export const getYAngle = (direction: AxisTickType = AxisTickType.Dynamic) => {
  if (direction === AxisTickType.Dynamic) {
    return TickRotation.horizontal;
  }

  return barAxisTypeToRotation[direction];
};

export const getXAngle = (direction: AxisTickType = AxisTickType.Dynamic, shouldRotateTicks?: boolean) => {
  if (direction === AxisTickType.Dynamic) {
    return shouldRotateTicks ? TickRotation.slanted : TickRotation.horizontal;
  }

  return barAxisTypeToRotation[direction];
};

export const Y_TICK_MARGIN = 15;
export const X_TICK_MARGIN = 10;
