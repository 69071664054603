import React from 'react';
import { Box } from '@mui/material';
import { flex } from '../../components/AvThemeProvider';
import { GroupHeaderRowType } from '../../components/Table/types';
import { ArrayResolution } from '../../types/filter.types';
import { ALL_SOURCES } from '../../types/sources.types';
import { ellipsis } from '../../utils/Utils';
import { DwQueryRequest, ProjectionID } from '../CustomDashboards/types';
import { ReactComponent as ListSon } from '../../assets/ListSon.svg';

export const getHeadCellWithExpended = ({ headCells }: { headCells: any[] }) =>
  headCells.map((hc, i) =>
    i === 0
      ? {
          ...hc,
          style: {
            '> div': { ...flex.itemsCenter },
          },
          formatter: (v, row, setRows, index) =>
            row.groupHeaderType === GroupHeaderRowType.SUB_GROUP ? (
              <Box
                sx={{
                  ...flex.center,
                  gap: 1,
                  pl: 3,
                  svg: { color: theme => theme.palette.colors.neutrals[600] },
                  ...ellipsis,
                }}>
                <ListSon />
                <Box>{hc.formatter ? hc.formatter(v, row, setRows, index) : v}</Box>
              </Box>
            ) : hc.formatter ? (
              hc.formatter(v, row, setRows, index)
            ) : (
              v
            ),
        }
      : hc
  );

export const NESTED_CURRENT_STATUS_FIELD = 'current_status.name';
export const CURRENT_STATUS_FIELD = 'current_status';

export const SOURCES_INITIAL_LIMIT = 3;

export const generateSourcesValuesQuery = ({
  projectionId,
  fieldName,
  avalorKey,
  projAliasPath,
  entityPathAlias,
  sources = [],
  showAll,
}: {
  projectionId: ProjectionID;
  fieldName: string;
  entityPathAlias: string;
  projAliasPath: string;
  avalorKey: string;
  sources: (typeof ALL_SOURCES | string)[];
  showAll: boolean;
}): DwQueryRequest => ({
  projectionId,
  select: {
    dims: [],
    metrics: [
      {
        name: showAll
          ? `COLLECT(json_object(value: ${entityPathAlias}.sources.${fieldName}, items: ${entityPathAlias}.sources.source_names))`
          : `TOP_K_BY(json_object(value: ${entityPathAlias}.sources.${fieldName}, items: ${entityPathAlias}.sources.source_names), ${entityPathAlias}.sources.${fieldName}, ${SOURCES_INITIAL_LIMIT})`,
        alias: 'sources',
      },
      {
        name: sources.includes(ALL_SOURCES)
          ? `COUNT_DISTINCT(${entityPathAlias}.sources.source_names)`
          : `COUNT_DISTINCT_IF(${entityPathAlias}.sources.source_names, ${entityPathAlias}.sources.source_names in (${sources
              .map(item => `'${item}'`)
              .join()}))`,
        alias: 'totalCount',
      },
    ],
  },
  distinct: true,
  sorting: [],
  filter: {
    and: {
      operands: [
        {
          expression: {
            fieldName: `${projAliasPath}._key`,
            stringCondition: {
              equals: avalorKey,
            },
          },
        },
        ...(sources.includes(ALL_SOURCES)
          ? []
          : [
              {
                expression: {
                  fieldName: `${entityPathAlias}._key`,
                  arrayCondition: {
                    resolution: ArrayResolution.ANY,
                    underlying: {
                      fieldName: `${entityPathAlias}.sources.source_names`,
                      stringCondition: {
                        in: { values: sources },
                      },
                    },
                  },
                },
              },
            ]),
      ],
    },
  },
});
