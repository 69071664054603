import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { add, sub } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useStaticErrorSnackBar } from '../../components/AvSnackBarMessage';
import { getDatesFromDefinition } from '../../components/DatePicker/utils';
import { getOperator, isNullOperator } from '../../components/filters/Utils';
import { useAvContext } from '../../context/AvContextProvider';
import { NotificationContext } from '../../context/AvSnackBarProvider';
import { AccountEntities, EntityTypeID } from '../../context/context.type';
import { WorkflowType } from '../../context/SnackBar.types';
import useQueryObject from '../../hooks/useQueryObjectSql';
import useQuerySql from '../../hooks/useQuerySql';
import { FeatureFlags, Permission, PermissionCategoriesNames, PermissionEntitiesNames } from '../../types';
import { ErrorTypes } from '../../types/query.types';
import { EntityConfigType, entityViewConfig, getActionsPermissionConfig } from '../../utils/entityViewConfig';
import { projectionNameToCategoryName } from '../../utils/permissions.utils';
import { QueryKey } from '../../utils/queryKey';
import { SeverityLabels } from '../../utils/severity.utils';
import {
  arrayToCsv,
  downloadBlob,
  getEscapedValue,
  getEscapedValueForSQLLike,
  getIfValidStringifiedObject,
  sqlFormatter,
} from '../../utils/Utils';
import { TARGET_MATCHING_CATEGORY_ENUM } from '../NotificationTargets/types';
import { useGetBucketAndStatuses } from '../Settings/TicketStatuses/hooks';
import { TTab } from '../Settings/UiConfigSettings/types';
import { FieldType, FieldTypeEnum } from '../Sources/Mapping/mapping.types';
import { apiUrls, baseEntitySqlsMap, filtersFieldToIgnore, groupByKeys, groupByKeysQueryProjection } from './ticket.types';
import { generateSourcesValuesQuery } from './Utils';

export const useConfirmedAndRemediatedBuckets = proj => {
  const { buckets } = useGetBucketAndStatuses(proj);
  return useMemo(() => {
    const confirmedBucket = buckets.find(b => b.closed) || { statuses: [] };
    const remediatedBucket = buckets.find(b => b.remediated) || { statuses: [] };

    return { confirmedBucket, remediatedBucket };
  }, [buckets]);
};

interface CreateDestinationIssueForTicketOptions {
  entityKey: string;
  projectionId: EntityTypeID;
  onSuccess?: (res: any) => void;
}

interface ActionStatus {
  mode: 'loading' | 'success' | 'error';
  message?: string;
  timedOut?: boolean;
}

export type CreateIssueParams = {
  integrationId: string;
  integrationKey?: string;
  dependency?: Promise<any>;
  destinationName: string;
};

export const useCreateDestinationIssueForTicket = ({
  entityKey,
  projectionId,
  onSuccess = () => {},
}: CreateDestinationIssueForTicketOptions) => {
  const { api } = useAvContext();
  const staticErrorSnackBar = useStaticErrorSnackBar();
  const { setRunInfo } = useContext(NotificationContext);

  const [actionStatus, setActionStatus] = useState<Partial<ActionStatus>>({});

  const createIssue = useCallback(
    ({ integrationId, integrationKey, dependency = Promise.resolve(), destinationName }: CreateIssueParams) => {
      setActionStatus({ mode: 'loading' });
      const requestUrl = integrationKey ? `${apiUrls.CREATE_SINGLE_INTEGRATION}/manual` : apiUrls.CREATE_SINGLE_INTEGRATION;
      return dependency
        .then(() =>
          api(requestUrl, {
            options: { method: 'POST' },
            onError: () => {},
            isWebserver: false,
            body: { entityKey, integrationId, integrationKey, projectionId },
          })
        )
        .then((res: any) => {
          onSuccess(res);
          if (res.attachmentWfResponse) {
            setRunInfo({
              wfId: res.attachmentWfResponse.workflowId,
              runId: res.attachmentWfResponse.runId,
              workflowType: WorkflowType.IntegrationAttachment,
            });
          }
        })
        .then(() => setActionStatus({ mode: 'success' }))
        .catch((e: Error) => {
          setActionStatus({ mode: 'error', message: e.message });
          staticErrorSnackBar({ title: `Create ${destinationName} Ticket Failed`, message: e.message });
        })
        .finally(() => setTimeout(() => setActionStatus(prev => ({ ...prev, timedOut: true })), 3000));
    },
    [entityKey, onSuccess]
  );

  return { createIssue, actionStatus };
};

const mapValueFunc = v => {
  const stringValue = `${v}`;
  return `'${getEscapedValue(stringValue)}'`;
};
export const PAGE_SIZE = 20;
export const queryOrderBy = (map: any[], filterArr?: any[]) => {
  const orderByFields = map
    .filter(({ property }) => !filterArr || filterArr.find(({ id }) => id === property))
    .map(({ property, isAsc }) => `${property} ${isAsc ? 'ASC' : 'DESC'}`)
    .join(', ');
  return map.length ? `ORDER BY ${orderByFields || `${(filterArr?.find(({ isKey }) => isKey) || filterArr?.[0])?.id} ASC`}` : '';
};
const ROW_LIMIT = 40000;

const aliasRegex = /(?: as )/gi;

const getDefaultOrderBy = f => {
  const field = (f && f.split(aliasRegex)?.[1]) || f;
  return [{ property: field }];
};

export const useTicketFieldsQuery = ({
  ticketKey,
  queryKey = 'tab-table',
  where = '',
  page = 0,
  fields = [],
  fieldToExcludeFromExport = [],
  orderBy = getDefaultOrderBy(fields[0]),
  enabled,
  keyPrefix = 'finding',
  groupBy,
  parentEntityPathAlias = 'ticket',
  isCountDistinctValues,
  projectionName,
}: {
  ticketKey: string;
  queryKey?: string;
  where?: string;
  page?: number;
  fields?: string[];
  fieldToExcludeFromExport?: string[];
  orderBy?: any[];
  enabled?: boolean;
  keyPrefix?: string;
  groupBy?: string[];
  parentEntityPathAlias?: string;
  isCountDistinctValues?: boolean;
  projectionName: string;
}) => {
  const {
    measurements,
    featureFlags,
    userPermissions: { isInternalRole },
  } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const refetchOnWindowFocus = featureFlags[FeatureFlags.RefetchTicketOnFocus] ? 'always' : false;
  const shouldCountDistinctValues = isCountDistinctValues;
  const fromTable = projectionName;
  const whereSQL = `${where ? `${where} AND` : 'WHERE'} ${parentEntityPathAlias}._key = ${mapValueFunc(ticketKey)}`;

  const metricsIncludedInFields = useMemo(
    () =>
      measurements.visible.reduce((acc: Set<string>, metric: any) => {
        if (fields.includes(metric.systemName)) {
          acc.add(metric.systemName);
        }

        return acc;
      }, new Set()),
    [fields, measurements]
  );

  const fieldsWithoutMetrics = fields.filter((field: string) => !metricsIncludedInFields.has(field));

  const allSQL = `select ${[
    ...new Set([
      ...fieldsWithoutMetrics,
      ...Array.from(metricsIncludedInFields).map((systemName: string) => `${systemName} as ${systemName}`),
      ...(groupBy ? [] : [`${keyPrefix}._key as avalor_key`]),
    ]),
  ].join(',')}
                  FROM ${fromTable} ${whereSQL}
                      ${groupBy ? `GROUP BY ${groupBy.join(', ')}` : ''}
                      ${queryOrderBy([...orderBy, ...(groupBy ? [] : [{ property: 'avalor_key', isAsc: true }])])}`;

  const exportSql = fieldToExcludeFromExport.length
    ? `select ${[...new Set([...fields.filter((f: string) => !fieldToExcludeFromExport.includes(f)), `${keyPrefix}._key`])].join(',')}
       FROM ${fromTable} ${whereSQL}
           ${groupBy ? `GROUP BY ${groupBy.join(', ')}` : ''}
           ${queryOrderBy([...orderBy, ...(groupBy ? [] : [{ property: `${keyPrefix}._key`, isAsc: true }])])}`
    : allSQL;

  const sql = `AVALOR PARAM total_row_count = true;  ${allSQL} OFFSET ${page * PAGE_SIZE} LIMIT ${PAGE_SIZE}`;

  const onError = isInternalRole
    ? (e: any) => e?.[0]?.message && enqueueSnackbar(`${ErrorTypes.Setup}: ${e?.[0]?.message}`, { variant: 'error' })
    : undefined;
  const { data, isLoading, isRefetching, totals } = useQuerySql({
    key: queryKey,
    sql,
    options: { refetchOnWindowFocus, placeholderData: keepPreviousData, enabled },
    muteErrors: true,
    debounceQueries: false,
    onError,
    showTotals: true,
  });

  const hasNonNullPrefix = 'HAS_NON_NULL_VALUES_';
  const populatedFieldsQuery =
    shouldCountDistinctValues &&
    `SELECT ${fields
      .filter((f: string) => !f.match(aliasRegex))
      .map(
        (f: string) => `MAX(IF(${f} != null, 1, 0)) as "${hasNonNullPrefix}${f}"`
      )} from ${fromTable} WHERE ${parentEntityPathAlias}._key = ${mapValueFunc(ticketKey)}`;

  const { data: distinctValuesByFieldsMap, isLoading: isLoadingCounts } = useQuerySql({
    key: 'tab-table',
    sql: populatedFieldsQuery as string,
    debounceQueries: false,
    onSuccess: (d: any) =>
      Object.keys(d[0]).reduce((acc: any, key: string) => {
        if (key.includes(hasNonNullPrefix)) {
          return { ...acc, [key.replace(hasNonNullPrefix, '')]: d[0][key] };
        }
        return acc;
      }, {}),
    options: { enabled: shouldCountDistinctValues },
  });

  return {
    data,
    isLoading,
    isLoadingCounts,
    isRefetching,
    sql: `${allSQL} LIMIT ${ROW_LIMIT}`,
    exportSql,
    count: totals,
    whereSQL,
    distinctValuesByFieldsMap,
  };
};

export const AlertDestinationsListByProjectionName = (isWorkManagement, projectionName, enabled = false) => {
  const {
    api,
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.notificationTargetsTicketsDropdown, projectionName, enabled, isWorkManagement],
    queryFn: () =>
      api(ALERT_DESTINATIONS_LIST_BY_PROJECTION_NAME, { options: { projectionName } }).then(
        data => data?.data?.findIntegrationInstanceByAccountIdAndProjectionName
      ),

    enabled:
      enabled &&
      (projectionName === entityViewConfig.Incident.projectionName ||
        hasAllowedPermissionToResource({
          resource: PermissionEntitiesNames.INT,
          category: projectionNameToCategoryName[projectionName] as PermissionCategoriesNames,
          permission: Permission.CREATE,
        }) ||
        hasAllowedPermissionToResource({
          resource: PermissionEntitiesNames.INT,
          category: projectionNameToCategoryName[projectionName] as PermissionCategoriesNames,
          permission: Permission.UPDATE,
        })),
  });

  const alertDestinations = useMemo(
    () =>
      data?.filter(
        ({ category, active, integrationType }) =>
          (isWorkManagement
            ? category !== TARGET_MATCHING_CATEGORY_ENUM.WORK_MANAGEMENT || isAnyTargetType(integrationType)
            : [TARGET_MATCHING_CATEGORY_ENUM.WORK_MANAGEMENT, TARGET_MATCHING_CATEGORY_ENUM.EXCEPTIONS].includes(category)) && !!active
      ) || [],
    [data]
  );
  return { alertDestinations, isLoading };
};

export function isAnyTargetType(integrationType) {
  return integrationType === 'ANYTARGET';
}

export const predefinedSavedViewFilters = ({ buckets, pathAlias, projectionName, userName }) => {
  const isTickets = projectionName === entityViewConfig.Ticket.projectionName;
  const now = new Date();
  const confirmedKey = 'closed';
  const notConfirmedStatuses = buckets?.filter(bucket => !bucket[confirmedKey]).flatMap(({ statuses }) => statuses.map(({ name }) => name));
  const confirmedStatuses = buckets?.filter(bucket => bucket[confirmedKey]).flatMap(({ statuses }) => statuses.map(({ name }) => name));
  return {
    active: {
      [`${pathAlias}.current_status.name`]: notConfirmedStatuses,
      ...(isTickets && { [`${pathAlias}.state`]: ['ACTIVE'] }),
    },
    openTickets: {
      [`${pathAlias}.current_status.name`]: notConfirmedStatuses,
    },
    pendingConfirmation: {
      [`${pathAlias}.current_status.name`]: notConfirmedStatuses,
      [`${pathAlias}.state`]: [isTickets ? 'INACTIVE' : 'FULLY_REMEDIATED'],
    },
    overSla: {
      [`${pathAlias}.sla`]: [
        encodeURIComponent(JSON.stringify({ value: { from: new Date('2020/01/01').toISOString(), to: now.toISOString() } })),
      ],
      [`${pathAlias}.current_status.name`]: notConfirmedStatuses,
    },
    closedTickets: {
      [`${pathAlias}.current_status.name`]: confirmedStatuses,
      ...(isTickets && { [`${pathAlias}.state`]: ['ACTIVE', 'INACTIVE'] }),
    },
    missingAssignee: { [`${pathAlias}.assignee_id`]: [null] },
    allTickets: {},
    ticketsWithNoIntegration: {
      [`${pathAlias}.integration_info.key`]: [null],
    },
    newFindings: {
      [`finding.first_seen`]: [
        encodeURIComponent(JSON.stringify({ value: { from: sub(now, { days: 7 }).toISOString(), to: now.toISOString() } })),
      ],
    },
    approachingSla: {
      [`${pathAlias}.sla`]: [
        encodeURIComponent(
          JSON.stringify({
            value: {
              from: now.toISOString(),
              to: add(now, {
                days: 5,
              }).toISOString(),
            },
          })
        ),
      ],
    },
    activeByEntity: {
      [`${pathAlias}.state`]: ['ACTIVE'],
    },
    activeCriticalAndHigh: {
      [`${pathAlias}.state`]: ['ACTIVE'],
      [`${pathAlias}.severity`]: [SeverityLabels.Critical, SeverityLabels.High],
    },
    coverage: {
      [`${pathAlias}.state`]: ['ACTIVE'],
      [`${pathAlias}.type`]: ['Coverage'],
    },
    CMDBHygiene: {
      [`${pathAlias}.state`]: ['ACTIVE'],
      [`${pathAlias}.type`]: ['CMDB Hygiene'],
    },
    newAndActive: {
      [`${pathAlias}.state`]: ['ACTIVE'],
      [`${pathAlias}.created`]: [
        encodeURIComponent(JSON.stringify({ value: { from: sub(now, { days: 7 }).toISOString(), to: now.toISOString() } })),
      ],
    },
    exceptionsReviewer: {
      'reviewer.name': [userName],
    },
    exceptionsRequester: {
      'requester.name': [userName],
    },
  };
};

export const prefixes = {
  IP: 'IPADDRESS ',
  FIX: 'FIX ',
};

const normalizeNumberOrString = value => (typeof value === 'number' ? value : `'${value}'`);

export const useBuildWhereClause = ({
  filters = {},
  extra = [],
  entityTypeId = '',
  fieldMap = {},
  allowAllFields = false,
  allowMetrics = false,
}: {
  filters: Record<string, any>;
  extra?: string[];
  entityTypeId?: string;
  fieldMap?: Record<string, any>;
  allowAllFields?: boolean;
  allowMetrics?: boolean;
}) => {
  const {
    measurements,
    featureFlags,
    accountEntities: { fieldMap: allFieldsMap },
  } = useAvContext();

  const metricsAsFilters = measurements?.visible.reduce((acc, { systemName }) => {
    if (filters[systemName]) {
      acc.add(systemName);
    }

    return acc;
  }, new Set());

  const filtered = Object.keys(filters).filter(
    field =>
      filters[field].length > 0 &&
      !filtersFieldToIgnore.includes(field) &&
      (allowAllFields || !!allFieldsMap[field] || (allowMetrics && metricsAsFilters.has(field)))
  );

  const whereParts = [
    ...filtered.map(fieldNoQuote => {
      const fieldValue = allFieldsMap[fieldNoQuote]?.value || fieldNoQuote;
      const field = fieldValue.includes(' ') ? `"${fieldValue}"` : fieldValue;
      const objectFilter = getIfValidStringifiedObject(filters[fieldNoQuote]?.[0]);
      const fieldType = fieldMap[fieldNoQuote]?.type || allFieldsMap[fieldNoQuote]?.type;
      if (objectFilter) {
        if ([FieldType.DateTime, FieldType.Date].includes(fieldType)) {
          const { value, preset } = objectFilter;
          if (preset) {
            const presetDate = getDatesFromDefinition(preset);
            return `${field} >= '${presetDate.from.toISOString()}' AND ${field} <= '${presetDate.to.toISOString()}'`;
          }
          return value === null ? `${field} = null` : `${field} >= '${value.from}' AND ${field} <= '${value.to}'`;
        }
        const operator = Object.keys(objectFilter)[0];

        return `(${objectFilter[operator]
          .map(({ value, operator: compoundOperator }) => {
            const prefix = prefixes[fieldType] || '';
            const sqlLikeEscape = getEscapedValueForSQLLike(value);
            const [operator, resolution] = compoundOperator.split('|');
            const fieldWithRepeated = resolution && !isNullOperator(operator) ? `${resolution}(${field})` : field;

            if (operator === 'contains') {
              return `${fieldWithRepeated} LIKE '%${sqlLikeEscape}%'`;
            }
            if (operator === 'notContains') {
              return `${fieldWithRepeated} NOT LIKE '%${sqlLikeEscape}%'`;
            }
            if (operator === 'starts') {
              return `${fieldWithRepeated} LIKE '${sqlLikeEscape}%'`;
            }
            if (operator === 'ends') {
              return `${fieldWithRepeated} LIKE '%${sqlLikeEscape}'`;
            }
            if (operator === 'equals') {
              return `${fieldWithRepeated} = ${prefix}${normalizeNumberOrString(value)}`;
            }
            if (operator === 'notEqual') {
              return `${fieldWithRepeated} != ${prefix}'${value}'`;
            }
            if (getOperator(operator) === 'gt') {
              return `${fieldWithRepeated} > ${prefix}${normalizeNumberOrString(value)}`;
            }
            if (getOperator(operator) === 'gte') {
              return `${fieldWithRepeated} >= ${prefix}${normalizeNumberOrString(value)}`;
            }
            if (getOperator(operator) === 'lt') {
              return `${fieldWithRepeated} < ${prefix}${normalizeNumberOrString(value)}`;
            }
            if (getOperator(operator) === 'lte') {
              return `${fieldWithRepeated} <= ${prefix}${normalizeNumberOrString(value)}`;
            }
            if (getOperator(operator) === 'ipRange') {
              return `${fieldWithRepeated} >= ${prefix}'${value.from}' AND ${fieldWithRepeated} <= ${prefix}'${value.to}'`;
            }
            if (getOperator(operator) === 'notIpRange') {
              return `${fieldWithRepeated} < ${prefix}'${value.from}' OR ${fieldWithRepeated} > ${prefix}'${value.to}'`;
            }
            if (getOperator(operator) === 'cidr') {
              return `${fieldWithRepeated} = SUBNET '${value}'`;
            }
            if (getOperator(operator) === 'notCidr') {
              return `${fieldWithRepeated} != SUBNET '${value}'`;
            }
            if (operator === 'equal') {
              return `${fieldWithRepeated} = ${value}`;
            }
            if (operator === 'empty') {
              return `${fieldWithRepeated} = null`;
            }
            if (operator === 'notEmpty') {
              return `${fieldWithRepeated} != null`;
            }
            return '';
          })
          .join(` ${operator.toUpperCase()} `)})`;
      }
      if (fieldType === FieldTypeEnum[FieldType.Boolean]) {
        return `(${filters[fieldNoQuote].map(v => `${field} = ${v}`).join(' OR ')})`;
      }
      const filtersWithOutNull = filters[fieldNoQuote]
        .filter(v => v)
        .map(mapValueFunc)
        .map(v => `${prefixes[fieldType] || ''}${v}`);
      const hasNull = filters[fieldNoQuote].length > filtersWithOutNull.length;
      const result = `(${[
        filtersWithOutNull.length ? `${field} in(${filtersWithOutNull.join(', ')})` : '',
        hasNull ? `${field} = null` : '',
      ]
        .filter(d => d)
        .join(' OR ')})`;
      return featureFlags[FeatureFlags.RemoveBackslashEscapingFromWhere] ? result : result.replace(/\\/g, '\\\\').replace(/\n/g, '\\n');
    }),
  ];

  if (filters.search?.[0]) {
    whereParts.push(
      `(${(entityViewConfig[entityTypeId] as EntityConfigType).searchFields
        ?.map(v => `${v} LIKE '%${getEscapedValue(filters.search[0])}%'`)
        .join(' OR ')})`
    );
  }

  if (filters.searchArray?.length && filters.searchArray[0]) {
    whereParts.push(
      `(${filters.searchArray
        .slice(1)
        .map(v => `${v} LIKE '%${getEscapedValue(filters.searchArray[0])}%'`)
        .join(' OR ')})`
    );
  }
  if (extra) {
    const extraArr = Array.isArray(extra) ? extra : [extra];
    whereParts.push(...extraArr.filter(f => f));
  }
  return whereParts.length ? `where ${whereParts.join(' AND ')}` : '';
};

export function useExportSqlToCsv(sql, headers, origin, exportData = undefined) {
  const { enqueueSnackbar } = useSnackbar();
  const [isWorking, setIsWorking] = useState<number | undefined>();
  const exportToCSV = data => {
    const filteredHeaders = headers.filter(h => !!h.label);
    const csv = arrayToCsv([
      filteredHeaders.map(h => h.label),
      ...data.map(row =>
        filteredHeaders.reduce((acc, { id: key, getValue }) => {
          acc.push(getValue ? getValue(row) : typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]);
          return acc;
        }, [])
      ),
    ]);
    return downloadBlob(csv, 'text/csv;charset=utf-8;', `export-${Date.now()}.csv`);
  };
  const { isLoading } = useQuerySql({
    origin,
    key: `${isWorking}-sql`,
    sql: isWorking ? sql : null,
    debounceQueries: false,
    options: { enabled: !exportData },
    onSuccess: d => {
      try {
        exportToCSV(d);
        setIsWorking(undefined);
      } catch (e) {
        enqueueSnackbar('Export failed', { variant: 'error' });
        console.error(e);
      }
    },
  });

  return { exportToCSV: () => (exportData ? exportToCSV(exportData) : setIsWorking(Date.now())), isLoading };
}

export function useExportQueryObjectToCsv(queryObject, headers, origin, globalFilters) {
  const { enqueueSnackbar } = useSnackbar();
  const [isWorking, setIsWorking] = useState(false);
  const exportToCSV = data => {
    const filteredHeaders =
      headers?.filter(h => !!h.label) ||
      Object.keys(data[0]).reduce<{ id: string; label: string }[]>(
        (acc, key) => (key !== 'id' ? [...acc, { id: key, label: key }] : acc),
        []
      );
    const csv = arrayToCsv([
      filteredHeaders.map(h => h.label),
      ...data.map(row =>
        filteredHeaders.reduce((acc, { id: key, getValue }) => {
          acc.push(getValue ? getValue(row) : row[key]);
          return acc;
        }, [])
      ),
    ]);
    return downloadBlob(csv, 'text/csv;charset=utf-8;', `export-${Date.now()}.csv`);
  };

  const { data, isLoading } = useQueryObject({
    origin,
    queryObject,
    options: { enabled: isWorking },
    contextFilter: globalFilters,
    onSuccess: data => {
      try {
        exportToCSV(data);
      } catch (e) {
        enqueueSnackbar('Export failed', { variant: 'error' });
        console.error(e);
      }
      setIsWorking(false);
      return data;
    },
  });
  useEffect(() => {
    if (data && isWorking) {
      exportToCSV(data);
      setIsWorking(false);
    }
  }, [isWorking]);

  return { exportToCSV: () => setIsWorking(true), isLoading };
}

export const TicketInfoContext = createContext<Record<string, any>>({});

export const buildNotificationTargetsQuery = alertDestinations =>
  alertDestinations
    ?.filter(({ matchingConditionAsSql }) => matchingConditionAsSql)
    .map(alert => `IF(${alert?.matchingConditionAsSql}, true, false) as target_${alert?.id}`)
    .join(',');

export const useUserViews = screenType => {
  const {
    api,
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  const {
    data: userViews,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [QueryKey.Views, screenType],
    queryFn: () => api(SAVED_VIEWS, { options: { screenType } }).then(data => data?.data?.findViewsByUserIdAndAccountIdAndScreenType),
    enabled: !!screenType && hasAllowedPermissionToResource({ resource: PermissionEntitiesNames.VIEWS }),
  });
  return { userViews: userViews || [], isLoading, refetch };
};

export const getHeadCellsWithSort = (headCells, orderBy) =>
  headCells.map(h => {
    const orderByItem = orderBy.find(o => o.property === h.id);
    const sort = orderByItem ? (orderByItem.isAsc ? 'asc' : 'desc') : undefined;
    return { ...h, sort };
  });

export const useBulkIntegration = (projectionId, selectedKeys, onSuccess) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: alertDestinationId =>
      api(apiUrls.UPDATE_INTEGRATION, {
        options: { method: 'PUT' },
        isWebserver: false,
        body: { keys: selectedKeys, alertDestinationId, projectionId },
      }).then(onSuccess(alertDestinationId)),
  });
};
export const useGetFile = ({ url, fileId, isPreview, enabled = true }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [`${fileId}${isPreview}`],
    queryFn: () =>
      api(`${url}&preview=${isPreview}&storedName=${fileId}`, { isWebserver: false, isJson: false }).then(async data => {
        const blob = await data.blob();
        return { dataURL: URL.createObjectURL(blob), blob };
      }),
    enabled: !!fileId && enabled,
  });
};

export const useGetRelatedTickets = ({ ticket, headCells }) => {
  const { entityType, projectionId, ticketKey, entityPathAlias } = ticket;
  const { groupingKeyField } = entityViewConfig[entityType];
  const { data: groupingKeys } = useQuerySql({
    key: 'grouping_keys',
    sql: `SELECT DISTINCT ${groupingKeyField} FROM ${projectionId.name} WHERE ${entityPathAlias}._key = '${ticketKey}'`,
    options: {
      enabled: !!groupingKeyField,
    },
    debounceQueries: false,
    onSuccess: data =>
      data
        .map(({ [groupingKeyField as string]: groupingKey }) => groupingKey && `'${getEscapedValue(groupingKey)}'`)
        .filter(d => d)
        .join(','),
  });
  const relatedTicketsWhereClause = groupingKeyField
    ? `WHERE ${groupingKeyField} in (${groupingKeys}) AND ${entityPathAlias}._key != '${ticketKey}'`
    : '';

  const baseSQL = baseEntitySqlsMap[entityType]({ fields: headCells, where: relatedTicketsWhereClause });
  return useQuerySql({
    key: 'related_tickets',
    sql: baseSQL,
    debounceQueries: false,
    options: {
      enabled: !!(groupingKeys?.length && groupingKeyField),
    },
  });
};

const getGroupByActionsMap = entityTypeId => ({
  [groupByKeys.assignee?.[entityTypeId]]: { sort: groupByKeys.assignee.sort },
  [groupByKeys.severity?.[entityTypeId]]: { sort: groupByKeys.severity.sort, format: groupByKeys.severity.formatter },
  [groupByKeys.sla?.[entityTypeId]]: { sort: groupByKeys.sla.sort, format: groupByKeys.sla.formatter },
  [groupByKeys.firstSeen?.[entityTypeId]]: { sort: groupByKeys.firstSeen.sort, format: groupByKeys.firstSeen.formatter },
  [groupByKeys.avalorStatus?.[entityTypeId]]: { sort: groupByKeys.avalorStatus.sort, format: groupByKeys.avalorStatus.formatter },
  [groupByKeys.created?.[entityTypeId]]: { sort: groupByKeys.created.sort, format: groupByKeys.created.formatter },
  [groupByKeys.type?.[entityTypeId]]: { sort: groupByKeys.type.sort },
  'exception.status': {
    sort: ({ title: a }, { title: b }) => (a === 'Requested' ? -1 : b === 'Requested' ? 1 : a.localeCompare(b)),
  },
});

export const useGetGroupBy = ({
  groupByKey,
  entityTypeId,
  entityName,
  projectionName,
  projections,
  where,
  onSuccess,
  displayNameMapFormatter,
  enabled = false,
}: {
  groupByKey: string;
  entityTypeId: string;
  entityName: string;
  projectionName: string;
  projections: AccountEntities;
  where: string;
  onSuccess?: (options: any[]) => void;
  displayNameMapFormatter?: { [key: string]: (value: any) => string };
  enabled?: boolean;
}) => {
  const { fieldMap } = useAvContext().accountEntities;

  const isKeyDateField = [FieldType.DateTime, FieldType.Date].includes(fieldMap[groupByKey]?.type as any);
  const groupByValuesSql = sqlFormatter(
    `SELECT ${
      groupByKeysQueryProjection(entityTypeId)[groupByKey] || `${groupByKey} as val`
    }, COUNT_DISTINCT(${entityName}._key) as count from ${projectionName} ${where} GROUP BY val ORDER BY val ASC${
      isKeyDateField ? ' LIMIT 1000' : ''
    }`
  );
  const { data, refetch, isLoading } = useQuerySql({
    key: 'group_by_values',
    sql: groupByValuesSql,
    debounceQueries: false,
    options: { enabled: !!groupByKey && enabled },
    onSuccess: groupByValues => {
      const groupByKeyParts = groupByKey?.split('.');
      const keyToRetrieve = groupByKeyParts && groupByKeyParts.length > 1 ? groupByKeyParts.slice(1).join('.') : groupByKey;
      const groupByKeyField =
        projections[projectionName].nameMap[keyToRetrieve] ||
        projections[projectionName].fieldList.INTERACTIVE.find(({ pathAlias }) => pathAlias === keyToRetrieve)?.title;
      const optionsMap = groupByValues
        ? groupByValues.reduce((acc, row) => {
            const keys = Array.isArray(row.val) ? row.val : [row.val];
            keys.forEach(key => {
              const rawKey = key;
              const title =
                getGroupByActionsMap(entityTypeId)?.[groupByKey]?.format?.(rawKey) ||
                displayNameMapFormatter?.[groupByKey]?.(key) ||
                rawKey ||
                `No ${groupByKeyField}`;

              acc[rawKey] = { title, value: key, count: (acc[rawKey]?.count || 0) + row.count };
            });
            return acc;
          }, {})
        : {};
      const options = Object.values(optionsMap);
      options.sort(getGroupByActionsMap(entityTypeId)?.[groupByKey]?.sort);
      onSuccess?.(options);
      return options;
    },
  });
  return { data, refetch, isLoading };
};

export const useGetShouldShowTabs = ({
  tabs = [],
  ticketKey,
  projectionName,
  entityPathAlias,
}: {
  tabs: TTab[];
  ticketKey: string;
  projectionName: string;
  entityPathAlias: string;
}) => {
  const shouldShowTabsFieldsMap = tabs.reduce((acc, { name, shouldShowTabField }) => {
    const nameWithoutSpaces = name.replaceAll(' ', '');
    return {
      ...acc,
      ...(shouldShowTabField && { [nameWithoutSpaces]: shouldShowTabField }),
    };
  }, {});

  const shouldShowTabsSql = `SELECT ${Object.keys(shouldShowTabsFieldsMap)
    .map(tabName => `${shouldShowTabsFieldsMap[tabName]} AS "${tabName}"`)
    .join(', ')} FROM ${projectionName} WHERE ${entityPathAlias}._key='${getEscapedValue(ticketKey)}'`;

  return useQuerySql({
    key: 'hidden-tabs',
    sql: shouldShowTabsSql,
    onSuccess: data => data?.[0] || {},
    options: {
      enabled: !!Object.keys(shouldShowTabsFieldsMap).length && !!ticketKey,
    },
  });
};

export const useCreateIncident = onCreateIncidentSuccess => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: (alertKeys: string[]) =>
      api(CREATE_INCIDENT, {
        options: { alertKeys },
      }).then(({ data }) => onCreateIncidentSuccess(data?.createManualIncident?.[0])),
  });
};

export const useGetSourcesValues = ({ projectionId, fieldName, entityPathAlias, avalorKey, field, showAll, projAliasPath }) => {
  const defaultValue = { sources: [], totalCount: 0 };

  const { data: { sources, totalCount } = defaultValue, isLoading } = useQueryObject({
    onSuccess: d =>
      d?.[0]
        ? {
            ...d?.[0],
            sources: d?.[0].sources
              .map(({ value, items }) => ({ value, source: items[0] }))
              .sort((a, b) => a.source.localeCompare(b.source)),
          }
        : defaultValue,
    options: { enabled: !!field.ingressSources, placeholderData: keepPreviousData },
    queryObject: generateSourcesValuesQuery({
      projectionId,
      fieldName,
      entityPathAlias,
      projAliasPath,
      avalorKey,
      sources: field.ingressSources,
      showAll,
    }),
  });
  return { sources, totalCount, isLoading };
};

export const useSplitEntities = ({ onSuccess = d => d }) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({
      name,
      sourceProjectionID,
      sourceProjectionKeys,
      splitStrategy = 'ALL',
      queryWhereClause = null,
      operation = 'SPLIT',
    }: {
      name: string;
      sourceProjectionID: EntityTypeID;
      sourceProjectionKeys?: string[];
      splitStrategy: string;
      queryWhereClause?: null | string;
      operation?: string;
    }) =>
      api(SPLIT_ENTITIES, {
        options: {
          splitEntitiesRequest: {
            entityName: name,
            sourceProjectionID,
            sourceProjectionKeys,
            splitStrategy,
            queryWhereClause,
            operation,
          },
        },
        onSuccess: ({ data }) => onSuccess(data.splitEntities),
      }),
  });
};

export const useMergeEntities = ({ onSuccess = d => d }) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({ title, keys, projectionId }: { title: string; keys: string[]; projectionId: EntityTypeID }) =>
      api(MERGE_ENTITIES, {
        options: {
          mergeEntitiesRequest: {
            keys,
            projectionID: projectionId,
            overrideFields: {
              title,
            },
          },
        },
        onSuccess: ({ data }) => onSuccess(data.mergeEntities),
      }),
  });
};

export const useGetEntityActions = (projectionName, ignorePermissions = false) => {
  const {
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  const entityConfig = Object.values(entityViewConfig).find(({ projectionName: p }) => p === projectionName);
  const actionsMap = Object.values(entityConfig?.actions || {}).reduce((acc, curr) => ({ ...acc, ...curr }), {});
  return Object.keys(actionsMap).reduce((acc, act) => {
    if (
      ignorePermissions
        ? !!actionsMap[act]
        : !!actionsMap[act] &&
          (getActionsPermissionConfig(projectionName)[act]
            ? hasAllowedPermissionToResource({
                category: projectionNameToCategoryName[projectionName] as PermissionCategoriesNames,
                ...getActionsPermissionConfig(projectionName)[act],
              })
            : true)
    ) {
      return { ...acc, [act]: true };
    }
    return { ...acc, [act]: false };
  }, {});
};

const SPLIT_ENTITIES = gql`
  mutation ($splitEntitiesRequest: SplitEntitiesRequest!) {
    splitEntities(splitEntitiesRequest: $splitEntitiesRequest) {
      wfId
      runId
    }
  }
`;

const MERGE_ENTITIES = gql`
  mutation ($mergeEntitiesRequest: MergeEntitiesRequest!) {
    mergeEntities(mergeEntitiesRequest: $mergeEntitiesRequest) {
      wfId
      runId
    }
  }
`;

const SAVED_VIEWS = gql`
  query findViewsByUserIdAndAccountIdAndScreenType($screenType: ScreenType!) {
    findViewsByUserIdAndAccountIdAndScreenType(screenType: $screenType) {
      id
      name
      screenType
      config
      publicView
      createdByUserId
      createdAt
      favorite
      defaultView
      global
      accountDefaultView
    }
  }
`;

const ALERT_DESTINATIONS_LIST_BY_PROJECTION_NAME = gql`
  query findIntegrationInstanceByAccountIdAndProjectionName($projectionName: String!) {
    findIntegrationInstanceByAccountIdAndProjectionName(projectionName: $projectionName) {
      id
      name
      active
      integrationType
      matchingConditionAsSql
      targetMatchingType
      category
      redactedConfig
      projectionName
      redactedAuthConfig
    }
  }
`;

const CREATE_INCIDENT = gql`
  mutation createManualIncident($alertKeys: [String]!) {
    createManualIncident(alertKeys: $alertKeys) {
      wfId
      runId
    }
  }
`;
