import React, { useState } from 'react';
import { Box, Collapse, IconButton, useTheme } from '@mui/material';
import { ExpandCard } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import { ReactComponent as Error } from '../assets/colorful/Error_full.svg';
import { ReactComponent as Success } from '../assets/colorful/Success_full.svg';
import { ReactComponent as Warning } from '../assets/colorful/Warning_full_orange.svg';
import { ReactComponent as Neutral } from '../assets/QuestionFull.svg';
import { ReactComponent as X } from '../assets/X.svg';

interface Props {
  variant: AttentionBoxVariant;
  content: string | React.ReactElement;
  collapseContent?: string | React.ReactElement;
  showIcon?: boolean;
  small?: boolean;
  onClick?: () => void;
  onDismiss?: () => void;
  icon?: React.JSX.Element;
  sx?;
}

const AvAttentionBox: React.FC<Props> = ({
  variant,
  content,
  showIcon = true,
  small = false,
  onClick,
  collapseContent,
  onDismiss,
  icon,
  sx,
}) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const bgVariant = {
    success: palette.colors.positive[100],
    error: palette.colors.negative[150],
    neutral: palette.colors.neutrals[150],
    warning: palette.colors.orange[100],
  };

  const iconVariant = {
    success: <Success />,
    error: <Error />,
    neutral: <Neutral />,
    warning: <Warning />,
  };

  return isDismissed ? null : (
    <Box
      onClick={() => {
        onClick?.();
      }}
      sx={{
        cursor: onClick ? 'pointer' : 'inherit',
        py: small ? 1 : '10px',
        px: 2,
        background: bgVariant[variant],
        ...flex.col,
        borderRadius: 2,
        ...sx,
      }}>
      <Box sx={{ ...flex.itemsStart, gap: 1 }}>
        {showIcon && (icon || iconVariant[variant])}
        <Box sx={{ flex: 1 }}>{content}</Box>
        {collapseContent && <ExpandCard expand={open} onClick={() => setOpen(!open)} />}
        {onDismiss && (
          <IconButton
            onClick={() => {
              onDismiss();
              setIsDismissed(true);
            }}>
            <X />
          </IconButton>
        )}
      </Box>
      {collapseContent ? <Collapse in={open}>{collapseContent}</Collapse> : null}
    </Box>
  );
};

export default AvAttentionBox;

export enum AttentionBoxVariant {
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
  Neutral = 'neutral',
}
