import { MutationOptions, QueryObserverOptions, useMutation, useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { shouldSkipUILogin } from '@utils/auth.utils';
import { useAvContext } from '../context/AvContextProvider';
import { isTokenExpired } from '../hooks/auth';

export const useAvQuery = <TData = unknown, TError = unknown>(options: QueryObserverOptions<TData, TError>) => {
  const isEnabled = useReturnQueryData(options);
  return useQuery<TData, TError>({ ...options, enabled: isEnabled });
};

export const useAvQueries = <TData extends unknown[] = unknown[], TError extends unknown[] = unknown[]>({
  queries,
}: {
  queries: QueryObserverOptions<unknown>[];
}): UseQueryResult<TData[number], TError[number]>[] => {
  const queriesWithEnabled = useReturnQueriesData(queries);

  return useQueries({ queries: queriesWithEnabled });
};

export const useAvMutation = <TData = unknown, TError = unknown, TVariables = void>(options: MutationOptions<TData, TError, TVariables>) =>
  useMutation<TData, TError, TVariables>(options);

const useReturnQueryData = (options: any) => {
  const { accountId, userIsActive, isLoading } = useAvContext();
  return isEnabled(userIsActive, accountId, options, isLoading);
};

const useReturnQueriesData = (queries: any) => {
  const { accountId, userIsActive, isLoading } = useAvContext();
  return queries.map(query => ({
    ...query,
    enabled: isEnabled(userIsActive, accountId, query, isLoading),
  }));
};

const isEnabled = (userIsActive, accountId, options, isLoading) =>
  (options.enabled ?? true) && (shouldSkipUILogin() || (userIsActive && !isTokenExpired(accountId) && !isLoading));
