import { createFileRoute } from '@tanstack/react-router';
import { PAGE_PATHS } from '../../../../../types';

const path = PAGE_PATHS.FACTOR;
export const Route = createFileRoute('/$accountId/risk360/factors/$id/')({
  staticData: {
    path,
    key: 'Factor',
  },
});
