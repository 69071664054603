import React from 'react';
import { Box } from '@mui/material';
import { flex } from './AvThemeProvider';
import { ReactComponent as Spinner } from '../assets/Spinner.svg';

const animateSpinner = {
  '@keyframes spin': { '0%': { transform: 'rotate(0deg)' }, '100%': { transform: 'rotate(360deg)' } },
};
interface LoadingProps {
  height?: number;
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({ height = 56, color }) => (
  <Box sx={{ ...flex.center, animateSpinner, height, width: height }}>
    <Spinner
      style={{
        animation: 'spin 1.7s linear infinite',
        color,
        height,
        width: height,
      }}
    />
  </Box>
);

export default Loading;
