import React from 'react';
import { ArrayResolution, Filter, RelationsProps, StringConditionType } from '../../types/filter.types';
import { emptyObject } from '../../utils/Utils';
import { useGetValuesOptions } from '../../views/Logs/hooks';
import { FilterInput } from '../FieldFilter';
import Select from '../Select';
import {
  booleanOptions2,
  ConditionType,
  FilterOption,
  getCondition,
  getRelationalExpressionProps,
  isNullOperator,
  selectorWidth,
} from './Utils';

interface StringNumberTypeBoxProps {
  filter: Filter;
  setFilter: any;
  size: any;
  isVertical: boolean;
  typeCondition: ConditionType.string | ConditionType.number | ConditionType.bool | ConditionType.field;
  options: FilterOption[];
  supportParser?: boolean;
  autocompleteFilter?: Date[];
  disabled?: boolean;
  relationsProps: RelationsProps;
}
const FilterTypeBox: React.FC<StringNumberTypeBoxProps> = ({
  filter,
  setFilter,
  size,
  isVertical,
  typeCondition,
  options,
  supportParser,
  autocompleteFilter,
  disabled = false,
  relationsProps = emptyObject,
}) => {
  const compoundOperator = getCondition(filter, typeCondition, supportParser ? StringConditionType.EQUALS : undefined);
  const [operator, resolution = ArrayResolution.ANY] = compoundOperator.split('|');
  const { additionalSelectOptions = undefined, enableCaseSensitive = false, enableSide = false } = relationsProps;

  const innerExpression = filter.expression?.arrayCondition?.underlying || filter.expression;
  const setValue = value => {
    const underlying = {
      fieldName: innerExpression?.fieldName,
      [typeCondition]: { [operator]: value },
      ...getRelationalExpressionProps({ operator, typeCondition, enableCaseSensitive, enableSide }),
    };
    return setFilter({
      ...filter,
      expression: filter.expression?.arrayCondition ? { arrayCondition: { underlying, resolution } } : underlying,
    });
  };

  const value = isNullOperator(operator) ? '' : innerExpression?.[typeCondition]?.[operator];

  const width = isVertical ? selectorWidth : 250;
  const isDisabled = isNullOperator(operator) || !innerExpression?.fieldName || disabled;
  const commonProps = {
    size,
    disabled: isDisabled,
    value,
    onChange: setValue,
    placeholder: isDisabled ? '' : 'Type Value',
  };

  const enableAutoCompleteValue =
    !!supportParser &&
    [StringConditionType.EQUALS, StringConditionType.NOT_EQUAL].includes(operator as StringConditionType) &&
    !!innerExpression?.fieldName;
  const { data: openSelectOptions, isLoading } = useGetValuesOptions({
    fieldName: innerExpression?.fieldName,
    enabled: enableAutoCompleteValue,
    autocompleteFilter,
  });
  return typeCondition === ConditionType.bool || typeCondition === ConditionType.field ? (
    <Select
      {...commonProps}
      style={{ width }}
      isRequired
      muiProps={{ disablePortal: true }}
      options={[
        ...(typeCondition === ConditionType.bool ? booleanOptions2 : additionalSelectOptions),
        ...(isNullOperator(operator) ? [{ title: '', value: '' }] : []),
      ]}
    />
  ) : (
    <FilterInput
      {...commonProps}
      inputWidth={width}
      numberField={typeCondition === ConditionType.number}
      operator={compoundOperator}
      options={options}
      optionsUseQueryProps={enableAutoCompleteValue ? { options: openSelectOptions, isLoading } : undefined}
      skeletonLoading
    />
  );
};

export default FilterTypeBox;
