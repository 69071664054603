import React from 'react';
import { flex } from '@components/AvThemeProvider';
import { isModuleNotFoundError, NotFound } from '@components/ErrorBoundary';
import Loading from '@components/Loading';
import { Box } from '@mui/material';
import { createRouter } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import { ErrorTypes } from './types/query.types';
import SomethingWentWrong from './assets/emptyStates/Something went Wrong.svg';

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => <NotFound />,
  defaultErrorComponent: error => {
    if (isModuleNotFoundError(error.error)) {
      return (
        <Box sx={flex.colCenter}>
          <Loading />
        </Box>
      );
    }
    return (
      <NotFound
        text="Something Went Wrong..."
        image={SomethingWentWrong}
        buttonProps={
          error.error.message === ErrorTypes.AvContext
            ? {
                variant: 'contained',
                children: 'Refresh to reload',
                onClick: () => window.location.reload(),
                sx: { fontWeight: 600 },
              }
            : undefined
        }
      />
    );
  },
  defaultPreload: 'intent',
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export default router;
