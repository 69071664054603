import { useAvContext } from '../context/AvContextProvider';
import { DwQueryRequest } from '../views/CustomDashboards/types';
import useQueryObject from './useQueryObjectSql';

const useGetAccountSources = ({ projName }: { projName: string }) => {
  const {
    accountEntities: { aggProjs },
  } = useAvContext();
  const { pathAlias } = aggProjs[projName];
  const sourcesField = `${pathAlias}.source_names`;
  const sourcesQuery: DwQueryRequest = {
    select: {
      dims: [
        {
          name: `explode(${sourcesField})`,
          alias: 'name',
        },
      ],
      metrics: [],
    },
    groupBy: [`explode(${sourcesField})`],
    filter: {
      expression: {
        fieldName: 'name',
        stringCondition: {
          notEmpty: {},
        },
      },
    },
    distinct: true,
    projectionId: {
      name: projName,
      builtIn: true,
    },
    sorting: [],
  };

  const { isLoading: isLoadingDataSources, data: dataSources = [] } = useQueryObject({
    queryObject: sourcesQuery,
  });

  return { isLoadingDataSources, dataSources };
};

export default useGetAccountSources;
