import { addMinutes, format, isValid } from 'date-fns';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { FilterTypes, mapAliasesAndMetrics } from '../../utils/mapping';
import { SEVERITY_LABELS } from '../../utils/severity.utils';

export const formatAsUTC = (value, formatWithTime = false, keepSlaWithoutFormat = false) => {
  const utcTime = addMinutes(new Date(value), new Date(value).getTimezoneOffset());
  const date = new Date(value);
  return formatWithTime
    ? (keepSlaWithoutFormat ? date : utcTime).toLocaleString()
    : format(keepSlaWithoutFormat ? date : utcTime, 'MMM dd, yyyy');
};

export const getFieldLabel = ({ field, fieldMap, projectionName }) =>
  fieldMap[field] ? (projectionName === fieldMap[field].mainProjId?.name ? fieldMap[field].displayName : fieldMap[field].title) : field;

export const actionCallback =
  (onSuccess, queryClient) =>
  (...args) => {
    onSuccess(...args);
    queryClient.resetQueries({ queryKey: ['ticket-info'] });
  };

export const StepsEnum = {
  NONE: 0,
  NEW: 5,
  OPENED: 10,
  ACKNOWLEDGED: 20,
  REMEDIATED: 30,
  DISPUTED: 40,
  NOT_CONFIRMED: 50,
  CONFIRMED: 60,
};

export const STATUS_LABELS = {
  [StepsEnum.NONE]: 'None',
  [StepsEnum.NEW]: 'Discovered',
  [StepsEnum.OPENED]: 'Opened',
  [StepsEnum.ACKNOWLEDGED]: 'Acknowledged',
  [StepsEnum.REMEDIATED]: 'Remediated',
  [StepsEnum.NOT_CONFIRMED]: 'Not Confirmed',
  [StepsEnum.CONFIRMED]: 'Confirmed',
};

export const ORDERED_TICKET_STATUS = [
  STATUS_LABELS[StepsEnum.NEW],
  STATUS_LABELS[StepsEnum.OPENED],
  STATUS_LABELS[StepsEnum.ACKNOWLEDGED],
  STATUS_LABELS[StepsEnum.REMEDIATED],
  STATUS_LABELS[StepsEnum.CONFIRMED],
  STATUS_LABELS[StepsEnum.NOT_CONFIRMED],
];

export const explodeFields = () => ({
  [FilterTypes.AssetV2]: { select: 'asset._key', group: 'asset._key' },
  [FilterTypes.AssetTypeV2]: { select: 'asset.type', group: 'asset.type' },
  [FilterTypes.TagsV2]: { select: 'EXPLODE(ticket.tags) as tag', group: 'tag' },
});

export const drawerWidth = 1100;

const sortedSeverities = [
  SEVERITY_LABELS.CRITICAL,
  SEVERITY_LABELS.HIGH,
  SEVERITY_LABELS.MEDIUM,
  SEVERITY_LABELS.LOW,
  SEVERITY_LABELS.INFO,
];

export const fieldPurposeMap = {
  // TODO change to field names
  uber_findings: {
    sources: 'Sources',
    type: 'Type',
    title: 'Title',
    severity: 'Severity',
    score: 'Severity score',
    state: 'State',
  },
  uber_assets: {
    sources: 'Sources',
    type: 'Type',
    title: 'Name',
    score: 'Risk Score',
    state: 'State',
  },
  tickets: {
    sources: 'Sources',
    type: 'Type',
    title: 'Title',
    severity: 'Severity',
    score: 'Severity score',
    state: 'State',
  },
  uber_global_vulnerabilities: {
    sources: 'Sources',
    type: 'Type',
    title: 'CVE',
    score: 'CVSS 3.1 Base Score',
    state: 'State',
  },
  uber_org_entities: {
    sources: 'Sources',
    type: 'Type',
    title: 'Name',
    state: 'State',
  },
  uber_components: {
    sources: 'Sources',
    type: 'Type',
    title: 'Name',
    state: 'State',
  },
  uber_component_instances: {
    sources: 'Sources',
    type: 'Type',
    title: 'ID',
    state: 'State',
  },
  uber_alerts: {
    sources: 'Sources',
    type: 'Type',
    title: 'Title',
    severity: 'Severity',
    score: 'Severity Score',
    state: 'State',
  },
  incidents: {
    sources: 'Sources',
    type: 'Type',
    title: 'Title',
    severity: 'Severity',
    score: 'Severity Score',
    state: 'State',
  },
  uber_alert_actors: {
    sources: 'Sources',
    type: 'Type',
    title: 'Title',
    state: 'State',
  },
  uber_applications: {
    sources: 'Sources',
    type: 'Type',
    title: 'Name',
    state: 'State',
  },
  default: {
    sources: 'Sources',
    type: 'Type',
    title: 'Name',
    state: 'State',
  },
};

export const groupByKeys = {
  assignee: {
    Ticket: 'ticket.assignee_id',
    Incident: 'incident.assignee_id',
    Asset: 'asset.owner_id',
    sort: ({ title: a }, { title: b }) => {
      const keyToSortFirst = 'No Assignee';
      if ((a === keyToSortFirst) !== (b === keyToSortFirst)) {
        return a === keyToSortFirst ? -1 : 1;
      }
      return a > b ? 1 : a < b ? -1 : 0;
    },
  },
  sla: {
    Ticket: 'ticket.sla',
    Incident: 'incident.sla',
    sort: ({ title: a }, { title: b }) => {
      const keyToSortFirst = 'No SLA';
      if ((a === keyToSortFirst) !== (b === keyToSortFirst)) {
        return a === keyToSortFirst ? 1 : -1;
      }
      return +new Date(a) > +new Date(b) ? 1 : +new Date(a) < +new Date(b) ? -1 : 0;
    },
    formatter: v => (isValid(new Date(v)) ? formatAsUTC(v) : 'No SLA'),
  },
  avalorStatus: {
    Ticket: 'ticket.current_status.name',
    Incident: 'incident.current_status.name',
    sort: ({ title: a }, { title: b }) => {
      const statuses = ORDERED_TICKET_STATUS.map(v => v);
      return statuses.indexOf(a) - statuses.indexOf(b);
    },
    formatter: v => STATUS_LABELS[StepsEnum[v]],
  },
  firstSeen: {
    Ticket: 'ticket.first_seen',
    Asset: 'asset.first_seen',
    sort: ({ title: a }, { title: b }) => (+new Date(a) > +new Date(b) ? 1 : +new Date(a) < +new Date(b) ? -1 : 0),
    formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : 'Unknown'),
  },
  created: {
    Ticket: 'ticket.created',
    Incident: 'incident.created',
    Asset: 'asset.created',
    sort: ({ title: a }, { title: b }) => (+new Date(a) > +new Date(b) ? 1 : +new Date(a) < +new Date(b) ? -1 : 0),
    formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : 'Unknown'),
  },
  severity: {
    Ticket: 'ticket.severity',
    Incident: 'incident.severity',
    sort: ({ title: a }, { title: b }) => sortedSeverities.indexOf(a) - sortedSeverities.indexOf(b),
    formatter: v => SEVERITY_LABELS[v],
  },
  type: {
    Ticket: 'ticket.type',
    Incident: 'incident.type',
    Asset: 'asset.type',
    sort: ({ title: a }, { title: b }) => a - b,
  },
};

export const groupByKeysQueryProjection = entityTypeId => ({
  [groupByKeys.assignee?.[entityTypeId]]: `${groupByKeys.assignee?.[entityTypeId]} as val`,
  [groupByKeys.sla?.[entityTypeId]]: `DAY(${groupByKeys.sla?.[entityTypeId]}) AS val`,
  [groupByKeys.type?.[entityTypeId]]: `${groupByKeys.type?.[entityTypeId]} as val`,
  [groupByKeys.avalorStatus?.[entityTypeId]]: `${groupByKeys.avalorStatus?.[entityTypeId]} as val`,
  ...(entityTypeId === entityViewConfig.Ticket.entityTypeId
    ? { [groupByKeys.firstSeen?.[entityTypeId]]: `DAY(${groupByKeys.firstSeen?.[entityTypeId]}) as val` }
    : {}),
});

type SqlStringParams = {
  fields: string[];
  headCellsFields?: string[];
  where?: string;
  isExport?: boolean;
  metrics?: { systemName: string }[];
};
export const baseTicketSql = ({ fields = [], where = '', isExport = false, metrics = [] }: SqlStringParams) => `select  
                  ${
                    isExport
                      ? fields.join(', ')
                      : `ticket.created as created
                  ${fields.includes('ticket._key') ? '' : ', ticket._key'} 
                  ${fields.includes('ticket.type') ? '' : ', ticket.type'} 
                  ${fields.includes('ticket.integration_info.key') ? '' : ', ticket.integration_info.key'} 
                  ${!fields.includes('ticket.state') ? ', ticket.state' : ''} 
                  ${fields.length ? `, ${fields.join(', ')}` : ''}`
                  }
                 ${fields.length && metrics?.length ? ', ' : ''} ${metrics
                   ?.map(({ systemName }) => `${systemName} as ${systemName}`)
                   .join(', ')}
                                                                                                        from tickets 
                  ${where}`;

export const baseIncidentSql = ({ fields = [], where = '', isExport = false, metrics = [] }: SqlStringParams) => `select 
                ${
                  isExport
                    ? fields.join(', ')
                    : `incident.created as created
                ${fields.includes('incident._key') ? '' : ', incident._key'} 
                ${fields.includes('incident.type') ? '' : ', incident.type'} 
                ${fields.includes('incident.integration_info.key') ? '' : ', incident.integration_info.key'} 
                ${fields.length ? `, ${fields.join(', ')}` : ''}`
                }
                  ${fields.length && metrics?.length ? ', ' : ''} ${metrics
                    ?.map(({ systemName }) => `${systemName} as ${systemName}`)
                    .join(', ')}
                from incidents
                ${where}`;

export const baseAssetSql = ({ fields = [], where = '', headCellsFields = [], metrics = [] }: SqlStringParams) => `select
                asset.created as created,
                ${mapAliasesAndMetrics.severityScore.metric} as ${mapAliasesAndMetrics.severityScore.alias},
                ${mapAliasesAndMetrics.totalFindings.metric} as ${mapAliasesAndMetrics.totalFindings.alias},
                critical_active_findings_granular AS CRITICAL,
                high_active_findings_granular AS HIGH,
                medium_active_findings_granular AS MEDIUM,
                low_active_findings_granular AS LOW,
                info_active_findings_granular AS NONE
                ${fields.includes('asset._key') ? '' : ', asset._key'}
                ${fields.length ? `, ${fields.join(', ')}` : ''}
                ${fields.length && metrics?.length ? ', ' : ''} ${metrics
                  ?.map(({ systemName }) => `${systemName} as ${systemName}`)
                  .join(', ')}
                from uber_assets
                ${where}
                group by created
                ${headCellsFields.includes('asset._key') ? '' : ', asset._key'}
                ${headCellsFields.includes('asset.type') ? '' : ', asset.type'}
                ${headCellsFields.length ? `, ${headCellsFields.join(', ')}` : ''}`;

export const baseAlertSql = ({ fields = [], where = '', isExport = false, metrics = [] }: SqlStringParams) => `select
                ${
                  isExport
                    ? fields.join(', ')
                    : `
                alert.created as created,
                alert.severity as severity,
                alert.grouping_key as grouping_key
                ${fields.includes('alert._key') ? '' : ', alert._key'} 
                ${fields.includes('alert.type') ? '' : ', alert.type'} 
                ${!fields.includes('alert.state') ? ', alert.state' : ''} 
                ${fields.length ? `, ${fields.join(', ')}` : ''}`
                }
                ${fields.length && metrics.length ? ', ' : ''} ${metrics
                  .map(({ systemName }) => `${systemName} as ${systemName}`)
                  .join(', ')}
                from uber_alerts 
                ${where}`;

export const baseFindingSql = ({ fields = [], where = '', isExport = false, metrics = [] }: SqlStringParams) => `select
            ${
              isExport
                ? fields.join(', ')
                : `
            finding.created as created
            ${fields.includes('finding._key') ? '' : ', finding._key'}
            ${fields.includes('finding.type') ? '' : ', finding.type'} 
            ${!fields.includes('finding.state') ? ', finding.state' : ''} 
            ${fields.length ? `, ${fields.join(', ')}` : ''}`
            }
            ${fields.length && metrics?.length ? ', ' : ''} ${metrics?.map(({ systemName }) => `${systemName} as ${systemName}`).join(', ')}
            from uber_findings
           ${where}`;

export const baseFactorSql = ({ fields = [], where = '', isExport = false }: SqlStringParams) => `select
           ${
             isExport
               ? fields.join(', ')
               : `
               factor.created as created, 
               factor.type as type, 
               factor.group_id as groupId, 
               factor.effective_normalized_score as scoreLeft,
               factor_normalized_max_score_granular as scoreRight
                ${fields.includes('factor._key') ? '' : ', factor._key'} 
                ${fields.length ? `, ${fields.join(', ')}` : ''}`
           }  from uber_factors
         ${where}`;

export const basePolicyPopulationSql = ({ fields = [], where = '', isExport = false }: SqlStringParams) => `select
           ${
             isExport
               ? fields.join(', ')
               : `
               policy_population.created as created, 
               policy_population.type as type,
               policy_population.severity
               ${fields.includes('policy_population.integration_info.key') ? '' : ', policy_population.integration_info.key'} 
               ${fields.includes('policy_population._key') ? '' : ', policy_population._key'} 
               ${fields.length ? `, ${fields.join(', ')}` : ''}`
           }  from uber_policy_populations
         ${where}`;

export const basePolicyViolationSql = ({ fields = [], where = '', isExport = false }: SqlStringParams) => `select
           ${
             isExport
               ? fields.join(', ')
               : `
               policy_violation.created as created, 
               policy_violation.type as type,
               policy_violation.severity
               ${fields.includes('policy_violation.integration_info.key') ? '' : ', policy_violation.integration_info.key'} 
               ${fields.includes('policy_violation._key') ? '' : ', policy_violation._key'} 
               ${fields.length ? `, ${fields.join(', ')}` : ''}`
           }  from uber_policy_violations
         ${where}`;

export const baseExceptionsSql = ({ fields = [], where = '', isExport = false }: SqlStringParams) => `select
           ${
             isExport
               ? fields.join(', ')
               : `
               exception.created as created
                ${fields.includes('exception._key') ? '' : ', exception._key'} 
                ${fields.length ? `, ${fields.join(', ')}` : ''}`
           }  from uber_exceptions
         ${where}`;

export const getInitialCustomHeadCells = ({ projectionName, extraRelationGroup, aggProjs, getType }) => {
  const filteredFieldList =
    extraRelationGroup === '*'
      ? aggProjs[projectionName].fieldList.INTERACTIVE
      : aggProjs[projectionName].fieldList.INTERACTIVE.filter(({ group }) => group === extraRelationGroup);
  return filteredFieldList.map(({ name, value, title }) => ({
    id: value,
    label: title || name,
    hidden: true,
    type: getType(name),
  }));
};

// TODO: move baseEntitySqlsMap to entityViewConfig
export const baseEntitySqlsMap = {
  [entityViewConfig.Ticket.entityTypeId]: baseTicketSql,
  [entityViewConfig.Incident.entityTypeId]: baseIncidentSql,
  [entityViewConfig.Asset.entityTypeId]: baseAssetSql,
  [entityViewConfig.Alert.entityTypeId]: baseAlertSql,
  [entityViewConfig.Finding.entityTypeId]: baseFindingSql,
  [entityViewConfig.Factor.entityTypeId]: baseFactorSql,
  [entityViewConfig.PolicyPopulation.entityTypeId]: basePolicyPopulationSql,
  [entityViewConfig.PolicyViolation.entityTypeId]: basePolicyViolationSql,
  [entityViewConfig.Exception.entityTypeId]: baseExceptionsSql,
};

const bulkPrefix = 'notification/ticket/bulk/';
export const apiUrls = {
  UPDATE_INTEGRATION: `${bulkPrefix}integration`,
  UPDATE_FIELDS: `${bulkPrefix}multi-fields`,
  CREATE_SINGLE_INTEGRATION: 'notification/ticket/integration',
  ADD_ATTACHMENT: 'notification/entity/metadata/attachment',
  UNLINK_INTEGRATION: 'notification/ticket/integration/unlink',
};

export const statusField = 'current_status.name';
export const filtersFieldToIgnore = [
  'tablePage',
  'orderBy',
  'search',
  'searchArray',
  'groupByKey',
  'tab',
  'ticketCategory',
  'ticket.detection_sources',
  'viewId',
  'activeEntityId',
  'investigationMode',
  'headCells',
  'expandCharts',
  'date',
  'dataSelectTypes',
  'isEditWidgetOpen',
  'isEditMode',
  'isCurrentTime',
  'activeProjName',
  'timeRange',
  'forceRender',
];
export const staticFiltersFields = ['ticket.is_fixable', 'ticket.last_status.status', 'ticket.severity'];
export const staticFiltersFieldsIncidents = ['incident.is_fixable', 'incident.last_status.status', 'incident.severity'];
export const staticFiltersFieldsV2 = ['is_fixable', 'current_status.name', 'severity', 'severity', 'source_names'];

export const getNestedFieldObj = (field, value) => ({ [field]: { name: value, timestamp: new Date().toISOString() } });

export const ASSET_TABLE_AVALOR_KEY = 'asset_avalor_key';
export const SCORE_EXPLANATION_FIELD = 'finding.score_factors';
export const FINDING_SEVERITY = 'finding.severity';
export const ALERT_SEVERITY = 'alert.severity';
export const FINDING_SEVERITY_SCORE = 'finding.severity_score';
export const ALERT_SEVERITY_SCORE = 'alert.severity_score';
export const FINDING_ORIGINAL_SEVERITY = 'finding.original_severity';
export const FINDING_ORIGINAL_SEVERITYSCORE = 'finding.original_severity_score';

export const EXCEPTION_REQUESTER_EMAIL = 'exception.requester_email';
export const EXCEPTION_REQUESTER_NAME = 'requester.name';
export const EXCEPTION_REVIEWER_EMAIL = 'exception.reviewer_email';
export const EXCEPTION_REVIEWER_NAME = 'reviewer.name';
export const EXCEPTION_STATUS_BUCKET_NAME = 'exception.status.status_bucket.name';
export const EXCEPTION_STATUS = 'current_status';
export const EXCEPTION_JUSTIFICATION_NAME = 'justification';
export const EXCEPTION_REASON_NAME = 'reason';
export const EXCEPTION_REQUESTED_SLA_NAME = 'requested_sla';
