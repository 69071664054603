import React from 'react';
import { APP_PATHS, FeatureFlags, PAGE_PATHS, Permission } from '../../types';
import { MenuItem } from '../../types/sideBar.types';
import { ReactComponent as AccountUsage } from '../../assets/AccountUsage.svg';
import { ReactComponent as Assets } from '../../assets/Assets.svg';
import { ReactComponent as Code } from '../../assets/Code.svg';
import { ReactComponent as Exceptions } from '../../assets/Exceptions.svg';
import { ReactComponent as Findings } from '../../assets/Findings.svg';
import { ReactComponent as Lock } from '../../assets/Lock.svg';
import { ReactComponent as Accounts } from '../../assets/screens/Accounts.svg';
import { ReactComponent as Alerts } from '../../assets/screens/Alerts.svg';
import { ReactComponent as AssetCoverage } from '../../assets/screens/Asset Coverage.svg';
import { ReactComponent as AssetInventory } from '../../assets/screens/Asset Inventory.svg';
import { ReactComponent as Authentications } from '../../assets/screens/Authentications.svg';
import { ReactComponent as CoverageAndGap } from '../../assets/screens/CoverageAndGap.svg';
import { ReactComponent as CustomDashboards } from '../../assets/screens/Custom Dashboards.svg';
import { ReactComponent as DataModel } from '../../assets/screens/Data model.svg';
import { ReactComponent as DataRetention } from '../../assets/screens/Data Retention.svg';
import { ReactComponent as DataUnification } from '../../assets/screens/Data Unification.svg';
import { ReactComponent as Entities } from '../../assets/screens/Entities.svg';
import { ReactComponent as FormattingRules } from '../../assets/screens/FormattingRules.svg';
import { ReactComponent as IncidentSideBar } from '../../assets/screens/Incident.svg';
import { ReactComponent as Integration } from '../../assets/screens/Integration.svg';
import { ReactComponent as Logs } from '../../assets/screens/Logs.svg';
import { ReactComponent as LookupTables } from '../../assets/screens/LookUp.svg';
import { ReactComponent as Measurements } from '../../assets/screens/Measurements.svg';
import { ReactComponent as Alias } from '../../assets/screens/Mention.svg';
import { ReactComponent as Overview } from '../../assets/screens/Overview.svg';
import { ReactComponent as Pivot } from '../../assets/screens/Pivot.svg';
import { ReactComponent as PolicyViolations } from '../../assets/screens/Policy Violations.svg';
import { ReactComponent as PolicyCompliance } from '../../assets/screens/PolicyCompliance.svg';
import { ReactComponent as Relations } from '../../assets/screens/Relations.svg';
import { ReactComponent as Remediation } from '../../assets/screens/Remediation.svg';
import { ReactComponent as Reports } from '../../assets/screens/Reports.svg';
import { ReactComponent as Risk } from '../../assets/screens/Risk.svg';
import { ReactComponent as ROI } from '../../assets/screens/ROI.svg';
import { ReactComponent as Rules } from '../../assets/screens/Rules.svg';
import { ReactComponent as GlobalScript } from '../../assets/screens/Script.svg';
import { ReactComponent as Setting } from '../../assets/screens/Setting.svg';
import { ReactComponent as Sources } from '../../assets/screens/Sources.svg';
import { ReactComponent as Tickets } from '../../assets/screens/Tickets.svg';
import { ReactComponent as User } from '../../assets/user.svg';
import { ReactComponent as WorkflowsIcon } from '../../assets/Workflows.svg';

export const SIDEBAR_WIDTH_OPEN = 230;
export const SIDEBAR_WIDTH_COLLAPSED = 70;

export const DetectionsMenuItems: MenuItem[] = [
  {
    name: 'INVESTIGATE',
    subMenu: [
      { path: PAGE_PATHS.INCIDENTS, name: 'Incidents', icon: <IncidentSideBar /> },
      { path: PAGE_PATHS.ALERTS, name: 'Alerts', icon: <Alerts /> },
      {
        path: PAGE_PATHS.SETTINGS,
        name: 'Settings',
        icon: <Setting />,
        subMenu: [
          { path: PAGE_PATHS.RULE_SET, name: 'Grouping Rules' },
          { path: PAGE_PATHS.TICKETS_STATUSES, name: 'Incidents Statuses' },
          { path: PAGE_PATHS.UI_CONFIG, name: 'UI Config' },
        ],
      },
    ],
  },
  {
    name: 'DETECT',
    subMenu: [
      { path: PAGE_PATHS.RULES, name: 'Rules', icon: <Rules /> },
      {
        path: PAGE_PATHS.GLOBAL_SCRIPTS,
        name: 'Scripts Library',
        icon: <GlobalScript />,
      },
      {
        path: PAGE_PATHS.LOOKUP_TABLES,
        name: 'Lookup Tables',
        icon: <LookupTables />,
      },
    ],
  },
  {
    name: 'ANALYZE',
    subMenu: [
      { path: PAGE_PATHS.PIVOT, name: 'Pivot', icon: <Pivot /> },
      { path: PAGE_PATHS.REPORTS, name: 'My Reports', icon: <Reports /> },
      {
        path: PAGE_PATHS.CUSTOM_DASHBOARDS,
        name: 'My Dashboards',
        icon: <CustomDashboards />,
      },
    ],
  },
];

export const VulnerabilitiesMenuItems: MenuItem[] = [
  {
    name: 'REMEDIATE',
    subMenu: [
      { path: PAGE_PATHS.TICKETS, name: 'Tickets', icon: <Tickets /> },
      { path: PAGE_PATHS.ASSETS, name: 'Assets', icon: <Assets /> },
      { path: PAGE_PATHS.FINDINGS, name: 'Findings', icon: <Findings /> },
      { path: PAGE_PATHS.EXCEPTIONS, name: 'Exceptions', icon: <Exceptions /> },
      {
        path: PAGE_PATHS.SETTINGS,
        name: 'Settings',
        icon: <Setting />,
        subMenu: [
          { path: PAGE_PATHS.RULE_SET, name: 'Grouping Rules' },
          { path: PAGE_PATHS.SCORE, name: 'Score' },
          { path: PAGE_PATHS.SEVERITY_SLA, name: 'Severity & SLA' },
          { path: PAGE_PATHS.TICKETS_STATUSES, name: 'Statuses' },
          { path: PAGE_PATHS.UI_CONFIG, name: 'UI Config' },
          { path: PAGE_PATHS.EXCEPTIONS_SETTINGS, name: 'Exceptions' },
        ],
      },
    ],
  },
  {
    name: 'ANALYZE',
    subMenu: [
      { path: PAGE_PATHS.OVERVIEW, name: 'Overview', icon: <Overview /> },
      {
        path: PAGE_PATHS.REMEDIATION,
        name: 'Remediation History',
        icon: <Remediation />,
      },
      { path: PAGE_PATHS.RISK, name: 'Risk', icon: <Risk /> },
      { path: PAGE_PATHS.PIVOT, name: 'Pivot', icon: <Pivot /> },
      {
        path: PAGE_PATHS.ASSET_COVERAGE,
        name: 'Asset Coverage',
        icon: <AssetCoverage />,
      },
      { path: PAGE_PATHS.ROI, name: 'ROI', icon: <ROI /> },
      { path: PAGE_PATHS.REPORTS, name: 'My Reports', icon: <Reports /> },
      {
        path: PAGE_PATHS.CUSTOM_DASHBOARDS,
        name: 'My Dashboards',
        icon: <CustomDashboards />,
      },
    ],
  },
];

export const IncidentsMenuItems: MenuItem[] = [
  {
    name: 'REMEDIATE',
    subMenu: [
      { path: PAGE_PATHS.INCIDENTS, name: 'Incidents', icon: <IncidentSideBar /> },
      {
        path: PAGE_PATHS.SETTINGS,
        name: 'Settings',
        icon: <Setting />,
        subMenu: [
          { path: PAGE_PATHS.RULE_SET, name: 'Grouping Rules' },
          { path: PAGE_PATHS.TICKETS_STATUSES, name: 'Incidents Statuses' },
          { path: PAGE_PATHS.UI_CONFIG, name: 'UI Config' },
        ],
      },
    ],
  },
  {
    name: 'ANALYZE',
    subMenu: [
      { path: PAGE_PATHS.PIVOT, name: 'Pivot', icon: <Pivot /> },
      { path: PAGE_PATHS.REPORTS, name: 'My Reports', icon: <Reports /> },
      {
        path: PAGE_PATHS.CUSTOM_DASHBOARDS,
        name: 'My Dashboards',
        icon: <CustomDashboards />,
      },
    ],
  },
];

export const ExploreMenuItems: MenuItem[] = [
  {
    name: 'EXPLORE',
    subMenu: [
      { path: PAGE_PATHS.ENTITIES, name: 'Entities', icon: <Entities /> },
      { path: PAGE_PATHS.LOGS, name: 'Logs', icon: <Logs /> },
      { path: PAGE_PATHS.REPORTS, name: 'Reports', icon: <Reports /> },
      { path: PAGE_PATHS.CUSTOM_DASHBOARDS, name: 'Dashboards', icon: <CustomDashboards /> },
    ],
  },
  {
    name: 'DETECT',
    subMenu: [],
  },
];

export const PlatformMenuItems: MenuItem[] = [
  {
    name: 'CONFIGURE',
    subMenu: [
      { path: PAGE_PATHS.SOURCES, name: 'Sources', icon: <Sources /> },
      { path: PAGE_PATHS.TARGETS, name: 'Outegrations', icon: <Integration /> },
      {
        path: PAGE_PATHS.AUTHENTICATION_SOURCES,
        name: 'Authentications',
        icon: <Authentications />,
        ffForDisplaying: FeatureFlags.OneAuthUI,
      },
      { path: PAGE_PATHS.MODEL_MANAGEMENT, name: 'Data Model', icon: <DataModel /> },
      {
        path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES,
        name: 'Data Unification',
        icon: <DataUnification />,
        subMenu: [
          { path: PAGE_PATHS.DATA_UNIFICATION_FIELDS, name: 'Fields', ffForDisplaying: FeatureFlags.DataUnificationRules },
          { path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES, name: 'Entities' },
        ],
      },
      { path: PAGE_PATHS.WORKFLOWS, name: 'Workflows', icon: <WorkflowsIcon /> },
      { path: PAGE_PATHS.RELATIONS, name: 'Relations', icon: <Relations /> },
      { path: PAGE_PATHS.ALIASES, name: 'Aliases', icon: <Alias /> },
      {
        path: PAGE_PATHS.DATA_PURGE,
        name: 'Data Purge',
        icon: <DataRetention />,
        ffForDisplaying: FeatureFlags.EnableDataRetentionDefaultRulesCreation,
      },
      { path: PAGE_PATHS.MEASUREMENTS, name: 'Measurements', icon: <Measurements /> },
      { path: PAGE_PATHS.FORMATTING_RULES, name: 'Formatting Rules', icon: <FormattingRules /> },
    ],
  },
];

export const SettingsMenuItems: MenuItem[] = [
  {
    name: 'PROFILE',
    subMenu: [{ path: PAGE_PATHS.EDIT_MY_USER, name: 'Settings', icon: <Setting /> }],
  },
  {
    name: 'ACCOUNT SETTINGS',
    subMenu: [
      { path: PAGE_PATHS.EDIT_MY_ACCOUNT_MANAGEMENT, name: 'Settings', icon: <Setting /> },
      { path: PAGE_PATHS.USER_MANAGEMENT, name: 'User Management', icon: <User /> },
      {
        name: 'Permissions',
        path: PAGE_PATHS.PERMISSIONS,
        icon: <Lock />,
        subMenu: [
          { path: PAGE_PATHS.ROLES, name: 'Roles' },
          { path: PAGE_PATHS.CONTENT_PERMISSIONS, name: 'Content' },
        ],
      },
      {
        path: PAGE_PATHS.ACCOUNT_USAGE,
        name: 'Account Usage',
        icon: <AccountUsage />,
        isBackOffice: true,
        ffForDisplaying: FeatureFlags.accountUsagePage,
      },
    ],
  },
  {
    name: 'MANAGE ACCOUNTS',
    subMenu: [
      { path: PAGE_PATHS.ALL_USERS, name: 'All Users', icon: <User />, isBackOffice: true },
      { path: PAGE_PATHS.ALL_ACCOUNTS, name: 'All Accounts', icon: <Accounts />, permission: Permission.CREATE },
    ],
  },
  {
    name: 'INTERNAL ADMIN TOOLS',
    subMenu: [
      { path: PAGE_PATHS.BACKOFFICE_ACTIONS, name: 'Backoffice Actions', icon: <Setting />, isBackOffice: true },
      { path: PAGE_PATHS.QUERY, name: 'Query Runner', icon: <Code />, permission: Permission.UPDATE },
      { path: PAGE_PATHS.ICONS, name: 'Logos Gallery', icon: <Sources /> },
      { path: PAGE_PATHS.RISK360_BACKOFFICE_ACTIONS, name: 'Risk360 Backoffice', icon: <Setting />, permission: Permission.UPDATE },
      { path: PAGE_PATHS.GLOBAL_FACTOR_RULES, name: 'Global Factor Rules', icon: <Rules /> },
    ],
  },
];

export const Risk360MenuItems: MenuItem[] = [
  {
    name: 'RISK MANAGEMENT',
    subMenu: [
      {
        path: PAGE_PATHS.FACTOR_RULES,
        name: 'Factor Rules',
        icon: <Rules />,
      },
    ],
  },
  {
    name: 'ANALYZE',
    subMenu: [
      {
        path: PAGE_PATHS.CUSTOM_DASHBOARDS,
        name: 'My Dashboards',
        icon: <CustomDashboards />,
      },
    ],
  },
];

export const CaasmMenuItems: MenuItem[] = [
  {
    name: 'REMEDIATE',
    subMenu: [
      { path: PAGE_PATHS.ASSETS, name: 'Assets', icon: <Assets /> },
      { path: PAGE_PATHS.POLICY_VIOLATIONS, name: 'Policy Violations', icon: <PolicyViolations /> },
      { path: PAGE_PATHS.POLICIES, name: 'Policies', icon: <Rules /> },
      {
        path: PAGE_PATHS.SETTINGS,
        name: 'Settings',
        icon: <Setting />,
        subMenu: [{ path: PAGE_PATHS.UI_CONFIG, name: 'UI Config' }],
      },
    ],
  },
  {
    name: 'ANALYZE',
    subMenu: [
      {
        path: PAGE_PATHS.ASSET_COVERAGE,
        name: 'Asset Coverage',
        icon: <AssetCoverage />,
      },
      {
        path: PAGE_PATHS.POLICY_COMPLIANCE,
        name: 'Policy Compliance',
        icon: <PolicyCompliance />,
      },
      {
        path: PAGE_PATHS.ASSET_INVENTORY,
        name: 'Asset Inventory',
        icon: <AssetInventory />,
      },
      {
        path: PAGE_PATHS.TOOL_COVERAGE_AND_GAP,
        name: 'Coverage And Gap',
        icon: <CoverageAndGap />,
      },
      { path: PAGE_PATHS.REPORTS, name: 'My Reports', icon: <Reports /> },
      {
        path: PAGE_PATHS.CUSTOM_DASHBOARDS,
        name: 'My Dashboards',
        icon: <CustomDashboards />,
      },
    ],
  },
];

export const ThreatsMenuItems: MenuItem[] = [
  {
    name: 'INVESTIGATE',
    subMenu: [
      { path: PAGE_PATHS.INCIDENTS, name: 'Incidents', icon: <IncidentSideBar /> },
      { path: PAGE_PATHS.ALERTS, name: 'Alerts', icon: <Alerts /> },
    ],
  },
  {
    name: 'ANALYZE',
    subMenu: [
      { path: PAGE_PATHS.REPORTS, name: 'My Reports', icon: <Reports /> },
      {
        path: PAGE_PATHS.CUSTOM_DASHBOARDS,
        name: 'My Dashboards',
        icon: <CustomDashboards />,
      },
    ],
  },
];

export const appsSideBar: Record<string, MenuItem[]> = {
  [APP_PATHS.DETECTIONS]: DetectionsMenuItems,
  [APP_PATHS.VULNERABILITIES]: VulnerabilitiesMenuItems,
  [APP_PATHS.INCIDENTS]: IncidentsMenuItems,
  [APP_PATHS.PLATFORM]: PlatformMenuItems,
  [APP_PATHS.EXPLORE]: ExploreMenuItems,
  [APP_PATHS.SETTINGS]: SettingsMenuItems,
  [APP_PATHS.RISK360]: Risk360MenuItems,
  [APP_PATHS.CAASM]: CaasmMenuItems,
  [APP_PATHS.THREATS]: ThreatsMenuItems,
};
