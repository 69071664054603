export enum QueryKey {
  accountUsageSources = 'accountUsageSources',
  mappingPreview = 'mappingPreview',
  sourceTypeList = 'sourceTypeList',
  enhancedAggProjs = 'enhancedAggProjs',
  detectionSources = 'detectionSources',
  notificationTargetsTicketsDropdown = 'notification_targets_tickets_dropdown',
  relations = 'relations',
  account = 'account',
  UserAdditionalInfo = 'userAdditionalInfo',
  FeatureFlags = 'featureFlags',
  MeasurementList = 'measurementList',
  UiConfig = 'ui_config',
  Entities = 'entities',
  DefaultUiConfig = 'default_ui_config',
  UseUiConfigByProjectionName = 'useUiConfigByProjectionName',
  AccessToken = 'access_token',
  UsersInfo = 'usersInfo',
  FindAllApps = 'findAllApps',
  MitreList = 'mitre-list',
  FindAccountDatastoresMapping = 'findAccountDatastoresMapping',
  GetAvailableServiceNames = 'getAvailableServiceNames',
  GetAvailableDatastoreCategories = 'getAvailableDatastoreCategories',
  SamlDetails = 'saml-details',
  FindAccountById = 'findAccountById',
  Gateways = 'gateways',
  AccountLimits = 'accountLimits',
  AccountUsage = 'accountUsage',
  AccountAliases = 'account-aliases',
  AuthenticationSourceListByType = 'authenticationSourceListByType',
  FindAllAccounts = 'findAllAccounts',
  FindAllUsers = 'findAllUsers',
  FindAllEtlMonitors = 'findAllEtlMonitors',
  FindAllRisk360Accounts = 'findAllRisk360Accounts',
  RunOLTPQuery = 'runOLTPQuery',
  ContentPermissions = 'content-permissions',
  CustomDashboardsList = 'customDashboardsList',
  FindDashboardById = 'findDashboardById',
  ExportDashboard = 'exportDashboard',
  CustomRolesList = 'customRolesList',
  FindCustomRoleById = 'findCustomRoleById',
  RetentionRules = 'RetentionRules',
  UnificationRules = 'unificationRules',
  UnificationsRulesSet = 'unificationsRulesSet',
  UnificationPreview = 'unificationPreview',
  PreviewEvaluation = 'previewEvaluation',
  GlobalQueries = 'global-queries',
  FindRiskFactorExecutionRulesByAccountId = 'findRiskFactorExecutionRulesByAccountId',
  FindBuiltInExecutionRulesByType = 'findBuiltInExecutionRulesByType',
  FindRiskFactorExecutionRuleById = 'findRiskFactorExecutionRuleById',
  FindBuiltInExecutionRuleById = 'findBuiltInExecutionRuleById',
  SourceTypes = 'sourceTypes',
  ExecutionGroupId = 'ExecutionGroupId',
  GETFORMATTINGRULES = 'GET_FORMATTING_RULES',
  FindGlobalScriptById = 'findGlobalScriptById',
  SourcesListLogsQuery = 'sourcesListLogsQuery',
  AccountFacets = 'account_facets',
  SourcesListFacets = 'sourcesListFacets',
  GETSOURCESLIST = 'GET_SOURCES_LIST',
  LogsQueries = 'logsQueries',
  LogsQueriesCategory = 'logsQueriesCategory',
  LookupTables = 'LookupTables',
  FieldRegulations = 'fieldRegulations',
  ProjEvaluations = 'projEvaluations',
  ModelPreview = 'model-preview',
  IntegrationLogsFilters = 'integration_logs_filters',
  GetActivityLogs = 'getActivityLogs',
  ContextByIntegration = 'contextByIntegration',
  NotificationTargets = 'NotificationTargets',
  SchemaByConfigAndDefaultMapping = 'schemaByConfigAndDefaultMapping',
  Policies = 'policies',
  PolicyId = 'policy_id',
  PolicyScenarioFilter = 'policy_scenario_filter',
  ReportData = 'report_data',
  AvailableIntegrationInstance = 'availableIntegrationInstance',
  RuleData = 'rule_data',
  RulesLogs = 'rulesLogs',
  RulesLogsFilters = 'rulesLogsFilters',
  FindRuleById = 'findRuleById',
  GetIntellisense = 'getIntellisense',
  FindRuleByAccountId = 'findRuleByAccountId',
  GroupingRules = 'groupingRules',
  SeverityScoring = 'severityScoring',
  SeveritySla = 'severity-sla',
  AggProjections = 'aggProjections',
  EditSource = 'editSource',
  ConnectorData = 'connector_data',
  DataPreview = 'data-preview',
  SourceMappingId = 'sourceMappingId',
  FileParser = 'fileParser',
  DataPreviewFromWizard = 'data-preview-from-wizard',
  UseDataStreamInstancePreviewSource = 'use-data-stream-instance-preview-source',
  TicketInfo = 'ticket-info',
  TabTable = 'tab-table',
  Views = 'views',
  GetUserRoles = 'getUserRoles',
  GetUsers = 'getUsers',
  GetUsersListNames = 'getUsersListNames',
  FindUserById = 'findUserById',
  GetAllowedEditUsersUserAccount = 'getAllowedEditUsersUserAccount',
  Workflows = 'workflows',
  Mapping = 'mapping',
  UseDataStreamInstancePreview = 'useDataStreamInstancePreview',
  AuthenticationSourceList = 'AuthenticationSourceList',
  AuthenticationSource = 'AuthenticationSource',
  Integration = 'integration',
  Bridge = 'bridge',
  AQLFunctionDocs = 'aql-function-docs',
  FieldsOverrideList = 'FieldsOverrideList',
  getDsiWorkflows = 'getDsiWorkflows',
  getDsiWorkflowsWithMetadata = 'getDsiWorkflowsWithMetadata',
  ExceptionsSettings = 'exceptionSettings',
  ExceptionsSettingsFormFields = 'exceptionSettingsFormField',
  ExceptionReviewers = 'exceptionReviewers',
  defaultRunsQueryKey = 'defaultRunsQueryKey',
  factorRunsQuery = 'factorRunsQuery',
  policyRunsQuery = 'policyRunsQuery',
  reportRunsQuery = 'reportRunsQuery',
  ruleRunsQuery = 'ruleRunsQuery',
  modelManagementRunsQuery = 'modelManagementRunsQuery',
  builtinExecutionRulesRunsQuery = 'builtinExecutionRulesRunsQuery',
  dataRetentionRunsQuery = 'dataRetentionRunsQuery',
}
