import React, { useCallback, useState } from 'react';
import { flex } from '@components/AvThemeProvider';
import { Box, Collapse, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { getPermissionNameFromValue } from '@utils/permissions.utils';
import { iconSize, uniqBy } from '@utils/Utils';
import AvCheckbox from '../../../components/AvCheckbox';
import { useAvContext } from '../../../context/AvContextProvider';
import { FeatureFlags, Permission } from '../../../types';
import AvMessagePopover from '../../Tickets/AvMessagePopover';
import { PermissionCategory, PermissionEntity } from '../types';
import { ReactComponent as ArrowDown } from '../../../assets/Arrow Down.svg';
import { ReactComponent as QuestionFull } from '../../../assets/colorful/Question_full.svg';

interface Props {
  value: string[];
  permissionCategory: PermissionCategory;
  onChange: (v: string[]) => void;
  disabled?: boolean;
}

const basePermissions = [Permission.READ, Permission.CREATE, Permission.UPDATE, Permission.DELETE];

export const getPermissions = isExceptionM2 => (isExceptionM2 ? [...basePermissions, Permission.AUDIT] : basePermissions);

const arrowIcon = (rotateDeg = 0) => <ArrowDown style={{ transform: `rotate(${rotateDeg}deg)`, ...iconSize(16) }} />;

const DisabledMinusIcon = () => <Box sx={{ ...flex.center, ...iconSize(16) }}>-</Box>;

const PermissionCategorySelection: React.FC<Props> = ({ permissionCategory, value, onChange, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(true);
  const theme = useTheme();
  const { featureFlags } = useAvContext();

  const permissions = getPermissions(featureFlags[FeatureFlags.ExceptionsM2]);

  const allSubOptionsByType = useCallback(
    permission =>
      permissionCategory.permissionEntities.reduce(
        (acc, cur) => [
          ...acc,
          ...(cur.subPermissions
            ? filterDisableEntityPermissionsByConfig(permission, cur).flatMap(v => getAllPermissionNamesFromValue(permission, v))
            : shouldDisableEntityByConfig(permission, cur)
              ? []
              : getAllPermissionNamesFromValue(permission, cur)),
        ],
        [] as string[]
      ),
    [permissionCategory, getPermissionNameFromValue]
  );

  const allSelectedSubOptionsByType = useCallback(
    permission => {
      const allPermissionsNames = permissionCategory.permissionEntities.reduce(
        (acc, cur) => [...acc, ...(cur.subPermissions ? cur.subPermissions.map(v => v) : [cur])],
        [] as PermissionEntity[]
      );
      const allPermissionsOptionsbyType = allPermissionsNames.flatMap(v => getAllPermissionNamesFromValue(permission, v));
      return allPermissionsOptionsbyType.filter(v => value.includes(v));
    },
    [permissionCategory, getPermissionNameFromValue, value]
  );

  const isIndeterminate = (permission: Permission) => {
    const allSelectedSubOptions = allSelectedSubOptionsByType(permission);
    return allSelectedSubOptions.length > 0 && allSelectedSubOptions.length < allSubOptionsByType(permission).length;
  };

  const shouldDisableCheckbox = (permission: Permission) => {
    if (permission === Permission.READ) {
      return !!(
        allSelectedSubOptionsByType(Permission.CREATE).length ||
        allSelectedSubOptionsByType(Permission.UPDATE).length ||
        allSelectedSubOptionsByType(Permission.DELETE).length ||
        allSelectedSubOptionsByType(Permission.AUDIT).length
      );
    }
    if (permission !== Permission.AUDIT) {
      return !!allSelectedSubOptionsByType(Permission.AUDIT).length;
    }
    return false;
  };

  const onChangeCategoryPermission = (permission: Permission) => {
    const allSubOptions = allSubOptionsByType(permission);
    const allReadSubOptions = allSubOptionsByType(Permission.READ);
    const allCreateSubOptions = allSubOptionsByType(Permission.CREATE);
    const allUpdateSubOptions = allSubOptionsByType(Permission.UPDATE);
    const allDeleteSubOptions = allSubOptionsByType(Permission.DELETE);

    const allCreateUpdateDeleteSubOptions = [...allCreateSubOptions, ...allUpdateSubOptions, ...allDeleteSubOptions];

    onChange(
      isIndeterminate(permission) || isChecked(permission)
        ? value.filter(v => !allSubOptions.includes(v))
        : uniqBy(
            [
              ...value,
              ...allSubOptions,
              ...(permission === Permission.READ ? [] : allReadSubOptions),
              ...(permission === Permission.AUDIT ? allCreateUpdateDeleteSubOptions : []),
            ],
            p => p
          )
    );
  };

  const isMenuChecked = (permission: Permission, subPermission: PermissionEntity) =>
    getAllPermissionNamesFromValue(permission, subPermission).every(v => value.includes(v));

  const isChecked = (permission: Permission) =>
    permissionCategory.permissionEntities.every(v =>
      v.subPermissions
        ? v.subPermissions.every(
            subPermission => shouldDisableEntityByConfig(permission, subPermission) || isMenuChecked(permission, subPermission)
          )
        : shouldDisableEntityByConfig(permission, v) || isMenuChecked(permission, v)
    );

  const isAllSubPermissionsDisabled = (permission: Permission) =>
    permissionCategory.permissionEntities.every(v =>
      v.subPermissions
        ? v.subPermissions.every(subPermission => shouldDisableEntityByConfig(permission, subPermission))
        : shouldDisableEntityByConfig(permission, v)
    );

  return (
    <Box
      sx={{
        borderRadius: '8px',
        background: 'white',
        border: `1.5px solid ${theme.palette.colors.neutrals[350]}`,
      }}>
      <Box sx={{ ...flex.row, ...getGridSx(featureFlags[FeatureFlags.ExceptionsM2]) }}>
        <Box sx={{ ...flex.itemsCenter, gap: 1, pl: 2, cursor: 'pointer' }} onClick={() => setIsOpen(prev => !prev)}>
          {arrowIcon(isOpen ? 0 : -90)}
          <Typography variant="h6">{permissionCategory.categoryDisplayName}</Typography>
        </Box>
        {permissions.map(permission => (
          <Box key={permission} sx={{ ...flex.itemsCenter }}>
            {isAllSubPermissionsDisabled(permission) ? (
              <DisabledMinusIcon />
            ) : (
              <AvCheckbox
                key={`${permissionCategory.categoryDisplayName}_${permission}`}
                value={isChecked(permission)}
                onChange={() => onChangeCategoryPermission(permission)}
                indeterminate={isIndeterminate(permission)}
                disabled={disabled || shouldDisableCheckbox(permission)}
              />
            )}
          </Box>
        ))}
      </Box>
      <Collapse in={isOpen}>
        {permissionCategory.permissionEntities.map(
          permissionEntity =>
            (permissionEntity.ff ? featureFlags[permissionEntity.ff] : true) && (
              <PermissionRowSelection
                key={`${permissionEntity.displayName}_${permissionEntity.name}_${permissionEntity.categories?.join('_')}`}
                permissionEntity={permissionEntity}
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            )
        )}
      </Collapse>
    </Box>
  );
};

export default PermissionCategorySelection;

interface PermissionRowSelectionProps {
  onChange: (v) => void;
  permissionEntity: PermissionEntity;
  value: string[];
  disabled?: boolean;
}
const PermissionRowSelection: React.FC<PermissionRowSelectionProps> = ({ permissionEntity, onChange, value, disabled = false }) => {
  const theme = useTheme();
  const isSubMenu = !!permissionEntity.subPermissions;
  const [isOpen, setIsOpen] = useState(false);
  const { featureFlags } = useAvContext();

  const permissions = getPermissions(featureFlags[FeatureFlags.ExceptionsM2]);

  const isChecked = (permission: Permission) => {
    if (isSubMenu) {
      return (
        permissionEntity.subPermissions!.every(
          subPermission => shouldDisableEntityByConfig(permission, subPermission) || isMenuChecked(permission, subPermission)
        ) && permissionEntity.subPermissions!.some(subPermission => isMenuChecked(permission, subPermission))
      );
    }
    return isMenuChecked(permission, permissionEntity);
  };

  const isIndeterminate = (permission: Permission) => {
    if (!isSubMenu) {
      return false;
    }
    const selectedSubItemsLength = permissionEntity.subPermissions!.filter(v => isMenuChecked(permission, v)).length;

    const selectedSubItemsDisabledByConfigLength = permissionEntity.subPermissions!.filter(v =>
      shouldDisableEntityByConfig(permission, v)
    ).length;
    return (
      selectedSubItemsLength > 0 &&
      selectedSubItemsLength < permissionEntity.subPermissions!.length - selectedSubItemsDisabledByConfigLength
    );
  };
  const isMenuChecked = (permission: Permission, subPermission: PermissionEntity) =>
    getAllPermissionNamesFromValue(permission, subPermission).every(v => value.includes(v));

  const shouldDisableCheckbox = (permission: Permission, permissionEnt: PermissionEntity) =>
    permissionEnt.subPermissions
      ? permission === Permission.READ &&
        permissionEnt.subPermissions!.some(
          subPermission =>
            (permissionEnt.name === subPermission.name || permissionEntity.name === permissionEnt.name) &&
            (isMenuChecked(Permission.CREATE, subPermission) ||
              isMenuChecked(Permission.UPDATE, subPermission) ||
              isMenuChecked(Permission.DELETE, subPermission) ||
              isMenuChecked(Permission.AUDIT, subPermission))
        )
      : permission === Permission.READ
        ? isMenuChecked(Permission.CREATE, permissionEnt) ||
          isMenuChecked(Permission.UPDATE, permissionEnt) ||
          isMenuChecked(Permission.DELETE, permissionEnt) ||
          isMenuChecked(Permission.AUDIT, permissionEnt)
        : permission !== Permission.AUDIT && isMenuChecked(Permission.AUDIT, permissionEnt);

  const onChangePermission = (permission: Permission) => {
    if (isSubMenu) {
      const newValues = filterDisableEntityPermissionsByConfig(permission, permissionEntity).flatMap(subPermission =>
        getAllPermissionNamesFromValue(permission, subPermission)
      );

      const readSubPermissions = permissionEntity.subPermissions!.flatMap(subPermission =>
        getAllPermissionNamesFromValue(Permission.READ, subPermission)
      );

      onChange(
        newValues.some(permission => value.includes(permission))
          ? value.filter(p => !newValues.includes(p))
          : uniqBy([...value, ...newValues, ...(permission === Permission.READ ? [] : readSubPermissions)], p => p)
      );
      return;
    }
    onChangeSubPermission(permission, permissionEntity);
  };

  const getPermissionNamesFromValueAndType = (permission, subPermission) =>
    shouldDisableEntityByConfig(permission, subPermission) ? [] : getAllPermissionNamesFromValue(permission, subPermission);

  const onChangeSubPermission = (permission: Permission, subPermission: PermissionEntity) => {
    const newValue = getAllPermissionNamesFromValue(permission, subPermission);

    const readPermission = getPermissionNamesFromValueAndType(Permission.READ, subPermission);
    const createPermission = getPermissionNamesFromValueAndType(Permission.CREATE, subPermission);
    const updatePermission = getPermissionNamesFromValueAndType(Permission.UPDATE, subPermission);
    const deletePermission = getPermissionNamesFromValueAndType(Permission.DELETE, subPermission);

    const CreateUpdateDeletePermissions = [...createPermission, ...deletePermission, ...updatePermission];

    onChange(
      newValue.every(v => value.includes(v))
        ? value.filter(p => !newValue.includes(p))
        : uniqBy(
            [
              ...value,
              ...newValue,
              ...(permission === Permission.READ ? [] : readPermission),
              ...(permission === Permission.AUDIT ? CreateUpdateDeletePermissions : []),
            ],
            p => p
          )
    );
  };

  return (
    <>
      <ListItem
        sx={{
          ...getGridSx(featureFlags[FeatureFlags.ExceptionsM2]),
          px: 0,
          borderTop: `1px solid ${theme.palette.colors.neutrals[200]}`,
          '&:hover': {
            backgroundColor: theme.palette.colors.neutrals[200],
          },
          '& * .showOnHover': {
            opacity: 0,
            transition: 'opacity 0.2s ease',
          },
          '&:hover * .showOnHover': {
            opacity: 1,
          },
        }}>
        <Box
          sx={{
            px: 5,
            ...flex.row,
            gap: 1,
          }}
          onClick={() => isSubMenu && setIsOpen(prev => !prev)}>
          <Box>
            {isSubMenu && arrowIcon(isOpen ? 0 : -90)}
            <Box>{permissionEntity.displayName}</Box>
          </Box>
          {permissionEntity.tooltip && (
            <Box className="showOnHover">
              <AvMessagePopover message={permissionEntity.tooltip} icon={<QuestionFull />} asTooltip />
            </Box>
          )}
        </Box>
        {permissions.map(permission => (
          <Box
            key={`${permissionEntity.name}_${permission}_${permissionEntity.displayName}`}
            sx={{
              ...flex.itemsCenter,
              ...disabledCheckboxByDefinitionStyle(shouldDisableEntityByConfig(permission, permissionEntity), theme),
            }}>
            {shouldDisableEntityByConfig(permission, permissionEntity) ? (
              <DisabledMinusIcon />
            ) : (
              <AvCheckbox
                value={isChecked(permission)}
                onChange={() => onChangePermission(permission)}
                indeterminate={isIndeterminate(permission)}
                disabled={disabled || shouldDisableCheckbox(permission, permissionEntity)}
              />
            )}
          </Box>
        ))}
      </ListItem>
      {permissionEntity.subPermissions && (
        <Collapse in={isOpen}>
          {permissionEntity.subPermissions.map(subPermissionEntity => (
            <ListItem
              key={subPermissionEntity.name}
              sx={{
                ...getGridSx(featureFlags[FeatureFlags.ExceptionsM2]),
                px: 0,
                borderTop: `1px solid ${theme.palette.colors.neutrals[200]}`,
                color: theme.palette.colors.neutrals[600],
                '&:hover': {
                  backgroundColor: theme.palette.colors.neutrals[200],
                },
              }}>
              <Box sx={{ pl: 7 }}>{subPermissionEntity.displayName}</Box>
              {permissions.map(permission => (
                <Box
                  key={`${permissionEntity.name}_${subPermissionEntity.name}_${permission}_${subPermissionEntity.displayName}`}
                  sx={{
                    ...flex.itemsCenter,
                    ...disabledCheckboxByDefinitionStyle(shouldDisableEntityByConfig(permission, subPermissionEntity), theme),
                  }}>
                  <AvCheckbox
                    value={isMenuChecked(permission, subPermissionEntity)}
                    onChange={() => onChangeSubPermission(permission, subPermissionEntity)}
                    disabled={disabled || shouldDisableCheckbox(permission, subPermissionEntity)}
                  />
                </Box>
              ))}
            </ListItem>
          ))}
        </Collapse>
      )}
    </>
  );
};

export const shouldDisableEntityByConfig = (permission: Permission, permissionEntity: PermissionEntity) =>
  permissionEntity.disabledPermissions?.includes(permission);

export const filterDisableEntityPermissionsByConfig = (permission: Permission, permissionEntity: PermissionEntity) =>
  permissionEntity.subPermissions?.filter(subPermission => !shouldDisableEntityByConfig(permission, subPermission)) || [];

export const getGridSx = isExceptionsM2 => ({
  display: 'grid',
  gridTemplateColumns: isExceptionsM2 ? '1.75fr 1fr 1fr 1fr 1fr 0.85fr' : '1.75fr 1fr 1fr 1fr 0.85fr',
  height: '48px',
});

const getAllPermissionNamesFromValue = (permission: Permission, permissionEntity: PermissionEntity): string[] =>
  permissionEntity.categories
    ? permissionEntity.categories.map(v => getPermissionNameFromValue({ categoryName: v, entityName: permissionEntity.name, permission }))
    : [getPermissionNameFromValue({ entityName: permissionEntity.name, permission })];

const disabledCheckboxByDefinitionStyle = (disabled, theme) =>
  disabled
    ? {
        '.MuiCheckbox-root.Mui-disabled': {
          color: theme.palette.colors.neutrals[300],
          backgroundColor: theme.palette.colors.neutrals[300],
          borderRadius: '3px',
        },
      }
    : {};
