export const OPERATORS = {
  ipInSubnet: 'ipInSubnet',
  subnetHasIp: 'subnetHasIp',
  in: 'in',
  includes: 'includes',
  equals: 'equals',
};

export const SIDES = {
  RHS: 'RHS',
  LHS: 'LHS',
};

export const SIDE_MAPPING = {
  [OPERATORS.in]: SIDES.RHS,
  [OPERATORS.includes]: SIDES.LHS,
};

export const SUBNET_SIDE_MAPPING = {
  [OPERATORS.ipInSubnet]: SIDES.LHS,
  [OPERATORS.subnetHasIp]: SIDES.RHS,
};

export const FILTER_TO_CRITERIA_OPERATOR_MAPPING = {
  [OPERATORS.subnetHasIp]: OPERATORS.ipInSubnet,
  [OPERATORS.includes]: OPERATORS.in,
};
