import React from 'react';
import { Box, useTheme } from '@mui/material';
import { flex } from '../components/AvThemeProvider';
import AvTooltip from '../components/AvTooltip';
import { useSeveritySettings } from '../views/Settings/hooks';
import { abbreviateNumber } from './Utils';
import { ReactComponent as Critical } from '../assets/colorful/Critical.svg';
import { ReactComponent as High } from '../assets/colorful/High.svg';
import { ReactComponent as Info } from '../assets/colorful/InfoGreen.svg';
import { ReactComponent as Low } from '../assets/colorful/Low.svg';
import { ReactComponent as Medium } from '../assets/colorful/Medium.svg';

export const SeverityIcons = {
  NONE: { color: 'unknown', icon: <Info />, order: 0 },
  INFO: { color: 'info', icon: <Info />, order: 1 },
  LOW: { color: 'low', icon: <Low />, order: 2 },
  MEDIUM: { color: 'warning', icon: <Medium />, order: 3 },
  HIGH: { color: 'high', icon: <High />, order: 4 },
  CRITICAL: { color: 'error', icon: <Critical />, order: 5 },
};

export const SEVERITY_LABELS = {
  INFO: 'Info',
  NONE: 'Info',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  CRITICAL: 'Critical',
};

export const severityOptions = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFO'].map(type => ({
  title: SEVERITY_LABELS[type],
  value: type,
}));
export const severityWithNone = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'NONE'];
export const severityOptionsWithNone = severityWithNone.map(type => ({
  title: SEVERITY_LABELS[type],
  value: type,
}));

export enum SeverityLabels {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
  Info = 'INFO',
  None = 'NONE',
}

interface VulCountBySeverityProps {
  data: any;
  totals?: any;
  includeNone?: boolean;
}

export const VulCountBySeverity: React.FC<VulCountBySeverityProps> = ({ data, totals = {}, includeNone = true }) => {
  const severities = [
    SeverityLabels.Critical,
    SeverityLabels.High,
    SeverityLabels.Medium,
    SeverityLabels.Low,
    ...(includeNone ? [SeverityLabels.None] : []),
  ];

  return (
    <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
      {severities.map(severity => (
        <SeverityFormatter key={`tile_${severity}`} severity={severity} totals={totals} value={data?.[severity]} />
      ))}
    </Box>
  );
};
export const SeverityFromWeight = ({ scoreLeft, scoreRight }: { scoreLeft: number; scoreRight: number }) => {
  const factorSeverityByScore = useFactorSeverityByScore();
  return (
    <Box sx={{ ...flex.itemsCenter, gap: 0.5, whiteSpace: 'nowrap' }}>
      <SeverityFormatter severity={factorSeverityByScore((scoreLeft / scoreRight) * 100).severity} value={scoreLeft} />
      <Box>/</Box>
      <AvTooltip title={scoreRight}>{abbreviateNumber(scoreRight)}</AvTooltip>
    </Box>
  );
};

export const getSeverityByScore = (
  theme,
  settingsSeverities: any[] = [],
  score = 0,
  hue?: number,
  bgHue = 100
): { severity: string; color: string; bgColor: string } | null => {
  const severity = settingsSeverities.find((item, index) => {
    const nextObj = settingsSeverities[index + 1];
    return (
      (index === 0 && score >= nextObj.upperThreshold) ||
      (index === settingsSeverities.length - 1 && score < item.upperThreshold) ||
      (score < item.upperThreshold && score >= nextObj.upperThreshold)
    );
  });
  if (!severity) {
    return null;
  }
  return {
    severity: severity?.label,
    color: severityBackgroundColor(severity?.label, theme, true, hue || (severity.label === 'MEDIUM' ? 700 : 600)),
    bgColor: severityBackgroundColor(severity?.label, theme, true, bgHue),
  };
};

export const SeverityFormatter = ({ severity, value, totals }: { severity: SeverityLabels; value: number; totals?: any }) => {
  const hasColor = value > 0;
  const isAllSolved = value === totals?.[severity];
  return (
    <Box
      sx={{
        ...flex.row,
        ...(hasColor ? severitiesStyle[severity].wrapper : severitiesStyle.disabled.wrapper),
        borderRadius: 2,
        textAlign: 'end',
      }}>
      <Box sx={{ ...severitiesStyle.label, ...(hasColor ? severitiesStyle[severity].label : severitiesStyle.disabled.label) }}>
        {severity[0]}
      </Box>
      <Box
        sx={{
          width: 36,
          pl: 0.5,
          pr: 1,
          ...flex.itemsCenter,
          whiteSpace: 'nowrap',
          fontWeight: hasColor && isAllSolved ? 600 : 400,
          textAlign: 'end',
        }}>
        <AvTooltip title={value} sx={{ width: '100%', textAlign: 'end' }}>
          <Box component="span" sx={{ width: '100%', textAlign: 'end', fontSize: 12 }}>
            {abbreviateNumber(value || 0)}
          </Box>
        </AvTooltip>
      </Box>
    </Box>
  );
};

export function severityBackgroundColor(severity, theme, isBorder = false, hue = 500) {
  const sevToColor = {
    CRITICAL: isBorder ? theme.palette.colors.pink[hue] : theme.palette.colors.neutrals[50],
    HIGH: isBorder ? theme.palette.colors.negative[hue] : theme.palette.colors.neutrals[50],
    MEDIUM: isBorder ? theme.palette.colors.orange[hue] : theme.palette.colors.neutrals[50],
    LOW: isBorder ? theme.palette.colors.yellow[hue] : theme.palette.colors.neutrals[50],
  };

  return sevToColor[severity] || theme.palette.colors.primary[isBorder ? 500 : 100];
}

export const severityBackgroundColorOrUndefined = (severity, theme) => {
  const sevToColor = {
    CRITICAL: theme.palette.colors.pink[500],
    HIGH: theme.palette.colors.negative[500],
    MEDIUM: theme.palette.colors.orange[500],
    LOW: theme.palette.colors.yellow[500],
    INFO: theme.palette.colors.positive[500],
    NONE: theme.palette.colors.positive[500],
  };

  return sevToColor[severity];
};

export const severitiesStyle = {
  label: {
    ...flex.center,
    borderRadius: '6px',
    fontWeight: 600,
    height: 20,
    width: 20,
    color: theme => theme.palette.white.main,
  },
  [SeverityLabels.Critical]: {
    wrapper: {
      background: theme => theme.palette.colors.pink[100],
    },
    label: {
      background: theme => severityBackgroundColorOrUndefined(SeverityLabels.Critical, theme),
    },
  },
  [SeverityLabels.High]: {
    wrapper: {
      background: theme => theme.palette.colors.negative[100],
    },
    label: {
      background: theme => severityBackgroundColorOrUndefined(SeverityLabels.High, theme),
    },
  },
  [SeverityLabels.Medium]: {
    wrapper: {
      background: theme => theme.palette.colors.orange[100],
    },
    label: {
      background: theme => severityBackgroundColorOrUndefined(SeverityLabels.Medium, theme),
    },
  },
  [SeverityLabels.Low]: {
    wrapper: {
      background: theme => theme.palette.colors.yellow[100],
    },
    label: {
      background: theme => severityBackgroundColorOrUndefined(SeverityLabels.Low, theme),
      color: theme => theme.palette.colors.yellow[800],
    },
  },
  [SeverityLabels.None]: {
    wrapper: {
      background: theme => theme.palette.colors.neutrals[200],
    },
    label: {
      background: theme => severityBackgroundColorOrUndefined(SeverityLabels.None, theme),
    },
  },
  disabled: {
    wrapper: {
      background: theme => theme.palette.colors.neutrals[200],
    },
    label: {
      background: 'linear-gradient(57.67deg, #C5C6D6 17.03%, #E2E2EB 82.37%)',
    },
  },
};

export const isValidSeverity = severity => Object.values(SeverityLabels).includes(severity);
export const useSeverityByScore = () => {
  const theme = useTheme();
  const settingsSeverities = useSeveritySettings();
  return score => getSeverityByScore(theme, settingsSeverities, score, 600, 100);
};

export type SeveritySetting = {
  severity: SeverityLabels;
  color: string;
  bgColor: string;
};
export const useFactorSeverityByScore = (hue = 500, bgHue = 100): ((s) => SeveritySetting) => {
  const theme = useTheme();
  return (score: number) => {
    if ((score >= 0 && score <= 25) || !score) {
      return {
        severity: SeverityLabels.Low,
        color: severityBackgroundColor(SeverityLabels.Low, theme, true, hue),
        bgColor: severityBackgroundColor(SeverityLabels.Low, theme, true, bgHue),
      };
    }
    if (score > 25 && score <= 50) {
      return {
        severity: SeverityLabels.Medium,
        color: severityBackgroundColor(SeverityLabels.Medium, theme, true, hue),
        bgColor: severityBackgroundColor(SeverityLabels.Medium, theme, true, bgHue),
      };
    }
    if (score > 50 && score <= 75) {
      return {
        severity: SeverityLabels.High,
        color: severityBackgroundColor(SeverityLabels.High, theme, true, hue),
        bgColor: severityBackgroundColor(SeverityLabels.High, theme, true, bgHue),
      };
    }
    if (score > 75) {
      return {
        severity: SeverityLabels.Critical,
        color: severityBackgroundColor(SeverityLabels.Critical, theme, true, hue),
        bgColor: severityBackgroundColor(SeverityLabels.Critical, theme, true, bgHue),
      };
    }
    return {
      severity: SeverityLabels.None,
      color: severityBackgroundColor(SeverityLabels.None, theme, true, hue),
      bgColor: severityBackgroundColor(SeverityLabels.None, theme, true, bgHue),
    };
  };
};
