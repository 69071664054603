import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { appsSideBar, SIDEBAR_WIDTH_OPEN } from '../components/newNavigation/sidebar.constants';
import envVariables from '../shared/projectEnvVariables';
import { useAvContext } from './AvContextProvider';

export const LayoutContext = React.createContext<LayoutContextType>({
  layoutWidths: { sideBar: SIDEBAR_WIDTH_OPEN },
  setLayoutWidths: () => {},
});

function LayoutContextProvider({ children }) {
  const { selectedApp } = useAvContext();
  const pathsObject = (appsSideBar?.[selectedApp] || [])
    .flatMap(({ subMenu }) => subMenu)
    .reduce((prev, { path, name }) => ({ ...prev, [path]: name }), {});

  const [layoutWidths, setLayoutWidths] = useState(JSON.parse(localStorage.getItem('layoutWidths') || '{}'));

  useEffect(() => {
    const isStaging = envVariables.VITE_ENV === 'stg';
    const path = pathsObject[window.location.pathname.split('/')[3]];
    document.title = `Zscaler Risk Platform${isStaging ? ' STG' : ''}${path ? ` | ${path}` : ''}`;
  }, [window.location.pathname, selectedApp]);

  const setWidths = (key, value) => {
    if (key === 'sideBar') {
      document.body.style.setProperty('--side-bar-width', `${value}px`);
    }
    setLayoutWidths(prev => {
      const newObj = { ...prev, [key]: value };
      localStorage.setItem('layoutWidths', JSON.stringify(newObj));
      return newObj;
    });
    setTimeout(() => dispatchEvent(new Event('resize')), 300);
  };

  const value = useMemo(() => ({ layoutWidths, setLayoutWidths: setWidths }), [layoutWidths]);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
}

LayoutContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
export default LayoutContextProvider;

type LayoutContextType = {
  layoutWidths: Record<string, number>;
  setLayoutWidths: (key: string, value: any) => void;
};
