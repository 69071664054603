import { ProjectionID, SortBy, TopResults } from '../views/CustomDashboards/types';
import { Filter } from './filter.types';

export interface QueryObjectProto {
  dims: Dim[];
  metrics: Metric[];
  scripts?: FieldScript[];
  filter?: Filter;
  groupBy?: string[];
  sorting?: SortBy[];
  top?: TopResults;
  sourceProjection: ProjectionID;
}

export interface Dim {
  fieldPath?: string;
  alias?: string;
}

export interface Metric {
  name?: string;
  agg: FieldFilterAndAgg;
}

export type FieldFilterAndAgg = {
  aggName: string;
  args: { stringValue }[];
  function: string | AggregationFunction;
  filter?: Filter;
};

export type FieldScript = {
  script: string;
  aggNames?: string[];
  evalStrategy: Strategy;
};

export enum Strategy {
  CEL = 'CEL',
  PYTHON_SCRIPT_PER_FIELD = 'PYTHON_SCRIPT_PER_FIELD',
}

export enum AggregationFunction {
  AVG = 'AVG',
  COLLECT = 'COLLECT',
  COLLECT_DISTINCT = 'COLLECT_DISTINCT',
  COUNT = 'COUNT',
  DISTINCT_COUNT = 'DISTINCT_COUNT',
  MAX = 'MAX',
  MIN = 'MIN',
  SUM = 'SUM',
  MAJORITY = 'MAJORITY',
  UNANIMOUS_OR_NULL = 'UNANIMOUS_OR_NULL',
  COLLECT_DISTINCT_FLAT = 'COLLECT_DISTINCT_FLAT',
  MIN_BY = 'MIN_BY',
  MAX_BY = 'MAX_BY',
}
