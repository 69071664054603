import { createFileRoute } from '@tanstack/react-router';
import { PAGE_PATHS } from '../../../../types';

const path = PAGE_PATHS.ASSETS;
export const Route = createFileRoute('/$accountId/caasm/assets/')({
  staticData: {
    path,
    key: 'Asset',
  },
});
