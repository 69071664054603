import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useRouter } from '@tanstack/react-router';
import { useAvContext } from '../context/AvContextProvider';
import { SearchContext } from '../context/SearchContextProvider';
import { FeatureFlags } from '../types';
import { useSearchParams } from '../utils/AvRouter';

const transformFilter = (filter: Record<string, any>) => {
  const newFilter: Record<string, any> = Object.keys(filter).reduce((obj, key) => {
    const filteredKey = key.replace('amp;', '');
    return filter[key] ? { ...obj, [filteredKey]: filter[key] } : obj;
  }, {});
  return Object.entries(newFilter).length ? newFilter : undefined;
};

export const useCustomSearchParams = ({
  defaultFilter = {},
  dateType = [],
  shouldBeArray,
  contextSearchKey = '',
}: {
  defaultFilter?: Record<string, string | string[] | Date | Date[] | number | null>;
  dateType?: string[];
  shouldBeArray?: (key: string) => boolean;
  contextSearchKey?: string;
}) => {
  const { featureFlags } = useAvContext();
  const { searchContext, setSearchContext } = useContext(SearchContext);
  const [search, setSearch] = useSearchParams(transformFilter(defaultFilter));
  const router = useRouter();
  const firstRender = useRef(true);
  const mountedPath = useMemo(() => window.location.pathname, []);
  const newSearch = useMemo(
    () =>
      Object.entries(search).reduce((acc, [key, val]) => {
        if (val === undefined) {
          return acc;
        }
        if (Array.isArray(val)) {
          return {
            ...acc,
            [key.replaceAll('+', ' ')]: val.map(v => (typeof v === 'string' ? v.replace(/(?<!\b[A-Z]{2,3})\+/g, ' ') : v)),
          };
        }
        const value = val === null ? '' : typeof val === 'string' ? val.replace(/(?<!\b[A-Z]{2,3})\+/g, ' ') : val;
        return {
          ...acc,
          [key.replaceAll('+', ' ')]: shouldBeArray?.(key) ? (value === '' ? [] : [value === 'null' ? null : value]) : value,
        };
      }, {}),
    []
  );

  useEffect(() => {
    if (mountedPath === window.location.pathname && !router.state.isLoading) {
      if (searchContext[contextSearchKey]) {
        setSearch(transformFilter(searchContext[contextSearchKey]), { replace: true });
      }
    }
  }, [window.location.search]);

  const setSearchParam = useCallback(
    filter => {
      if (mountedPath === window.location.pathname) {
        if (contextSearchKey && featureFlags[FeatureFlags.KeepSessionFilters]) {
          setSearchContext(prev => ({ ...prev, [contextSearchKey]: { ...defaultFilter, ...transformFilter(filter) } }));
        }
        setSearch(transformFilter(filter), { replace: true });
      }
    },
    [setSearch, defaultFilter]
  );

  const entriesObj = useMemo(() => {
    const value = searchContext[contextSearchKey as string] || {
      ...defaultFilter,
      ...handleDateType(dateType, firstRender.current ? newSearch : search),
    };
    firstRender.current = false;
    return value;
  }, [search, searchContext]);

  return [entriesObj, setSearchParam] as [Record<string, any>, (filter: any) => void];
};

const handleDateType = (dateTypes, search) => {
  if (dateTypes && !dateTypes.length) {
    return search;
  }
  return Object.entries(search).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return { ...acc, [key]: value.map(val => (dateTypes.includes(key) ? (!val ? '' : new Date(val)) : val)) };
    }
    return { ...acc, [key]: dateTypes.includes(key) ? (!value ? '' : new Date(value as string)) : value };
  }, {});
};
