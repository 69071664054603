import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useAvContext } from './AvContextProvider';

interface SearchContextValue {
  searchContext: Record<string, any>;
  setSearchContext: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const SearchContext = React.createContext<SearchContextValue>({
  searchContext: {},
  setSearchContext: () => {},
});

interface SearchContextProviderProps {
  children: ReactNode;
}

function SearchContextProvider({ children }: SearchContextProviderProps) {
  const [searchContext, setSearchContext] = useState({});
  const { accountId } = useAvContext();

  useEffect(() => {
    setSearchContext({});
  }, [accountId]);

  const value = useMemo(() => ({ searchContext, setSearchContext }), [searchContext]);

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

export default SearchContextProvider;
