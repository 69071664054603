import { APP_PATHS, FeatureFlags, Permission, PermissionAppName, PermissionEntitiesNames, PermissionModelEntityName } from '../../types';
import { FilterFuncParam, PermissionCategory } from './types';

export const PermissionCategories: PermissionCategory[] = [
  {
    categoryDisplayName: 'Platform',
    permissionEntities: [
      {
        name: PermissionEntitiesNames.MODEL,
        displayName: 'Model Management',
        disabledPermissions: [Permission.AUDIT],
        tooltip: `Manage data model entities and their fields`,
      },
      {
        name: PermissionEntitiesNames.SOURCE_MAPPING,
        displayName: 'Data Source Mapping',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.DELETE],
        tooltip: 'View and manage data source mapping',
      },
      {
        name: PermissionEntitiesNames.SOURCE,
        displayName: 'Data Source',
        disabledPermissions: [Permission.AUDIT],
        tooltip: 'View, create and manage data sources',
      },
      {
        name: PermissionEntitiesNames.VIEWS,
        displayName: 'User Saved View',
        disabledPermissions: [Permission.AUDIT],
        tooltip: 'View, create and manage user saved views across the platform',
      },
      {
        name: PermissionEntitiesNames.INTEGRATION,
        displayName: 'Outegrations',
        disabledPermissions: [Permission.AUDIT],
        tooltip: 'View and manage outegrations',
      },
      {
        name: PermissionEntitiesNames.AUTHENTICATION,
        displayName: 'Authentications',
        disabledPermissions: [Permission.AUDIT],
        tooltip: 'View, create and manage authentication sources',
      },
    ],
  },
  {
    categoryDisplayName: 'Explore',
    permissionEntities: [
      {
        name: PermissionEntitiesNames.SEARCH,
        displayName: 'Search',
        disabledPermissions: [Permission.AUDIT],
        tooltip: 'View and search logs',
      },
      {
        name: PermissionEntitiesNames.REPORT,
        displayName: 'Reports & Custom Dashboards',
        disabledPermissions: [Permission.AUDIT],
        tooltip: 'View and manage custom dashboards and reports',
      },
    ],
  },
  {
    filterFunc: ({ apps }) => filterApps(apps, [APP_PATHS.VULNERABILITIES]),
    categoryDisplayName: 'Vulnerabilities App',
    permissionEntities: [
      {
        name: PermissionEntitiesNames.ANALYTICS,
        displayName: 'Analytics & Insights',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.UPDATE, Permission.DELETE],
        categories: [PermissionAppName.VULNERABILITIES],
        tooltip: 'Built-in vulnerability dashboard and analytics',
      },
      {
        name: PermissionEntitiesNames.OPT,
        displayName: 'Ticket Operational View',
        disabledPermissions: [Permission.AUDIT, Permission.DELETE],
        categories: [PermissionModelEntityName.TICKET],
        tooltip: 'View and manage tickets',
      },
      {
        name: PermissionEntitiesNames.INT,
        displayName: 'Ticket Outegrations',
        disabledPermissions: [Permission.AUDIT, Permission.READ, Permission.DELETE],
        categories: [PermissionModelEntityName.TICKET],
        tooltip: 'Trigger 3rd party outegrations from tickets',
      },
      {
        name: PermissionEntitiesNames.SETTINGS,
        displayName: 'Ticket Settings',
        disabledPermissions: [Permission.AUDIT],
        categories: [PermissionModelEntityName.TICKET, PermissionModelEntityName.FINDING],
        tooltip: 'Tickets scoring, grouping rules and life cycle customization',
      },
      {
        name: PermissionEntitiesNames.OPT,
        displayName: 'Finding Operational View',
        disabledPermissions: [Permission.AUDIT, Permission.DELETE],
        categories: [PermissionModelEntityName.FINDING],
        tooltip: 'View and manage findings',
      },
      {
        name: PermissionEntitiesNames.OPT,
        displayName: 'Asset Operational View',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.DELETE],
        categories: [PermissionModelEntityName.ASSET],
        tooltip: 'View and manage assets',
      },
      {
        name: PermissionEntitiesNames.EXCEPTION,
        displayName: 'Exception Operational View',
        disabledPermissions: [Permission.DELETE],
        categories: [PermissionAppName.VULNERABILITIES],
        tooltip: 'View and manage exceptions',
      },
      {
        name: PermissionEntitiesNames.SETTINGS,
        displayName: 'Exception Settings',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.DELETE],
        categories: [PermissionModelEntityName.EXCEPTION],
        tooltip: 'Exceptions form, assignment rules and notifications customization',
        ff: FeatureFlags.ExceptionsM2,
      },
    ],
  },
  {
    filterFunc: ({ apps }) => filterApps(apps, [APP_PATHS.DETECTIONS]),
    categoryDisplayName: 'Detection App',
    permissionEntities: [
      {
        name: PermissionEntitiesNames.RULE,
        displayName: 'Rules',
        disabledPermissions: [Permission.AUDIT],
        categories: [PermissionAppName.DETECTIONS],
        tooltip: 'View and manage alert rules',
      },
      {
        name: PermissionEntitiesNames.GLOBAL_SCRIPT,
        displayName: 'Global Scripts',
        disabledPermissions: [Permission.AUDIT],
        categories: [PermissionAppName.DETECTIONS],
        tooltip: 'View and manage global scripts',
      },
      {
        name: PermissionEntitiesNames.LOOKUP_TABLE,
        displayName: 'Lookup Tables',
        disabledPermissions: [Permission.AUDIT],
        categories: [PermissionAppName.DETECTIONS],
        tooltip: 'View and manage look up tables',
      },
      {
        name: PermissionEntitiesNames.ANALYTICS,
        displayName: 'Analytics & Insights',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.UPDATE, Permission.DELETE],
        categories: [PermissionAppName.DETECTIONS],
        tooltip: 'Built-in detections dashboard and analytics',
      },
      {
        name: PermissionEntitiesNames.OPT,
        displayName: 'Incident Operational View',
        disabledPermissions: [Permission.AUDIT, Permission.DELETE],
        categories: [PermissionModelEntityName.INCIDENT],
        tooltip: 'View and manage incidents',
      },
      {
        name: PermissionEntitiesNames.SETTINGS,
        displayName: 'Incident Settings',
        disabledPermissions: [Permission.AUDIT],
        categories: [PermissionModelEntityName.INCIDENT, PermissionModelEntityName.ALERT],
        tooltip: 'Incident life cycle and grouping rules customization',
      },
      {
        name: PermissionEntitiesNames.OPT,
        displayName: 'Alert Operational View',
        disabledPermissions: [Permission.AUDIT, Permission.DELETE],
        categories: [PermissionModelEntityName.ALERT],
        tooltip: 'View and manage alerts',
      },
    ],
  },
  {
    filterFunc: ({ apps }) => filterApps(apps, [APP_PATHS.INCIDENTS]),
    categoryDisplayName: 'Incidents App',
    permissionEntities: [
      {
        name: PermissionEntitiesNames.ANALYTICS,
        displayName: 'Analytics & Insights',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.UPDATE, Permission.DELETE],
        categories: [PermissionAppName.INCIDENTS],
        tooltip: 'Built-in detections dashboard and analytics',
      },
      {
        name: PermissionEntitiesNames.OPT,
        displayName: 'Incident Operational View',
        disabledPermissions: [Permission.AUDIT, Permission.DELETE],
        categories: [PermissionModelEntityName.INCIDENT],
        tooltip: 'View and manage incidents',
      },
      {
        name: PermissionEntitiesNames.SETTINGS,
        displayName: 'Incident Settings',
        disabledPermissions: [Permission.AUDIT],
        categories: [PermissionModelEntityName.INCIDENT],
        tooltip: 'Incident life cycle and grouping rules customization',
      },
    ],
  },
  {
    filterFunc: ({ apps }) => filterApps(apps, [APP_PATHS.RISK360]),
    categoryDisplayName: 'Risk360 App',
    permissionEntities: [
      {
        name: PermissionEntitiesNames.OPT,
        displayName: 'Factor',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.UPDATE, Permission.DELETE],
        categories: [PermissionModelEntityName.FACTOR],
        tooltip: 'Built-in factors dashboard and analytics',
      },
      {
        name: PermissionEntitiesNames.EXE_RULE,
        displayName: 'Execution Rule',
        disabledPermissions: [Permission.AUDIT],
        categories: [PermissionAppName.RISK360],
        tooltip: 'View and manage execution rules',
      },
      {
        name: PermissionEntitiesNames.BUILTIN_EXE_RULE,
        displayName: 'Built In Execution Rule',
        disabledPermissions: [Permission.AUDIT],
        categories: [PermissionAppName.RISK360],
        tooltip: 'View and manage built in execution rules',
      },
    ],
  },
  {
    filterFunc: ({ apps }) => filterApps(apps, [APP_PATHS.CAASM]),
    categoryDisplayName: 'Caasm App',
    permissionEntities: [
      {
        name: PermissionEntitiesNames.OPT,
        displayName: 'Policy Violations',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.DELETE],
        categories: [PermissionModelEntityName.POLICYVIOLATION],
        tooltip: 'View and manage policy violations',
      },
      {
        name: PermissionEntitiesNames.INT,
        displayName: 'Policy Violations Outegrations',
        disabledPermissions: [Permission.AUDIT, Permission.READ, Permission.DELETE],
        categories: [PermissionModelEntityName.POLICYVIOLATION],
        tooltip: 'Trigger 3rd party outegrations from policy violations',
      },
      {
        name: PermissionEntitiesNames.EXE_RULE,
        displayName: 'Policy Rule',
        disabledPermissions: [Permission.AUDIT],
        categories: [PermissionAppName.CAASM],
        tooltip: 'View and manage policy rules',
      },
      {
        name: PermissionEntitiesNames.ANALYTICS,
        displayName: 'Analytics & Insights',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.UPDATE, Permission.DELETE],
        categories: [PermissionAppName.CAASM],
        tooltip: 'Built-in CAASM dashboards and analytics',
      },
      {
        name: PermissionEntitiesNames.OPT,
        displayName: 'Asset Operational View',
        disabledPermissions: [Permission.AUDIT, Permission.CREATE, Permission.DELETE],
        categories: [PermissionModelEntityName.ASSET],
        tooltip: 'View and manage assets',
      },
      {
        name: PermissionEntitiesNames.INT,
        displayName: 'Asset Outegrations',
        disabledPermissions: [Permission.AUDIT, Permission.READ, Permission.DELETE],
        categories: [PermissionModelEntityName.ASSET],
        tooltip: 'Trigger 3rd party outegrations from assets',
      },
    ],
  },
];

const filterApps = (allAccountApps, filteredApps) => filteredApps.some(app => allAccountApps.includes(app));

export const filterPermissionEntitiesWithFunc = (arr, filterFuncParam: FilterFuncParam) =>
  arr.reduce((filtered, item) => {
    if (!item.filterFunc || item.filterFunc(filterFuncParam)) {
      const { permissionEntities, ...filteredItem } = item;
      return [
        ...filtered,
        {
          ...filteredItem,
          ...(permissionEntities && { permissionEntities: filterPermissionEntitiesWithFunc(permissionEntities, filterFuncParam) }),
        },
      ];
    }
    return filtered;
  }, []);
