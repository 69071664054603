import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags } from '../types';
import { FieldType } from '../views/CustomDashboards/types/types';
import AvButton, { ButtonKinds, ButtonPosition } from './AvButton';
import AvMenu from './AvMenu';
import { flex } from './AvThemeProvider';
import { ReactComponent as FilterActive } from '../assets/colorful/FilterActive.svg';
import { ReactComponent as Dimension } from '../assets/Dimension.svg';
import { ReactComponent as Filter } from '../assets/Filter.svg';
import { ReactComponent as Measurements } from '../assets/Measurements.svg';

const filterIcon = <Filter style={{ width: 20, height: 20 }} />;
const filterActiveIcon = <FilterActive style={{ width: 20, height: 20 }} />;

export enum ButtonTypes {
  Measurements = 'Measurements',
  Dimensions = 'Dimensions',
  All = 'All',
  InUse = 'InUse',
  TopFields = 'TopFields',
  Populated = 'Populated',
}

const INCLUDE_EMPTY_FIELDS = 'Include Empty Fields';
const includeUnpopulatedFieldsOption = { title: INCLUDE_EMPTY_FIELDS };

interface Props {
  activeFilterType: FieldType | null;
  topFields: {
    isTopFieldsFilterActive: boolean;
    filterByTopFields: VoidFunction;
  };
  inUse?: {
    isInUseFilterActive: boolean;
    filterByUsedFields: VoidFunction;
  };
  unpopulated: {
    isUnpopulatedFieldsActive: boolean;
    filterByUnpopulatedFields: VoidFunction;
  };
  onClickFilterType: (filterType: FieldType) => void;
  hiddenButtons?: Set<ButtonTypes>;
  maxWidth?: string;
}

export default function FiltersListToolbar({
  activeFilterType,
  onClickFilterType,
  topFields: { isTopFieldsFilterActive, filterByTopFields },
  inUse,
  unpopulated: { isUnpopulatedFieldsActive, filterByUnpopulatedFields },
  hiddenButtons = new Set([ButtonTypes.InUse]),
  maxWidth = '300px',
}: Props) {
  const theme = useTheme();
  const { featureFlags } = useAvContext();

  const menuOnChange = () => {
    filterByUnpopulatedFields();
  };

  return (
    <Box
      sx={{
        ...flex.justifyBetweenCenter,
        mt: 1,
        color: isTopFieldsFilterActive ? theme.palette.colors.neutrals[800] : theme.palette.colors.neutrals[600],
        maxWidth,
      }}>
      <Box sx={{ ...flex.center, gap: '4px' }}>
        {isTopFieldsFilterActive || inUse?.isInUseFilterActive ? filterActiveIcon : filterIcon}
        {!hiddenButtons.has(ButtonTypes.TopFields) && (
          <Button
            size="xSmall"
            variant="filter"
            onClick={filterByTopFields}
            className={isTopFieldsFilterActive ? 'active-filter' : undefined}
            sx={{ backgroundColor: theme.palette.colors.neutrals[300] }}>
            Top Fields
          </Button>
        )}

        {!hiddenButtons.has(ButtonTypes.InUse) && (
          <Button
            size="xSmall"
            variant="filter"
            onClick={inUse?.filterByUsedFields}
            className={inUse?.isInUseFilterActive ? 'active-filter' : undefined}
            sx={{ backgroundColor: theme.palette.colors.neutrals[300] }}>
            In Use
          </Button>
        )}
      </Box>

      <Box sx={{ ...flex.row, gap: 1 / 2 }}>
        <Box sx={{ ...flex.row, gap: '1px', backgroundColor: theme.palette.colors.neutrals[400], borderRadius: '10px' }}>
          {!hiddenButtons.has(ButtonTypes.All) && (
            <AvButton
              kind={ButtonKinds.TOGGLE}
              value={FieldType.All}
              onClick={() => onClickFilterType(FieldType.All)}
              isActive={activeFilterType === FieldType.All}
              tooltip="All"
              position={ButtonPosition.LEFT}>
              All
            </AvButton>
          )}
          {!hiddenButtons.has(ButtonTypes.Measurements) && (
            <AvButton
              kind={ButtonKinds.TOGGLE}
              value={FieldType.Measurements}
              onClick={() => onClickFilterType(FieldType.Measurements)}
              isActive={activeFilterType === FieldType.Measurements}
              tooltip="Measurements"
              position={hiddenButtons.has(ButtonTypes.All) ? ButtonPosition.LEFT : ButtonPosition.CENTER}>
              <Measurements />
            </AvButton>
          )}

          {!hiddenButtons.has(ButtonTypes.Dimensions) && (
            <AvButton
              kind={ButtonKinds.TOGGLE}
              value={FieldType.Dimensions}
              onClick={() => onClickFilterType(FieldType.Dimensions)}
              isActive={activeFilterType === FieldType.Dimensions}
              tooltip="Dimensions"
              position={ButtonPosition.RIGHT}>
              <Dimension />
            </AvButton>
          )}
        </Box>

        {featureFlags[FeatureFlags.PopulatedFields] && !hiddenButtons.has(ButtonTypes.Populated) && (
          <AvMenu
            options={[includeUnpopulatedFieldsOption]}
            isMultiple
            value={isUnpopulatedFieldsActive ? [INCLUDE_EMPTY_FIELDS] : []}
            onChange={menuOnChange}
            showSelection={false}
            sx={{ padding: 0 }}
          />
        )}
      </Box>
    </Box>
  );
}
