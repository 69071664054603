export enum WorkflowType {
  BulkUpdateTickets = 'BulkUpdateTickets',
  ManualOverride = 'ManualOverride',
  BulkCreateIssues = 'BulkCreateIssues',
  SplitTicket = 'SplitTicket',
  MergeTickets = 'MergeTickets',
  ManualGrouping = 'ManualGrouping',
  ManualCreateIncident = 'ManualCreateIncident',
  ReprocessAggProj = 'ReprocessAggProj',
  IntegrationAttachment = 'IntegrationAttachment',
  DataDeletion = 'DataDeletion',
  AccountProvisioning = 'AccountProvisioning',
  AccountDeprovisioning = 'AccountDeprovisioning',
  ExportDashboard = 'ExportDashboard',
  RunFactorRules = 'RunFactorRules',
  RunPolicyRules = 'RunPolicyRules',
  ExportQuery = 'ExportQuery',
  GlobalFactorsPublish = 'GlobalFactorsPublish',
  DeleteUsers = 'DeleteUsers',
  Default = 'Default',
  DeletePolicyRules = 'DeletePolicyRules',
  DeleteFactorRules = 'DeleteFactorRules',
}
