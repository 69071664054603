import { gql } from '@apollo/client';
import { Query } from '@tanstack/react-query';
import type { QueryObjectProps } from './useQueryObjectAsyncWithCachedResults';

type Row = {
  data: Record<string, unknown>;
  id: string;
};

type DwQueryResponse = {
  asyncResult?: 'ASYNC_DONE' | 'ASYNC_RUNNING';
  queryId?: string;
  shouldPollWithQueryId?: boolean | null;
  responseCalculatedAt?: string | null;
  totalRowCount?: number | null;
  totals?: Row[] | null;
  rows: Row[];
};

export type AsyncQueryWithCacheResponse = {
  queryId?: string;
  responseCalculatedAt?: string | null;
  shouldPollWithQueryId?: boolean | null;
  maybeResponse?: DwQueryResponse | null;
};

export const QUERY = gql`
  query queryObjectAsync($req: Json!) {
    queryObjectAsync(value: $req) {
      queryId
      shouldPollWithQueryId
      responseCalculatedAt
      maybeResponse {
        asyncResult
        queryId
        rows {
          data
          id
        }
        totalRowCount
        totals {
          data
          id
        }
      }
    }
  }
`;

export const POLLING = gql`
  query asyncPollDwQuery($req: Json!) {
    asyncPollDwQuery(value: $req) {
      rows {
        data
        id
      }
      totalRowCount
      totals {
        data
        id
      }
      asyncResult
    }
  }
`;

export const POLLING_INTERVAL = 3_000;

export function shouldEnablePolling(data?: AsyncQueryWithCacheResponse) {
  if (!data) {
    return false;
  }

  if (typeof data.shouldPollWithQueryId === 'boolean') {
    return data.shouldPollWithQueryId;
  }

  return Boolean(data?.queryId);
}

export function shouldRefetch(query: Query) {
  const response = query.state.data;
  return Boolean(response && typeof response === 'object' && 'asyncResult' in response && response.asyncResult === 'ASYNC_RUNNING');
}

export const formatTags = (tags?: QueryObjectProps['tags']) =>
  tags
    ? Object.entries(tags)
        .filter(([, value]) => Boolean(value))
        .map(([key, value]) => `${key}:${value}`)
    : undefined;
