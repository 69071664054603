import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { useAvContext } from '../context/AvContextProvider';
import { QueryKey } from '../utils/queryKey';

export const GET_USERS_INFO = gql`
  query findUsersMetadata($userIds: [String]) {
    findUsersMetadata(userIds: $userIds) {
      id
      firstName
      lastName
      email
      currentAccountRoleId
    }
  }
`;

const emptyRes = { '-1': 'Zscaler' };

export const GetUsersInfo = (
  userIds,
  onSuccess = data =>
    data?.reduce(
      (obj, { id, firstName, lastName }) => ({
        ...obj,
        [id]: `${firstName} ${lastName}`,
      }),
      emptyRes
    ),
  defaultRes: object = emptyRes
) => {
  const { api } = useAvContext();

  const {
    data: usersInfo = defaultRes,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [QueryKey.UsersInfo, ...userIds, onSuccess.toString()],
    queryFn: () => api(GET_USERS_INFO, { options: { userIds }, onSuccess: ({ data }) => onSuccess(data?.findUsersMetadata) }),
    enabled: !!userIds.length,
    gcTime: 0,
  });
  return { usersInfo, refetch, isLoading };
};
