import { DwQueryRequest } from '../CustomDashboards/types';
import { FileFormat } from '../Reports/types';
import { MappingType } from '../Sources/Mapping/mapping.types';

export const TARGET_MATCHING_TYPE_ENUM = { ALWAYS: 'ALWAYS', NEVER: 'NEVER', CONDITION: 'CONDITION' };

export type SchemaField = {
  systemName: string;
  displayName: string;
  inputType: string;
  availableOptions: string[];
  required: boolean;
  fieldCategory: string;
  integrationOriginalField: {
    name: string;
    systemName: string;
    inputType: string;
    itemsType: string;
    customFieldType: string;
  };
};

export type AlertDestinationConfig = Record<string, string | string[] | boolean>;

export type CopyMappingDetails = {
  mapping: TMapping;
  integrationType?: string;
  projectionName?: string;
};

export type TMapping = {
  id?: string;
  syncComments: boolean;
  syncCommentsTriggers: TriggerField[];
  createMapping: {
    fieldMapping: FieldMapping[];
  };
  createMappingEnabled: boolean;
  syncMapping: {
    fieldMapping: FieldMapping[];
  };
  syncMappingEnabled: boolean;
  webhookMapping: {
    fieldMapping: FieldMapping[];
  };
  webhookMappingEnabled: boolean;
  attachmentOnCreate?: IntegrationAttachment;
  attachmentOnSync?: IntegrationAttachment;
};

export interface IntegrationAttachment {
  fileName: string;
  format: FileFormat;
  query: DwQueryRequest;
  triggerFields: TriggerField[];
  triggerOnFieldsChange: boolean;
}

export enum TriggerType {
  VALUE = 'VALUE',
  EMPTY = 'EMPTY',
  ANY = 'ANY',
}

export interface TriggerField {
  field?: string;
  from: { value: null | string; type: TriggerType };
  to: { value: null | string; type: TriggerType };
}

export type IntegrationWithConfig = Omit<Integration, 'redactedConfig'> & { config: AlertDestinationConfig };

export type Integration = {
  id?: string;
  name: string;
  projectionName?: string;
  active: boolean;
  integrationType: string;
  category: string;
  redactedConfig: AlertDestinationConfig;
  schema?: SchemaField[];
  authenticationSourceId?: string;
  mapping: TMapping;
  matchingCondition?: {
    stringCondition?: any;
    fieldName?: string;
  } | null;
  targetMatchingType: string;
  gatewayId?: string;
};

export type FieldMapping = {
  modelFieldName: null | string;
  fieldType: string;
  mappingType: MappingType;
  mappingValue: string;
  mandatory?: boolean;
  deleted?: boolean;
};

export const TARGET_MATCHING_CATEGORY_ENUM = {
  ALERTS: 'Alerts',
  AUTOMATION: 'Automation',
  WORK_MANAGEMENT: 'Work Management',
  STORAGE: 'Storage',
  CMDB: 'CMDB',
  EXCEPTIONS: 'Exceptions',
};

export enum TestType {
  'Create' = 'Create',
}

export enum TestTypeKeys {
  'Create' = 'createMapping',
}

export type MappingGroup = {
  fieldMapping: FieldMapping[];
};

export enum IntegrationMappingType {
  CREATE = 'CREATE',
  SYNC = 'SYNC',
  WEBHOOK = 'WEBHOOK',
}

export const ProjectionsByCategory = {
  [TARGET_MATCHING_CATEGORY_ENUM.CMDB]: ['uber_assets', 'uber_policy_populations', 'uber_policy_violations'],
  [TARGET_MATCHING_CATEGORY_ENUM.WORK_MANAGEMENT]: ['tickets', 'incidents', 'uber_policy_populations', 'uber_policy_violations'],
  [TARGET_MATCHING_CATEGORY_ENUM.EXCEPTIONS]: ['uber_exceptions'],
};
