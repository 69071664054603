import React from 'react';
import { Box, Button } from '@mui/material';
import AvAttentionBox, { AttentionBoxVariant } from '../../components/AvAttentionBox';
import { flex } from '../../components/AvThemeProvider';
import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS } from '../../types';
import { Link } from '../../utils/AvRouter';
import { SettingsName, SettingType } from './hooks';
import { ReactComponent as LinkIcon } from '../../assets/Link.svg';

function SettingNotSyncedBox({ onClick, settingsType, isSynced }: { onClick: () => void; settingsType: SettingType; isSynced?: boolean }) {
  const { getPathName } = useAvContext();

  const settingsToPagePathParams = {
    [SettingType.GroupingRules]: [`${APP_PATHS.SETTINGS}/${PAGE_PATHS.RULE_SET}`, undefined, APP_PATHS.VULNERABILITIES],
    [SettingType.Score]: [`${APP_PATHS.SETTINGS}/${PAGE_PATHS.SCORE}`, undefined, APP_PATHS.VULNERABILITIES],
    [SettingType.SeveritySLA]: [`${APP_PATHS.SETTINGS}/${PAGE_PATHS.SEVERITY_SLA}`, undefined, APP_PATHS.VULNERABILITIES],
    [SettingType.UnificationRules]: [PAGE_PATHS.DATA_UNIFICATION, undefined, APP_PATHS.PLATFORM],
  };
  return (
    <AvAttentionBox
      content={
        <Box
          sx={{
            ...flex.itemsCenter,
            fontSize: 14,
            fontWeight: 400,
            gap: 1,
            a: { color: theme => theme.palette.primary.main, fontWeight: 600 },
          }}>
          Configured through{' '}
          <Link
            style={{ ...flex.itemsCenter, gap: 1 }}
            to={
              getPathName(
                ...(isSynced && settingsToPagePathParams[settingsType]
                  ? settingsToPagePathParams[settingsType]
                  : [PAGE_PATHS.MODEL_MANAGEMENT, undefined, APP_PATHS.PLATFORM])
              ).to
            }>
            {isSynced && SettingsName[settingsType] ? SettingsName[settingsType] : 'Data Model'}
            <LinkIcon />
          </Link>
          <Button variant="outlined" size="xSmall" onClick={onClick}>
            Unlink & Override
          </Button>
        </Box>
      }
      variant={AttentionBoxVariant.Warning}
    />
  );
}

export default SettingNotSyncedBox;
