import React from 'react';
import { Box, useTheme } from '@mui/material';
import { App } from '../../types/sideBar.types';
import { Link } from '../../utils/AvRouter';
import { iconSize } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import { ActionButton } from '../Table/Utils';
import { ReactComponent as StarFull } from '../../assets/StarFull.svg';
import { ReactComponent as StarLine } from '../../assets/StarLine.svg';

const starFullIcon = theme => <StarFull style={{ color: theme.palette.colors.complementary[500], ...iconSize(20) }} />;
const starLineIcon = <StarLine style={iconSize(20)} />;

interface AppItemProps extends App {
  isSelected: boolean;
  isFavorite?: boolean;
  disableHover?: boolean;
  showDescription?: boolean;
  to?: string;
  toggleFavoriteApp?: () => void;
  appIconSize?: number;
}
export const AppItem: React.FC<AppItemProps> = ({
  name,
  icon,
  imageFull,
  appIconSize,
  isSelected,
  to,
  disableHover,
  showDescription,
  isFavorite = true,
  toggleFavoriteApp,
  description,
  backgroundColor,
}) => {
  const theme = useTheme();
  return (
    <Link to={to}>
      <Box sx={theme => appIconStyle(theme, isSelected, disableHover, backgroundColor, appIconSize, imageFull)}>
        {toggleFavoriteApp &&
          ActionButton(
            isFavorite ? 'Remove from favorite' : 'Add to Favorite',
            toggleFavoriteApp,
            isFavorite ? starFullIcon(theme) : starLineIcon
          )}
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            borderRadius: '8px',
            position: 'relative',
          }}>
          <Box className="icon-container">{icon}</Box>
        </Box>
        <Box sx={{ ...flex.col }}>
          <Box
            sx={{
              fontWeight: 600,
              color: (theme: any) => (!disableHover ? theme.palette.colors.white : theme.palette.colors.neutrals[800]),
            }}>
            {name === 'CAASM' ? 'Assets' : name}
          </Box>
          {showDescription && <Box sx={{ fontWeight: 400, color: (theme: any) => theme.palette.colors.neutrals[600] }}>{description}</Box>}
        </Box>
      </Box>
    </Link>
  );
};
export const appIconStyle = (theme, isSelected, disableHover, backgroundColor: string, appIconSize = 22, imageFull = undefined) => ({
  ...flex.center,
  pl: '4px',
  pr: '12px',
  py: '4px',
  gap: '8px',
  borderRadius: '10px',
  cursor: 'pointer',
  color: theme.palette.colors.neutrals[150],
  border: `1px solid transparent`,
  transition: theme.transitions.create(['border-color'], {
    duration: theme.transitions.duration.shortest,
  }),
  svg: {
    ...iconSize(appIconSize),
  },
  ...opacityGradientBgColor(theme, imageFull),
  '.icon-container': {
    p: '5px',
    borderRadius: '8px',
    ...flex.center,
  },
  ...(disableHover && { '.icon-container::before': { opacity: 1 } }),
  ':hover': !disableHover && {
    borderColor: theme.palette.colors.primary[400],
    '.icon-container::before': { opacity: 1 },
  },
  ...(isSelected
    ? {
        '.icon-container::before': { opacity: 1 },
        borderColor: 'none',
        background: 'linear-gradient(45deg, rgba(148, 184, 255, 0.30) -3.85%, rgba(238, 243, 253, 0.30) 93.41%)',
        ':hover': !disableHover && {
          background: 'linear-gradient(45deg, rgba(148, 184, 255, 0.15) -3.85%, rgba(238, 243, 253, 0.15) 93.41%)',
        },
      }
    : {}),
});

export const appBarItemSelectedStyle = theme => ({
  svg: {
    color: theme.palette.colors.neutrals[100],
    ...iconSize(22),
  },
  borderColor: 'none',
  background: 'linear-gradient(45deg, rgba(148, 184, 255, 0.30) -3.85%, rgba(238, 243, 253, 0.30) 93.41%)',
});
export const appBarItemHoveredStyle = theme => ({
  ':hover': {
    borderColor: theme.palette.colors.primary[400],
    color: theme.palette.colors.neutrals[100],
  },
});

const opacityGradientBgColor = (theme, fullImage) => ({
  '.icon-container:before': {
    background: `url("${fullImage}")`,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.medium,
    }),
    content: '" "',
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '32px',
    height: '32px',
    opacity: 0,
  },
});
