import React, { useRef, useState } from 'react';
import { Box, ClickAwayListener, Divider, InputLabel, Paper, Popper, SxProps, useTheme } from '@mui/material';
import { emptyObject, iconSize } from '@utils/Utils';
import useGetAccountSources from '../../hooks/useGetAccountSources';
import { ALL_SOURCES } from '../../types/sources.types';
import AvItemWithLogoGroup, { IconSize } from '../AvItemWithLogoGroup';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import DataSourceSelect from '../DataSourceSelect';
import { IconVariant } from '../ItemWithLogo';
import SwitchToggle from '../SwitchToggle';
import { ReactComponent as ArrowDown } from '../../assets/Arrow Down.svg';
import { ReactComponent as Circle } from '../../assets/Circle.svg';
import { ReactComponent as Warning } from '../../assets/colorful/Info Full.svg';
import { ReactComponent as QuestionFull } from '../../assets/QuestionFull.svg';
import { ReactComponent as Unified } from '../../assets/Unified.svg';

const popupIcon = <ArrowDown style={iconSize(16)} />;
const questionFull = <QuestionFull />;
const unified = <Unified style={iconSize(20)} />;
const circle = <Circle style={iconSize(20)} />;
const warning = <Warning />;

interface UnifiedIngressToggleProps {
  isIngressExp: boolean;
  onChangeIngressExp: () => void;
  onChangeSources: (source) => void;
  sourcesValue: (string | typeof ALL_SOURCES)[];
  activeProjName?: string;
  enableAllSources?: boolean;
  sx?: SxProps;
  label?: string;
  showToggle?: boolean;
  multiSelect?: boolean;
}

const paddingY = 8;

const UnifiedIngressToggle: React.FC<UnifiedIngressToggleProps> = ({
  isIngressExp,
  onChangeIngressExp,
  onChangeSources,
  sourcesValue,
  activeProjName = 'uber_assets',
  enableAllSources = false,
  sx = emptyObject,
  label,
  showToggle = true,
  multiSelect = true,
}) => {
  const { palette } = useTheme();

  const { dataSources: availableSourceOption = [], isLoadingDataSources: isLoadingDetectionSources } = useGetAccountSources({
    projName: activeProjName,
  });

  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const boxRef = useRef();
  const warningIcon = <Box sx={{ ...flex.justifyEndCenter, ml: 9.5 }}>{warning}</Box>;
  return (
    <Box>
      {label && <InputLabel required>{label}</InputLabel>}
      <Box sx={{ ...flex.itemsCenter }}>
        <Box
          onClick={() => setIsPopperOpen(prev => !prev)}
          ref={boxRef}
          sx={{
            ...flex.itemsCenter,
            border: `1px solid ${palette.colors.neutrals[400]}`,
            borderRadius: 2.5,
            pr: 1.5,
            height: 32,
            width: 144,
            transition: theme => theme.transitions.create(['border-color']),
            borderColor: isPopperOpen ? theme => theme.palette.primary.main : undefined,
            ':hover': isPopperOpen ? undefined : { borderColor: theme => theme.palette.colors.neutrals[800] },
            ...sx,
          }}>
          <Box
            sx={{
              ...flex.center,
              backgroundColor: palette.colors.neutrals[500],
              border: `1px solid ${palette.colors.neutrals[500]}`,
              borderBottomLeftRadius: 9,
              borderTopLeftRadius: 9,
              height: '100%',
              width: 40,
              mr: 0.5,
              px: 0.7,
              color: palette.colors.neutrals[100],
            }}>
            {isIngressExp ? circle : unified}
          </Box>
          <Box
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '100%',
            }}>
            {isIngressExp ? (
              <AvItemWithLogoGroup
                variant={IconVariant.sourcesMapByName}
                items={sourcesValue.includes(ALL_SOURCES) ? availableSourceOption.map(({ name }) => name) : sourcesValue}
                size={IconSize.collapsed}
              />
            ) : (
              <Box sx={{ ml: 0.5 }}>Unified</Box>
            )}
          </Box>
          <Box
            sx={{
              ...flex.center,
              mt: '1px',
              width: 14,
              height: 14,
              p: '12px',
              borderRadius: 2.5,
              transform: isPopperOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              textOverflow: 'clip',
              color: theme => theme.palette.colors.neutrals[600],
              ':hover': {
                cursor: 'pointer',
                background: theme => theme.palette.colors.neutrals[150],
              },
            }}>
            {popupIcon}
          </Box>
        </Box>
        <Popper open={isPopperOpen} anchorEl={boxRef.current} placement="bottom-start" sx={{ zIndex: theme => theme.zIndex.modal }}>
          <ClickAwayListener onClickAway={() => setIsPopperOpen(prev => !prev)}>
            <Paper
              sx={{
                ...flex.colItemsStart,
                backgroundColor: `${palette.colors.neutrals[100]}`,
                padding: 1,
                width: 300,
                borderRadius: 1,
                gap: 1.5,
              }}>
              <Box
                sx={{
                  backgroundColor: palette.colors.neutrals[200],
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  ...flex.justifyStartCenter,
                  gap: 1,
                  width: '100%',
                  height: 36,
                  fontSize: 12,
                  fontWeight: 600,
                  color: palette.colors.neutrals[600],
                }}>
                <AvTooltip
                  title={
                    isIngressExp ? 'The field values from specific data sources' : 'The field value following the merge of multiple sources'
                  }>
                  <Box>{questionFull}</Box>
                </AvTooltip>
                {isIngressExp ? 'Original Source Value' : 'Merged Entity Value'}
              </Box>
              {showToggle && (
                <Box sx={{ ...flex.justifyStartCenter }}>
                  <SwitchToggle value={isIngressExp} onChange={onChangeIngressExp} sx={{ marginRight: 2, mb: 0.5 }} /> Use Original Source
                  {isIngressExp && <AvTooltip title="You must choose at least one source">{warningIcon} </AvTooltip>}
                </Box>
              )}
              {showToggle && isIngressExp && (
                <Box sx={{ ...flex.center, width: '100%' }}>
                  <Divider orientation="horizontal" sx={{ width: 280 }} />
                </Box>
              )}
              {isIngressExp && (
                <Box
                  sx={{
                    width: 284,
                    borderRadius: 1,
                  }}>
                  <DataSourceSelect
                    size="xSmall"
                    onChange={val => {
                      const newVal = enableAllSources
                        ? !sourcesValue.includes(ALL_SOURCES) && val.includes(ALL_SOURCES)
                          ? [ALL_SOURCES]
                          : sourcesValue.includes(ALL_SOURCES) && !val.includes(ALL_SOURCES)
                            ? []
                            : val.filter(source => source !== ALL_SOURCES)
                        : val;
                      onChangeSources(newVal);
                      if (!multiSelect) {
                        setIsPopperOpen(prev => !prev);
                      }
                    }}
                    options={
                      enableAllSources
                        ? [{ value: ALL_SOURCES, title: 'Any Original Source' }, ...availableSourceOption]
                        : availableSourceOption
                    }
                    filterValue={
                      enableAllSources && sourcesValue.includes(ALL_SOURCES)
                        ? [ALL_SOURCES, ...availableSourceOption.map(({ name }) => name)].filter(a => a)
                        : multiSelect
                          ? sourcesValue
                          : sourcesValue[0]
                    }
                    selectProps={{
                      showOnlyAutoComplete: true,
                      loading: isLoadingDetectionSources,
                      isMultiple: multiSelect,
                      muiProps: {
                        slotProps: {
                          paper: {
                            sx: { boxShadow: 'unset', '.MuiAutocomplete-listbox': { maxHeight: 200 - 2 * paddingY } },
                          },
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Box>
  );
};

export default UnifiedIngressToggle;
