import React, { useRef, useState } from 'react';
import { Box, capitalize, ClickAwayListener } from '@mui/material';
import useClickOutside from '../../hooks/useClickOutside';
import { Filter } from '../../types/filter.types';
import AvDateRangePicker from '../DatePicker/AvDateRangePicker';
import { formatDate } from '../DatePicker/utils';
import { FilterStyledPopper } from '../Select';
import TextInput from '../TextInput';
import { getDatePickerFromQueryObject, getStringConditionFromDatePicker, selectorWidth } from './Utils';

interface DateTypeBoxProps {
  filter: Filter;
  setFilter: any;
  size: any;
  isVertical: boolean;
}
const DateTypeBox: React.FC<DateTypeBoxProps> = ({ filter, setFilter, size, isVertical }) => {
  const [open, setOpen] = useState(false);
  const popperInnerRef = useRef(null);
  const buttonRef = useRef(null);
  const field = (filter.expression?.arrayCondition?.underlying || filter.expression)?.fieldName;

  const handleClose = () => {
    setOpen(false);
  };

  useClickOutside(popperInnerRef, handleClose);
  const dateValue = getDatePickerFromQueryObject(filter) || {
    value: {
      from: new Date(),
      to: new Date(),
    },
  };
  const displayValue =
    dateValue.preset?.type && dateValue.preset?.period
      ? `${capitalize(dateValue.preset.type)} ${dateValue.preset.count} ${capitalize(dateValue.preset.period)}`
      : [formatDate(dateValue.value.from, 'MMM dd, yyyy'), formatDate(dateValue.value.to, 'MMM dd, yyyy')].join(' - ');

  return (
    <Box key={field}>
      <Box ref={buttonRef} onClick={() => setOpen(!open)}>
        <TextInput
          size={size}
          style={{ width: isVertical ? selectorWidth : 250 }}
          disableClearButton
          placeholder="date"
          value={displayValue}
        />
      </Box>
      <FilterStyledPopper
        open={open}
        anchorEl={buttonRef.current}
        placement="bottom-start"
        sx={{
          minWidth: '400px',
          width: 'fit-content',
          padding: 0,
        }}>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box>
            <AvDateRangePicker
              value={{ from: new Date(dateValue.value.from), to: new Date(dateValue.value.to) }}
              activePresetValue={dateValue.preset}
              onChange={newDate => {
                setFilter({
                  ...filter,
                  expression: {
                    ...filter.expression,
                    ...getStringConditionFromDatePicker(newDate),
                  },
                });
              }}
              onClose={handleClose}
              showAsCalendar
              isRange
              showRelativeOptions
              isFilterExpression
            />
          </Box>
        </ClickAwayListener>
      </FilterStyledPopper>
    </Box>
  );
};

export default DateTypeBox;
