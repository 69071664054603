import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import AvSidePanel from '../../components/AvSidePanel';
import { flex } from '../../components/AvThemeProvider';
import { IconVariant, ItemWithLogo } from '../../components/ItemWithLogo';
import Select from '../../components/Select';
import SwitchToggle from '../../components/SwitchToggle';
import { useAvContext } from '../../context/AvContextProvider';
import { LayoutContext } from '../../context/LayoutContext';
import useQueryObject from '../../hooks/useQueryObjectSql';
import { PermissionEntitiesNames } from '../../types';
import { generateOption, iconSize } from '../../utils/Utils';
import { SortDir } from '../CustomDashboards/types';
import RulesList from '../Settings/GroupingRules/RulesList';
import { typeLabelMap } from '../Sources/Mapping/mapping.types';
import CELExpressionInput from './CELExpressionInput';
import { SourcePriorityRuleConfig, UnificationRule } from './types';
import { emptyRule } from './Utils';
import { ReactComponent as Plus } from '../../assets/Plus.svg';

const plusIcon = <Plus style={{ ...iconSize(16), marginLeft: '-6px' }} />; // TODO AVA-9614

const celFieldOptions = ['result'].map(generateOption);

interface Props {
  rule: UnificationRule;
  field: string;
  onFieldChange: (value) => void;
  projectionName: string;
  isOpen: boolean;
  onClose: () => void;
}
function EditSourcePriorityRule({ rule, field, onFieldChange, projectionName, isOpen, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { sideBar: sidebarWidth } = useContext(LayoutContext).layoutWidths;
  const {
    accountEntities: { fieldMap, aggProjs, ingressProjs },
  } = useAvContext();
  const fieldOptions = ingressProjs[aggProjs[projectionName].aggregates.name].fields;
  const ingressField = fieldOptions.find(({ name }) => name === fieldMap[field].name)!;
  const fieldType = typeLabelMap[ingressField!.originalType.kind!];
  const [newRule, setNewRule] = useState<UnificationRule>(() => emptyRule(fieldType, ingressField.value));
  const ruleConfig = newRule.ruleConfig as SourcePriorityRuleConfig;
  const onRuleConfigChange = field => value => setNewRule({ ...newRule, ruleConfig: { ...ruleConfig, [field]: value } });
  const onChangeSourcePriority = (rowIndex, newItem) =>
    onRuleConfigChange('sourcePriority')(ruleConfig.sourcePriority!.with(rowIndex, newItem));

  const defaultSourceNamesOptions = newRule.isNew ? [] : ruleConfig.sourcePriority.map(({ sourceName }) => sourceName);
  const { data: sourceNameOptions = defaultSourceNamesOptions, isLoading } = useQueryObject({
    queryObject: {
      select: { dims: [{ name: `explode(${aggProjs[projectionName].pathAlias}.source_names)`, alias: 'sourceName' }], metrics: [] },
      sorting: [{ name: 'sourceName', dir: SortDir.ASC }],
      projectionId: aggProjs[projectionName].projId,
      distinct: true,
    },
    onSuccess: d => d.map(({ sourceName }) => sourceName),
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  const getSourceComponent = hideText => val => <ItemWithLogo variant={IconVariant.sourcesMapByName} type={val} hideText={hideText} />;
  const nameFormatter = (row, rowIndex) => (
    <Select
      size="small"
      value={row.sourceName}
      onChange={sourceName => onChangeSourcePriority(rowIndex, { ...row, sourceName })}
      options={sourceNameOptions}
      loading={isLoading}
      skeletonLoading={isLoading}
      getValueFunc={val => val}
      getLabelFunc={val => val}
      renderOption={getSourceComponent(false)}
      startAdornment={getSourceComponent(true)}
      placeholder="Select Source..."
      isRequired
    />
  );
  const fieldFormatter = (row, rowIndex) => (
    <Select
      size="small"
      value={row.fieldNameWithAlias}
      onChange={fieldNameWithAlias => onChangeSourcePriority(rowIndex, { ...row, fieldNameWithAlias })}
      options={fieldOptions}
      isRequired
    />
  );
  const headCellsForSources = [
    { label: 'Name', formatter: nameFormatter, width: '300px', hasTooltip: false },
    { label: 'Field', formatter: fieldFormatter, width: '300px', hasTooltip: false },
  ];

  const onSave = () => {
    if (ruleConfig.sourcePriority.find(({ sourceName, fieldNameWithAlias }) => !sourceName || !fieldNameWithAlias)) {
      enqueueSnackbar('Source must have a valid name and field', { variant: 'error' });
    } else {
      onFieldChange(newRule);
      doClose();
    }
  };

  const doClose = () => {
    onClose();
    setNewRule(emptyRule(fieldType, ingressField.value));
  };

  useEffect(() => {
    if (rule) {
      setNewRule(rule);
    }
  }, [rule]);

  return !isOpen ? (
    <AvSidePanel storageKey="dataUnificationInfo" />
  ) : (
    <AvSidePanel
      storageKey="dataUnificationInfo"
      maxWidthOffset={sidebarWidth}
      isOpen={isOpen}
      minWidth={940}
      onClose={() => doClose()}
      headComponent={<Typography variant="h5">Priority By Source Rule</Typography>}
      footerComponent={
        <Box sx={{ ...flex.justifyEnd, gap: 1 }}>
          <Button size="small" onClick={() => doClose()}>
            Cancel
          </Button>
          <Button size="small" variant="contained" onClick={onSave}>
            Add
          </Button>
        </Box>
      }>
      <Box sx={{ mt: 2 }}>Select sources and fields to retrieve value from</Box>
      <RulesList
        rules={ruleConfig.sourcePriority}
        onChange={onRuleConfigChange('sourcePriority')}
        findDefaultFallbackRule={() => false}
        headCells={headCellsForSources}
        permissionConfiguration={{ resource: PermissionEntitiesNames.MODEL }}
        buttonProps={{ edit: { isHidden: true }, clone: { isHidden: true } }}
        variant="compact"
        showHeaders={false}
      />
      <Button
        variant="outlined"
        size="xSmall"
        onClick={() =>
          onRuleConfigChange('sourcePriority')([
            ...ruleConfig.sourcePriority!,
            { sourceName: undefined, fieldNameWithAlias: ingressField.value },
          ])
        }
        sx={{ alignSelf: 'flex-start', ml: 3 }}>
        {plusIcon}Add Source
      </Button>
      <Divider sx={{ mx: -2, mt: 1, mb: 1 }} />
      <Box sx={{ ...flex.col, gap: 2, width: '100%', pl: 3 }}>
        <SwitchToggle
          label="Set result using expression"
          value={ruleConfig.expression !== null}
          onChange={() => onRuleConfigChange('expression')(ruleConfig.expression === null ? '{{result}}' : null)}
          sx={{ '.MuiTypography-root': { fontSize: 12 } }}
        />
        {ruleConfig.expression !== null && (
          <CELExpressionInput
            projectionName={projectionName}
            onChange={onRuleConfigChange('expression')}
            value={ruleConfig.expression.toString()}
            fieldOptions={celFieldOptions}
          />
        )}
      </Box>
    </AvSidePanel>
  );
}

export default EditSourcePriorityRule;
