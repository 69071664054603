/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ErrorProcessRequestImport } from './routes/error-process-request'
import { Route as IndexImport } from './routes/index'
import { Route as AccountIdTicketsIndexImport } from './routes/$accountId/tickets/index'
import { Route as AccountIdVulnerabilitiesExternalProjectionsLayoutImport } from './routes/$accountId/vulnerabilities/_externalProjectionsLayout'
import { Route as AccountIdThreatsExternalProjectionsLayoutImport } from './routes/$accountId/threats/_externalProjectionsLayout'
import { Route as AccountIdRisk360ExternalProjectionsLayoutImport } from './routes/$accountId/risk360/_externalProjectionsLayout'
import { Route as AccountIdPlatformExternalProjectionsLayoutImport } from './routes/$accountId/platform/_externalProjectionsLayout'
import { Route as AccountIdIncidentsExternalProjectionsLayoutImport } from './routes/$accountId/incidents/_externalProjectionsLayout'
import { Route as AccountIdExploreExternalProjectionsLayoutImport } from './routes/$accountId/explore/_externalProjectionsLayout'
import { Route as AccountIdDetectionsExternalProjectionsLayoutImport } from './routes/$accountId/detections/_externalProjectionsLayout'
import { Route as AccountIdCaasmExternalProjectionsLayoutImport } from './routes/$accountId/caasm/_externalProjectionsLayout'
import { Route as AccountIdVulnerabilitiesTicketsIndexImport } from './routes/$accountId/vulnerabilities/tickets/index'
import { Route as AccountIdVulnerabilitiesFindingsIndexImport } from './routes/$accountId/vulnerabilities/findings/index'
import { Route as AccountIdVulnerabilitiesExceptionsIndexImport } from './routes/$accountId/vulnerabilities/exceptions/index'
import { Route as AccountIdVulnerabilitiesAssetsIndexImport } from './routes/$accountId/vulnerabilities/assets/index'
import { Route as AccountIdTicketsIdIndexImport } from './routes/$accountId/tickets/$id/index'
import { Route as AccountIdThreatsIncidentsIndexImport } from './routes/$accountId/threats/incidents/index'
import { Route as AccountIdThreatsAlertsIndexImport } from './routes/$accountId/threats/alerts/index'
import { Route as AccountIdRisk360FactorsIndexImport } from './routes/$accountId/risk360/factors/index'
import { Route as AccountIdIncidentsIncidentsIndexImport } from './routes/$accountId/incidents/incidents/index'
import { Route as AccountIdDetectionsIncidentsIndexImport } from './routes/$accountId/detections/incidents/index'
import { Route as AccountIdDetectionsAlertsIndexImport } from './routes/$accountId/detections/alerts/index'
import { Route as AccountIdCaasmPolicyViolationsIndexImport } from './routes/$accountId/caasm/policy-violations/index'
import { Route as AccountIdCaasmAssetsIndexImport } from './routes/$accountId/caasm/assets/index'
import { Route as AccountIdVulnerabilitiesTicketsIdIndexImport } from './routes/$accountId/vulnerabilities/tickets/$id/index'
import { Route as AccountIdVulnerabilitiesFindingsIdIndexImport } from './routes/$accountId/vulnerabilities/findings/$id/index'
import { Route as AccountIdVulnerabilitiesExceptionsIdIndexImport } from './routes/$accountId/vulnerabilities/exceptions/$id/index'
import { Route as AccountIdVulnerabilitiesAssetsIdIndexImport } from './routes/$accountId/vulnerabilities/assets/$id/index'
import { Route as AccountIdThreatsIncidentsIdIndexImport } from './routes/$accountId/threats/incidents/$id/index'
import { Route as AccountIdThreatsAlertsIdIndexImport } from './routes/$accountId/threats/alerts/$id/index'
import { Route as AccountIdRisk360FactorsIdIndexImport } from './routes/$accountId/risk360/factors/$id/index'
import { Route as AccountIdIncidentsIncidentsIdIndexImport } from './routes/$accountId/incidents/incidents/$id/index'
import { Route as AccountIdDetectionsIncidentsIdIndexImport } from './routes/$accountId/detections/incidents/$id/index'
import { Route as AccountIdDetectionsAlertsIdIndexImport } from './routes/$accountId/detections/alerts/$id/index'
import { Route as AccountIdCaasmPolicyViolationsIdIndexImport } from './routes/$accountId/caasm/policy-violations/$id/index'
import { Route as AccountIdCaasmAssetsIdIndexImport } from './routes/$accountId/caasm/assets/$id/index'
import { Route as AccountIdVulnerabilitiesSettingsRuleSetCreateIndexImport } from './routes/$accountId/vulnerabilities/settings/rule-set/create/index'
import { Route as AccountIdVulnerabilitiesSettingsRuleSetIdIndexImport } from './routes/$accountId/vulnerabilities/settings/rule-set/$id/index'
import { Route as AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexImport } from './routes/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex/index'
import { Route as AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexImport } from './routes/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex/index'
import { Route as AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexImport } from './routes/$accountId/platform/data-entities/fields/set.$fieldName/create/index'
import { Route as AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexImport } from './routes/$accountId/platform/data-entities/fields/set.$fieldName/edit.$id/index'
import { Route as AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexImport } from './routes/$accountId/platform/data-entities/fields/set.$fieldName/audit.$id/index'

// Create Virtual Routes

const AccountIdVulnerabilitiesImport = createFileRoute(
  '/$accountId/vulnerabilities',
)()
const AccountIdThreatsImport = createFileRoute('/$accountId/threats')()
const AccountIdRisk360Import = createFileRoute('/$accountId/risk360')()
const AccountIdPlatformImport = createFileRoute('/$accountId/platform')()
const AccountIdIncidentsImport = createFileRoute('/$accountId/incidents')()
const AccountIdExploreImport = createFileRoute('/$accountId/explore')()
const AccountIdDetectionsImport = createFileRoute('/$accountId/detections')()
const AccountIdCaasmImport = createFileRoute('/$accountId/caasm')()
const AccountIdIndexLazyImport = createFileRoute('/$accountId/')()
const AccountIdVulnerabilitiesIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/',
)()
const AccountIdRisk360IndexLazyImport = createFileRoute(
  '/$accountId/risk360/',
)()
const AccountIdIncidentsIndexLazyImport = createFileRoute(
  '/$accountId/incidents/',
)()
const AccountIdExploreIndexLazyImport = createFileRoute(
  '/$accountId/explore/',
)()
const AccountIdDetectionsIndexLazyImport = createFileRoute(
  '/$accountId/detections/',
)()
const AccountIdCaasmIndexLazyImport = createFileRoute('/$accountId/caasm/')()
const AccountIdVulnerabilitiesSettingsIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/settings/',
)()
const AccountIdVulnerabilitiesRoiIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/roi/',
)()
const AccountIdVulnerabilitiesRiskIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/risk/',
)()
const AccountIdVulnerabilitiesRemediationIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/remediation/',
)()
const AccountIdVulnerabilitiesPivotIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/pivot/',
)()
const AccountIdVulnerabilitiesOverviewIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/overview/',
)()
const AccountIdVulnerabilitiesExploreIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/explore/',
)()
const AccountIdVulnerabilitiesAssetCoverageIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/asset-coverage/',
)()
const AccountIdSettingsUserManagementIndexLazyImport = createFileRoute(
  '/$accountId/settings/user-management/',
)()
const AccountIdSettingsRisk360BackofficeActionsIndexLazyImport =
  createFileRoute('/$accountId/settings/risk360-backoffice-actions/')()
const AccountIdSettingsQueryIndexLazyImport = createFileRoute(
  '/$accountId/settings/query/',
)()
const AccountIdSettingsProfileSettingsIndexLazyImport = createFileRoute(
  '/$accountId/settings/profile-settings/',
)()
const AccountIdSettingsIconsIndexLazyImport = createFileRoute(
  '/$accountId/settings/icons/',
)()
const AccountIdSettingsGlobalFactorRulesIndexLazyImport = createFileRoute(
  '/$accountId/settings/global-factor-rules/',
)()
const AccountIdSettingsBackofficeActionsIndexLazyImport = createFileRoute(
  '/$accountId/settings/backoffice-actions/',
)()
const AccountIdSettingsAllUsersIndexLazyImport = createFileRoute(
  '/$accountId/settings/all-users/',
)()
const AccountIdSettingsAllAccountsIndexLazyImport = createFileRoute(
  '/$accountId/settings/all-accounts/',
)()
const AccountIdSettingsAccountUsageIndexLazyImport = createFileRoute(
  '/$accountId/settings/account-usage/',
)()
const AccountIdSettingsAccountSettingsIndexLazyImport = createFileRoute(
  '/$accountId/settings/account-settings/',
)()
const AccountIdRisk360FactorRulesIndexLazyImport = createFileRoute(
  '/$accountId/risk360/factor-rules/',
)()
const AccountIdPlatformWorkflowsIndexLazyImport = createFileRoute(
  '/$accountId/platform/workflows/',
)()
const AccountIdPlatformTargetsIndexLazyImport = createFileRoute(
  '/$accountId/platform/targets/',
)()
const AccountIdPlatformSourcesIndexLazyImport = createFileRoute(
  '/$accountId/platform/sources/',
)()
const AccountIdPlatformRelationsIndexLazyImport = createFileRoute(
  '/$accountId/platform/relations/',
)()
const AccountIdPlatformModelManagementIndexLazyImport = createFileRoute(
  '/$accountId/platform/model-management/',
)()
const AccountIdPlatformFormattingRulesIndexLazyImport = createFileRoute(
  '/$accountId/platform/formatting-rules/',
)()
const AccountIdPlatformDataPurgeIndexLazyImport = createFileRoute(
  '/$accountId/platform/data-purge/',
)()
const AccountIdPlatformAuthenticationSourcesIndexLazyImport = createFileRoute(
  '/$accountId/platform/authentication-sources/',
)()
const AccountIdPlatformAssetAgingIndexLazyImport = createFileRoute(
  '/$accountId/platform/asset-aging/',
)()
const AccountIdPlatformAliasesIndexLazyImport = createFileRoute(
  '/$accountId/platform/aliases/',
)()
const AccountIdIncidentsSettingsIndexLazyImport = createFileRoute(
  '/$accountId/incidents/settings/',
)()
const AccountIdIncidentsPivotIndexLazyImport = createFileRoute(
  '/$accountId/incidents/pivot/',
)()
const AccountIdExploreLogsIndexLazyImport = createFileRoute(
  '/$accountId/explore/logs/',
)()
const AccountIdExploreEntitiesIndexLazyImport = createFileRoute(
  '/$accountId/explore/entities/',
)()
const AccountIdDetectionsSettingsIndexLazyImport = createFileRoute(
  '/$accountId/detections/settings/',
)()
const AccountIdDetectionsRulesIndexLazyImport = createFileRoute(
  '/$accountId/detections/rules/',
)()
const AccountIdDetectionsPivotIndexLazyImport = createFileRoute(
  '/$accountId/detections/pivot/',
)()
const AccountIdDetectionsLookupTablesIndexLazyImport = createFileRoute(
  '/$accountId/detections/lookup-tables/',
)()
const AccountIdDetectionsGlobalScriptsIndexLazyImport = createFileRoute(
  '/$accountId/detections/global-scripts/',
)()
const AccountIdDetectionsExploreIndexLazyImport = createFileRoute(
  '/$accountId/detections/explore/',
)()
const AccountIdCaasmToolCoverageAndGapIndexLazyImport = createFileRoute(
  '/$accountId/caasm/tool-coverage-and-gap/',
)()
const AccountIdCaasmSettingsIndexLazyImport = createFileRoute(
  '/$accountId/caasm/settings/',
)()
const AccountIdCaasmPolicyComplianceIndexLazyImport = createFileRoute(
  '/$accountId/caasm/policy-compliance/',
)()
const AccountIdCaasmPoliciesIndexLazyImport = createFileRoute(
  '/$accountId/caasm/policies/',
)()
const AccountIdCaasmAssetInventoryIndexLazyImport = createFileRoute(
  '/$accountId/caasm/asset-inventory/',
)()
const AccountIdCaasmAssetCoverageIndexLazyImport = createFileRoute(
  '/$accountId/caasm/asset-coverage/',
)()
const AccountIdVulnerabilitiesTicketsVisualIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/tickets/visual/',
)()
const AccountIdVulnerabilitiesSettingsUiConfigIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/settings/ui-config/',
)()
const AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyImport =
  createFileRoute('/$accountId/vulnerabilities/settings/tickets-statuses/')()
const AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyImport =
  createFileRoute('/$accountId/vulnerabilities/settings/severity-sla/')()
const AccountIdVulnerabilitiesSettingsScoreIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/settings/score/',
)()
const AccountIdVulnerabilitiesSettingsRuleSetIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/settings/rule-set/',
)()
const AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyImport =
  createFileRoute('/$accountId/vulnerabilities/settings/exceptions-settings/')()
const AccountIdVulnerabilitiesPivotIdIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/pivot/$id/',
)()
const AccountIdVulnerabilitiesFindingsVisualIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/findings/visual/',
)()
const AccountIdVulnerabilitiesExceptionsVisualIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/exceptions/visual/',
)()
const AccountIdVulnerabilitiesAssetsVisualIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/assets/visual/',
)()
const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/',
  )()
const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/',
  )()
const AccountIdThreatsIncidentsVisualIndexLazyImport = createFileRoute(
  '/$accountId/threats/incidents/visual/',
)()
const AccountIdThreatsAlertsVisualIndexLazyImport = createFileRoute(
  '/$accountId/threats/alerts/visual/',
)()
const AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyImport =
  createFileRoute('/$accountId/threats/_externalProjectionsLayout/reports/')()
const AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyImport =
  createFileRoute(
    '/$accountId/threats/_externalProjectionsLayout/dashboards/',
  )()
const AccountIdSettingsUserManagementCreateIndexLazyImport = createFileRoute(
  '/$accountId/settings/user-management/create/',
)()
const AccountIdSettingsPermissionsRolesIndexLazyImport = createFileRoute(
  '/$accountId/settings/permissions/roles/',
)()
const AccountIdSettingsPermissionsContentPermissionsIndexLazyImport =
  createFileRoute('/$accountId/settings/permissions/content-permissions/')()
const AccountIdSettingsGlobalFactorRulesCreateIndexLazyImport = createFileRoute(
  '/$accountId/settings/global-factor-rules/create/',
)()
const AccountIdSettingsAllUsersCreateIndexLazyImport = createFileRoute(
  '/$accountId/settings/all-users/create/',
)()
const AccountIdSettingsAllAccountsCreateIndexLazyImport = createFileRoute(
  '/$accountId/settings/all-accounts/create/',
)()
const AccountIdRisk360FactorsVisualIndexLazyImport = createFileRoute(
  '/$accountId/risk360/factors/visual/',
)()
const AccountIdRisk360FactorRulesRunsIndexLazyImport = createFileRoute(
  '/$accountId/risk360/factor-rules/runs/',
)()
const AccountIdRisk360FactorRulesCreateIndexLazyImport = createFileRoute(
  '/$accountId/risk360/factor-rules/create/',
)()
const AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyImport =
  createFileRoute(
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/',
  )()
const AccountIdPlatformTargetsCreateIndexLazyImport = createFileRoute(
  '/$accountId/platform/targets/create/',
)()
const AccountIdPlatformSourcesCreateIndexLazyImport = createFileRoute(
  '/$accountId/platform/sources/create/',
)()
const AccountIdPlatformRelationsRelationIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/relations/$relationId/',
)()
const AccountIdPlatformModelManagementRunsIndexLazyImport = createFileRoute(
  '/$accountId/platform/model-management/runs/',
)()
const AccountIdPlatformModelManagementIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/model-management/$id/',
)()
const AccountIdPlatformFormattingRulesCreateIndexLazyImport = createFileRoute(
  '/$accountId/platform/formatting-rules/create/',
)()
const AccountIdPlatformDataPurgeRunsIndexLazyImport = createFileRoute(
  '/$accountId/platform/data-purge/runs/',
)()
const AccountIdPlatformDataPurgeProjIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/data-purge/$projId/',
)()
const AccountIdPlatformDataEntitiesFieldsIndexLazyImport = createFileRoute(
  '/$accountId/platform/data-entities/fields/',
)()
const AccountIdPlatformDataEntitiesDataEntitiesIndexLazyImport =
  createFileRoute('/$accountId/platform/data-entities/data-entities/')()
const AccountIdPlatformAssetAgingCreateIndexLazyImport = createFileRoute(
  '/$accountId/platform/asset-aging/create/',
)()
const AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyImport =
  createFileRoute(
    '/$accountId/platform/_externalProjectionsLayout/measurements/',
  )()
const AccountIdIncidentsSettingsUiConfigIndexLazyImport = createFileRoute(
  '/$accountId/incidents/settings/ui-config/',
)()
const AccountIdIncidentsSettingsTicketsStatusesIndexLazyImport =
  createFileRoute('/$accountId/incidents/settings/tickets-statuses/')()
const AccountIdIncidentsSettingsRuleSetIndexLazyImport = createFileRoute(
  '/$accountId/incidents/settings/rule-set/',
)()
const AccountIdIncidentsPivotIdIndexLazyImport = createFileRoute(
  '/$accountId/incidents/pivot/$id/',
)()
const AccountIdIncidentsIncidentsVisualIndexLazyImport = createFileRoute(
  '/$accountId/incidents/incidents/visual/',
)()
const AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyImport =
  createFileRoute('/$accountId/incidents/_externalProjectionsLayout/reports/')()
const AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/',
  )()
const AccountIdExploreExternalProjectionsLayoutReportsIndexLazyImport =
  createFileRoute('/$accountId/explore/_externalProjectionsLayout/reports/')()
const AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyImport =
  createFileRoute(
    '/$accountId/explore/_externalProjectionsLayout/dashboards/',
  )()
const AccountIdDetectionsSettingsUiConfigIndexLazyImport = createFileRoute(
  '/$accountId/detections/settings/ui-config/',
)()
const AccountIdDetectionsSettingsTicketsStatusesIndexLazyImport =
  createFileRoute('/$accountId/detections/settings/tickets-statuses/')()
const AccountIdDetectionsSettingsRuleSetIndexLazyImport = createFileRoute(
  '/$accountId/detections/settings/rule-set/',
)()
const AccountIdDetectionsRulesLogsIndexLazyImport = createFileRoute(
  '/$accountId/detections/rules/logs/',
)()
const AccountIdDetectionsRulesCreateIndexLazyImport = createFileRoute(
  '/$accountId/detections/rules/create/',
)()
const AccountIdDetectionsPivotIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/pivot/$id/',
)()
const AccountIdDetectionsLookupTablesCreateIndexLazyImport = createFileRoute(
  '/$accountId/detections/lookup-tables/create/',
)()
const AccountIdDetectionsIncidentsVisualIndexLazyImport = createFileRoute(
  '/$accountId/detections/incidents/visual/',
)()
const AccountIdDetectionsGlobalScriptsCreateIndexLazyImport = createFileRoute(
  '/$accountId/detections/global-scripts/create/',
)()
const AccountIdDetectionsExploreIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/explore/$id/',
)()
const AccountIdDetectionsAlertsVisualIndexLazyImport = createFileRoute(
  '/$accountId/detections/alerts/visual/',
)()
const AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/reports/',
  )()
const AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/dashboards/',
  )()
const AccountIdCaasmSettingsUiConfigIndexLazyImport = createFileRoute(
  '/$accountId/caasm/settings/ui-config/',
)()
const AccountIdCaasmPolicyViolationsVisualIndexLazyImport = createFileRoute(
  '/$accountId/caasm/policy-violations/visual/',
)()
const AccountIdCaasmPoliciesCreateIndexLazyImport = createFileRoute(
  '/$accountId/caasm/policies/create/',
)()
const AccountIdCaasmAssetsVisualIndexLazyImport = createFileRoute(
  '/$accountId/caasm/assets/visual/',
)()
const AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyImport =
  createFileRoute('/$accountId/caasm/_externalProjectionsLayout/reports/')()
const AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyImport =
  createFileRoute('/$accountId/caasm/_externalProjectionsLayout/dashboards/')()
const AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyImport =
  createFileRoute('/$accountId/vulnerabilities/ui-config/audit/$path/')()
const AccountIdVulnerabilitiesScoreAuditPathIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/score/audit/$path/',
)()
const AccountIdVulnerabilitiesFindingsRunsIdIndexLazyImport = createFileRoute(
  '/$accountId/vulnerabilities/findings/runs/$id/',
)()
const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/create/',
  )()
const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/create/',
  )()
const AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/threats/_externalProjectionsLayout/reports/create/',
  )()
const AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/threats/_externalProjectionsLayout/dashboards/create/',
  )()
const AccountIdSettingsUserManagementEditIdIndexLazyImport = createFileRoute(
  '/$accountId/settings/user-management/edit/$id/',
)()
const AccountIdSettingsUserManagementAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/settings/user-management/audit/$id/',
)()
const AccountIdSettingsPermissionsRolesCreateIndexLazyImport = createFileRoute(
  '/$accountId/settings/permissions/roles/create/',
)()
const AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/settings/permissions/content-permissions/create/',
  )()
const AccountIdSettingsGlobalFactorRulesEditIdIndexLazyImport = createFileRoute(
  '/$accountId/settings/global-factor-rules/edit/$id/',
)()
const AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyImport =
  createFileRoute('/$accountId/settings/global-factor-rules/audit/$id/')()
const AccountIdSettingsAllUsersEditIdIndexLazyImport = createFileRoute(
  '/$accountId/settings/all-users/edit/$id/',
)()
const AccountIdSettingsAllUsersAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/settings/all-users/audit/$id/',
)()
const AccountIdSettingsAllAccountsEditIdIndexLazyImport = createFileRoute(
  '/$accountId/settings/all-accounts/edit/$id/',
)()
const AccountIdSettingsAllAccountsAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/settings/all-accounts/audit/$id/',
)()
const AccountIdRisk360FactorRulesEditIdIndexLazyImport = createFileRoute(
  '/$accountId/risk360/factor-rules/edit/$id/',
)()
const AccountIdRisk360FactorRulesAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/risk360/factor-rules/audit/$id/',
)()
const AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/create/',
  )()
const AccountIdPlatformTargetsMappingIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/targets/mapping/$id/',
)()
const AccountIdPlatformTargetsEditIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/targets/edit/$id/',
)()
const AccountIdPlatformTargetsCreateTypeIndexLazyImport = createFileRoute(
  '/$accountId/platform/targets/create/$type/',
)()
const AccountIdPlatformTargetsAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/targets/audit/$id/',
)()
const AccountIdPlatformTargetsActivityLogIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/targets/activityLog/$id/',
)()
const AccountIdPlatformSourcesRunsIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/sources/runs/$id/',
)()
const AccountIdPlatformSourcesEditIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/sources/edit/$id/',
)()
const AccountIdPlatformSourcesCreateTypeIndexLazyImport = createFileRoute(
  '/$accountId/platform/sources/create/$type/',
)()
const AccountIdPlatformSourcesAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/sources/audit/$id/',
)()
const AccountIdPlatformModelManagementAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/model-management/audit/$id/',
)()
const AccountIdPlatformModelManagementIdPreviewIndexLazyImport =
  createFileRoute('/$accountId/platform/model-management/$id/preview/')()
const AccountIdPlatformFormattingRulesEditIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/formatting-rules/edit/$id/',
)()
const AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyImport =
  createFileRoute('/$accountId/platform/data-entities/data-entities/$projId/')()
const AccountIdPlatformAssetAgingEditIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/asset-aging/edit/$id/',
)()
const AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/platform/_externalProjectionsLayout/measurements/create/',
  )()
const AccountIdIncidentsSettingsRuleSetCreateIndexLazyImport = createFileRoute(
  '/$accountId/incidents/settings/rule-set/create/',
)()
const AccountIdIncidentsSettingsRuleSetIdIndexLazyImport = createFileRoute(
  '/$accountId/incidents/settings/rule-set/$id/',
)()
const AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/_externalProjectionsLayout/reports/create/',
  )()
const AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/create/',
  )()
const AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/explore/_externalProjectionsLayout/reports/create/',
  )()
const AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/explore/_externalProjectionsLayout/dashboards/create/',
  )()
const AccountIdDetectionsUiConfigAuditPathIndexLazyImport = createFileRoute(
  '/$accountId/detections/ui-config/audit/$path/',
)()
const AccountIdDetectionsSettingsRuleSetCreateIndexLazyImport = createFileRoute(
  '/$accountId/detections/settings/rule-set/create/',
)()
const AccountIdDetectionsSettingsRuleSetIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/settings/rule-set/$id/',
)()
const AccountIdDetectionsRulesRunsIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/rules/runs/$id/',
)()
const AccountIdDetectionsRulesEditIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/rules/edit/$id/',
)()
const AccountIdDetectionsRulesAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/rules/audit/$id/',
)()
const AccountIdDetectionsLookupTablesEditIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/lookup-tables/edit/$id/',
)()
const AccountIdDetectionsLookupTablesAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/lookup-tables/audit/$id/',
)()
const AccountIdDetectionsGlobalScriptsEditIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/global-scripts/edit/$id/',
)()
const AccountIdDetectionsGlobalScriptsAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/detections/global-scripts/audit/$id/',
)()
const AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/reports/create/',
  )()
const AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/dashboards/create/',
  )()
const AccountIdCaasmUiConfigAuditPathIndexLazyImport = createFileRoute(
  '/$accountId/caasm/ui-config/audit/$path/',
)()
const AccountIdCaasmPoliciesRunsIdIndexLazyImport = createFileRoute(
  '/$accountId/caasm/policies/runs/$id/',
)()
const AccountIdCaasmPoliciesEditIdIndexLazyImport = createFileRoute(
  '/$accountId/caasm/policies/edit/$id/',
)()
const AccountIdCaasmPoliciesDuplicateIdIndexLazyImport = createFileRoute(
  '/$accountId/caasm/policies/duplicate/$id/',
)()
const AccountIdCaasmPoliciesAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/caasm/policies/audit/$id/',
)()
const AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/caasm/_externalProjectionsLayout/reports/create/',
  )()
const AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyImport =
  createFileRoute(
    '/$accountId/caasm/_externalProjectionsLayout/dashboards/create/',
  )()
const AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyImport =
  createFileRoute('/$accountId/vulnerabilities/settings/rule-set/audit/$id/')()
const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/runs/$id/',
  )()
const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/edit/$id/',
  )()
const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/audit/$id/',
  )()
const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/runs/$id/',
  )()
const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/edit/$id/',
  )()
const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/audit/$id/',
  )()
const AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/threats/_externalProjectionsLayout/reports/runs/$id/',
  )()
const AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/threats/_externalProjectionsLayout/reports/edit/$id/',
  )()
const AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/threats/_externalProjectionsLayout/reports/audit/$id/',
  )()
const AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/threats/_externalProjectionsLayout/dashboards/runs/$id/',
  )()
const AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/threats/_externalProjectionsLayout/dashboards/edit/$id/',
  )()
const AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/threats/_externalProjectionsLayout/dashboards/audit/$id/',
  )()
const AccountIdSettingsPermissionsRolesEditIdIndexLazyImport = createFileRoute(
  '/$accountId/settings/permissions/roles/edit/$id/',
)()
const AccountIdSettingsPermissionsRolesAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/settings/permissions/roles/audit/$id/',
)()
const AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/settings/permissions/content-permissions/edit/$id/',
  )()
const AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/settings/permissions/content-permissions/audit/$id/',
  )()
const AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/runs/$id/',
  )()
const AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/edit/$id/',
  )()
const AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/audit/$id/',
  )()
const AccountIdPlatformSourcesEditIdPreviewIdIndexLazyImport = createFileRoute(
  '/$accountId/platform/sources/edit/$id/$previewId/',
)()
const AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyImport =
  createFileRoute('/$accountId/platform/sources/create/$type/$previewId/')()
const AccountIdPlatformSourcesIdMappingPathTypeIndexLazyImport =
  createFileRoute('/$accountId/platform/sources/$id/$mappingPath/$type/')()
const AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyImport =
  createFileRoute('/$accountId/platform/data-entities/fields/set/$fieldName/')()
const AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyImport =
  createFileRoute(
    '/$accountId/platform/data-entities/data-entities/$projId/$ruleId/',
  )()
const AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyImport =
  createFileRoute(
    '/$accountId/platform/_externalProjectionsLayout/measurements/edit/$systemName/',
  )()
const AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/platform/_externalProjectionsLayout/measurements/audit/$id/',
  )()
const AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyImport =
  createFileRoute('/$accountId/incidents/settings/ui-config/audit/$path/')()
const AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/settings/rule-set/create/$ruleIndex/',
  )()
const AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyImport = createFileRoute(
  '/$accountId/incidents/settings/rule-set/audit/$id/',
)()
const AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyImport =
  createFileRoute('/$accountId/incidents/settings/rule-set/$id/$ruleIndex/')()
const AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/_externalProjectionsLayout/reports/runs/$id/',
  )()
const AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/_externalProjectionsLayout/reports/edit/$id/',
  )()
const AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/_externalProjectionsLayout/reports/audit/$id/',
  )()
const AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/runs/$id/',
  )()
const AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/edit/$id/',
  )()
const AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/audit/$id/',
  )()
const AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/explore/_externalProjectionsLayout/reports/runs/$id/',
  )()
const AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/explore/_externalProjectionsLayout/reports/edit/$id/',
  )()
const AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/explore/_externalProjectionsLayout/dashboards/runs/$id/',
  )()
const AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/explore/_externalProjectionsLayout/dashboards/edit/$id/',
  )()
const AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/explore/_externalProjectionsLayout/dashboards/audit/$id/',
  )()
const AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/settings/rule-set/create/$ruleIndex/',
  )()
const AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyImport =
  createFileRoute('/$accountId/detections/settings/rule-set/audit/$id/')()
const AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyImport =
  createFileRoute('/$accountId/detections/settings/rule-set/$id/$ruleIndex/')()
const AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/reports/runs/$id/',
  )()
const AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/reports/edit/$id/',
  )()
const AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/reports/audit/$id/',
  )()
const AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/dashboards/runs/$id/',
  )()
const AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/dashboards/edit/$id/',
  )()
const AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/detections/_externalProjectionsLayout/dashboards/audit/$id/',
  )()
const AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/caasm/_externalProjectionsLayout/reports/runs/$id/',
  )()
const AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/caasm/_externalProjectionsLayout/reports/edit/$id/',
  )()
const AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/caasm/_externalProjectionsLayout/reports/audit/$id/',
  )()
const AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport =
  createFileRoute(
    '/$accountId/caasm/_externalProjectionsLayout/dashboards/runs/$id/',
  )()
const AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/caasm/_externalProjectionsLayout/dashboards/edit/$id/',
  )()
const AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport =
  createFileRoute(
    '/$accountId/caasm/_externalProjectionsLayout/dashboards/audit/$id/',
  )()
const AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyImport =
  createFileRoute(
    '/$accountId/platform/targets/audit/$id/mapping/$idMapping/',
  )()
const AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyImport =
  createFileRoute(
    '/$accountId/platform/sources/audit/$id/data-mapping/$type/',
  )()
const AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyImport =
  createFileRoute(
    '/$accountId/platform/sources/$id/$mappingPath/$type/preview/',
  )()

// Create/Update Routes

const ErrorProcessRequestRoute = ErrorProcessRequestImport.update({
  id: '/error-process-request',
  path: '/error-process-request',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdVulnerabilitiesRoute = AccountIdVulnerabilitiesImport.update({
  id: '/$accountId/vulnerabilities',
  path: '/$accountId/vulnerabilities',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdThreatsRoute = AccountIdThreatsImport.update({
  id: '/$accountId/threats',
  path: '/$accountId/threats',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdRisk360Route = AccountIdRisk360Import.update({
  id: '/$accountId/risk360',
  path: '/$accountId/risk360',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdPlatformRoute = AccountIdPlatformImport.update({
  id: '/$accountId/platform',
  path: '/$accountId/platform',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdIncidentsRoute = AccountIdIncidentsImport.update({
  id: '/$accountId/incidents',
  path: '/$accountId/incidents',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdExploreRoute = AccountIdExploreImport.update({
  id: '/$accountId/explore',
  path: '/$accountId/explore',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdDetectionsRoute = AccountIdDetectionsImport.update({
  id: '/$accountId/detections',
  path: '/$accountId/detections',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdCaasmRoute = AccountIdCaasmImport.update({
  id: '/$accountId/caasm',
  path: '/$accountId/caasm',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdIndexLazyRoute = AccountIdIndexLazyImport.update({
  id: '/$accountId/',
  path: '/$accountId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/$accountId/index.lazy').then((d) => d.Route),
)

const AccountIdVulnerabilitiesIndexLazyRoute =
  AccountIdVulnerabilitiesIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdRisk360IndexLazyRoute = AccountIdRisk360IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AccountIdRisk360Route,
} as any).lazy(() =>
  import('./routes/$accountId/risk360/index.lazy').then((d) => d.Route),
)

const AccountIdIncidentsIndexLazyRoute =
  AccountIdIncidentsIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/incidents/index.lazy').then((d) => d.Route),
  )

const AccountIdExploreIndexLazyRoute = AccountIdExploreIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AccountIdExploreRoute,
} as any).lazy(() =>
  import('./routes/$accountId/explore/index.lazy').then((d) => d.Route),
)

const AccountIdDetectionsIndexLazyRoute =
  AccountIdDetectionsIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/index.lazy').then((d) => d.Route),
  )

const AccountIdCaasmIndexLazyRoute = AccountIdCaasmIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AccountIdCaasmRoute,
} as any).lazy(() =>
  import('./routes/$accountId/caasm/index.lazy').then((d) => d.Route),
)

const AccountIdTicketsIndexRoute = AccountIdTicketsIndexImport.update({
  id: '/$accountId/tickets/',
  path: '/$accountId/tickets/',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdVulnerabilitiesExternalProjectionsLayoutRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutImport.update({
    id: '/_externalProjectionsLayout',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any)

const AccountIdThreatsExternalProjectionsLayoutRoute =
  AccountIdThreatsExternalProjectionsLayoutImport.update({
    id: '/_externalProjectionsLayout',
    getParentRoute: () => AccountIdThreatsRoute,
  } as any)

const AccountIdRisk360ExternalProjectionsLayoutRoute =
  AccountIdRisk360ExternalProjectionsLayoutImport.update({
    id: '/_externalProjectionsLayout',
    getParentRoute: () => AccountIdRisk360Route,
  } as any)

const AccountIdPlatformExternalProjectionsLayoutRoute =
  AccountIdPlatformExternalProjectionsLayoutImport.update({
    id: '/_externalProjectionsLayout',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any)

const AccountIdIncidentsExternalProjectionsLayoutRoute =
  AccountIdIncidentsExternalProjectionsLayoutImport.update({
    id: '/_externalProjectionsLayout',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any)

const AccountIdExploreExternalProjectionsLayoutRoute =
  AccountIdExploreExternalProjectionsLayoutImport.update({
    id: '/_externalProjectionsLayout',
    getParentRoute: () => AccountIdExploreRoute,
  } as any)

const AccountIdDetectionsExternalProjectionsLayoutRoute =
  AccountIdDetectionsExternalProjectionsLayoutImport.update({
    id: '/_externalProjectionsLayout',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any)

const AccountIdCaasmExternalProjectionsLayoutRoute =
  AccountIdCaasmExternalProjectionsLayoutImport.update({
    id: '/_externalProjectionsLayout',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any)

const AccountIdVulnerabilitiesSettingsIndexLazyRoute =
  AccountIdVulnerabilitiesSettingsIndexLazyImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/settings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesRoiIndexLazyRoute =
  AccountIdVulnerabilitiesRoiIndexLazyImport.update({
    id: '/roi/',
    path: '/roi/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/roi/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesRiskIndexLazyRoute =
  AccountIdVulnerabilitiesRiskIndexLazyImport.update({
    id: '/risk/',
    path: '/risk/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/risk/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesRemediationIndexLazyRoute =
  AccountIdVulnerabilitiesRemediationIndexLazyImport.update({
    id: '/remediation/',
    path: '/remediation/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/remediation/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesPivotIndexLazyRoute =
  AccountIdVulnerabilitiesPivotIndexLazyImport.update({
    id: '/pivot/',
    path: '/pivot/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/pivot/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesOverviewIndexLazyRoute =
  AccountIdVulnerabilitiesOverviewIndexLazyImport.update({
    id: '/overview/',
    path: '/overview/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/overview/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesExploreIndexLazyRoute =
  AccountIdVulnerabilitiesExploreIndexLazyImport.update({
    id: '/explore/',
    path: '/explore/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/explore/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesAssetCoverageIndexLazyRoute =
  AccountIdVulnerabilitiesAssetCoverageIndexLazyImport.update({
    id: '/asset-coverage/',
    path: '/asset-coverage/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/asset-coverage/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsUserManagementIndexLazyRoute =
  AccountIdSettingsUserManagementIndexLazyImport.update({
    id: '/$accountId/settings/user-management/',
    path: '/$accountId/settings/user-management/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/user-management/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsRisk360BackofficeActionsIndexLazyRoute =
  AccountIdSettingsRisk360BackofficeActionsIndexLazyImport.update({
    id: '/$accountId/settings/risk360-backoffice-actions/',
    path: '/$accountId/settings/risk360-backoffice-actions/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/risk360-backoffice-actions/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsQueryIndexLazyRoute =
  AccountIdSettingsQueryIndexLazyImport.update({
    id: '/$accountId/settings/query/',
    path: '/$accountId/settings/query/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/query/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsProfileSettingsIndexLazyRoute =
  AccountIdSettingsProfileSettingsIndexLazyImport.update({
    id: '/$accountId/settings/profile-settings/',
    path: '/$accountId/settings/profile-settings/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/profile-settings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsIconsIndexLazyRoute =
  AccountIdSettingsIconsIndexLazyImport.update({
    id: '/$accountId/settings/icons/',
    path: '/$accountId/settings/icons/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/icons/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsGlobalFactorRulesIndexLazyRoute =
  AccountIdSettingsGlobalFactorRulesIndexLazyImport.update({
    id: '/$accountId/settings/global-factor-rules/',
    path: '/$accountId/settings/global-factor-rules/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/global-factor-rules/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsBackofficeActionsIndexLazyRoute =
  AccountIdSettingsBackofficeActionsIndexLazyImport.update({
    id: '/$accountId/settings/backoffice-actions/',
    path: '/$accountId/settings/backoffice-actions/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/backoffice-actions/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsAllUsersIndexLazyRoute =
  AccountIdSettingsAllUsersIndexLazyImport.update({
    id: '/$accountId/settings/all-users/',
    path: '/$accountId/settings/all-users/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/all-users/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsAllAccountsIndexLazyRoute =
  AccountIdSettingsAllAccountsIndexLazyImport.update({
    id: '/$accountId/settings/all-accounts/',
    path: '/$accountId/settings/all-accounts/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/all-accounts/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsAccountUsageIndexLazyRoute =
  AccountIdSettingsAccountUsageIndexLazyImport.update({
    id: '/$accountId/settings/account-usage/',
    path: '/$accountId/settings/account-usage/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/account-usage/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsAccountSettingsIndexLazyRoute =
  AccountIdSettingsAccountSettingsIndexLazyImport.update({
    id: '/$accountId/settings/account-settings/',
    path: '/$accountId/settings/account-settings/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/account-settings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdRisk360FactorRulesIndexLazyRoute =
  AccountIdRisk360FactorRulesIndexLazyImport.update({
    id: '/factor-rules/',
    path: '/factor-rules/',
    getParentRoute: () => AccountIdRisk360Route,
  } as any).lazy(() =>
    import('./routes/$accountId/risk360/factor-rules/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformWorkflowsIndexLazyRoute =
  AccountIdPlatformWorkflowsIndexLazyImport.update({
    id: '/workflows/',
    path: '/workflows/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/workflows/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformTargetsIndexLazyRoute =
  AccountIdPlatformTargetsIndexLazyImport.update({
    id: '/targets/',
    path: '/targets/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/targets/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformSourcesIndexLazyRoute =
  AccountIdPlatformSourcesIndexLazyImport.update({
    id: '/sources/',
    path: '/sources/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/sources/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformRelationsIndexLazyRoute =
  AccountIdPlatformRelationsIndexLazyImport.update({
    id: '/relations/',
    path: '/relations/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/relations/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformModelManagementIndexLazyRoute =
  AccountIdPlatformModelManagementIndexLazyImport.update({
    id: '/model-management/',
    path: '/model-management/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/model-management/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformFormattingRulesIndexLazyRoute =
  AccountIdPlatformFormattingRulesIndexLazyImport.update({
    id: '/formatting-rules/',
    path: '/formatting-rules/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/formatting-rules/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformDataPurgeIndexLazyRoute =
  AccountIdPlatformDataPurgeIndexLazyImport.update({
    id: '/data-purge/',
    path: '/data-purge/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/data-purge/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformAuthenticationSourcesIndexLazyRoute =
  AccountIdPlatformAuthenticationSourcesIndexLazyImport.update({
    id: '/authentication-sources/',
    path: '/authentication-sources/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/authentication-sources/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformAssetAgingIndexLazyRoute =
  AccountIdPlatformAssetAgingIndexLazyImport.update({
    id: '/asset-aging/',
    path: '/asset-aging/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/asset-aging/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformAliasesIndexLazyRoute =
  AccountIdPlatformAliasesIndexLazyImport.update({
    id: '/aliases/',
    path: '/aliases/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/aliases/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdIncidentsSettingsIndexLazyRoute =
  AccountIdIncidentsSettingsIndexLazyImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/incidents/settings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdIncidentsPivotIndexLazyRoute =
  AccountIdIncidentsPivotIndexLazyImport.update({
    id: '/pivot/',
    path: '/pivot/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/incidents/pivot/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdExploreLogsIndexLazyRoute =
  AccountIdExploreLogsIndexLazyImport.update({
    id: '/logs/',
    path: '/logs/',
    getParentRoute: () => AccountIdExploreRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/explore/logs/index.lazy').then((d) => d.Route),
  )

const AccountIdExploreEntitiesIndexLazyRoute =
  AccountIdExploreEntitiesIndexLazyImport.update({
    id: '/entities/',
    path: '/entities/',
    getParentRoute: () => AccountIdExploreRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/explore/entities/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsSettingsIndexLazyRoute =
  AccountIdDetectionsSettingsIndexLazyImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/settings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsRulesIndexLazyRoute =
  AccountIdDetectionsRulesIndexLazyImport.update({
    id: '/rules/',
    path: '/rules/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/rules/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsPivotIndexLazyRoute =
  AccountIdDetectionsPivotIndexLazyImport.update({
    id: '/pivot/',
    path: '/pivot/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/pivot/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsLookupTablesIndexLazyRoute =
  AccountIdDetectionsLookupTablesIndexLazyImport.update({
    id: '/lookup-tables/',
    path: '/lookup-tables/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/lookup-tables/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsGlobalScriptsIndexLazyRoute =
  AccountIdDetectionsGlobalScriptsIndexLazyImport.update({
    id: '/global-scripts/',
    path: '/global-scripts/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/global-scripts/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsExploreIndexLazyRoute =
  AccountIdDetectionsExploreIndexLazyImport.update({
    id: '/explore/',
    path: '/explore/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/explore/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmToolCoverageAndGapIndexLazyRoute =
  AccountIdCaasmToolCoverageAndGapIndexLazyImport.update({
    id: '/tool-coverage-and-gap/',
    path: '/tool-coverage-and-gap/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/tool-coverage-and-gap/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmSettingsIndexLazyRoute =
  AccountIdCaasmSettingsIndexLazyImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/settings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmPolicyComplianceIndexLazyRoute =
  AccountIdCaasmPolicyComplianceIndexLazyImport.update({
    id: '/policy-compliance/',
    path: '/policy-compliance/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/policy-compliance/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmPoliciesIndexLazyRoute =
  AccountIdCaasmPoliciesIndexLazyImport.update({
    id: '/policies/',
    path: '/policies/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/policies/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmAssetInventoryIndexLazyRoute =
  AccountIdCaasmAssetInventoryIndexLazyImport.update({
    id: '/asset-inventory/',
    path: '/asset-inventory/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/asset-inventory/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmAssetCoverageIndexLazyRoute =
  AccountIdCaasmAssetCoverageIndexLazyImport.update({
    id: '/asset-coverage/',
    path: '/asset-coverage/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/asset-coverage/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesTicketsIndexRoute =
  AccountIdVulnerabilitiesTicketsIndexImport.update({
    id: '/tickets/',
    path: '/tickets/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/tickets/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesFindingsIndexRoute =
  AccountIdVulnerabilitiesFindingsIndexImport.update({
    id: '/findings/',
    path: '/findings/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/findings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesExceptionsIndexRoute =
  AccountIdVulnerabilitiesExceptionsIndexImport.update({
    id: '/exceptions/',
    path: '/exceptions/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/exceptions/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesAssetsIndexRoute =
  AccountIdVulnerabilitiesAssetsIndexImport.update({
    id: '/assets/',
    path: '/assets/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/assets/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdTicketsIdIndexRoute = AccountIdTicketsIdIndexImport.update({
  id: '/$accountId/tickets/$id/',
  path: '/$accountId/tickets/$id/',
  getParentRoute: () => rootRoute,
} as any)

const AccountIdThreatsIncidentsIndexRoute =
  AccountIdThreatsIncidentsIndexImport.update({
    id: '/incidents/',
    path: '/incidents/',
    getParentRoute: () => AccountIdThreatsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/threats/incidents/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdThreatsAlertsIndexRoute =
  AccountIdThreatsAlertsIndexImport.update({
    id: '/alerts/',
    path: '/alerts/',
    getParentRoute: () => AccountIdThreatsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/threats/alerts/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdRisk360FactorsIndexRoute =
  AccountIdRisk360FactorsIndexImport.update({
    id: '/factors/',
    path: '/factors/',
    getParentRoute: () => AccountIdRisk360Route,
  } as any).lazy(() =>
    import('./routes/$accountId/risk360/factors/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdIncidentsIncidentsIndexRoute =
  AccountIdIncidentsIncidentsIndexImport.update({
    id: '/incidents/',
    path: '/incidents/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/incidents/incidents/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsIncidentsIndexRoute =
  AccountIdDetectionsIncidentsIndexImport.update({
    id: '/incidents/',
    path: '/incidents/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/incidents/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsAlertsIndexRoute =
  AccountIdDetectionsAlertsIndexImport.update({
    id: '/alerts/',
    path: '/alerts/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/alerts/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmPolicyViolationsIndexRoute =
  AccountIdCaasmPolicyViolationsIndexImport.update({
    id: '/policy-violations/',
    path: '/policy-violations/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/policy-violations/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmAssetsIndexRoute = AccountIdCaasmAssetsIndexImport.update({
  id: '/assets/',
  path: '/assets/',
  getParentRoute: () => AccountIdCaasmRoute,
} as any).lazy(() =>
  import('./routes/$accountId/caasm/assets/index.lazy').then((d) => d.Route),
)

const AccountIdVulnerabilitiesTicketsVisualIndexLazyRoute =
  AccountIdVulnerabilitiesTicketsVisualIndexLazyImport.update({
    id: '/tickets/visual/',
    path: '/tickets/visual/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/tickets/visual/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsUiConfigIndexLazyRoute =
  AccountIdVulnerabilitiesSettingsUiConfigIndexLazyImport.update({
    id: '/settings/ui-config/',
    path: '/settings/ui-config/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/ui-config/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyRoute =
  AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyImport.update({
    id: '/settings/tickets-statuses/',
    path: '/settings/tickets-statuses/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/tickets-statuses/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyRoute =
  AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyImport.update({
    id: '/settings/severity-sla/',
    path: '/settings/severity-sla/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/severity-sla/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsScoreIndexLazyRoute =
  AccountIdVulnerabilitiesSettingsScoreIndexLazyImport.update({
    id: '/settings/score/',
    path: '/settings/score/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/score/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsRuleSetIndexLazyRoute =
  AccountIdVulnerabilitiesSettingsRuleSetIndexLazyImport.update({
    id: '/settings/rule-set/',
    path: '/settings/rule-set/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/rule-set/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyRoute =
  AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyImport.update({
    id: '/settings/exceptions-settings/',
    path: '/settings/exceptions-settings/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/exceptions-settings/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesPivotIdIndexLazyRoute =
  AccountIdVulnerabilitiesPivotIdIndexLazyImport.update({
    id: '/pivot/$id/',
    path: '/pivot/$id/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/pivot/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesFindingsVisualIndexLazyRoute =
  AccountIdVulnerabilitiesFindingsVisualIndexLazyImport.update({
    id: '/findings/visual/',
    path: '/findings/visual/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/findings/visual/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExceptionsVisualIndexLazyRoute =
  AccountIdVulnerabilitiesExceptionsVisualIndexLazyImport.update({
    id: '/exceptions/visual/',
    path: '/exceptions/visual/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/exceptions/visual/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesAssetsVisualIndexLazyRoute =
  AccountIdVulnerabilitiesAssetsVisualIndexLazyImport.update({
    id: '/assets/visual/',
    path: '/assets/visual/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/assets/visual/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyImport.update(
    {
      id: '/reports/',
      path: '/reports/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/reports/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyImport.update(
    {
      id: '/dashboards/',
      path: '/dashboards/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsIncidentsVisualIndexLazyRoute =
  AccountIdThreatsIncidentsVisualIndexLazyImport.update({
    id: '/incidents/visual/',
    path: '/incidents/visual/',
    getParentRoute: () => AccountIdThreatsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/threats/incidents/visual/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdThreatsAlertsVisualIndexLazyRoute =
  AccountIdThreatsAlertsVisualIndexLazyImport.update({
    id: '/alerts/visual/',
    path: '/alerts/visual/',
    getParentRoute: () => AccountIdThreatsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/threats/alerts/visual/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyImport.update({
    id: '/reports/',
    path: '/reports/',
    getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/reports/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyImport.update({
    id: '/dashboards/',
    path: '/dashboards/',
    getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/dashboards/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsUserManagementCreateIndexLazyRoute =
  AccountIdSettingsUserManagementCreateIndexLazyImport.update({
    id: '/$accountId/settings/user-management/create/',
    path: '/$accountId/settings/user-management/create/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/user-management/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsPermissionsRolesIndexLazyRoute =
  AccountIdSettingsPermissionsRolesIndexLazyImport.update({
    id: '/$accountId/settings/permissions/roles/',
    path: '/$accountId/settings/permissions/roles/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/permissions/roles/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsPermissionsContentPermissionsIndexLazyRoute =
  AccountIdSettingsPermissionsContentPermissionsIndexLazyImport.update({
    id: '/$accountId/settings/permissions/content-permissions/',
    path: '/$accountId/settings/permissions/content-permissions/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/permissions/content-permissions/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsGlobalFactorRulesCreateIndexLazyRoute =
  AccountIdSettingsGlobalFactorRulesCreateIndexLazyImport.update({
    id: '/$accountId/settings/global-factor-rules/create/',
    path: '/$accountId/settings/global-factor-rules/create/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/global-factor-rules/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsAllUsersCreateIndexLazyRoute =
  AccountIdSettingsAllUsersCreateIndexLazyImport.update({
    id: '/$accountId/settings/all-users/create/',
    path: '/$accountId/settings/all-users/create/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/all-users/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsAllAccountsCreateIndexLazyRoute =
  AccountIdSettingsAllAccountsCreateIndexLazyImport.update({
    id: '/$accountId/settings/all-accounts/create/',
    path: '/$accountId/settings/all-accounts/create/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/all-accounts/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdRisk360FactorsVisualIndexLazyRoute =
  AccountIdRisk360FactorsVisualIndexLazyImport.update({
    id: '/factors/visual/',
    path: '/factors/visual/',
    getParentRoute: () => AccountIdRisk360Route,
  } as any).lazy(() =>
    import('./routes/$accountId/risk360/factors/visual/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdRisk360FactorRulesRunsIndexLazyRoute =
  AccountIdRisk360FactorRulesRunsIndexLazyImport.update({
    id: '/factor-rules/runs/',
    path: '/factor-rules/runs/',
    getParentRoute: () => AccountIdRisk360Route,
  } as any).lazy(() =>
    import('./routes/$accountId/risk360/factor-rules/runs/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdRisk360FactorRulesCreateIndexLazyRoute =
  AccountIdRisk360FactorRulesCreateIndexLazyImport.update({
    id: '/factor-rules/create/',
    path: '/factor-rules/create/',
    getParentRoute: () => AccountIdRisk360Route,
  } as any).lazy(() =>
    import('./routes/$accountId/risk360/factor-rules/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyRoute =
  AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyImport.update({
    id: '/dashboards/',
    path: '/dashboards/',
    getParentRoute: () => AccountIdRisk360ExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/risk360/_externalProjectionsLayout/dashboards/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformTargetsCreateIndexLazyRoute =
  AccountIdPlatformTargetsCreateIndexLazyImport.update({
    id: '/targets/create/',
    path: '/targets/create/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/targets/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformSourcesCreateIndexLazyRoute =
  AccountIdPlatformSourcesCreateIndexLazyImport.update({
    id: '/sources/create/',
    path: '/sources/create/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/sources/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformRelationsRelationIdIndexLazyRoute =
  AccountIdPlatformRelationsRelationIdIndexLazyImport.update({
    id: '/relations/$relationId/',
    path: '/relations/$relationId/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/relations/$relationId/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformModelManagementRunsIndexLazyRoute =
  AccountIdPlatformModelManagementRunsIndexLazyImport.update({
    id: '/model-management/runs/',
    path: '/model-management/runs/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/model-management/runs/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformModelManagementIdIndexLazyRoute =
  AccountIdPlatformModelManagementIdIndexLazyImport.update({
    id: '/model-management/$id/',
    path: '/model-management/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/model-management/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformFormattingRulesCreateIndexLazyRoute =
  AccountIdPlatformFormattingRulesCreateIndexLazyImport.update({
    id: '/formatting-rules/create/',
    path: '/formatting-rules/create/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/formatting-rules/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformDataPurgeRunsIndexLazyRoute =
  AccountIdPlatformDataPurgeRunsIndexLazyImport.update({
    id: '/data-purge/runs/',
    path: '/data-purge/runs/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/data-purge/runs/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformDataPurgeProjIdIndexLazyRoute =
  AccountIdPlatformDataPurgeProjIdIndexLazyImport.update({
    id: '/data-purge/$projId/',
    path: '/data-purge/$projId/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/data-purge/$projId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformDataEntitiesFieldsIndexLazyRoute =
  AccountIdPlatformDataEntitiesFieldsIndexLazyImport.update({
    id: '/data-entities/fields/',
    path: '/data-entities/fields/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/data-entities/fields/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformDataEntitiesDataEntitiesIndexLazyRoute =
  AccountIdPlatformDataEntitiesDataEntitiesIndexLazyImport.update({
    id: '/data-entities/data-entities/',
    path: '/data-entities/data-entities/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/data-entities/data-entities/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformAssetAgingCreateIndexLazyRoute =
  AccountIdPlatformAssetAgingCreateIndexLazyImport.update({
    id: '/asset-aging/create/',
    path: '/asset-aging/create/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/asset-aging/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyRoute =
  AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyImport.update({
    id: '/measurements/',
    path: '/measurements/',
    getParentRoute: () => AccountIdPlatformExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/_externalProjectionsLayout/measurements/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsSettingsUiConfigIndexLazyRoute =
  AccountIdIncidentsSettingsUiConfigIndexLazyImport.update({
    id: '/settings/ui-config/',
    path: '/settings/ui-config/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/incidents/settings/ui-config/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdIncidentsSettingsTicketsStatusesIndexLazyRoute =
  AccountIdIncidentsSettingsTicketsStatusesIndexLazyImport.update({
    id: '/settings/tickets-statuses/',
    path: '/settings/tickets-statuses/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/incidents/settings/tickets-statuses/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsSettingsRuleSetIndexLazyRoute =
  AccountIdIncidentsSettingsRuleSetIndexLazyImport.update({
    id: '/settings/rule-set/',
    path: '/settings/rule-set/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/incidents/settings/rule-set/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdIncidentsPivotIdIndexLazyRoute =
  AccountIdIncidentsPivotIdIndexLazyImport.update({
    id: '/pivot/$id/',
    path: '/pivot/$id/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/incidents/pivot/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdIncidentsIncidentsVisualIndexLazyRoute =
  AccountIdIncidentsIncidentsVisualIndexLazyImport.update({
    id: '/incidents/visual/',
    path: '/incidents/visual/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/incidents/incidents/visual/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyImport.update({
    id: '/reports/',
    path: '/reports/',
    getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/reports/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyImport.update({
    id: '/dashboards/',
    path: '/dashboards/',
    getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/dashboards/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdExploreExternalProjectionsLayoutReportsIndexLazyRoute =
  AccountIdExploreExternalProjectionsLayoutReportsIndexLazyImport.update({
    id: '/reports/',
    path: '/reports/',
    getParentRoute: () => AccountIdExploreExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/explore/_externalProjectionsLayout/reports/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyRoute =
  AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyImport.update({
    id: '/dashboards/',
    path: '/dashboards/',
    getParentRoute: () => AccountIdExploreExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/explore/_externalProjectionsLayout/dashboards/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsSettingsUiConfigIndexLazyRoute =
  AccountIdDetectionsSettingsUiConfigIndexLazyImport.update({
    id: '/settings/ui-config/',
    path: '/settings/ui-config/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/settings/ui-config/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsSettingsTicketsStatusesIndexLazyRoute =
  AccountIdDetectionsSettingsTicketsStatusesIndexLazyImport.update({
    id: '/settings/tickets-statuses/',
    path: '/settings/tickets-statuses/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/settings/tickets-statuses/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsSettingsRuleSetIndexLazyRoute =
  AccountIdDetectionsSettingsRuleSetIndexLazyImport.update({
    id: '/settings/rule-set/',
    path: '/settings/rule-set/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/settings/rule-set/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsRulesLogsIndexLazyRoute =
  AccountIdDetectionsRulesLogsIndexLazyImport.update({
    id: '/rules/logs/',
    path: '/rules/logs/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/rules/logs/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsRulesCreateIndexLazyRoute =
  AccountIdDetectionsRulesCreateIndexLazyImport.update({
    id: '/rules/create/',
    path: '/rules/create/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/rules/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsPivotIdIndexLazyRoute =
  AccountIdDetectionsPivotIdIndexLazyImport.update({
    id: '/pivot/$id/',
    path: '/pivot/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/pivot/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsLookupTablesCreateIndexLazyRoute =
  AccountIdDetectionsLookupTablesCreateIndexLazyImport.update({
    id: '/lookup-tables/create/',
    path: '/lookup-tables/create/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/lookup-tables/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsIncidentsVisualIndexLazyRoute =
  AccountIdDetectionsIncidentsVisualIndexLazyImport.update({
    id: '/incidents/visual/',
    path: '/incidents/visual/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/incidents/visual/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsGlobalScriptsCreateIndexLazyRoute =
  AccountIdDetectionsGlobalScriptsCreateIndexLazyImport.update({
    id: '/global-scripts/create/',
    path: '/global-scripts/create/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/global-scripts/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExploreIdIndexLazyRoute =
  AccountIdDetectionsExploreIdIndexLazyImport.update({
    id: '/explore/$id/',
    path: '/explore/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/explore/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsAlertsVisualIndexLazyRoute =
  AccountIdDetectionsAlertsVisualIndexLazyImport.update({
    id: '/alerts/visual/',
    path: '/alerts/visual/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/alerts/visual/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyImport.update({
    id: '/reports/',
    path: '/reports/',
    getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/reports/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyImport.update({
    id: '/dashboards/',
    path: '/dashboards/',
    getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/dashboards/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmSettingsUiConfigIndexLazyRoute =
  AccountIdCaasmSettingsUiConfigIndexLazyImport.update({
    id: '/settings/ui-config/',
    path: '/settings/ui-config/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/settings/ui-config/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmPolicyViolationsVisualIndexLazyRoute =
  AccountIdCaasmPolicyViolationsVisualIndexLazyImport.update({
    id: '/policy-violations/visual/',
    path: '/policy-violations/visual/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/caasm/policy-violations/visual/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmPoliciesCreateIndexLazyRoute =
  AccountIdCaasmPoliciesCreateIndexLazyImport.update({
    id: '/policies/create/',
    path: '/policies/create/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/policies/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmAssetsVisualIndexLazyRoute =
  AccountIdCaasmAssetsVisualIndexLazyImport.update({
    id: '/assets/visual/',
    path: '/assets/visual/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/assets/visual/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyImport.update({
    id: '/reports/',
    path: '/reports/',
    getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/reports/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyImport.update({
    id: '/dashboards/',
    path: '/dashboards/',
    getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/dashboards/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesTicketsIdIndexRoute =
  AccountIdVulnerabilitiesTicketsIdIndexImport.update({
    id: '/tickets/$id/',
    path: '/tickets/$id/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/tickets/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesFindingsIdIndexRoute =
  AccountIdVulnerabilitiesFindingsIdIndexImport.update({
    id: '/findings/$id/',
    path: '/findings/$id/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/findings/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesExceptionsIdIndexRoute =
  AccountIdVulnerabilitiesExceptionsIdIndexImport.update({
    id: '/exceptions/$id/',
    path: '/exceptions/$id/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/exceptions/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesAssetsIdIndexRoute =
  AccountIdVulnerabilitiesAssetsIdIndexImport.update({
    id: '/assets/$id/',
    path: '/assets/$id/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/vulnerabilities/assets/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdThreatsIncidentsIdIndexRoute =
  AccountIdThreatsIncidentsIdIndexImport.update({
    id: '/incidents/$id/',
    path: '/incidents/$id/',
    getParentRoute: () => AccountIdThreatsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/threats/incidents/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdThreatsAlertsIdIndexRoute =
  AccountIdThreatsAlertsIdIndexImport.update({
    id: '/alerts/$id/',
    path: '/alerts/$id/',
    getParentRoute: () => AccountIdThreatsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/threats/alerts/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdRisk360FactorsIdIndexRoute =
  AccountIdRisk360FactorsIdIndexImport.update({
    id: '/factors/$id/',
    path: '/factors/$id/',
    getParentRoute: () => AccountIdRisk360Route,
  } as any).lazy(() =>
    import('./routes/$accountId/risk360/factors/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdIncidentsIncidentsIdIndexRoute =
  AccountIdIncidentsIncidentsIdIndexImport.update({
    id: '/incidents/$id/',
    path: '/incidents/$id/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/incidents/incidents/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsIncidentsIdIndexRoute =
  AccountIdDetectionsIncidentsIdIndexImport.update({
    id: '/incidents/$id/',
    path: '/incidents/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/incidents/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsAlertsIdIndexRoute =
  AccountIdDetectionsAlertsIdIndexImport.update({
    id: '/alerts/$id/',
    path: '/alerts/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/alerts/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmPolicyViolationsIdIndexRoute =
  AccountIdCaasmPolicyViolationsIdIndexImport.update({
    id: '/policy-violations/$id/',
    path: '/policy-violations/$id/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/policy-violations/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmAssetsIdIndexRoute =
  AccountIdCaasmAssetsIdIndexImport.update({
    id: '/assets/$id/',
    path: '/assets/$id/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/assets/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyRoute =
  AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyImport.update({
    id: '/ui-config/audit/$path/',
    path: '/ui-config/audit/$path/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/ui-config/audit/$path/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesScoreAuditPathIndexLazyRoute =
  AccountIdVulnerabilitiesScoreAuditPathIndexLazyImport.update({
    id: '/score/audit/$path/',
    path: '/score/audit/$path/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/score/audit/$path/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesFindingsRunsIdIndexLazyRoute =
  AccountIdVulnerabilitiesFindingsRunsIdIndexLazyImport.update({
    id: '/findings/runs/$id/',
    path: '/findings/runs/$id/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/findings/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyImport.update(
    {
      id: '/reports/create/',
      path: '/reports/create/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/reports/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyImport.update(
    {
      id: '/dashboards/create/',
      path: '/dashboards/create/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyImport.update({
    id: '/reports/create/',
    path: '/reports/create/',
    getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/reports/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyImport.update(
    {
      id: '/dashboards/create/',
      path: '/dashboards/create/',
      getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/dashboards/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsUserManagementEditIdIndexLazyRoute =
  AccountIdSettingsUserManagementEditIdIndexLazyImport.update({
    id: '/$accountId/settings/user-management/edit/$id/',
    path: '/$accountId/settings/user-management/edit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/user-management/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsUserManagementAuditIdIndexLazyRoute =
  AccountIdSettingsUserManagementAuditIdIndexLazyImport.update({
    id: '/$accountId/settings/user-management/audit/$id/',
    path: '/$accountId/settings/user-management/audit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/user-management/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsPermissionsRolesCreateIndexLazyRoute =
  AccountIdSettingsPermissionsRolesCreateIndexLazyImport.update({
    id: '/$accountId/settings/permissions/roles/create/',
    path: '/$accountId/settings/permissions/roles/create/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/permissions/roles/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyRoute =
  AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyImport.update({
    id: '/$accountId/settings/permissions/content-permissions/create/',
    path: '/$accountId/settings/permissions/content-permissions/create/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/permissions/content-permissions/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsGlobalFactorRulesEditIdIndexLazyRoute =
  AccountIdSettingsGlobalFactorRulesEditIdIndexLazyImport.update({
    id: '/$accountId/settings/global-factor-rules/edit/$id/',
    path: '/$accountId/settings/global-factor-rules/edit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/global-factor-rules/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyRoute =
  AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyImport.update({
    id: '/$accountId/settings/global-factor-rules/audit/$id/',
    path: '/$accountId/settings/global-factor-rules/audit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/global-factor-rules/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsAllUsersEditIdIndexLazyRoute =
  AccountIdSettingsAllUsersEditIdIndexLazyImport.update({
    id: '/$accountId/settings/all-users/edit/$id/',
    path: '/$accountId/settings/all-users/edit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/all-users/edit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsAllUsersAuditIdIndexLazyRoute =
  AccountIdSettingsAllUsersAuditIdIndexLazyImport.update({
    id: '/$accountId/settings/all-users/audit/$id/',
    path: '/$accountId/settings/all-users/audit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/settings/all-users/audit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdSettingsAllAccountsEditIdIndexLazyRoute =
  AccountIdSettingsAllAccountsEditIdIndexLazyImport.update({
    id: '/$accountId/settings/all-accounts/edit/$id/',
    path: '/$accountId/settings/all-accounts/edit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/all-accounts/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsAllAccountsAuditIdIndexLazyRoute =
  AccountIdSettingsAllAccountsAuditIdIndexLazyImport.update({
    id: '/$accountId/settings/all-accounts/audit/$id/',
    path: '/$accountId/settings/all-accounts/audit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/all-accounts/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdRisk360FactorRulesEditIdIndexLazyRoute =
  AccountIdRisk360FactorRulesEditIdIndexLazyImport.update({
    id: '/factor-rules/edit/$id/',
    path: '/factor-rules/edit/$id/',
    getParentRoute: () => AccountIdRisk360Route,
  } as any).lazy(() =>
    import('./routes/$accountId/risk360/factor-rules/edit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdRisk360FactorRulesAuditIdIndexLazyRoute =
  AccountIdRisk360FactorRulesAuditIdIndexLazyImport.update({
    id: '/factor-rules/audit/$id/',
    path: '/factor-rules/audit/$id/',
    getParentRoute: () => AccountIdRisk360Route,
  } as any).lazy(() =>
    import(
      './routes/$accountId/risk360/factor-rules/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyRoute =
  AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyImport.update(
    {
      id: '/dashboards/create/',
      path: '/dashboards/create/',
      getParentRoute: () => AccountIdRisk360ExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/risk360/_externalProjectionsLayout/dashboards/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformTargetsMappingIdIndexLazyRoute =
  AccountIdPlatformTargetsMappingIdIndexLazyImport.update({
    id: '/targets/mapping/$id/',
    path: '/targets/mapping/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/targets/mapping/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformTargetsEditIdIndexLazyRoute =
  AccountIdPlatformTargetsEditIdIndexLazyImport.update({
    id: '/targets/edit/$id/',
    path: '/targets/edit/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/targets/edit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformTargetsCreateTypeIndexLazyRoute =
  AccountIdPlatformTargetsCreateTypeIndexLazyImport.update({
    id: '/targets/create/$type/',
    path: '/targets/create/$type/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/targets/create/$type/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformTargetsAuditIdIndexLazyRoute =
  AccountIdPlatformTargetsAuditIdIndexLazyImport.update({
    id: '/targets/audit/$id/',
    path: '/targets/audit/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/targets/audit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformTargetsActivityLogIdIndexLazyRoute =
  AccountIdPlatformTargetsActivityLogIdIndexLazyImport.update({
    id: '/targets/activityLog/$id/',
    path: '/targets/activityLog/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/targets/activityLog/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformSourcesRunsIdIndexLazyRoute =
  AccountIdPlatformSourcesRunsIdIndexLazyImport.update({
    id: '/sources/runs/$id/',
    path: '/sources/runs/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/sources/runs/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformSourcesEditIdIndexLazyRoute =
  AccountIdPlatformSourcesEditIdIndexLazyImport.update({
    id: '/sources/edit/$id/',
    path: '/sources/edit/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/sources/edit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformSourcesCreateTypeIndexLazyRoute =
  AccountIdPlatformSourcesCreateTypeIndexLazyImport.update({
    id: '/sources/create/$type/',
    path: '/sources/create/$type/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/sources/create/$type/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformSourcesAuditIdIndexLazyRoute =
  AccountIdPlatformSourcesAuditIdIndexLazyImport.update({
    id: '/sources/audit/$id/',
    path: '/sources/audit/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/sources/audit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformModelManagementAuditIdIndexLazyRoute =
  AccountIdPlatformModelManagementAuditIdIndexLazyImport.update({
    id: '/model-management/audit/$id/',
    path: '/model-management/audit/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/model-management/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformModelManagementIdPreviewIndexLazyRoute =
  AccountIdPlatformModelManagementIdPreviewIndexLazyImport.update({
    id: '/model-management/$id/preview/',
    path: '/model-management/$id/preview/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/model-management/$id/preview/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformFormattingRulesEditIdIndexLazyRoute =
  AccountIdPlatformFormattingRulesEditIdIndexLazyImport.update({
    id: '/formatting-rules/edit/$id/',
    path: '/formatting-rules/edit/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/formatting-rules/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyRoute =
  AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyImport.update({
    id: '/data-entities/data-entities/$projId/',
    path: '/data-entities/data-entities/$projId/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/data-entities/data-entities/$projId/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformAssetAgingEditIdIndexLazyRoute =
  AccountIdPlatformAssetAgingEditIdIndexLazyImport.update({
    id: '/asset-aging/edit/$id/',
    path: '/asset-aging/edit/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/platform/asset-aging/edit.$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyRoute =
  AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyImport.update(
    {
      id: '/measurements/create/',
      path: '/measurements/create/',
      getParentRoute: () => AccountIdPlatformExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/platform/_externalProjectionsLayout/measurements/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsSettingsRuleSetCreateIndexLazyRoute =
  AccountIdIncidentsSettingsRuleSetCreateIndexLazyImport.update({
    id: '/settings/rule-set/create/',
    path: '/settings/rule-set/create/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/incidents/settings/rule-set/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsSettingsRuleSetIdIndexLazyRoute =
  AccountIdIncidentsSettingsRuleSetIdIndexLazyImport.update({
    id: '/settings/rule-set/$id/',
    path: '/settings/rule-set/$id/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/incidents/settings/rule-set/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyImport.update(
    {
      id: '/reports/create/',
      path: '/reports/create/',
      getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/reports/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyImport.update(
    {
      id: '/dashboards/create/',
      path: '/dashboards/create/',
      getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/dashboards/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyRoute =
  AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyImport.update({
    id: '/reports/create/',
    path: '/reports/create/',
    getParentRoute: () => AccountIdExploreExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/explore/_externalProjectionsLayout/reports/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyRoute =
  AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyImport.update(
    {
      id: '/dashboards/create/',
      path: '/dashboards/create/',
      getParentRoute: () => AccountIdExploreExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/explore/_externalProjectionsLayout/dashboards/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsUiConfigAuditPathIndexLazyRoute =
  AccountIdDetectionsUiConfigAuditPathIndexLazyImport.update({
    id: '/ui-config/audit/$path/',
    path: '/ui-config/audit/$path/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/ui-config/audit/$path/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsSettingsRuleSetCreateIndexLazyRoute =
  AccountIdDetectionsSettingsRuleSetCreateIndexLazyImport.update({
    id: '/settings/rule-set/create/',
    path: '/settings/rule-set/create/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/settings/rule-set/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsSettingsRuleSetIdIndexLazyRoute =
  AccountIdDetectionsSettingsRuleSetIdIndexLazyImport.update({
    id: '/settings/rule-set/$id/',
    path: '/settings/rule-set/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/settings/rule-set/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsRulesRunsIdIndexLazyRoute =
  AccountIdDetectionsRulesRunsIdIndexLazyImport.update({
    id: '/rules/runs/$id/',
    path: '/rules/runs/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/rules/runs/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsRulesEditIdIndexLazyRoute =
  AccountIdDetectionsRulesEditIdIndexLazyImport.update({
    id: '/rules/edit/$id/',
    path: '/rules/edit/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/rules/edit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsRulesAuditIdIndexLazyRoute =
  AccountIdDetectionsRulesAuditIdIndexLazyImport.update({
    id: '/rules/audit/$id/',
    path: '/rules/audit/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/detections/rules/audit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdDetectionsLookupTablesEditIdIndexLazyRoute =
  AccountIdDetectionsLookupTablesEditIdIndexLazyImport.update({
    id: '/lookup-tables/edit/$id/',
    path: '/lookup-tables/edit/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/lookup-tables/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsLookupTablesAuditIdIndexLazyRoute =
  AccountIdDetectionsLookupTablesAuditIdIndexLazyImport.update({
    id: '/lookup-tables/audit/$id/',
    path: '/lookup-tables/audit/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/lookup-tables/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsGlobalScriptsEditIdIndexLazyRoute =
  AccountIdDetectionsGlobalScriptsEditIdIndexLazyImport.update({
    id: '/global-scripts/edit/$id/',
    path: '/global-scripts/edit/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/global-scripts/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsGlobalScriptsAuditIdIndexLazyRoute =
  AccountIdDetectionsGlobalScriptsAuditIdIndexLazyImport.update({
    id: '/global-scripts/audit/$id/',
    path: '/global-scripts/audit/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/global-scripts/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyImport.update(
    {
      id: '/reports/create/',
      path: '/reports/create/',
      getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/reports/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyImport.update(
    {
      id: '/dashboards/create/',
      path: '/dashboards/create/',
      getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/dashboards/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmUiConfigAuditPathIndexLazyRoute =
  AccountIdCaasmUiConfigAuditPathIndexLazyImport.update({
    id: '/ui-config/audit/$path/',
    path: '/ui-config/audit/$path/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/ui-config/audit/$path/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmPoliciesRunsIdIndexLazyRoute =
  AccountIdCaasmPoliciesRunsIdIndexLazyImport.update({
    id: '/policies/runs/$id/',
    path: '/policies/runs/$id/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/policies/runs/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmPoliciesEditIdIndexLazyRoute =
  AccountIdCaasmPoliciesEditIdIndexLazyImport.update({
    id: '/policies/edit/$id/',
    path: '/policies/edit/$id/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/policies/edit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmPoliciesDuplicateIdIndexLazyRoute =
  AccountIdCaasmPoliciesDuplicateIdIndexLazyImport.update({
    id: '/policies/duplicate/$id/',
    path: '/policies/duplicate/$id/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/policies/duplicate/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmPoliciesAuditIdIndexLazyRoute =
  AccountIdCaasmPoliciesAuditIdIndexLazyImport.update({
    id: '/policies/audit/$id/',
    path: '/policies/audit/$id/',
    getParentRoute: () => AccountIdCaasmRoute,
  } as any).lazy(() =>
    import('./routes/$accountId/caasm/policies/audit/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyImport.update({
    id: '/reports/create/',
    path: '/reports/create/',
    getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/reports/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyImport.update(
    {
      id: '/dashboards/create/',
      path: '/dashboards/create/',
      getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/dashboards/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsRuleSetCreateIndexRoute =
  AccountIdVulnerabilitiesSettingsRuleSetCreateIndexImport.update({
    id: '/settings/rule-set/create/',
    path: '/settings/rule-set/create/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/rule-set/create/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsRuleSetIdIndexRoute =
  AccountIdVulnerabilitiesSettingsRuleSetIdIndexImport.update({
    id: '/settings/rule-set/$id/',
    path: '/settings/rule-set/$id/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/rule-set/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyRoute =
  AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyImport.update({
    id: '/settings/rule-set/audit/$id/',
    path: '/settings/rule-set/audit/$id/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/rule-set/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyImport.update(
    {
      id: '/reports/runs/$id/',
      path: '/reports/runs/$id/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/reports/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyImport.update(
    {
      id: '/reports/edit/$id/',
      path: '/reports/edit/$id/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/reports/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyImport.update(
    {
      id: '/reports/audit/$id/',
      path: '/reports/audit/$id/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/reports/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport.update(
    {
      id: '/dashboards/runs/$id/',
      path: '/dashboards/runs/$id/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyImport.update(
    {
      id: '/dashboards/edit/$id/',
      path: '/dashboards/edit/$id/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute =
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport.update(
    {
      id: '/dashboards/audit/$id/',
      path: '/dashboards/audit/$id/',
      getParentRoute: () =>
        AccountIdVulnerabilitiesExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyImport.update({
    id: '/reports/runs/$id/',
    path: '/reports/runs/$id/',
    getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/reports/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyImport.update({
    id: '/reports/edit/$id/',
    path: '/reports/edit/$id/',
    getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/reports/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyImport.update(
    {
      id: '/reports/audit/$id/',
      path: '/reports/audit/$id/',
      getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/reports/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport.update(
    {
      id: '/dashboards/runs/$id/',
      path: '/dashboards/runs/$id/',
      getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/dashboards/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyImport.update(
    {
      id: '/dashboards/edit/$id/',
      path: '/dashboards/edit/$id/',
      getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/dashboards/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute =
  AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport.update(
    {
      id: '/dashboards/audit/$id/',
      path: '/dashboards/audit/$id/',
      getParentRoute: () => AccountIdThreatsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/threats/_externalProjectionsLayout/dashboards/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsPermissionsRolesEditIdIndexLazyRoute =
  AccountIdSettingsPermissionsRolesEditIdIndexLazyImport.update({
    id: '/$accountId/settings/permissions/roles/edit/$id/',
    path: '/$accountId/settings/permissions/roles/edit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/permissions/roles/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsPermissionsRolesAuditIdIndexLazyRoute =
  AccountIdSettingsPermissionsRolesAuditIdIndexLazyImport.update({
    id: '/$accountId/settings/permissions/roles/audit/$id/',
    path: '/$accountId/settings/permissions/roles/audit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/permissions/roles/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyRoute =
  AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyImport.update({
    id: '/$accountId/settings/permissions/content-permissions/edit/$id/',
    path: '/$accountId/settings/permissions/content-permissions/edit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/permissions/content-permissions/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyRoute =
  AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyImport.update({
    id: '/$accountId/settings/permissions/content-permissions/audit/$id/',
    path: '/$accountId/settings/permissions/content-permissions/audit/$id/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/settings/permissions/content-permissions/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute =
  AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport.update(
    {
      id: '/dashboards/runs/$id/',
      path: '/dashboards/runs/$id/',
      getParentRoute: () => AccountIdRisk360ExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/risk360/_externalProjectionsLayout/dashboards/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute =
  AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyImport.update(
    {
      id: '/dashboards/edit/$id/',
      path: '/dashboards/edit/$id/',
      getParentRoute: () => AccountIdRisk360ExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/risk360/_externalProjectionsLayout/dashboards/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute =
  AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport.update(
    {
      id: '/dashboards/audit/$id/',
      path: '/dashboards/audit/$id/',
      getParentRoute: () => AccountIdRisk360ExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/risk360/_externalProjectionsLayout/dashboards/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformSourcesEditIdPreviewIdIndexLazyRoute =
  AccountIdPlatformSourcesEditIdPreviewIdIndexLazyImport.update({
    id: '/sources/edit/$id/$previewId/',
    path: '/sources/edit/$id/$previewId/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/sources/edit/$id/$previewId/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyRoute =
  AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyImport.update({
    id: '/sources/create/$type/$previewId/',
    path: '/sources/create/$type/$previewId/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/sources/create/$type/$previewId/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformSourcesIdMappingPathTypeIndexLazyRoute =
  AccountIdPlatformSourcesIdMappingPathTypeIndexLazyImport.update({
    id: '/sources/$id/$mappingPath/$type/',
    path: '/sources/$id/$mappingPath/$type/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/sources/$id/$mappingPath/$type/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyRoute =
  AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyImport.update({
    id: '/data-entities/fields/set/$fieldName/',
    path: '/data-entities/fields/set/$fieldName/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/data-entities/fields/set.$fieldName/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyRoute =
  AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyImport.update({
    id: '/data-entities/data-entities/$projId/$ruleId/',
    path: '/data-entities/data-entities/$projId/$ruleId/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/data-entities/data-entities/$projId/$ruleId/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyRoute =
  AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyImport.update(
    {
      id: '/measurements/edit/$systemName/',
      path: '/measurements/edit/$systemName/',
      getParentRoute: () => AccountIdPlatformExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/platform/_externalProjectionsLayout/measurements/edit/$systemName/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyRoute =
  AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyImport.update(
    {
      id: '/measurements/audit/$id/',
      path: '/measurements/audit/$id/',
      getParentRoute: () => AccountIdPlatformExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/platform/_externalProjectionsLayout/measurements/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyRoute =
  AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyImport.update({
    id: '/settings/ui-config/audit/$path/',
    path: '/settings/ui-config/audit/$path/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/incidents/settings/ui-config/audit/$path/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyRoute =
  AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyImport.update({
    id: '/settings/rule-set/create/$ruleIndex/',
    path: '/settings/rule-set/create/$ruleIndex/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/incidents/settings/rule-set/create/$ruleIndex/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyRoute =
  AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyImport.update({
    id: '/settings/rule-set/audit/$id/',
    path: '/settings/rule-set/audit/$id/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/incidents/settings/rule-set/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyRoute =
  AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyImport.update({
    id: '/settings/rule-set/$id/$ruleIndex/',
    path: '/settings/rule-set/$id/$ruleIndex/',
    getParentRoute: () => AccountIdIncidentsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/incidents/settings/rule-set/$id/$ruleIndex/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyImport.update(
    {
      id: '/reports/runs/$id/',
      path: '/reports/runs/$id/',
      getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/reports/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyImport.update(
    {
      id: '/reports/edit/$id/',
      path: '/reports/edit/$id/',
      getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/reports/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyImport.update(
    {
      id: '/reports/audit/$id/',
      path: '/reports/audit/$id/',
      getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/reports/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport.update(
    {
      id: '/dashboards/runs/$id/',
      path: '/dashboards/runs/$id/',
      getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/dashboards/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyImport.update(
    {
      id: '/dashboards/edit/$id/',
      path: '/dashboards/edit/$id/',
      getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/dashboards/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute =
  AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport.update(
    {
      id: '/dashboards/audit/$id/',
      path: '/dashboards/audit/$id/',
      getParentRoute: () => AccountIdIncidentsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/incidents/_externalProjectionsLayout/dashboards/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyRoute =
  AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyImport.update({
    id: '/reports/runs/$id/',
    path: '/reports/runs/$id/',
    getParentRoute: () => AccountIdExploreExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/explore/_externalProjectionsLayout/reports/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyRoute =
  AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyImport.update({
    id: '/reports/edit/$id/',
    path: '/reports/edit/$id/',
    getParentRoute: () => AccountIdExploreExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/explore/_externalProjectionsLayout/reports/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute =
  AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport.update(
    {
      id: '/dashboards/runs/$id/',
      path: '/dashboards/runs/$id/',
      getParentRoute: () => AccountIdExploreExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/explore/_externalProjectionsLayout/dashboards/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute =
  AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyImport.update(
    {
      id: '/dashboards/edit/$id/',
      path: '/dashboards/edit/$id/',
      getParentRoute: () => AccountIdExploreExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/explore/_externalProjectionsLayout/dashboards/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute =
  AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport.update(
    {
      id: '/dashboards/audit/$id/',
      path: '/dashboards/audit/$id/',
      getParentRoute: () => AccountIdExploreExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/explore/_externalProjectionsLayout/dashboards/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyRoute =
  AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyImport.update({
    id: '/settings/rule-set/create/$ruleIndex/',
    path: '/settings/rule-set/create/$ruleIndex/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/settings/rule-set/create/$ruleIndex/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyRoute =
  AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyImport.update({
    id: '/settings/rule-set/audit/$id/',
    path: '/settings/rule-set/audit/$id/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/settings/rule-set/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyRoute =
  AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyImport.update({
    id: '/settings/rule-set/$id/$ruleIndex/',
    path: '/settings/rule-set/$id/$ruleIndex/',
    getParentRoute: () => AccountIdDetectionsRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/detections/settings/rule-set/$id/$ruleIndex/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyImport.update(
    {
      id: '/reports/runs/$id/',
      path: '/reports/runs/$id/',
      getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/reports/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyImport.update(
    {
      id: '/reports/edit/$id/',
      path: '/reports/edit/$id/',
      getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/reports/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyImport.update(
    {
      id: '/reports/audit/$id/',
      path: '/reports/audit/$id/',
      getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/reports/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport.update(
    {
      id: '/dashboards/runs/$id/',
      path: '/dashboards/runs/$id/',
      getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/dashboards/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyImport.update(
    {
      id: '/dashboards/edit/$id/',
      path: '/dashboards/edit/$id/',
      getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/dashboards/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute =
  AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport.update(
    {
      id: '/dashboards/audit/$id/',
      path: '/dashboards/audit/$id/',
      getParentRoute: () => AccountIdDetectionsExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/detections/_externalProjectionsLayout/dashboards/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyImport.update({
    id: '/reports/runs/$id/',
    path: '/reports/runs/$id/',
    getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/reports/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyImport.update({
    id: '/reports/edit/$id/',
    path: '/reports/edit/$id/',
    getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/reports/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyImport.update({
    id: '/reports/audit/$id/',
    path: '/reports/audit/$id/',
    getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/reports/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport.update(
    {
      id: '/dashboards/runs/$id/',
      path: '/dashboards/runs/$id/',
      getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/dashboards/runs/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyImport.update(
    {
      id: '/dashboards/edit/$id/',
      path: '/dashboards/edit/$id/',
      getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/dashboards/edit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute =
  AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport.update(
    {
      id: '/dashboards/audit/$id/',
      path: '/dashboards/audit/$id/',
      getParentRoute: () => AccountIdCaasmExternalProjectionsLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/$accountId/caasm/_externalProjectionsLayout/dashboards/audit/$id/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexRoute =
  AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexImport.update({
    id: '/settings/rule-set/create/$ruleIndex/',
    path: '/settings/rule-set/create/$ruleIndex/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexRoute =
  AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexImport.update({
    id: '/settings/rule-set/$id/$ruleIndex/',
    path: '/settings/rule-set/$id/$ruleIndex/',
    getParentRoute: () => AccountIdVulnerabilitiesRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyRoute =
  AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyImport.update({
    id: '/targets/audit/$id/mapping/$idMapping/',
    path: '/targets/audit/$id/mapping/$idMapping/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/targets/audit/$id/mapping/$idMapping/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyRoute =
  AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyImport.update({
    id: '/sources/audit/$id/data-mapping/$type/',
    path: '/sources/audit/$id/data-mapping/$type/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/sources/audit/$id/data-mapping/$type/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyRoute =
  AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyImport.update({
    id: '/sources/$id/$mappingPath/$type/preview/',
    path: '/sources/$id/$mappingPath/$type/preview/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any).lazy(() =>
    import(
      './routes/$accountId/platform/sources/$id/$mappingPath/$type/preview/index.lazy'
    ).then((d) => d.Route),
  )

const AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexRoute =
  AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexImport.update({
    id: '/data-entities/fields/set/$fieldName/create/',
    path: '/data-entities/fields/set/$fieldName/create/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any)

const AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexRoute =
  AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexImport.update({
    id: '/data-entities/fields/set/$fieldName/edit/$id/',
    path: '/data-entities/fields/set/$fieldName/edit/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any)

const AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexRoute =
  AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexImport.update({
    id: '/data-entities/fields/set/$fieldName/audit/$id/',
    path: '/data-entities/fields/set/$fieldName/audit/$id/',
    getParentRoute: () => AccountIdPlatformRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/error-process-request': {
      id: '/error-process-request'
      path: '/error-process-request'
      fullPath: '/error-process-request'
      preLoaderRoute: typeof ErrorProcessRequestImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/': {
      id: '/$accountId/'
      path: '/$accountId'
      fullPath: '/$accountId'
      preLoaderRoute: typeof AccountIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/caasm': {
      id: '/$accountId/caasm'
      path: '/$accountId/caasm'
      fullPath: '/$accountId/caasm'
      preLoaderRoute: typeof AccountIdCaasmImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/caasm/_externalProjectionsLayout': {
      id: '/$accountId/caasm/_externalProjectionsLayout'
      path: '/$accountId/caasm'
      fullPath: '/$accountId/caasm'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
      parentRoute: typeof AccountIdCaasmRoute
    }
    '/$accountId/detections': {
      id: '/$accountId/detections'
      path: '/$accountId/detections'
      fullPath: '/$accountId/detections'
      preLoaderRoute: typeof AccountIdDetectionsImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/detections/_externalProjectionsLayout': {
      id: '/$accountId/detections/_externalProjectionsLayout'
      path: '/$accountId/detections'
      fullPath: '/$accountId/detections'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
      parentRoute: typeof AccountIdDetectionsRoute
    }
    '/$accountId/explore': {
      id: '/$accountId/explore'
      path: '/$accountId/explore'
      fullPath: '/$accountId/explore'
      preLoaderRoute: typeof AccountIdExploreImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/explore/_externalProjectionsLayout': {
      id: '/$accountId/explore/_externalProjectionsLayout'
      path: '/$accountId/explore'
      fullPath: '/$accountId/explore'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
      parentRoute: typeof AccountIdExploreRoute
    }
    '/$accountId/incidents': {
      id: '/$accountId/incidents'
      path: '/$accountId/incidents'
      fullPath: '/$accountId/incidents'
      preLoaderRoute: typeof AccountIdIncidentsImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/incidents/_externalProjectionsLayout': {
      id: '/$accountId/incidents/_externalProjectionsLayout'
      path: '/$accountId/incidents'
      fullPath: '/$accountId/incidents'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
      parentRoute: typeof AccountIdIncidentsRoute
    }
    '/$accountId/platform': {
      id: '/$accountId/platform'
      path: '/$accountId/platform'
      fullPath: '/$accountId/platform'
      preLoaderRoute: typeof AccountIdPlatformImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/platform/_externalProjectionsLayout': {
      id: '/$accountId/platform/_externalProjectionsLayout'
      path: '/$accountId/platform'
      fullPath: '/$accountId/platform'
      preLoaderRoute: typeof AccountIdPlatformExternalProjectionsLayoutImport
      parentRoute: typeof AccountIdPlatformRoute
    }
    '/$accountId/risk360': {
      id: '/$accountId/risk360'
      path: '/$accountId/risk360'
      fullPath: '/$accountId/risk360'
      preLoaderRoute: typeof AccountIdRisk360Import
      parentRoute: typeof rootRoute
    }
    '/$accountId/risk360/_externalProjectionsLayout': {
      id: '/$accountId/risk360/_externalProjectionsLayout'
      path: '/$accountId/risk360'
      fullPath: '/$accountId/risk360'
      preLoaderRoute: typeof AccountIdRisk360ExternalProjectionsLayoutImport
      parentRoute: typeof AccountIdRisk360Route
    }
    '/$accountId/threats': {
      id: '/$accountId/threats'
      path: '/$accountId/threats'
      fullPath: '/$accountId/threats'
      preLoaderRoute: typeof AccountIdThreatsImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/threats/_externalProjectionsLayout': {
      id: '/$accountId/threats/_externalProjectionsLayout'
      path: '/$accountId/threats'
      fullPath: '/$accountId/threats'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
      parentRoute: typeof AccountIdThreatsRoute
    }
    '/$accountId/vulnerabilities': {
      id: '/$accountId/vulnerabilities'
      path: '/$accountId/vulnerabilities'
      fullPath: '/$accountId/vulnerabilities'
      preLoaderRoute: typeof AccountIdVulnerabilitiesImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout'
      path: '/$accountId/vulnerabilities'
      fullPath: '/$accountId/vulnerabilities'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
      parentRoute: typeof AccountIdVulnerabilitiesRoute
    }
    '/$accountId/tickets/': {
      id: '/$accountId/tickets/'
      path: '/$accountId/tickets'
      fullPath: '/$accountId/tickets'
      preLoaderRoute: typeof AccountIdTicketsIndexImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/caasm/': {
      id: '/$accountId/caasm/'
      path: '/'
      fullPath: '/$accountId/caasm/'
      preLoaderRoute: typeof AccountIdCaasmIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/detections/': {
      id: '/$accountId/detections/'
      path: '/'
      fullPath: '/$accountId/detections/'
      preLoaderRoute: typeof AccountIdDetectionsIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/explore/': {
      id: '/$accountId/explore/'
      path: '/'
      fullPath: '/$accountId/explore/'
      preLoaderRoute: typeof AccountIdExploreIndexLazyImport
      parentRoute: typeof AccountIdExploreImport
    }
    '/$accountId/incidents/': {
      id: '/$accountId/incidents/'
      path: '/'
      fullPath: '/$accountId/incidents/'
      preLoaderRoute: typeof AccountIdIncidentsIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/risk360/': {
      id: '/$accountId/risk360/'
      path: '/'
      fullPath: '/$accountId/risk360/'
      preLoaderRoute: typeof AccountIdRisk360IndexLazyImport
      parentRoute: typeof AccountIdRisk360Import
    }
    '/$accountId/vulnerabilities/': {
      id: '/$accountId/vulnerabilities/'
      path: '/'
      fullPath: '/$accountId/vulnerabilities/'
      preLoaderRoute: typeof AccountIdVulnerabilitiesIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/caasm/assets/': {
      id: '/$accountId/caasm/assets/'
      path: '/assets'
      fullPath: '/$accountId/caasm/assets'
      preLoaderRoute: typeof AccountIdCaasmAssetsIndexImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/policy-violations/': {
      id: '/$accountId/caasm/policy-violations/'
      path: '/policy-violations'
      fullPath: '/$accountId/caasm/policy-violations'
      preLoaderRoute: typeof AccountIdCaasmPolicyViolationsIndexImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/detections/alerts/': {
      id: '/$accountId/detections/alerts/'
      path: '/alerts'
      fullPath: '/$accountId/detections/alerts'
      preLoaderRoute: typeof AccountIdDetectionsAlertsIndexImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/incidents/': {
      id: '/$accountId/detections/incidents/'
      path: '/incidents'
      fullPath: '/$accountId/detections/incidents'
      preLoaderRoute: typeof AccountIdDetectionsIncidentsIndexImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/incidents/incidents/': {
      id: '/$accountId/incidents/incidents/'
      path: '/incidents'
      fullPath: '/$accountId/incidents/incidents'
      preLoaderRoute: typeof AccountIdIncidentsIncidentsIndexImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/risk360/factors/': {
      id: '/$accountId/risk360/factors/'
      path: '/factors'
      fullPath: '/$accountId/risk360/factors'
      preLoaderRoute: typeof AccountIdRisk360FactorsIndexImport
      parentRoute: typeof AccountIdRisk360Import
    }
    '/$accountId/threats/alerts/': {
      id: '/$accountId/threats/alerts/'
      path: '/alerts'
      fullPath: '/$accountId/threats/alerts'
      preLoaderRoute: typeof AccountIdThreatsAlertsIndexImport
      parentRoute: typeof AccountIdThreatsImport
    }
    '/$accountId/threats/incidents/': {
      id: '/$accountId/threats/incidents/'
      path: '/incidents'
      fullPath: '/$accountId/threats/incidents'
      preLoaderRoute: typeof AccountIdThreatsIncidentsIndexImport
      parentRoute: typeof AccountIdThreatsImport
    }
    '/$accountId/tickets/$id/': {
      id: '/$accountId/tickets/$id/'
      path: '/$accountId/tickets/$id'
      fullPath: '/$accountId/tickets/$id'
      preLoaderRoute: typeof AccountIdTicketsIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/vulnerabilities/assets/': {
      id: '/$accountId/vulnerabilities/assets/'
      path: '/assets'
      fullPath: '/$accountId/vulnerabilities/assets'
      preLoaderRoute: typeof AccountIdVulnerabilitiesAssetsIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/exceptions/': {
      id: '/$accountId/vulnerabilities/exceptions/'
      path: '/exceptions'
      fullPath: '/$accountId/vulnerabilities/exceptions'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExceptionsIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/findings/': {
      id: '/$accountId/vulnerabilities/findings/'
      path: '/findings'
      fullPath: '/$accountId/vulnerabilities/findings'
      preLoaderRoute: typeof AccountIdVulnerabilitiesFindingsIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/tickets/': {
      id: '/$accountId/vulnerabilities/tickets/'
      path: '/tickets'
      fullPath: '/$accountId/vulnerabilities/tickets'
      preLoaderRoute: typeof AccountIdVulnerabilitiesTicketsIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/caasm/asset-coverage/': {
      id: '/$accountId/caasm/asset-coverage/'
      path: '/asset-coverage'
      fullPath: '/$accountId/caasm/asset-coverage'
      preLoaderRoute: typeof AccountIdCaasmAssetCoverageIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/asset-inventory/': {
      id: '/$accountId/caasm/asset-inventory/'
      path: '/asset-inventory'
      fullPath: '/$accountId/caasm/asset-inventory'
      preLoaderRoute: typeof AccountIdCaasmAssetInventoryIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/policies/': {
      id: '/$accountId/caasm/policies/'
      path: '/policies'
      fullPath: '/$accountId/caasm/policies'
      preLoaderRoute: typeof AccountIdCaasmPoliciesIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/policy-compliance/': {
      id: '/$accountId/caasm/policy-compliance/'
      path: '/policy-compliance'
      fullPath: '/$accountId/caasm/policy-compliance'
      preLoaderRoute: typeof AccountIdCaasmPolicyComplianceIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/settings/': {
      id: '/$accountId/caasm/settings/'
      path: '/settings'
      fullPath: '/$accountId/caasm/settings'
      preLoaderRoute: typeof AccountIdCaasmSettingsIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/tool-coverage-and-gap/': {
      id: '/$accountId/caasm/tool-coverage-and-gap/'
      path: '/tool-coverage-and-gap'
      fullPath: '/$accountId/caasm/tool-coverage-and-gap'
      preLoaderRoute: typeof AccountIdCaasmToolCoverageAndGapIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/detections/explore/': {
      id: '/$accountId/detections/explore/'
      path: '/explore'
      fullPath: '/$accountId/detections/explore'
      preLoaderRoute: typeof AccountIdDetectionsExploreIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/global-scripts/': {
      id: '/$accountId/detections/global-scripts/'
      path: '/global-scripts'
      fullPath: '/$accountId/detections/global-scripts'
      preLoaderRoute: typeof AccountIdDetectionsGlobalScriptsIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/lookup-tables/': {
      id: '/$accountId/detections/lookup-tables/'
      path: '/lookup-tables'
      fullPath: '/$accountId/detections/lookup-tables'
      preLoaderRoute: typeof AccountIdDetectionsLookupTablesIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/pivot/': {
      id: '/$accountId/detections/pivot/'
      path: '/pivot'
      fullPath: '/$accountId/detections/pivot'
      preLoaderRoute: typeof AccountIdDetectionsPivotIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/rules/': {
      id: '/$accountId/detections/rules/'
      path: '/rules'
      fullPath: '/$accountId/detections/rules'
      preLoaderRoute: typeof AccountIdDetectionsRulesIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/settings/': {
      id: '/$accountId/detections/settings/'
      path: '/settings'
      fullPath: '/$accountId/detections/settings'
      preLoaderRoute: typeof AccountIdDetectionsSettingsIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/explore/entities/': {
      id: '/$accountId/explore/entities/'
      path: '/entities'
      fullPath: '/$accountId/explore/entities'
      preLoaderRoute: typeof AccountIdExploreEntitiesIndexLazyImport
      parentRoute: typeof AccountIdExploreImport
    }
    '/$accountId/explore/logs/': {
      id: '/$accountId/explore/logs/'
      path: '/logs'
      fullPath: '/$accountId/explore/logs'
      preLoaderRoute: typeof AccountIdExploreLogsIndexLazyImport
      parentRoute: typeof AccountIdExploreImport
    }
    '/$accountId/incidents/pivot/': {
      id: '/$accountId/incidents/pivot/'
      path: '/pivot'
      fullPath: '/$accountId/incidents/pivot'
      preLoaderRoute: typeof AccountIdIncidentsPivotIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/incidents/settings/': {
      id: '/$accountId/incidents/settings/'
      path: '/settings'
      fullPath: '/$accountId/incidents/settings'
      preLoaderRoute: typeof AccountIdIncidentsSettingsIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/platform/aliases/': {
      id: '/$accountId/platform/aliases/'
      path: '/aliases'
      fullPath: '/$accountId/platform/aliases'
      preLoaderRoute: typeof AccountIdPlatformAliasesIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/asset-aging/': {
      id: '/$accountId/platform/asset-aging/'
      path: '/asset-aging'
      fullPath: '/$accountId/platform/asset-aging'
      preLoaderRoute: typeof AccountIdPlatformAssetAgingIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/authentication-sources/': {
      id: '/$accountId/platform/authentication-sources/'
      path: '/authentication-sources'
      fullPath: '/$accountId/platform/authentication-sources'
      preLoaderRoute: typeof AccountIdPlatformAuthenticationSourcesIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/data-purge/': {
      id: '/$accountId/platform/data-purge/'
      path: '/data-purge'
      fullPath: '/$accountId/platform/data-purge'
      preLoaderRoute: typeof AccountIdPlatformDataPurgeIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/formatting-rules/': {
      id: '/$accountId/platform/formatting-rules/'
      path: '/formatting-rules'
      fullPath: '/$accountId/platform/formatting-rules'
      preLoaderRoute: typeof AccountIdPlatformFormattingRulesIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/model-management/': {
      id: '/$accountId/platform/model-management/'
      path: '/model-management'
      fullPath: '/$accountId/platform/model-management'
      preLoaderRoute: typeof AccountIdPlatformModelManagementIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/relations/': {
      id: '/$accountId/platform/relations/'
      path: '/relations'
      fullPath: '/$accountId/platform/relations'
      preLoaderRoute: typeof AccountIdPlatformRelationsIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/': {
      id: '/$accountId/platform/sources/'
      path: '/sources'
      fullPath: '/$accountId/platform/sources'
      preLoaderRoute: typeof AccountIdPlatformSourcesIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/targets/': {
      id: '/$accountId/platform/targets/'
      path: '/targets'
      fullPath: '/$accountId/platform/targets'
      preLoaderRoute: typeof AccountIdPlatformTargetsIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/workflows/': {
      id: '/$accountId/platform/workflows/'
      path: '/workflows'
      fullPath: '/$accountId/platform/workflows'
      preLoaderRoute: typeof AccountIdPlatformWorkflowsIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/risk360/factor-rules/': {
      id: '/$accountId/risk360/factor-rules/'
      path: '/factor-rules'
      fullPath: '/$accountId/risk360/factor-rules'
      preLoaderRoute: typeof AccountIdRisk360FactorRulesIndexLazyImport
      parentRoute: typeof AccountIdRisk360Import
    }
    '/$accountId/settings/account-settings/': {
      id: '/$accountId/settings/account-settings/'
      path: '/$accountId/settings/account-settings'
      fullPath: '/$accountId/settings/account-settings'
      preLoaderRoute: typeof AccountIdSettingsAccountSettingsIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/account-usage/': {
      id: '/$accountId/settings/account-usage/'
      path: '/$accountId/settings/account-usage'
      fullPath: '/$accountId/settings/account-usage'
      preLoaderRoute: typeof AccountIdSettingsAccountUsageIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/all-accounts/': {
      id: '/$accountId/settings/all-accounts/'
      path: '/$accountId/settings/all-accounts'
      fullPath: '/$accountId/settings/all-accounts'
      preLoaderRoute: typeof AccountIdSettingsAllAccountsIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/all-users/': {
      id: '/$accountId/settings/all-users/'
      path: '/$accountId/settings/all-users'
      fullPath: '/$accountId/settings/all-users'
      preLoaderRoute: typeof AccountIdSettingsAllUsersIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/backoffice-actions/': {
      id: '/$accountId/settings/backoffice-actions/'
      path: '/$accountId/settings/backoffice-actions'
      fullPath: '/$accountId/settings/backoffice-actions'
      preLoaderRoute: typeof AccountIdSettingsBackofficeActionsIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/global-factor-rules/': {
      id: '/$accountId/settings/global-factor-rules/'
      path: '/$accountId/settings/global-factor-rules'
      fullPath: '/$accountId/settings/global-factor-rules'
      preLoaderRoute: typeof AccountIdSettingsGlobalFactorRulesIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/icons/': {
      id: '/$accountId/settings/icons/'
      path: '/$accountId/settings/icons'
      fullPath: '/$accountId/settings/icons'
      preLoaderRoute: typeof AccountIdSettingsIconsIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/profile-settings/': {
      id: '/$accountId/settings/profile-settings/'
      path: '/$accountId/settings/profile-settings'
      fullPath: '/$accountId/settings/profile-settings'
      preLoaderRoute: typeof AccountIdSettingsProfileSettingsIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/query/': {
      id: '/$accountId/settings/query/'
      path: '/$accountId/settings/query'
      fullPath: '/$accountId/settings/query'
      preLoaderRoute: typeof AccountIdSettingsQueryIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/risk360-backoffice-actions/': {
      id: '/$accountId/settings/risk360-backoffice-actions/'
      path: '/$accountId/settings/risk360-backoffice-actions'
      fullPath: '/$accountId/settings/risk360-backoffice-actions'
      preLoaderRoute: typeof AccountIdSettingsRisk360BackofficeActionsIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/user-management/': {
      id: '/$accountId/settings/user-management/'
      path: '/$accountId/settings/user-management'
      fullPath: '/$accountId/settings/user-management'
      preLoaderRoute: typeof AccountIdSettingsUserManagementIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/vulnerabilities/asset-coverage/': {
      id: '/$accountId/vulnerabilities/asset-coverage/'
      path: '/asset-coverage'
      fullPath: '/$accountId/vulnerabilities/asset-coverage'
      preLoaderRoute: typeof AccountIdVulnerabilitiesAssetCoverageIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/explore/': {
      id: '/$accountId/vulnerabilities/explore/'
      path: '/explore'
      fullPath: '/$accountId/vulnerabilities/explore'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExploreIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/overview/': {
      id: '/$accountId/vulnerabilities/overview/'
      path: '/overview'
      fullPath: '/$accountId/vulnerabilities/overview'
      preLoaderRoute: typeof AccountIdVulnerabilitiesOverviewIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/pivot/': {
      id: '/$accountId/vulnerabilities/pivot/'
      path: '/pivot'
      fullPath: '/$accountId/vulnerabilities/pivot'
      preLoaderRoute: typeof AccountIdVulnerabilitiesPivotIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/remediation/': {
      id: '/$accountId/vulnerabilities/remediation/'
      path: '/remediation'
      fullPath: '/$accountId/vulnerabilities/remediation'
      preLoaderRoute: typeof AccountIdVulnerabilitiesRemediationIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/risk/': {
      id: '/$accountId/vulnerabilities/risk/'
      path: '/risk'
      fullPath: '/$accountId/vulnerabilities/risk'
      preLoaderRoute: typeof AccountIdVulnerabilitiesRiskIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/roi/': {
      id: '/$accountId/vulnerabilities/roi/'
      path: '/roi'
      fullPath: '/$accountId/vulnerabilities/roi'
      preLoaderRoute: typeof AccountIdVulnerabilitiesRoiIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/': {
      id: '/$accountId/vulnerabilities/settings/'
      path: '/settings'
      fullPath: '/$accountId/vulnerabilities/settings'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/caasm/assets/$id/': {
      id: '/$accountId/caasm/assets/$id/'
      path: '/assets/$id'
      fullPath: '/$accountId/caasm/assets/$id'
      preLoaderRoute: typeof AccountIdCaasmAssetsIdIndexImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/policy-violations/$id/': {
      id: '/$accountId/caasm/policy-violations/$id/'
      path: '/policy-violations/$id'
      fullPath: '/$accountId/caasm/policy-violations/$id'
      preLoaderRoute: typeof AccountIdCaasmPolicyViolationsIdIndexImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/detections/alerts/$id/': {
      id: '/$accountId/detections/alerts/$id/'
      path: '/alerts/$id'
      fullPath: '/$accountId/detections/alerts/$id'
      preLoaderRoute: typeof AccountIdDetectionsAlertsIdIndexImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/incidents/$id/': {
      id: '/$accountId/detections/incidents/$id/'
      path: '/incidents/$id'
      fullPath: '/$accountId/detections/incidents/$id'
      preLoaderRoute: typeof AccountIdDetectionsIncidentsIdIndexImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/incidents/incidents/$id/': {
      id: '/$accountId/incidents/incidents/$id/'
      path: '/incidents/$id'
      fullPath: '/$accountId/incidents/incidents/$id'
      preLoaderRoute: typeof AccountIdIncidentsIncidentsIdIndexImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/risk360/factors/$id/': {
      id: '/$accountId/risk360/factors/$id/'
      path: '/factors/$id'
      fullPath: '/$accountId/risk360/factors/$id'
      preLoaderRoute: typeof AccountIdRisk360FactorsIdIndexImport
      parentRoute: typeof AccountIdRisk360Import
    }
    '/$accountId/threats/alerts/$id/': {
      id: '/$accountId/threats/alerts/$id/'
      path: '/alerts/$id'
      fullPath: '/$accountId/threats/alerts/$id'
      preLoaderRoute: typeof AccountIdThreatsAlertsIdIndexImport
      parentRoute: typeof AccountIdThreatsImport
    }
    '/$accountId/threats/incidents/$id/': {
      id: '/$accountId/threats/incidents/$id/'
      path: '/incidents/$id'
      fullPath: '/$accountId/threats/incidents/$id'
      preLoaderRoute: typeof AccountIdThreatsIncidentsIdIndexImport
      parentRoute: typeof AccountIdThreatsImport
    }
    '/$accountId/vulnerabilities/assets/$id/': {
      id: '/$accountId/vulnerabilities/assets/$id/'
      path: '/assets/$id'
      fullPath: '/$accountId/vulnerabilities/assets/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesAssetsIdIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/exceptions/$id/': {
      id: '/$accountId/vulnerabilities/exceptions/$id/'
      path: '/exceptions/$id'
      fullPath: '/$accountId/vulnerabilities/exceptions/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExceptionsIdIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/findings/$id/': {
      id: '/$accountId/vulnerabilities/findings/$id/'
      path: '/findings/$id'
      fullPath: '/$accountId/vulnerabilities/findings/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesFindingsIdIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/tickets/$id/': {
      id: '/$accountId/vulnerabilities/tickets/$id/'
      path: '/tickets/$id'
      fullPath: '/$accountId/vulnerabilities/tickets/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesTicketsIdIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/dashboards/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/dashboards/'
      path: '/dashboards'
      fullPath: '/$accountId/caasm/dashboards'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/reports/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/reports/'
      path: '/reports'
      fullPath: '/$accountId/caasm/reports'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/caasm/assets/visual/': {
      id: '/$accountId/caasm/assets/visual/'
      path: '/assets/visual'
      fullPath: '/$accountId/caasm/assets/visual'
      preLoaderRoute: typeof AccountIdCaasmAssetsVisualIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/policies/create/': {
      id: '/$accountId/caasm/policies/create/'
      path: '/policies/create'
      fullPath: '/$accountId/caasm/policies/create'
      preLoaderRoute: typeof AccountIdCaasmPoliciesCreateIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/policy-violations/visual/': {
      id: '/$accountId/caasm/policy-violations/visual/'
      path: '/policy-violations/visual'
      fullPath: '/$accountId/caasm/policy-violations/visual'
      preLoaderRoute: typeof AccountIdCaasmPolicyViolationsVisualIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/settings/ui-config/': {
      id: '/$accountId/caasm/settings/ui-config/'
      path: '/settings/ui-config'
      fullPath: '/$accountId/caasm/settings/ui-config'
      preLoaderRoute: typeof AccountIdCaasmSettingsUiConfigIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/detections/_externalProjectionsLayout/dashboards/': {
      id: '/$accountId/detections/_externalProjectionsLayout/dashboards/'
      path: '/dashboards'
      fullPath: '/$accountId/detections/dashboards'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/_externalProjectionsLayout/reports/': {
      id: '/$accountId/detections/_externalProjectionsLayout/reports/'
      path: '/reports'
      fullPath: '/$accountId/detections/reports'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/alerts/visual/': {
      id: '/$accountId/detections/alerts/visual/'
      path: '/alerts/visual'
      fullPath: '/$accountId/detections/alerts/visual'
      preLoaderRoute: typeof AccountIdDetectionsAlertsVisualIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/explore/$id/': {
      id: '/$accountId/detections/explore/$id/'
      path: '/explore/$id'
      fullPath: '/$accountId/detections/explore/$id'
      preLoaderRoute: typeof AccountIdDetectionsExploreIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/global-scripts/create/': {
      id: '/$accountId/detections/global-scripts/create/'
      path: '/global-scripts/create'
      fullPath: '/$accountId/detections/global-scripts/create'
      preLoaderRoute: typeof AccountIdDetectionsGlobalScriptsCreateIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/incidents/visual/': {
      id: '/$accountId/detections/incidents/visual/'
      path: '/incidents/visual'
      fullPath: '/$accountId/detections/incidents/visual'
      preLoaderRoute: typeof AccountIdDetectionsIncidentsVisualIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/lookup-tables/create/': {
      id: '/$accountId/detections/lookup-tables/create/'
      path: '/lookup-tables/create'
      fullPath: '/$accountId/detections/lookup-tables/create'
      preLoaderRoute: typeof AccountIdDetectionsLookupTablesCreateIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/pivot/$id/': {
      id: '/$accountId/detections/pivot/$id/'
      path: '/pivot/$id'
      fullPath: '/$accountId/detections/pivot/$id'
      preLoaderRoute: typeof AccountIdDetectionsPivotIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/rules/create/': {
      id: '/$accountId/detections/rules/create/'
      path: '/rules/create'
      fullPath: '/$accountId/detections/rules/create'
      preLoaderRoute: typeof AccountIdDetectionsRulesCreateIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/rules/logs/': {
      id: '/$accountId/detections/rules/logs/'
      path: '/rules/logs'
      fullPath: '/$accountId/detections/rules/logs'
      preLoaderRoute: typeof AccountIdDetectionsRulesLogsIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/settings/rule-set/': {
      id: '/$accountId/detections/settings/rule-set/'
      path: '/settings/rule-set'
      fullPath: '/$accountId/detections/settings/rule-set'
      preLoaderRoute: typeof AccountIdDetectionsSettingsRuleSetIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/settings/tickets-statuses/': {
      id: '/$accountId/detections/settings/tickets-statuses/'
      path: '/settings/tickets-statuses'
      fullPath: '/$accountId/detections/settings/tickets-statuses'
      preLoaderRoute: typeof AccountIdDetectionsSettingsTicketsStatusesIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/settings/ui-config/': {
      id: '/$accountId/detections/settings/ui-config/'
      path: '/settings/ui-config'
      fullPath: '/$accountId/detections/settings/ui-config'
      preLoaderRoute: typeof AccountIdDetectionsSettingsUiConfigIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/explore/_externalProjectionsLayout/dashboards/': {
      id: '/$accountId/explore/_externalProjectionsLayout/dashboards/'
      path: '/dashboards'
      fullPath: '/$accountId/explore/dashboards'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyImport
      parentRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
    }
    '/$accountId/explore/_externalProjectionsLayout/reports/': {
      id: '/$accountId/explore/_externalProjectionsLayout/reports/'
      path: '/reports'
      fullPath: '/$accountId/explore/reports'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutReportsIndexLazyImport
      parentRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/dashboards/'
      path: '/dashboards'
      fullPath: '/$accountId/incidents/dashboards'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/reports/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/reports/'
      path: '/reports'
      fullPath: '/$accountId/incidents/reports'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/incidents/visual/': {
      id: '/$accountId/incidents/incidents/visual/'
      path: '/incidents/visual'
      fullPath: '/$accountId/incidents/incidents/visual'
      preLoaderRoute: typeof AccountIdIncidentsIncidentsVisualIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/incidents/pivot/$id/': {
      id: '/$accountId/incidents/pivot/$id/'
      path: '/pivot/$id'
      fullPath: '/$accountId/incidents/pivot/$id'
      preLoaderRoute: typeof AccountIdIncidentsPivotIdIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/incidents/settings/rule-set/': {
      id: '/$accountId/incidents/settings/rule-set/'
      path: '/settings/rule-set'
      fullPath: '/$accountId/incidents/settings/rule-set'
      preLoaderRoute: typeof AccountIdIncidentsSettingsRuleSetIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/incidents/settings/tickets-statuses/': {
      id: '/$accountId/incidents/settings/tickets-statuses/'
      path: '/settings/tickets-statuses'
      fullPath: '/$accountId/incidents/settings/tickets-statuses'
      preLoaderRoute: typeof AccountIdIncidentsSettingsTicketsStatusesIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/incidents/settings/ui-config/': {
      id: '/$accountId/incidents/settings/ui-config/'
      path: '/settings/ui-config'
      fullPath: '/$accountId/incidents/settings/ui-config'
      preLoaderRoute: typeof AccountIdIncidentsSettingsUiConfigIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/platform/_externalProjectionsLayout/measurements/': {
      id: '/$accountId/platform/_externalProjectionsLayout/measurements/'
      path: '/measurements'
      fullPath: '/$accountId/platform/measurements'
      preLoaderRoute: typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyImport
      parentRoute: typeof AccountIdPlatformExternalProjectionsLayoutImport
    }
    '/$accountId/platform/asset-aging/create/': {
      id: '/$accountId/platform/asset-aging/create/'
      path: '/asset-aging/create'
      fullPath: '/$accountId/platform/asset-aging/create'
      preLoaderRoute: typeof AccountIdPlatformAssetAgingCreateIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/data-entities/data-entities/': {
      id: '/$accountId/platform/data-entities/data-entities/'
      path: '/data-entities/data-entities'
      fullPath: '/$accountId/platform/data-entities/data-entities'
      preLoaderRoute: typeof AccountIdPlatformDataEntitiesDataEntitiesIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/data-entities/fields/': {
      id: '/$accountId/platform/data-entities/fields/'
      path: '/data-entities/fields'
      fullPath: '/$accountId/platform/data-entities/fields'
      preLoaderRoute: typeof AccountIdPlatformDataEntitiesFieldsIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/data-purge/$projId/': {
      id: '/$accountId/platform/data-purge/$projId/'
      path: '/data-purge/$projId'
      fullPath: '/$accountId/platform/data-purge/$projId'
      preLoaderRoute: typeof AccountIdPlatformDataPurgeProjIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/data-purge/runs/': {
      id: '/$accountId/platform/data-purge/runs/'
      path: '/data-purge/runs'
      fullPath: '/$accountId/platform/data-purge/runs'
      preLoaderRoute: typeof AccountIdPlatformDataPurgeRunsIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/formatting-rules/create/': {
      id: '/$accountId/platform/formatting-rules/create/'
      path: '/formatting-rules/create'
      fullPath: '/$accountId/platform/formatting-rules/create'
      preLoaderRoute: typeof AccountIdPlatformFormattingRulesCreateIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/model-management/$id/': {
      id: '/$accountId/platform/model-management/$id/'
      path: '/model-management/$id'
      fullPath: '/$accountId/platform/model-management/$id'
      preLoaderRoute: typeof AccountIdPlatformModelManagementIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/model-management/runs/': {
      id: '/$accountId/platform/model-management/runs/'
      path: '/model-management/runs'
      fullPath: '/$accountId/platform/model-management/runs'
      preLoaderRoute: typeof AccountIdPlatformModelManagementRunsIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/relations/$relationId/': {
      id: '/$accountId/platform/relations/$relationId/'
      path: '/relations/$relationId'
      fullPath: '/$accountId/platform/relations/$relationId'
      preLoaderRoute: typeof AccountIdPlatformRelationsRelationIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/create/': {
      id: '/$accountId/platform/sources/create/'
      path: '/sources/create'
      fullPath: '/$accountId/platform/sources/create'
      preLoaderRoute: typeof AccountIdPlatformSourcesCreateIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/targets/create/': {
      id: '/$accountId/platform/targets/create/'
      path: '/targets/create'
      fullPath: '/$accountId/platform/targets/create'
      preLoaderRoute: typeof AccountIdPlatformTargetsCreateIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/': {
      id: '/$accountId/risk360/_externalProjectionsLayout/dashboards/'
      path: '/dashboards'
      fullPath: '/$accountId/risk360/dashboards'
      preLoaderRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyImport
      parentRoute: typeof AccountIdRisk360ExternalProjectionsLayoutImport
    }
    '/$accountId/risk360/factor-rules/create/': {
      id: '/$accountId/risk360/factor-rules/create/'
      path: '/factor-rules/create'
      fullPath: '/$accountId/risk360/factor-rules/create'
      preLoaderRoute: typeof AccountIdRisk360FactorRulesCreateIndexLazyImport
      parentRoute: typeof AccountIdRisk360Import
    }
    '/$accountId/risk360/factor-rules/runs/': {
      id: '/$accountId/risk360/factor-rules/runs/'
      path: '/factor-rules/runs'
      fullPath: '/$accountId/risk360/factor-rules/runs'
      preLoaderRoute: typeof AccountIdRisk360FactorRulesRunsIndexLazyImport
      parentRoute: typeof AccountIdRisk360Import
    }
    '/$accountId/risk360/factors/visual/': {
      id: '/$accountId/risk360/factors/visual/'
      path: '/factors/visual'
      fullPath: '/$accountId/risk360/factors/visual'
      preLoaderRoute: typeof AccountIdRisk360FactorsVisualIndexLazyImport
      parentRoute: typeof AccountIdRisk360Import
    }
    '/$accountId/settings/all-accounts/create/': {
      id: '/$accountId/settings/all-accounts/create/'
      path: '/$accountId/settings/all-accounts/create'
      fullPath: '/$accountId/settings/all-accounts/create'
      preLoaderRoute: typeof AccountIdSettingsAllAccountsCreateIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/all-users/create/': {
      id: '/$accountId/settings/all-users/create/'
      path: '/$accountId/settings/all-users/create'
      fullPath: '/$accountId/settings/all-users/create'
      preLoaderRoute: typeof AccountIdSettingsAllUsersCreateIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/global-factor-rules/create/': {
      id: '/$accountId/settings/global-factor-rules/create/'
      path: '/$accountId/settings/global-factor-rules/create'
      fullPath: '/$accountId/settings/global-factor-rules/create'
      preLoaderRoute: typeof AccountIdSettingsGlobalFactorRulesCreateIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/permissions/content-permissions/': {
      id: '/$accountId/settings/permissions/content-permissions/'
      path: '/$accountId/settings/permissions/content-permissions'
      fullPath: '/$accountId/settings/permissions/content-permissions'
      preLoaderRoute: typeof AccountIdSettingsPermissionsContentPermissionsIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/permissions/roles/': {
      id: '/$accountId/settings/permissions/roles/'
      path: '/$accountId/settings/permissions/roles'
      fullPath: '/$accountId/settings/permissions/roles'
      preLoaderRoute: typeof AccountIdSettingsPermissionsRolesIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/user-management/create/': {
      id: '/$accountId/settings/user-management/create/'
      path: '/$accountId/settings/user-management/create'
      fullPath: '/$accountId/settings/user-management/create'
      preLoaderRoute: typeof AccountIdSettingsUserManagementCreateIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/threats/_externalProjectionsLayout/dashboards/': {
      id: '/$accountId/threats/_externalProjectionsLayout/dashboards/'
      path: '/dashboards'
      fullPath: '/$accountId/threats/dashboards'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/threats/_externalProjectionsLayout/reports/': {
      id: '/$accountId/threats/_externalProjectionsLayout/reports/'
      path: '/reports'
      fullPath: '/$accountId/threats/reports'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/threats/alerts/visual/': {
      id: '/$accountId/threats/alerts/visual/'
      path: '/alerts/visual'
      fullPath: '/$accountId/threats/alerts/visual'
      preLoaderRoute: typeof AccountIdThreatsAlertsVisualIndexLazyImport
      parentRoute: typeof AccountIdThreatsImport
    }
    '/$accountId/threats/incidents/visual/': {
      id: '/$accountId/threats/incidents/visual/'
      path: '/incidents/visual'
      fullPath: '/$accountId/threats/incidents/visual'
      preLoaderRoute: typeof AccountIdThreatsIncidentsVisualIndexLazyImport
      parentRoute: typeof AccountIdThreatsImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/'
      path: '/dashboards'
      fullPath: '/$accountId/vulnerabilities/dashboards'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/'
      path: '/reports'
      fullPath: '/$accountId/vulnerabilities/reports'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/assets/visual/': {
      id: '/$accountId/vulnerabilities/assets/visual/'
      path: '/assets/visual'
      fullPath: '/$accountId/vulnerabilities/assets/visual'
      preLoaderRoute: typeof AccountIdVulnerabilitiesAssetsVisualIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/exceptions/visual/': {
      id: '/$accountId/vulnerabilities/exceptions/visual/'
      path: '/exceptions/visual'
      fullPath: '/$accountId/vulnerabilities/exceptions/visual'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExceptionsVisualIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/findings/visual/': {
      id: '/$accountId/vulnerabilities/findings/visual/'
      path: '/findings/visual'
      fullPath: '/$accountId/vulnerabilities/findings/visual'
      preLoaderRoute: typeof AccountIdVulnerabilitiesFindingsVisualIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/pivot/$id/': {
      id: '/$accountId/vulnerabilities/pivot/$id/'
      path: '/pivot/$id'
      fullPath: '/$accountId/vulnerabilities/pivot/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesPivotIdIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/exceptions-settings/': {
      id: '/$accountId/vulnerabilities/settings/exceptions-settings/'
      path: '/settings/exceptions-settings'
      fullPath: '/$accountId/vulnerabilities/settings/exceptions-settings'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/rule-set/': {
      id: '/$accountId/vulnerabilities/settings/rule-set/'
      path: '/settings/rule-set'
      fullPath: '/$accountId/vulnerabilities/settings/rule-set'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/score/': {
      id: '/$accountId/vulnerabilities/settings/score/'
      path: '/settings/score'
      fullPath: '/$accountId/vulnerabilities/settings/score'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsScoreIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/severity-sla/': {
      id: '/$accountId/vulnerabilities/settings/severity-sla/'
      path: '/settings/severity-sla'
      fullPath: '/$accountId/vulnerabilities/settings/severity-sla'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/tickets-statuses/': {
      id: '/$accountId/vulnerabilities/settings/tickets-statuses/'
      path: '/settings/tickets-statuses'
      fullPath: '/$accountId/vulnerabilities/settings/tickets-statuses'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/ui-config/': {
      id: '/$accountId/vulnerabilities/settings/ui-config/'
      path: '/settings/ui-config'
      fullPath: '/$accountId/vulnerabilities/settings/ui-config'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsUiConfigIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/tickets/visual/': {
      id: '/$accountId/vulnerabilities/tickets/visual/'
      path: '/tickets/visual'
      fullPath: '/$accountId/vulnerabilities/tickets/visual'
      preLoaderRoute: typeof AccountIdVulnerabilitiesTicketsVisualIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/rule-set/$id/': {
      id: '/$accountId/vulnerabilities/settings/rule-set/$id/'
      path: '/settings/rule-set/$id'
      fullPath: '/$accountId/vulnerabilities/settings/rule-set/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetIdIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/rule-set/create/': {
      id: '/$accountId/vulnerabilities/settings/rule-set/create/'
      path: '/settings/rule-set/create'
      fullPath: '/$accountId/vulnerabilities/settings/rule-set/create'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetCreateIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/dashboards/create/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/dashboards/create/'
      path: '/dashboards/create'
      fullPath: '/$accountId/caasm/dashboards/create'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/reports/create/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/reports/create/'
      path: '/reports/create'
      fullPath: '/$accountId/caasm/reports/create'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/caasm/policies/audit/$id/': {
      id: '/$accountId/caasm/policies/audit/$id/'
      path: '/policies/audit/$id'
      fullPath: '/$accountId/caasm/policies/audit/$id'
      preLoaderRoute: typeof AccountIdCaasmPoliciesAuditIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/policies/duplicate/$id/': {
      id: '/$accountId/caasm/policies/duplicate/$id/'
      path: '/policies/duplicate/$id'
      fullPath: '/$accountId/caasm/policies/duplicate/$id'
      preLoaderRoute: typeof AccountIdCaasmPoliciesDuplicateIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/policies/edit/$id/': {
      id: '/$accountId/caasm/policies/edit/$id/'
      path: '/policies/edit/$id'
      fullPath: '/$accountId/caasm/policies/edit/$id'
      preLoaderRoute: typeof AccountIdCaasmPoliciesEditIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/policies/runs/$id/': {
      id: '/$accountId/caasm/policies/runs/$id/'
      path: '/policies/runs/$id'
      fullPath: '/$accountId/caasm/policies/runs/$id'
      preLoaderRoute: typeof AccountIdCaasmPoliciesRunsIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/caasm/ui-config/audit/$path/': {
      id: '/$accountId/caasm/ui-config/audit/$path/'
      path: '/ui-config/audit/$path'
      fullPath: '/$accountId/caasm/ui-config/audit/$path'
      preLoaderRoute: typeof AccountIdCaasmUiConfigAuditPathIndexLazyImport
      parentRoute: typeof AccountIdCaasmImport
    }
    '/$accountId/detections/_externalProjectionsLayout/dashboards/create/': {
      id: '/$accountId/detections/_externalProjectionsLayout/dashboards/create/'
      path: '/dashboards/create'
      fullPath: '/$accountId/detections/dashboards/create'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/_externalProjectionsLayout/reports/create/': {
      id: '/$accountId/detections/_externalProjectionsLayout/reports/create/'
      path: '/reports/create'
      fullPath: '/$accountId/detections/reports/create'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/global-scripts/audit/$id/': {
      id: '/$accountId/detections/global-scripts/audit/$id/'
      path: '/global-scripts/audit/$id'
      fullPath: '/$accountId/detections/global-scripts/audit/$id'
      preLoaderRoute: typeof AccountIdDetectionsGlobalScriptsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/global-scripts/edit/$id/': {
      id: '/$accountId/detections/global-scripts/edit/$id/'
      path: '/global-scripts/edit/$id'
      fullPath: '/$accountId/detections/global-scripts/edit/$id'
      preLoaderRoute: typeof AccountIdDetectionsGlobalScriptsEditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/lookup-tables/audit/$id/': {
      id: '/$accountId/detections/lookup-tables/audit/$id/'
      path: '/lookup-tables/audit/$id'
      fullPath: '/$accountId/detections/lookup-tables/audit/$id'
      preLoaderRoute: typeof AccountIdDetectionsLookupTablesAuditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/lookup-tables/edit/$id/': {
      id: '/$accountId/detections/lookup-tables/edit/$id/'
      path: '/lookup-tables/edit/$id'
      fullPath: '/$accountId/detections/lookup-tables/edit/$id'
      preLoaderRoute: typeof AccountIdDetectionsLookupTablesEditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/rules/audit/$id/': {
      id: '/$accountId/detections/rules/audit/$id/'
      path: '/rules/audit/$id'
      fullPath: '/$accountId/detections/rules/audit/$id'
      preLoaderRoute: typeof AccountIdDetectionsRulesAuditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/rules/edit/$id/': {
      id: '/$accountId/detections/rules/edit/$id/'
      path: '/rules/edit/$id'
      fullPath: '/$accountId/detections/rules/edit/$id'
      preLoaderRoute: typeof AccountIdDetectionsRulesEditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/rules/runs/$id/': {
      id: '/$accountId/detections/rules/runs/$id/'
      path: '/rules/runs/$id'
      fullPath: '/$accountId/detections/rules/runs/$id'
      preLoaderRoute: typeof AccountIdDetectionsRulesRunsIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/settings/rule-set/$id/': {
      id: '/$accountId/detections/settings/rule-set/$id/'
      path: '/settings/rule-set/$id'
      fullPath: '/$accountId/detections/settings/rule-set/$id'
      preLoaderRoute: typeof AccountIdDetectionsSettingsRuleSetIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/settings/rule-set/create/': {
      id: '/$accountId/detections/settings/rule-set/create/'
      path: '/settings/rule-set/create'
      fullPath: '/$accountId/detections/settings/rule-set/create'
      preLoaderRoute: typeof AccountIdDetectionsSettingsRuleSetCreateIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/ui-config/audit/$path/': {
      id: '/$accountId/detections/ui-config/audit/$path/'
      path: '/ui-config/audit/$path'
      fullPath: '/$accountId/detections/ui-config/audit/$path'
      preLoaderRoute: typeof AccountIdDetectionsUiConfigAuditPathIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/explore/_externalProjectionsLayout/dashboards/create/': {
      id: '/$accountId/explore/_externalProjectionsLayout/dashboards/create/'
      path: '/dashboards/create'
      fullPath: '/$accountId/explore/dashboards/create'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyImport
      parentRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
    }
    '/$accountId/explore/_externalProjectionsLayout/reports/create/': {
      id: '/$accountId/explore/_externalProjectionsLayout/reports/create/'
      path: '/reports/create'
      fullPath: '/$accountId/explore/reports/create'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyImport
      parentRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/create/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/dashboards/create/'
      path: '/dashboards/create'
      fullPath: '/$accountId/incidents/dashboards/create'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/reports/create/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/reports/create/'
      path: '/reports/create'
      fullPath: '/$accountId/incidents/reports/create'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/settings/rule-set/$id/': {
      id: '/$accountId/incidents/settings/rule-set/$id/'
      path: '/settings/rule-set/$id'
      fullPath: '/$accountId/incidents/settings/rule-set/$id'
      preLoaderRoute: typeof AccountIdIncidentsSettingsRuleSetIdIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/incidents/settings/rule-set/create/': {
      id: '/$accountId/incidents/settings/rule-set/create/'
      path: '/settings/rule-set/create'
      fullPath: '/$accountId/incidents/settings/rule-set/create'
      preLoaderRoute: typeof AccountIdIncidentsSettingsRuleSetCreateIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/platform/_externalProjectionsLayout/measurements/create/': {
      id: '/$accountId/platform/_externalProjectionsLayout/measurements/create/'
      path: '/measurements/create'
      fullPath: '/$accountId/platform/measurements/create'
      preLoaderRoute: typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyImport
      parentRoute: typeof AccountIdPlatformExternalProjectionsLayoutImport
    }
    '/$accountId/platform/asset-aging/edit/$id/': {
      id: '/$accountId/platform/asset-aging/edit/$id/'
      path: '/asset-aging/edit/$id'
      fullPath: '/$accountId/platform/asset-aging/edit/$id'
      preLoaderRoute: typeof AccountIdPlatformAssetAgingEditIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/data-entities/data-entities/$projId/': {
      id: '/$accountId/platform/data-entities/data-entities/$projId/'
      path: '/data-entities/data-entities/$projId'
      fullPath: '/$accountId/platform/data-entities/data-entities/$projId'
      preLoaderRoute: typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/formatting-rules/edit/$id/': {
      id: '/$accountId/platform/formatting-rules/edit/$id/'
      path: '/formatting-rules/edit/$id'
      fullPath: '/$accountId/platform/formatting-rules/edit/$id'
      preLoaderRoute: typeof AccountIdPlatformFormattingRulesEditIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/model-management/$id/preview/': {
      id: '/$accountId/platform/model-management/$id/preview/'
      path: '/model-management/$id/preview'
      fullPath: '/$accountId/platform/model-management/$id/preview'
      preLoaderRoute: typeof AccountIdPlatformModelManagementIdPreviewIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/model-management/audit/$id/': {
      id: '/$accountId/platform/model-management/audit/$id/'
      path: '/model-management/audit/$id'
      fullPath: '/$accountId/platform/model-management/audit/$id'
      preLoaderRoute: typeof AccountIdPlatformModelManagementAuditIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/audit/$id/': {
      id: '/$accountId/platform/sources/audit/$id/'
      path: '/sources/audit/$id'
      fullPath: '/$accountId/platform/sources/audit/$id'
      preLoaderRoute: typeof AccountIdPlatformSourcesAuditIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/create/$type/': {
      id: '/$accountId/platform/sources/create/$type/'
      path: '/sources/create/$type'
      fullPath: '/$accountId/platform/sources/create/$type'
      preLoaderRoute: typeof AccountIdPlatformSourcesCreateTypeIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/edit/$id/': {
      id: '/$accountId/platform/sources/edit/$id/'
      path: '/sources/edit/$id'
      fullPath: '/$accountId/platform/sources/edit/$id'
      preLoaderRoute: typeof AccountIdPlatformSourcesEditIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/runs/$id/': {
      id: '/$accountId/platform/sources/runs/$id/'
      path: '/sources/runs/$id'
      fullPath: '/$accountId/platform/sources/runs/$id'
      preLoaderRoute: typeof AccountIdPlatformSourcesRunsIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/targets/activityLog/$id/': {
      id: '/$accountId/platform/targets/activityLog/$id/'
      path: '/targets/activityLog/$id'
      fullPath: '/$accountId/platform/targets/activityLog/$id'
      preLoaderRoute: typeof AccountIdPlatformTargetsActivityLogIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/targets/audit/$id/': {
      id: '/$accountId/platform/targets/audit/$id/'
      path: '/targets/audit/$id'
      fullPath: '/$accountId/platform/targets/audit/$id'
      preLoaderRoute: typeof AccountIdPlatformTargetsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/targets/create/$type/': {
      id: '/$accountId/platform/targets/create/$type/'
      path: '/targets/create/$type'
      fullPath: '/$accountId/platform/targets/create/$type'
      preLoaderRoute: typeof AccountIdPlatformTargetsCreateTypeIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/targets/edit/$id/': {
      id: '/$accountId/platform/targets/edit/$id/'
      path: '/targets/edit/$id'
      fullPath: '/$accountId/platform/targets/edit/$id'
      preLoaderRoute: typeof AccountIdPlatformTargetsEditIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/targets/mapping/$id/': {
      id: '/$accountId/platform/targets/mapping/$id/'
      path: '/targets/mapping/$id'
      fullPath: '/$accountId/platform/targets/mapping/$id'
      preLoaderRoute: typeof AccountIdPlatformTargetsMappingIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/create/': {
      id: '/$accountId/risk360/_externalProjectionsLayout/dashboards/create/'
      path: '/dashboards/create'
      fullPath: '/$accountId/risk360/dashboards/create'
      preLoaderRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyImport
      parentRoute: typeof AccountIdRisk360ExternalProjectionsLayoutImport
    }
    '/$accountId/risk360/factor-rules/audit/$id/': {
      id: '/$accountId/risk360/factor-rules/audit/$id/'
      path: '/factor-rules/audit/$id'
      fullPath: '/$accountId/risk360/factor-rules/audit/$id'
      preLoaderRoute: typeof AccountIdRisk360FactorRulesAuditIdIndexLazyImport
      parentRoute: typeof AccountIdRisk360Import
    }
    '/$accountId/risk360/factor-rules/edit/$id/': {
      id: '/$accountId/risk360/factor-rules/edit/$id/'
      path: '/factor-rules/edit/$id'
      fullPath: '/$accountId/risk360/factor-rules/edit/$id'
      preLoaderRoute: typeof AccountIdRisk360FactorRulesEditIdIndexLazyImport
      parentRoute: typeof AccountIdRisk360Import
    }
    '/$accountId/settings/all-accounts/audit/$id/': {
      id: '/$accountId/settings/all-accounts/audit/$id/'
      path: '/$accountId/settings/all-accounts/audit/$id'
      fullPath: '/$accountId/settings/all-accounts/audit/$id'
      preLoaderRoute: typeof AccountIdSettingsAllAccountsAuditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/all-accounts/edit/$id/': {
      id: '/$accountId/settings/all-accounts/edit/$id/'
      path: '/$accountId/settings/all-accounts/edit/$id'
      fullPath: '/$accountId/settings/all-accounts/edit/$id'
      preLoaderRoute: typeof AccountIdSettingsAllAccountsEditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/all-users/audit/$id/': {
      id: '/$accountId/settings/all-users/audit/$id/'
      path: '/$accountId/settings/all-users/audit/$id'
      fullPath: '/$accountId/settings/all-users/audit/$id'
      preLoaderRoute: typeof AccountIdSettingsAllUsersAuditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/all-users/edit/$id/': {
      id: '/$accountId/settings/all-users/edit/$id/'
      path: '/$accountId/settings/all-users/edit/$id'
      fullPath: '/$accountId/settings/all-users/edit/$id'
      preLoaderRoute: typeof AccountIdSettingsAllUsersEditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/global-factor-rules/audit/$id/': {
      id: '/$accountId/settings/global-factor-rules/audit/$id/'
      path: '/$accountId/settings/global-factor-rules/audit/$id'
      fullPath: '/$accountId/settings/global-factor-rules/audit/$id'
      preLoaderRoute: typeof AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/global-factor-rules/edit/$id/': {
      id: '/$accountId/settings/global-factor-rules/edit/$id/'
      path: '/$accountId/settings/global-factor-rules/edit/$id'
      fullPath: '/$accountId/settings/global-factor-rules/edit/$id'
      preLoaderRoute: typeof AccountIdSettingsGlobalFactorRulesEditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/permissions/content-permissions/create/': {
      id: '/$accountId/settings/permissions/content-permissions/create/'
      path: '/$accountId/settings/permissions/content-permissions/create'
      fullPath: '/$accountId/settings/permissions/content-permissions/create'
      preLoaderRoute: typeof AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/permissions/roles/create/': {
      id: '/$accountId/settings/permissions/roles/create/'
      path: '/$accountId/settings/permissions/roles/create'
      fullPath: '/$accountId/settings/permissions/roles/create'
      preLoaderRoute: typeof AccountIdSettingsPermissionsRolesCreateIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/user-management/audit/$id/': {
      id: '/$accountId/settings/user-management/audit/$id/'
      path: '/$accountId/settings/user-management/audit/$id'
      fullPath: '/$accountId/settings/user-management/audit/$id'
      preLoaderRoute: typeof AccountIdSettingsUserManagementAuditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/user-management/edit/$id/': {
      id: '/$accountId/settings/user-management/edit/$id/'
      path: '/$accountId/settings/user-management/edit/$id'
      fullPath: '/$accountId/settings/user-management/edit/$id'
      preLoaderRoute: typeof AccountIdSettingsUserManagementEditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/threats/_externalProjectionsLayout/dashboards/create/': {
      id: '/$accountId/threats/_externalProjectionsLayout/dashboards/create/'
      path: '/dashboards/create'
      fullPath: '/$accountId/threats/dashboards/create'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/threats/_externalProjectionsLayout/reports/create/': {
      id: '/$accountId/threats/_externalProjectionsLayout/reports/create/'
      path: '/reports/create'
      fullPath: '/$accountId/threats/reports/create'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/create/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/create/'
      path: '/dashboards/create'
      fullPath: '/$accountId/vulnerabilities/dashboards/create'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/create/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/create/'
      path: '/reports/create'
      fullPath: '/$accountId/vulnerabilities/reports/create'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/findings/runs/$id/': {
      id: '/$accountId/vulnerabilities/findings/runs/$id/'
      path: '/findings/runs/$id'
      fullPath: '/$accountId/vulnerabilities/findings/runs/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesFindingsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/score/audit/$path/': {
      id: '/$accountId/vulnerabilities/score/audit/$path/'
      path: '/score/audit/$path'
      fullPath: '/$accountId/vulnerabilities/score/audit/$path'
      preLoaderRoute: typeof AccountIdVulnerabilitiesScoreAuditPathIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/ui-config/audit/$path/': {
      id: '/$accountId/vulnerabilities/ui-config/audit/$path/'
      path: '/ui-config/audit/$path'
      fullPath: '/$accountId/vulnerabilities/ui-config/audit/$path'
      preLoaderRoute: typeof AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex/': {
      id: '/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex/'
      path: '/settings/rule-set/$id/$ruleIndex'
      fullPath: '/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex/': {
      id: '/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex/'
      path: '/settings/rule-set/create/$ruleIndex'
      fullPath: '/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/dashboards/audit/$id/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/dashboards/audit/$id/'
      path: '/dashboards/audit/$id'
      fullPath: '/$accountId/caasm/dashboards/audit/$id'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/dashboards/edit/$id/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/dashboards/edit/$id/'
      path: '/dashboards/edit/$id'
      fullPath: '/$accountId/caasm/dashboards/edit/$id'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/dashboards/runs/$id/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/dashboards/runs/$id/'
      path: '/dashboards/runs/$id'
      fullPath: '/$accountId/caasm/dashboards/runs/$id'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/reports/audit/$id/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/reports/audit/$id/'
      path: '/reports/audit/$id'
      fullPath: '/$accountId/caasm/reports/audit/$id'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/reports/edit/$id/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/reports/edit/$id/'
      path: '/reports/edit/$id'
      fullPath: '/$accountId/caasm/reports/edit/$id'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/caasm/_externalProjectionsLayout/reports/runs/$id/': {
      id: '/$accountId/caasm/_externalProjectionsLayout/reports/runs/$id/'
      path: '/reports/runs/$id'
      fullPath: '/$accountId/caasm/reports/runs/$id'
      preLoaderRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdCaasmExternalProjectionsLayoutImport
    }
    '/$accountId/detections/_externalProjectionsLayout/dashboards/audit/$id/': {
      id: '/$accountId/detections/_externalProjectionsLayout/dashboards/audit/$id/'
      path: '/dashboards/audit/$id'
      fullPath: '/$accountId/detections/dashboards/audit/$id'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/_externalProjectionsLayout/dashboards/edit/$id/': {
      id: '/$accountId/detections/_externalProjectionsLayout/dashboards/edit/$id/'
      path: '/dashboards/edit/$id'
      fullPath: '/$accountId/detections/dashboards/edit/$id'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/_externalProjectionsLayout/dashboards/runs/$id/': {
      id: '/$accountId/detections/_externalProjectionsLayout/dashboards/runs/$id/'
      path: '/dashboards/runs/$id'
      fullPath: '/$accountId/detections/dashboards/runs/$id'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/_externalProjectionsLayout/reports/audit/$id/': {
      id: '/$accountId/detections/_externalProjectionsLayout/reports/audit/$id/'
      path: '/reports/audit/$id'
      fullPath: '/$accountId/detections/reports/audit/$id'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/_externalProjectionsLayout/reports/edit/$id/': {
      id: '/$accountId/detections/_externalProjectionsLayout/reports/edit/$id/'
      path: '/reports/edit/$id'
      fullPath: '/$accountId/detections/reports/edit/$id'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/_externalProjectionsLayout/reports/runs/$id/': {
      id: '/$accountId/detections/_externalProjectionsLayout/reports/runs/$id/'
      path: '/reports/runs/$id'
      fullPath: '/$accountId/detections/reports/runs/$id'
      preLoaderRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsExternalProjectionsLayoutImport
    }
    '/$accountId/detections/settings/rule-set/$id/$ruleIndex/': {
      id: '/$accountId/detections/settings/rule-set/$id/$ruleIndex/'
      path: '/settings/rule-set/$id/$ruleIndex'
      fullPath: '/$accountId/detections/settings/rule-set/$id/$ruleIndex'
      preLoaderRoute: typeof AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/settings/rule-set/audit/$id/': {
      id: '/$accountId/detections/settings/rule-set/audit/$id/'
      path: '/settings/rule-set/audit/$id'
      fullPath: '/$accountId/detections/settings/rule-set/audit/$id'
      preLoaderRoute: typeof AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/detections/settings/rule-set/create/$ruleIndex/': {
      id: '/$accountId/detections/settings/rule-set/create/$ruleIndex/'
      path: '/settings/rule-set/create/$ruleIndex'
      fullPath: '/$accountId/detections/settings/rule-set/create/$ruleIndex'
      preLoaderRoute: typeof AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyImport
      parentRoute: typeof AccountIdDetectionsImport
    }
    '/$accountId/explore/_externalProjectionsLayout/dashboards/audit/$id/': {
      id: '/$accountId/explore/_externalProjectionsLayout/dashboards/audit/$id/'
      path: '/dashboards/audit/$id'
      fullPath: '/$accountId/explore/dashboards/audit/$id'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
    }
    '/$accountId/explore/_externalProjectionsLayout/dashboards/edit/$id/': {
      id: '/$accountId/explore/_externalProjectionsLayout/dashboards/edit/$id/'
      path: '/dashboards/edit/$id'
      fullPath: '/$accountId/explore/dashboards/edit/$id'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyImport
      parentRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
    }
    '/$accountId/explore/_externalProjectionsLayout/dashboards/runs/$id/': {
      id: '/$accountId/explore/_externalProjectionsLayout/dashboards/runs/$id/'
      path: '/dashboards/runs/$id'
      fullPath: '/$accountId/explore/dashboards/runs/$id'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
    }
    '/$accountId/explore/_externalProjectionsLayout/reports/edit/$id/': {
      id: '/$accountId/explore/_externalProjectionsLayout/reports/edit/$id/'
      path: '/reports/edit/$id'
      fullPath: '/$accountId/explore/reports/edit/$id'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyImport
      parentRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
    }
    '/$accountId/explore/_externalProjectionsLayout/reports/runs/$id/': {
      id: '/$accountId/explore/_externalProjectionsLayout/reports/runs/$id/'
      path: '/reports/runs/$id'
      fullPath: '/$accountId/explore/reports/runs/$id'
      preLoaderRoute: typeof AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdExploreExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/audit/$id/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/dashboards/audit/$id/'
      path: '/dashboards/audit/$id'
      fullPath: '/$accountId/incidents/dashboards/audit/$id'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/edit/$id/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/dashboards/edit/$id/'
      path: '/dashboards/edit/$id'
      fullPath: '/$accountId/incidents/dashboards/edit/$id'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/dashboards/runs/$id/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/dashboards/runs/$id/'
      path: '/dashboards/runs/$id'
      fullPath: '/$accountId/incidents/dashboards/runs/$id'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/reports/audit/$id/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/reports/audit/$id/'
      path: '/reports/audit/$id'
      fullPath: '/$accountId/incidents/reports/audit/$id'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/reports/edit/$id/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/reports/edit/$id/'
      path: '/reports/edit/$id'
      fullPath: '/$accountId/incidents/reports/edit/$id'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/_externalProjectionsLayout/reports/runs/$id/': {
      id: '/$accountId/incidents/_externalProjectionsLayout/reports/runs/$id/'
      path: '/reports/runs/$id'
      fullPath: '/$accountId/incidents/reports/runs/$id'
      preLoaderRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdIncidentsExternalProjectionsLayoutImport
    }
    '/$accountId/incidents/settings/rule-set/$id/$ruleIndex/': {
      id: '/$accountId/incidents/settings/rule-set/$id/$ruleIndex/'
      path: '/settings/rule-set/$id/$ruleIndex'
      fullPath: '/$accountId/incidents/settings/rule-set/$id/$ruleIndex'
      preLoaderRoute: typeof AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/incidents/settings/rule-set/audit/$id/': {
      id: '/$accountId/incidents/settings/rule-set/audit/$id/'
      path: '/settings/rule-set/audit/$id'
      fullPath: '/$accountId/incidents/settings/rule-set/audit/$id'
      preLoaderRoute: typeof AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/incidents/settings/rule-set/create/$ruleIndex/': {
      id: '/$accountId/incidents/settings/rule-set/create/$ruleIndex/'
      path: '/settings/rule-set/create/$ruleIndex'
      fullPath: '/$accountId/incidents/settings/rule-set/create/$ruleIndex'
      preLoaderRoute: typeof AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/incidents/settings/ui-config/audit/$path/': {
      id: '/$accountId/incidents/settings/ui-config/audit/$path/'
      path: '/settings/ui-config/audit/$path'
      fullPath: '/$accountId/incidents/settings/ui-config/audit/$path'
      preLoaderRoute: typeof AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyImport
      parentRoute: typeof AccountIdIncidentsImport
    }
    '/$accountId/platform/_externalProjectionsLayout/measurements/audit/$id/': {
      id: '/$accountId/platform/_externalProjectionsLayout/measurements/audit/$id/'
      path: '/measurements/audit/$id'
      fullPath: '/$accountId/platform/measurements/audit/$id'
      preLoaderRoute: typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformExternalProjectionsLayoutImport
    }
    '/$accountId/platform/_externalProjectionsLayout/measurements/edit/$systemName/': {
      id: '/$accountId/platform/_externalProjectionsLayout/measurements/edit/$systemName/'
      path: '/measurements/edit/$systemName'
      fullPath: '/$accountId/platform/measurements/edit/$systemName'
      preLoaderRoute: typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyImport
      parentRoute: typeof AccountIdPlatformExternalProjectionsLayoutImport
    }
    '/$accountId/platform/data-entities/data-entities/$projId/$ruleId/': {
      id: '/$accountId/platform/data-entities/data-entities/$projId/$ruleId/'
      path: '/data-entities/data-entities/$projId/$ruleId'
      fullPath: '/$accountId/platform/data-entities/data-entities/$projId/$ruleId'
      preLoaderRoute: typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/data-entities/fields/set/$fieldName/': {
      id: '/$accountId/platform/data-entities/fields/set/$fieldName/'
      path: '/data-entities/fields/set/$fieldName'
      fullPath: '/$accountId/platform/data-entities/fields/set/$fieldName'
      preLoaderRoute: typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/$id/$mappingPath/$type/': {
      id: '/$accountId/platform/sources/$id/$mappingPath/$type/'
      path: '/sources/$id/$mappingPath/$type'
      fullPath: '/$accountId/platform/sources/$id/$mappingPath/$type'
      preLoaderRoute: typeof AccountIdPlatformSourcesIdMappingPathTypeIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/create/$type/$previewId/': {
      id: '/$accountId/platform/sources/create/$type/$previewId/'
      path: '/sources/create/$type/$previewId'
      fullPath: '/$accountId/platform/sources/create/$type/$previewId'
      preLoaderRoute: typeof AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/edit/$id/$previewId/': {
      id: '/$accountId/platform/sources/edit/$id/$previewId/'
      path: '/sources/edit/$id/$previewId'
      fullPath: '/$accountId/platform/sources/edit/$id/$previewId'
      preLoaderRoute: typeof AccountIdPlatformSourcesEditIdPreviewIdIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/audit/$id/': {
      id: '/$accountId/risk360/_externalProjectionsLayout/dashboards/audit/$id/'
      path: '/dashboards/audit/$id'
      fullPath: '/$accountId/risk360/dashboards/audit/$id'
      preLoaderRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdRisk360ExternalProjectionsLayoutImport
    }
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/edit/$id/': {
      id: '/$accountId/risk360/_externalProjectionsLayout/dashboards/edit/$id/'
      path: '/dashboards/edit/$id'
      fullPath: '/$accountId/risk360/dashboards/edit/$id'
      preLoaderRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyImport
      parentRoute: typeof AccountIdRisk360ExternalProjectionsLayoutImport
    }
    '/$accountId/risk360/_externalProjectionsLayout/dashboards/runs/$id/': {
      id: '/$accountId/risk360/_externalProjectionsLayout/dashboards/runs/$id/'
      path: '/dashboards/runs/$id'
      fullPath: '/$accountId/risk360/dashboards/runs/$id'
      preLoaderRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdRisk360ExternalProjectionsLayoutImport
    }
    '/$accountId/settings/permissions/content-permissions/audit/$id/': {
      id: '/$accountId/settings/permissions/content-permissions/audit/$id/'
      path: '/$accountId/settings/permissions/content-permissions/audit/$id'
      fullPath: '/$accountId/settings/permissions/content-permissions/audit/$id'
      preLoaderRoute: typeof AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/permissions/content-permissions/edit/$id/': {
      id: '/$accountId/settings/permissions/content-permissions/edit/$id/'
      path: '/$accountId/settings/permissions/content-permissions/edit/$id'
      fullPath: '/$accountId/settings/permissions/content-permissions/edit/$id'
      preLoaderRoute: typeof AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/permissions/roles/audit/$id/': {
      id: '/$accountId/settings/permissions/roles/audit/$id/'
      path: '/$accountId/settings/permissions/roles/audit/$id'
      fullPath: '/$accountId/settings/permissions/roles/audit/$id'
      preLoaderRoute: typeof AccountIdSettingsPermissionsRolesAuditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/settings/permissions/roles/edit/$id/': {
      id: '/$accountId/settings/permissions/roles/edit/$id/'
      path: '/$accountId/settings/permissions/roles/edit/$id'
      fullPath: '/$accountId/settings/permissions/roles/edit/$id'
      preLoaderRoute: typeof AccountIdSettingsPermissionsRolesEditIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$accountId/threats/_externalProjectionsLayout/dashboards/audit/$id/': {
      id: '/$accountId/threats/_externalProjectionsLayout/dashboards/audit/$id/'
      path: '/dashboards/audit/$id'
      fullPath: '/$accountId/threats/dashboards/audit/$id'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/threats/_externalProjectionsLayout/dashboards/edit/$id/': {
      id: '/$accountId/threats/_externalProjectionsLayout/dashboards/edit/$id/'
      path: '/dashboards/edit/$id'
      fullPath: '/$accountId/threats/dashboards/edit/$id'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/threats/_externalProjectionsLayout/dashboards/runs/$id/': {
      id: '/$accountId/threats/_externalProjectionsLayout/dashboards/runs/$id/'
      path: '/dashboards/runs/$id'
      fullPath: '/$accountId/threats/dashboards/runs/$id'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/threats/_externalProjectionsLayout/reports/audit/$id/': {
      id: '/$accountId/threats/_externalProjectionsLayout/reports/audit/$id/'
      path: '/reports/audit/$id'
      fullPath: '/$accountId/threats/reports/audit/$id'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/threats/_externalProjectionsLayout/reports/edit/$id/': {
      id: '/$accountId/threats/_externalProjectionsLayout/reports/edit/$id/'
      path: '/reports/edit/$id'
      fullPath: '/$accountId/threats/reports/edit/$id'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/threats/_externalProjectionsLayout/reports/runs/$id/': {
      id: '/$accountId/threats/_externalProjectionsLayout/reports/runs/$id/'
      path: '/reports/runs/$id'
      fullPath: '/$accountId/threats/reports/runs/$id'
      preLoaderRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdThreatsExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/audit/$id/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/audit/$id/'
      path: '/dashboards/audit/$id'
      fullPath: '/$accountId/vulnerabilities/dashboards/audit/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/edit/$id/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/edit/$id/'
      path: '/dashboards/edit/$id'
      fullPath: '/$accountId/vulnerabilities/dashboards/edit/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/runs/$id/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/runs/$id/'
      path: '/dashboards/runs/$id'
      fullPath: '/$accountId/vulnerabilities/dashboards/runs/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/audit/$id/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/audit/$id/'
      path: '/reports/audit/$id'
      fullPath: '/$accountId/vulnerabilities/reports/audit/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/edit/$id/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/edit/$id/'
      path: '/reports/edit/$id'
      fullPath: '/$accountId/vulnerabilities/reports/edit/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/runs/$id/': {
      id: '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/runs/$id/'
      path: '/reports/runs/$id'
      fullPath: '/$accountId/vulnerabilities/reports/runs/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutImport
    }
    '/$accountId/vulnerabilities/settings/rule-set/audit/$id/': {
      id: '/$accountId/vulnerabilities/settings/rule-set/audit/$id/'
      path: '/settings/rule-set/audit/$id'
      fullPath: '/$accountId/vulnerabilities/settings/rule-set/audit/$id'
      preLoaderRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyImport
      parentRoute: typeof AccountIdVulnerabilitiesImport
    }
    '/$accountId/platform/data-entities/fields/set/$fieldName/create/': {
      id: '/$accountId/platform/data-entities/fields/set/$fieldName/create/'
      path: '/data-entities/fields/set/$fieldName/create'
      fullPath: '/$accountId/platform/data-entities/fields/set/$fieldName/create'
      preLoaderRoute: typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/$id/$mappingPath/$type/preview/': {
      id: '/$accountId/platform/sources/$id/$mappingPath/$type/preview/'
      path: '/sources/$id/$mappingPath/$type/preview'
      fullPath: '/$accountId/platform/sources/$id/$mappingPath/$type/preview'
      preLoaderRoute: typeof AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/sources/audit/$id/data-mapping/$type/': {
      id: '/$accountId/platform/sources/audit/$id/data-mapping/$type/'
      path: '/sources/audit/$id/data-mapping/$type'
      fullPath: '/$accountId/platform/sources/audit/$id/data-mapping/$type'
      preLoaderRoute: typeof AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/targets/audit/$id/mapping/$idMapping/': {
      id: '/$accountId/platform/targets/audit/$id/mapping/$idMapping/'
      path: '/targets/audit/$id/mapping/$idMapping'
      fullPath: '/$accountId/platform/targets/audit/$id/mapping/$idMapping'
      preLoaderRoute: typeof AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id/': {
      id: '/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id/'
      path: '/data-entities/fields/set/$fieldName/audit/$id'
      fullPath: '/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id'
      preLoaderRoute: typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexImport
      parentRoute: typeof AccountIdPlatformImport
    }
    '/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id/': {
      id: '/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id/'
      path: '/data-entities/fields/set/$fieldName/edit/$id'
      fullPath: '/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id'
      preLoaderRoute: typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexImport
      parentRoute: typeof AccountIdPlatformImport
    }
  }
}

// Create and export the route tree

interface AccountIdCaasmExternalProjectionsLayoutRouteChildren {
  AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyRoute
  AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyRoute
  AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyRoute
  AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyRoute: typeof AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
}

const AccountIdCaasmExternalProjectionsLayoutRouteChildren: AccountIdCaasmExternalProjectionsLayoutRouteChildren =
  {
    AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyRoute,
    AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyRoute,
    AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyRoute,
    AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyRoute,
    AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute,
    AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute,
    AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute,
    AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyRoute,
    AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyRoute,
    AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyRoute:
      AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyRoute,
  }

const AccountIdCaasmExternalProjectionsLayoutRouteWithChildren =
  AccountIdCaasmExternalProjectionsLayoutRoute._addFileChildren(
    AccountIdCaasmExternalProjectionsLayoutRouteChildren,
  )

interface AccountIdCaasmRouteChildren {
  AccountIdCaasmExternalProjectionsLayoutRoute: typeof AccountIdCaasmExternalProjectionsLayoutRouteWithChildren
  AccountIdCaasmIndexLazyRoute: typeof AccountIdCaasmIndexLazyRoute
  AccountIdCaasmAssetsIndexRoute: typeof AccountIdCaasmAssetsIndexRoute
  AccountIdCaasmPolicyViolationsIndexRoute: typeof AccountIdCaasmPolicyViolationsIndexRoute
  AccountIdCaasmAssetCoverageIndexLazyRoute: typeof AccountIdCaasmAssetCoverageIndexLazyRoute
  AccountIdCaasmAssetInventoryIndexLazyRoute: typeof AccountIdCaasmAssetInventoryIndexLazyRoute
  AccountIdCaasmPoliciesIndexLazyRoute: typeof AccountIdCaasmPoliciesIndexLazyRoute
  AccountIdCaasmPolicyComplianceIndexLazyRoute: typeof AccountIdCaasmPolicyComplianceIndexLazyRoute
  AccountIdCaasmSettingsIndexLazyRoute: typeof AccountIdCaasmSettingsIndexLazyRoute
  AccountIdCaasmToolCoverageAndGapIndexLazyRoute: typeof AccountIdCaasmToolCoverageAndGapIndexLazyRoute
  AccountIdCaasmAssetsIdIndexRoute: typeof AccountIdCaasmAssetsIdIndexRoute
  AccountIdCaasmPolicyViolationsIdIndexRoute: typeof AccountIdCaasmPolicyViolationsIdIndexRoute
  AccountIdCaasmAssetsVisualIndexLazyRoute: typeof AccountIdCaasmAssetsVisualIndexLazyRoute
  AccountIdCaasmPoliciesCreateIndexLazyRoute: typeof AccountIdCaasmPoliciesCreateIndexLazyRoute
  AccountIdCaasmPolicyViolationsVisualIndexLazyRoute: typeof AccountIdCaasmPolicyViolationsVisualIndexLazyRoute
  AccountIdCaasmSettingsUiConfigIndexLazyRoute: typeof AccountIdCaasmSettingsUiConfigIndexLazyRoute
  AccountIdCaasmPoliciesAuditIdIndexLazyRoute: typeof AccountIdCaasmPoliciesAuditIdIndexLazyRoute
  AccountIdCaasmPoliciesDuplicateIdIndexLazyRoute: typeof AccountIdCaasmPoliciesDuplicateIdIndexLazyRoute
  AccountIdCaasmPoliciesEditIdIndexLazyRoute: typeof AccountIdCaasmPoliciesEditIdIndexLazyRoute
  AccountIdCaasmPoliciesRunsIdIndexLazyRoute: typeof AccountIdCaasmPoliciesRunsIdIndexLazyRoute
  AccountIdCaasmUiConfigAuditPathIndexLazyRoute: typeof AccountIdCaasmUiConfigAuditPathIndexLazyRoute
}

const AccountIdCaasmRouteChildren: AccountIdCaasmRouteChildren = {
  AccountIdCaasmExternalProjectionsLayoutRoute:
    AccountIdCaasmExternalProjectionsLayoutRouteWithChildren,
  AccountIdCaasmIndexLazyRoute: AccountIdCaasmIndexLazyRoute,
  AccountIdCaasmAssetsIndexRoute: AccountIdCaasmAssetsIndexRoute,
  AccountIdCaasmPolicyViolationsIndexRoute:
    AccountIdCaasmPolicyViolationsIndexRoute,
  AccountIdCaasmAssetCoverageIndexLazyRoute:
    AccountIdCaasmAssetCoverageIndexLazyRoute,
  AccountIdCaasmAssetInventoryIndexLazyRoute:
    AccountIdCaasmAssetInventoryIndexLazyRoute,
  AccountIdCaasmPoliciesIndexLazyRoute: AccountIdCaasmPoliciesIndexLazyRoute,
  AccountIdCaasmPolicyComplianceIndexLazyRoute:
    AccountIdCaasmPolicyComplianceIndexLazyRoute,
  AccountIdCaasmSettingsIndexLazyRoute: AccountIdCaasmSettingsIndexLazyRoute,
  AccountIdCaasmToolCoverageAndGapIndexLazyRoute:
    AccountIdCaasmToolCoverageAndGapIndexLazyRoute,
  AccountIdCaasmAssetsIdIndexRoute: AccountIdCaasmAssetsIdIndexRoute,
  AccountIdCaasmPolicyViolationsIdIndexRoute:
    AccountIdCaasmPolicyViolationsIdIndexRoute,
  AccountIdCaasmAssetsVisualIndexLazyRoute:
    AccountIdCaasmAssetsVisualIndexLazyRoute,
  AccountIdCaasmPoliciesCreateIndexLazyRoute:
    AccountIdCaasmPoliciesCreateIndexLazyRoute,
  AccountIdCaasmPolicyViolationsVisualIndexLazyRoute:
    AccountIdCaasmPolicyViolationsVisualIndexLazyRoute,
  AccountIdCaasmSettingsUiConfigIndexLazyRoute:
    AccountIdCaasmSettingsUiConfigIndexLazyRoute,
  AccountIdCaasmPoliciesAuditIdIndexLazyRoute:
    AccountIdCaasmPoliciesAuditIdIndexLazyRoute,
  AccountIdCaasmPoliciesDuplicateIdIndexLazyRoute:
    AccountIdCaasmPoliciesDuplicateIdIndexLazyRoute,
  AccountIdCaasmPoliciesEditIdIndexLazyRoute:
    AccountIdCaasmPoliciesEditIdIndexLazyRoute,
  AccountIdCaasmPoliciesRunsIdIndexLazyRoute:
    AccountIdCaasmPoliciesRunsIdIndexLazyRoute,
  AccountIdCaasmUiConfigAuditPathIndexLazyRoute:
    AccountIdCaasmUiConfigAuditPathIndexLazyRoute,
}

const AccountIdCaasmRouteWithChildren = AccountIdCaasmRoute._addFileChildren(
  AccountIdCaasmRouteChildren,
)

interface AccountIdDetectionsExternalProjectionsLayoutRouteChildren {
  AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyRoute
  AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyRoute
  AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute: typeof AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
}

const AccountIdDetectionsExternalProjectionsLayoutRouteChildren: AccountIdDetectionsExternalProjectionsLayoutRouteChildren =
  {
    AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyRoute,
    AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyRoute,
    AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute,
    AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyRoute,
    AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute,
    AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute,
    AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute,
    AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute,
    AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyRoute,
    AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute:
      AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute,
  }

const AccountIdDetectionsExternalProjectionsLayoutRouteWithChildren =
  AccountIdDetectionsExternalProjectionsLayoutRoute._addFileChildren(
    AccountIdDetectionsExternalProjectionsLayoutRouteChildren,
  )

interface AccountIdDetectionsRouteChildren {
  AccountIdDetectionsExternalProjectionsLayoutRoute: typeof AccountIdDetectionsExternalProjectionsLayoutRouteWithChildren
  AccountIdDetectionsIndexLazyRoute: typeof AccountIdDetectionsIndexLazyRoute
  AccountIdDetectionsAlertsIndexRoute: typeof AccountIdDetectionsAlertsIndexRoute
  AccountIdDetectionsIncidentsIndexRoute: typeof AccountIdDetectionsIncidentsIndexRoute
  AccountIdDetectionsExploreIndexLazyRoute: typeof AccountIdDetectionsExploreIndexLazyRoute
  AccountIdDetectionsGlobalScriptsIndexLazyRoute: typeof AccountIdDetectionsGlobalScriptsIndexLazyRoute
  AccountIdDetectionsLookupTablesIndexLazyRoute: typeof AccountIdDetectionsLookupTablesIndexLazyRoute
  AccountIdDetectionsPivotIndexLazyRoute: typeof AccountIdDetectionsPivotIndexLazyRoute
  AccountIdDetectionsRulesIndexLazyRoute: typeof AccountIdDetectionsRulesIndexLazyRoute
  AccountIdDetectionsSettingsIndexLazyRoute: typeof AccountIdDetectionsSettingsIndexLazyRoute
  AccountIdDetectionsAlertsIdIndexRoute: typeof AccountIdDetectionsAlertsIdIndexRoute
  AccountIdDetectionsIncidentsIdIndexRoute: typeof AccountIdDetectionsIncidentsIdIndexRoute
  AccountIdDetectionsAlertsVisualIndexLazyRoute: typeof AccountIdDetectionsAlertsVisualIndexLazyRoute
  AccountIdDetectionsExploreIdIndexLazyRoute: typeof AccountIdDetectionsExploreIdIndexLazyRoute
  AccountIdDetectionsGlobalScriptsCreateIndexLazyRoute: typeof AccountIdDetectionsGlobalScriptsCreateIndexLazyRoute
  AccountIdDetectionsIncidentsVisualIndexLazyRoute: typeof AccountIdDetectionsIncidentsVisualIndexLazyRoute
  AccountIdDetectionsLookupTablesCreateIndexLazyRoute: typeof AccountIdDetectionsLookupTablesCreateIndexLazyRoute
  AccountIdDetectionsPivotIdIndexLazyRoute: typeof AccountIdDetectionsPivotIdIndexLazyRoute
  AccountIdDetectionsRulesCreateIndexLazyRoute: typeof AccountIdDetectionsRulesCreateIndexLazyRoute
  AccountIdDetectionsRulesLogsIndexLazyRoute: typeof AccountIdDetectionsRulesLogsIndexLazyRoute
  AccountIdDetectionsSettingsRuleSetIndexLazyRoute: typeof AccountIdDetectionsSettingsRuleSetIndexLazyRoute
  AccountIdDetectionsSettingsTicketsStatusesIndexLazyRoute: typeof AccountIdDetectionsSettingsTicketsStatusesIndexLazyRoute
  AccountIdDetectionsSettingsUiConfigIndexLazyRoute: typeof AccountIdDetectionsSettingsUiConfigIndexLazyRoute
  AccountIdDetectionsGlobalScriptsAuditIdIndexLazyRoute: typeof AccountIdDetectionsGlobalScriptsAuditIdIndexLazyRoute
  AccountIdDetectionsGlobalScriptsEditIdIndexLazyRoute: typeof AccountIdDetectionsGlobalScriptsEditIdIndexLazyRoute
  AccountIdDetectionsLookupTablesAuditIdIndexLazyRoute: typeof AccountIdDetectionsLookupTablesAuditIdIndexLazyRoute
  AccountIdDetectionsLookupTablesEditIdIndexLazyRoute: typeof AccountIdDetectionsLookupTablesEditIdIndexLazyRoute
  AccountIdDetectionsRulesAuditIdIndexLazyRoute: typeof AccountIdDetectionsRulesAuditIdIndexLazyRoute
  AccountIdDetectionsRulesEditIdIndexLazyRoute: typeof AccountIdDetectionsRulesEditIdIndexLazyRoute
  AccountIdDetectionsRulesRunsIdIndexLazyRoute: typeof AccountIdDetectionsRulesRunsIdIndexLazyRoute
  AccountIdDetectionsSettingsRuleSetIdIndexLazyRoute: typeof AccountIdDetectionsSettingsRuleSetIdIndexLazyRoute
  AccountIdDetectionsSettingsRuleSetCreateIndexLazyRoute: typeof AccountIdDetectionsSettingsRuleSetCreateIndexLazyRoute
  AccountIdDetectionsUiConfigAuditPathIndexLazyRoute: typeof AccountIdDetectionsUiConfigAuditPathIndexLazyRoute
  AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyRoute: typeof AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyRoute
  AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyRoute: typeof AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyRoute
  AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyRoute: typeof AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyRoute
}

const AccountIdDetectionsRouteChildren: AccountIdDetectionsRouteChildren = {
  AccountIdDetectionsExternalProjectionsLayoutRoute:
    AccountIdDetectionsExternalProjectionsLayoutRouteWithChildren,
  AccountIdDetectionsIndexLazyRoute: AccountIdDetectionsIndexLazyRoute,
  AccountIdDetectionsAlertsIndexRoute: AccountIdDetectionsAlertsIndexRoute,
  AccountIdDetectionsIncidentsIndexRoute:
    AccountIdDetectionsIncidentsIndexRoute,
  AccountIdDetectionsExploreIndexLazyRoute:
    AccountIdDetectionsExploreIndexLazyRoute,
  AccountIdDetectionsGlobalScriptsIndexLazyRoute:
    AccountIdDetectionsGlobalScriptsIndexLazyRoute,
  AccountIdDetectionsLookupTablesIndexLazyRoute:
    AccountIdDetectionsLookupTablesIndexLazyRoute,
  AccountIdDetectionsPivotIndexLazyRoute:
    AccountIdDetectionsPivotIndexLazyRoute,
  AccountIdDetectionsRulesIndexLazyRoute:
    AccountIdDetectionsRulesIndexLazyRoute,
  AccountIdDetectionsSettingsIndexLazyRoute:
    AccountIdDetectionsSettingsIndexLazyRoute,
  AccountIdDetectionsAlertsIdIndexRoute: AccountIdDetectionsAlertsIdIndexRoute,
  AccountIdDetectionsIncidentsIdIndexRoute:
    AccountIdDetectionsIncidentsIdIndexRoute,
  AccountIdDetectionsAlertsVisualIndexLazyRoute:
    AccountIdDetectionsAlertsVisualIndexLazyRoute,
  AccountIdDetectionsExploreIdIndexLazyRoute:
    AccountIdDetectionsExploreIdIndexLazyRoute,
  AccountIdDetectionsGlobalScriptsCreateIndexLazyRoute:
    AccountIdDetectionsGlobalScriptsCreateIndexLazyRoute,
  AccountIdDetectionsIncidentsVisualIndexLazyRoute:
    AccountIdDetectionsIncidentsVisualIndexLazyRoute,
  AccountIdDetectionsLookupTablesCreateIndexLazyRoute:
    AccountIdDetectionsLookupTablesCreateIndexLazyRoute,
  AccountIdDetectionsPivotIdIndexLazyRoute:
    AccountIdDetectionsPivotIdIndexLazyRoute,
  AccountIdDetectionsRulesCreateIndexLazyRoute:
    AccountIdDetectionsRulesCreateIndexLazyRoute,
  AccountIdDetectionsRulesLogsIndexLazyRoute:
    AccountIdDetectionsRulesLogsIndexLazyRoute,
  AccountIdDetectionsSettingsRuleSetIndexLazyRoute:
    AccountIdDetectionsSettingsRuleSetIndexLazyRoute,
  AccountIdDetectionsSettingsTicketsStatusesIndexLazyRoute:
    AccountIdDetectionsSettingsTicketsStatusesIndexLazyRoute,
  AccountIdDetectionsSettingsUiConfigIndexLazyRoute:
    AccountIdDetectionsSettingsUiConfigIndexLazyRoute,
  AccountIdDetectionsGlobalScriptsAuditIdIndexLazyRoute:
    AccountIdDetectionsGlobalScriptsAuditIdIndexLazyRoute,
  AccountIdDetectionsGlobalScriptsEditIdIndexLazyRoute:
    AccountIdDetectionsGlobalScriptsEditIdIndexLazyRoute,
  AccountIdDetectionsLookupTablesAuditIdIndexLazyRoute:
    AccountIdDetectionsLookupTablesAuditIdIndexLazyRoute,
  AccountIdDetectionsLookupTablesEditIdIndexLazyRoute:
    AccountIdDetectionsLookupTablesEditIdIndexLazyRoute,
  AccountIdDetectionsRulesAuditIdIndexLazyRoute:
    AccountIdDetectionsRulesAuditIdIndexLazyRoute,
  AccountIdDetectionsRulesEditIdIndexLazyRoute:
    AccountIdDetectionsRulesEditIdIndexLazyRoute,
  AccountIdDetectionsRulesRunsIdIndexLazyRoute:
    AccountIdDetectionsRulesRunsIdIndexLazyRoute,
  AccountIdDetectionsSettingsRuleSetIdIndexLazyRoute:
    AccountIdDetectionsSettingsRuleSetIdIndexLazyRoute,
  AccountIdDetectionsSettingsRuleSetCreateIndexLazyRoute:
    AccountIdDetectionsSettingsRuleSetCreateIndexLazyRoute,
  AccountIdDetectionsUiConfigAuditPathIndexLazyRoute:
    AccountIdDetectionsUiConfigAuditPathIndexLazyRoute,
  AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyRoute:
    AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyRoute,
  AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyRoute:
    AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyRoute,
  AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyRoute:
    AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyRoute,
}

const AccountIdDetectionsRouteWithChildren =
  AccountIdDetectionsRoute._addFileChildren(AccountIdDetectionsRouteChildren)

interface AccountIdExploreExternalProjectionsLayoutRouteChildren {
  AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyRoute
  AccountIdExploreExternalProjectionsLayoutReportsIndexLazyRoute: typeof AccountIdExploreExternalProjectionsLayoutReportsIndexLazyRoute
  AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyRoute: typeof AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyRoute
  AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute: typeof AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyRoute: typeof AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyRoute: typeof AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
}

const AccountIdExploreExternalProjectionsLayoutRouteChildren: AccountIdExploreExternalProjectionsLayoutRouteChildren =
  {
    AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyRoute:
      AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyRoute,
    AccountIdExploreExternalProjectionsLayoutReportsIndexLazyRoute:
      AccountIdExploreExternalProjectionsLayoutReportsIndexLazyRoute,
    AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyRoute:
      AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyRoute,
    AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyRoute:
      AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyRoute,
    AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute:
      AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute,
    AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute:
      AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute,
    AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute:
      AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute,
    AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyRoute:
      AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyRoute,
    AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyRoute:
      AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyRoute,
  }

const AccountIdExploreExternalProjectionsLayoutRouteWithChildren =
  AccountIdExploreExternalProjectionsLayoutRoute._addFileChildren(
    AccountIdExploreExternalProjectionsLayoutRouteChildren,
  )

interface AccountIdExploreRouteChildren {
  AccountIdExploreExternalProjectionsLayoutRoute: typeof AccountIdExploreExternalProjectionsLayoutRouteWithChildren
  AccountIdExploreIndexLazyRoute: typeof AccountIdExploreIndexLazyRoute
  AccountIdExploreEntitiesIndexLazyRoute: typeof AccountIdExploreEntitiesIndexLazyRoute
  AccountIdExploreLogsIndexLazyRoute: typeof AccountIdExploreLogsIndexLazyRoute
}

const AccountIdExploreRouteChildren: AccountIdExploreRouteChildren = {
  AccountIdExploreExternalProjectionsLayoutRoute:
    AccountIdExploreExternalProjectionsLayoutRouteWithChildren,
  AccountIdExploreIndexLazyRoute: AccountIdExploreIndexLazyRoute,
  AccountIdExploreEntitiesIndexLazyRoute:
    AccountIdExploreEntitiesIndexLazyRoute,
  AccountIdExploreLogsIndexLazyRoute: AccountIdExploreLogsIndexLazyRoute,
}

const AccountIdExploreRouteWithChildren =
  AccountIdExploreRoute._addFileChildren(AccountIdExploreRouteChildren)

interface AccountIdIncidentsExternalProjectionsLayoutRouteChildren {
  AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyRoute
  AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyRoute
  AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute: typeof AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
}

const AccountIdIncidentsExternalProjectionsLayoutRouteChildren: AccountIdIncidentsExternalProjectionsLayoutRouteChildren =
  {
    AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyRoute,
    AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyRoute,
    AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute,
    AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyRoute,
    AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute,
    AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute,
    AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute,
    AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute,
    AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyRoute,
    AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute:
      AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute,
  }

const AccountIdIncidentsExternalProjectionsLayoutRouteWithChildren =
  AccountIdIncidentsExternalProjectionsLayoutRoute._addFileChildren(
    AccountIdIncidentsExternalProjectionsLayoutRouteChildren,
  )

interface AccountIdIncidentsRouteChildren {
  AccountIdIncidentsExternalProjectionsLayoutRoute: typeof AccountIdIncidentsExternalProjectionsLayoutRouteWithChildren
  AccountIdIncidentsIndexLazyRoute: typeof AccountIdIncidentsIndexLazyRoute
  AccountIdIncidentsIncidentsIndexRoute: typeof AccountIdIncidentsIncidentsIndexRoute
  AccountIdIncidentsPivotIndexLazyRoute: typeof AccountIdIncidentsPivotIndexLazyRoute
  AccountIdIncidentsSettingsIndexLazyRoute: typeof AccountIdIncidentsSettingsIndexLazyRoute
  AccountIdIncidentsIncidentsIdIndexRoute: typeof AccountIdIncidentsIncidentsIdIndexRoute
  AccountIdIncidentsIncidentsVisualIndexLazyRoute: typeof AccountIdIncidentsIncidentsVisualIndexLazyRoute
  AccountIdIncidentsPivotIdIndexLazyRoute: typeof AccountIdIncidentsPivotIdIndexLazyRoute
  AccountIdIncidentsSettingsRuleSetIndexLazyRoute: typeof AccountIdIncidentsSettingsRuleSetIndexLazyRoute
  AccountIdIncidentsSettingsTicketsStatusesIndexLazyRoute: typeof AccountIdIncidentsSettingsTicketsStatusesIndexLazyRoute
  AccountIdIncidentsSettingsUiConfigIndexLazyRoute: typeof AccountIdIncidentsSettingsUiConfigIndexLazyRoute
  AccountIdIncidentsSettingsRuleSetIdIndexLazyRoute: typeof AccountIdIncidentsSettingsRuleSetIdIndexLazyRoute
  AccountIdIncidentsSettingsRuleSetCreateIndexLazyRoute: typeof AccountIdIncidentsSettingsRuleSetCreateIndexLazyRoute
  AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyRoute: typeof AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyRoute
  AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyRoute: typeof AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyRoute
  AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyRoute: typeof AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyRoute
  AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyRoute: typeof AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyRoute
}

const AccountIdIncidentsRouteChildren: AccountIdIncidentsRouteChildren = {
  AccountIdIncidentsExternalProjectionsLayoutRoute:
    AccountIdIncidentsExternalProjectionsLayoutRouteWithChildren,
  AccountIdIncidentsIndexLazyRoute: AccountIdIncidentsIndexLazyRoute,
  AccountIdIncidentsIncidentsIndexRoute: AccountIdIncidentsIncidentsIndexRoute,
  AccountIdIncidentsPivotIndexLazyRoute: AccountIdIncidentsPivotIndexLazyRoute,
  AccountIdIncidentsSettingsIndexLazyRoute:
    AccountIdIncidentsSettingsIndexLazyRoute,
  AccountIdIncidentsIncidentsIdIndexRoute:
    AccountIdIncidentsIncidentsIdIndexRoute,
  AccountIdIncidentsIncidentsVisualIndexLazyRoute:
    AccountIdIncidentsIncidentsVisualIndexLazyRoute,
  AccountIdIncidentsPivotIdIndexLazyRoute:
    AccountIdIncidentsPivotIdIndexLazyRoute,
  AccountIdIncidentsSettingsRuleSetIndexLazyRoute:
    AccountIdIncidentsSettingsRuleSetIndexLazyRoute,
  AccountIdIncidentsSettingsTicketsStatusesIndexLazyRoute:
    AccountIdIncidentsSettingsTicketsStatusesIndexLazyRoute,
  AccountIdIncidentsSettingsUiConfigIndexLazyRoute:
    AccountIdIncidentsSettingsUiConfigIndexLazyRoute,
  AccountIdIncidentsSettingsRuleSetIdIndexLazyRoute:
    AccountIdIncidentsSettingsRuleSetIdIndexLazyRoute,
  AccountIdIncidentsSettingsRuleSetCreateIndexLazyRoute:
    AccountIdIncidentsSettingsRuleSetCreateIndexLazyRoute,
  AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyRoute:
    AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyRoute,
  AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyRoute:
    AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyRoute,
  AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyRoute:
    AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyRoute,
  AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyRoute:
    AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyRoute,
}

const AccountIdIncidentsRouteWithChildren =
  AccountIdIncidentsRoute._addFileChildren(AccountIdIncidentsRouteChildren)

interface AccountIdPlatformExternalProjectionsLayoutRouteChildren {
  AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyRoute: typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyRoute
  AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyRoute: typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyRoute
  AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyRoute: typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyRoute
  AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyRoute: typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyRoute
}

const AccountIdPlatformExternalProjectionsLayoutRouteChildren: AccountIdPlatformExternalProjectionsLayoutRouteChildren =
  {
    AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyRoute:
      AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyRoute,
    AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyRoute:
      AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyRoute,
    AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyRoute:
      AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyRoute,
    AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyRoute:
      AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyRoute,
  }

const AccountIdPlatformExternalProjectionsLayoutRouteWithChildren =
  AccountIdPlatformExternalProjectionsLayoutRoute._addFileChildren(
    AccountIdPlatformExternalProjectionsLayoutRouteChildren,
  )

interface AccountIdPlatformRouteChildren {
  AccountIdPlatformExternalProjectionsLayoutRoute: typeof AccountIdPlatformExternalProjectionsLayoutRouteWithChildren
  AccountIdPlatformAliasesIndexLazyRoute: typeof AccountIdPlatformAliasesIndexLazyRoute
  AccountIdPlatformAssetAgingIndexLazyRoute: typeof AccountIdPlatformAssetAgingIndexLazyRoute
  AccountIdPlatformAuthenticationSourcesIndexLazyRoute: typeof AccountIdPlatformAuthenticationSourcesIndexLazyRoute
  AccountIdPlatformDataPurgeIndexLazyRoute: typeof AccountIdPlatformDataPurgeIndexLazyRoute
  AccountIdPlatformFormattingRulesIndexLazyRoute: typeof AccountIdPlatformFormattingRulesIndexLazyRoute
  AccountIdPlatformModelManagementIndexLazyRoute: typeof AccountIdPlatformModelManagementIndexLazyRoute
  AccountIdPlatformRelationsIndexLazyRoute: typeof AccountIdPlatformRelationsIndexLazyRoute
  AccountIdPlatformSourcesIndexLazyRoute: typeof AccountIdPlatformSourcesIndexLazyRoute
  AccountIdPlatformTargetsIndexLazyRoute: typeof AccountIdPlatformTargetsIndexLazyRoute
  AccountIdPlatformWorkflowsIndexLazyRoute: typeof AccountIdPlatformWorkflowsIndexLazyRoute
  AccountIdPlatformAssetAgingCreateIndexLazyRoute: typeof AccountIdPlatformAssetAgingCreateIndexLazyRoute
  AccountIdPlatformDataEntitiesDataEntitiesIndexLazyRoute: typeof AccountIdPlatformDataEntitiesDataEntitiesIndexLazyRoute
  AccountIdPlatformDataEntitiesFieldsIndexLazyRoute: typeof AccountIdPlatformDataEntitiesFieldsIndexLazyRoute
  AccountIdPlatformDataPurgeProjIdIndexLazyRoute: typeof AccountIdPlatformDataPurgeProjIdIndexLazyRoute
  AccountIdPlatformDataPurgeRunsIndexLazyRoute: typeof AccountIdPlatformDataPurgeRunsIndexLazyRoute
  AccountIdPlatformFormattingRulesCreateIndexLazyRoute: typeof AccountIdPlatformFormattingRulesCreateIndexLazyRoute
  AccountIdPlatformModelManagementIdIndexLazyRoute: typeof AccountIdPlatformModelManagementIdIndexLazyRoute
  AccountIdPlatformModelManagementRunsIndexLazyRoute: typeof AccountIdPlatformModelManagementRunsIndexLazyRoute
  AccountIdPlatformRelationsRelationIdIndexLazyRoute: typeof AccountIdPlatformRelationsRelationIdIndexLazyRoute
  AccountIdPlatformSourcesCreateIndexLazyRoute: typeof AccountIdPlatformSourcesCreateIndexLazyRoute
  AccountIdPlatformTargetsCreateIndexLazyRoute: typeof AccountIdPlatformTargetsCreateIndexLazyRoute
  AccountIdPlatformAssetAgingEditIdIndexLazyRoute: typeof AccountIdPlatformAssetAgingEditIdIndexLazyRoute
  AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyRoute: typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyRoute
  AccountIdPlatformFormattingRulesEditIdIndexLazyRoute: typeof AccountIdPlatformFormattingRulesEditIdIndexLazyRoute
  AccountIdPlatformModelManagementIdPreviewIndexLazyRoute: typeof AccountIdPlatformModelManagementIdPreviewIndexLazyRoute
  AccountIdPlatformModelManagementAuditIdIndexLazyRoute: typeof AccountIdPlatformModelManagementAuditIdIndexLazyRoute
  AccountIdPlatformSourcesAuditIdIndexLazyRoute: typeof AccountIdPlatformSourcesAuditIdIndexLazyRoute
  AccountIdPlatformSourcesCreateTypeIndexLazyRoute: typeof AccountIdPlatformSourcesCreateTypeIndexLazyRoute
  AccountIdPlatformSourcesEditIdIndexLazyRoute: typeof AccountIdPlatformSourcesEditIdIndexLazyRoute
  AccountIdPlatformSourcesRunsIdIndexLazyRoute: typeof AccountIdPlatformSourcesRunsIdIndexLazyRoute
  AccountIdPlatformTargetsActivityLogIdIndexLazyRoute: typeof AccountIdPlatformTargetsActivityLogIdIndexLazyRoute
  AccountIdPlatformTargetsAuditIdIndexLazyRoute: typeof AccountIdPlatformTargetsAuditIdIndexLazyRoute
  AccountIdPlatformTargetsCreateTypeIndexLazyRoute: typeof AccountIdPlatformTargetsCreateTypeIndexLazyRoute
  AccountIdPlatformTargetsEditIdIndexLazyRoute: typeof AccountIdPlatformTargetsEditIdIndexLazyRoute
  AccountIdPlatformTargetsMappingIdIndexLazyRoute: typeof AccountIdPlatformTargetsMappingIdIndexLazyRoute
  AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyRoute: typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyRoute
  AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyRoute: typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyRoute
  AccountIdPlatformSourcesIdMappingPathTypeIndexLazyRoute: typeof AccountIdPlatformSourcesIdMappingPathTypeIndexLazyRoute
  AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyRoute: typeof AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyRoute
  AccountIdPlatformSourcesEditIdPreviewIdIndexLazyRoute: typeof AccountIdPlatformSourcesEditIdPreviewIdIndexLazyRoute
  AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexRoute: typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexRoute
  AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyRoute: typeof AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyRoute
  AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyRoute: typeof AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyRoute
  AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyRoute: typeof AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyRoute
  AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexRoute: typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexRoute
  AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexRoute: typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexRoute
}

const AccountIdPlatformRouteChildren: AccountIdPlatformRouteChildren = {
  AccountIdPlatformExternalProjectionsLayoutRoute:
    AccountIdPlatformExternalProjectionsLayoutRouteWithChildren,
  AccountIdPlatformAliasesIndexLazyRoute:
    AccountIdPlatformAliasesIndexLazyRoute,
  AccountIdPlatformAssetAgingIndexLazyRoute:
    AccountIdPlatformAssetAgingIndexLazyRoute,
  AccountIdPlatformAuthenticationSourcesIndexLazyRoute:
    AccountIdPlatformAuthenticationSourcesIndexLazyRoute,
  AccountIdPlatformDataPurgeIndexLazyRoute:
    AccountIdPlatformDataPurgeIndexLazyRoute,
  AccountIdPlatformFormattingRulesIndexLazyRoute:
    AccountIdPlatformFormattingRulesIndexLazyRoute,
  AccountIdPlatformModelManagementIndexLazyRoute:
    AccountIdPlatformModelManagementIndexLazyRoute,
  AccountIdPlatformRelationsIndexLazyRoute:
    AccountIdPlatformRelationsIndexLazyRoute,
  AccountIdPlatformSourcesIndexLazyRoute:
    AccountIdPlatformSourcesIndexLazyRoute,
  AccountIdPlatformTargetsIndexLazyRoute:
    AccountIdPlatformTargetsIndexLazyRoute,
  AccountIdPlatformWorkflowsIndexLazyRoute:
    AccountIdPlatformWorkflowsIndexLazyRoute,
  AccountIdPlatformAssetAgingCreateIndexLazyRoute:
    AccountIdPlatformAssetAgingCreateIndexLazyRoute,
  AccountIdPlatformDataEntitiesDataEntitiesIndexLazyRoute:
    AccountIdPlatformDataEntitiesDataEntitiesIndexLazyRoute,
  AccountIdPlatformDataEntitiesFieldsIndexLazyRoute:
    AccountIdPlatformDataEntitiesFieldsIndexLazyRoute,
  AccountIdPlatformDataPurgeProjIdIndexLazyRoute:
    AccountIdPlatformDataPurgeProjIdIndexLazyRoute,
  AccountIdPlatformDataPurgeRunsIndexLazyRoute:
    AccountIdPlatformDataPurgeRunsIndexLazyRoute,
  AccountIdPlatformFormattingRulesCreateIndexLazyRoute:
    AccountIdPlatformFormattingRulesCreateIndexLazyRoute,
  AccountIdPlatformModelManagementIdIndexLazyRoute:
    AccountIdPlatformModelManagementIdIndexLazyRoute,
  AccountIdPlatformModelManagementRunsIndexLazyRoute:
    AccountIdPlatformModelManagementRunsIndexLazyRoute,
  AccountIdPlatformRelationsRelationIdIndexLazyRoute:
    AccountIdPlatformRelationsRelationIdIndexLazyRoute,
  AccountIdPlatformSourcesCreateIndexLazyRoute:
    AccountIdPlatformSourcesCreateIndexLazyRoute,
  AccountIdPlatformTargetsCreateIndexLazyRoute:
    AccountIdPlatformTargetsCreateIndexLazyRoute,
  AccountIdPlatformAssetAgingEditIdIndexLazyRoute:
    AccountIdPlatformAssetAgingEditIdIndexLazyRoute,
  AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyRoute:
    AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyRoute,
  AccountIdPlatformFormattingRulesEditIdIndexLazyRoute:
    AccountIdPlatformFormattingRulesEditIdIndexLazyRoute,
  AccountIdPlatformModelManagementIdPreviewIndexLazyRoute:
    AccountIdPlatformModelManagementIdPreviewIndexLazyRoute,
  AccountIdPlatformModelManagementAuditIdIndexLazyRoute:
    AccountIdPlatformModelManagementAuditIdIndexLazyRoute,
  AccountIdPlatformSourcesAuditIdIndexLazyRoute:
    AccountIdPlatformSourcesAuditIdIndexLazyRoute,
  AccountIdPlatformSourcesCreateTypeIndexLazyRoute:
    AccountIdPlatformSourcesCreateTypeIndexLazyRoute,
  AccountIdPlatformSourcesEditIdIndexLazyRoute:
    AccountIdPlatformSourcesEditIdIndexLazyRoute,
  AccountIdPlatformSourcesRunsIdIndexLazyRoute:
    AccountIdPlatformSourcesRunsIdIndexLazyRoute,
  AccountIdPlatformTargetsActivityLogIdIndexLazyRoute:
    AccountIdPlatformTargetsActivityLogIdIndexLazyRoute,
  AccountIdPlatformTargetsAuditIdIndexLazyRoute:
    AccountIdPlatformTargetsAuditIdIndexLazyRoute,
  AccountIdPlatformTargetsCreateTypeIndexLazyRoute:
    AccountIdPlatformTargetsCreateTypeIndexLazyRoute,
  AccountIdPlatformTargetsEditIdIndexLazyRoute:
    AccountIdPlatformTargetsEditIdIndexLazyRoute,
  AccountIdPlatformTargetsMappingIdIndexLazyRoute:
    AccountIdPlatformTargetsMappingIdIndexLazyRoute,
  AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyRoute:
    AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyRoute,
  AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyRoute:
    AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyRoute,
  AccountIdPlatformSourcesIdMappingPathTypeIndexLazyRoute:
    AccountIdPlatformSourcesIdMappingPathTypeIndexLazyRoute,
  AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyRoute:
    AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyRoute,
  AccountIdPlatformSourcesEditIdPreviewIdIndexLazyRoute:
    AccountIdPlatformSourcesEditIdPreviewIdIndexLazyRoute,
  AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexRoute:
    AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexRoute,
  AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyRoute:
    AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyRoute,
  AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyRoute:
    AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyRoute,
  AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyRoute:
    AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyRoute,
  AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexRoute:
    AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexRoute,
  AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexRoute:
    AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexRoute,
}

const AccountIdPlatformRouteWithChildren =
  AccountIdPlatformRoute._addFileChildren(AccountIdPlatformRouteChildren)

interface AccountIdRisk360ExternalProjectionsLayoutRouteChildren {
  AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyRoute
  AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute: typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
}

const AccountIdRisk360ExternalProjectionsLayoutRouteChildren: AccountIdRisk360ExternalProjectionsLayoutRouteChildren =
  {
    AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyRoute:
      AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyRoute,
    AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyRoute:
      AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyRoute,
    AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute:
      AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute,
    AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute:
      AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute,
    AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute:
      AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute,
  }

const AccountIdRisk360ExternalProjectionsLayoutRouteWithChildren =
  AccountIdRisk360ExternalProjectionsLayoutRoute._addFileChildren(
    AccountIdRisk360ExternalProjectionsLayoutRouteChildren,
  )

interface AccountIdRisk360RouteChildren {
  AccountIdRisk360ExternalProjectionsLayoutRoute: typeof AccountIdRisk360ExternalProjectionsLayoutRouteWithChildren
  AccountIdRisk360IndexLazyRoute: typeof AccountIdRisk360IndexLazyRoute
  AccountIdRisk360FactorsIndexRoute: typeof AccountIdRisk360FactorsIndexRoute
  AccountIdRisk360FactorRulesIndexLazyRoute: typeof AccountIdRisk360FactorRulesIndexLazyRoute
  AccountIdRisk360FactorsIdIndexRoute: typeof AccountIdRisk360FactorsIdIndexRoute
  AccountIdRisk360FactorRulesCreateIndexLazyRoute: typeof AccountIdRisk360FactorRulesCreateIndexLazyRoute
  AccountIdRisk360FactorRulesRunsIndexLazyRoute: typeof AccountIdRisk360FactorRulesRunsIndexLazyRoute
  AccountIdRisk360FactorsVisualIndexLazyRoute: typeof AccountIdRisk360FactorsVisualIndexLazyRoute
  AccountIdRisk360FactorRulesAuditIdIndexLazyRoute: typeof AccountIdRisk360FactorRulesAuditIdIndexLazyRoute
  AccountIdRisk360FactorRulesEditIdIndexLazyRoute: typeof AccountIdRisk360FactorRulesEditIdIndexLazyRoute
}

const AccountIdRisk360RouteChildren: AccountIdRisk360RouteChildren = {
  AccountIdRisk360ExternalProjectionsLayoutRoute:
    AccountIdRisk360ExternalProjectionsLayoutRouteWithChildren,
  AccountIdRisk360IndexLazyRoute: AccountIdRisk360IndexLazyRoute,
  AccountIdRisk360FactorsIndexRoute: AccountIdRisk360FactorsIndexRoute,
  AccountIdRisk360FactorRulesIndexLazyRoute:
    AccountIdRisk360FactorRulesIndexLazyRoute,
  AccountIdRisk360FactorsIdIndexRoute: AccountIdRisk360FactorsIdIndexRoute,
  AccountIdRisk360FactorRulesCreateIndexLazyRoute:
    AccountIdRisk360FactorRulesCreateIndexLazyRoute,
  AccountIdRisk360FactorRulesRunsIndexLazyRoute:
    AccountIdRisk360FactorRulesRunsIndexLazyRoute,
  AccountIdRisk360FactorsVisualIndexLazyRoute:
    AccountIdRisk360FactorsVisualIndexLazyRoute,
  AccountIdRisk360FactorRulesAuditIdIndexLazyRoute:
    AccountIdRisk360FactorRulesAuditIdIndexLazyRoute,
  AccountIdRisk360FactorRulesEditIdIndexLazyRoute:
    AccountIdRisk360FactorRulesEditIdIndexLazyRoute,
}

const AccountIdRisk360RouteWithChildren =
  AccountIdRisk360Route._addFileChildren(AccountIdRisk360RouteChildren)

interface AccountIdThreatsExternalProjectionsLayoutRouteChildren {
  AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyRoute
  AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyRoute
  AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute: typeof AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
}

const AccountIdThreatsExternalProjectionsLayoutRouteChildren: AccountIdThreatsExternalProjectionsLayoutRouteChildren =
  {
    AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyRoute,
    AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyRoute,
    AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute,
    AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyRoute,
    AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute,
    AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute,
    AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute,
    AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute,
    AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyRoute,
    AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute:
      AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute,
  }

const AccountIdThreatsExternalProjectionsLayoutRouteWithChildren =
  AccountIdThreatsExternalProjectionsLayoutRoute._addFileChildren(
    AccountIdThreatsExternalProjectionsLayoutRouteChildren,
  )

interface AccountIdThreatsRouteChildren {
  AccountIdThreatsExternalProjectionsLayoutRoute: typeof AccountIdThreatsExternalProjectionsLayoutRouteWithChildren
  AccountIdThreatsAlertsIndexRoute: typeof AccountIdThreatsAlertsIndexRoute
  AccountIdThreatsIncidentsIndexRoute: typeof AccountIdThreatsIncidentsIndexRoute
  AccountIdThreatsAlertsIdIndexRoute: typeof AccountIdThreatsAlertsIdIndexRoute
  AccountIdThreatsIncidentsIdIndexRoute: typeof AccountIdThreatsIncidentsIdIndexRoute
  AccountIdThreatsAlertsVisualIndexLazyRoute: typeof AccountIdThreatsAlertsVisualIndexLazyRoute
  AccountIdThreatsIncidentsVisualIndexLazyRoute: typeof AccountIdThreatsIncidentsVisualIndexLazyRoute
}

const AccountIdThreatsRouteChildren: AccountIdThreatsRouteChildren = {
  AccountIdThreatsExternalProjectionsLayoutRoute:
    AccountIdThreatsExternalProjectionsLayoutRouteWithChildren,
  AccountIdThreatsAlertsIndexRoute: AccountIdThreatsAlertsIndexRoute,
  AccountIdThreatsIncidentsIndexRoute: AccountIdThreatsIncidentsIndexRoute,
  AccountIdThreatsAlertsIdIndexRoute: AccountIdThreatsAlertsIdIndexRoute,
  AccountIdThreatsIncidentsIdIndexRoute: AccountIdThreatsIncidentsIdIndexRoute,
  AccountIdThreatsAlertsVisualIndexLazyRoute:
    AccountIdThreatsAlertsVisualIndexLazyRoute,
  AccountIdThreatsIncidentsVisualIndexLazyRoute:
    AccountIdThreatsIncidentsVisualIndexLazyRoute,
}

const AccountIdThreatsRouteWithChildren =
  AccountIdThreatsRoute._addFileChildren(AccountIdThreatsRouteChildren)

interface AccountIdVulnerabilitiesExternalProjectionsLayoutRouteChildren {
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyRoute
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyRoute
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyRoute
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
}

const AccountIdVulnerabilitiesExternalProjectionsLayoutRouteChildren: AccountIdVulnerabilitiesExternalProjectionsLayoutRouteChildren =
  {
    AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyRoute,
    AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyRoute,
    AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyRoute,
    AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyRoute,
    AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute,
    AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute,
    AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute,
    AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyRoute,
    AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyRoute,
    AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyRoute,
  }

const AccountIdVulnerabilitiesExternalProjectionsLayoutRouteWithChildren =
  AccountIdVulnerabilitiesExternalProjectionsLayoutRoute._addFileChildren(
    AccountIdVulnerabilitiesExternalProjectionsLayoutRouteChildren,
  )

interface AccountIdVulnerabilitiesRouteChildren {
  AccountIdVulnerabilitiesExternalProjectionsLayoutRoute: typeof AccountIdVulnerabilitiesExternalProjectionsLayoutRouteWithChildren
  AccountIdVulnerabilitiesIndexLazyRoute: typeof AccountIdVulnerabilitiesIndexLazyRoute
  AccountIdVulnerabilitiesAssetsIndexRoute: typeof AccountIdVulnerabilitiesAssetsIndexRoute
  AccountIdVulnerabilitiesExceptionsIndexRoute: typeof AccountIdVulnerabilitiesExceptionsIndexRoute
  AccountIdVulnerabilitiesFindingsIndexRoute: typeof AccountIdVulnerabilitiesFindingsIndexRoute
  AccountIdVulnerabilitiesTicketsIndexRoute: typeof AccountIdVulnerabilitiesTicketsIndexRoute
  AccountIdVulnerabilitiesAssetCoverageIndexLazyRoute: typeof AccountIdVulnerabilitiesAssetCoverageIndexLazyRoute
  AccountIdVulnerabilitiesExploreIndexLazyRoute: typeof AccountIdVulnerabilitiesExploreIndexLazyRoute
  AccountIdVulnerabilitiesOverviewIndexLazyRoute: typeof AccountIdVulnerabilitiesOverviewIndexLazyRoute
  AccountIdVulnerabilitiesPivotIndexLazyRoute: typeof AccountIdVulnerabilitiesPivotIndexLazyRoute
  AccountIdVulnerabilitiesRemediationIndexLazyRoute: typeof AccountIdVulnerabilitiesRemediationIndexLazyRoute
  AccountIdVulnerabilitiesRiskIndexLazyRoute: typeof AccountIdVulnerabilitiesRiskIndexLazyRoute
  AccountIdVulnerabilitiesRoiIndexLazyRoute: typeof AccountIdVulnerabilitiesRoiIndexLazyRoute
  AccountIdVulnerabilitiesSettingsIndexLazyRoute: typeof AccountIdVulnerabilitiesSettingsIndexLazyRoute
  AccountIdVulnerabilitiesAssetsIdIndexRoute: typeof AccountIdVulnerabilitiesAssetsIdIndexRoute
  AccountIdVulnerabilitiesExceptionsIdIndexRoute: typeof AccountIdVulnerabilitiesExceptionsIdIndexRoute
  AccountIdVulnerabilitiesFindingsIdIndexRoute: typeof AccountIdVulnerabilitiesFindingsIdIndexRoute
  AccountIdVulnerabilitiesTicketsIdIndexRoute: typeof AccountIdVulnerabilitiesTicketsIdIndexRoute
  AccountIdVulnerabilitiesAssetsVisualIndexLazyRoute: typeof AccountIdVulnerabilitiesAssetsVisualIndexLazyRoute
  AccountIdVulnerabilitiesExceptionsVisualIndexLazyRoute: typeof AccountIdVulnerabilitiesExceptionsVisualIndexLazyRoute
  AccountIdVulnerabilitiesFindingsVisualIndexLazyRoute: typeof AccountIdVulnerabilitiesFindingsVisualIndexLazyRoute
  AccountIdVulnerabilitiesPivotIdIndexLazyRoute: typeof AccountIdVulnerabilitiesPivotIdIndexLazyRoute
  AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyRoute: typeof AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyRoute
  AccountIdVulnerabilitiesSettingsRuleSetIndexLazyRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetIndexLazyRoute
  AccountIdVulnerabilitiesSettingsScoreIndexLazyRoute: typeof AccountIdVulnerabilitiesSettingsScoreIndexLazyRoute
  AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyRoute: typeof AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyRoute
  AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyRoute: typeof AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyRoute
  AccountIdVulnerabilitiesSettingsUiConfigIndexLazyRoute: typeof AccountIdVulnerabilitiesSettingsUiConfigIndexLazyRoute
  AccountIdVulnerabilitiesTicketsVisualIndexLazyRoute: typeof AccountIdVulnerabilitiesTicketsVisualIndexLazyRoute
  AccountIdVulnerabilitiesSettingsRuleSetIdIndexRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetIdIndexRoute
  AccountIdVulnerabilitiesSettingsRuleSetCreateIndexRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetCreateIndexRoute
  AccountIdVulnerabilitiesFindingsRunsIdIndexLazyRoute: typeof AccountIdVulnerabilitiesFindingsRunsIdIndexLazyRoute
  AccountIdVulnerabilitiesScoreAuditPathIndexLazyRoute: typeof AccountIdVulnerabilitiesScoreAuditPathIndexLazyRoute
  AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyRoute: typeof AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyRoute
  AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexRoute
  AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexRoute
  AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyRoute: typeof AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyRoute
}

const AccountIdVulnerabilitiesRouteChildren: AccountIdVulnerabilitiesRouteChildren =
  {
    AccountIdVulnerabilitiesExternalProjectionsLayoutRoute:
      AccountIdVulnerabilitiesExternalProjectionsLayoutRouteWithChildren,
    AccountIdVulnerabilitiesIndexLazyRoute:
      AccountIdVulnerabilitiesIndexLazyRoute,
    AccountIdVulnerabilitiesAssetsIndexRoute:
      AccountIdVulnerabilitiesAssetsIndexRoute,
    AccountIdVulnerabilitiesExceptionsIndexRoute:
      AccountIdVulnerabilitiesExceptionsIndexRoute,
    AccountIdVulnerabilitiesFindingsIndexRoute:
      AccountIdVulnerabilitiesFindingsIndexRoute,
    AccountIdVulnerabilitiesTicketsIndexRoute:
      AccountIdVulnerabilitiesTicketsIndexRoute,
    AccountIdVulnerabilitiesAssetCoverageIndexLazyRoute:
      AccountIdVulnerabilitiesAssetCoverageIndexLazyRoute,
    AccountIdVulnerabilitiesExploreIndexLazyRoute:
      AccountIdVulnerabilitiesExploreIndexLazyRoute,
    AccountIdVulnerabilitiesOverviewIndexLazyRoute:
      AccountIdVulnerabilitiesOverviewIndexLazyRoute,
    AccountIdVulnerabilitiesPivotIndexLazyRoute:
      AccountIdVulnerabilitiesPivotIndexLazyRoute,
    AccountIdVulnerabilitiesRemediationIndexLazyRoute:
      AccountIdVulnerabilitiesRemediationIndexLazyRoute,
    AccountIdVulnerabilitiesRiskIndexLazyRoute:
      AccountIdVulnerabilitiesRiskIndexLazyRoute,
    AccountIdVulnerabilitiesRoiIndexLazyRoute:
      AccountIdVulnerabilitiesRoiIndexLazyRoute,
    AccountIdVulnerabilitiesSettingsIndexLazyRoute:
      AccountIdVulnerabilitiesSettingsIndexLazyRoute,
    AccountIdVulnerabilitiesAssetsIdIndexRoute:
      AccountIdVulnerabilitiesAssetsIdIndexRoute,
    AccountIdVulnerabilitiesExceptionsIdIndexRoute:
      AccountIdVulnerabilitiesExceptionsIdIndexRoute,
    AccountIdVulnerabilitiesFindingsIdIndexRoute:
      AccountIdVulnerabilitiesFindingsIdIndexRoute,
    AccountIdVulnerabilitiesTicketsIdIndexRoute:
      AccountIdVulnerabilitiesTicketsIdIndexRoute,
    AccountIdVulnerabilitiesAssetsVisualIndexLazyRoute:
      AccountIdVulnerabilitiesAssetsVisualIndexLazyRoute,
    AccountIdVulnerabilitiesExceptionsVisualIndexLazyRoute:
      AccountIdVulnerabilitiesExceptionsVisualIndexLazyRoute,
    AccountIdVulnerabilitiesFindingsVisualIndexLazyRoute:
      AccountIdVulnerabilitiesFindingsVisualIndexLazyRoute,
    AccountIdVulnerabilitiesPivotIdIndexLazyRoute:
      AccountIdVulnerabilitiesPivotIdIndexLazyRoute,
    AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyRoute:
      AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyRoute,
    AccountIdVulnerabilitiesSettingsRuleSetIndexLazyRoute:
      AccountIdVulnerabilitiesSettingsRuleSetIndexLazyRoute,
    AccountIdVulnerabilitiesSettingsScoreIndexLazyRoute:
      AccountIdVulnerabilitiesSettingsScoreIndexLazyRoute,
    AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyRoute:
      AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyRoute,
    AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyRoute:
      AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyRoute,
    AccountIdVulnerabilitiesSettingsUiConfigIndexLazyRoute:
      AccountIdVulnerabilitiesSettingsUiConfigIndexLazyRoute,
    AccountIdVulnerabilitiesTicketsVisualIndexLazyRoute:
      AccountIdVulnerabilitiesTicketsVisualIndexLazyRoute,
    AccountIdVulnerabilitiesSettingsRuleSetIdIndexRoute:
      AccountIdVulnerabilitiesSettingsRuleSetIdIndexRoute,
    AccountIdVulnerabilitiesSettingsRuleSetCreateIndexRoute:
      AccountIdVulnerabilitiesSettingsRuleSetCreateIndexRoute,
    AccountIdVulnerabilitiesFindingsRunsIdIndexLazyRoute:
      AccountIdVulnerabilitiesFindingsRunsIdIndexLazyRoute,
    AccountIdVulnerabilitiesScoreAuditPathIndexLazyRoute:
      AccountIdVulnerabilitiesScoreAuditPathIndexLazyRoute,
    AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyRoute:
      AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyRoute,
    AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexRoute:
      AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexRoute,
    AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexRoute:
      AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexRoute,
    AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyRoute:
      AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyRoute,
  }

const AccountIdVulnerabilitiesRouteWithChildren =
  AccountIdVulnerabilitiesRoute._addFileChildren(
    AccountIdVulnerabilitiesRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/error-process-request': typeof ErrorProcessRequestRoute
  '/$accountId': typeof AccountIdIndexLazyRoute
  '/$accountId/caasm': typeof AccountIdCaasmExternalProjectionsLayoutRouteWithChildren
  '/$accountId/detections': typeof AccountIdDetectionsExternalProjectionsLayoutRouteWithChildren
  '/$accountId/explore': typeof AccountIdExploreExternalProjectionsLayoutRouteWithChildren
  '/$accountId/incidents': typeof AccountIdIncidentsExternalProjectionsLayoutRouteWithChildren
  '/$accountId/platform': typeof AccountIdPlatformExternalProjectionsLayoutRouteWithChildren
  '/$accountId/risk360': typeof AccountIdRisk360ExternalProjectionsLayoutRouteWithChildren
  '/$accountId/threats': typeof AccountIdThreatsExternalProjectionsLayoutRouteWithChildren
  '/$accountId/vulnerabilities': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutRouteWithChildren
  '/$accountId/tickets': typeof AccountIdTicketsIndexRoute
  '/$accountId/caasm/': typeof AccountIdCaasmIndexLazyRoute
  '/$accountId/detections/': typeof AccountIdDetectionsIndexLazyRoute
  '/$accountId/explore/': typeof AccountIdExploreIndexLazyRoute
  '/$accountId/incidents/': typeof AccountIdIncidentsIndexLazyRoute
  '/$accountId/risk360/': typeof AccountIdRisk360IndexLazyRoute
  '/$accountId/vulnerabilities/': typeof AccountIdVulnerabilitiesIndexLazyRoute
  '/$accountId/caasm/assets': typeof AccountIdCaasmAssetsIndexRoute
  '/$accountId/caasm/policy-violations': typeof AccountIdCaasmPolicyViolationsIndexRoute
  '/$accountId/detections/alerts': typeof AccountIdDetectionsAlertsIndexRoute
  '/$accountId/detections/incidents': typeof AccountIdDetectionsIncidentsIndexRoute
  '/$accountId/incidents/incidents': typeof AccountIdIncidentsIncidentsIndexRoute
  '/$accountId/risk360/factors': typeof AccountIdRisk360FactorsIndexRoute
  '/$accountId/threats/alerts': typeof AccountIdThreatsAlertsIndexRoute
  '/$accountId/threats/incidents': typeof AccountIdThreatsIncidentsIndexRoute
  '/$accountId/tickets/$id': typeof AccountIdTicketsIdIndexRoute
  '/$accountId/vulnerabilities/assets': typeof AccountIdVulnerabilitiesAssetsIndexRoute
  '/$accountId/vulnerabilities/exceptions': typeof AccountIdVulnerabilitiesExceptionsIndexRoute
  '/$accountId/vulnerabilities/findings': typeof AccountIdVulnerabilitiesFindingsIndexRoute
  '/$accountId/vulnerabilities/tickets': typeof AccountIdVulnerabilitiesTicketsIndexRoute
  '/$accountId/caasm/asset-coverage': typeof AccountIdCaasmAssetCoverageIndexLazyRoute
  '/$accountId/caasm/asset-inventory': typeof AccountIdCaasmAssetInventoryIndexLazyRoute
  '/$accountId/caasm/policies': typeof AccountIdCaasmPoliciesIndexLazyRoute
  '/$accountId/caasm/policy-compliance': typeof AccountIdCaasmPolicyComplianceIndexLazyRoute
  '/$accountId/caasm/settings': typeof AccountIdCaasmSettingsIndexLazyRoute
  '/$accountId/caasm/tool-coverage-and-gap': typeof AccountIdCaasmToolCoverageAndGapIndexLazyRoute
  '/$accountId/detections/explore': typeof AccountIdDetectionsExploreIndexLazyRoute
  '/$accountId/detections/global-scripts': typeof AccountIdDetectionsGlobalScriptsIndexLazyRoute
  '/$accountId/detections/lookup-tables': typeof AccountIdDetectionsLookupTablesIndexLazyRoute
  '/$accountId/detections/pivot': typeof AccountIdDetectionsPivotIndexLazyRoute
  '/$accountId/detections/rules': typeof AccountIdDetectionsRulesIndexLazyRoute
  '/$accountId/detections/settings': typeof AccountIdDetectionsSettingsIndexLazyRoute
  '/$accountId/explore/entities': typeof AccountIdExploreEntitiesIndexLazyRoute
  '/$accountId/explore/logs': typeof AccountIdExploreLogsIndexLazyRoute
  '/$accountId/incidents/pivot': typeof AccountIdIncidentsPivotIndexLazyRoute
  '/$accountId/incidents/settings': typeof AccountIdIncidentsSettingsIndexLazyRoute
  '/$accountId/platform/aliases': typeof AccountIdPlatformAliasesIndexLazyRoute
  '/$accountId/platform/asset-aging': typeof AccountIdPlatformAssetAgingIndexLazyRoute
  '/$accountId/platform/authentication-sources': typeof AccountIdPlatformAuthenticationSourcesIndexLazyRoute
  '/$accountId/platform/data-purge': typeof AccountIdPlatformDataPurgeIndexLazyRoute
  '/$accountId/platform/formatting-rules': typeof AccountIdPlatformFormattingRulesIndexLazyRoute
  '/$accountId/platform/model-management': typeof AccountIdPlatformModelManagementIndexLazyRoute
  '/$accountId/platform/relations': typeof AccountIdPlatformRelationsIndexLazyRoute
  '/$accountId/platform/sources': typeof AccountIdPlatformSourcesIndexLazyRoute
  '/$accountId/platform/targets': typeof AccountIdPlatformTargetsIndexLazyRoute
  '/$accountId/platform/workflows': typeof AccountIdPlatformWorkflowsIndexLazyRoute
  '/$accountId/risk360/factor-rules': typeof AccountIdRisk360FactorRulesIndexLazyRoute
  '/$accountId/settings/account-settings': typeof AccountIdSettingsAccountSettingsIndexLazyRoute
  '/$accountId/settings/account-usage': typeof AccountIdSettingsAccountUsageIndexLazyRoute
  '/$accountId/settings/all-accounts': typeof AccountIdSettingsAllAccountsIndexLazyRoute
  '/$accountId/settings/all-users': typeof AccountIdSettingsAllUsersIndexLazyRoute
  '/$accountId/settings/backoffice-actions': typeof AccountIdSettingsBackofficeActionsIndexLazyRoute
  '/$accountId/settings/global-factor-rules': typeof AccountIdSettingsGlobalFactorRulesIndexLazyRoute
  '/$accountId/settings/icons': typeof AccountIdSettingsIconsIndexLazyRoute
  '/$accountId/settings/profile-settings': typeof AccountIdSettingsProfileSettingsIndexLazyRoute
  '/$accountId/settings/query': typeof AccountIdSettingsQueryIndexLazyRoute
  '/$accountId/settings/risk360-backoffice-actions': typeof AccountIdSettingsRisk360BackofficeActionsIndexLazyRoute
  '/$accountId/settings/user-management': typeof AccountIdSettingsUserManagementIndexLazyRoute
  '/$accountId/vulnerabilities/asset-coverage': typeof AccountIdVulnerabilitiesAssetCoverageIndexLazyRoute
  '/$accountId/vulnerabilities/explore': typeof AccountIdVulnerabilitiesExploreIndexLazyRoute
  '/$accountId/vulnerabilities/overview': typeof AccountIdVulnerabilitiesOverviewIndexLazyRoute
  '/$accountId/vulnerabilities/pivot': typeof AccountIdVulnerabilitiesPivotIndexLazyRoute
  '/$accountId/vulnerabilities/remediation': typeof AccountIdVulnerabilitiesRemediationIndexLazyRoute
  '/$accountId/vulnerabilities/risk': typeof AccountIdVulnerabilitiesRiskIndexLazyRoute
  '/$accountId/vulnerabilities/roi': typeof AccountIdVulnerabilitiesRoiIndexLazyRoute
  '/$accountId/vulnerabilities/settings': typeof AccountIdVulnerabilitiesSettingsIndexLazyRoute
  '/$accountId/caasm/assets/$id': typeof AccountIdCaasmAssetsIdIndexRoute
  '/$accountId/caasm/policy-violations/$id': typeof AccountIdCaasmPolicyViolationsIdIndexRoute
  '/$accountId/detections/alerts/$id': typeof AccountIdDetectionsAlertsIdIndexRoute
  '/$accountId/detections/incidents/$id': typeof AccountIdDetectionsIncidentsIdIndexRoute
  '/$accountId/incidents/incidents/$id': typeof AccountIdIncidentsIncidentsIdIndexRoute
  '/$accountId/risk360/factors/$id': typeof AccountIdRisk360FactorsIdIndexRoute
  '/$accountId/threats/alerts/$id': typeof AccountIdThreatsAlertsIdIndexRoute
  '/$accountId/threats/incidents/$id': typeof AccountIdThreatsIncidentsIdIndexRoute
  '/$accountId/vulnerabilities/assets/$id': typeof AccountIdVulnerabilitiesAssetsIdIndexRoute
  '/$accountId/vulnerabilities/exceptions/$id': typeof AccountIdVulnerabilitiesExceptionsIdIndexRoute
  '/$accountId/vulnerabilities/findings/$id': typeof AccountIdVulnerabilitiesFindingsIdIndexRoute
  '/$accountId/vulnerabilities/tickets/$id': typeof AccountIdVulnerabilitiesTicketsIdIndexRoute
  '/$accountId/caasm/dashboards': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/caasm/reports': typeof AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/caasm/assets/visual': typeof AccountIdCaasmAssetsVisualIndexLazyRoute
  '/$accountId/caasm/policies/create': typeof AccountIdCaasmPoliciesCreateIndexLazyRoute
  '/$accountId/caasm/policy-violations/visual': typeof AccountIdCaasmPolicyViolationsVisualIndexLazyRoute
  '/$accountId/caasm/settings/ui-config': typeof AccountIdCaasmSettingsUiConfigIndexLazyRoute
  '/$accountId/detections/dashboards': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/detections/reports': typeof AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/detections/alerts/visual': typeof AccountIdDetectionsAlertsVisualIndexLazyRoute
  '/$accountId/detections/explore/$id': typeof AccountIdDetectionsExploreIdIndexLazyRoute
  '/$accountId/detections/global-scripts/create': typeof AccountIdDetectionsGlobalScriptsCreateIndexLazyRoute
  '/$accountId/detections/incidents/visual': typeof AccountIdDetectionsIncidentsVisualIndexLazyRoute
  '/$accountId/detections/lookup-tables/create': typeof AccountIdDetectionsLookupTablesCreateIndexLazyRoute
  '/$accountId/detections/pivot/$id': typeof AccountIdDetectionsPivotIdIndexLazyRoute
  '/$accountId/detections/rules/create': typeof AccountIdDetectionsRulesCreateIndexLazyRoute
  '/$accountId/detections/rules/logs': typeof AccountIdDetectionsRulesLogsIndexLazyRoute
  '/$accountId/detections/settings/rule-set': typeof AccountIdDetectionsSettingsRuleSetIndexLazyRoute
  '/$accountId/detections/settings/tickets-statuses': typeof AccountIdDetectionsSettingsTicketsStatusesIndexLazyRoute
  '/$accountId/detections/settings/ui-config': typeof AccountIdDetectionsSettingsUiConfigIndexLazyRoute
  '/$accountId/explore/dashboards': typeof AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/explore/reports': typeof AccountIdExploreExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/incidents/dashboards': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/incidents/reports': typeof AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/incidents/incidents/visual': typeof AccountIdIncidentsIncidentsVisualIndexLazyRoute
  '/$accountId/incidents/pivot/$id': typeof AccountIdIncidentsPivotIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set': typeof AccountIdIncidentsSettingsRuleSetIndexLazyRoute
  '/$accountId/incidents/settings/tickets-statuses': typeof AccountIdIncidentsSettingsTicketsStatusesIndexLazyRoute
  '/$accountId/incidents/settings/ui-config': typeof AccountIdIncidentsSettingsUiConfigIndexLazyRoute
  '/$accountId/platform/measurements': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyRoute
  '/$accountId/platform/asset-aging/create': typeof AccountIdPlatformAssetAgingCreateIndexLazyRoute
  '/$accountId/platform/data-entities/data-entities': typeof AccountIdPlatformDataEntitiesDataEntitiesIndexLazyRoute
  '/$accountId/platform/data-entities/fields': typeof AccountIdPlatformDataEntitiesFieldsIndexLazyRoute
  '/$accountId/platform/data-purge/$projId': typeof AccountIdPlatformDataPurgeProjIdIndexLazyRoute
  '/$accountId/platform/data-purge/runs': typeof AccountIdPlatformDataPurgeRunsIndexLazyRoute
  '/$accountId/platform/formatting-rules/create': typeof AccountIdPlatformFormattingRulesCreateIndexLazyRoute
  '/$accountId/platform/model-management/$id': typeof AccountIdPlatformModelManagementIdIndexLazyRoute
  '/$accountId/platform/model-management/runs': typeof AccountIdPlatformModelManagementRunsIndexLazyRoute
  '/$accountId/platform/relations/$relationId': typeof AccountIdPlatformRelationsRelationIdIndexLazyRoute
  '/$accountId/platform/sources/create': typeof AccountIdPlatformSourcesCreateIndexLazyRoute
  '/$accountId/platform/targets/create': typeof AccountIdPlatformTargetsCreateIndexLazyRoute
  '/$accountId/risk360/dashboards': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/risk360/factor-rules/create': typeof AccountIdRisk360FactorRulesCreateIndexLazyRoute
  '/$accountId/risk360/factor-rules/runs': typeof AccountIdRisk360FactorRulesRunsIndexLazyRoute
  '/$accountId/risk360/factors/visual': typeof AccountIdRisk360FactorsVisualIndexLazyRoute
  '/$accountId/settings/all-accounts/create': typeof AccountIdSettingsAllAccountsCreateIndexLazyRoute
  '/$accountId/settings/all-users/create': typeof AccountIdSettingsAllUsersCreateIndexLazyRoute
  '/$accountId/settings/global-factor-rules/create': typeof AccountIdSettingsGlobalFactorRulesCreateIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions': typeof AccountIdSettingsPermissionsContentPermissionsIndexLazyRoute
  '/$accountId/settings/permissions/roles': typeof AccountIdSettingsPermissionsRolesIndexLazyRoute
  '/$accountId/settings/user-management/create': typeof AccountIdSettingsUserManagementCreateIndexLazyRoute
  '/$accountId/threats/dashboards': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/threats/reports': typeof AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/threats/alerts/visual': typeof AccountIdThreatsAlertsVisualIndexLazyRoute
  '/$accountId/threats/incidents/visual': typeof AccountIdThreatsIncidentsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/vulnerabilities/reports': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/vulnerabilities/assets/visual': typeof AccountIdVulnerabilitiesAssetsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/exceptions/visual': typeof AccountIdVulnerabilitiesExceptionsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/findings/visual': typeof AccountIdVulnerabilitiesFindingsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/pivot/$id': typeof AccountIdVulnerabilitiesPivotIdIndexLazyRoute
  '/$accountId/vulnerabilities/settings/exceptions-settings': typeof AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set': typeof AccountIdVulnerabilitiesSettingsRuleSetIndexLazyRoute
  '/$accountId/vulnerabilities/settings/score': typeof AccountIdVulnerabilitiesSettingsScoreIndexLazyRoute
  '/$accountId/vulnerabilities/settings/severity-sla': typeof AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyRoute
  '/$accountId/vulnerabilities/settings/tickets-statuses': typeof AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyRoute
  '/$accountId/vulnerabilities/settings/ui-config': typeof AccountIdVulnerabilitiesSettingsUiConfigIndexLazyRoute
  '/$accountId/vulnerabilities/tickets/visual': typeof AccountIdVulnerabilitiesTicketsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/$id': typeof AccountIdVulnerabilitiesSettingsRuleSetIdIndexRoute
  '/$accountId/vulnerabilities/settings/rule-set/create': typeof AccountIdVulnerabilitiesSettingsRuleSetCreateIndexRoute
  '/$accountId/caasm/dashboards/create': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/caasm/reports/create': typeof AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/caasm/policies/audit/$id': typeof AccountIdCaasmPoliciesAuditIdIndexLazyRoute
  '/$accountId/caasm/policies/duplicate/$id': typeof AccountIdCaasmPoliciesDuplicateIdIndexLazyRoute
  '/$accountId/caasm/policies/edit/$id': typeof AccountIdCaasmPoliciesEditIdIndexLazyRoute
  '/$accountId/caasm/policies/runs/$id': typeof AccountIdCaasmPoliciesRunsIdIndexLazyRoute
  '/$accountId/caasm/ui-config/audit/$path': typeof AccountIdCaasmUiConfigAuditPathIndexLazyRoute
  '/$accountId/detections/dashboards/create': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/detections/reports/create': typeof AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/detections/global-scripts/audit/$id': typeof AccountIdDetectionsGlobalScriptsAuditIdIndexLazyRoute
  '/$accountId/detections/global-scripts/edit/$id': typeof AccountIdDetectionsGlobalScriptsEditIdIndexLazyRoute
  '/$accountId/detections/lookup-tables/audit/$id': typeof AccountIdDetectionsLookupTablesAuditIdIndexLazyRoute
  '/$accountId/detections/lookup-tables/edit/$id': typeof AccountIdDetectionsLookupTablesEditIdIndexLazyRoute
  '/$accountId/detections/rules/audit/$id': typeof AccountIdDetectionsRulesAuditIdIndexLazyRoute
  '/$accountId/detections/rules/edit/$id': typeof AccountIdDetectionsRulesEditIdIndexLazyRoute
  '/$accountId/detections/rules/runs/$id': typeof AccountIdDetectionsRulesRunsIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/$id': typeof AccountIdDetectionsSettingsRuleSetIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/create': typeof AccountIdDetectionsSettingsRuleSetCreateIndexLazyRoute
  '/$accountId/detections/ui-config/audit/$path': typeof AccountIdDetectionsUiConfigAuditPathIndexLazyRoute
  '/$accountId/explore/dashboards/create': typeof AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/explore/reports/create': typeof AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/incidents/dashboards/create': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/incidents/reports/create': typeof AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/$id': typeof AccountIdIncidentsSettingsRuleSetIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/create': typeof AccountIdIncidentsSettingsRuleSetCreateIndexLazyRoute
  '/$accountId/platform/measurements/create': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyRoute
  '/$accountId/platform/asset-aging/edit/$id': typeof AccountIdPlatformAssetAgingEditIdIndexLazyRoute
  '/$accountId/platform/data-entities/data-entities/$projId': typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyRoute
  '/$accountId/platform/formatting-rules/edit/$id': typeof AccountIdPlatformFormattingRulesEditIdIndexLazyRoute
  '/$accountId/platform/model-management/$id/preview': typeof AccountIdPlatformModelManagementIdPreviewIndexLazyRoute
  '/$accountId/platform/model-management/audit/$id': typeof AccountIdPlatformModelManagementAuditIdIndexLazyRoute
  '/$accountId/platform/sources/audit/$id': typeof AccountIdPlatformSourcesAuditIdIndexLazyRoute
  '/$accountId/platform/sources/create/$type': typeof AccountIdPlatformSourcesCreateTypeIndexLazyRoute
  '/$accountId/platform/sources/edit/$id': typeof AccountIdPlatformSourcesEditIdIndexLazyRoute
  '/$accountId/platform/sources/runs/$id': typeof AccountIdPlatformSourcesRunsIdIndexLazyRoute
  '/$accountId/platform/targets/activityLog/$id': typeof AccountIdPlatformTargetsActivityLogIdIndexLazyRoute
  '/$accountId/platform/targets/audit/$id': typeof AccountIdPlatformTargetsAuditIdIndexLazyRoute
  '/$accountId/platform/targets/create/$type': typeof AccountIdPlatformTargetsCreateTypeIndexLazyRoute
  '/$accountId/platform/targets/edit/$id': typeof AccountIdPlatformTargetsEditIdIndexLazyRoute
  '/$accountId/platform/targets/mapping/$id': typeof AccountIdPlatformTargetsMappingIdIndexLazyRoute
  '/$accountId/risk360/dashboards/create': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/risk360/factor-rules/audit/$id': typeof AccountIdRisk360FactorRulesAuditIdIndexLazyRoute
  '/$accountId/risk360/factor-rules/edit/$id': typeof AccountIdRisk360FactorRulesEditIdIndexLazyRoute
  '/$accountId/settings/all-accounts/audit/$id': typeof AccountIdSettingsAllAccountsAuditIdIndexLazyRoute
  '/$accountId/settings/all-accounts/edit/$id': typeof AccountIdSettingsAllAccountsEditIdIndexLazyRoute
  '/$accountId/settings/all-users/audit/$id': typeof AccountIdSettingsAllUsersAuditIdIndexLazyRoute
  '/$accountId/settings/all-users/edit/$id': typeof AccountIdSettingsAllUsersEditIdIndexLazyRoute
  '/$accountId/settings/global-factor-rules/audit/$id': typeof AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyRoute
  '/$accountId/settings/global-factor-rules/edit/$id': typeof AccountIdSettingsGlobalFactorRulesEditIdIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/create': typeof AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyRoute
  '/$accountId/settings/permissions/roles/create': typeof AccountIdSettingsPermissionsRolesCreateIndexLazyRoute
  '/$accountId/settings/user-management/audit/$id': typeof AccountIdSettingsUserManagementAuditIdIndexLazyRoute
  '/$accountId/settings/user-management/edit/$id': typeof AccountIdSettingsUserManagementEditIdIndexLazyRoute
  '/$accountId/threats/dashboards/create': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/threats/reports/create': typeof AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards/create': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/vulnerabilities/reports/create': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/vulnerabilities/findings/runs/$id': typeof AccountIdVulnerabilitiesFindingsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/score/audit/$path': typeof AccountIdVulnerabilitiesScoreAuditPathIndexLazyRoute
  '/$accountId/vulnerabilities/ui-config/audit/$path': typeof AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex': typeof AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexRoute
  '/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex': typeof AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexRoute
  '/$accountId/caasm/dashboards/audit/$id': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/caasm/dashboards/edit/$id': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/caasm/dashboards/runs/$id': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/caasm/reports/audit/$id': typeof AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/caasm/reports/edit/$id': typeof AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/caasm/reports/runs/$id': typeof AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/detections/dashboards/audit/$id': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/detections/dashboards/edit/$id': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/detections/dashboards/runs/$id': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/detections/reports/audit/$id': typeof AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/detections/reports/edit/$id': typeof AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/detections/reports/runs/$id': typeof AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/$id/$ruleIndex': typeof AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyRoute
  '/$accountId/detections/settings/rule-set/audit/$id': typeof AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/create/$ruleIndex': typeof AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyRoute
  '/$accountId/explore/dashboards/audit/$id': typeof AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/explore/dashboards/edit/$id': typeof AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/explore/dashboards/runs/$id': typeof AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/explore/reports/edit/$id': typeof AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/explore/reports/runs/$id': typeof AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/incidents/dashboards/audit/$id': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/incidents/dashboards/edit/$id': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/incidents/dashboards/runs/$id': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/incidents/reports/audit/$id': typeof AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/incidents/reports/edit/$id': typeof AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/incidents/reports/runs/$id': typeof AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/$id/$ruleIndex': typeof AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/audit/$id': typeof AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/create/$ruleIndex': typeof AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyRoute
  '/$accountId/incidents/settings/ui-config/audit/$path': typeof AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyRoute
  '/$accountId/platform/measurements/audit/$id': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyRoute
  '/$accountId/platform/measurements/edit/$systemName': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyRoute
  '/$accountId/platform/data-entities/data-entities/$projId/$ruleId': typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyRoute
  '/$accountId/platform/sources/$id/$mappingPath/$type': typeof AccountIdPlatformSourcesIdMappingPathTypeIndexLazyRoute
  '/$accountId/platform/sources/create/$type/$previewId': typeof AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyRoute
  '/$accountId/platform/sources/edit/$id/$previewId': typeof AccountIdPlatformSourcesEditIdPreviewIdIndexLazyRoute
  '/$accountId/risk360/dashboards/audit/$id': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/risk360/dashboards/edit/$id': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/risk360/dashboards/runs/$id': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/audit/$id': typeof AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/edit/$id': typeof AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyRoute
  '/$accountId/settings/permissions/roles/audit/$id': typeof AccountIdSettingsPermissionsRolesAuditIdIndexLazyRoute
  '/$accountId/settings/permissions/roles/edit/$id': typeof AccountIdSettingsPermissionsRolesEditIdIndexLazyRoute
  '/$accountId/threats/dashboards/audit/$id': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/threats/dashboards/edit/$id': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/threats/dashboards/runs/$id': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/threats/reports/audit/$id': typeof AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/threats/reports/edit/$id': typeof AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/threats/reports/runs/$id': typeof AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards/audit/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards/edit/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards/runs/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/reports/audit/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/vulnerabilities/reports/edit/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/vulnerabilities/reports/runs/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/audit/$id': typeof AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/create': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexRoute
  '/$accountId/platform/sources/$id/$mappingPath/$type/preview': typeof AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyRoute
  '/$accountId/platform/sources/audit/$id/data-mapping/$type': typeof AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyRoute
  '/$accountId/platform/targets/audit/$id/mapping/$idMapping': typeof AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/error-process-request': typeof ErrorProcessRequestRoute
  '/$accountId': typeof AccountIdIndexLazyRoute
  '/$accountId/caasm': typeof AccountIdCaasmIndexLazyRoute
  '/$accountId/detections': typeof AccountIdDetectionsIndexLazyRoute
  '/$accountId/explore': typeof AccountIdExploreIndexLazyRoute
  '/$accountId/incidents': typeof AccountIdIncidentsIndexLazyRoute
  '/$accountId/platform': typeof AccountIdPlatformExternalProjectionsLayoutRouteWithChildren
  '/$accountId/risk360': typeof AccountIdRisk360IndexLazyRoute
  '/$accountId/threats': typeof AccountIdThreatsExternalProjectionsLayoutRouteWithChildren
  '/$accountId/vulnerabilities': typeof AccountIdVulnerabilitiesIndexLazyRoute
  '/$accountId/tickets': typeof AccountIdTicketsIndexRoute
  '/$accountId/caasm/assets': typeof AccountIdCaasmAssetsIndexRoute
  '/$accountId/caasm/policy-violations': typeof AccountIdCaasmPolicyViolationsIndexRoute
  '/$accountId/detections/alerts': typeof AccountIdDetectionsAlertsIndexRoute
  '/$accountId/detections/incidents': typeof AccountIdDetectionsIncidentsIndexRoute
  '/$accountId/incidents/incidents': typeof AccountIdIncidentsIncidentsIndexRoute
  '/$accountId/risk360/factors': typeof AccountIdRisk360FactorsIndexRoute
  '/$accountId/threats/alerts': typeof AccountIdThreatsAlertsIndexRoute
  '/$accountId/threats/incidents': typeof AccountIdThreatsIncidentsIndexRoute
  '/$accountId/tickets/$id': typeof AccountIdTicketsIdIndexRoute
  '/$accountId/vulnerabilities/assets': typeof AccountIdVulnerabilitiesAssetsIndexRoute
  '/$accountId/vulnerabilities/exceptions': typeof AccountIdVulnerabilitiesExceptionsIndexRoute
  '/$accountId/vulnerabilities/findings': typeof AccountIdVulnerabilitiesFindingsIndexRoute
  '/$accountId/vulnerabilities/tickets': typeof AccountIdVulnerabilitiesTicketsIndexRoute
  '/$accountId/caasm/asset-coverage': typeof AccountIdCaasmAssetCoverageIndexLazyRoute
  '/$accountId/caasm/asset-inventory': typeof AccountIdCaasmAssetInventoryIndexLazyRoute
  '/$accountId/caasm/policies': typeof AccountIdCaasmPoliciesIndexLazyRoute
  '/$accountId/caasm/policy-compliance': typeof AccountIdCaasmPolicyComplianceIndexLazyRoute
  '/$accountId/caasm/settings': typeof AccountIdCaasmSettingsIndexLazyRoute
  '/$accountId/caasm/tool-coverage-and-gap': typeof AccountIdCaasmToolCoverageAndGapIndexLazyRoute
  '/$accountId/detections/explore': typeof AccountIdDetectionsExploreIndexLazyRoute
  '/$accountId/detections/global-scripts': typeof AccountIdDetectionsGlobalScriptsIndexLazyRoute
  '/$accountId/detections/lookup-tables': typeof AccountIdDetectionsLookupTablesIndexLazyRoute
  '/$accountId/detections/pivot': typeof AccountIdDetectionsPivotIndexLazyRoute
  '/$accountId/detections/rules': typeof AccountIdDetectionsRulesIndexLazyRoute
  '/$accountId/detections/settings': typeof AccountIdDetectionsSettingsIndexLazyRoute
  '/$accountId/explore/entities': typeof AccountIdExploreEntitiesIndexLazyRoute
  '/$accountId/explore/logs': typeof AccountIdExploreLogsIndexLazyRoute
  '/$accountId/incidents/pivot': typeof AccountIdIncidentsPivotIndexLazyRoute
  '/$accountId/incidents/settings': typeof AccountIdIncidentsSettingsIndexLazyRoute
  '/$accountId/platform/aliases': typeof AccountIdPlatformAliasesIndexLazyRoute
  '/$accountId/platform/asset-aging': typeof AccountIdPlatformAssetAgingIndexLazyRoute
  '/$accountId/platform/authentication-sources': typeof AccountIdPlatformAuthenticationSourcesIndexLazyRoute
  '/$accountId/platform/data-purge': typeof AccountIdPlatformDataPurgeIndexLazyRoute
  '/$accountId/platform/formatting-rules': typeof AccountIdPlatformFormattingRulesIndexLazyRoute
  '/$accountId/platform/model-management': typeof AccountIdPlatformModelManagementIndexLazyRoute
  '/$accountId/platform/relations': typeof AccountIdPlatformRelationsIndexLazyRoute
  '/$accountId/platform/sources': typeof AccountIdPlatformSourcesIndexLazyRoute
  '/$accountId/platform/targets': typeof AccountIdPlatformTargetsIndexLazyRoute
  '/$accountId/platform/workflows': typeof AccountIdPlatformWorkflowsIndexLazyRoute
  '/$accountId/risk360/factor-rules': typeof AccountIdRisk360FactorRulesIndexLazyRoute
  '/$accountId/settings/account-settings': typeof AccountIdSettingsAccountSettingsIndexLazyRoute
  '/$accountId/settings/account-usage': typeof AccountIdSettingsAccountUsageIndexLazyRoute
  '/$accountId/settings/all-accounts': typeof AccountIdSettingsAllAccountsIndexLazyRoute
  '/$accountId/settings/all-users': typeof AccountIdSettingsAllUsersIndexLazyRoute
  '/$accountId/settings/backoffice-actions': typeof AccountIdSettingsBackofficeActionsIndexLazyRoute
  '/$accountId/settings/global-factor-rules': typeof AccountIdSettingsGlobalFactorRulesIndexLazyRoute
  '/$accountId/settings/icons': typeof AccountIdSettingsIconsIndexLazyRoute
  '/$accountId/settings/profile-settings': typeof AccountIdSettingsProfileSettingsIndexLazyRoute
  '/$accountId/settings/query': typeof AccountIdSettingsQueryIndexLazyRoute
  '/$accountId/settings/risk360-backoffice-actions': typeof AccountIdSettingsRisk360BackofficeActionsIndexLazyRoute
  '/$accountId/settings/user-management': typeof AccountIdSettingsUserManagementIndexLazyRoute
  '/$accountId/vulnerabilities/asset-coverage': typeof AccountIdVulnerabilitiesAssetCoverageIndexLazyRoute
  '/$accountId/vulnerabilities/explore': typeof AccountIdVulnerabilitiesExploreIndexLazyRoute
  '/$accountId/vulnerabilities/overview': typeof AccountIdVulnerabilitiesOverviewIndexLazyRoute
  '/$accountId/vulnerabilities/pivot': typeof AccountIdVulnerabilitiesPivotIndexLazyRoute
  '/$accountId/vulnerabilities/remediation': typeof AccountIdVulnerabilitiesRemediationIndexLazyRoute
  '/$accountId/vulnerabilities/risk': typeof AccountIdVulnerabilitiesRiskIndexLazyRoute
  '/$accountId/vulnerabilities/roi': typeof AccountIdVulnerabilitiesRoiIndexLazyRoute
  '/$accountId/vulnerabilities/settings': typeof AccountIdVulnerabilitiesSettingsIndexLazyRoute
  '/$accountId/caasm/assets/$id': typeof AccountIdCaasmAssetsIdIndexRoute
  '/$accountId/caasm/policy-violations/$id': typeof AccountIdCaasmPolicyViolationsIdIndexRoute
  '/$accountId/detections/alerts/$id': typeof AccountIdDetectionsAlertsIdIndexRoute
  '/$accountId/detections/incidents/$id': typeof AccountIdDetectionsIncidentsIdIndexRoute
  '/$accountId/incidents/incidents/$id': typeof AccountIdIncidentsIncidentsIdIndexRoute
  '/$accountId/risk360/factors/$id': typeof AccountIdRisk360FactorsIdIndexRoute
  '/$accountId/threats/alerts/$id': typeof AccountIdThreatsAlertsIdIndexRoute
  '/$accountId/threats/incidents/$id': typeof AccountIdThreatsIncidentsIdIndexRoute
  '/$accountId/vulnerabilities/assets/$id': typeof AccountIdVulnerabilitiesAssetsIdIndexRoute
  '/$accountId/vulnerabilities/exceptions/$id': typeof AccountIdVulnerabilitiesExceptionsIdIndexRoute
  '/$accountId/vulnerabilities/findings/$id': typeof AccountIdVulnerabilitiesFindingsIdIndexRoute
  '/$accountId/vulnerabilities/tickets/$id': typeof AccountIdVulnerabilitiesTicketsIdIndexRoute
  '/$accountId/caasm/dashboards': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/caasm/reports': typeof AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/caasm/assets/visual': typeof AccountIdCaasmAssetsVisualIndexLazyRoute
  '/$accountId/caasm/policies/create': typeof AccountIdCaasmPoliciesCreateIndexLazyRoute
  '/$accountId/caasm/policy-violations/visual': typeof AccountIdCaasmPolicyViolationsVisualIndexLazyRoute
  '/$accountId/caasm/settings/ui-config': typeof AccountIdCaasmSettingsUiConfigIndexLazyRoute
  '/$accountId/detections/dashboards': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/detections/reports': typeof AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/detections/alerts/visual': typeof AccountIdDetectionsAlertsVisualIndexLazyRoute
  '/$accountId/detections/explore/$id': typeof AccountIdDetectionsExploreIdIndexLazyRoute
  '/$accountId/detections/global-scripts/create': typeof AccountIdDetectionsGlobalScriptsCreateIndexLazyRoute
  '/$accountId/detections/incidents/visual': typeof AccountIdDetectionsIncidentsVisualIndexLazyRoute
  '/$accountId/detections/lookup-tables/create': typeof AccountIdDetectionsLookupTablesCreateIndexLazyRoute
  '/$accountId/detections/pivot/$id': typeof AccountIdDetectionsPivotIdIndexLazyRoute
  '/$accountId/detections/rules/create': typeof AccountIdDetectionsRulesCreateIndexLazyRoute
  '/$accountId/detections/rules/logs': typeof AccountIdDetectionsRulesLogsIndexLazyRoute
  '/$accountId/detections/settings/rule-set': typeof AccountIdDetectionsSettingsRuleSetIndexLazyRoute
  '/$accountId/detections/settings/tickets-statuses': typeof AccountIdDetectionsSettingsTicketsStatusesIndexLazyRoute
  '/$accountId/detections/settings/ui-config': typeof AccountIdDetectionsSettingsUiConfigIndexLazyRoute
  '/$accountId/explore/dashboards': typeof AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/explore/reports': typeof AccountIdExploreExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/incidents/dashboards': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/incidents/reports': typeof AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/incidents/incidents/visual': typeof AccountIdIncidentsIncidentsVisualIndexLazyRoute
  '/$accountId/incidents/pivot/$id': typeof AccountIdIncidentsPivotIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set': typeof AccountIdIncidentsSettingsRuleSetIndexLazyRoute
  '/$accountId/incidents/settings/tickets-statuses': typeof AccountIdIncidentsSettingsTicketsStatusesIndexLazyRoute
  '/$accountId/incidents/settings/ui-config': typeof AccountIdIncidentsSettingsUiConfigIndexLazyRoute
  '/$accountId/platform/measurements': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyRoute
  '/$accountId/platform/asset-aging/create': typeof AccountIdPlatformAssetAgingCreateIndexLazyRoute
  '/$accountId/platform/data-entities/data-entities': typeof AccountIdPlatformDataEntitiesDataEntitiesIndexLazyRoute
  '/$accountId/platform/data-entities/fields': typeof AccountIdPlatformDataEntitiesFieldsIndexLazyRoute
  '/$accountId/platform/data-purge/$projId': typeof AccountIdPlatformDataPurgeProjIdIndexLazyRoute
  '/$accountId/platform/data-purge/runs': typeof AccountIdPlatformDataPurgeRunsIndexLazyRoute
  '/$accountId/platform/formatting-rules/create': typeof AccountIdPlatformFormattingRulesCreateIndexLazyRoute
  '/$accountId/platform/model-management/$id': typeof AccountIdPlatformModelManagementIdIndexLazyRoute
  '/$accountId/platform/model-management/runs': typeof AccountIdPlatformModelManagementRunsIndexLazyRoute
  '/$accountId/platform/relations/$relationId': typeof AccountIdPlatformRelationsRelationIdIndexLazyRoute
  '/$accountId/platform/sources/create': typeof AccountIdPlatformSourcesCreateIndexLazyRoute
  '/$accountId/platform/targets/create': typeof AccountIdPlatformTargetsCreateIndexLazyRoute
  '/$accountId/risk360/dashboards': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/risk360/factor-rules/create': typeof AccountIdRisk360FactorRulesCreateIndexLazyRoute
  '/$accountId/risk360/factor-rules/runs': typeof AccountIdRisk360FactorRulesRunsIndexLazyRoute
  '/$accountId/risk360/factors/visual': typeof AccountIdRisk360FactorsVisualIndexLazyRoute
  '/$accountId/settings/all-accounts/create': typeof AccountIdSettingsAllAccountsCreateIndexLazyRoute
  '/$accountId/settings/all-users/create': typeof AccountIdSettingsAllUsersCreateIndexLazyRoute
  '/$accountId/settings/global-factor-rules/create': typeof AccountIdSettingsGlobalFactorRulesCreateIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions': typeof AccountIdSettingsPermissionsContentPermissionsIndexLazyRoute
  '/$accountId/settings/permissions/roles': typeof AccountIdSettingsPermissionsRolesIndexLazyRoute
  '/$accountId/settings/user-management/create': typeof AccountIdSettingsUserManagementCreateIndexLazyRoute
  '/$accountId/threats/dashboards': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/threats/reports': typeof AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/threats/alerts/visual': typeof AccountIdThreatsAlertsVisualIndexLazyRoute
  '/$accountId/threats/incidents/visual': typeof AccountIdThreatsIncidentsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/vulnerabilities/reports': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/vulnerabilities/assets/visual': typeof AccountIdVulnerabilitiesAssetsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/exceptions/visual': typeof AccountIdVulnerabilitiesExceptionsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/findings/visual': typeof AccountIdVulnerabilitiesFindingsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/pivot/$id': typeof AccountIdVulnerabilitiesPivotIdIndexLazyRoute
  '/$accountId/vulnerabilities/settings/exceptions-settings': typeof AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set': typeof AccountIdVulnerabilitiesSettingsRuleSetIndexLazyRoute
  '/$accountId/vulnerabilities/settings/score': typeof AccountIdVulnerabilitiesSettingsScoreIndexLazyRoute
  '/$accountId/vulnerabilities/settings/severity-sla': typeof AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyRoute
  '/$accountId/vulnerabilities/settings/tickets-statuses': typeof AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyRoute
  '/$accountId/vulnerabilities/settings/ui-config': typeof AccountIdVulnerabilitiesSettingsUiConfigIndexLazyRoute
  '/$accountId/vulnerabilities/tickets/visual': typeof AccountIdVulnerabilitiesTicketsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/$id': typeof AccountIdVulnerabilitiesSettingsRuleSetIdIndexRoute
  '/$accountId/vulnerabilities/settings/rule-set/create': typeof AccountIdVulnerabilitiesSettingsRuleSetCreateIndexRoute
  '/$accountId/caasm/dashboards/create': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/caasm/reports/create': typeof AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/caasm/policies/audit/$id': typeof AccountIdCaasmPoliciesAuditIdIndexLazyRoute
  '/$accountId/caasm/policies/duplicate/$id': typeof AccountIdCaasmPoliciesDuplicateIdIndexLazyRoute
  '/$accountId/caasm/policies/edit/$id': typeof AccountIdCaasmPoliciesEditIdIndexLazyRoute
  '/$accountId/caasm/policies/runs/$id': typeof AccountIdCaasmPoliciesRunsIdIndexLazyRoute
  '/$accountId/caasm/ui-config/audit/$path': typeof AccountIdCaasmUiConfigAuditPathIndexLazyRoute
  '/$accountId/detections/dashboards/create': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/detections/reports/create': typeof AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/detections/global-scripts/audit/$id': typeof AccountIdDetectionsGlobalScriptsAuditIdIndexLazyRoute
  '/$accountId/detections/global-scripts/edit/$id': typeof AccountIdDetectionsGlobalScriptsEditIdIndexLazyRoute
  '/$accountId/detections/lookup-tables/audit/$id': typeof AccountIdDetectionsLookupTablesAuditIdIndexLazyRoute
  '/$accountId/detections/lookup-tables/edit/$id': typeof AccountIdDetectionsLookupTablesEditIdIndexLazyRoute
  '/$accountId/detections/rules/audit/$id': typeof AccountIdDetectionsRulesAuditIdIndexLazyRoute
  '/$accountId/detections/rules/edit/$id': typeof AccountIdDetectionsRulesEditIdIndexLazyRoute
  '/$accountId/detections/rules/runs/$id': typeof AccountIdDetectionsRulesRunsIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/$id': typeof AccountIdDetectionsSettingsRuleSetIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/create': typeof AccountIdDetectionsSettingsRuleSetCreateIndexLazyRoute
  '/$accountId/detections/ui-config/audit/$path': typeof AccountIdDetectionsUiConfigAuditPathIndexLazyRoute
  '/$accountId/explore/dashboards/create': typeof AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/explore/reports/create': typeof AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/incidents/dashboards/create': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/incidents/reports/create': typeof AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/$id': typeof AccountIdIncidentsSettingsRuleSetIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/create': typeof AccountIdIncidentsSettingsRuleSetCreateIndexLazyRoute
  '/$accountId/platform/measurements/create': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyRoute
  '/$accountId/platform/asset-aging/edit/$id': typeof AccountIdPlatformAssetAgingEditIdIndexLazyRoute
  '/$accountId/platform/data-entities/data-entities/$projId': typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyRoute
  '/$accountId/platform/formatting-rules/edit/$id': typeof AccountIdPlatformFormattingRulesEditIdIndexLazyRoute
  '/$accountId/platform/model-management/$id/preview': typeof AccountIdPlatformModelManagementIdPreviewIndexLazyRoute
  '/$accountId/platform/model-management/audit/$id': typeof AccountIdPlatformModelManagementAuditIdIndexLazyRoute
  '/$accountId/platform/sources/audit/$id': typeof AccountIdPlatformSourcesAuditIdIndexLazyRoute
  '/$accountId/platform/sources/create/$type': typeof AccountIdPlatformSourcesCreateTypeIndexLazyRoute
  '/$accountId/platform/sources/edit/$id': typeof AccountIdPlatformSourcesEditIdIndexLazyRoute
  '/$accountId/platform/sources/runs/$id': typeof AccountIdPlatformSourcesRunsIdIndexLazyRoute
  '/$accountId/platform/targets/activityLog/$id': typeof AccountIdPlatformTargetsActivityLogIdIndexLazyRoute
  '/$accountId/platform/targets/audit/$id': typeof AccountIdPlatformTargetsAuditIdIndexLazyRoute
  '/$accountId/platform/targets/create/$type': typeof AccountIdPlatformTargetsCreateTypeIndexLazyRoute
  '/$accountId/platform/targets/edit/$id': typeof AccountIdPlatformTargetsEditIdIndexLazyRoute
  '/$accountId/platform/targets/mapping/$id': typeof AccountIdPlatformTargetsMappingIdIndexLazyRoute
  '/$accountId/risk360/dashboards/create': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/risk360/factor-rules/audit/$id': typeof AccountIdRisk360FactorRulesAuditIdIndexLazyRoute
  '/$accountId/risk360/factor-rules/edit/$id': typeof AccountIdRisk360FactorRulesEditIdIndexLazyRoute
  '/$accountId/settings/all-accounts/audit/$id': typeof AccountIdSettingsAllAccountsAuditIdIndexLazyRoute
  '/$accountId/settings/all-accounts/edit/$id': typeof AccountIdSettingsAllAccountsEditIdIndexLazyRoute
  '/$accountId/settings/all-users/audit/$id': typeof AccountIdSettingsAllUsersAuditIdIndexLazyRoute
  '/$accountId/settings/all-users/edit/$id': typeof AccountIdSettingsAllUsersEditIdIndexLazyRoute
  '/$accountId/settings/global-factor-rules/audit/$id': typeof AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyRoute
  '/$accountId/settings/global-factor-rules/edit/$id': typeof AccountIdSettingsGlobalFactorRulesEditIdIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/create': typeof AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyRoute
  '/$accountId/settings/permissions/roles/create': typeof AccountIdSettingsPermissionsRolesCreateIndexLazyRoute
  '/$accountId/settings/user-management/audit/$id': typeof AccountIdSettingsUserManagementAuditIdIndexLazyRoute
  '/$accountId/settings/user-management/edit/$id': typeof AccountIdSettingsUserManagementEditIdIndexLazyRoute
  '/$accountId/threats/dashboards/create': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/threats/reports/create': typeof AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards/create': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/vulnerabilities/reports/create': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/vulnerabilities/findings/runs/$id': typeof AccountIdVulnerabilitiesFindingsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/score/audit/$path': typeof AccountIdVulnerabilitiesScoreAuditPathIndexLazyRoute
  '/$accountId/vulnerabilities/ui-config/audit/$path': typeof AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex': typeof AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexRoute
  '/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex': typeof AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexRoute
  '/$accountId/caasm/dashboards/audit/$id': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/caasm/dashboards/edit/$id': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/caasm/dashboards/runs/$id': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/caasm/reports/audit/$id': typeof AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/caasm/reports/edit/$id': typeof AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/caasm/reports/runs/$id': typeof AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/detections/dashboards/audit/$id': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/detections/dashboards/edit/$id': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/detections/dashboards/runs/$id': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/detections/reports/audit/$id': typeof AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/detections/reports/edit/$id': typeof AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/detections/reports/runs/$id': typeof AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/$id/$ruleIndex': typeof AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyRoute
  '/$accountId/detections/settings/rule-set/audit/$id': typeof AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/create/$ruleIndex': typeof AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyRoute
  '/$accountId/explore/dashboards/audit/$id': typeof AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/explore/dashboards/edit/$id': typeof AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/explore/dashboards/runs/$id': typeof AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/explore/reports/edit/$id': typeof AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/explore/reports/runs/$id': typeof AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/incidents/dashboards/audit/$id': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/incidents/dashboards/edit/$id': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/incidents/dashboards/runs/$id': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/incidents/reports/audit/$id': typeof AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/incidents/reports/edit/$id': typeof AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/incidents/reports/runs/$id': typeof AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/$id/$ruleIndex': typeof AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/audit/$id': typeof AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/create/$ruleIndex': typeof AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyRoute
  '/$accountId/incidents/settings/ui-config/audit/$path': typeof AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyRoute
  '/$accountId/platform/measurements/audit/$id': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyRoute
  '/$accountId/platform/measurements/edit/$systemName': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyRoute
  '/$accountId/platform/data-entities/data-entities/$projId/$ruleId': typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyRoute
  '/$accountId/platform/sources/$id/$mappingPath/$type': typeof AccountIdPlatformSourcesIdMappingPathTypeIndexLazyRoute
  '/$accountId/platform/sources/create/$type/$previewId': typeof AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyRoute
  '/$accountId/platform/sources/edit/$id/$previewId': typeof AccountIdPlatformSourcesEditIdPreviewIdIndexLazyRoute
  '/$accountId/risk360/dashboards/audit/$id': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/risk360/dashboards/edit/$id': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/risk360/dashboards/runs/$id': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/audit/$id': typeof AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/edit/$id': typeof AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyRoute
  '/$accountId/settings/permissions/roles/audit/$id': typeof AccountIdSettingsPermissionsRolesAuditIdIndexLazyRoute
  '/$accountId/settings/permissions/roles/edit/$id': typeof AccountIdSettingsPermissionsRolesEditIdIndexLazyRoute
  '/$accountId/threats/dashboards/audit/$id': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/threats/dashboards/edit/$id': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/threats/dashboards/runs/$id': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/threats/reports/audit/$id': typeof AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/threats/reports/edit/$id': typeof AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/threats/reports/runs/$id': typeof AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards/audit/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards/edit/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/vulnerabilities/dashboards/runs/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/reports/audit/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/vulnerabilities/reports/edit/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/vulnerabilities/reports/runs/$id': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/audit/$id': typeof AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/create': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexRoute
  '/$accountId/platform/sources/$id/$mappingPath/$type/preview': typeof AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyRoute
  '/$accountId/platform/sources/audit/$id/data-mapping/$type': typeof AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyRoute
  '/$accountId/platform/targets/audit/$id/mapping/$idMapping': typeof AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/error-process-request': typeof ErrorProcessRequestRoute
  '/$accountId/': typeof AccountIdIndexLazyRoute
  '/$accountId/caasm': typeof AccountIdCaasmRouteWithChildren
  '/$accountId/caasm/_externalProjectionsLayout': typeof AccountIdCaasmExternalProjectionsLayoutRouteWithChildren
  '/$accountId/detections': typeof AccountIdDetectionsRouteWithChildren
  '/$accountId/detections/_externalProjectionsLayout': typeof AccountIdDetectionsExternalProjectionsLayoutRouteWithChildren
  '/$accountId/explore': typeof AccountIdExploreRouteWithChildren
  '/$accountId/explore/_externalProjectionsLayout': typeof AccountIdExploreExternalProjectionsLayoutRouteWithChildren
  '/$accountId/incidents': typeof AccountIdIncidentsRouteWithChildren
  '/$accountId/incidents/_externalProjectionsLayout': typeof AccountIdIncidentsExternalProjectionsLayoutRouteWithChildren
  '/$accountId/platform': typeof AccountIdPlatformRouteWithChildren
  '/$accountId/platform/_externalProjectionsLayout': typeof AccountIdPlatformExternalProjectionsLayoutRouteWithChildren
  '/$accountId/risk360': typeof AccountIdRisk360RouteWithChildren
  '/$accountId/risk360/_externalProjectionsLayout': typeof AccountIdRisk360ExternalProjectionsLayoutRouteWithChildren
  '/$accountId/threats': typeof AccountIdThreatsRouteWithChildren
  '/$accountId/threats/_externalProjectionsLayout': typeof AccountIdThreatsExternalProjectionsLayoutRouteWithChildren
  '/$accountId/vulnerabilities': typeof AccountIdVulnerabilitiesRouteWithChildren
  '/$accountId/vulnerabilities/_externalProjectionsLayout': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutRouteWithChildren
  '/$accountId/tickets/': typeof AccountIdTicketsIndexRoute
  '/$accountId/caasm/': typeof AccountIdCaasmIndexLazyRoute
  '/$accountId/detections/': typeof AccountIdDetectionsIndexLazyRoute
  '/$accountId/explore/': typeof AccountIdExploreIndexLazyRoute
  '/$accountId/incidents/': typeof AccountIdIncidentsIndexLazyRoute
  '/$accountId/risk360/': typeof AccountIdRisk360IndexLazyRoute
  '/$accountId/vulnerabilities/': typeof AccountIdVulnerabilitiesIndexLazyRoute
  '/$accountId/caasm/assets/': typeof AccountIdCaasmAssetsIndexRoute
  '/$accountId/caasm/policy-violations/': typeof AccountIdCaasmPolicyViolationsIndexRoute
  '/$accountId/detections/alerts/': typeof AccountIdDetectionsAlertsIndexRoute
  '/$accountId/detections/incidents/': typeof AccountIdDetectionsIncidentsIndexRoute
  '/$accountId/incidents/incidents/': typeof AccountIdIncidentsIncidentsIndexRoute
  '/$accountId/risk360/factors/': typeof AccountIdRisk360FactorsIndexRoute
  '/$accountId/threats/alerts/': typeof AccountIdThreatsAlertsIndexRoute
  '/$accountId/threats/incidents/': typeof AccountIdThreatsIncidentsIndexRoute
  '/$accountId/tickets/$id/': typeof AccountIdTicketsIdIndexRoute
  '/$accountId/vulnerabilities/assets/': typeof AccountIdVulnerabilitiesAssetsIndexRoute
  '/$accountId/vulnerabilities/exceptions/': typeof AccountIdVulnerabilitiesExceptionsIndexRoute
  '/$accountId/vulnerabilities/findings/': typeof AccountIdVulnerabilitiesFindingsIndexRoute
  '/$accountId/vulnerabilities/tickets/': typeof AccountIdVulnerabilitiesTicketsIndexRoute
  '/$accountId/caasm/asset-coverage/': typeof AccountIdCaasmAssetCoverageIndexLazyRoute
  '/$accountId/caasm/asset-inventory/': typeof AccountIdCaasmAssetInventoryIndexLazyRoute
  '/$accountId/caasm/policies/': typeof AccountIdCaasmPoliciesIndexLazyRoute
  '/$accountId/caasm/policy-compliance/': typeof AccountIdCaasmPolicyComplianceIndexLazyRoute
  '/$accountId/caasm/settings/': typeof AccountIdCaasmSettingsIndexLazyRoute
  '/$accountId/caasm/tool-coverage-and-gap/': typeof AccountIdCaasmToolCoverageAndGapIndexLazyRoute
  '/$accountId/detections/explore/': typeof AccountIdDetectionsExploreIndexLazyRoute
  '/$accountId/detections/global-scripts/': typeof AccountIdDetectionsGlobalScriptsIndexLazyRoute
  '/$accountId/detections/lookup-tables/': typeof AccountIdDetectionsLookupTablesIndexLazyRoute
  '/$accountId/detections/pivot/': typeof AccountIdDetectionsPivotIndexLazyRoute
  '/$accountId/detections/rules/': typeof AccountIdDetectionsRulesIndexLazyRoute
  '/$accountId/detections/settings/': typeof AccountIdDetectionsSettingsIndexLazyRoute
  '/$accountId/explore/entities/': typeof AccountIdExploreEntitiesIndexLazyRoute
  '/$accountId/explore/logs/': typeof AccountIdExploreLogsIndexLazyRoute
  '/$accountId/incidents/pivot/': typeof AccountIdIncidentsPivotIndexLazyRoute
  '/$accountId/incidents/settings/': typeof AccountIdIncidentsSettingsIndexLazyRoute
  '/$accountId/platform/aliases/': typeof AccountIdPlatformAliasesIndexLazyRoute
  '/$accountId/platform/asset-aging/': typeof AccountIdPlatformAssetAgingIndexLazyRoute
  '/$accountId/platform/authentication-sources/': typeof AccountIdPlatformAuthenticationSourcesIndexLazyRoute
  '/$accountId/platform/data-purge/': typeof AccountIdPlatformDataPurgeIndexLazyRoute
  '/$accountId/platform/formatting-rules/': typeof AccountIdPlatformFormattingRulesIndexLazyRoute
  '/$accountId/platform/model-management/': typeof AccountIdPlatformModelManagementIndexLazyRoute
  '/$accountId/platform/relations/': typeof AccountIdPlatformRelationsIndexLazyRoute
  '/$accountId/platform/sources/': typeof AccountIdPlatformSourcesIndexLazyRoute
  '/$accountId/platform/targets/': typeof AccountIdPlatformTargetsIndexLazyRoute
  '/$accountId/platform/workflows/': typeof AccountIdPlatformWorkflowsIndexLazyRoute
  '/$accountId/risk360/factor-rules/': typeof AccountIdRisk360FactorRulesIndexLazyRoute
  '/$accountId/settings/account-settings/': typeof AccountIdSettingsAccountSettingsIndexLazyRoute
  '/$accountId/settings/account-usage/': typeof AccountIdSettingsAccountUsageIndexLazyRoute
  '/$accountId/settings/all-accounts/': typeof AccountIdSettingsAllAccountsIndexLazyRoute
  '/$accountId/settings/all-users/': typeof AccountIdSettingsAllUsersIndexLazyRoute
  '/$accountId/settings/backoffice-actions/': typeof AccountIdSettingsBackofficeActionsIndexLazyRoute
  '/$accountId/settings/global-factor-rules/': typeof AccountIdSettingsGlobalFactorRulesIndexLazyRoute
  '/$accountId/settings/icons/': typeof AccountIdSettingsIconsIndexLazyRoute
  '/$accountId/settings/profile-settings/': typeof AccountIdSettingsProfileSettingsIndexLazyRoute
  '/$accountId/settings/query/': typeof AccountIdSettingsQueryIndexLazyRoute
  '/$accountId/settings/risk360-backoffice-actions/': typeof AccountIdSettingsRisk360BackofficeActionsIndexLazyRoute
  '/$accountId/settings/user-management/': typeof AccountIdSettingsUserManagementIndexLazyRoute
  '/$accountId/vulnerabilities/asset-coverage/': typeof AccountIdVulnerabilitiesAssetCoverageIndexLazyRoute
  '/$accountId/vulnerabilities/explore/': typeof AccountIdVulnerabilitiesExploreIndexLazyRoute
  '/$accountId/vulnerabilities/overview/': typeof AccountIdVulnerabilitiesOverviewIndexLazyRoute
  '/$accountId/vulnerabilities/pivot/': typeof AccountIdVulnerabilitiesPivotIndexLazyRoute
  '/$accountId/vulnerabilities/remediation/': typeof AccountIdVulnerabilitiesRemediationIndexLazyRoute
  '/$accountId/vulnerabilities/risk/': typeof AccountIdVulnerabilitiesRiskIndexLazyRoute
  '/$accountId/vulnerabilities/roi/': typeof AccountIdVulnerabilitiesRoiIndexLazyRoute
  '/$accountId/vulnerabilities/settings/': typeof AccountIdVulnerabilitiesSettingsIndexLazyRoute
  '/$accountId/caasm/assets/$id/': typeof AccountIdCaasmAssetsIdIndexRoute
  '/$accountId/caasm/policy-violations/$id/': typeof AccountIdCaasmPolicyViolationsIdIndexRoute
  '/$accountId/detections/alerts/$id/': typeof AccountIdDetectionsAlertsIdIndexRoute
  '/$accountId/detections/incidents/$id/': typeof AccountIdDetectionsIncidentsIdIndexRoute
  '/$accountId/incidents/incidents/$id/': typeof AccountIdIncidentsIncidentsIdIndexRoute
  '/$accountId/risk360/factors/$id/': typeof AccountIdRisk360FactorsIdIndexRoute
  '/$accountId/threats/alerts/$id/': typeof AccountIdThreatsAlertsIdIndexRoute
  '/$accountId/threats/incidents/$id/': typeof AccountIdThreatsIncidentsIdIndexRoute
  '/$accountId/vulnerabilities/assets/$id/': typeof AccountIdVulnerabilitiesAssetsIdIndexRoute
  '/$accountId/vulnerabilities/exceptions/$id/': typeof AccountIdVulnerabilitiesExceptionsIdIndexRoute
  '/$accountId/vulnerabilities/findings/$id/': typeof AccountIdVulnerabilitiesFindingsIdIndexRoute
  '/$accountId/vulnerabilities/tickets/$id/': typeof AccountIdVulnerabilitiesTicketsIdIndexRoute
  '/$accountId/caasm/_externalProjectionsLayout/dashboards/': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/caasm/_externalProjectionsLayout/reports/': typeof AccountIdCaasmExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/caasm/assets/visual/': typeof AccountIdCaasmAssetsVisualIndexLazyRoute
  '/$accountId/caasm/policies/create/': typeof AccountIdCaasmPoliciesCreateIndexLazyRoute
  '/$accountId/caasm/policy-violations/visual/': typeof AccountIdCaasmPolicyViolationsVisualIndexLazyRoute
  '/$accountId/caasm/settings/ui-config/': typeof AccountIdCaasmSettingsUiConfigIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/dashboards/': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/reports/': typeof AccountIdDetectionsExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/detections/alerts/visual/': typeof AccountIdDetectionsAlertsVisualIndexLazyRoute
  '/$accountId/detections/explore/$id/': typeof AccountIdDetectionsExploreIdIndexLazyRoute
  '/$accountId/detections/global-scripts/create/': typeof AccountIdDetectionsGlobalScriptsCreateIndexLazyRoute
  '/$accountId/detections/incidents/visual/': typeof AccountIdDetectionsIncidentsVisualIndexLazyRoute
  '/$accountId/detections/lookup-tables/create/': typeof AccountIdDetectionsLookupTablesCreateIndexLazyRoute
  '/$accountId/detections/pivot/$id/': typeof AccountIdDetectionsPivotIdIndexLazyRoute
  '/$accountId/detections/rules/create/': typeof AccountIdDetectionsRulesCreateIndexLazyRoute
  '/$accountId/detections/rules/logs/': typeof AccountIdDetectionsRulesLogsIndexLazyRoute
  '/$accountId/detections/settings/rule-set/': typeof AccountIdDetectionsSettingsRuleSetIndexLazyRoute
  '/$accountId/detections/settings/tickets-statuses/': typeof AccountIdDetectionsSettingsTicketsStatusesIndexLazyRoute
  '/$accountId/detections/settings/ui-config/': typeof AccountIdDetectionsSettingsUiConfigIndexLazyRoute
  '/$accountId/explore/_externalProjectionsLayout/dashboards/': typeof AccountIdExploreExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/explore/_externalProjectionsLayout/reports/': typeof AccountIdExploreExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/dashboards/': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/reports/': typeof AccountIdIncidentsExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/incidents/incidents/visual/': typeof AccountIdIncidentsIncidentsVisualIndexLazyRoute
  '/$accountId/incidents/pivot/$id/': typeof AccountIdIncidentsPivotIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/': typeof AccountIdIncidentsSettingsRuleSetIndexLazyRoute
  '/$accountId/incidents/settings/tickets-statuses/': typeof AccountIdIncidentsSettingsTicketsStatusesIndexLazyRoute
  '/$accountId/incidents/settings/ui-config/': typeof AccountIdIncidentsSettingsUiConfigIndexLazyRoute
  '/$accountId/platform/_externalProjectionsLayout/measurements/': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsIndexLazyRoute
  '/$accountId/platform/asset-aging/create/': typeof AccountIdPlatformAssetAgingCreateIndexLazyRoute
  '/$accountId/platform/data-entities/data-entities/': typeof AccountIdPlatformDataEntitiesDataEntitiesIndexLazyRoute
  '/$accountId/platform/data-entities/fields/': typeof AccountIdPlatformDataEntitiesFieldsIndexLazyRoute
  '/$accountId/platform/data-purge/$projId/': typeof AccountIdPlatformDataPurgeProjIdIndexLazyRoute
  '/$accountId/platform/data-purge/runs/': typeof AccountIdPlatformDataPurgeRunsIndexLazyRoute
  '/$accountId/platform/formatting-rules/create/': typeof AccountIdPlatformFormattingRulesCreateIndexLazyRoute
  '/$accountId/platform/model-management/$id/': typeof AccountIdPlatformModelManagementIdIndexLazyRoute
  '/$accountId/platform/model-management/runs/': typeof AccountIdPlatformModelManagementRunsIndexLazyRoute
  '/$accountId/platform/relations/$relationId/': typeof AccountIdPlatformRelationsRelationIdIndexLazyRoute
  '/$accountId/platform/sources/create/': typeof AccountIdPlatformSourcesCreateIndexLazyRoute
  '/$accountId/platform/targets/create/': typeof AccountIdPlatformTargetsCreateIndexLazyRoute
  '/$accountId/risk360/_externalProjectionsLayout/dashboards/': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/risk360/factor-rules/create/': typeof AccountIdRisk360FactorRulesCreateIndexLazyRoute
  '/$accountId/risk360/factor-rules/runs/': typeof AccountIdRisk360FactorRulesRunsIndexLazyRoute
  '/$accountId/risk360/factors/visual/': typeof AccountIdRisk360FactorsVisualIndexLazyRoute
  '/$accountId/settings/all-accounts/create/': typeof AccountIdSettingsAllAccountsCreateIndexLazyRoute
  '/$accountId/settings/all-users/create/': typeof AccountIdSettingsAllUsersCreateIndexLazyRoute
  '/$accountId/settings/global-factor-rules/create/': typeof AccountIdSettingsGlobalFactorRulesCreateIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/': typeof AccountIdSettingsPermissionsContentPermissionsIndexLazyRoute
  '/$accountId/settings/permissions/roles/': typeof AccountIdSettingsPermissionsRolesIndexLazyRoute
  '/$accountId/settings/user-management/create/': typeof AccountIdSettingsUserManagementCreateIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/dashboards/': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/reports/': typeof AccountIdThreatsExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/threats/alerts/visual/': typeof AccountIdThreatsAlertsVisualIndexLazyRoute
  '/$accountId/threats/incidents/visual/': typeof AccountIdThreatsIncidentsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsIndexLazyRoute
  '/$accountId/vulnerabilities/assets/visual/': typeof AccountIdVulnerabilitiesAssetsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/exceptions/visual/': typeof AccountIdVulnerabilitiesExceptionsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/findings/visual/': typeof AccountIdVulnerabilitiesFindingsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/pivot/$id/': typeof AccountIdVulnerabilitiesPivotIdIndexLazyRoute
  '/$accountId/vulnerabilities/settings/exceptions-settings/': typeof AccountIdVulnerabilitiesSettingsExceptionsSettingsIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/': typeof AccountIdVulnerabilitiesSettingsRuleSetIndexLazyRoute
  '/$accountId/vulnerabilities/settings/score/': typeof AccountIdVulnerabilitiesSettingsScoreIndexLazyRoute
  '/$accountId/vulnerabilities/settings/severity-sla/': typeof AccountIdVulnerabilitiesSettingsSeveritySlaIndexLazyRoute
  '/$accountId/vulnerabilities/settings/tickets-statuses/': typeof AccountIdVulnerabilitiesSettingsTicketsStatusesIndexLazyRoute
  '/$accountId/vulnerabilities/settings/ui-config/': typeof AccountIdVulnerabilitiesSettingsUiConfigIndexLazyRoute
  '/$accountId/vulnerabilities/tickets/visual/': typeof AccountIdVulnerabilitiesTicketsVisualIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/$id/': typeof AccountIdVulnerabilitiesSettingsRuleSetIdIndexRoute
  '/$accountId/vulnerabilities/settings/rule-set/create/': typeof AccountIdVulnerabilitiesSettingsRuleSetCreateIndexRoute
  '/$accountId/caasm/_externalProjectionsLayout/dashboards/create/': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/caasm/_externalProjectionsLayout/reports/create/': typeof AccountIdCaasmExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/caasm/policies/audit/$id/': typeof AccountIdCaasmPoliciesAuditIdIndexLazyRoute
  '/$accountId/caasm/policies/duplicate/$id/': typeof AccountIdCaasmPoliciesDuplicateIdIndexLazyRoute
  '/$accountId/caasm/policies/edit/$id/': typeof AccountIdCaasmPoliciesEditIdIndexLazyRoute
  '/$accountId/caasm/policies/runs/$id/': typeof AccountIdCaasmPoliciesRunsIdIndexLazyRoute
  '/$accountId/caasm/ui-config/audit/$path/': typeof AccountIdCaasmUiConfigAuditPathIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/dashboards/create/': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/reports/create/': typeof AccountIdDetectionsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/detections/global-scripts/audit/$id/': typeof AccountIdDetectionsGlobalScriptsAuditIdIndexLazyRoute
  '/$accountId/detections/global-scripts/edit/$id/': typeof AccountIdDetectionsGlobalScriptsEditIdIndexLazyRoute
  '/$accountId/detections/lookup-tables/audit/$id/': typeof AccountIdDetectionsLookupTablesAuditIdIndexLazyRoute
  '/$accountId/detections/lookup-tables/edit/$id/': typeof AccountIdDetectionsLookupTablesEditIdIndexLazyRoute
  '/$accountId/detections/rules/audit/$id/': typeof AccountIdDetectionsRulesAuditIdIndexLazyRoute
  '/$accountId/detections/rules/edit/$id/': typeof AccountIdDetectionsRulesEditIdIndexLazyRoute
  '/$accountId/detections/rules/runs/$id/': typeof AccountIdDetectionsRulesRunsIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/$id/': typeof AccountIdDetectionsSettingsRuleSetIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/create/': typeof AccountIdDetectionsSettingsRuleSetCreateIndexLazyRoute
  '/$accountId/detections/ui-config/audit/$path/': typeof AccountIdDetectionsUiConfigAuditPathIndexLazyRoute
  '/$accountId/explore/_externalProjectionsLayout/dashboards/create/': typeof AccountIdExploreExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/explore/_externalProjectionsLayout/reports/create/': typeof AccountIdExploreExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/dashboards/create/': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/reports/create/': typeof AccountIdIncidentsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/$id/': typeof AccountIdIncidentsSettingsRuleSetIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/create/': typeof AccountIdIncidentsSettingsRuleSetCreateIndexLazyRoute
  '/$accountId/platform/_externalProjectionsLayout/measurements/create/': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsCreateIndexLazyRoute
  '/$accountId/platform/asset-aging/edit/$id/': typeof AccountIdPlatformAssetAgingEditIdIndexLazyRoute
  '/$accountId/platform/data-entities/data-entities/$projId/': typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdIndexLazyRoute
  '/$accountId/platform/formatting-rules/edit/$id/': typeof AccountIdPlatformFormattingRulesEditIdIndexLazyRoute
  '/$accountId/platform/model-management/$id/preview/': typeof AccountIdPlatformModelManagementIdPreviewIndexLazyRoute
  '/$accountId/platform/model-management/audit/$id/': typeof AccountIdPlatformModelManagementAuditIdIndexLazyRoute
  '/$accountId/platform/sources/audit/$id/': typeof AccountIdPlatformSourcesAuditIdIndexLazyRoute
  '/$accountId/platform/sources/create/$type/': typeof AccountIdPlatformSourcesCreateTypeIndexLazyRoute
  '/$accountId/platform/sources/edit/$id/': typeof AccountIdPlatformSourcesEditIdIndexLazyRoute
  '/$accountId/platform/sources/runs/$id/': typeof AccountIdPlatformSourcesRunsIdIndexLazyRoute
  '/$accountId/platform/targets/activityLog/$id/': typeof AccountIdPlatformTargetsActivityLogIdIndexLazyRoute
  '/$accountId/platform/targets/audit/$id/': typeof AccountIdPlatformTargetsAuditIdIndexLazyRoute
  '/$accountId/platform/targets/create/$type/': typeof AccountIdPlatformTargetsCreateTypeIndexLazyRoute
  '/$accountId/platform/targets/edit/$id/': typeof AccountIdPlatformTargetsEditIdIndexLazyRoute
  '/$accountId/platform/targets/mapping/$id/': typeof AccountIdPlatformTargetsMappingIdIndexLazyRoute
  '/$accountId/risk360/_externalProjectionsLayout/dashboards/create/': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/risk360/factor-rules/audit/$id/': typeof AccountIdRisk360FactorRulesAuditIdIndexLazyRoute
  '/$accountId/risk360/factor-rules/edit/$id/': typeof AccountIdRisk360FactorRulesEditIdIndexLazyRoute
  '/$accountId/settings/all-accounts/audit/$id/': typeof AccountIdSettingsAllAccountsAuditIdIndexLazyRoute
  '/$accountId/settings/all-accounts/edit/$id/': typeof AccountIdSettingsAllAccountsEditIdIndexLazyRoute
  '/$accountId/settings/all-users/audit/$id/': typeof AccountIdSettingsAllUsersAuditIdIndexLazyRoute
  '/$accountId/settings/all-users/edit/$id/': typeof AccountIdSettingsAllUsersEditIdIndexLazyRoute
  '/$accountId/settings/global-factor-rules/audit/$id/': typeof AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyRoute
  '/$accountId/settings/global-factor-rules/edit/$id/': typeof AccountIdSettingsGlobalFactorRulesEditIdIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/create/': typeof AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyRoute
  '/$accountId/settings/permissions/roles/create/': typeof AccountIdSettingsPermissionsRolesCreateIndexLazyRoute
  '/$accountId/settings/user-management/audit/$id/': typeof AccountIdSettingsUserManagementAuditIdIndexLazyRoute
  '/$accountId/settings/user-management/edit/$id/': typeof AccountIdSettingsUserManagementEditIdIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/dashboards/create/': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/reports/create/': typeof AccountIdThreatsExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/create/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsCreateIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/create/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsCreateIndexLazyRoute
  '/$accountId/vulnerabilities/findings/runs/$id/': typeof AccountIdVulnerabilitiesFindingsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/score/audit/$path/': typeof AccountIdVulnerabilitiesScoreAuditPathIndexLazyRoute
  '/$accountId/vulnerabilities/ui-config/audit/$path/': typeof AccountIdVulnerabilitiesUiConfigAuditPathIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex/': typeof AccountIdVulnerabilitiesSettingsRuleSetIdRuleIndexIndexRoute
  '/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex/': typeof AccountIdVulnerabilitiesSettingsRuleSetCreateRuleIndexIndexRoute
  '/$accountId/caasm/_externalProjectionsLayout/dashboards/audit/$id/': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/caasm/_externalProjectionsLayout/dashboards/edit/$id/': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/caasm/_externalProjectionsLayout/dashboards/runs/$id/': typeof AccountIdCaasmExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/caasm/_externalProjectionsLayout/reports/audit/$id/': typeof AccountIdCaasmExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/caasm/_externalProjectionsLayout/reports/edit/$id/': typeof AccountIdCaasmExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/caasm/_externalProjectionsLayout/reports/runs/$id/': typeof AccountIdCaasmExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/dashboards/audit/$id/': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/dashboards/edit/$id/': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/dashboards/runs/$id/': typeof AccountIdDetectionsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/reports/audit/$id/': typeof AccountIdDetectionsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/reports/edit/$id/': typeof AccountIdDetectionsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/detections/_externalProjectionsLayout/reports/runs/$id/': typeof AccountIdDetectionsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/$id/$ruleIndex/': typeof AccountIdDetectionsSettingsRuleSetIdRuleIndexIndexLazyRoute
  '/$accountId/detections/settings/rule-set/audit/$id/': typeof AccountIdDetectionsSettingsRuleSetAuditIdIndexLazyRoute
  '/$accountId/detections/settings/rule-set/create/$ruleIndex/': typeof AccountIdDetectionsSettingsRuleSetCreateRuleIndexIndexLazyRoute
  '/$accountId/explore/_externalProjectionsLayout/dashboards/audit/$id/': typeof AccountIdExploreExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/explore/_externalProjectionsLayout/dashboards/edit/$id/': typeof AccountIdExploreExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/explore/_externalProjectionsLayout/dashboards/runs/$id/': typeof AccountIdExploreExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/explore/_externalProjectionsLayout/reports/edit/$id/': typeof AccountIdExploreExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/explore/_externalProjectionsLayout/reports/runs/$id/': typeof AccountIdExploreExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/dashboards/audit/$id/': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/dashboards/edit/$id/': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/dashboards/runs/$id/': typeof AccountIdIncidentsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/reports/audit/$id/': typeof AccountIdIncidentsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/reports/edit/$id/': typeof AccountIdIncidentsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/incidents/_externalProjectionsLayout/reports/runs/$id/': typeof AccountIdIncidentsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/$id/$ruleIndex/': typeof AccountIdIncidentsSettingsRuleSetIdRuleIndexIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/audit/$id/': typeof AccountIdIncidentsSettingsRuleSetAuditIdIndexLazyRoute
  '/$accountId/incidents/settings/rule-set/create/$ruleIndex/': typeof AccountIdIncidentsSettingsRuleSetCreateRuleIndexIndexLazyRoute
  '/$accountId/incidents/settings/ui-config/audit/$path/': typeof AccountIdIncidentsSettingsUiConfigAuditPathIndexLazyRoute
  '/$accountId/platform/_externalProjectionsLayout/measurements/audit/$id/': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsAuditIdIndexLazyRoute
  '/$accountId/platform/_externalProjectionsLayout/measurements/edit/$systemName/': typeof AccountIdPlatformExternalProjectionsLayoutMeasurementsEditSystemNameIndexLazyRoute
  '/$accountId/platform/data-entities/data-entities/$projId/$ruleId/': typeof AccountIdPlatformDataEntitiesDataEntitiesProjIdRuleIdIndexLazyRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameIndexLazyRoute
  '/$accountId/platform/sources/$id/$mappingPath/$type/': typeof AccountIdPlatformSourcesIdMappingPathTypeIndexLazyRoute
  '/$accountId/platform/sources/create/$type/$previewId/': typeof AccountIdPlatformSourcesCreateTypePreviewIdIndexLazyRoute
  '/$accountId/platform/sources/edit/$id/$previewId/': typeof AccountIdPlatformSourcesEditIdPreviewIdIndexLazyRoute
  '/$accountId/risk360/_externalProjectionsLayout/dashboards/audit/$id/': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/risk360/_externalProjectionsLayout/dashboards/edit/$id/': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/risk360/_externalProjectionsLayout/dashboards/runs/$id/': typeof AccountIdRisk360ExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/audit/$id/': typeof AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyRoute
  '/$accountId/settings/permissions/content-permissions/edit/$id/': typeof AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyRoute
  '/$accountId/settings/permissions/roles/audit/$id/': typeof AccountIdSettingsPermissionsRolesAuditIdIndexLazyRoute
  '/$accountId/settings/permissions/roles/edit/$id/': typeof AccountIdSettingsPermissionsRolesEditIdIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/dashboards/audit/$id/': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/dashboards/edit/$id/': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/dashboards/runs/$id/': typeof AccountIdThreatsExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/reports/audit/$id/': typeof AccountIdThreatsExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/reports/edit/$id/': typeof AccountIdThreatsExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/threats/_externalProjectionsLayout/reports/runs/$id/': typeof AccountIdThreatsExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/audit/$id/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsAuditIdIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/edit/$id/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsEditIdIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/runs/$id/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutDashboardsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/audit/$id/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsAuditIdIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/edit/$id/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsEditIdIndexLazyRoute
  '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/runs/$id/': typeof AccountIdVulnerabilitiesExternalProjectionsLayoutReportsRunsIdIndexLazyRoute
  '/$accountId/vulnerabilities/settings/rule-set/audit/$id/': typeof AccountIdVulnerabilitiesSettingsRuleSetAuditIdIndexLazyRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/create/': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameCreateIndexRoute
  '/$accountId/platform/sources/$id/$mappingPath/$type/preview/': typeof AccountIdPlatformSourcesIdMappingPathTypePreviewIndexLazyRoute
  '/$accountId/platform/sources/audit/$id/data-mapping/$type/': typeof AccountIdPlatformSourcesAuditIdDataMappingTypeIndexLazyRoute
  '/$accountId/platform/targets/audit/$id/mapping/$idMapping/': typeof AccountIdPlatformTargetsAuditIdMappingIdMappingIndexLazyRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id/': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameAuditIdIndexRoute
  '/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id/': typeof AccountIdPlatformDataEntitiesFieldsSetFieldNameEditIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/error-process-request'
    | '/$accountId'
    | '/$accountId/caasm'
    | '/$accountId/detections'
    | '/$accountId/explore'
    | '/$accountId/incidents'
    | '/$accountId/platform'
    | '/$accountId/risk360'
    | '/$accountId/threats'
    | '/$accountId/vulnerabilities'
    | '/$accountId/tickets'
    | '/$accountId/caasm/'
    | '/$accountId/detections/'
    | '/$accountId/explore/'
    | '/$accountId/incidents/'
    | '/$accountId/risk360/'
    | '/$accountId/vulnerabilities/'
    | '/$accountId/caasm/assets'
    | '/$accountId/caasm/policy-violations'
    | '/$accountId/detections/alerts'
    | '/$accountId/detections/incidents'
    | '/$accountId/incidents/incidents'
    | '/$accountId/risk360/factors'
    | '/$accountId/threats/alerts'
    | '/$accountId/threats/incidents'
    | '/$accountId/tickets/$id'
    | '/$accountId/vulnerabilities/assets'
    | '/$accountId/vulnerabilities/exceptions'
    | '/$accountId/vulnerabilities/findings'
    | '/$accountId/vulnerabilities/tickets'
    | '/$accountId/caasm/asset-coverage'
    | '/$accountId/caasm/asset-inventory'
    | '/$accountId/caasm/policies'
    | '/$accountId/caasm/policy-compliance'
    | '/$accountId/caasm/settings'
    | '/$accountId/caasm/tool-coverage-and-gap'
    | '/$accountId/detections/explore'
    | '/$accountId/detections/global-scripts'
    | '/$accountId/detections/lookup-tables'
    | '/$accountId/detections/pivot'
    | '/$accountId/detections/rules'
    | '/$accountId/detections/settings'
    | '/$accountId/explore/entities'
    | '/$accountId/explore/logs'
    | '/$accountId/incidents/pivot'
    | '/$accountId/incidents/settings'
    | '/$accountId/platform/aliases'
    | '/$accountId/platform/asset-aging'
    | '/$accountId/platform/authentication-sources'
    | '/$accountId/platform/data-purge'
    | '/$accountId/platform/formatting-rules'
    | '/$accountId/platform/model-management'
    | '/$accountId/platform/relations'
    | '/$accountId/platform/sources'
    | '/$accountId/platform/targets'
    | '/$accountId/platform/workflows'
    | '/$accountId/risk360/factor-rules'
    | '/$accountId/settings/account-settings'
    | '/$accountId/settings/account-usage'
    | '/$accountId/settings/all-accounts'
    | '/$accountId/settings/all-users'
    | '/$accountId/settings/backoffice-actions'
    | '/$accountId/settings/global-factor-rules'
    | '/$accountId/settings/icons'
    | '/$accountId/settings/profile-settings'
    | '/$accountId/settings/query'
    | '/$accountId/settings/risk360-backoffice-actions'
    | '/$accountId/settings/user-management'
    | '/$accountId/vulnerabilities/asset-coverage'
    | '/$accountId/vulnerabilities/explore'
    | '/$accountId/vulnerabilities/overview'
    | '/$accountId/vulnerabilities/pivot'
    | '/$accountId/vulnerabilities/remediation'
    | '/$accountId/vulnerabilities/risk'
    | '/$accountId/vulnerabilities/roi'
    | '/$accountId/vulnerabilities/settings'
    | '/$accountId/caasm/assets/$id'
    | '/$accountId/caasm/policy-violations/$id'
    | '/$accountId/detections/alerts/$id'
    | '/$accountId/detections/incidents/$id'
    | '/$accountId/incidents/incidents/$id'
    | '/$accountId/risk360/factors/$id'
    | '/$accountId/threats/alerts/$id'
    | '/$accountId/threats/incidents/$id'
    | '/$accountId/vulnerabilities/assets/$id'
    | '/$accountId/vulnerabilities/exceptions/$id'
    | '/$accountId/vulnerabilities/findings/$id'
    | '/$accountId/vulnerabilities/tickets/$id'
    | '/$accountId/caasm/dashboards'
    | '/$accountId/caasm/reports'
    | '/$accountId/caasm/assets/visual'
    | '/$accountId/caasm/policies/create'
    | '/$accountId/caasm/policy-violations/visual'
    | '/$accountId/caasm/settings/ui-config'
    | '/$accountId/detections/dashboards'
    | '/$accountId/detections/reports'
    | '/$accountId/detections/alerts/visual'
    | '/$accountId/detections/explore/$id'
    | '/$accountId/detections/global-scripts/create'
    | '/$accountId/detections/incidents/visual'
    | '/$accountId/detections/lookup-tables/create'
    | '/$accountId/detections/pivot/$id'
    | '/$accountId/detections/rules/create'
    | '/$accountId/detections/rules/logs'
    | '/$accountId/detections/settings/rule-set'
    | '/$accountId/detections/settings/tickets-statuses'
    | '/$accountId/detections/settings/ui-config'
    | '/$accountId/explore/dashboards'
    | '/$accountId/explore/reports'
    | '/$accountId/incidents/dashboards'
    | '/$accountId/incidents/reports'
    | '/$accountId/incidents/incidents/visual'
    | '/$accountId/incidents/pivot/$id'
    | '/$accountId/incidents/settings/rule-set'
    | '/$accountId/incidents/settings/tickets-statuses'
    | '/$accountId/incidents/settings/ui-config'
    | '/$accountId/platform/measurements'
    | '/$accountId/platform/asset-aging/create'
    | '/$accountId/platform/data-entities/data-entities'
    | '/$accountId/platform/data-entities/fields'
    | '/$accountId/platform/data-purge/$projId'
    | '/$accountId/platform/data-purge/runs'
    | '/$accountId/platform/formatting-rules/create'
    | '/$accountId/platform/model-management/$id'
    | '/$accountId/platform/model-management/runs'
    | '/$accountId/platform/relations/$relationId'
    | '/$accountId/platform/sources/create'
    | '/$accountId/platform/targets/create'
    | '/$accountId/risk360/dashboards'
    | '/$accountId/risk360/factor-rules/create'
    | '/$accountId/risk360/factor-rules/runs'
    | '/$accountId/risk360/factors/visual'
    | '/$accountId/settings/all-accounts/create'
    | '/$accountId/settings/all-users/create'
    | '/$accountId/settings/global-factor-rules/create'
    | '/$accountId/settings/permissions/content-permissions'
    | '/$accountId/settings/permissions/roles'
    | '/$accountId/settings/user-management/create'
    | '/$accountId/threats/dashboards'
    | '/$accountId/threats/reports'
    | '/$accountId/threats/alerts/visual'
    | '/$accountId/threats/incidents/visual'
    | '/$accountId/vulnerabilities/dashboards'
    | '/$accountId/vulnerabilities/reports'
    | '/$accountId/vulnerabilities/assets/visual'
    | '/$accountId/vulnerabilities/exceptions/visual'
    | '/$accountId/vulnerabilities/findings/visual'
    | '/$accountId/vulnerabilities/pivot/$id'
    | '/$accountId/vulnerabilities/settings/exceptions-settings'
    | '/$accountId/vulnerabilities/settings/rule-set'
    | '/$accountId/vulnerabilities/settings/score'
    | '/$accountId/vulnerabilities/settings/severity-sla'
    | '/$accountId/vulnerabilities/settings/tickets-statuses'
    | '/$accountId/vulnerabilities/settings/ui-config'
    | '/$accountId/vulnerabilities/tickets/visual'
    | '/$accountId/vulnerabilities/settings/rule-set/$id'
    | '/$accountId/vulnerabilities/settings/rule-set/create'
    | '/$accountId/caasm/dashboards/create'
    | '/$accountId/caasm/reports/create'
    | '/$accountId/caasm/policies/audit/$id'
    | '/$accountId/caasm/policies/duplicate/$id'
    | '/$accountId/caasm/policies/edit/$id'
    | '/$accountId/caasm/policies/runs/$id'
    | '/$accountId/caasm/ui-config/audit/$path'
    | '/$accountId/detections/dashboards/create'
    | '/$accountId/detections/reports/create'
    | '/$accountId/detections/global-scripts/audit/$id'
    | '/$accountId/detections/global-scripts/edit/$id'
    | '/$accountId/detections/lookup-tables/audit/$id'
    | '/$accountId/detections/lookup-tables/edit/$id'
    | '/$accountId/detections/rules/audit/$id'
    | '/$accountId/detections/rules/edit/$id'
    | '/$accountId/detections/rules/runs/$id'
    | '/$accountId/detections/settings/rule-set/$id'
    | '/$accountId/detections/settings/rule-set/create'
    | '/$accountId/detections/ui-config/audit/$path'
    | '/$accountId/explore/dashboards/create'
    | '/$accountId/explore/reports/create'
    | '/$accountId/incidents/dashboards/create'
    | '/$accountId/incidents/reports/create'
    | '/$accountId/incidents/settings/rule-set/$id'
    | '/$accountId/incidents/settings/rule-set/create'
    | '/$accountId/platform/measurements/create'
    | '/$accountId/platform/asset-aging/edit/$id'
    | '/$accountId/platform/data-entities/data-entities/$projId'
    | '/$accountId/platform/formatting-rules/edit/$id'
    | '/$accountId/platform/model-management/$id/preview'
    | '/$accountId/platform/model-management/audit/$id'
    | '/$accountId/platform/sources/audit/$id'
    | '/$accountId/platform/sources/create/$type'
    | '/$accountId/platform/sources/edit/$id'
    | '/$accountId/platform/sources/runs/$id'
    | '/$accountId/platform/targets/activityLog/$id'
    | '/$accountId/platform/targets/audit/$id'
    | '/$accountId/platform/targets/create/$type'
    | '/$accountId/platform/targets/edit/$id'
    | '/$accountId/platform/targets/mapping/$id'
    | '/$accountId/risk360/dashboards/create'
    | '/$accountId/risk360/factor-rules/audit/$id'
    | '/$accountId/risk360/factor-rules/edit/$id'
    | '/$accountId/settings/all-accounts/audit/$id'
    | '/$accountId/settings/all-accounts/edit/$id'
    | '/$accountId/settings/all-users/audit/$id'
    | '/$accountId/settings/all-users/edit/$id'
    | '/$accountId/settings/global-factor-rules/audit/$id'
    | '/$accountId/settings/global-factor-rules/edit/$id'
    | '/$accountId/settings/permissions/content-permissions/create'
    | '/$accountId/settings/permissions/roles/create'
    | '/$accountId/settings/user-management/audit/$id'
    | '/$accountId/settings/user-management/edit/$id'
    | '/$accountId/threats/dashboards/create'
    | '/$accountId/threats/reports/create'
    | '/$accountId/vulnerabilities/dashboards/create'
    | '/$accountId/vulnerabilities/reports/create'
    | '/$accountId/vulnerabilities/findings/runs/$id'
    | '/$accountId/vulnerabilities/score/audit/$path'
    | '/$accountId/vulnerabilities/ui-config/audit/$path'
    | '/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex'
    | '/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex'
    | '/$accountId/caasm/dashboards/audit/$id'
    | '/$accountId/caasm/dashboards/edit/$id'
    | '/$accountId/caasm/dashboards/runs/$id'
    | '/$accountId/caasm/reports/audit/$id'
    | '/$accountId/caasm/reports/edit/$id'
    | '/$accountId/caasm/reports/runs/$id'
    | '/$accountId/detections/dashboards/audit/$id'
    | '/$accountId/detections/dashboards/edit/$id'
    | '/$accountId/detections/dashboards/runs/$id'
    | '/$accountId/detections/reports/audit/$id'
    | '/$accountId/detections/reports/edit/$id'
    | '/$accountId/detections/reports/runs/$id'
    | '/$accountId/detections/settings/rule-set/$id/$ruleIndex'
    | '/$accountId/detections/settings/rule-set/audit/$id'
    | '/$accountId/detections/settings/rule-set/create/$ruleIndex'
    | '/$accountId/explore/dashboards/audit/$id'
    | '/$accountId/explore/dashboards/edit/$id'
    | '/$accountId/explore/dashboards/runs/$id'
    | '/$accountId/explore/reports/edit/$id'
    | '/$accountId/explore/reports/runs/$id'
    | '/$accountId/incidents/dashboards/audit/$id'
    | '/$accountId/incidents/dashboards/edit/$id'
    | '/$accountId/incidents/dashboards/runs/$id'
    | '/$accountId/incidents/reports/audit/$id'
    | '/$accountId/incidents/reports/edit/$id'
    | '/$accountId/incidents/reports/runs/$id'
    | '/$accountId/incidents/settings/rule-set/$id/$ruleIndex'
    | '/$accountId/incidents/settings/rule-set/audit/$id'
    | '/$accountId/incidents/settings/rule-set/create/$ruleIndex'
    | '/$accountId/incidents/settings/ui-config/audit/$path'
    | '/$accountId/platform/measurements/audit/$id'
    | '/$accountId/platform/measurements/edit/$systemName'
    | '/$accountId/platform/data-entities/data-entities/$projId/$ruleId'
    | '/$accountId/platform/data-entities/fields/set/$fieldName'
    | '/$accountId/platform/sources/$id/$mappingPath/$type'
    | '/$accountId/platform/sources/create/$type/$previewId'
    | '/$accountId/platform/sources/edit/$id/$previewId'
    | '/$accountId/risk360/dashboards/audit/$id'
    | '/$accountId/risk360/dashboards/edit/$id'
    | '/$accountId/risk360/dashboards/runs/$id'
    | '/$accountId/settings/permissions/content-permissions/audit/$id'
    | '/$accountId/settings/permissions/content-permissions/edit/$id'
    | '/$accountId/settings/permissions/roles/audit/$id'
    | '/$accountId/settings/permissions/roles/edit/$id'
    | '/$accountId/threats/dashboards/audit/$id'
    | '/$accountId/threats/dashboards/edit/$id'
    | '/$accountId/threats/dashboards/runs/$id'
    | '/$accountId/threats/reports/audit/$id'
    | '/$accountId/threats/reports/edit/$id'
    | '/$accountId/threats/reports/runs/$id'
    | '/$accountId/vulnerabilities/dashboards/audit/$id'
    | '/$accountId/vulnerabilities/dashboards/edit/$id'
    | '/$accountId/vulnerabilities/dashboards/runs/$id'
    | '/$accountId/vulnerabilities/reports/audit/$id'
    | '/$accountId/vulnerabilities/reports/edit/$id'
    | '/$accountId/vulnerabilities/reports/runs/$id'
    | '/$accountId/vulnerabilities/settings/rule-set/audit/$id'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/create'
    | '/$accountId/platform/sources/$id/$mappingPath/$type/preview'
    | '/$accountId/platform/sources/audit/$id/data-mapping/$type'
    | '/$accountId/platform/targets/audit/$id/mapping/$idMapping'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/error-process-request'
    | '/$accountId'
    | '/$accountId/caasm'
    | '/$accountId/detections'
    | '/$accountId/explore'
    | '/$accountId/incidents'
    | '/$accountId/platform'
    | '/$accountId/risk360'
    | '/$accountId/threats'
    | '/$accountId/vulnerabilities'
    | '/$accountId/tickets'
    | '/$accountId/caasm/assets'
    | '/$accountId/caasm/policy-violations'
    | '/$accountId/detections/alerts'
    | '/$accountId/detections/incidents'
    | '/$accountId/incidents/incidents'
    | '/$accountId/risk360/factors'
    | '/$accountId/threats/alerts'
    | '/$accountId/threats/incidents'
    | '/$accountId/tickets/$id'
    | '/$accountId/vulnerabilities/assets'
    | '/$accountId/vulnerabilities/exceptions'
    | '/$accountId/vulnerabilities/findings'
    | '/$accountId/vulnerabilities/tickets'
    | '/$accountId/caasm/asset-coverage'
    | '/$accountId/caasm/asset-inventory'
    | '/$accountId/caasm/policies'
    | '/$accountId/caasm/policy-compliance'
    | '/$accountId/caasm/settings'
    | '/$accountId/caasm/tool-coverage-and-gap'
    | '/$accountId/detections/explore'
    | '/$accountId/detections/global-scripts'
    | '/$accountId/detections/lookup-tables'
    | '/$accountId/detections/pivot'
    | '/$accountId/detections/rules'
    | '/$accountId/detections/settings'
    | '/$accountId/explore/entities'
    | '/$accountId/explore/logs'
    | '/$accountId/incidents/pivot'
    | '/$accountId/incidents/settings'
    | '/$accountId/platform/aliases'
    | '/$accountId/platform/asset-aging'
    | '/$accountId/platform/authentication-sources'
    | '/$accountId/platform/data-purge'
    | '/$accountId/platform/formatting-rules'
    | '/$accountId/platform/model-management'
    | '/$accountId/platform/relations'
    | '/$accountId/platform/sources'
    | '/$accountId/platform/targets'
    | '/$accountId/platform/workflows'
    | '/$accountId/risk360/factor-rules'
    | '/$accountId/settings/account-settings'
    | '/$accountId/settings/account-usage'
    | '/$accountId/settings/all-accounts'
    | '/$accountId/settings/all-users'
    | '/$accountId/settings/backoffice-actions'
    | '/$accountId/settings/global-factor-rules'
    | '/$accountId/settings/icons'
    | '/$accountId/settings/profile-settings'
    | '/$accountId/settings/query'
    | '/$accountId/settings/risk360-backoffice-actions'
    | '/$accountId/settings/user-management'
    | '/$accountId/vulnerabilities/asset-coverage'
    | '/$accountId/vulnerabilities/explore'
    | '/$accountId/vulnerabilities/overview'
    | '/$accountId/vulnerabilities/pivot'
    | '/$accountId/vulnerabilities/remediation'
    | '/$accountId/vulnerabilities/risk'
    | '/$accountId/vulnerabilities/roi'
    | '/$accountId/vulnerabilities/settings'
    | '/$accountId/caasm/assets/$id'
    | '/$accountId/caasm/policy-violations/$id'
    | '/$accountId/detections/alerts/$id'
    | '/$accountId/detections/incidents/$id'
    | '/$accountId/incidents/incidents/$id'
    | '/$accountId/risk360/factors/$id'
    | '/$accountId/threats/alerts/$id'
    | '/$accountId/threats/incidents/$id'
    | '/$accountId/vulnerabilities/assets/$id'
    | '/$accountId/vulnerabilities/exceptions/$id'
    | '/$accountId/vulnerabilities/findings/$id'
    | '/$accountId/vulnerabilities/tickets/$id'
    | '/$accountId/caasm/dashboards'
    | '/$accountId/caasm/reports'
    | '/$accountId/caasm/assets/visual'
    | '/$accountId/caasm/policies/create'
    | '/$accountId/caasm/policy-violations/visual'
    | '/$accountId/caasm/settings/ui-config'
    | '/$accountId/detections/dashboards'
    | '/$accountId/detections/reports'
    | '/$accountId/detections/alerts/visual'
    | '/$accountId/detections/explore/$id'
    | '/$accountId/detections/global-scripts/create'
    | '/$accountId/detections/incidents/visual'
    | '/$accountId/detections/lookup-tables/create'
    | '/$accountId/detections/pivot/$id'
    | '/$accountId/detections/rules/create'
    | '/$accountId/detections/rules/logs'
    | '/$accountId/detections/settings/rule-set'
    | '/$accountId/detections/settings/tickets-statuses'
    | '/$accountId/detections/settings/ui-config'
    | '/$accountId/explore/dashboards'
    | '/$accountId/explore/reports'
    | '/$accountId/incidents/dashboards'
    | '/$accountId/incidents/reports'
    | '/$accountId/incidents/incidents/visual'
    | '/$accountId/incidents/pivot/$id'
    | '/$accountId/incidents/settings/rule-set'
    | '/$accountId/incidents/settings/tickets-statuses'
    | '/$accountId/incidents/settings/ui-config'
    | '/$accountId/platform/measurements'
    | '/$accountId/platform/asset-aging/create'
    | '/$accountId/platform/data-entities/data-entities'
    | '/$accountId/platform/data-entities/fields'
    | '/$accountId/platform/data-purge/$projId'
    | '/$accountId/platform/data-purge/runs'
    | '/$accountId/platform/formatting-rules/create'
    | '/$accountId/platform/model-management/$id'
    | '/$accountId/platform/model-management/runs'
    | '/$accountId/platform/relations/$relationId'
    | '/$accountId/platform/sources/create'
    | '/$accountId/platform/targets/create'
    | '/$accountId/risk360/dashboards'
    | '/$accountId/risk360/factor-rules/create'
    | '/$accountId/risk360/factor-rules/runs'
    | '/$accountId/risk360/factors/visual'
    | '/$accountId/settings/all-accounts/create'
    | '/$accountId/settings/all-users/create'
    | '/$accountId/settings/global-factor-rules/create'
    | '/$accountId/settings/permissions/content-permissions'
    | '/$accountId/settings/permissions/roles'
    | '/$accountId/settings/user-management/create'
    | '/$accountId/threats/dashboards'
    | '/$accountId/threats/reports'
    | '/$accountId/threats/alerts/visual'
    | '/$accountId/threats/incidents/visual'
    | '/$accountId/vulnerabilities/dashboards'
    | '/$accountId/vulnerabilities/reports'
    | '/$accountId/vulnerabilities/assets/visual'
    | '/$accountId/vulnerabilities/exceptions/visual'
    | '/$accountId/vulnerabilities/findings/visual'
    | '/$accountId/vulnerabilities/pivot/$id'
    | '/$accountId/vulnerabilities/settings/exceptions-settings'
    | '/$accountId/vulnerabilities/settings/rule-set'
    | '/$accountId/vulnerabilities/settings/score'
    | '/$accountId/vulnerabilities/settings/severity-sla'
    | '/$accountId/vulnerabilities/settings/tickets-statuses'
    | '/$accountId/vulnerabilities/settings/ui-config'
    | '/$accountId/vulnerabilities/tickets/visual'
    | '/$accountId/vulnerabilities/settings/rule-set/$id'
    | '/$accountId/vulnerabilities/settings/rule-set/create'
    | '/$accountId/caasm/dashboards/create'
    | '/$accountId/caasm/reports/create'
    | '/$accountId/caasm/policies/audit/$id'
    | '/$accountId/caasm/policies/duplicate/$id'
    | '/$accountId/caasm/policies/edit/$id'
    | '/$accountId/caasm/policies/runs/$id'
    | '/$accountId/caasm/ui-config/audit/$path'
    | '/$accountId/detections/dashboards/create'
    | '/$accountId/detections/reports/create'
    | '/$accountId/detections/global-scripts/audit/$id'
    | '/$accountId/detections/global-scripts/edit/$id'
    | '/$accountId/detections/lookup-tables/audit/$id'
    | '/$accountId/detections/lookup-tables/edit/$id'
    | '/$accountId/detections/rules/audit/$id'
    | '/$accountId/detections/rules/edit/$id'
    | '/$accountId/detections/rules/runs/$id'
    | '/$accountId/detections/settings/rule-set/$id'
    | '/$accountId/detections/settings/rule-set/create'
    | '/$accountId/detections/ui-config/audit/$path'
    | '/$accountId/explore/dashboards/create'
    | '/$accountId/explore/reports/create'
    | '/$accountId/incidents/dashboards/create'
    | '/$accountId/incidents/reports/create'
    | '/$accountId/incidents/settings/rule-set/$id'
    | '/$accountId/incidents/settings/rule-set/create'
    | '/$accountId/platform/measurements/create'
    | '/$accountId/platform/asset-aging/edit/$id'
    | '/$accountId/platform/data-entities/data-entities/$projId'
    | '/$accountId/platform/formatting-rules/edit/$id'
    | '/$accountId/platform/model-management/$id/preview'
    | '/$accountId/platform/model-management/audit/$id'
    | '/$accountId/platform/sources/audit/$id'
    | '/$accountId/platform/sources/create/$type'
    | '/$accountId/platform/sources/edit/$id'
    | '/$accountId/platform/sources/runs/$id'
    | '/$accountId/platform/targets/activityLog/$id'
    | '/$accountId/platform/targets/audit/$id'
    | '/$accountId/platform/targets/create/$type'
    | '/$accountId/platform/targets/edit/$id'
    | '/$accountId/platform/targets/mapping/$id'
    | '/$accountId/risk360/dashboards/create'
    | '/$accountId/risk360/factor-rules/audit/$id'
    | '/$accountId/risk360/factor-rules/edit/$id'
    | '/$accountId/settings/all-accounts/audit/$id'
    | '/$accountId/settings/all-accounts/edit/$id'
    | '/$accountId/settings/all-users/audit/$id'
    | '/$accountId/settings/all-users/edit/$id'
    | '/$accountId/settings/global-factor-rules/audit/$id'
    | '/$accountId/settings/global-factor-rules/edit/$id'
    | '/$accountId/settings/permissions/content-permissions/create'
    | '/$accountId/settings/permissions/roles/create'
    | '/$accountId/settings/user-management/audit/$id'
    | '/$accountId/settings/user-management/edit/$id'
    | '/$accountId/threats/dashboards/create'
    | '/$accountId/threats/reports/create'
    | '/$accountId/vulnerabilities/dashboards/create'
    | '/$accountId/vulnerabilities/reports/create'
    | '/$accountId/vulnerabilities/findings/runs/$id'
    | '/$accountId/vulnerabilities/score/audit/$path'
    | '/$accountId/vulnerabilities/ui-config/audit/$path'
    | '/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex'
    | '/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex'
    | '/$accountId/caasm/dashboards/audit/$id'
    | '/$accountId/caasm/dashboards/edit/$id'
    | '/$accountId/caasm/dashboards/runs/$id'
    | '/$accountId/caasm/reports/audit/$id'
    | '/$accountId/caasm/reports/edit/$id'
    | '/$accountId/caasm/reports/runs/$id'
    | '/$accountId/detections/dashboards/audit/$id'
    | '/$accountId/detections/dashboards/edit/$id'
    | '/$accountId/detections/dashboards/runs/$id'
    | '/$accountId/detections/reports/audit/$id'
    | '/$accountId/detections/reports/edit/$id'
    | '/$accountId/detections/reports/runs/$id'
    | '/$accountId/detections/settings/rule-set/$id/$ruleIndex'
    | '/$accountId/detections/settings/rule-set/audit/$id'
    | '/$accountId/detections/settings/rule-set/create/$ruleIndex'
    | '/$accountId/explore/dashboards/audit/$id'
    | '/$accountId/explore/dashboards/edit/$id'
    | '/$accountId/explore/dashboards/runs/$id'
    | '/$accountId/explore/reports/edit/$id'
    | '/$accountId/explore/reports/runs/$id'
    | '/$accountId/incidents/dashboards/audit/$id'
    | '/$accountId/incidents/dashboards/edit/$id'
    | '/$accountId/incidents/dashboards/runs/$id'
    | '/$accountId/incidents/reports/audit/$id'
    | '/$accountId/incidents/reports/edit/$id'
    | '/$accountId/incidents/reports/runs/$id'
    | '/$accountId/incidents/settings/rule-set/$id/$ruleIndex'
    | '/$accountId/incidents/settings/rule-set/audit/$id'
    | '/$accountId/incidents/settings/rule-set/create/$ruleIndex'
    | '/$accountId/incidents/settings/ui-config/audit/$path'
    | '/$accountId/platform/measurements/audit/$id'
    | '/$accountId/platform/measurements/edit/$systemName'
    | '/$accountId/platform/data-entities/data-entities/$projId/$ruleId'
    | '/$accountId/platform/data-entities/fields/set/$fieldName'
    | '/$accountId/platform/sources/$id/$mappingPath/$type'
    | '/$accountId/platform/sources/create/$type/$previewId'
    | '/$accountId/platform/sources/edit/$id/$previewId'
    | '/$accountId/risk360/dashboards/audit/$id'
    | '/$accountId/risk360/dashboards/edit/$id'
    | '/$accountId/risk360/dashboards/runs/$id'
    | '/$accountId/settings/permissions/content-permissions/audit/$id'
    | '/$accountId/settings/permissions/content-permissions/edit/$id'
    | '/$accountId/settings/permissions/roles/audit/$id'
    | '/$accountId/settings/permissions/roles/edit/$id'
    | '/$accountId/threats/dashboards/audit/$id'
    | '/$accountId/threats/dashboards/edit/$id'
    | '/$accountId/threats/dashboards/runs/$id'
    | '/$accountId/threats/reports/audit/$id'
    | '/$accountId/threats/reports/edit/$id'
    | '/$accountId/threats/reports/runs/$id'
    | '/$accountId/vulnerabilities/dashboards/audit/$id'
    | '/$accountId/vulnerabilities/dashboards/edit/$id'
    | '/$accountId/vulnerabilities/dashboards/runs/$id'
    | '/$accountId/vulnerabilities/reports/audit/$id'
    | '/$accountId/vulnerabilities/reports/edit/$id'
    | '/$accountId/vulnerabilities/reports/runs/$id'
    | '/$accountId/vulnerabilities/settings/rule-set/audit/$id'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/create'
    | '/$accountId/platform/sources/$id/$mappingPath/$type/preview'
    | '/$accountId/platform/sources/audit/$id/data-mapping/$type'
    | '/$accountId/platform/targets/audit/$id/mapping/$idMapping'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id'
  id:
    | '__root__'
    | '/'
    | '/error-process-request'
    | '/$accountId/'
    | '/$accountId/caasm'
    | '/$accountId/caasm/_externalProjectionsLayout'
    | '/$accountId/detections'
    | '/$accountId/detections/_externalProjectionsLayout'
    | '/$accountId/explore'
    | '/$accountId/explore/_externalProjectionsLayout'
    | '/$accountId/incidents'
    | '/$accountId/incidents/_externalProjectionsLayout'
    | '/$accountId/platform'
    | '/$accountId/platform/_externalProjectionsLayout'
    | '/$accountId/risk360'
    | '/$accountId/risk360/_externalProjectionsLayout'
    | '/$accountId/threats'
    | '/$accountId/threats/_externalProjectionsLayout'
    | '/$accountId/vulnerabilities'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout'
    | '/$accountId/tickets/'
    | '/$accountId/caasm/'
    | '/$accountId/detections/'
    | '/$accountId/explore/'
    | '/$accountId/incidents/'
    | '/$accountId/risk360/'
    | '/$accountId/vulnerabilities/'
    | '/$accountId/caasm/assets/'
    | '/$accountId/caasm/policy-violations/'
    | '/$accountId/detections/alerts/'
    | '/$accountId/detections/incidents/'
    | '/$accountId/incidents/incidents/'
    | '/$accountId/risk360/factors/'
    | '/$accountId/threats/alerts/'
    | '/$accountId/threats/incidents/'
    | '/$accountId/tickets/$id/'
    | '/$accountId/vulnerabilities/assets/'
    | '/$accountId/vulnerabilities/exceptions/'
    | '/$accountId/vulnerabilities/findings/'
    | '/$accountId/vulnerabilities/tickets/'
    | '/$accountId/caasm/asset-coverage/'
    | '/$accountId/caasm/asset-inventory/'
    | '/$accountId/caasm/policies/'
    | '/$accountId/caasm/policy-compliance/'
    | '/$accountId/caasm/settings/'
    | '/$accountId/caasm/tool-coverage-and-gap/'
    | '/$accountId/detections/explore/'
    | '/$accountId/detections/global-scripts/'
    | '/$accountId/detections/lookup-tables/'
    | '/$accountId/detections/pivot/'
    | '/$accountId/detections/rules/'
    | '/$accountId/detections/settings/'
    | '/$accountId/explore/entities/'
    | '/$accountId/explore/logs/'
    | '/$accountId/incidents/pivot/'
    | '/$accountId/incidents/settings/'
    | '/$accountId/platform/aliases/'
    | '/$accountId/platform/asset-aging/'
    | '/$accountId/platform/authentication-sources/'
    | '/$accountId/platform/data-purge/'
    | '/$accountId/platform/formatting-rules/'
    | '/$accountId/platform/model-management/'
    | '/$accountId/platform/relations/'
    | '/$accountId/platform/sources/'
    | '/$accountId/platform/targets/'
    | '/$accountId/platform/workflows/'
    | '/$accountId/risk360/factor-rules/'
    | '/$accountId/settings/account-settings/'
    | '/$accountId/settings/account-usage/'
    | '/$accountId/settings/all-accounts/'
    | '/$accountId/settings/all-users/'
    | '/$accountId/settings/backoffice-actions/'
    | '/$accountId/settings/global-factor-rules/'
    | '/$accountId/settings/icons/'
    | '/$accountId/settings/profile-settings/'
    | '/$accountId/settings/query/'
    | '/$accountId/settings/risk360-backoffice-actions/'
    | '/$accountId/settings/user-management/'
    | '/$accountId/vulnerabilities/asset-coverage/'
    | '/$accountId/vulnerabilities/explore/'
    | '/$accountId/vulnerabilities/overview/'
    | '/$accountId/vulnerabilities/pivot/'
    | '/$accountId/vulnerabilities/remediation/'
    | '/$accountId/vulnerabilities/risk/'
    | '/$accountId/vulnerabilities/roi/'
    | '/$accountId/vulnerabilities/settings/'
    | '/$accountId/caasm/assets/$id/'
    | '/$accountId/caasm/policy-violations/$id/'
    | '/$accountId/detections/alerts/$id/'
    | '/$accountId/detections/incidents/$id/'
    | '/$accountId/incidents/incidents/$id/'
    | '/$accountId/risk360/factors/$id/'
    | '/$accountId/threats/alerts/$id/'
    | '/$accountId/threats/incidents/$id/'
    | '/$accountId/vulnerabilities/assets/$id/'
    | '/$accountId/vulnerabilities/exceptions/$id/'
    | '/$accountId/vulnerabilities/findings/$id/'
    | '/$accountId/vulnerabilities/tickets/$id/'
    | '/$accountId/caasm/_externalProjectionsLayout/dashboards/'
    | '/$accountId/caasm/_externalProjectionsLayout/reports/'
    | '/$accountId/caasm/assets/visual/'
    | '/$accountId/caasm/policies/create/'
    | '/$accountId/caasm/policy-violations/visual/'
    | '/$accountId/caasm/settings/ui-config/'
    | '/$accountId/detections/_externalProjectionsLayout/dashboards/'
    | '/$accountId/detections/_externalProjectionsLayout/reports/'
    | '/$accountId/detections/alerts/visual/'
    | '/$accountId/detections/explore/$id/'
    | '/$accountId/detections/global-scripts/create/'
    | '/$accountId/detections/incidents/visual/'
    | '/$accountId/detections/lookup-tables/create/'
    | '/$accountId/detections/pivot/$id/'
    | '/$accountId/detections/rules/create/'
    | '/$accountId/detections/rules/logs/'
    | '/$accountId/detections/settings/rule-set/'
    | '/$accountId/detections/settings/tickets-statuses/'
    | '/$accountId/detections/settings/ui-config/'
    | '/$accountId/explore/_externalProjectionsLayout/dashboards/'
    | '/$accountId/explore/_externalProjectionsLayout/reports/'
    | '/$accountId/incidents/_externalProjectionsLayout/dashboards/'
    | '/$accountId/incidents/_externalProjectionsLayout/reports/'
    | '/$accountId/incidents/incidents/visual/'
    | '/$accountId/incidents/pivot/$id/'
    | '/$accountId/incidents/settings/rule-set/'
    | '/$accountId/incidents/settings/tickets-statuses/'
    | '/$accountId/incidents/settings/ui-config/'
    | '/$accountId/platform/_externalProjectionsLayout/measurements/'
    | '/$accountId/platform/asset-aging/create/'
    | '/$accountId/platform/data-entities/data-entities/'
    | '/$accountId/platform/data-entities/fields/'
    | '/$accountId/platform/data-purge/$projId/'
    | '/$accountId/platform/data-purge/runs/'
    | '/$accountId/platform/formatting-rules/create/'
    | '/$accountId/platform/model-management/$id/'
    | '/$accountId/platform/model-management/runs/'
    | '/$accountId/platform/relations/$relationId/'
    | '/$accountId/platform/sources/create/'
    | '/$accountId/platform/targets/create/'
    | '/$accountId/risk360/_externalProjectionsLayout/dashboards/'
    | '/$accountId/risk360/factor-rules/create/'
    | '/$accountId/risk360/factor-rules/runs/'
    | '/$accountId/risk360/factors/visual/'
    | '/$accountId/settings/all-accounts/create/'
    | '/$accountId/settings/all-users/create/'
    | '/$accountId/settings/global-factor-rules/create/'
    | '/$accountId/settings/permissions/content-permissions/'
    | '/$accountId/settings/permissions/roles/'
    | '/$accountId/settings/user-management/create/'
    | '/$accountId/threats/_externalProjectionsLayout/dashboards/'
    | '/$accountId/threats/_externalProjectionsLayout/reports/'
    | '/$accountId/threats/alerts/visual/'
    | '/$accountId/threats/incidents/visual/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/'
    | '/$accountId/vulnerabilities/assets/visual/'
    | '/$accountId/vulnerabilities/exceptions/visual/'
    | '/$accountId/vulnerabilities/findings/visual/'
    | '/$accountId/vulnerabilities/pivot/$id/'
    | '/$accountId/vulnerabilities/settings/exceptions-settings/'
    | '/$accountId/vulnerabilities/settings/rule-set/'
    | '/$accountId/vulnerabilities/settings/score/'
    | '/$accountId/vulnerabilities/settings/severity-sla/'
    | '/$accountId/vulnerabilities/settings/tickets-statuses/'
    | '/$accountId/vulnerabilities/settings/ui-config/'
    | '/$accountId/vulnerabilities/tickets/visual/'
    | '/$accountId/vulnerabilities/settings/rule-set/$id/'
    | '/$accountId/vulnerabilities/settings/rule-set/create/'
    | '/$accountId/caasm/_externalProjectionsLayout/dashboards/create/'
    | '/$accountId/caasm/_externalProjectionsLayout/reports/create/'
    | '/$accountId/caasm/policies/audit/$id/'
    | '/$accountId/caasm/policies/duplicate/$id/'
    | '/$accountId/caasm/policies/edit/$id/'
    | '/$accountId/caasm/policies/runs/$id/'
    | '/$accountId/caasm/ui-config/audit/$path/'
    | '/$accountId/detections/_externalProjectionsLayout/dashboards/create/'
    | '/$accountId/detections/_externalProjectionsLayout/reports/create/'
    | '/$accountId/detections/global-scripts/audit/$id/'
    | '/$accountId/detections/global-scripts/edit/$id/'
    | '/$accountId/detections/lookup-tables/audit/$id/'
    | '/$accountId/detections/lookup-tables/edit/$id/'
    | '/$accountId/detections/rules/audit/$id/'
    | '/$accountId/detections/rules/edit/$id/'
    | '/$accountId/detections/rules/runs/$id/'
    | '/$accountId/detections/settings/rule-set/$id/'
    | '/$accountId/detections/settings/rule-set/create/'
    | '/$accountId/detections/ui-config/audit/$path/'
    | '/$accountId/explore/_externalProjectionsLayout/dashboards/create/'
    | '/$accountId/explore/_externalProjectionsLayout/reports/create/'
    | '/$accountId/incidents/_externalProjectionsLayout/dashboards/create/'
    | '/$accountId/incidents/_externalProjectionsLayout/reports/create/'
    | '/$accountId/incidents/settings/rule-set/$id/'
    | '/$accountId/incidents/settings/rule-set/create/'
    | '/$accountId/platform/_externalProjectionsLayout/measurements/create/'
    | '/$accountId/platform/asset-aging/edit/$id/'
    | '/$accountId/platform/data-entities/data-entities/$projId/'
    | '/$accountId/platform/formatting-rules/edit/$id/'
    | '/$accountId/platform/model-management/$id/preview/'
    | '/$accountId/platform/model-management/audit/$id/'
    | '/$accountId/platform/sources/audit/$id/'
    | '/$accountId/platform/sources/create/$type/'
    | '/$accountId/platform/sources/edit/$id/'
    | '/$accountId/platform/sources/runs/$id/'
    | '/$accountId/platform/targets/activityLog/$id/'
    | '/$accountId/platform/targets/audit/$id/'
    | '/$accountId/platform/targets/create/$type/'
    | '/$accountId/platform/targets/edit/$id/'
    | '/$accountId/platform/targets/mapping/$id/'
    | '/$accountId/risk360/_externalProjectionsLayout/dashboards/create/'
    | '/$accountId/risk360/factor-rules/audit/$id/'
    | '/$accountId/risk360/factor-rules/edit/$id/'
    | '/$accountId/settings/all-accounts/audit/$id/'
    | '/$accountId/settings/all-accounts/edit/$id/'
    | '/$accountId/settings/all-users/audit/$id/'
    | '/$accountId/settings/all-users/edit/$id/'
    | '/$accountId/settings/global-factor-rules/audit/$id/'
    | '/$accountId/settings/global-factor-rules/edit/$id/'
    | '/$accountId/settings/permissions/content-permissions/create/'
    | '/$accountId/settings/permissions/roles/create/'
    | '/$accountId/settings/user-management/audit/$id/'
    | '/$accountId/settings/user-management/edit/$id/'
    | '/$accountId/threats/_externalProjectionsLayout/dashboards/create/'
    | '/$accountId/threats/_externalProjectionsLayout/reports/create/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/create/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/create/'
    | '/$accountId/vulnerabilities/findings/runs/$id/'
    | '/$accountId/vulnerabilities/score/audit/$path/'
    | '/$accountId/vulnerabilities/ui-config/audit/$path/'
    | '/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex/'
    | '/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex/'
    | '/$accountId/caasm/_externalProjectionsLayout/dashboards/audit/$id/'
    | '/$accountId/caasm/_externalProjectionsLayout/dashboards/edit/$id/'
    | '/$accountId/caasm/_externalProjectionsLayout/dashboards/runs/$id/'
    | '/$accountId/caasm/_externalProjectionsLayout/reports/audit/$id/'
    | '/$accountId/caasm/_externalProjectionsLayout/reports/edit/$id/'
    | '/$accountId/caasm/_externalProjectionsLayout/reports/runs/$id/'
    | '/$accountId/detections/_externalProjectionsLayout/dashboards/audit/$id/'
    | '/$accountId/detections/_externalProjectionsLayout/dashboards/edit/$id/'
    | '/$accountId/detections/_externalProjectionsLayout/dashboards/runs/$id/'
    | '/$accountId/detections/_externalProjectionsLayout/reports/audit/$id/'
    | '/$accountId/detections/_externalProjectionsLayout/reports/edit/$id/'
    | '/$accountId/detections/_externalProjectionsLayout/reports/runs/$id/'
    | '/$accountId/detections/settings/rule-set/$id/$ruleIndex/'
    | '/$accountId/detections/settings/rule-set/audit/$id/'
    | '/$accountId/detections/settings/rule-set/create/$ruleIndex/'
    | '/$accountId/explore/_externalProjectionsLayout/dashboards/audit/$id/'
    | '/$accountId/explore/_externalProjectionsLayout/dashboards/edit/$id/'
    | '/$accountId/explore/_externalProjectionsLayout/dashboards/runs/$id/'
    | '/$accountId/explore/_externalProjectionsLayout/reports/edit/$id/'
    | '/$accountId/explore/_externalProjectionsLayout/reports/runs/$id/'
    | '/$accountId/incidents/_externalProjectionsLayout/dashboards/audit/$id/'
    | '/$accountId/incidents/_externalProjectionsLayout/dashboards/edit/$id/'
    | '/$accountId/incidents/_externalProjectionsLayout/dashboards/runs/$id/'
    | '/$accountId/incidents/_externalProjectionsLayout/reports/audit/$id/'
    | '/$accountId/incidents/_externalProjectionsLayout/reports/edit/$id/'
    | '/$accountId/incidents/_externalProjectionsLayout/reports/runs/$id/'
    | '/$accountId/incidents/settings/rule-set/$id/$ruleIndex/'
    | '/$accountId/incidents/settings/rule-set/audit/$id/'
    | '/$accountId/incidents/settings/rule-set/create/$ruleIndex/'
    | '/$accountId/incidents/settings/ui-config/audit/$path/'
    | '/$accountId/platform/_externalProjectionsLayout/measurements/audit/$id/'
    | '/$accountId/platform/_externalProjectionsLayout/measurements/edit/$systemName/'
    | '/$accountId/platform/data-entities/data-entities/$projId/$ruleId/'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/'
    | '/$accountId/platform/sources/$id/$mappingPath/$type/'
    | '/$accountId/platform/sources/create/$type/$previewId/'
    | '/$accountId/platform/sources/edit/$id/$previewId/'
    | '/$accountId/risk360/_externalProjectionsLayout/dashboards/audit/$id/'
    | '/$accountId/risk360/_externalProjectionsLayout/dashboards/edit/$id/'
    | '/$accountId/risk360/_externalProjectionsLayout/dashboards/runs/$id/'
    | '/$accountId/settings/permissions/content-permissions/audit/$id/'
    | '/$accountId/settings/permissions/content-permissions/edit/$id/'
    | '/$accountId/settings/permissions/roles/audit/$id/'
    | '/$accountId/settings/permissions/roles/edit/$id/'
    | '/$accountId/threats/_externalProjectionsLayout/dashboards/audit/$id/'
    | '/$accountId/threats/_externalProjectionsLayout/dashboards/edit/$id/'
    | '/$accountId/threats/_externalProjectionsLayout/dashboards/runs/$id/'
    | '/$accountId/threats/_externalProjectionsLayout/reports/audit/$id/'
    | '/$accountId/threats/_externalProjectionsLayout/reports/edit/$id/'
    | '/$accountId/threats/_externalProjectionsLayout/reports/runs/$id/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/audit/$id/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/edit/$id/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/runs/$id/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/audit/$id/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/edit/$id/'
    | '/$accountId/vulnerabilities/_externalProjectionsLayout/reports/runs/$id/'
    | '/$accountId/vulnerabilities/settings/rule-set/audit/$id/'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/create/'
    | '/$accountId/platform/sources/$id/$mappingPath/$type/preview/'
    | '/$accountId/platform/sources/audit/$id/data-mapping/$type/'
    | '/$accountId/platform/targets/audit/$id/mapping/$idMapping/'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id/'
    | '/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ErrorProcessRequestRoute: typeof ErrorProcessRequestRoute
  AccountIdIndexLazyRoute: typeof AccountIdIndexLazyRoute
  AccountIdCaasmRoute: typeof AccountIdCaasmRouteWithChildren
  AccountIdDetectionsRoute: typeof AccountIdDetectionsRouteWithChildren
  AccountIdExploreRoute: typeof AccountIdExploreRouteWithChildren
  AccountIdIncidentsRoute: typeof AccountIdIncidentsRouteWithChildren
  AccountIdPlatformRoute: typeof AccountIdPlatformRouteWithChildren
  AccountIdRisk360Route: typeof AccountIdRisk360RouteWithChildren
  AccountIdThreatsRoute: typeof AccountIdThreatsRouteWithChildren
  AccountIdVulnerabilitiesRoute: typeof AccountIdVulnerabilitiesRouteWithChildren
  AccountIdTicketsIndexRoute: typeof AccountIdTicketsIndexRoute
  AccountIdTicketsIdIndexRoute: typeof AccountIdTicketsIdIndexRoute
  AccountIdSettingsAccountSettingsIndexLazyRoute: typeof AccountIdSettingsAccountSettingsIndexLazyRoute
  AccountIdSettingsAccountUsageIndexLazyRoute: typeof AccountIdSettingsAccountUsageIndexLazyRoute
  AccountIdSettingsAllAccountsIndexLazyRoute: typeof AccountIdSettingsAllAccountsIndexLazyRoute
  AccountIdSettingsAllUsersIndexLazyRoute: typeof AccountIdSettingsAllUsersIndexLazyRoute
  AccountIdSettingsBackofficeActionsIndexLazyRoute: typeof AccountIdSettingsBackofficeActionsIndexLazyRoute
  AccountIdSettingsGlobalFactorRulesIndexLazyRoute: typeof AccountIdSettingsGlobalFactorRulesIndexLazyRoute
  AccountIdSettingsIconsIndexLazyRoute: typeof AccountIdSettingsIconsIndexLazyRoute
  AccountIdSettingsProfileSettingsIndexLazyRoute: typeof AccountIdSettingsProfileSettingsIndexLazyRoute
  AccountIdSettingsQueryIndexLazyRoute: typeof AccountIdSettingsQueryIndexLazyRoute
  AccountIdSettingsRisk360BackofficeActionsIndexLazyRoute: typeof AccountIdSettingsRisk360BackofficeActionsIndexLazyRoute
  AccountIdSettingsUserManagementIndexLazyRoute: typeof AccountIdSettingsUserManagementIndexLazyRoute
  AccountIdSettingsAllAccountsCreateIndexLazyRoute: typeof AccountIdSettingsAllAccountsCreateIndexLazyRoute
  AccountIdSettingsAllUsersCreateIndexLazyRoute: typeof AccountIdSettingsAllUsersCreateIndexLazyRoute
  AccountIdSettingsGlobalFactorRulesCreateIndexLazyRoute: typeof AccountIdSettingsGlobalFactorRulesCreateIndexLazyRoute
  AccountIdSettingsPermissionsContentPermissionsIndexLazyRoute: typeof AccountIdSettingsPermissionsContentPermissionsIndexLazyRoute
  AccountIdSettingsPermissionsRolesIndexLazyRoute: typeof AccountIdSettingsPermissionsRolesIndexLazyRoute
  AccountIdSettingsUserManagementCreateIndexLazyRoute: typeof AccountIdSettingsUserManagementCreateIndexLazyRoute
  AccountIdSettingsAllAccountsAuditIdIndexLazyRoute: typeof AccountIdSettingsAllAccountsAuditIdIndexLazyRoute
  AccountIdSettingsAllAccountsEditIdIndexLazyRoute: typeof AccountIdSettingsAllAccountsEditIdIndexLazyRoute
  AccountIdSettingsAllUsersAuditIdIndexLazyRoute: typeof AccountIdSettingsAllUsersAuditIdIndexLazyRoute
  AccountIdSettingsAllUsersEditIdIndexLazyRoute: typeof AccountIdSettingsAllUsersEditIdIndexLazyRoute
  AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyRoute: typeof AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyRoute
  AccountIdSettingsGlobalFactorRulesEditIdIndexLazyRoute: typeof AccountIdSettingsGlobalFactorRulesEditIdIndexLazyRoute
  AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyRoute: typeof AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyRoute
  AccountIdSettingsPermissionsRolesCreateIndexLazyRoute: typeof AccountIdSettingsPermissionsRolesCreateIndexLazyRoute
  AccountIdSettingsUserManagementAuditIdIndexLazyRoute: typeof AccountIdSettingsUserManagementAuditIdIndexLazyRoute
  AccountIdSettingsUserManagementEditIdIndexLazyRoute: typeof AccountIdSettingsUserManagementEditIdIndexLazyRoute
  AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyRoute: typeof AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyRoute
  AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyRoute: typeof AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyRoute
  AccountIdSettingsPermissionsRolesAuditIdIndexLazyRoute: typeof AccountIdSettingsPermissionsRolesAuditIdIndexLazyRoute
  AccountIdSettingsPermissionsRolesEditIdIndexLazyRoute: typeof AccountIdSettingsPermissionsRolesEditIdIndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ErrorProcessRequestRoute: ErrorProcessRequestRoute,
  AccountIdIndexLazyRoute: AccountIdIndexLazyRoute,
  AccountIdCaasmRoute: AccountIdCaasmRouteWithChildren,
  AccountIdDetectionsRoute: AccountIdDetectionsRouteWithChildren,
  AccountIdExploreRoute: AccountIdExploreRouteWithChildren,
  AccountIdIncidentsRoute: AccountIdIncidentsRouteWithChildren,
  AccountIdPlatformRoute: AccountIdPlatformRouteWithChildren,
  AccountIdRisk360Route: AccountIdRisk360RouteWithChildren,
  AccountIdThreatsRoute: AccountIdThreatsRouteWithChildren,
  AccountIdVulnerabilitiesRoute: AccountIdVulnerabilitiesRouteWithChildren,
  AccountIdTicketsIndexRoute: AccountIdTicketsIndexRoute,
  AccountIdTicketsIdIndexRoute: AccountIdTicketsIdIndexRoute,
  AccountIdSettingsAccountSettingsIndexLazyRoute:
    AccountIdSettingsAccountSettingsIndexLazyRoute,
  AccountIdSettingsAccountUsageIndexLazyRoute:
    AccountIdSettingsAccountUsageIndexLazyRoute,
  AccountIdSettingsAllAccountsIndexLazyRoute:
    AccountIdSettingsAllAccountsIndexLazyRoute,
  AccountIdSettingsAllUsersIndexLazyRoute:
    AccountIdSettingsAllUsersIndexLazyRoute,
  AccountIdSettingsBackofficeActionsIndexLazyRoute:
    AccountIdSettingsBackofficeActionsIndexLazyRoute,
  AccountIdSettingsGlobalFactorRulesIndexLazyRoute:
    AccountIdSettingsGlobalFactorRulesIndexLazyRoute,
  AccountIdSettingsIconsIndexLazyRoute: AccountIdSettingsIconsIndexLazyRoute,
  AccountIdSettingsProfileSettingsIndexLazyRoute:
    AccountIdSettingsProfileSettingsIndexLazyRoute,
  AccountIdSettingsQueryIndexLazyRoute: AccountIdSettingsQueryIndexLazyRoute,
  AccountIdSettingsRisk360BackofficeActionsIndexLazyRoute:
    AccountIdSettingsRisk360BackofficeActionsIndexLazyRoute,
  AccountIdSettingsUserManagementIndexLazyRoute:
    AccountIdSettingsUserManagementIndexLazyRoute,
  AccountIdSettingsAllAccountsCreateIndexLazyRoute:
    AccountIdSettingsAllAccountsCreateIndexLazyRoute,
  AccountIdSettingsAllUsersCreateIndexLazyRoute:
    AccountIdSettingsAllUsersCreateIndexLazyRoute,
  AccountIdSettingsGlobalFactorRulesCreateIndexLazyRoute:
    AccountIdSettingsGlobalFactorRulesCreateIndexLazyRoute,
  AccountIdSettingsPermissionsContentPermissionsIndexLazyRoute:
    AccountIdSettingsPermissionsContentPermissionsIndexLazyRoute,
  AccountIdSettingsPermissionsRolesIndexLazyRoute:
    AccountIdSettingsPermissionsRolesIndexLazyRoute,
  AccountIdSettingsUserManagementCreateIndexLazyRoute:
    AccountIdSettingsUserManagementCreateIndexLazyRoute,
  AccountIdSettingsAllAccountsAuditIdIndexLazyRoute:
    AccountIdSettingsAllAccountsAuditIdIndexLazyRoute,
  AccountIdSettingsAllAccountsEditIdIndexLazyRoute:
    AccountIdSettingsAllAccountsEditIdIndexLazyRoute,
  AccountIdSettingsAllUsersAuditIdIndexLazyRoute:
    AccountIdSettingsAllUsersAuditIdIndexLazyRoute,
  AccountIdSettingsAllUsersEditIdIndexLazyRoute:
    AccountIdSettingsAllUsersEditIdIndexLazyRoute,
  AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyRoute:
    AccountIdSettingsGlobalFactorRulesAuditIdIndexLazyRoute,
  AccountIdSettingsGlobalFactorRulesEditIdIndexLazyRoute:
    AccountIdSettingsGlobalFactorRulesEditIdIndexLazyRoute,
  AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyRoute:
    AccountIdSettingsPermissionsContentPermissionsCreateIndexLazyRoute,
  AccountIdSettingsPermissionsRolesCreateIndexLazyRoute:
    AccountIdSettingsPermissionsRolesCreateIndexLazyRoute,
  AccountIdSettingsUserManagementAuditIdIndexLazyRoute:
    AccountIdSettingsUserManagementAuditIdIndexLazyRoute,
  AccountIdSettingsUserManagementEditIdIndexLazyRoute:
    AccountIdSettingsUserManagementEditIdIndexLazyRoute,
  AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyRoute:
    AccountIdSettingsPermissionsContentPermissionsAuditIdIndexLazyRoute,
  AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyRoute:
    AccountIdSettingsPermissionsContentPermissionsEditIdIndexLazyRoute,
  AccountIdSettingsPermissionsRolesAuditIdIndexLazyRoute:
    AccountIdSettingsPermissionsRolesAuditIdIndexLazyRoute,
  AccountIdSettingsPermissionsRolesEditIdIndexLazyRoute:
    AccountIdSettingsPermissionsRolesEditIdIndexLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/error-process-request",
        "/$accountId/",
        "/$accountId/caasm",
        "/$accountId/detections",
        "/$accountId/explore",
        "/$accountId/incidents",
        "/$accountId/platform",
        "/$accountId/risk360",
        "/$accountId/threats",
        "/$accountId/vulnerabilities",
        "/$accountId/tickets/",
        "/$accountId/tickets/$id/",
        "/$accountId/settings/account-settings/",
        "/$accountId/settings/account-usage/",
        "/$accountId/settings/all-accounts/",
        "/$accountId/settings/all-users/",
        "/$accountId/settings/backoffice-actions/",
        "/$accountId/settings/global-factor-rules/",
        "/$accountId/settings/icons/",
        "/$accountId/settings/profile-settings/",
        "/$accountId/settings/query/",
        "/$accountId/settings/risk360-backoffice-actions/",
        "/$accountId/settings/user-management/",
        "/$accountId/settings/all-accounts/create/",
        "/$accountId/settings/all-users/create/",
        "/$accountId/settings/global-factor-rules/create/",
        "/$accountId/settings/permissions/content-permissions/",
        "/$accountId/settings/permissions/roles/",
        "/$accountId/settings/user-management/create/",
        "/$accountId/settings/all-accounts/audit/$id/",
        "/$accountId/settings/all-accounts/edit/$id/",
        "/$accountId/settings/all-users/audit/$id/",
        "/$accountId/settings/all-users/edit/$id/",
        "/$accountId/settings/global-factor-rules/audit/$id/",
        "/$accountId/settings/global-factor-rules/edit/$id/",
        "/$accountId/settings/permissions/content-permissions/create/",
        "/$accountId/settings/permissions/roles/create/",
        "/$accountId/settings/user-management/audit/$id/",
        "/$accountId/settings/user-management/edit/$id/",
        "/$accountId/settings/permissions/content-permissions/audit/$id/",
        "/$accountId/settings/permissions/content-permissions/edit/$id/",
        "/$accountId/settings/permissions/roles/audit/$id/",
        "/$accountId/settings/permissions/roles/edit/$id/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/error-process-request": {
      "filePath": "error-process-request.tsx"
    },
    "/$accountId/": {
      "filePath": "$accountId/index.lazy.tsx"
    },
    "/$accountId/caasm": {
      "filePath": "$accountId/caasm",
      "children": [
        "/$accountId/caasm/_externalProjectionsLayout",
        "/$accountId/caasm/",
        "/$accountId/caasm/assets/",
        "/$accountId/caasm/policy-violations/",
        "/$accountId/caasm/asset-coverage/",
        "/$accountId/caasm/asset-inventory/",
        "/$accountId/caasm/policies/",
        "/$accountId/caasm/policy-compliance/",
        "/$accountId/caasm/settings/",
        "/$accountId/caasm/tool-coverage-and-gap/",
        "/$accountId/caasm/assets/$id/",
        "/$accountId/caasm/policy-violations/$id/",
        "/$accountId/caasm/assets/visual/",
        "/$accountId/caasm/policies/create/",
        "/$accountId/caasm/policy-violations/visual/",
        "/$accountId/caasm/settings/ui-config/",
        "/$accountId/caasm/policies/audit/$id/",
        "/$accountId/caasm/policies/duplicate/$id/",
        "/$accountId/caasm/policies/edit/$id/",
        "/$accountId/caasm/policies/runs/$id/",
        "/$accountId/caasm/ui-config/audit/$path/"
      ]
    },
    "/$accountId/caasm/_externalProjectionsLayout": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout.tsx",
      "parent": "/$accountId/caasm",
      "children": [
        "/$accountId/caasm/_externalProjectionsLayout/dashboards/",
        "/$accountId/caasm/_externalProjectionsLayout/reports/",
        "/$accountId/caasm/_externalProjectionsLayout/dashboards/create/",
        "/$accountId/caasm/_externalProjectionsLayout/reports/create/",
        "/$accountId/caasm/_externalProjectionsLayout/dashboards/audit/$id/",
        "/$accountId/caasm/_externalProjectionsLayout/dashboards/edit/$id/",
        "/$accountId/caasm/_externalProjectionsLayout/dashboards/runs/$id/",
        "/$accountId/caasm/_externalProjectionsLayout/reports/audit/$id/",
        "/$accountId/caasm/_externalProjectionsLayout/reports/edit/$id/",
        "/$accountId/caasm/_externalProjectionsLayout/reports/runs/$id/"
      ]
    },
    "/$accountId/detections": {
      "filePath": "$accountId/detections",
      "children": [
        "/$accountId/detections/_externalProjectionsLayout",
        "/$accountId/detections/",
        "/$accountId/detections/alerts/",
        "/$accountId/detections/incidents/",
        "/$accountId/detections/explore/",
        "/$accountId/detections/global-scripts/",
        "/$accountId/detections/lookup-tables/",
        "/$accountId/detections/pivot/",
        "/$accountId/detections/rules/",
        "/$accountId/detections/settings/",
        "/$accountId/detections/alerts/$id/",
        "/$accountId/detections/incidents/$id/",
        "/$accountId/detections/alerts/visual/",
        "/$accountId/detections/explore/$id/",
        "/$accountId/detections/global-scripts/create/",
        "/$accountId/detections/incidents/visual/",
        "/$accountId/detections/lookup-tables/create/",
        "/$accountId/detections/pivot/$id/",
        "/$accountId/detections/rules/create/",
        "/$accountId/detections/rules/logs/",
        "/$accountId/detections/settings/rule-set/",
        "/$accountId/detections/settings/tickets-statuses/",
        "/$accountId/detections/settings/ui-config/",
        "/$accountId/detections/global-scripts/audit/$id/",
        "/$accountId/detections/global-scripts/edit/$id/",
        "/$accountId/detections/lookup-tables/audit/$id/",
        "/$accountId/detections/lookup-tables/edit/$id/",
        "/$accountId/detections/rules/audit/$id/",
        "/$accountId/detections/rules/edit/$id/",
        "/$accountId/detections/rules/runs/$id/",
        "/$accountId/detections/settings/rule-set/$id/",
        "/$accountId/detections/settings/rule-set/create/",
        "/$accountId/detections/ui-config/audit/$path/",
        "/$accountId/detections/settings/rule-set/$id/$ruleIndex/",
        "/$accountId/detections/settings/rule-set/audit/$id/",
        "/$accountId/detections/settings/rule-set/create/$ruleIndex/"
      ]
    },
    "/$accountId/detections/_externalProjectionsLayout": {
      "filePath": "$accountId/detections/_externalProjectionsLayout.tsx",
      "parent": "/$accountId/detections",
      "children": [
        "/$accountId/detections/_externalProjectionsLayout/dashboards/",
        "/$accountId/detections/_externalProjectionsLayout/reports/",
        "/$accountId/detections/_externalProjectionsLayout/dashboards/create/",
        "/$accountId/detections/_externalProjectionsLayout/reports/create/",
        "/$accountId/detections/_externalProjectionsLayout/dashboards/audit/$id/",
        "/$accountId/detections/_externalProjectionsLayout/dashboards/edit/$id/",
        "/$accountId/detections/_externalProjectionsLayout/dashboards/runs/$id/",
        "/$accountId/detections/_externalProjectionsLayout/reports/audit/$id/",
        "/$accountId/detections/_externalProjectionsLayout/reports/edit/$id/",
        "/$accountId/detections/_externalProjectionsLayout/reports/runs/$id/"
      ]
    },
    "/$accountId/explore": {
      "filePath": "$accountId/explore",
      "children": [
        "/$accountId/explore/_externalProjectionsLayout",
        "/$accountId/explore/",
        "/$accountId/explore/entities/",
        "/$accountId/explore/logs/"
      ]
    },
    "/$accountId/explore/_externalProjectionsLayout": {
      "filePath": "$accountId/explore/_externalProjectionsLayout.tsx",
      "parent": "/$accountId/explore",
      "children": [
        "/$accountId/explore/_externalProjectionsLayout/dashboards/",
        "/$accountId/explore/_externalProjectionsLayout/reports/",
        "/$accountId/explore/_externalProjectionsLayout/dashboards/create/",
        "/$accountId/explore/_externalProjectionsLayout/reports/create/",
        "/$accountId/explore/_externalProjectionsLayout/dashboards/audit/$id/",
        "/$accountId/explore/_externalProjectionsLayout/dashboards/edit/$id/",
        "/$accountId/explore/_externalProjectionsLayout/dashboards/runs/$id/",
        "/$accountId/explore/_externalProjectionsLayout/reports/edit/$id/",
        "/$accountId/explore/_externalProjectionsLayout/reports/runs/$id/"
      ]
    },
    "/$accountId/incidents": {
      "filePath": "$accountId/incidents",
      "children": [
        "/$accountId/incidents/_externalProjectionsLayout",
        "/$accountId/incidents/",
        "/$accountId/incidents/incidents/",
        "/$accountId/incidents/pivot/",
        "/$accountId/incidents/settings/",
        "/$accountId/incidents/incidents/$id/",
        "/$accountId/incidents/incidents/visual/",
        "/$accountId/incidents/pivot/$id/",
        "/$accountId/incidents/settings/rule-set/",
        "/$accountId/incidents/settings/tickets-statuses/",
        "/$accountId/incidents/settings/ui-config/",
        "/$accountId/incidents/settings/rule-set/$id/",
        "/$accountId/incidents/settings/rule-set/create/",
        "/$accountId/incidents/settings/rule-set/$id/$ruleIndex/",
        "/$accountId/incidents/settings/rule-set/audit/$id/",
        "/$accountId/incidents/settings/rule-set/create/$ruleIndex/",
        "/$accountId/incidents/settings/ui-config/audit/$path/"
      ]
    },
    "/$accountId/incidents/_externalProjectionsLayout": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout.tsx",
      "parent": "/$accountId/incidents",
      "children": [
        "/$accountId/incidents/_externalProjectionsLayout/dashboards/",
        "/$accountId/incidents/_externalProjectionsLayout/reports/",
        "/$accountId/incidents/_externalProjectionsLayout/dashboards/create/",
        "/$accountId/incidents/_externalProjectionsLayout/reports/create/",
        "/$accountId/incidents/_externalProjectionsLayout/dashboards/audit/$id/",
        "/$accountId/incidents/_externalProjectionsLayout/dashboards/edit/$id/",
        "/$accountId/incidents/_externalProjectionsLayout/dashboards/runs/$id/",
        "/$accountId/incidents/_externalProjectionsLayout/reports/audit/$id/",
        "/$accountId/incidents/_externalProjectionsLayout/reports/edit/$id/",
        "/$accountId/incidents/_externalProjectionsLayout/reports/runs/$id/"
      ]
    },
    "/$accountId/platform": {
      "filePath": "$accountId/platform",
      "children": [
        "/$accountId/platform/_externalProjectionsLayout",
        "/$accountId/platform/aliases/",
        "/$accountId/platform/asset-aging/",
        "/$accountId/platform/authentication-sources/",
        "/$accountId/platform/data-purge/",
        "/$accountId/platform/formatting-rules/",
        "/$accountId/platform/model-management/",
        "/$accountId/platform/relations/",
        "/$accountId/platform/sources/",
        "/$accountId/platform/targets/",
        "/$accountId/platform/workflows/",
        "/$accountId/platform/asset-aging/create/",
        "/$accountId/platform/data-entities/data-entities/",
        "/$accountId/platform/data-entities/fields/",
        "/$accountId/platform/data-purge/$projId/",
        "/$accountId/platform/data-purge/runs/",
        "/$accountId/platform/formatting-rules/create/",
        "/$accountId/platform/model-management/$id/",
        "/$accountId/platform/model-management/runs/",
        "/$accountId/platform/relations/$relationId/",
        "/$accountId/platform/sources/create/",
        "/$accountId/platform/targets/create/",
        "/$accountId/platform/asset-aging/edit/$id/",
        "/$accountId/platform/data-entities/data-entities/$projId/",
        "/$accountId/platform/formatting-rules/edit/$id/",
        "/$accountId/platform/model-management/$id/preview/",
        "/$accountId/platform/model-management/audit/$id/",
        "/$accountId/platform/sources/audit/$id/",
        "/$accountId/platform/sources/create/$type/",
        "/$accountId/platform/sources/edit/$id/",
        "/$accountId/platform/sources/runs/$id/",
        "/$accountId/platform/targets/activityLog/$id/",
        "/$accountId/platform/targets/audit/$id/",
        "/$accountId/platform/targets/create/$type/",
        "/$accountId/platform/targets/edit/$id/",
        "/$accountId/platform/targets/mapping/$id/",
        "/$accountId/platform/data-entities/data-entities/$projId/$ruleId/",
        "/$accountId/platform/data-entities/fields/set/$fieldName/",
        "/$accountId/platform/sources/$id/$mappingPath/$type/",
        "/$accountId/platform/sources/create/$type/$previewId/",
        "/$accountId/platform/sources/edit/$id/$previewId/",
        "/$accountId/platform/data-entities/fields/set/$fieldName/create/",
        "/$accountId/platform/sources/$id/$mappingPath/$type/preview/",
        "/$accountId/platform/sources/audit/$id/data-mapping/$type/",
        "/$accountId/platform/targets/audit/$id/mapping/$idMapping/",
        "/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id/",
        "/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id/"
      ]
    },
    "/$accountId/platform/_externalProjectionsLayout": {
      "filePath": "$accountId/platform/_externalProjectionsLayout.tsx",
      "parent": "/$accountId/platform",
      "children": [
        "/$accountId/platform/_externalProjectionsLayout/measurements/",
        "/$accountId/platform/_externalProjectionsLayout/measurements/create/",
        "/$accountId/platform/_externalProjectionsLayout/measurements/audit/$id/",
        "/$accountId/platform/_externalProjectionsLayout/measurements/edit/$systemName/"
      ]
    },
    "/$accountId/risk360": {
      "filePath": "$accountId/risk360",
      "children": [
        "/$accountId/risk360/_externalProjectionsLayout",
        "/$accountId/risk360/",
        "/$accountId/risk360/factors/",
        "/$accountId/risk360/factor-rules/",
        "/$accountId/risk360/factors/$id/",
        "/$accountId/risk360/factor-rules/create/",
        "/$accountId/risk360/factor-rules/runs/",
        "/$accountId/risk360/factors/visual/",
        "/$accountId/risk360/factor-rules/audit/$id/",
        "/$accountId/risk360/factor-rules/edit/$id/"
      ]
    },
    "/$accountId/risk360/_externalProjectionsLayout": {
      "filePath": "$accountId/risk360/_externalProjectionsLayout.tsx",
      "parent": "/$accountId/risk360",
      "children": [
        "/$accountId/risk360/_externalProjectionsLayout/dashboards/",
        "/$accountId/risk360/_externalProjectionsLayout/dashboards/create/",
        "/$accountId/risk360/_externalProjectionsLayout/dashboards/audit/$id/",
        "/$accountId/risk360/_externalProjectionsLayout/dashboards/edit/$id/",
        "/$accountId/risk360/_externalProjectionsLayout/dashboards/runs/$id/"
      ]
    },
    "/$accountId/threats": {
      "filePath": "$accountId/threats",
      "children": [
        "/$accountId/threats/_externalProjectionsLayout",
        "/$accountId/threats/alerts/",
        "/$accountId/threats/incidents/",
        "/$accountId/threats/alerts/$id/",
        "/$accountId/threats/incidents/$id/",
        "/$accountId/threats/alerts/visual/",
        "/$accountId/threats/incidents/visual/"
      ]
    },
    "/$accountId/threats/_externalProjectionsLayout": {
      "filePath": "$accountId/threats/_externalProjectionsLayout.tsx",
      "parent": "/$accountId/threats",
      "children": [
        "/$accountId/threats/_externalProjectionsLayout/dashboards/",
        "/$accountId/threats/_externalProjectionsLayout/reports/",
        "/$accountId/threats/_externalProjectionsLayout/dashboards/create/",
        "/$accountId/threats/_externalProjectionsLayout/reports/create/",
        "/$accountId/threats/_externalProjectionsLayout/dashboards/audit/$id/",
        "/$accountId/threats/_externalProjectionsLayout/dashboards/edit/$id/",
        "/$accountId/threats/_externalProjectionsLayout/dashboards/runs/$id/",
        "/$accountId/threats/_externalProjectionsLayout/reports/audit/$id/",
        "/$accountId/threats/_externalProjectionsLayout/reports/edit/$id/",
        "/$accountId/threats/_externalProjectionsLayout/reports/runs/$id/"
      ]
    },
    "/$accountId/vulnerabilities": {
      "filePath": "$accountId/vulnerabilities",
      "children": [
        "/$accountId/vulnerabilities/_externalProjectionsLayout",
        "/$accountId/vulnerabilities/",
        "/$accountId/vulnerabilities/assets/",
        "/$accountId/vulnerabilities/exceptions/",
        "/$accountId/vulnerabilities/findings/",
        "/$accountId/vulnerabilities/tickets/",
        "/$accountId/vulnerabilities/asset-coverage/",
        "/$accountId/vulnerabilities/explore/",
        "/$accountId/vulnerabilities/overview/",
        "/$accountId/vulnerabilities/pivot/",
        "/$accountId/vulnerabilities/remediation/",
        "/$accountId/vulnerabilities/risk/",
        "/$accountId/vulnerabilities/roi/",
        "/$accountId/vulnerabilities/settings/",
        "/$accountId/vulnerabilities/assets/$id/",
        "/$accountId/vulnerabilities/exceptions/$id/",
        "/$accountId/vulnerabilities/findings/$id/",
        "/$accountId/vulnerabilities/tickets/$id/",
        "/$accountId/vulnerabilities/assets/visual/",
        "/$accountId/vulnerabilities/exceptions/visual/",
        "/$accountId/vulnerabilities/findings/visual/",
        "/$accountId/vulnerabilities/pivot/$id/",
        "/$accountId/vulnerabilities/settings/exceptions-settings/",
        "/$accountId/vulnerabilities/settings/rule-set/",
        "/$accountId/vulnerabilities/settings/score/",
        "/$accountId/vulnerabilities/settings/severity-sla/",
        "/$accountId/vulnerabilities/settings/tickets-statuses/",
        "/$accountId/vulnerabilities/settings/ui-config/",
        "/$accountId/vulnerabilities/tickets/visual/",
        "/$accountId/vulnerabilities/settings/rule-set/$id/",
        "/$accountId/vulnerabilities/settings/rule-set/create/",
        "/$accountId/vulnerabilities/findings/runs/$id/",
        "/$accountId/vulnerabilities/score/audit/$path/",
        "/$accountId/vulnerabilities/ui-config/audit/$path/",
        "/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex/",
        "/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex/",
        "/$accountId/vulnerabilities/settings/rule-set/audit/$id/"
      ]
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout.tsx",
      "parent": "/$accountId/vulnerabilities",
      "children": [
        "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/",
        "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/",
        "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/create/",
        "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/create/",
        "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/audit/$id/",
        "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/edit/$id/",
        "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/runs/$id/",
        "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/audit/$id/",
        "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/edit/$id/",
        "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/runs/$id/"
      ]
    },
    "/$accountId/tickets/": {
      "filePath": "$accountId/tickets/index.tsx"
    },
    "/$accountId/caasm/": {
      "filePath": "$accountId/caasm/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/detections/": {
      "filePath": "$accountId/detections/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/explore/": {
      "filePath": "$accountId/explore/index.lazy.tsx",
      "parent": "/$accountId/explore"
    },
    "/$accountId/incidents/": {
      "filePath": "$accountId/incidents/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/risk360/": {
      "filePath": "$accountId/risk360/index.lazy.tsx",
      "parent": "/$accountId/risk360"
    },
    "/$accountId/vulnerabilities/": {
      "filePath": "$accountId/vulnerabilities/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/caasm/assets/": {
      "filePath": "$accountId/caasm/assets/index.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/policy-violations/": {
      "filePath": "$accountId/caasm/policy-violations/index.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/detections/alerts/": {
      "filePath": "$accountId/detections/alerts/index.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/incidents/": {
      "filePath": "$accountId/detections/incidents/index.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/incidents/incidents/": {
      "filePath": "$accountId/incidents/incidents/index.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/risk360/factors/": {
      "filePath": "$accountId/risk360/factors/index.tsx",
      "parent": "/$accountId/risk360"
    },
    "/$accountId/threats/alerts/": {
      "filePath": "$accountId/threats/alerts/index.tsx",
      "parent": "/$accountId/threats"
    },
    "/$accountId/threats/incidents/": {
      "filePath": "$accountId/threats/incidents/index.tsx",
      "parent": "/$accountId/threats"
    },
    "/$accountId/tickets/$id/": {
      "filePath": "$accountId/tickets/$id/index.tsx"
    },
    "/$accountId/vulnerabilities/assets/": {
      "filePath": "$accountId/vulnerabilities/assets/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/exceptions/": {
      "filePath": "$accountId/vulnerabilities/exceptions/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/findings/": {
      "filePath": "$accountId/vulnerabilities/findings/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/tickets/": {
      "filePath": "$accountId/vulnerabilities/tickets/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/caasm/asset-coverage/": {
      "filePath": "$accountId/caasm/asset-coverage/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/asset-inventory/": {
      "filePath": "$accountId/caasm/asset-inventory/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/policies/": {
      "filePath": "$accountId/caasm/policies/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/policy-compliance/": {
      "filePath": "$accountId/caasm/policy-compliance/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/settings/": {
      "filePath": "$accountId/caasm/settings/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/tool-coverage-and-gap/": {
      "filePath": "$accountId/caasm/tool-coverage-and-gap/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/detections/explore/": {
      "filePath": "$accountId/detections/explore/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/global-scripts/": {
      "filePath": "$accountId/detections/global-scripts/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/lookup-tables/": {
      "filePath": "$accountId/detections/lookup-tables/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/pivot/": {
      "filePath": "$accountId/detections/pivot/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/rules/": {
      "filePath": "$accountId/detections/rules/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/settings/": {
      "filePath": "$accountId/detections/settings/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/explore/entities/": {
      "filePath": "$accountId/explore/entities/index.lazy.tsx",
      "parent": "/$accountId/explore"
    },
    "/$accountId/explore/logs/": {
      "filePath": "$accountId/explore/logs/index.lazy.tsx",
      "parent": "/$accountId/explore"
    },
    "/$accountId/incidents/pivot/": {
      "filePath": "$accountId/incidents/pivot/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/incidents/settings/": {
      "filePath": "$accountId/incidents/settings/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/platform/aliases/": {
      "filePath": "$accountId/platform/aliases/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/asset-aging/": {
      "filePath": "$accountId/platform/asset-aging/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/authentication-sources/": {
      "filePath": "$accountId/platform/authentication-sources/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/data-purge/": {
      "filePath": "$accountId/platform/data-purge/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/formatting-rules/": {
      "filePath": "$accountId/platform/formatting-rules/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/model-management/": {
      "filePath": "$accountId/platform/model-management/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/relations/": {
      "filePath": "$accountId/platform/relations/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/": {
      "filePath": "$accountId/platform/sources/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/targets/": {
      "filePath": "$accountId/platform/targets/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/workflows/": {
      "filePath": "$accountId/platform/workflows/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/risk360/factor-rules/": {
      "filePath": "$accountId/risk360/factor-rules/index.lazy.tsx",
      "parent": "/$accountId/risk360"
    },
    "/$accountId/settings/account-settings/": {
      "filePath": "$accountId/settings/account-settings/index.lazy.tsx"
    },
    "/$accountId/settings/account-usage/": {
      "filePath": "$accountId/settings/account-usage/index.lazy.tsx"
    },
    "/$accountId/settings/all-accounts/": {
      "filePath": "$accountId/settings/all-accounts/index.lazy.tsx"
    },
    "/$accountId/settings/all-users/": {
      "filePath": "$accountId/settings/all-users/index.lazy.tsx"
    },
    "/$accountId/settings/backoffice-actions/": {
      "filePath": "$accountId/settings/backoffice-actions/index.lazy.tsx"
    },
    "/$accountId/settings/global-factor-rules/": {
      "filePath": "$accountId/settings/global-factor-rules/index.lazy.tsx"
    },
    "/$accountId/settings/icons/": {
      "filePath": "$accountId/settings/icons/index.lazy.tsx"
    },
    "/$accountId/settings/profile-settings/": {
      "filePath": "$accountId/settings/profile-settings/index.lazy.tsx"
    },
    "/$accountId/settings/query/": {
      "filePath": "$accountId/settings/query/index.lazy.tsx"
    },
    "/$accountId/settings/risk360-backoffice-actions/": {
      "filePath": "$accountId/settings/risk360-backoffice-actions/index.lazy.tsx"
    },
    "/$accountId/settings/user-management/": {
      "filePath": "$accountId/settings/user-management/index.lazy.tsx"
    },
    "/$accountId/vulnerabilities/asset-coverage/": {
      "filePath": "$accountId/vulnerabilities/asset-coverage/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/explore/": {
      "filePath": "$accountId/vulnerabilities/explore/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/overview/": {
      "filePath": "$accountId/vulnerabilities/overview/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/pivot/": {
      "filePath": "$accountId/vulnerabilities/pivot/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/remediation/": {
      "filePath": "$accountId/vulnerabilities/remediation/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/risk/": {
      "filePath": "$accountId/vulnerabilities/risk/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/roi/": {
      "filePath": "$accountId/vulnerabilities/roi/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/": {
      "filePath": "$accountId/vulnerabilities/settings/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/caasm/assets/$id/": {
      "filePath": "$accountId/caasm/assets/$id/index.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/policy-violations/$id/": {
      "filePath": "$accountId/caasm/policy-violations/$id/index.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/detections/alerts/$id/": {
      "filePath": "$accountId/detections/alerts/$id/index.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/incidents/$id/": {
      "filePath": "$accountId/detections/incidents/$id/index.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/incidents/incidents/$id/": {
      "filePath": "$accountId/incidents/incidents/$id/index.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/risk360/factors/$id/": {
      "filePath": "$accountId/risk360/factors/$id/index.tsx",
      "parent": "/$accountId/risk360"
    },
    "/$accountId/threats/alerts/$id/": {
      "filePath": "$accountId/threats/alerts/$id/index.tsx",
      "parent": "/$accountId/threats"
    },
    "/$accountId/threats/incidents/$id/": {
      "filePath": "$accountId/threats/incidents/$id/index.tsx",
      "parent": "/$accountId/threats"
    },
    "/$accountId/vulnerabilities/assets/$id/": {
      "filePath": "$accountId/vulnerabilities/assets/$id/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/exceptions/$id/": {
      "filePath": "$accountId/vulnerabilities/exceptions/$id/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/findings/$id/": {
      "filePath": "$accountId/vulnerabilities/findings/$id/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/tickets/$id/": {
      "filePath": "$accountId/vulnerabilities/tickets/$id/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/caasm/_externalProjectionsLayout/dashboards/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/dashboards/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/caasm/_externalProjectionsLayout/reports/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/reports/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/caasm/assets/visual/": {
      "filePath": "$accountId/caasm/assets/visual/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/policies/create/": {
      "filePath": "$accountId/caasm/policies/create/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/policy-violations/visual/": {
      "filePath": "$accountId/caasm/policy-violations/visual/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/settings/ui-config/": {
      "filePath": "$accountId/caasm/settings/ui-config/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/detections/_externalProjectionsLayout/dashboards/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/dashboards/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/_externalProjectionsLayout/reports/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/reports/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/alerts/visual/": {
      "filePath": "$accountId/detections/alerts/visual/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/explore/$id/": {
      "filePath": "$accountId/detections/explore/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/global-scripts/create/": {
      "filePath": "$accountId/detections/global-scripts/create/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/incidents/visual/": {
      "filePath": "$accountId/detections/incidents/visual/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/lookup-tables/create/": {
      "filePath": "$accountId/detections/lookup-tables/create/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/pivot/$id/": {
      "filePath": "$accountId/detections/pivot/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/rules/create/": {
      "filePath": "$accountId/detections/rules/create/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/rules/logs/": {
      "filePath": "$accountId/detections/rules/logs/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/settings/rule-set/": {
      "filePath": "$accountId/detections/settings/rule-set/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/settings/tickets-statuses/": {
      "filePath": "$accountId/detections/settings/tickets-statuses/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/settings/ui-config/": {
      "filePath": "$accountId/detections/settings/ui-config/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/explore/_externalProjectionsLayout/dashboards/": {
      "filePath": "$accountId/explore/_externalProjectionsLayout/dashboards/index.lazy.tsx",
      "parent": "/$accountId/explore/_externalProjectionsLayout"
    },
    "/$accountId/explore/_externalProjectionsLayout/reports/": {
      "filePath": "$accountId/explore/_externalProjectionsLayout/reports/index.lazy.tsx",
      "parent": "/$accountId/explore/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/dashboards/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/dashboards/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/reports/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/reports/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/incidents/visual/": {
      "filePath": "$accountId/incidents/incidents/visual/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/incidents/pivot/$id/": {
      "filePath": "$accountId/incidents/pivot/$id/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/incidents/settings/rule-set/": {
      "filePath": "$accountId/incidents/settings/rule-set/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/incidents/settings/tickets-statuses/": {
      "filePath": "$accountId/incidents/settings/tickets-statuses/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/incidents/settings/ui-config/": {
      "filePath": "$accountId/incidents/settings/ui-config/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/platform/_externalProjectionsLayout/measurements/": {
      "filePath": "$accountId/platform/_externalProjectionsLayout/measurements/index.lazy.tsx",
      "parent": "/$accountId/platform/_externalProjectionsLayout"
    },
    "/$accountId/platform/asset-aging/create/": {
      "filePath": "$accountId/platform/asset-aging/create/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/data-entities/data-entities/": {
      "filePath": "$accountId/platform/data-entities/data-entities/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/data-entities/fields/": {
      "filePath": "$accountId/platform/data-entities/fields/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/data-purge/$projId/": {
      "filePath": "$accountId/platform/data-purge/$projId/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/data-purge/runs/": {
      "filePath": "$accountId/platform/data-purge/runs/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/formatting-rules/create/": {
      "filePath": "$accountId/platform/formatting-rules/create/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/model-management/$id/": {
      "filePath": "$accountId/platform/model-management/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/model-management/runs/": {
      "filePath": "$accountId/platform/model-management/runs/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/relations/$relationId/": {
      "filePath": "$accountId/platform/relations/$relationId/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/create/": {
      "filePath": "$accountId/platform/sources/create/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/targets/create/": {
      "filePath": "$accountId/platform/targets/create/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/risk360/_externalProjectionsLayout/dashboards/": {
      "filePath": "$accountId/risk360/_externalProjectionsLayout/dashboards/index.lazy.tsx",
      "parent": "/$accountId/risk360/_externalProjectionsLayout"
    },
    "/$accountId/risk360/factor-rules/create/": {
      "filePath": "$accountId/risk360/factor-rules/create/index.lazy.tsx",
      "parent": "/$accountId/risk360"
    },
    "/$accountId/risk360/factor-rules/runs/": {
      "filePath": "$accountId/risk360/factor-rules/runs/index.lazy.tsx",
      "parent": "/$accountId/risk360"
    },
    "/$accountId/risk360/factors/visual/": {
      "filePath": "$accountId/risk360/factors/visual/index.lazy.tsx",
      "parent": "/$accountId/risk360"
    },
    "/$accountId/settings/all-accounts/create/": {
      "filePath": "$accountId/settings/all-accounts/create/index.lazy.tsx"
    },
    "/$accountId/settings/all-users/create/": {
      "filePath": "$accountId/settings/all-users/create/index.lazy.tsx"
    },
    "/$accountId/settings/global-factor-rules/create/": {
      "filePath": "$accountId/settings/global-factor-rules/create/index.lazy.tsx"
    },
    "/$accountId/settings/permissions/content-permissions/": {
      "filePath": "$accountId/settings/permissions/content-permissions/index.lazy.tsx"
    },
    "/$accountId/settings/permissions/roles/": {
      "filePath": "$accountId/settings/permissions/roles/index.lazy.tsx"
    },
    "/$accountId/settings/user-management/create/": {
      "filePath": "$accountId/settings/user-management/create/index.lazy.tsx"
    },
    "/$accountId/threats/_externalProjectionsLayout/dashboards/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/dashboards/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/threats/_externalProjectionsLayout/reports/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/reports/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/threats/alerts/visual/": {
      "filePath": "$accountId/threats/alerts/visual/index.lazy.tsx",
      "parent": "/$accountId/threats"
    },
    "/$accountId/threats/incidents/visual/": {
      "filePath": "$accountId/threats/incidents/visual/index.lazy.tsx",
      "parent": "/$accountId/threats"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/reports/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/assets/visual/": {
      "filePath": "$accountId/vulnerabilities/assets/visual/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/exceptions/visual/": {
      "filePath": "$accountId/vulnerabilities/exceptions/visual/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/findings/visual/": {
      "filePath": "$accountId/vulnerabilities/findings/visual/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/pivot/$id/": {
      "filePath": "$accountId/vulnerabilities/pivot/$id/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/exceptions-settings/": {
      "filePath": "$accountId/vulnerabilities/settings/exceptions-settings/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/rule-set/": {
      "filePath": "$accountId/vulnerabilities/settings/rule-set/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/score/": {
      "filePath": "$accountId/vulnerabilities/settings/score/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/severity-sla/": {
      "filePath": "$accountId/vulnerabilities/settings/severity-sla/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/tickets-statuses/": {
      "filePath": "$accountId/vulnerabilities/settings/tickets-statuses/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/ui-config/": {
      "filePath": "$accountId/vulnerabilities/settings/ui-config/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/tickets/visual/": {
      "filePath": "$accountId/vulnerabilities/tickets/visual/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/rule-set/$id/": {
      "filePath": "$accountId/vulnerabilities/settings/rule-set/$id/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/rule-set/create/": {
      "filePath": "$accountId/vulnerabilities/settings/rule-set/create/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/caasm/_externalProjectionsLayout/dashboards/create/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/dashboards/create/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/caasm/_externalProjectionsLayout/reports/create/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/reports/create/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/caasm/policies/audit/$id/": {
      "filePath": "$accountId/caasm/policies/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/policies/duplicate/$id/": {
      "filePath": "$accountId/caasm/policies/duplicate/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/policies/edit/$id/": {
      "filePath": "$accountId/caasm/policies/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/policies/runs/$id/": {
      "filePath": "$accountId/caasm/policies/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/caasm/ui-config/audit/$path/": {
      "filePath": "$accountId/caasm/ui-config/audit/$path/index.lazy.tsx",
      "parent": "/$accountId/caasm"
    },
    "/$accountId/detections/_externalProjectionsLayout/dashboards/create/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/dashboards/create/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/_externalProjectionsLayout/reports/create/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/reports/create/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/global-scripts/audit/$id/": {
      "filePath": "$accountId/detections/global-scripts/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/global-scripts/edit/$id/": {
      "filePath": "$accountId/detections/global-scripts/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/lookup-tables/audit/$id/": {
      "filePath": "$accountId/detections/lookup-tables/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/lookup-tables/edit/$id/": {
      "filePath": "$accountId/detections/lookup-tables/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/rules/audit/$id/": {
      "filePath": "$accountId/detections/rules/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/rules/edit/$id/": {
      "filePath": "$accountId/detections/rules/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/rules/runs/$id/": {
      "filePath": "$accountId/detections/rules/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/settings/rule-set/$id/": {
      "filePath": "$accountId/detections/settings/rule-set/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/settings/rule-set/create/": {
      "filePath": "$accountId/detections/settings/rule-set/create/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/ui-config/audit/$path/": {
      "filePath": "$accountId/detections/ui-config/audit/$path/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/explore/_externalProjectionsLayout/dashboards/create/": {
      "filePath": "$accountId/explore/_externalProjectionsLayout/dashboards/create/index.lazy.tsx",
      "parent": "/$accountId/explore/_externalProjectionsLayout"
    },
    "/$accountId/explore/_externalProjectionsLayout/reports/create/": {
      "filePath": "$accountId/explore/_externalProjectionsLayout/reports/create/index.lazy.tsx",
      "parent": "/$accountId/explore/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/dashboards/create/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/dashboards/create/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/reports/create/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/reports/create/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/settings/rule-set/$id/": {
      "filePath": "$accountId/incidents/settings/rule-set/$id/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/incidents/settings/rule-set/create/": {
      "filePath": "$accountId/incidents/settings/rule-set/create/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/platform/_externalProjectionsLayout/measurements/create/": {
      "filePath": "$accountId/platform/_externalProjectionsLayout/measurements/create/index.lazy.tsx",
      "parent": "/$accountId/platform/_externalProjectionsLayout"
    },
    "/$accountId/platform/asset-aging/edit/$id/": {
      "filePath": "$accountId/platform/asset-aging/edit.$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/data-entities/data-entities/$projId/": {
      "filePath": "$accountId/platform/data-entities/data-entities/$projId/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/formatting-rules/edit/$id/": {
      "filePath": "$accountId/platform/formatting-rules/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/model-management/$id/preview/": {
      "filePath": "$accountId/platform/model-management/$id/preview/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/model-management/audit/$id/": {
      "filePath": "$accountId/platform/model-management/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/audit/$id/": {
      "filePath": "$accountId/platform/sources/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/create/$type/": {
      "filePath": "$accountId/platform/sources/create/$type/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/edit/$id/": {
      "filePath": "$accountId/platform/sources/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/runs/$id/": {
      "filePath": "$accountId/platform/sources/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/targets/activityLog/$id/": {
      "filePath": "$accountId/platform/targets/activityLog/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/targets/audit/$id/": {
      "filePath": "$accountId/platform/targets/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/targets/create/$type/": {
      "filePath": "$accountId/platform/targets/create/$type/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/targets/edit/$id/": {
      "filePath": "$accountId/platform/targets/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/targets/mapping/$id/": {
      "filePath": "$accountId/platform/targets/mapping/$id/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/risk360/_externalProjectionsLayout/dashboards/create/": {
      "filePath": "$accountId/risk360/_externalProjectionsLayout/dashboards/create/index.lazy.tsx",
      "parent": "/$accountId/risk360/_externalProjectionsLayout"
    },
    "/$accountId/risk360/factor-rules/audit/$id/": {
      "filePath": "$accountId/risk360/factor-rules/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/risk360"
    },
    "/$accountId/risk360/factor-rules/edit/$id/": {
      "filePath": "$accountId/risk360/factor-rules/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/risk360"
    },
    "/$accountId/settings/all-accounts/audit/$id/": {
      "filePath": "$accountId/settings/all-accounts/audit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/all-accounts/edit/$id/": {
      "filePath": "$accountId/settings/all-accounts/edit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/all-users/audit/$id/": {
      "filePath": "$accountId/settings/all-users/audit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/all-users/edit/$id/": {
      "filePath": "$accountId/settings/all-users/edit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/global-factor-rules/audit/$id/": {
      "filePath": "$accountId/settings/global-factor-rules/audit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/global-factor-rules/edit/$id/": {
      "filePath": "$accountId/settings/global-factor-rules/edit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/permissions/content-permissions/create/": {
      "filePath": "$accountId/settings/permissions/content-permissions/create/index.lazy.tsx"
    },
    "/$accountId/settings/permissions/roles/create/": {
      "filePath": "$accountId/settings/permissions/roles/create/index.lazy.tsx"
    },
    "/$accountId/settings/user-management/audit/$id/": {
      "filePath": "$accountId/settings/user-management/audit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/user-management/edit/$id/": {
      "filePath": "$accountId/settings/user-management/edit/$id/index.lazy.tsx"
    },
    "/$accountId/threats/_externalProjectionsLayout/dashboards/create/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/dashboards/create/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/threats/_externalProjectionsLayout/reports/create/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/reports/create/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/create/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/create/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/create/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/reports/create/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/findings/runs/$id/": {
      "filePath": "$accountId/vulnerabilities/findings/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/score/audit/$path/": {
      "filePath": "$accountId/vulnerabilities/score/audit/$path/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/ui-config/audit/$path/": {
      "filePath": "$accountId/vulnerabilities/ui-config/audit/$path/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex/": {
      "filePath": "$accountId/vulnerabilities/settings/rule-set/$id/$ruleIndex/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex/": {
      "filePath": "$accountId/vulnerabilities/settings/rule-set/create/$ruleIndex/index.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/caasm/_externalProjectionsLayout/dashboards/audit/$id/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/dashboards/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/caasm/_externalProjectionsLayout/dashboards/edit/$id/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/dashboards/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/caasm/_externalProjectionsLayout/dashboards/runs/$id/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/dashboards/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/caasm/_externalProjectionsLayout/reports/audit/$id/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/reports/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/caasm/_externalProjectionsLayout/reports/edit/$id/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/reports/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/caasm/_externalProjectionsLayout/reports/runs/$id/": {
      "filePath": "$accountId/caasm/_externalProjectionsLayout/reports/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/caasm/_externalProjectionsLayout"
    },
    "/$accountId/detections/_externalProjectionsLayout/dashboards/audit/$id/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/dashboards/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/_externalProjectionsLayout/dashboards/edit/$id/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/dashboards/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/_externalProjectionsLayout/dashboards/runs/$id/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/dashboards/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/_externalProjectionsLayout/reports/audit/$id/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/reports/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/_externalProjectionsLayout/reports/edit/$id/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/reports/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/_externalProjectionsLayout/reports/runs/$id/": {
      "filePath": "$accountId/detections/_externalProjectionsLayout/reports/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/detections/_externalProjectionsLayout"
    },
    "/$accountId/detections/settings/rule-set/$id/$ruleIndex/": {
      "filePath": "$accountId/detections/settings/rule-set/$id/$ruleIndex/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/settings/rule-set/audit/$id/": {
      "filePath": "$accountId/detections/settings/rule-set/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/detections/settings/rule-set/create/$ruleIndex/": {
      "filePath": "$accountId/detections/settings/rule-set/create/$ruleIndex/index.lazy.tsx",
      "parent": "/$accountId/detections"
    },
    "/$accountId/explore/_externalProjectionsLayout/dashboards/audit/$id/": {
      "filePath": "$accountId/explore/_externalProjectionsLayout/dashboards/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/explore/_externalProjectionsLayout"
    },
    "/$accountId/explore/_externalProjectionsLayout/dashboards/edit/$id/": {
      "filePath": "$accountId/explore/_externalProjectionsLayout/dashboards/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/explore/_externalProjectionsLayout"
    },
    "/$accountId/explore/_externalProjectionsLayout/dashboards/runs/$id/": {
      "filePath": "$accountId/explore/_externalProjectionsLayout/dashboards/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/explore/_externalProjectionsLayout"
    },
    "/$accountId/explore/_externalProjectionsLayout/reports/edit/$id/": {
      "filePath": "$accountId/explore/_externalProjectionsLayout/reports/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/explore/_externalProjectionsLayout"
    },
    "/$accountId/explore/_externalProjectionsLayout/reports/runs/$id/": {
      "filePath": "$accountId/explore/_externalProjectionsLayout/reports/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/explore/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/dashboards/audit/$id/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/dashboards/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/dashboards/edit/$id/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/dashboards/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/dashboards/runs/$id/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/dashboards/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/reports/audit/$id/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/reports/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/reports/edit/$id/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/reports/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/_externalProjectionsLayout/reports/runs/$id/": {
      "filePath": "$accountId/incidents/_externalProjectionsLayout/reports/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/incidents/_externalProjectionsLayout"
    },
    "/$accountId/incidents/settings/rule-set/$id/$ruleIndex/": {
      "filePath": "$accountId/incidents/settings/rule-set/$id/$ruleIndex/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/incidents/settings/rule-set/audit/$id/": {
      "filePath": "$accountId/incidents/settings/rule-set/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/incidents/settings/rule-set/create/$ruleIndex/": {
      "filePath": "$accountId/incidents/settings/rule-set/create/$ruleIndex/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/incidents/settings/ui-config/audit/$path/": {
      "filePath": "$accountId/incidents/settings/ui-config/audit/$path/index.lazy.tsx",
      "parent": "/$accountId/incidents"
    },
    "/$accountId/platform/_externalProjectionsLayout/measurements/audit/$id/": {
      "filePath": "$accountId/platform/_externalProjectionsLayout/measurements/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/platform/_externalProjectionsLayout"
    },
    "/$accountId/platform/_externalProjectionsLayout/measurements/edit/$systemName/": {
      "filePath": "$accountId/platform/_externalProjectionsLayout/measurements/edit/$systemName/index.lazy.tsx",
      "parent": "/$accountId/platform/_externalProjectionsLayout"
    },
    "/$accountId/platform/data-entities/data-entities/$projId/$ruleId/": {
      "filePath": "$accountId/platform/data-entities/data-entities/$projId/$ruleId/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/data-entities/fields/set/$fieldName/": {
      "filePath": "$accountId/platform/data-entities/fields/set.$fieldName/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/$id/$mappingPath/$type/": {
      "filePath": "$accountId/platform/sources/$id/$mappingPath/$type/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/create/$type/$previewId/": {
      "filePath": "$accountId/platform/sources/create/$type/$previewId/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/edit/$id/$previewId/": {
      "filePath": "$accountId/platform/sources/edit/$id/$previewId/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/risk360/_externalProjectionsLayout/dashboards/audit/$id/": {
      "filePath": "$accountId/risk360/_externalProjectionsLayout/dashboards/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/risk360/_externalProjectionsLayout"
    },
    "/$accountId/risk360/_externalProjectionsLayout/dashboards/edit/$id/": {
      "filePath": "$accountId/risk360/_externalProjectionsLayout/dashboards/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/risk360/_externalProjectionsLayout"
    },
    "/$accountId/risk360/_externalProjectionsLayout/dashboards/runs/$id/": {
      "filePath": "$accountId/risk360/_externalProjectionsLayout/dashboards/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/risk360/_externalProjectionsLayout"
    },
    "/$accountId/settings/permissions/content-permissions/audit/$id/": {
      "filePath": "$accountId/settings/permissions/content-permissions/audit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/permissions/content-permissions/edit/$id/": {
      "filePath": "$accountId/settings/permissions/content-permissions/edit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/permissions/roles/audit/$id/": {
      "filePath": "$accountId/settings/permissions/roles/audit/$id/index.lazy.tsx"
    },
    "/$accountId/settings/permissions/roles/edit/$id/": {
      "filePath": "$accountId/settings/permissions/roles/edit/$id/index.lazy.tsx"
    },
    "/$accountId/threats/_externalProjectionsLayout/dashboards/audit/$id/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/dashboards/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/threats/_externalProjectionsLayout/dashboards/edit/$id/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/dashboards/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/threats/_externalProjectionsLayout/dashboards/runs/$id/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/dashboards/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/threats/_externalProjectionsLayout/reports/audit/$id/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/reports/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/threats/_externalProjectionsLayout/reports/edit/$id/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/reports/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/threats/_externalProjectionsLayout/reports/runs/$id/": {
      "filePath": "$accountId/threats/_externalProjectionsLayout/reports/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/threats/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/audit/$id/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/edit/$id/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/runs/$id/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/dashboards/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/audit/$id/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/reports/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/edit/$id/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/reports/edit/$id/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/_externalProjectionsLayout/reports/runs/$id/": {
      "filePath": "$accountId/vulnerabilities/_externalProjectionsLayout/reports/runs/$id/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities/_externalProjectionsLayout"
    },
    "/$accountId/vulnerabilities/settings/rule-set/audit/$id/": {
      "filePath": "$accountId/vulnerabilities/settings/rule-set/audit/$id/index.lazy.tsx",
      "parent": "/$accountId/vulnerabilities"
    },
    "/$accountId/platform/data-entities/fields/set/$fieldName/create/": {
      "filePath": "$accountId/platform/data-entities/fields/set.$fieldName/create/index.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/$id/$mappingPath/$type/preview/": {
      "filePath": "$accountId/platform/sources/$id/$mappingPath/$type/preview/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/sources/audit/$id/data-mapping/$type/": {
      "filePath": "$accountId/platform/sources/audit/$id/data-mapping/$type/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/targets/audit/$id/mapping/$idMapping/": {
      "filePath": "$accountId/platform/targets/audit/$id/mapping/$idMapping/index.lazy.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/data-entities/fields/set/$fieldName/audit/$id/": {
      "filePath": "$accountId/platform/data-entities/fields/set.$fieldName/audit.$id/index.tsx",
      "parent": "/$accountId/platform"
    },
    "/$accountId/platform/data-entities/fields/set/$fieldName/edit/$id/": {
      "filePath": "$accountId/platform/data-entities/fields/set.$fieldName/edit.$id/index.tsx",
      "parent": "/$accountId/platform"
    }
  }
}
ROUTE_MANIFEST_END */
